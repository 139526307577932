import { Grid, Box, Stack, Typography } from '@mui/material';
import React from 'react';
import Slider from "react-slick";
import './BrandSection.css';

//import Afirme from 'assets/img/insurances/Afirme-color.svg';
// import { ReactComponent as GMX } from 'assets/img/insurances/GMX.svg';
import HDI from 'assets/img/insurances/HDI-color.svg';
import MAPFRE from 'assets/img/insurances/mapfre-color.svg';
import Qualitas from 'assets/img/insurances/Qualitas-color.svg';
import GNP from 'assets/img/insurances/GNP-colorNew.svg';
import AXA from 'assets/img/insurances/AXA-color.svg';
import Zurich from 'assets/img/insurances/zurich-color.svg';
import AIG from 'assets/img/insurances/AIG-color.svg';

import accordIcon from 'assets/img/icons/accordIcon.svg';

export default function BrandSection() {

    const logos = [HDI, MAPFRE, Qualitas, GNP, AXA, Zurich, AIG];

    var settings = {
        dots: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
        autoplay: true,
        speed: 10000,
        autoplaySpeed: 10000,
        cssEase: "linear",
        waitForAnimate: false,
        pauseOnFocus: false, 
        pauseOnHover: false,
        variableWidth: true,
        useTransform : false,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 1,
              }
            },
            {
                breakpoint: 834,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            }
          ]
    }

    return (
        <section style={{ paddingBottom: '80px' }}>
            <Box sx={{
                display: 'flex', width: '100%', flexDirection: 'column', background: '#FFFF', justifyContent: 'center',
                paddingLeft: { xs: 1.875, sm: 1.875, md: 15.625, lg: 43.125, xl: 43.125 },
                paddingRight: { xs: 1.875, sm: 1.875, md: 15.625, lg: 43.125, xl: 43.125 },
            }}>
                <Stack sx={{ alignItems: 'center' }}>
                    <img src={accordIcon} style={{ width: '84px', height: '68px' }}></img>
                </Stack>
                <Typography sx={{
                    paddingTop: '40px',
                    color: '#212121',
                    textAlign: 'center',
                    fontFamily: 'Montserrat',
                    fontSize: '24px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: 'normal',
                }}><h2>¿Quiénes son nuestros aliados?</h2>
                </Typography>
                <Typography sx={{
                    paddingTop: '40px',
                    paddingBottom: '40px',
                    color: '#212121',
                    fontFamily: 'Montserrat',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: 'normal',
                }}>Trabajamos de la mano con las mejores aseguradoras para que puedas conseguir la protección ideal para ti y para los que más amas.
                </Typography>
            </Box >
            <div className="containerSlider">
                <Slider {...settings} className='branchSlider'>
                    {logos.map((logo, index) => (
                        <div key={index} style={{display:'inherit'}}>
                            <img src={logo} alt='logo' />
                        </div>
                    ))}
                </Slider>
            </div>
        </section>
    );
}
