export default function Legend () {
  return (
    <section className="pb-5 bg-gray-100" >
      <div className="container mx-auto max-w-full">
        <div className='bg-white uppercase text-center font-22' style={{color: '#212121', padding: 20}}>
          Aprovecha meses sin intereses todo el año con tarjetas participantes.
        </div>
      </div>
    </section>
  )
}