import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button } from '@mui/material';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  backgroundColor: 'transparent'
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ChevronRightIcon />}
    {...props}
  />
))(({ theme }) => ({
  padding: '10px 14px',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
    hidden: true

  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '20px 25px',
  borderTop: '1px solid rgba(0, 0, 0, .125)'
}));

export default function SimpleAccordion() {

  const [expanded, setExpanded] = useState('');
  const [selectedNotice, setSelectedNotice] = useState('');

  const handleChange = (aviso) => (event, newExpanded) => {
    setExpanded(newExpanded ? aviso : false);
    setSelectedNotice(aviso);
    let infoavisos = document.getElementsByClassName('info');
    for (let i = 0; i < infoavisos.length; i++) {
      infoavisos[i].style.display = "block";
    }
    let avisos = document.getElementsByClassName('avisos');
    for (let i = 0; i < avisos.length; i++) {
      if (avisos[i].id !== aviso) {
        avisos[i].style.display = "none";
      }
    }
    document.getElementById(aviso+'-header').style.pointerEvents = "none";
  }

  const showAllNotices = (notice) => (e) => {
    setExpanded(false);
    let infoavisos = document.getElementsByClassName('info');
    for (let i = 0; i < infoavisos.length; i++) {
      infoavisos[i].style.display = "none";
    }
    let avisos = document.getElementsByClassName('avisos');
    for (let i = 0; i < avisos.length; i++) {
      avisos[i].style.display = "block";
    }
    document.getElementById(notice+'-header').style.pointerEvents = "auto";
  };

  return (
    <Box sx={{
      paddingLeft: { xs: '16px', sm: '16px', md: '36px', lg: '247px', xl: '247px' },
      paddingRight: { xs: '16px', sm: '16px', md: '36px', lg: '247px', xl: '247px' }
    }} >
      {
        expanded &&
        <Box sx={{ width: '100%',paddingTop:'40px'}}>
          <Button sx={{ flexDirection: 'row'}}
            onClick={showAllNotices(selectedNotice)}>
            <ArrowBackIcon sx={{ color: '#039ECC' }} />
            <Typography
              sx={{
                color: '#039ECC',
                fontFeatureSettings: { clig: 'off', liga: 'off' },
                fontFamily: 'Montserrat',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '24px'
              }}>
              Regresar</Typography>
          </Button>
        </Box>
      }
      <Accordion
        id="aviso1"
        expanded={expanded === 'aviso1'}
        onChange={handleChange('aviso1')}
        className="avisos">
        <AccordionSummary
          aria-controls="panel2a-content"
          id="aviso1-header"
          expandIcon={expanded === `aviso1` ? <></> : <ChevronRightIcon />}
        >
          <Typography className='header-avisoinfo'><p><b>AVISO DE PRIVACIDAD INTEGRAL PARA CLIENTES.</b></p></Typography>
        </AccordionSummary>
        <AccordionDetails className="info">
          <Typography>
            <p><b>Casanueva Pérez, S.A.P.I. de C.V., subsidiarias y filiales (INTERprotección).</b></p>
            <p><span >&nbsp;</span></p>
            <p><b>Identidad del responsable.</b></p>
            <p><span >Casanueva Pérez, S.A.P.I. de C.V., subsidiarias y filiales en lo sucesivo (“</span><b>INTERprotección
            </b><span>”) en su carácter de responsable del tratamiento de sus datos personales, manifiesta que para
              efectos del presente Aviso de Privacidad su domicilio se encuentra ubicado en Miguel de Cervantes Saavedra
              No 301, Torre Sur, Piso 17 Colonia Granada, Alcaldía Miguel Hidalgo, CP 11520, Ciudad de México, y con página web&nbsp;
              </span><u><a style={{ color: '#039ECC' }} href="http://www.inter.mx/">www.inter.mx</a></u></p>
            <p><b>INTERprotección</b><span> es el responsable del tratamiento de los datos personales que nos proporcione, los
              cuales se protegerán bajo las más estrictas medidas de seguridad a fin de garantizar su confidencialidad,
              protección y tratamiento conforme a lo dispuesto en el presente Aviso de Privacidad, la Ley Federal de Protección

              de Datos Personales en Posesión de los Particulares (LA LEY) y demás normatividad aplicable.</span></p>
            <p><b>INTERprotección</b><span> pone a su disposición el presente Aviso de Privacidad, con la finalidad de dar
              cumplimiento a los artículos 15, 16 y demás relativos de la LEY y a los artículos 24 al 30 y demás relativos del Reglamento de
              la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (REGLAMENTO) y la normatividad que resulte
              aplicable; y al respecto le informa lo siguiente:</span></p>
            <p><span >&nbsp;</span></p>
            <p><b>Razones sociales de INTERprotección.</b></p>
            <p><b>INTERprotección</b><span>, en su carácter de responsable del tratamiento de sus datos personales, manifiesta que para efectos
              del presente Aviso de Privacidad las razones sociales cubiertas, son las siguientes: Casanueva Pérez, S.A.P.I. de C.V., Centro
              INTER de Servicios, S.A. de C.V., INTERprotección, Agente de Seguros y de Fianzas S.A. de C.V., INTERprotección Guadalajara,
              Agente de Seguros y de Fianzas, S.A. de C.V., INTERprotección Mérida, Agente de Seguros y de Fianzas, S.A. de C.V., INTER Los
              Cabos, Agente de Seguros y de Fianzas, S.A. de C.V., Se Listo, Agente de Seguros y de Fianzas, S.A. de C.V., PCP, Agente de
              Seguros y de Fianzas, S.A. de C.V., MK, Agente de Seguros y de Fianzas, S.A. de C.V., EJC, Agente de Seguros y de Fianzas,
              S.A.P.I. de C.V., HJK, Agente de Seguros y de Fianzas, S.A.P.I. de C.V., INTER Infraestructura, Agente de Seguros y de Fianzas,
              S.A. de C.V., INTERópolis, Agente de Seguros y de Fianzas, S.A.P.I. de C.V,.Intersecuritas, Agente de Seguros y de Fianzas, S.A.
              de C.V., Reasinter, Intermediarios de Reaseguro S.A. de C.V., Flag Assist, S.A.P.I. de C.V., INTERprotección Asistencias, S.A.P.I.
              de C.V., Financial Lines Advisory Group, S.A.P.I. de C.V., INTER Monterrey Agente de Seguros y de Fianzas, S.A. de C.V.,
              INTERprotección Sinaloa, Agente de Seguros y de Fianzas, S.A. de C.V., Unifin, Agente de Seguros y de Fianzas, S.A. de C.V.,
              INTER Mota Latam, Agente de Seguros y de Fianzas, S.A.P.I. de C.V.</span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Datos personales recibidos del TITULAR</b></li>
            </ol>
            <p><span >Para llevar a cabo las finalidades descritas en el presente Aviso de Privacidad, se solicitarán de usted los siguientes datos
              personales:</span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li>Datos generales.</li>
            </ol>
            <ol>
              <li ><span >Datos de identificación y contacto, datos de características físicas, datos familiares y de beneficiarios, datos de referencias,
                datos de pasatiempos y entretenimientos, datos laborales y profesionales, en caso de ser extranjero situación migratoria, país de
                origen y nacionalidad.</span></li>
            </ol>
            <p><span >&nbsp;</span></p>
            <ol>
              <li>Datos financieros y patrimoniales.</li>
            </ol>
            <ol>
              <li><span >Datos financieros, datos bancarios, datos fiscales, datos crediticios, datos patrimoniales, datos de seguros y fianzas.
                Para el tratamiento de estas categorías de datos (Financieros y Patrimoniales) se requiere de su consentimiento expreso, el cual se otorga
                mediante la firma del presente Aviso de Privacidad.
              </span></li>
            </ol>
            <p><span >&nbsp;</span></p>
            <ol>
              <li>Datos sensibles.</li>
            </ol>
            <ol>
              <li><span>Para las finalidades informadas en el presente Aviso de Privacidad utilizaremos los siguientes datos personales considerados como
                sensibles, que requieren de especial protección y se tratarán bajo las más estrictas medidas de seguridad y confidencialidad:
                Datos sensibles: estado de salud (pasado, presente y futuro), resultado de estudios clínicos, antecedentes clínicos y médicos, tipo de sangre,
                alergias, enfermedades hereditarias, congénitas o adquiridas, hábitos de consumo de alcohol, tabaco o drogas; en caso de requerirse para el
                cumplimiento de la Ley Federal para la Prevención e Identificación de Operaciones con Recursos de Procedencia Ilícita (LFPIORPI), o la ley
                aplicable respecto a la finalidad primaria establecida, situación jurídica y política propia o de familiares.
                Geolocalización para estar en posibilidad de prestar servicios que sean contratados y que así estén diseñados y acordados con EL CLIENTE.
                El tratamiento de los datos personales sensibles requiere de su consentimiento expreso y por escrito, que otorga mediante la firma del presente
                Aviso de Privacidad.
              </span></li>
            </ol>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Finalidades del uso de los datos personales.</b></li>
            </ol>
            <p><span >&nbsp;</span></p>
            <ol>
              <li>Finalidades primarias</li>
            </ol>
            <ol>
              <li><span>Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son necesarias para bridar el producto o
                servicio que usted nos solicita:
                <li><span>1. Llevar a cabo las actividades relativas a la intermediación para la contratación de seguros o fianzas a través de la obtención de cotizaciones
                  por parte de las compañías de seguros y fianzas; generar solicitudes de pólizas de seguro o fianzas, generar endosos de pólizas; administrar pólizas
                  y pagos de primas, renovaciones, cancelaciones y devoluciones de primas, enviarle pólizas y demás documentos relativos al seguro o fianza intermediadas
                  por nuestro conducto; la administración, trámite y seguimiento de siniestros; implementación e integración a sistemas de financiamiento.</span>
                </li>
                <li><span>2. En complemento a las actividades señaladas en el punto anterior, se podrá cotizar y contratar lo siguiente:</span></li>
                <li><span>a. Productos y servicios de ingeniería y consultoría, consistentes en evaluación y gestión de riesgos, cumplimiento regulatorio y normativo, capacitación,
                  asesoría en prevención y reducción de siniestralidad, y soporte a clientes.</span>
                </li>
                <li><span>b. Productos y servicios internos o externos para la mejora y apoyo al servicio a clientes, consistentes en redes médicas, laboratorios, farmacias,
                  hospitales, servicios auxiliares de salud, de asistencia vial o asistencia en el hogar.</span>
                </li>
                <li><span>c. Productos y servicios de asesoría, asistencia o gestión legal a clientes o asesoría jurídica en responsabilidad civil profesional médica.</span></li>
                <li><span>3. Verificar y validar su identidad, localización y contacto a través de sistemas de comunicación directa, digital o celular.</span>
                </li>
                <li><span>4. Grabación de llamadas o videollamadas para el seguimiento y mejora en la calidad de nuestros servicios, y en caso de vista a nuestras instalaciones
                  el control del acceso y videovigilancia.</span>
                </li>
                <li><span>5. Cumplimiento legal a los requerimientos de autoridades y dependencias del Gobierno Federal, Estatal o Local, y </span>
                </li>
                <li><span>6. El almacenamiento de los datos personales para la integración de expedientes físicos o digitales.</span></li>
              </span></li>
            </ol>
            <p><span >&nbsp;</span></p>
            <ol>
              <li>Finalidades secundarias</li>
            </ol>
            <ol>
              <li><span>Si usted no se opone, <b>INTERprotección</b> tratará su información personal para finalidades que no son necesarias para brindarle el servicio solicitado,
                pero que nos permiten ofrecerle una mejor atención:
              </span></li>
            </ol>
            <p>
              <table class="default" style={{ border: '1px solid black' }}>
                <tr>
                  <td style={{ border: '1px solid black' }}>#</td>
                  <td style={{ border: '1px solid black' }}>Finalidad secundaria</td>
                  <td style={{ border: '1px solid black' }}>No deseo que se traten mis datos personales para dicha finalidad</td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', textAlign: 'center' }}>1</td>
                  <td style={{ border: '1px solid black' }}>Realizar estudios y procesos internos.</td>
                  <td style={{ border: '1px solid black', textAlign: 'center' }}>(     )</td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', textAlign: 'center' }}>2</td>
                  <td style={{ border: '1px solid black' }}>Realizar encuestas de calidad en el servicio y atención a clientes y
                    actividades encaminadas a promover y mejorar los servicios de <b>INTERprotección</b>.</td>
                  <td style={{ border: '1px solid black', textAlign: 'center' }}>(     )</td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', textAlign: 'center' }}>3</td>
                  <td style={{ border: '1px solid black' }}>Para fines mercadotécnicos, publicitarios o de prospección comercial de
                    nuestros productos o servicios.</td>
                  <td style={{ border: '1px solid black', textAlign: 'center' }}>(     )</td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', textAlign: 'center' }}>4</td>
                  <td style={{ border: '1px solid black' }}>Para fines mercadotécnicos, publicitarios o de prospección comercial de
                    alianzas comerciales.</td>
                  <td style={{ border: '1px solid black', textAlign: 'center' }}>(     )</td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', textAlign: 'center' }}>5</td>
                  <td style={{ border: '1px solid black' }}>Enviarle información sobre productos, servicios y boletines del sector
                    de seguros, fianzas y financiero.</td>
                  <td style={{ border: '1px solid black', textAlign: 'center' }}>(     )</td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', textAlign: 'center' }}>6</td>
                  <td style={{ border: '1px solid black' }}>Fines estadísticos y de estudios del mercado y la industria.</td>
                  <td style={{ border: '1px solid black', textAlign: 'center' }}>(     )</td>
                </tr>
              </table>
            </p>
            <p><span>Le informamos que en caso de que no desee que sus datos personales se traten para estas finalidades secundarias,
              usted puede indicarlo al momento de la presentación del presente Aviso de Privacidad, o tiene un plazo de 5 (cinco) días
              hábiles para comunicarlo al correo: <a style={{ color: '#039ECC' }} href="mailto:leyproteccion@inter.mx">
                leyproteccion@inter.mx</a></span>
            </p>
            <p><span>La negativa del uso de sus datos personales para las finalidades secundarias no será un motivo para que le neguemos
              la prestación de los servicios, productos o relación jurídica que solicita de parte de nosotros.</span>
            </p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Transferencias o remisiones de datos.</b></li>
            </ol>
            <p><b>INTERprotección</b><span > conforme a lo que se establece en el artículo 37 de LA LEY, le informa que las transferencias
              de datos personales que puede efectuar sin su consentimiento son las siguientes:</span></p>
            <p><b>I.</b><span > Cuando la transferencia esté prevista en una Ley o Tratado en los que México sea parte.</span></p>
            <p><b>II.</b><span > Cuando la transferencia sea necesaria para la prevención o el diagnóstico médico, la prestación de
              asistencia sanitaria, tratamiento médico o la gestión de servicios sanitarios.</span></p>
            <p><b>III.</b><span > Cuando la transferencia sea efectuada a sociedades controladoras, subsidiarias o afiliadas bajo
              el control común de <b>INTERprotección</b>, o a una sociedad matriz o a cualquier sociedad del mismo grupo de <b>INTERprotección</b> que
              opere bajo los mismos procesos y políticas internas.</span></p>
            <p><b>IV.</b><span > Cuando la transferencia sea necesaria por virtud de un contrato celebrado o por celebrar en interés del <b>TITULAR</b>,
              por <b>INTERprotección</b> y un <b>TERCERO</b>.</span></p>
            <p><b>V.</b><span > Cuando la transferencia sea necesaria o legalmente exigida para la salvaguarda de un interés público, o para la
              procuración o administración de justicia.</span></p>
            <p><b>VI.</b><span > Cuando la transferencia sea precisa para el reconocimiento, ejercicio o defensa de un derecho en un proceso judicial, y
            </span></p>
            <p><b>VII.</b><span > VII.	Cuando la transferencia sea precisa para el mantenimiento o cumplimiento de una relación jurídica entre el de
              <b>INTERprotección</b>, y el <b>TITULAR</b>.</span></p>
            <p><span>Se hace de su conocimiento que INTERprotección, adicional a las transferencias que realiza y que no requieren de su consentimiento,
              podrá llevar a cabo la transferencia de sus datos personales que a continuación se indican: </span>
            </p>
            <p><span >&nbsp;</span></p>
            <p>
              <table class="default">
                <tr>
                  <td style={{ borderTop: '1px solid black', borderBottom: '1px solid black' }}><b>Terceros</b></td>
                  <td style={{ borderTop: '1px solid black', borderBottom: '1px solid black' }}><b>FINALIDAD</b></td>
                </tr>
                <tr>
                  <td style={{ borderTop: '1px solid black' }}><b>Aseguradoras, reaseguradoras o afianzadoras.</b></td>
                  <td style={{ borderTop: '1px solid black' }}>
                    <p><span>• Cuando los <b>TITULARES</b> soliciten la prestación de servicios de intermediación y asesoría para la contratación, administración
                      y cancelación de seguros, y fianzas.</span></p>
                    <p><span>• Cuando los <b>TITULARES</b> soliciten el apoyo en trámite y seguimiento de siniestros.</span></p>
                  </td>
                </tr>
                <tr>
                  <td style={{ borderTop: '1px solid black' }}><b>Terceros: proveedores de redes médicas, laboratorios, farmacias, hospitales y
                    servicios auxiliares de salud. </b></td>
                  <td style={{ borderTop: '1px solid black' }}>
                    <p><span>• Cuando los <b>TITULARES</b> soliciten la prestación de servicios a proveedores de redes médicas, laboratorios, farmacias, hospitales y
                      servicios auxiliares de salud.</span></p>
                  </td>
                </tr>
                <tr>
                  <td style={{ borderTop: '1px solid black' }}><b>Terceros: proveedores de asistencia vial o en el hogar.</b></td>
                  <td style={{ borderTop: '1px solid black' }}>
                    <p><span>• Cuando los <b>TITULARES</b> soliciten la prestación de servicios de asistencia vial, apoyo en siniestros o asistencia en el hogar.</span></p>
                  </td>
                </tr>
                <tr>
                  <td style={{ borderTop: '1px solid black' }}><b>Terceros: Instituciones de crédito, bancarias.</b></td>
                  <td style={{ borderTop: '1px solid black' }}>
                    <p><span>• Cuando los <b>TITULARES</b> soliciten sistemas de financiamiento o financiamiento de seguros.</span></p>
                  </td>
                </tr>
                <tr>
                  <td style={{ borderTop: '1px solid black', borderBottom: '1px solid black' }}><b>Despachos jurídicos.</b></td>
                  <td style={{ borderTop: '1px solid black', borderBottom: '1px solid black' }}>
                    <p><span>• Cuando los <b>TITULARES</b> soliciten servicios de asistencia de red jurídica para atención a siniestros.</span></p>
                    <p><span>• Cuando los <b>TITULARES</b> soliciten servicio de asistencia jurídica en responsabilidad civil profesional médica.</span></p>
                  </td>
                </tr>
              </table>
            </p>
            <p><span>Para estas transferencias requerimos de su consentimiento expreso y por escrito, que usted otorga al firmar el presente Aviso de Privacidad.</span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Lineamientos y limitaciones en las transferencias o remisiones de datos personales.</b></li>
            </ol>
            <p><span>En los casos que INTERprotección comparte su información personal, le informa que se establecen y seguirán los siguientes lineamientos y limitaciones con base a lo establecido en
              la LFPDPPP, su reglamento y normatividad vigente: </span>
            </p>
            <p><span>• <b>INTERprotección</b> no compartirá su información personal a destinatarios distintos a los establecidos en el presente Aviso de Privacidad.</span></p>
            <p><span>• En todos los casos que <b>INTERprotección</b> realice transferencias a terceros responsables o remisiones a encargados comunicará a los mismos el presente Aviso de Privacidad.</span></p>
            <p><span>• Se estabecerá con los destinatarios por medio de cláusulas contractuales, lo siguiente:</span></p>
            <p><span>o Que el tratamiento de los datos personales compartidos solo se realice para las finalidades establecidas en el presente Aviso de Privacidad.</span></p>
            <p><span>o Que los datos personales compartidos se traten conforme a lo establecido en la LEY, su REGLAMENTO y demás ordenamientos aplicables.</span></p>
            <p><span>o Que los datos personales compartidos en ningún caso se pueden comunicar a ningún tercero.</span></p>
            <p><span>• En el caso de que <b>INTERprotección</b> necesite compartir su información personal a terceros distintos o para dar tratamiento para finalidades distintas a las establecidos en el presente
              Aviso de Privacidad y que se requiera de su consentimiento, pondrá a su disposición el nuevo Aviso de Privacidad, previo a compartir o dar tratamiento de sus datos personales, y solicitará su
              consentimiento.</span></p>
            <p><span >&nbsp;</span></p>
            <p><span >Se recomienda que usted como <b>TITULAR</b> de los datos personales, notifique a <b>INTERprotección</b> sobre cualquier cambio respecto su domicilio físico y/o dirección de correo electrónico,
              con la finalidad de mantener actualizados sus datos personales para informarle cualquier cambio o modificación respecto del contenido en el Aviso de Privacidad.</span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Medios para ejercer los derechos de acceso, rectificación, cancelación y oposición (ARCO) de los datos personales.</b></li>
            </ol>
            <p><span>Usted podrá ejercer sus derechos de acceso, rectificación, cancelación u oposición de sus datos personales (Derechos ARCO) directamente con el Oficial de Privacidad en nuestro domicilio ubicado
              en Avenida Miguel de Cervantes Saavedra No 301, Torre Sur, Piso 17 Colonia Granada, Alcaldía Miguel Hidalgo, CP 11520, Ciudad de México, o bien, a través del correo: <a style={{ color: '#039ECC' }}
                href="mailto:leyproteccion@inter.mx">leyproteccion@inter.mx</a>
              <p><span >&nbsp;</span></p>
              Si desea conocer el procedimiento para el ejercicio de estos derechos, puede solicitarlo directamente a nuestro el Oficial de Privacidad o enviar un correo electrónico a la dirección antes señalada.
              Es importante comunicarle que <b>INTERprotección</b> podrá negar el acceso para que usted ejerza sus Derechos ARCO en los siguientes supuestos:
            </span></p>
            <ol>
              <li><span>1. Cuando usted no sea el TITULAR de los datos personales, o su representante legal no esté debidamente acreditado para ejercer por medio de él, sus Derechos ARCO.</span></li>
              <li><span>2. Cuando en nuestra base de datos no se encuentren sus datos personales.</span></li>
              <li><span>3. Cuando se lesionen los derechos de un tercero.</span></li>
              <li><span>4. Cuando exista un impedimento legal o la resolución de una autoridad competente, que restrinja sus Derechos ARCO, y</span></li>
              <li><span>5. Cuando la Rectificación, Cancelación u Oposición haya sido previamente realizada.</span></li>
            </ol>
            <p><span><b>INTERprotección</b> siempre le informará el motivo de su decisión y se la comunicará a usted o en caso, a su representante legal por correo electrónico, acompañando, en su caso, las pruebas que
              resulten pertinentes.
              El ejercicio de los Derechos ARCO será gratuito.</span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Mecanismo para revocación del consentimiento.</b></li>
            </ol>
            <p><span >Usted podrá revocar su consentimiento para que INTERprotección deje de llevar a cabo el tratamiento de sus datos personales directamente con el Oficial de Privacidad en nuestro domicilio ubicado en
              Avenida Miguel de Cervantes Saavedra No 301, Torre Sur, Piso 17 Colonia Granada, Alcaldía Miguel Hidalgo, CP 11520, Ciudad de México, o bien, a través del correo: <a style={{ color: '#039ECC' }}
                href="mailto:leyproteccion@inter.mx">leyproteccion@inter.mx</a>
              Si desea conocer el procedimiento para revocar su consentimiento, puede solicitarlo directamente a nuestro el Oficial de Privacidad o enviar un correo electrónico a la dirección antes señalada.
            </span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Uso de tecnologías de rastreo en nuestro portal de internet.</b></li>
            </ol>
            <p><span>Le informamos que en nuestra página de Internet utilizamos cookies para obtener información no personal de los visitantes en línea, dichos cookies rastrean el tipo de navegador, sistema operativo y
              prestador de servicios de Internet y nos permiten llevar un control del número total de visitantes a nuestra página.
              Los tipos de cookies que podemos usar son: estadística, publicidad y seguridad. Estas tecnologías podrán deshabilitarse siguiendo los siguientes pasos: entrando a la sección de configuración de su navegador,
              en el apartado seguridad y privacidad, usted puede programar para darle aviso de cuando le son enviados cookies, o bien pueden deshabilitarse los mismos. Los datos que se obtienen de estas tecnologías de rastreo
              son: dirección IP, duración del tiempo de interacción en la página, y tipo de navegador utilizado, con la finalidad de obtener información respecto de hábitos de navegación, vínculos seguidos y páginas al
              interior de nuestro sitio que visita el equipo vinculado a la dirección IP.
            </span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Las previsiones especiales para el tratamiento de datos personales de categorías especiales de TITULARES, tales como menores de edad, personas en estado de interdicción, personas adultas mayores,
                personas con discapacidad, migrantes.</b></li>
            </ol>
            <p><span >Para las finalidades descritas en el presente Aviso de Privacidad, en caso de tratar datos personales de categorías especiales, como son: menores de edad y personas en estado de interdicción, se consideran
              las siguientes previsiones especiales:</span></p>
            <ol>
              <li><span>•	Se solicita el consentimiento expreso y por escrito en todos los casos del padre o tutor de dichos <b>TITULARES</b>,</span></li>
              <li><span>•	La verificación de la autenticidad del consentimiento otorgado por los padres o tutores, solicitando la documentación que lo acredite como tales,</span></li>
              <li><span>•	La implementación y mantenimiento de medidas de seguridad más estrictas a efecto de asegurar la confidencialidad de los datos personales de categorías especiales de <b>TITULARES</b>, y</span></li>
              <li><span>•	En el tratamiento de datos personales de los <b>TITULARES</b> de estas categorías especiales, se tendrá un especial cuidado para que la toma de decisiones sobre los mismos, no involucren puntos de vista y
                percepciones discriminatorias.</span></li>
            </ol>
            <p><span >En el caso de tratamiento de personas mayores de edad o de categorías especiales, como son: personas con discapacidad y migrantes, se considera las siguientes previsiones especiales:</span></p>
            <ol>
              <li><span>•	Se solicita el consentimiento expreso y por escrito del <b>TITULAR</b> en todos los casos.</span></li>
              <li><span>•	La implementación y mantenimiento de medidas de seguridad más estrictas a efecto de asegurar la confidencialidad de los datos personales de categorías especiales de <b>TITULARES</b>, y</span></li>
              <li><span>•	En el tratamiento de datos personales de los <b>TITULARES</b> de estas categorías especiales, se tendrá un especial cuidado para que la toma de decisiones sobre los mismos, no involucren puntos de vista y
                percepciones discriminatorias.</span></li>
            </ol>
            <p><span >&nbsp;</span></p>
            <p><span >Para el tratamiento de datos personales de categorías especiales de TITULARES, INTERprotección en todos los casos que procedan, solo realizará el tratamiento de los datos personales si se ha cumplido con las
              previsiones especiales establecidas.
              Para estos casos, en que INTERprotección requiere de su consentimiento expreso y por escrito, que se otorga al firmar el presente Aviso de Privacidad.
            </span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Seguridad de los datos personales y confidencialidad.</b></li>
            </ol>
            <p><span ><b>INTERprotección</b> implementará las medidas de seguridad técnicas, administrativas y físicas necesarias para asegurar la integridad de sus datos personales y evitar su daño, pérdida, alteración, destrucción o el
              uso, acceso o tratamiento no autorizado.
              Únicamente el personal autorizado, que ha cumplido y observado los correspondientes requisitos de confidencialidad, podrá́ participar en el tratamiento de sus datos personales.
              En caso de haber una vulneración en las medidas de seguridad con las que se resguardan los datos personales del <b>TITULAR</b> y que éstas pudieran afectarle en sus derechos patrimoniales o morales, <b>INTERprotección</b> le
              informará de forma inmediata para que el <b>TITULAR</b> tome las medidas que estime conducentes.
            </span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Derecho a la protección de sus datos personales.</b></li>
            </ol>
            <p><span>Si usted considera que su derecho a la protección de sus datos personales ha sido lesionado por alguna conducta u omisión de nuestra parte, o presume alguna violación a las disposiciones previstas en la Ley Federal de
              Protección de Datos Personales en Posesión de los Particulares, su Reglamento y demás ordenamientos aplicables, podrá interponer su inconformidad o denuncia ante el Instituto Nacional de Trasparencia, Acceso a la Información y
              Protección de Datos Personales (INAI). Para más información, le sugerimos visitar su página oficial de Internet <a style={{ color: '#039ECC' }}
                href="http://www.inai.org.mx/">www.INAI.org.mx</a>.</span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Modificaciones o actualizaciones al Aviso de Privacidad.</b></li>
            </ol>
            <p><span>En caso de que exista un cambio de este aviso de privacidad, lo haremos de su conocimiento a través de la página de INTERprotección, <a style={{ color: '#039ECC' }}
              href="http://www.inter.mx/">www.inter.mx</a> la que le recomendamos verificar con frecuencia; o solicitando el Aviso
              de Privacidad vigente al Oficial de Privacidad al correo: <a style={{ color: '#039ECC' }} href="mailto:leyproteccion@inter.mx">leyproteccion@inter.mx</a></span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Consentimiento.</b></li>
            </ol>
            <p><span>Manifiesto que he leído y entiendo el contenido y alcance del presente Aviso de Privacidad y otorgo a <b>INTERprotección</b> la autorización y mi consentimiento expreso
              y por escrito por medio de mi firma autógrafa o firma digital en el presente Aviso de Privacidad, para el tratamiento de mis datos personales, datos personales sensibles,
              patrimoniales financieros y bancarios, transferencias o remisiones, y en el caso del tratamiento de datos personales de categorías especiales de <b>TITULARES</b>, sea realizado
              conforme los términos del presente Aviso de Privacidad.
              Manifiesto y confirmo que se ha puesto a mi disposición el presente Aviso de Privacidad de forma personal y por escrito por parte de los colaboradores de <b>INTERprotección</b> a
              los cuales he proporcionado mis datos personales, o de forma directa y en formato digital a través de los medios por los cuales he proporcionado mis datos personales.
              Confirmo que recibí de <b>INTERprotección</b> el presente Aviso de Privacidad, y que su contenido se hizo de mi conocimiento de manera oportuna.
            </span></p>
            <p><span >&nbsp;</span></p>
            <p>
              <table class="default">
                <tr>
                  <td>Firma del <b>TITULAR:</b></td>
                  <td>   ______________________________</td>
                </tr>
                <tr>
                  <td>Nombre del <b>TITULAR:</b></td>
                  <td>   ______________________________</td>
                </tr>
                <tr>
                  <td>Fecha:</td>
                  <td>   ______________________________</td>
                </tr>
              </table>
            </p>
            <p><span >&nbsp;</span></p>
            <p><b><i>Última actualización: 06 junio 2023.</i></b></p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        id="aviso2"
        expanded={expanded === 'aviso2'}
        onChange={handleChange('aviso2')}
        className="avisos">
        <AccordionSummary
          aria-controls="panel2a-content"
          id="aviso2-header"
          expandIcon={expanded === `aviso2` ? <></> : <ChevronRightIcon />}
        >
          <Typography className='header-avisoinfo'><p><b>AVISO DE PRIVACIDAD INTEGRAL PARA CAPITAL HUMANO (COLABORADORES y CANDIDATOS).</b></p></Typography>
        </AccordionSummary>
        <AccordionDetails className="info">
          <Typography>
            <p><b>Casanueva Pérez, S.A.P.I. de C.V., subsidiarias y filiales (INTERprotección).</b></p>
            <p><span >&nbsp;</span></p>
            <p><b>Identidad del responsable.</b></p>
            <p><span >Casanueva Pérez, S.A.P.I. de C.V., subsidiarias y filiales en lo sucesivo (<b>INTERprotección</b>),
              en su carácter de responsable del tratamiento de sus datos personales, manifiesta que para efectos del presente
              Aviso de Privacidad su domicilio se encuentra ubicado en Miguel de Cervantes Saavedra No 301, Torre Sur, Piso 17
              Colonia Granada, Alcaldía Miguel Hidalgo, CP 11520, Ciudad de México, y con página web <u><a style={{ color: '#039ECC' }}
                href="http://www.inter.mx/">www.inter.mx</a></u></span></p>
            <p><span><b>INTERprotección</b> es el responsable del tratamiento de los datos personales que nos proporcione, los
              cuales se protegerán bajo las más estrictas medidas de seguridad a fin de garantizar su confidencialidad, protección y
              tratamiento conforme a lo dispuesto en el presente Aviso de Privacidad, la Ley Federal de Protección de Datos Personales
              en Posesión de los Particulares (LA LEY) y demás normatividad aplicable.</span></p>
            <p><span><b>INTERprotección</b></span> pone a su disposición el presente Aviso de Privacidad, con la finalidad de dar
              cumplimiento a los artículos 15, 16 y demás relativos de la LEY y a los artículos 24 al 30 y demás relativos del Reglamento
              de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (REGLAMENTO) y la normatividad que
              resulte aplicable; y al respecto le informa lo siguiente:</p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Razones sociales de INTERprotección.</b></li>
            </ol>
            <p><span ><b>INTERprotección</b>, en su carácter de responsable del tratamiento de sus datos personales, manifiesta que para efectos
              del presente Aviso de Privacidad las razones sociales cubiertas, son las siguientes: Casanueva Pérez, S.A.P.I. de C.V.,
              Centro INTER de Servicios, S.A. de C.V., INTERprotección, Agente de Seguros y de Fianzas S.A. de C.V., INTERprotección
              Guadalajara, Agente de Seguros y de Fianzas, S.A. de C.V., INTERprotección Mérida, Agente de Seguros y de Fianzas, S.A. de
              C.V., INTER Los Cabos, Agente de Seguros y de Fianzas, S.A. de C.V., Se Listo, Agente de Seguros y de Fianzas, S.A. de C.V.,
              PCP, Agente de Seguros y de Fianzas, S.A. de C.V., MK, Agente de Seguros y de Fianzas, S.A. de C.V., EJC, Agente de Seguros
              y de Fianzas, S.A.P.I. de C.V., HJK, Agente de Seguros y de Fianzas, S.A.P.I. de C.V., INTER Infraestructura, Agente de
              Seguros y de Fianzas, S.A. de C.V., INTERópolis, Agente de Seguros y de Fianzas, S.A.P.I. de C.V,.Intersecuritas, Agente de
              Seguros y de Fianzas, S.A. de C.V., Reasinter, Intermediarios de Reaseguro S.A. de C.V., Flag Assist, S.A.P.I. de C.V.,
              INTERprotección Asistencias, S.A.P.I. de C.V., Financial Lines Advisory Group, S.A.P.I. de C.V., INTER Monterrey Agente de
              Seguros y de Fianzas, S.A. de C.V., INTERprotección Sinaloa, Agente de Seguros y de Fianzas, S.A. de C.V., Unifin, Agente de
              Seguros y de Fianzas, S.A. de C.V., INTER Mota Latam, Agente de Seguros y de Fianzas, S.A.P.I. de C.V.</span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Datos personales recibidos del TITULAR.</b></li>
            </ol>
            <p><span >Para llevar a cabo las finalidades descritas en el presente Aviso de Privacidad, se solicitarán de usted los siguientes
              datos personales:</span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li>Datos generales.</li>
            </ol>
            <ol>
              <li ><span >Datos de identificación y contacto, datos de características físicas, datos familiares y de beneficiarios, datos de
                referencias, datos de pasatiempos y entretenimientos, datos laborales, académicos y profesionales, en caso de ser extranjero
                situación migratoria, país de origen y nacionalidad.</span></li>
            </ol>
            <p><span >&nbsp;</span></p>
            <ol>
              <li>Datos financieros y patrimoniales.</li>
            </ol>
            <ol>
              <li ><span >Datos financieros, datos bancarios, datos fiscales, datos crediticios, datos patrimoniales, datos de seguros y fianzas.
                Para el tratamiento de estas categorías de datos (Financieros y Patrimoniales) se requiere de su consentimiento expreso, el cual se
                otorga mediante la firma del presente Aviso de Privacidad.
              </span></li>
            </ol>
            <p><span >&nbsp;</span></p>
            <ol>
              <li>Datos sensibles.</li>
            </ol>
            <ol>
              <li ><span >Para las finalidades informadas en el presente Aviso de Privacidad utilizaremos los siguientes datos personales considerados
                como sensibles, que requieren de especial protección y se tratarán bajo las más estrictas medidas de seguridad y confidencialidad:
                Datos sensibles: estado de salud (pasado, presente y futuro), resultado de estudios clínicos, antecedentes clínicos y médicos, tipo
                de sangre, alergias, enfermedades hereditarias, congénitas o adquiridas, hábitos de consumo de alcohol, tabaco o drogas; en caso de
                requerirse para el cumplimiento de la Ley Federal para la Prevención e Identificación de Operaciones con Recursos de Procedencia Ilícita
                (LFPIORPI), o la ley aplicable respecto a la finalidad primaria establecida, situación jurídica y política propia o de familiares.
                Resultados de estudios psicométricos, pruebas de polígrafo. Las pruebas de polígrafo no se consideran un procedimiento general, solo se
                realizan en puestos clave que requieren de altos niveles de confianza y responsabilidad.
                Datos biométricos de colaboradores: Huella dactilar y reconocimiento facial. Como medida de seguridad INTERprotección ha establecido que,
                en el caso de los datos biométricos de los colaboradores se encuentren disociados, manejándose en bases de datos diferentes de las de los
                datos de identificación y contacto.
                El tratamiento de los datos personales sensibles requiere de su consentimiento expreso y por escrito, que otorga mediante la firma del
                presente Aviso de Privacidad.
              </span></li>
            </ol>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Finalidades del uso de los datos personales.</b></li>
            </ol>
            <p><span >&nbsp;</span></p>
            <ol>
              <li>Finalidades primarias candidatos.</li>
            </ol>
            <ol>
              <li><span>Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades:</span></li>
              <li><span>1. Participar en el proceso de reclutamiento y selección, por medio de: a) realizar entrevistas de trabajo del proceso de selección;
                b) validar el cumplimiento del perfil requerido; c) validar experiencia académica y laboral; d) validar conocimientos técnicos; e) validar su
                alineación a la cultura de la empresa; f) validar referencias familiares y personales; g) evaluar condiciones en las que prestará sus servicios;
                h) en caso de avanzar en el proceso de selección, estudios socioeconómicos y psicométricos; i) pruebas de polígrafo, únicamente en la fase final
                del proceso de selección de posiciones críticas que requieren de altos niveles de confianza y responsabilidad.</span>
              </li>
              <li><span>2. Grabación de llamadas o videollamadas para el seguimiento y mejora en la calidad de nuestros servicios, y en caso de vista a
                nuestras instalaciones el control del acceso y videovigilancia.</span></li>
              <li><span>3. Cumplimiento legal a los requerimientos de autoridades y dependencias del Gobierno Federal, Estatal o Local, y</span>
              </li>
              <li><span>4. Integración y almacenamiento de expedientes físicos y digitales de candidatos.</span>
              </li>
            </ol>
            <p><span >&nbsp;</span></p>
            <ol>
              <li>Finalidades primarias colaboradores.</li>
            </ol>
            <ol>
              <li><span>Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades:</span></li>
              <li><span>1. Proceso de contratación del colaborador de INTERprotección.</span></li>
              <li><span>2. Cumplimiento de derechos y obligaciones inherentes a la relación jurídica entre el colaborador e INTERproteción;</span></li>
              <li><span>3. Procesos derivados de la relación laboral establecida: a) Proceso de administración de personal; b) pago de sueldos y salarios;
                c) otorgamiento de prestaciones de seguridad social y beneficios a colaboradores; d) trámite y contratación de fianzas de fidelidad; e) trámite
                y contratación de seguros; f) cobranza en caso de incumplimiento en el pago de deudas adquiridas y debidamente acreditadas; g) cumplimiento de
                políticas y procedimientos; h) asignar y comprobar viáticos y pasajes nacionales e internacionales; i) programar acciones de capacitación;
                j)realización de pruebas de polígrafo a colaboradores en puestos clave que así esté establecido, o en caso de ocurrencia de un evento grave que
                así se determine; k) procesos de acceso y control de asistencia; l)seguimiento por medio de la realización de estudios socioeconómicos y
                psicométricos.</span>
              </li>
              <li><span>4. El envío de información a instituciones de seguridad social.</span></li>
              <li><span>5. El envío de información a instituciones de seguros y fianzas, y a empresas proveedoras de prestaciones y beneficios a colaboradores.
              </span></li>
              <li><span>6. Grabación de llamadas o videollamadas para el seguimiento y mejora en la calidad de nuestros servicios, y videovigilancia dentro
                de las instalaciones de nuestra organización.</span></li>
              <li><span>7. Cumplimiento legal a los requerimientos de autoridades y dependencias del Gobierno Federal, Estatal o Local, y a lo aplicable
                al ámbito laboral.</span></li>
              <li><span>8. Integración y almacenamiento de expedientes físicos y digitales de colaboradores.</span></li>
            </ol>
            <p><span >&nbsp;</span></p>
            <ol>
              <li>Finalidades secundarias.</li>
            </ol>
            <ol>
              <li><span>Si usted no se opone, <b>INTERprotección</b> tratará su información personal para finalidades que no son necesarias para brindarle el servicio
                solicitado, pero que nos permiten ofrecerle una mejor atención:</span></li>
            </ol>
            <p>
              <table class="default" style={{ border: '1px solid black' }}>
                <tr>
                  <td style={{ border: '1px solid black' }}>#</td>
                  <td style={{ border: '1px solid black' }}>Finalidad secundaria</td>
                  <td style={{ border: '1px solid black' }}>No deseo que se traten mis datos personales para dicha finalidad</td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', textAlign: 'center' }}>1</td>
                  <td style={{ border: '1px solid black' }}>Realizar estudios y procesos internos.</td>
                  <td style={{ border: '1px solid black', textAlign: 'center' }}>(     )</td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', textAlign: 'center' }}>2</td>
                  <td style={{ border: '1px solid black' }}>Realizar encuestas de calidad en el servicio y atención a clientes y
                    actividades encaminadas a promover y mejorar los servicios de <b>INTERprotección</b>.</td>
                  <td style={{ border: '1px solid black', textAlign: 'center' }}>(     )</td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', textAlign: 'center' }}>3</td>
                  <td style={{ border: '1px solid black' }}>Para fines mercadotécnicos, publicitarios o de prospección comercial de
                    nuestros productos o servicios.</td>
                  <td style={{ border: '1px solid black', textAlign: 'center' }}>(     )</td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', textAlign: 'center' }}>4</td>
                  <td style={{ border: '1px solid black' }}>Para fines mercadotécnicos, publicitarios o de prospección comercial de
                    alianzas comerciales.</td>
                  <td style={{ border: '1px solid black', textAlign: 'center' }}>(     )</td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', textAlign: 'center' }}>5</td>
                  <td style={{ border: '1px solid black' }}>Enviarle información sobre productos, servicios y boletines del sector
                    de seguros, fianzas y financiero.</td>
                  <td style={{ border: '1px solid black', textAlign: 'center' }}>(     )</td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', textAlign: 'center' }}>6</td>
                  <td style={{ border: '1px solid black' }}>Fines estadísticos y de estudios del mercado y la industria.</td>
                  <td style={{ border: '1px solid black', textAlign: 'center' }}>(     )</td>
                </tr>
                <tr>
                  <td style={{ border: '1px solid black', textAlign: 'center' }}>7</td>
                  <td style={{ border: '1px solid black' }}>Para el intercambio curricular de candidatos.</td>
                  <td style={{ border: '1px solid black', textAlign: 'center' }}>(     )</td>
                </tr>
              </table>
            </p>
            <p><span >Le informamos que en caso de que no desee que sus datos personales se traten para estas finalidades
              secundarias, usted puede indicarlo al momento de la presentación del presente Aviso de Privacidad, o tiene un plazo
              de 5 (cinco) días hábiles para comunicarlo al correo: <u><a href="mailto:leyproteccion@inter.mx" style={{ color: '#039ECC' }}>
                leyproteccion@inter.mx</a></u><br />
              La negativa del uso de sus datos personales para las finalidades secundarias no será un motivo para que le neguemos
              la prestación de los servicios, productos o relación jurídica que solicita de parte de nosotros.</span>
            </p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Transferencias o remisiones de datos.</b></li>
            </ol>
            <p><b>INTERprotección</b><span > conforme a lo que se establece en el artículo 37 de LA LEY, le informa que las transferencias
              de datos personales que puede efectuar sin su consentimiento son las siguientes:</span></p>
            <p><b>I.</b><span > Cuando la transferencia esté prevista en una Ley o Tratado en los que México sea parte.</span></p>
            <p><b>II.</b><span > Cuando la transferencia sea necesaria para la prevención o el diagnóstico médico, la prestación de
              asistencia sanitaria, tratamiento médico o la gestión de servicios sanitarios.</span></p>
            <p><b>III.</b><span > Cuando la transferencia sea efectuada a sociedades controladoras, subsidiarias o afiliadas bajo
              el control común de <b>INTERprotección</b>, o a una sociedad matriz o a cualquier sociedad del mismo grupo de <b>INTERprotección</b> que
              opere bajo los mismos procesos y políticas internas.</span></p>
            <p><b>IV.</b><span > Cuando la transferencia sea necesaria por virtud de un contrato celebrado o por celebrar en interés del <b>TITULAR</b>,
              por <b>INTERprotección</b> y un <b>TERCERO</b>.</span></p>
            <p><b>V.</b><span > Cuando la transferencia sea necesaria o legalmente exigida para la salvaguarda de un interés público, o para la
              procuración o administración de justicia.</span></p>
            <p><b>VI.</b><span > Cuando la transferencia sea precisa para el reconocimiento, ejercicio o defensa de un derecho en un proceso judicial, y
            </span></p>
            <p><b>VII.</b><span > VII. Cuando la transferencia sea precisa para el mantenimiento o cumplimiento de una relación jurídica entre el de
              <b>INTERprotección</b>, y el <b>TITULAR</b>.</span></p>
            <p><span >&nbsp;</span></p>
            <p><span>Se hace de su conocimiento que <b>INTERprotección</b>, adicional a las transferencias que realiza y que no requieren de su consentimiento,
              podrá llevar a cabo la transferencia de sus datos personales que a continuación se indican: </span>
            </p>
            <p>
              <table class="default">
                <tr>
                  <td style={{ borderTop: '1px solid black', borderBottom: '1px solid black' }}><b>Terceros</b></td>
                  <td style={{ borderTop: '1px solid black', borderBottom: '1px solid black' }}><b>FINALIDAD</b></td>
                </tr>
                <tr>
                  <td style={{ borderTop: '1px solid black' }}><b>Aseguradoras, reaseguradoras o afianzadoras.</b></td>
                  <td style={{ borderTop: '1px solid black' }}>
                    <p><span>• Como prestación laboral de los colaboradores la contratación, administración y cancelación de seguros, y fianzas.</span></p>
                    <p><span>• Cuando los colaboradores soliciten la prestación de servicios de intermediación y asesoría para la contratación, administración
                      y cancelación de seguros, y fianzas.</span></p>
                    <p><span>• Cuando los colaboradores soliciten el apoyo en trámite y seguimiento de siniestros.</span></p>
                  </td>
                </tr>
                <tr>
                  <td style={{ borderTop: '1px solid black' }}><b>Empresas proveedoras de prestaciones y beneficios a colaboradores.</b></td>
                  <td style={{ borderTop: '1px solid black' }}>
                    <p><span>• Como prestación laboral de los colaboradores.</span></p>
                  </td>
                </tr>
                <tr>
                  <td style={{ borderTop: '1px solid black' }}><b>Líneas aéreas y agencias de viajes.</b></td>
                  <td style={{ borderTop: '1px solid black' }}>
                    <p><span>• Para la contratación de viajes de colaboradores.</span></p>
                  </td>
                </tr>
                <tr>
                  <td style={{ borderTop: '1px solid black' }}><b>Grupos de intercambio curricular.</b></td>
                  <td style={{ borderTop: '1px solid black' }}>
                    <p><span>• Intercambio curricular de candidatos.</span></p>
                  </td>
                </tr>
                <tr>
                  <td style={{ borderTop: '1px solid black', borderBottom: '1px solid black' }}><b>Prestadores de servicios certificados para la realización de
                    estudios socioeconómicos y psicométricos.</b></td>
                  <td style={{ borderTop: '1px solid black', borderBottom: '1px solid black' }}>
                    <p><span>• Para la realización de estudios socioeconómicos y psicométricos de candidatos y colaboradores.</span></p>
                  </td>
                </tr>
                <tr>
                  <td style={{ borderTop: '1px solid black', borderBottom: '1px solid black' }}><b>Prestadores de servicios autorizados para la realización de
                    pruebas de polígrafo.</b></td>
                  <td style={{ borderTop: '1px solid black', borderBottom: '1px solid black' }}>
                    <p><span>• Pruebas de polígrafo para posiciones criticas de candidatos y colaboradores.</span></p>
                  </td>
                </tr>
              </table>
            </p>
            <p><span>Para estas transferencias requerimos de su consentimiento expreso y por escrito, que usted otorga al firmar el presente Aviso de
              Privacidad.</span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Lineamientos y limitaciones en las transferencias o remisiones de datos personales.</b></li>
            </ol>
            <p><span>En los casos que <b>INTERprotección</b> comparte su información personal, le informa que se establecen y seguirán los siguientes lineamientos y limitaciones con base a lo establecido en
              la LFPDPPP, su reglamento y normatividad vigente: </span>
            </p>
            <p><span>• <b>INTERprotección</b> no compartirá su información personal a destinatarios distintos a los establecidos en el presente Aviso de Privacidad.</span></p>
            <p><span>• En todos los casos que <b>INTERprotección</b> realice transferencias a terceros responsables o remisiones a encargados comunicará a los mismos el presente Aviso de Privacidad.</span></p>
            <p><span>• Se estabecerá con los destinatarios por medio de cláusulas contractuales, lo siguiente:</span></p>
            <p><span>o Que el tratamiento de los datos personales compartidos solo se realice para las finalidades establecidas en el presente Aviso de Privacidad.</span></p>
            <p><span>o Que los datos personales compartidos se traten conforme a lo establecido en la LEY, su REGLAMENTO y demás ordenamientos aplicables.</span></p>
            <p><span>o Que los datos personales compartidos en ningún caso se pueden comunicar a ningún tercero.</span></p>
            <p><span>• En el caso de que <b>INTERprotección</b> necesite compartir su información personal a terceros distintos o para dar tratamiento para finalidades distintas a las establecidos en el presente
              Aviso de Privacidad y que se requiera de su consentimiento, pondrá a su disposición el nuevo Aviso de Privacidad, previo a compartir o dar tratamiento de sus datos personales, y solicitará su
              consentimiento.</span></p>
            <p><span >&nbsp;</span></p>
            <p><span >Se recomienda que usted como <b>TITULAR</b> de los datos personales, notifique a <b>INTERprotección</b> sobre cualquier cambio respecto su domicilio físico y/o dirección de correo electrónico,
              con la finalidad de mantener actualizados sus datos personales para informarle cualquier cambio o modificación respecto del contenido en el Aviso de Privacidad.</span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Medios para ejercer los derechos de acceso, rectificación, cancelación y oposición (ARCO) de los datos personales.</b></li>
            </ol>
            <p><span>Usted podrá ejercer sus derechos de acceso, rectificación, cancelación u oposición de sus datos personales (Derechos ARCO) directamente con el Oficial de Privacidad en nuestro domicilio ubicado
              en Avenida Miguel de Cervantes Saavedra No 301, Torre Sur, Piso 17 Colonia Granada, Alcaldía Miguel Hidalgo, CP 11520, Ciudad de México, o bien, a través del correo: <a style={{ color: '#039ECC' }}
                href="mailto:leyproteccion@inter.mx">leyproteccion@inter.mx</a><br />
              Si desea conocer el procedimiento para el ejercicio de estos derechos, puede solicitarlo directamente a nuestro el Oficial de Privacidad o enviar un correo electrónico a la dirección antes señalada.<br />
              Es importante comunicarle que <b>INTERprotección</b> podrá negar el acceso para que usted ejerza sus Derechos ARCO en los siguientes supuestos:
            </span></p>
            <ol>
              <li><span>1. Cuando usted no sea el <b>TITULAR</b> de los datos personales, o su representante legal no esté debidamente acreditado para ejercer por medio de él, sus Derechos ARCO.</span></li>
              <li><span>2. Cuando en nuestra base de datos no se encuentren sus datos personales.</span></li>
              <li><span>3. Cuando se lesionen los derechos de un tercero.</span></li>
              <li><span>4. Cuando exista un impedimento legal o la resolución de una autoridad competente, que restrinja sus Derechos ARCO, y</span></li>
              <li><span>5. Cuando la Rectificación, Cancelación u Oposición haya sido previamente realizada.</span></li>
            </ol>
            <p><span><b>INTERprotección</b> siempre le informará el motivo de su decisión y se la comunicará a usted o en caso, a su representante legal por correo electrónico, acompañando, en su caso, las pruebas que
              resulten pertinentes.<br />
              El ejercicio de los Derechos ARCO será gratuito.</span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Mecanismo para revocación del consentimiento.</b></li>
            </ol>
            <p><span >Usted podrá revocar su consentimiento para que <b>INTERprotección</b> deje de llevar a cabo el tratamiento de sus datos personales directamente con el Oficial de Privacidad en nuestro domicilio ubicado en
              Avenida Miguel de Cervantes Saavedra No 301, Torre Sur, Piso 17 Colonia Granada, Alcaldía Miguel Hidalgo, CP 11520, Ciudad de México, o bien, a través del correo: <a style={{ color: '#039ECC' }}
                href="mailto:leyproteccion@inter.mx">leyproteccion@inter.mx</a><br />
              Si desea conocer el procedimiento para revocar su consentimiento, puede solicitarlo directamente a nuestro el Oficial de Privacidad o enviar un correo electrónico a la dirección antes señalada.
            </span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Uso de tecnologías de rastreo en nuestro portal de internet.</b></li>
            </ol>
            <p><span>Le informamos que en nuestra página de Internet utilizamos cookies para obtener información <b>no personal</b> de los visitantes en línea, dichos cookies rastrean el tipo de navegador, sistema operativo y
              prestador de servicios de Internet y nos permiten llevar un control del número total de visitantes a nuestra página.<br />
              Los tipos de cookies que podemos usar son: estadística, publicidad y seguridad. Estas tecnologías podrán deshabilitarse siguiendo los siguientes pasos: entrando a la sección de configuración de su navegador,
              en el apartado seguridad y privacidad, usted puede programar para darle aviso de cuando le son enviados cookies, o bien pueden deshabilitarse los mismos. Los datos que se obtienen de estas tecnologías de rastreo
              son: dirección IP, duración del tiempo de interacción en la página, y tipo de navegador utilizado, con la finalidad de obtener información respecto de hábitos de navegación, vínculos seguidos y páginas al
              interior de nuestro sitio que visita el equipo vinculado a la dirección IP.
            </span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Las previsiones especiales para el tratamiento de datos personales de categorías especiales de TITULARES, tales como personas adultas mayores, personas con discapacidad, migrantes.</b></li>
            </ol>
            <p><span>En el caso de tratamiento de personas mayores de edad o de categorías especiales, como son: personas con discapacidad y migrantes, se considera las siguientes previsiones especiales:</span></p>
            <ol>
              <li><span>• Se solicita el consentimiento expreso y por escrito del <b>TITULAR</b> en todos los casos.</span></li>
              <li><span>•	La implementación y mantenimiento de medidas de seguridad más estrictas a efecto de asegurar la confidencialidad de los datos personales de categorías especiales de <b>TITULARES</b>, y</span></li>
              <li><span>•	En el tratamiento de datos personales de los <b>TITULARES</b> de estas categorías especiales, se tendrá un especial cuidado para que la toma de decisiones sobre los mismos, no involucren puntos de vista y
                percepciones discriminatorias.</span></li>
            </ol>
            <p><span >&nbsp;</span></p>
            <p><span >Para el tratamiento de datos personales de categorías especiales de <b>TITULARES</b>, <b>INTERprotección</b> en todos los casos que procedan, solo realizará el tratamiento de los datos personales si se ha cumplido
              con las previsiones especiales establecidas.<br />
              Para estos casos, <b>INTERprotección</b> requiere de su consentimiento expreso y por escrito, que se otorga al firmar el presente Aviso de Privacidad.
            </span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Seguridad de los datos personales y confidencialidad.</b></li>
            </ol>
            <p><span ><b>INTERprotección</b> implementará las medidas de seguridad técnicas, administrativas y físicas necesarias para asegurar la integridad de sus datos personales y evitar su daño, pérdida, alteración, destrucción o el
              uso, acceso o tratamiento no autorizado.<br />
              Únicamente el personal autorizado, que ha cumplido y observado los correspondientes requisitos de confidencialidad, podrá́ participar en el tratamiento de sus datos personales.<br />
              En caso de haber una vulneración en las medidas de seguridad con las que se resguardan los datos personales del <b>TITULAR</b> y que éstas pudieran afectarle en sus derechos patrimoniales o morales, <b>INTERprotección</b> le
              informará de forma inmediata para que el <b>TITULAR</b> tome las medidas que estime conducentes.
            </span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Derecho a la protección de sus datos personales.</b></li>
            </ol>
            <p><span>Si usted considera que su derecho a la protección de sus datos personales ha sido lesionado por alguna conducta u omisión de nuestra parte, o presume alguna violación a las disposiciones previstas en la Ley Federal de
              Protección de Datos Personales en Posesión de los Particulares, su Reglamento y demás ordenamientos aplicables, podrá interponer su inconformidad o denuncia ante el Instituto Nacional de Trasparencia, Acceso a la Información y
              Protección de Datos Personales (INAI). Para más información, le sugerimos visitar su página oficial de Internet <a style={{ color: '#039ECC' }}
                href="http://www.inai.org.mx/">www.INAI.org.mx</a>.</span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Modificaciones o actualizaciones al Aviso de Privacidad.</b></li>
            </ol>
            <p><span>En caso de que exista un cambio de este aviso de privacidad, lo haremos de su conocimiento a través de la página de <b>INTERprotección</b>, <a style={{ color: '#039ECC' }}
              href="http://www.inter.mx/">www.inter.mx</a> la que le recomendamos verificar con frecuencia; o solicitando el Aviso
              de Privacidad vigente al Oficial de Privacidad al correo: <a style={{ color: '#039ECC' }} href="mailto:leyproteccion@inter.mx">leyproteccion@inter.mx</a></span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Consentimiento.</b></li>
            </ol>
            <p><span>Manifiesto que he leído y entiendo el contenido y alcance del presente Aviso de Privacidad y otorgo a <b>INTERprotección</b> la autorización y mi consentimiento expreso
              y por escrito por medio de mi firma autógrafa o firma digital en el presente Aviso de Privacidad, para el tratamiento de mis datos personales, datos personales sensibles,
              patrimoniales financieros y bancarios, transferencias o remisiones, y en el caso del tratamiento de datos personales de categorías especiales de <b>TITULARES</b>, sea realizado
              conforme los términos del presente Aviso de Privacidad.<br />
              Manifiesto y confirmo que se ha puesto a mi disposición el presente Aviso de Privacidad de forma personal y por escrito por parte de los colaboradores de <b>INTERprotección</b> a
              los cuales he proporcionado mis datos personales, o de forma directa y en formato digital a través de los medios por los cuales he proporcionado mis datos personales.<br />
              Confirmo que recibí de <b>INTERprotección</b> el presente Aviso de Privacidad, y que su contenido se hizo de mi conocimiento de manera oportuna.
            </span></p>
            <p><span >&nbsp;</span></p>
            <p>
              <table class="default">
                <tr>
                  <td>Firma del <b>TITULAR:</b></td>
                  <td>   ______________________________</td>
                </tr>
                <tr>
                  <td>Nombre del <b>TITULAR:</b></td>
                  <td>   ______________________________</td>
                </tr>
                <tr>
                  <td>Fecha:</td>
                  <td>   ______________________________</td>
                </tr>
              </table>
            </p>
            <p><span >&nbsp;</span></p>
            <p><b><i>Última actualización: 06 junio 2023.</i></b></p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        id="aviso3"
        expanded={expanded === 'aviso3'}
        onChange={handleChange('aviso3')}
        className="avisos">
        <AccordionSummary
          aria-controls="panel2a-content"
          id="aviso3-header"
          expandIcon={expanded === `aviso3` ? <></> : <ChevronRightIcon />}
        >
          <Typography className='header-avisoinfo'><p><b>AVISO DE PRIVACIDAD INTEGRAL PARA PARA REGISTRO DE ENTRADAS Y SALIDAS A LAS INSTALACIONES (VISITANTES A INSTALACIONES).</b></p></Typography>
        </AccordionSummary>
        <AccordionDetails className="info">
          <Typography>
            <p><b>Casanueva Pérez, S.A.P.I. de C.V., subsidiarias y filiales (INTERprotección).</b></p>
            <p><span >&nbsp;</span></p>
            <p><b>Identidad del responsable.</b></p>
            <p><span >Casanueva Pérez, S.A.P.I. de C.V., subsidiarias y filiales en lo sucesivo (<b>INTERprotección</b>),
              en su carácter de responsable del tratamiento de sus datos personales, manifiesta que para efectos del presente
              Aviso de Privacidad su domicilio se encuentra ubicado en Miguel de Cervantes Saavedra No 301, Torre Sur, Piso 17
              Colonia Granada, Alcaldía Miguel Hidalgo, CP 11520, Ciudad de México, y con página web <u><a style={{ color: '#039ECC' }}
                href="http://www.inter.mx/">www.inter.mx</a></u></span></p>
            <p><span><b>INTERprotección</b> es el responsable del tratamiento de los datos personales que nos proporcione, los
              cuales se protegerán bajo las más estrictas medidas de seguridad a fin de garantizar su confidencialidad, protección y
              tratamiento conforme a lo dispuesto en el presente Aviso de Privacidad, la Ley Federal de Protección de Datos Personales
              en Posesión de los Particulares (LA LEY) y demás normatividad aplicable.</span></p>
            <p><span><b>INTERprotección</b></span> pone a su disposición el presente Aviso de Privacidad, con la finalidad de dar
              cumplimiento a los artículos 15, 16 y demás relativos de la LEY y a los artículos 24 al 30 y demás relativos del Reglamento
              de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (REGLAMENTO) y la normatividad que
              resulte aplicable; y al respecto le informa lo siguiente:</p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Razones sociales de INTERprotección.</b></li>
            </ol>
            <p><span ><b>INTERprotección</b>, en su carácter de responsable del tratamiento de sus datos personales, manifiesta que para efectos
              del presente Aviso de Privacidad las razones sociales cubiertas, son las siguientes: Casanueva Pérez, S.A.P.I. de C.V.,
              Centro INTER de Servicios, S.A. de C.V., INTERprotección, Agente de Seguros y de Fianzas S.A. de C.V., INTERprotección
              Guadalajara, Agente de Seguros y de Fianzas, S.A. de C.V., INTERprotección Mérida, Agente de Seguros y de Fianzas, S.A. de
              C.V., INTER Los Cabos, Agente de Seguros y de Fianzas, S.A. de C.V., Se Listo, Agente de Seguros y de Fianzas, S.A. de C.V.,
              PCP, Agente de Seguros y de Fianzas, S.A. de C.V., MK, Agente de Seguros y de Fianzas, S.A. de C.V., EJC, Agente de Seguros
              y de Fianzas, S.A.P.I. de C.V., HJK, Agente de Seguros y de Fianzas, S.A.P.I. de C.V., INTER Infraestructura, Agente de
              Seguros y de Fianzas, S.A. de C.V., INTERópolis, Agente de Seguros y de Fianzas, S.A.P.I. de C.V,.Intersecuritas, Agente de
              Seguros y de Fianzas, S.A. de C.V., Reasinter, Intermediarios de Reaseguro S.A. de C.V., Flag Assist, S.A.P.I. de C.V.,
              INTERprotección Asistencias, S.A.P.I. de C.V., Financial Lines Advisory Group, S.A.P.I. de C.V., INTER Monterrey Agente de
              Seguros y de Fianzas, S.A. de C.V., INTERprotección Sinaloa, Agente de Seguros y de Fianzas, S.A. de C.V., Unifin, Agente de
              Seguros y de Fianzas, S.A. de C.V., INTER Mota Latam, Agente de Seguros y de Fianzas, S.A.P.I. de C.V.</span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Datos personales recibidos del TITULAR.</b></li>
            </ol>
            <p><span >Para llevar a cabo las finalidades descritas en el presente Aviso de Privacidad, se solicitarán de usted los siguientes
              datos personales:</span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li>Datos generales.</li>
            </ol>
            <ol>
              <li ><span >Datos de Identificación y contacto.</span></li>
            </ol>
            <p><span >&nbsp;</span></p>
            <ol>
              <li>Datos sensibles.</li>
            </ol>
            <ol>
              <li ><span >Se informa que no se solicitarán datos personales sensibles, para el presente aviso de privacidad</span></li>
            </ol>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Finalidades del uso de los datos personales.</b></li>
            </ol>
            <p><span >&nbsp;</span></p>
            <ol>
              <li>Finalidades primarias.</li>
            </ol>
            <ol>
              <li><span>Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades:</span></li>
              <li><span>1. Control de acceso a las instalaciones de INTERprotección, cuyo tratamiento forma parte de las medidas de seguridad
                adoptadas al interior de la organización.</span>
              </li>
            </ol>
            <p><span >&nbsp;</span></p>
            <ol>
              <li>Finalidades secundarias.</li>
            </ol>
            <ol>
              <li><span>Se informa que no se establecen finalidades secundarias de <b>TITULARES</b> videograbados en instalaciones de INTERprotección,
                para el presente aviso de privacidad.</span></li>
            </ol>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Transferencias o remisiones de datos.</b></li>
            </ol>
            <p><b>INTERprotección</b><span > conforme a lo que se establece en el artículo 37 de LA LEY, le informa que las transferencias
              de datos personales que puede efectuar sin su consentimiento son las siguientes:</span></p>
            <p><b>I.</b><span > Cuando la transferencia esté prevista en una Ley o Tratado en los que México sea parte.</span></p>
            <p><b>II.</b><span > Cuando la transferencia sea necesaria para la prevención o el diagnóstico médico, la prestación de
              asistencia sanitaria, tratamiento médico o la gestión de servicios sanitarios.</span></p>
            <p><b>III.</b><span > Cuando la transferencia sea efectuada a sociedades controladoras, subsidiarias o afiliadas bajo
              el control común de <b>INTERprotección</b>, o a una sociedad matriz o a cualquier sociedad del mismo grupo de <b>INTERprotección</b> que
              opere bajo los mismos procesos y políticas internas.</span></p>
            <p><b>IV.</b><span > Cuando la transferencia sea necesaria por virtud de un contrato celebrado o por celebrar en interés del <b>TITULAR</b>,
              por <b>INTERprotección</b> y un <b>TERCERO</b>.</span></p>
            <p><b>V.</b><span > Cuando la transferencia sea necesaria o legalmente exigida para la salvaguarda de un interés público, o para la
              procuración o administración de justicia.</span></p>
            <p><b>VI.</b><span > Cuando la transferencia sea precisa para el reconocimiento, ejercicio o defensa de un derecho en un proceso judicial, y
            </span></p>
            <p><b>VII.</b><span > Cuando la transferencia sea precisa para el mantenimiento o cumplimiento de una relación jurídica entre el de
              <b>INTERprotección</b>, y el <b>TITULAR</b>.</span></p>
            <p><span >&nbsp;</span></p>
            <p><span>Se hace de su conocimiento que <b>INTERprotección</b>, no realiza a cabo ninguna transferencia de sus datos personales que requiera
              de su consentimiento y por tanto que deba de informarse. </span>
            </p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Lineamientos y limitaciones en las transferencias o remisiones de datos personales.</b></li>
            </ol>
            <p><span>En los casos que <b>INTERprotección</b> comparte su información personal, le informa que se establecen y seguirán los siguientes lineamientos y limitaciones con base a lo establecido en
              la LFPDPPP, su reglamento y normatividad vigente: </span>
            </p>
            <p><span>• <b>INTERprotección</b> no compartirá su información personal a destinatarios distintos a los establecidos en el presente Aviso de Privacidad.</span></p>
            <p><span>• En todos los casos que <b>INTERprotección</b> realice transferencias a terceros responsables o remisiones a encargados comunicará a los mismos el presente Aviso de Privacidad.</span></p>
            <p><span>• Se estabecerá con los destinatarios por medio de cláusulas contractuales, lo siguiente:</span></p>
            <p><span>o Que el tratamiento de los datos personales compartidos solo se realice para las finalidades establecidas en el presente Aviso de Privacidad.</span></p>
            <p><span>o Que los datos personales compartidos se traten conforme a lo establecido en la LEY, su REGLAMENTO y demás ordenamientos aplicables.</span></p>
            <p><span>o Que los datos personales compartidos en ningún caso se pueden comunicar a ningún tercero.</span></p>
            <p><span>• En el caso de que <b>INTERprotección</b> necesite compartir su información personal a terceros distintos o para dar tratamiento para finalidades distintas a las establecidos en el presente
              Aviso de Privacidad y que se requiera de su consentimiento, pondrá a su disposición el nuevo Aviso de Privacidad, previo a compartir o dar tratamiento de sus datos personales, y solicitará su
              consentimiento.</span></p>
            <p><span >&nbsp;</span></p>
            <p><span >Se recomienda que usted como <b>TITULAR</b> de los datos personales, notifique a <b>INTERprotección</b> sobre cualquier cambio respecto su domicilio físico y/o dirección de correo electrónico,
              con la finalidad de mantener actualizados sus datos personales para informarle cualquier cambio o modificación respecto del contenido en el Aviso de Privacidad.</span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Medios para ejercer los derechos de acceso, rectificación, cancelación y oposición (ARCO) de los datos personales.</b></li>
            </ol>
            <p><span>Usted podrá ejercer sus derechos de acceso, rectificación, cancelación u oposición de sus datos personales (Derechos ARCO) directamente con el Oficial de Privacidad en nuestro domicilio ubicado
              en Avenida Miguel de Cervantes Saavedra No 301, Torre Sur, Piso 17 Colonia Granada, Alcaldía Miguel Hidalgo, CP 11520, Ciudad de México, o bien, a través del correo: <u><a style={{ color: '#039ECC' }}
                href="mailto:leyproteccion@inter.mx">leyproteccion@inter.mx</a></u><br />
              Si desea conocer el procedimiento para el ejercicio de estos derechos, puede solicitarlo directamente a nuestro Oficial de Privacidad o enviar un correo electrónico a la dirección antes señalada.<br />
              Es importante comunicarle que <b>INTERprotección</b> podrá negar el acceso para que usted ejerza sus Derechos ARCO en los siguientes supuestos:
            </span></p>
            <ol>
              <li><span>1. Cuando usted no sea el <b>TITULAR</b> de los datos personales, o su representante legal no esté debidamente acreditado para ejercer por medio de él, sus Derechos ARCO.</span></li>
              <li><span>2. Cuando en nuestra base de datos no se encuentren sus datos personales.</span></li>
              <li><span>3. Cuando se lesionen los derechos de un tercero.</span></li>
              <li><span>4. Cuando exista un impedimento legal o la resolución de una autoridad competente, que restrinja sus Derechos ARCO, y</span></li>
              <li><span>5. Cuando la Rectificación, Cancelación u Oposición haya sido previamente realizada.</span></li>
            </ol>
            <p><span><b>INTERprotección</b> siempre le informará el motivo de su decisión y se la comunicará a usted o en caso, a su representante legal por correo electrónico, acompañando, en su caso, las pruebas que
              resulten pertinentes.<br />
              El ejercicio de los Derechos ARCO será gratuito.</span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Mecanismo para revocación del consentimiento.</b></li>
            </ol>
            <p><span >Usted podrá revocar su consentimiento para que <b>INTERprotección</b> deje de llevar a cabo el tratamiento de sus datos personales directamente con el Oficial de Privacidad en nuestro domicilio ubicado en
              Avenida Miguel de Cervantes Saavedra No 301, Torre Sur, Piso 17 Colonia Granada, Alcaldía Miguel Hidalgo, CP 11520, Ciudad de México, o bien, a través del correo: <u><a style={{ color: '#039ECC' }}
                href="mailto:leyproteccion@inter.mx">leyproteccion@inter.mx</a></u><br />
              Si desea conocer el procedimiento para revocar su consentimiento, puede solicitarlo directamente a nuestro Oficial de Privacidad o enviar un correo electrónico a la dirección antes señalada.
            </span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Uso de tecnologías de rastreo en nuestro portal de internet.</b></li>
            </ol>
            <p><span>Le informamos que en nuestra página de Internet utilizamos cookies para obtener información <b>no personal</b> de los visitantes en línea, dichos cookies rastrean el tipo de navegador, sistema operativo y
              prestador de servicios de Internet y nos permiten llevar un control del número total de visitantes a nuestra página.<br />
              Los tipos de cookies que podemos usar son: estadística, publicidad y seguridad. Estas tecnologías podrán deshabilitarse siguiendo los siguientes pasos: entrando a la sección de configuración de su navegador,
              en el apartado seguridad y privacidad, usted puede programar para darle aviso de cuando le son enviados cookies, o bien pueden deshabilitarse los mismos. Los datos que se obtienen de estas tecnologías de rastreo
              son: dirección IP, duración del tiempo de interacción en la página, y tipo de navegador utilizado, con la finalidad de obtener información respecto de hábitos de navegación, vínculos seguidos y páginas al
              interior de nuestro sitio que visita el equipo vinculado a la dirección IP.
            </span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Las previsiones especiales para el tratamiento de datos personales de categorías especiales de TITULARES, tales como menores de edad, personas en estado de interdicción, personas adultas mayores, personas
                con discapacidad, migrantes.</b></li>
            </ol>
            <p><span>Para las finalidades descritas en el presente Aviso de Privacidad, en caso de tratar datos personales de categorías especiales, como son: menores de edad y personas en estado de interdicción, se consideran
              las siguientes previsiones especiales:</span></p>
            <ol>
              <li><span>• Se solicita el consentimiento expreso y por escrito en todos los casos del padre o tutor de dichos <b>TITULARES</b>.</span></li>
              <li><span>•	La verificación de la autenticidad del consentimiento otorgado por los padres o tutores, solicitando la documentación que lo acredite como tales.</span></li>
              <li><span>•	La implementación y mantenimiento de medidas de seguridad más estrictas a efecto de asegurar la confidencialidad de los datos personales de categorías especiales de <b>TITULARES</b>, y</span></li>
              <li><span>•	En el tratamiento de datos personales de los <b>TITULARES</b> de estas categorías especiales, se tendrá un especial cuidado para que la toma de decisiones sobre los mismos, no involucren puntos de vista y
                percepciones discriminatorias.</span></li>
            </ol>
            <p><span>En el caso de tratamiento de personas mayores de edad o de categorías especiales, como son: personas con discapacidad y migrantes, se considera las siguientes previsiones especiales:</span></p>
            <ol>
              <li><span>•	Se solicita el consentimiento expreso y por escrito del <b>TITULAR</b> en todos los casos.</span></li>
              <li><span>•	La implementación y mantenimiento de medidas de seguridad más estrictas a efecto de asegurar la confidencialidad de los datos personales de categorías especiales de <b>TITULARES</b>, y</span></li>
              <li><span>•	En el tratamiento de datos personales de los <b>TITULARES</b> de estas categorías especiales, se tendrá un especial cuidado para que la toma de decisiones sobre los mismos, no involucren puntos de vista y
                percepciones discriminatorias.</span></li>
            </ol>
            <p><span>Para el tratamiento de datos personales de categorías especiales de <b>TITULARES</b>, <b>INTERprotección</b> en todos los casos que procedan, solo realizará el tratamiento de los datos personales si se ha cumplido con las
              previsiones especiales establecidas.<br />
              Para estos casos, <b>INTERprotección</b> requiere de su consentimiento expreso y por escrito, que se otorga al firmar el presente Aviso de Privacidad.
            </span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Seguridad de los datos personales y confidencialidad.</b></li>
            </ol>
            <p><span ><b>INTERprotección</b> implementará las medidas de seguridad técnicas, administrativas y físicas necesarias para asegurar la integridad de sus datos personales y evitar su daño, pérdida, alteración, destrucción o el
              uso, acceso o tratamiento no autorizado.<br />
              Únicamente el personal autorizado, que ha cumplido y observado los correspondientes requisitos de confidencialidad, podrá́ participar en el tratamiento de sus datos personales.<br />
              En caso de haber una vulneración en las medidas de seguridad con las que se resguardan los datos personales del <b>TITULAR</b> y que éstas pudieran afectarle en sus derechos patrimoniales o morales, <b>INTERprotección</b> le
              informará de forma inmediata para que el <b>TITULAR</b> tome las medidas que estime conducentes.
            </span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Derecho a la protección de sus datos personales.</b></li>
            </ol>
            <p><span>Si usted considera que su derecho a la protección de sus datos personales ha sido lesionado por alguna conducta u omisión de nuestra parte, o presume alguna violación a las disposiciones previstas en la Ley Federal de
              Protección de Datos Personales en Posesión de los Particulares, su Reglamento y demás ordenamientos aplicables, podrá interponer su inconformidad o denuncia ante el Instituto Nacional de Trasparencia, Acceso a la Información y
              Protección de Datos Personales (INAI). Para más información, le sugerimos visitar su página oficial de Internet <u><a style={{ color: '#039ECC' }}
                href="http://www.inai.org.mx/">www.INAI.org.mx</a></u>.</span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Modificaciones o actualizaciones al Aviso de Privacidad.</b></li>
            </ol>
            <p><span>En caso de que exista un cambio de este aviso de privacidad, lo haremos de su conocimiento a través de la página de <b>INTERprotección</b>, <u><a style={{ color: '#039ECC' }}
              href="http://www.inter.mx/">www.inter.mx</a></u> la que le recomendamos verificar con frecuencia; o solicitando el Aviso
              de Privacidad vigente al Oficial de Privacidad al correo: <ul><a style={{ color: '#039ECC' }} href="mailto:leyproteccion@inter.mx">leyproteccion@inter.mx</a></ul></span></p>
            <p><span >&nbsp;</span></p>
            <p><b><i>Última actualización: 06 junio 2023.</i></b></p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        id="aviso4"
        expanded={expanded === 'aviso4'}
        onChange={handleChange('aviso4')}
        className="avisos">
        <AccordionSummary
          aria-controls="panel2a-content"
          id="aviso4-header"
          expandIcon={expanded === `aviso4` ? <></> : <ChevronRightIcon />}
        >
          <Typography className='header-avisoinfo'><p><b>AVISO DE PRIVACIDAD INTEGRAL PARA VIDEOVIGILANCIA INSTALACIONES INTERPROTECCION (TITULARES GRABADOS).</b></p></Typography>
        </AccordionSummary>
        <AccordionDetails className="info">
          <Typography>
            <p><b>Casanueva Pérez, S.A.P.I. de C.V., subsidiarias y filiales (INTERprotección).</b></p>
            <p><span >&nbsp;</span></p>
            <p><b>Identidad del responsable.</b></p>
            <p><span >Casanueva Pérez, S.A.P.I. de C.V., subsidiarias y filiales en lo sucesivo (<b>INTERprotección</b>),
              en su carácter de responsable del tratamiento de sus datos personales, manifiesta que para efectos del presente
              Aviso de Privacidad su domicilio se encuentra ubicado en Miguel de Cervantes Saavedra No 301, Torre Sur, Piso 17
              Colonia Granada, Alcaldía Miguel Hidalgo, CP 11520, Ciudad de México, y con página web <u><a style={{ color: '#039ECC' }}
                href="http://www.inter.mx/">www.inter.mx</a></u></span></p>
            <p><span><b>INTERprotección</b> es el responsable del tratamiento de los datos personales que nos proporcione, los
              cuales se protegerán bajo las más estrictas medidas de seguridad a fin de garantizar su confidencialidad, protección y
              tratamiento conforme a lo dispuesto en el presente Aviso de Privacidad, la Ley Federal de Protección de Datos Personales
              en Posesión de los Particulares (LA LEY) y demás normatividad aplicable.</span></p>
            <p><span><b>INTERprotección</b></span> pone a su disposición el presente Aviso de Privacidad, con la finalidad de dar
              cumplimiento a los artículos 15, 16 y demás relativos de la LEY y a los artículos 24 al 30 y demás relativos del Reglamento
              de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (REGLAMENTO) y la normatividad que
              resulte aplicable; y al respecto le informa lo siguiente:</p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Razones sociales de INTERprotección.</b></li>
            </ol>
            <p><span ><b>INTERprotección</b>, en su carácter de responsable del tratamiento de sus datos personales, manifiesta que para efectos
              del presente Aviso de Privacidad las razones sociales cubiertas, son las siguientes: Casanueva Pérez, S.A.P.I. de C.V.,
              Centro INTER de Servicios, S.A. de C.V., INTERprotección, Agente de Seguros y de Fianzas S.A. de C.V., INTERprotección
              Guadalajara, Agente de Seguros y de Fianzas, S.A. de C.V., INTERprotección Mérida, Agente de Seguros y de Fianzas, S.A. de
              C.V., INTER Los Cabos, Agente de Seguros y de Fianzas, S.A. de C.V., Se Listo, Agente de Seguros y de Fianzas, S.A. de C.V.,
              PCP, Agente de Seguros y de Fianzas, S.A. de C.V., MK, Agente de Seguros y de Fianzas, S.A. de C.V., EJC, Agente de Seguros
              y de Fianzas, S.A.P.I. de C.V., HJK, Agente de Seguros y de Fianzas, S.A.P.I. de C.V., INTER Infraestructura, Agente de
              Seguros y de Fianzas, S.A. de C.V., INTERópolis, Agente de Seguros y de Fianzas, S.A.P.I. de C.V,.Intersecuritas, Agente de
              Seguros y de Fianzas, S.A. de C.V., Reasinter, Intermediarios de Reaseguro S.A. de C.V., Flag Assist, S.A.P.I. de C.V.,
              INTERprotección Asistencias, S.A.P.I. de C.V., Financial Lines Advisory Group, S.A.P.I. de C.V., INTER Monterrey Agente de
              Seguros y de Fianzas, S.A. de C.V., INTERprotección Sinaloa, Agente de Seguros y de Fianzas, S.A. de C.V., Unifin, Agente de
              Seguros y de Fianzas, S.A. de C.V., INTER Mota Latam, Agente de Seguros y de Fianzas, S.A.P.I. de C.V.</span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Datos personales recibidos del TITULAR.</b></li>
            </ol>
            <p><span >Para llevar a cabo las finalidades descritas en el presente Aviso de Privacidad, se solicitarán de usted los siguientes
              datos personales:</span></p>
            <p><span>• Datos obtenidos de la grabación con cámaras de vigilancia de INTERprotección.</span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li>Datos sensibles.</li>
            </ol>
            <ol>
              <li ><span >Se informa que no se solicitarán datos personales sensibles, para el presente aviso de privacidad</span></li>
            </ol>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Finalidades del uso de los datos personales.</b></li>
            </ol>
            <p><span >&nbsp;</span></p>
            <ol>
              <li>Finalidades primarias.</li>
            </ol>
            <ol>
              <li><span>Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades:</span></li>
              <li><span>1.	Efectuar el proceso de grabación con cámaras de vigilancia, a fin de Preservar la seguridad de las personas y
                las instalaciones de INTERprotección. Su tratamiento forma parte de las medidas de seguridad adoptadas al interior de nuestra organización.</span>
              </li>
            </ol>
            <p><span >&nbsp;</span></p>
            <ol>
              <li>Finalidades secundarias.</li>
            </ol>
            <ol>
              <li><span>Se informa que no se establecen finalidades secundarias, para el presente aviso de privacidad.</span></li>
            </ol>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Transferencias o remisiones de datos.</b></li>
            </ol>
            <p><b>INTERprotección</b><span > conforme a lo que se establece en el artículo 37 de LA LEY, le informa que las transferencias
              de datos personales que puede efectuar sin su consentimiento son las siguientes:</span></p>
            <p><b>I.</b><span > Cuando la transferencia esté prevista en una Ley o Tratado en los que México sea parte.</span></p>
            <p><b>II.</b><span > Cuando la transferencia sea necesaria para la prevención o el diagnóstico médico, la prestación de
              asistencia sanitaria, tratamiento médico o la gestión de servicios sanitarios.</span></p>
            <p><b>III.</b><span > Cuando la transferencia sea efectuada a sociedades controladoras, subsidiarias o afiliadas bajo
              el control común de <b>INTERprotección</b>, o a una sociedad matriz o a cualquier sociedad del mismo grupo de <b>INTERprotección</b> que
              opere bajo los mismos procesos y políticas internas.</span></p>
            <p><b>IV.</b><span > Cuando la transferencia sea necesaria por virtud de un contrato celebrado o por celebrar en interés del <b>TITULAR</b>,
              por <b>INTERprotección</b> y un <b>TERCERO</b>.</span></p>
            <p><b>V.</b><span > Cuando la transferencia sea necesaria o legalmente exigida para la salvaguarda de un interés público, o para la
              procuración o administración de justicia.</span></p>
            <p><b>VI.</b><span > Cuando la transferencia sea precisa para el reconocimiento, ejercicio o defensa de un derecho en un proceso judicial, y
            </span></p>
            <p><b>VII.</b><span > Cuando la transferencia sea precisa para el mantenimiento o cumplimiento de una relación jurídica entre el de
              <b>INTERprotección</b>, y el <b>TITULAR</b>.</span></p>
            <p><span >&nbsp;</span></p>
            <p><span>Se hace de su conocimiento que <b>INTERprotección</b>, no realiza a cabo ninguna transferencia de sus datos personales que requiera
              de su consentimiento y por tanto que deba de informarse. </span>
            </p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Lineamientos y limitaciones en las transferencias o remisiones de datos personales.</b></li>
            </ol>
            <p><span>En los casos que <b>INTERprotección</b> comparte su información personal, le informa que se establecen y seguirán los siguientes lineamientos y limitaciones con base a lo establecido en
              la LFPDPPP, su reglamento y normatividad vigente: </span>
            </p>
            <p><span>• <b>INTERprotección</b> no compartirá su información personal a destinatarios distintos a los establecidos en el presente Aviso de Privacidad.</span></p>
            <p><span>• En todos los casos que <b>INTERprotección</b> realice transferencias a terceros responsables o remisiones a encargados comunicará a los mismos el presente Aviso de Privacidad.</span></p>
            <p><span>• Se estabecerá con los destinatarios por medio de cláusulas contractuales, lo siguiente:</span></p>
            <p><span>o Que el tratamiento de los datos personales compartidos solo se realice para las finalidades establecidas en el presente Aviso de Privacidad.</span></p>
            <p><span>o Que los datos personales compartidos se traten conforme a lo establecido en la LEY, su REGLAMENTO y demás ordenamientos aplicables.</span></p>
            <p><span>o Que los datos personales compartidos en ningún caso se pueden comunicar a ningún tercero.</span></p>
            <p><span>• En el caso de que <b>INTERprotección</b> necesite compartir su información personal a terceros distintos o para dar tratamiento para finalidades distintas a las establecidos en el presente
              Aviso de Privacidad y que se requiera de su consentimiento, pondrá a su disposición el nuevo Aviso de Privacidad, previo a compartir o dar tratamiento de sus datos personales, y solicitará su
              consentimiento.</span></p>
            <p><span >&nbsp;</span></p>
            <p><span >Se recomienda que usted como <b>TITULAR</b> de los datos personales, notifique a <b>INTERprotección</b> sobre cualquier cambio respecto su domicilio físico y/o dirección de correo electrónico,
              con la finalidad de mantener actualizados sus datos personales para informarle cualquier cambio o modificación respecto del contenido en el Aviso de Privacidad.</span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Medios para ejercer los derechos de acceso, rectificación, cancelación y oposición (ARCO) de los datos personales.</b></li>
            </ol>
            <p><span>Usted podrá ejercer sus derechos de acceso, rectificación, cancelación u oposición de sus datos personales (Derechos ARCO) directamente con el Oficial de Privacidad en nuestro domicilio ubicado
              en Avenida Miguel de Cervantes Saavedra No 301, Torre Sur, Piso 17 Colonia Granada, Alcaldía Miguel Hidalgo, CP 11520, Ciudad de México, o bien, a través del correo: <u><a style={{ color: '#039ECC' }}
                href="mailto:leyproteccion@inter.mx">leyproteccion@inter.mx</a></u><br />
              Si desea conocer el procedimiento para el ejercicio de estos derechos, puede solicitarlo directamente a nuestro Oficial de Privacidad o enviar un correo electrónico a la dirección antes señalada.<br />
              Es importante comunicarle que <b>INTERprotección</b> podrá negar el acceso para que usted ejerza sus Derechos ARCO en los siguientes supuestos:
            </span></p>
            <ol>
              <li><span>1. Cuando usted no sea el <b>TITULAR</b> de los datos personales, o su representante legal no esté debidamente acreditado para ejercer por medio de él, sus Derechos ARCO.</span></li>
              <li><span>2. Cuando en nuestra base de datos no se encuentren sus datos personales.</span></li>
              <li><span>3. Cuando se lesionen los derechos de un tercero.</span></li>
              <li><span>4. Cuando exista un impedimento legal o la resolución de una autoridad competente, que restrinja sus Derechos ARCO, y</span></li>
              <li><span>5. Cuando la Rectificación, Cancelación u Oposición haya sido previamente realizada.</span></li>
            </ol>
            <p><span><b>INTERprotección</b> siempre le informará el motivo de su decisión y se la comunicará a usted o en caso, a su representante legal por correo electrónico, acompañando, en su caso, las pruebas que
              resulten pertinentes.<br />
              El ejercicio de los Derechos ARCO será gratuito.</span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Mecanismo para revocación del consentimiento.</b></li>
            </ol>
            <p><span >Usted podrá revocar su consentimiento para que <b>INTERprotección</b> deje de llevar a cabo el tratamiento de sus datos personales directamente con el Oficial de Privacidad en nuestro domicilio ubicado en
              Avenida Miguel de Cervantes Saavedra No 301, Torre Sur, Piso 17 Colonia Granada, Alcaldía Miguel Hidalgo, CP 11520, Ciudad de México, o bien, a través del correo: <u><a style={{ color: '#039ECC' }}
                href="mailto:leyproteccion@inter.mx">leyproteccion@inter.mx</a></u><br />
              Si desea conocer el procedimiento para revocar su consentimiento, puede solicitarlo directamente a nuestro Oficial de Privacidad o enviar un correo electrónico a la dirección antes señalada.
            </span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Uso de tecnologías de rastreo en nuestro portal de internet.</b></li>
            </ol>
            <p><span>Le informamos que en nuestra página de Internet utilizamos cookies para obtener información <b>no personal</b> de los visitantes en línea, dichos cookies rastrean el tipo de navegador, sistema operativo y
              prestador de servicios de Internet y nos permiten llevar un control del número total de visitantes a nuestra página.<br />
              Los tipos de cookies que podemos usar son: estadística, publicidad y seguridad. Estas tecnologías podrán deshabilitarse siguiendo los siguientes pasos: entrando a la sección de configuración de su navegador,
              en el apartado seguridad y privacidad, usted puede programar para darle aviso de cuando le son enviados cookies, o bien pueden deshabilitarse los mismos. Los datos que se obtienen de estas tecnologías de rastreo
              son: dirección IP, duración del tiempo de interacción en la página, y tipo de navegador utilizado, con la finalidad de obtener información respecto de hábitos de navegación, vínculos seguidos y páginas al
              interior de nuestro sitio que visita el equipo vinculado a la dirección IP.
            </span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Las previsiones especiales para el tratamiento de datos personales de categorías especiales de TITULARES, tales como menores de edad, personas en estado de interdicción, personas adultas mayores, personas
                con discapacidad, migrantes.</b></li>
            </ol>
            <p><span>Para las finalidades descritas en el presente Aviso de Privacidad, en caso de tratar datos personales de categorías especiales, como son: menores de edad y personas en estado de interdicción, se consideran
              las siguientes previsiones especiales:</span></p>
            <ol>
              <li><span>• Se solicita el consentimiento expreso y por escrito en todos los casos del padre o tutor de dichos <b>TITULARES</b>.</span></li>
              <li><span>•	La verificación de la autenticidad del consentimiento otorgado por los padres o tutores, solicitando la documentación que lo acredite como tales.</span></li>
              <li><span>•	La implementación y mantenimiento de medidas de seguridad más estrictas a efecto de asegurar la confidencialidad de los datos personales de categorías especiales de <b>TITULARES</b>, y</span></li>
              <li><span>•	En el tratamiento de datos personales de los <b>TITULARES</b> de estas categorías especiales, se tendrá un especial cuidado para que la toma de decisiones sobre los mismos, no involucren puntos de vista y
                percepciones discriminatorias.</span></li>
            </ol>
            <p><span>En el caso de tratamiento de personas mayores de edad o de categorías especiales, como son: personas con discapacidad y migrantes, se considera las siguientes previsiones especiales:</span></p>
            <ol>
              <li><span>•	Se solicita el consentimiento expreso y por escrito del <b>TITULAR</b> en todos los casos.</span></li>
              <li><span>•	La implementación y mantenimiento de medidas de seguridad más estrictas a efecto de asegurar la confidencialidad de los datos personales de categorías especiales de <b>TITULARES</b>, y</span></li>
              <li><span>•	En el tratamiento de datos personales de los <b>TITULARES</b> de estas categorías especiales, se tendrá un especial cuidado para que la toma de decisiones sobre los mismos, no involucren puntos de vista y
                percepciones discriminatorias.</span></li>
            </ol>
            <p><span>Para el tratamiento de datos personales de categorías especiales de <b>TITULARES</b>, <b>INTERprotección</b> en todos los casos que procedan, solo realizará el tratamiento de los datos personales si se ha cumplido con las
              previsiones especiales establecidas.<br />
              Para estos casos, <b>INTERprotección</b> requiere de su consentimiento expreso y por escrito, que se otorga al firmar el presente Aviso de Privacidad.
            </span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Seguridad de los datos personales y confidencialidad.</b></li>
            </ol>
            <p><span ><b>INTERprotección</b> implementará las medidas de seguridad técnicas, administrativas y físicas necesarias para asegurar la integridad de sus datos personales y evitar su daño, pérdida, alteración, destrucción o el
              uso, acceso o tratamiento no autorizado.<br />
              Únicamente el personal autorizado, que ha cumplido y observado los correspondientes requisitos de confidencialidad, podrá participar en el tratamiento de sus datos personales.<br />
              En caso de haber una vulneración en las medidas de seguridad con las que se resguardan los datos personales del <b>TITULAR</b> y que éstas pudieran afectarle en sus derechos patrimoniales o morales, <b>INTERprotección</b> le
              informará de forma inmediata para que el <b>TITULAR</b> tome las medidas que estime conducentes.
            </span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Derecho a la protección de sus datos personales.</b></li>
            </ol>
            <p><span>Si usted considera que su derecho a la protección de sus datos personales ha sido lesionado por alguna conducta u omisión de nuestra parte, o presume alguna violación a las disposiciones previstas en la Ley Federal de
              Protección de Datos Personales en Posesión de los Particulares, su Reglamento y demás ordenamientos aplicables, podrá interponer su inconformidad o denuncia ante el Instituto Nacional de Trasparencia, Acceso a la Información y
              Protección de Datos Personales (INAI). Para más información, le sugerimos visitar su página oficial de Internet <u><a style={{ color: '#039ECC' }}
                href="http://www.inai.org.mx/">www.INAI.org.mx</a></u>.</span></p>
            <p><span >&nbsp;</span></p>
            <ol>
              <li><b>Modificaciones o actualizaciones al Aviso de Privacidad.</b></li>
            </ol>
            <p><span>En caso de que exista un cambio de este aviso de privacidad, lo haremos de su conocimiento a través de la página de <b>INTERprotección</b>, <u><a style={{ color: '#039ECC' }}
              href="http://www.inter.mx/">www.inter.mx</a></u> la que le recomendamos verificar con frecuencia; o solicitando el Aviso
              de Privacidad vigente al Oficial de Privacidad al correo: <ul><a style={{ color: '#039ECC' }} href="mailto:leyproteccion@inter.mx">leyproteccion@inter.mx</a></ul></span></p>
            <p><span >&nbsp;</span></p>
            <p><b><i>Última actualización: 19 agosto 2022.</i></b></p>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}