import styles from './PagoExitoso.module.css';
import successful_payment from '../../assets/img/cobranza/successful_payment.png';
import { ContactPhone } from "./CobranzaEnum";

export default function PagoExitoso() {
  const contact_phone = ContactPhone;

  const PhoneLink = () => {
    return (
      <a href={`tel:${contact_phone.replace(/\s+/g, '')}`} className={styles['pago-recibido-info-telefono']}>
        {contact_phone}
      </a>
    )
  }

  return (
    <div className={styles['container-pago-exitoso']}>
      <p className={styles['pago-exitoso-titulo']}>¡Tu pago se ha realizado con éxito!</p>
      <img src={successful_payment} className={styles['img-pago-ok']} alt='Pago exitoso' />
      <p className={styles['pago-exitoso-info']}>Gracias por tu pago, si necesitas ayuda o tienes dudas, comunícate con nosotros al
      <span className={styles['pago-exitoso-info-telefono']}> {<PhoneLink />} </span>y con gusto te atenderemos.</p>
    </div>
  );
}
