
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";
import { Box, Button } from '@mui/material';
import { useEffect } from 'react';


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  backgroundColor: 'transparent'
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ChevronRightIcon />}
    {...props}
  />
))(({ theme }) => ({
  padding: '10px 14px',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
    hidden: true

  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '20px 25px',
  borderTop: '1px solid rgba(0, 0, 0, .125)'
}));

export default function TermnsSection() {
  
  const text = [
    {
      head: 'PROMO GP de Bélgica',
      body: `Vigencia de la promoción:

      1. Aplica para pólizas de Coche Seguro y Moto Segura contratadas en inter.mx con cobertura amplia del lunes 22 de julio del 2024 hasta el domingo 28 de julio del 2024 antes de las 06:59 hrs, tiempo del centro o hasta agotar existencias.
      
      Límite de clientes:
      2. Válido para las primeras 150 personas en contratar dentro del período de la promoción su seguro de coche o moto directamente en inter.mx
      3. Válido solo para 150 monederos en Amazon México con un valor de $500.00 MXN.
      4. Únicamente aplica para pólizas de Coche Seguro y Moto Segura contratadas con cobertura amplia dentro del sitio inter.mx, aplicando el código de la promoción, según el medio por el que se conoce la misma.

      Para ser considerado como cliente participante:
      5. Se deberá pagar de contado o 06 y 12 meses sin intereses (con tarjetas participantes) únicamente por el periodo de la promoción. 
      6. No aplica planes de pago mensual, trimestral y semestral (diferidos en la plataforma digital de seguros inter.mx).
      7. Aplica para los códigos INTERWIN (canales digitales) e INTERPROMO (T.V. y medios masivos). Al momento de la contratación en el campo “código de promoción”.
      8. Únicamente se enviará el monedero en Amazon México a aquellas pólizas que estén totalmente pagadas de contado dentro del período de la promoción, de lo contrario no aplica.

      Beneficio por cliente:
      9. Un monedero en Amazon México por cliente con un valor de $500.00 MXN, por contratar tu póliza durante el periodo de la promoción.
      10. Si Checo Pérez queda en primer lugar en el Gran Premio de Bélgica  2024, obtendrás el equivalente a $1,000.00 MXN en Amazon México.
      11. Cada beneficio corresponde a los diferentes resultados de la carrera respectivamente. Los beneficios son individuales, no son acumulables.
      12. Si la carrera correspondiente a esta promoción es cancelada, independientemente de la circunstancia; serás acreedor al beneficio que no involucra la posición del piloto Sergio Pérez en la carrera.

      Proceso para recibir el monedero:
      13. Para hacer válida la promoción recibirás un correo dentro de los primeros 05 días hábiles con los pasos a seguir, revisa tu bandeja de spam o promociones, en caso de no recibirlo comunícate al 5544246837.
      14. Se enviará tu monedero en Amazon México por correo a los 60 días naturales posteriores a tu contratación, se indicará la fecha por correo electrónico. 
      15. El monedero en Amazon México  se enviará al correo electrónico que el titular de la póliza haya registrado al momento de la contratación en inter.mx
      16. La póliza tendrá que estar pagada y vigente antes del envío del monedero en Amazon México.
      17. En caso de no realizar el pago de la póliza durante el periodo de la promoción, esta no será válida.
      18. La información al momento de contratación deberá corresponder al titular de la póliza para hacer válida la promoción.
      19. Aplica únicamente para nuevas contrataciones de Coche Seguro o Moto Segura, no aplica en renovaciones.
      20. Localización y contacto con el titular y beneficiarios por los distintos canales de comunicación empleados por inter.mx ya sea correo, SMS, WhatsApp, entre otros.`
    },
    {
      head: 'PROMO GP de Hungría',
      body: `Vigencia de la promoción:

      1. Aplica para pólizas de Coche Seguro y Moto Segura contratadas en inter.mx con cobertura amplia del lunes 15 de julio del 2024 hasta el domingo 21 de julio del 2024 antes de las 06:59 hrs, tiempo del centro o hasta agotar existencias.
      
      Límite de clientes:
      2. Válido para las primeras 150 personas en contratar dentro del período de la promoción su seguro de coche o moto directamente en inter.mx
      3. Válido solo para 150 monederos en Amazon México con un valor de $600.00 MXN.
      4. Únicamente aplica para pólizas de Coche Seguro y Moto Segura contratadas con cobertura amplia dentro del sitio inter.mx, aplicando el código de la promoción, según el medio por el que se conoce la misma.

      Para ser considerado como cliente participante:
      5. Se deberá pagar de contado o 06 y 12 meses sin intereses (con tarjetas participantes) únicamente por el periodo de la promoción. 
      6. No aplica planes de pago mensual, trimestral y semestral (diferidos en la plataforma digital de seguros inter.mx).
      7. Aplica para los códigos INTERWIN (canales digitales) e INTERPROMO (T.V. y medios masivos). Al momento de la contratación en el campo “código de promoción”.
      8. Únicamente se enviará el monedero en Amazon México a aquellas pólizas que estén totalmente pagadas de contado dentro del período de la promoción, de lo contrario no aplica.

      Beneficio por cliente:
      9. Un monedero en Amazon México por cliente con un valor de $600.00 MXN, por contratar tu póliza durante el periodo de la promoción.
      10. Si Checo Pérez queda en primer lugar en el Gran Premio de Hungría  2024, obtendrás el equivalente a $1,500.00 MXN en Amazon México.
      11. Cada beneficio corresponde a los diferentes resultados de la carrera respectivamente. Los beneficios son individuales, no son acumulables.
      12. Si la carrera correspondiente a esta promoción es cancelada, independientemente de la circunstancia; serás acreedor al beneficio que no involucra la posición del piloto Sergio Pérez en la carrera.

      Proceso para recibir el monedero:
      13.	Para hacer válida la promoción recibirás un correo dentro de los primeros 05 días hábiles con los pasos a seguir, revisa tu bandeja de spam o promociones, en caso de no recibirlo comunícate al 5544246837.
      14.	Se enviará tu monedero en Amazon México por correo a los 60 días naturales posteriores a tu contratación, se indicará la fecha por correo electrónico. 
      15.	El monedero en Amazon México  se enviará al correo electrónico que el titular de la póliza haya registrado al momento de la contratación en inter.mx
      16.	La póliza tendrá que estar pagada y vigente antes del envío del monedero en Amazon México.
      17.	En caso de no realizar el pago de la póliza durante el periodo de la promoción, esta no será válida.
      18.	La información al momento de contratación deberá corresponder al titular de la póliza para hacer válida la promoción.
      19.	Aplica únicamente para nuevas contrataciones de Coche Seguro o Moto Segura, no aplica en renovaciones.
      20.	Localización y contacto con el titular y beneficiarios por los distintos canales de comunicación empleados por inter.mx ya sea correo, SMS, WhatsApp, entre otros.`
    },
    {
      head: 'PROMO GP de Silverstone',
      body: `Vigencia de la promoción:

      1. Aplica para pólizas de Coche Seguro y Moto Segura contratadas en inter.mx con cobertura amplia del lunes 01 de julio del 2024 hasta el domingo 07 de julio del 2024 antes de las 07:59 hrs, tiempo del centro o hasta agotar existencias.
      
      Límite de clientes:
      2. Válido para las primeras 150 personas en contratar dentro del período de la promoción su seguro de coche o moto directamente en inter.mx
      3. Válido solo para 150 monederos en Amazon México con un valor de $500.00 MXN.
      4. Únicamente aplica para pólizas de Coche Seguro y Moto Segura contratadas con cobertura amplia dentro del sitio inter.mx, aplicando el código de la promoción, según el medio por el que se conoce la misma.

      Para ser considerado como cliente participante:
      5. Se deberá pagar de contado o 06 y 12 meses sin intereses (con tarjetas participantes) únicamente por el periodo de la promoción. 
      6. No aplica planes de pago mensual, trimestral y semestral (diferidos en la plataforma digital de seguros inter.mx).
      7. Aplica para los códigos INTERWIN (canales digitales) e INTERPROMO (T.V. y medios masivos). Al momento de la contratación en el campo “código de promoción”.
      8. Únicamente se enviará el monedero en Amazon México a aquellas pólizas que estén totalmente pagadas de contado dentro del período de la promoción, de lo contrario no aplica.

      Beneficio por cliente:
      9. Un monedero en Amazon México por cliente con un valor de $500.00 MXN, por contratar tu póliza durante el periodo de la promoción.
      10. Si Checo Pérez queda en primer lugar en el Gran Premio de Silverstone  2024, obtendrás el equivalente a $1,500.00 MXN en Amazon México.
      11. Cada beneficio corresponde a los diferentes resultados de la carrera respectivamente. Los beneficios son individuales, no son acumulables.
      12. Si la carrera correspondiente a esta promoción es cancelada, independientemente de la circunstancia; serás acreedor al beneficio que no involucra la posición del piloto Sergio Pérez en la carrera.

      Proceso para recibir el monedero:
      13.	Para hacer válida la promoción recibirás un correo dentro de los primeros 05 días hábiles con los pasos a seguir, revisa tu bandeja de spam o promociones, en caso de no recibirlo comunícate al 5544246837.
      14.	Se enviará tu monedero en Amazon México por correo a los 60 días naturales posteriores a tu contratación, se indicará la fecha por correo electrónico. 
      15.	El monedero en Amazon México  se enviará al correo electrónico que el titular de la póliza haya registrado al momento de la contratación en inter.mx
      16.	La póliza tendrá que estar pagada y vigente antes del envío del monedero en Amazon México.
      17.	En caso de no realizar el pago de la póliza durante el periodo de la promoción, esta no será válida.
      18.	La información al momento de contratación deberá corresponder al titular de la póliza para hacer válida la promoción.
      19.	Aplica únicamente para nuevas contrataciones de Coche Seguro o Moto Segura, no aplica en renovaciones.
      20.	Localización y contacto con el titular y beneficiarios por los distintos canales de comunicación empleados por inter.mx ya sea correo, SMS, WhatsApp, entre otros.`
    },
    {
      head: 'Giveaway X: Gana una mochila Oracle Red Bull Racing.',
      body: `Vigencia del giveaway:

      1. Aplica para usuarios que participen en la dinámica del lunes 01 de julio de 2024 al domingo 07 de julio 2024, antes de las 23:59, tiempo del centro.
      
      Para ser considerado participante:
      2. Cuéntanos tu momento favorito de esta temporada y agrega #SeguroSeguro.
      3. Etiqueta y sigue a @intermx en X (https://twitter.com/intermx)
      4. Sigue a @KatiiaMunguia en X (https://twitter.com/KatiiaMunguia) 
      5. Dale RP al post con la dinámica.

      Premio para el ganador:
      6. El ganador se llevará:
        ●	Una mochila de Oracle Red Bull Racing.
      7. Válido solo para 1 usuario ganador.
       
      Proceso para obtener el premio :
      8. Aplica solo para participantes dentro de la república mexicana.
      9. El ganador será elegido al azar y se anunciará el día lunes 08 de julio del 2024.
      10. Se anunciará al ganador a través de la cuenta oficial de X de @intermx (https://twitter.com/intermx)
      11. Contactaremos vía DM a través de la cuenta oficial de X de @intermx (https://twitter.com/intermx) al ganador para gestionar la entrega del premio.
      12. El ganador deberá compartir sus datos domiciliarios para hacer el envío de su premio de manera correcta y completa.
        ●	El premio se entregará única y exclusivamente en el domicilio del usuario ganador.
        ●	El ganador deberá compartirnos su identificación oficial para recibir su premio. 
      13. El ganador será el responsable sobre la recepción y entrega de su premio, una vez entregados inter.mx no se hace responsable.

      IMPORTANTE. Si eres ganador, recuerda que te contactaremos vía mensaje directo desde la cuenta de X de inter.mx (https://twitter.com/intermx). Haz caso omiso en caso de recibir mensajes de alguna cuenta ajena al Giveaway.`
    },
    {
      head: 'PROMO GP de Austria',
      body: `Vigencia de la promoción:

      1. Aplica para pólizas de Coche Seguro y Moto Segura contratadas en inter.mx con cobertura amplia del lunes 24 de junio del 2024 hasta el domingo 30 de junio del 2024 antes de las 06:59 hrs, tiempo del centro o hasta agotar existencias.
      
      Límite de clientes:
      2. Válido para las primeras 150 personas en contratar dentro del período de la promoción su seguro de coche o moto directamente en inter.mx
      3. Válido solo para 150 monederos en Amazon México con un valor de $750.00 MXN.
      4. Únicamente aplica para pólizas de Coche Seguro y Moto Segura contratadas con cobertura amplia dentro del sitio inter.mx, aplicando el código de la promoción, según el medio por el que se conoce la misma.

      Para ser considerado como cliente participante:
      5. Se deberá pagar de contado o 06 y 12 meses sin intereses (con tarjetas participantes) únicamente por el periodo de la promoción. 
      6. No aplica planes de pago mensual, trimestral y semestral (diferidos en la plataforma digital de seguros inter.mx).
      7. Aplica para los códigos INTERWIN (canales digitales) e INTERPROMO (T.V. y medios masivos). Al momento de la contratación en el campo “código de promoción”.
      8. Únicamente se enviará el monedero en Amazon México a aquellas pólizas que estén totalmente pagadas de contado dentro del período de la promoción, de lo contrario no aplica.

      Beneficio por cliente:
      9. Un monedero en Amazon México por cliente con un valor de $750.00 MXN, por contratar tu póliza durante el periodo de la promoción.
      10. Si Checo Pérez queda en primer lugar en el Gran Premio de Austria  2024, obtendrás el equivalente a $2,500.00 MXN en Amazon México.
      11. Cada beneficio corresponde a los diferentes resultados de la carrera respectivamente. Los beneficios son individuales, no son acumulables.
      12. Si la carrera correspondiente a esta promoción es cancelada, independientemente de la circunstancia; serás acreedor al beneficio que no involucra la posición del piloto Sergio Pérez en la carrera.

      Proceso para recibir el monedero:
      13.	Para hacer válida la promoción recibirás un correo dentro de los primeros 05 días hábiles con los pasos a seguir, revisa tu bandeja de spam o promociones, en caso de no recibirlo comunícate al 5544246837.
      14.	Se enviará tu monedero en Amazon México por correo a los 60 días naturales posteriores a tu contratación, se indicará la fecha por correo electrónico. 
      15.	El monedero en Amazon México  se enviará al correo electrónico que el titular de la póliza haya registrado al momento de la contratación en inter.mx
      16.	La póliza tendrá que estar pagada y vigente antes del envío del monedero en Amazon México.
      17.	En caso de no realizar el pago de la póliza durante el periodo de la promoción, esta no será válida.
      18.	La información al momento de contratación deberá corresponder al titular de la póliza para hacer válida la promoción.
      19.	Aplica únicamente para nuevas contrataciones de Coche Seguro o Moto Segura, no aplica en renovaciones.
      20. Localización y contacto con el titular y beneficiarios por los distintos canales de comunicación empleados por inter.mx ya sea correo, SMS, WhatsApp, entre otros.`
    },
    {
      head: 'Giveaway IG: ¡Participa y gana una gorra firmada para papá!',
      body: `Vigencia del giveaway:

      1. Aplica para usuarios que participen en la dinámica del lunes 17 de junio de 2024 al domingo 23 de junio de 2024, antes de las 23:59, tiempo del centro.
      
      Para ser considerado participante:
      2. Deberás seguir a @intermx en Instagram (https://www.instagram.com/intermx/) 
      3. En los comentarios, describe a tu papá con 3 emojis usando el hashtag #SeguroSeguro
        ●	Tu cuenta de Instagram deberá ser pública.
        ●	Para participar debes ser mayor de edad.   	
      4. El comentario ganador será elegido al azar y lo anunciaremos en la cuenta oficial de Instagram de @intermx

      Premio por ganador:
      5. El ganador será acreedor a:
        ●	1 gorra firmada por el piloto Sergio Pérez del GP de Miami 2024.
      6. Válido solo para 1 usuario ganador.
       
      Proceso para obtener el premio :
      7. Aplica solo para participantes dentro de la república mexicana.
      8. El usuario ganador que haya participado en la dinámica y haya seguido los pasos entre el 17 al 23 de junio 2024 será elegido al azar.
      9. Se anunciará al ganador a través de la cuenta oficial de Instagram: @intermx, el lunes 24 de junio 2024.
      10. Contactaremos vía DM a través de Instagram de @intermx al ganador para gestionar la entrega del premio.
      11. El ganador deberá compartirnos sus datos domiciliarios para hacer el envío de su premio de manera correcta y completa.
        ●	El premio se entregará única y exclusivamente en el domicilio del usuario ganador.
        ●	El ganador deberá compartirnos una imagen de su identificación oficial para recibir su premio. 
      12. El ganador será responsable sobre la recepción y entrega de su premio, una vez entregados inter.mx no se hace responsable.

      IMPORTANTE. Si eres ganador, recuerda que te contactaremos vía mensaje directo desde la cuenta oficial de Instagram de Inter.mx (https://www.instagram.com/intermx/). Haz caso omiso en caso de recibir mensajes de alguna cuenta ajena al Giveaway.`
    },
    {
      head: 'PROMO GP de Barcelona',
      body: `Vigencia de la promoción:

      1. Aplica para pólizas de Coche Seguro y Moto Segura contratadas en inter.mx con cobertura amplia del lunes 17 de junio del 2024 hasta el domingo 23 de junio del 2024 antes de las 06:59 hrs, tiempo del centro o hasta agotar existencias.
      
      Límite de clientes:
      2. Válido para las primeras 150 personas en contratar dentro del período de la promoción su seguro de coche o moto directamente en inter.mx
      3. Válido solo para 150 monederos en Amazon México con un valor de $750.00 MXN.
      4. Únicamente aplica para pólizas de Coche Seguro y Moto Segura contratadas con cobertura amplia dentro del sitio inter.mx, aplicando el código de la promoción, según el medio por el que se conoce la misma.

      Para ser considerado como cliente participante:
      5. Se deberá pagar de contado o 06 y 12 meses sin intereses (con tarjetas participantes) únicamente por el periodo de la promoción. 
      6. No aplica planes de pago mensual, trimestral y semestral (diferidos en la plataforma digital de seguros inter.mx).
      7. Aplica para los códigos INTERWIN (canales digitales) e INTERPROMO (T.V. y medios masivos). Al momento de la contratación en el campo “código de promoción”.
      8. Únicamente se enviará el monedero en Amazon México a aquellas pólizas que estén totalmente pagadas de contado dentro del período de la promoción, de lo contrario no aplica.

      Beneficio por cliente:
      9. Un monedero en Amazon México por cliente con un valor de $750.00 MXN, por contratar tu póliza durante el periodo de la promoción.
      10. Si Checo Pérez queda en primer lugar en el Gran Premio de Barcelona 2024, obtendrás el equivalente a $1,000 MXN en Amazon México.
      11. Cada beneficio corresponde a los diferentes resultados de la carrera respectivamente. Los beneficios son individuales, no son acumulables.
      12. Si la carrera correspondiente a esta promoción es cancelada, independientemente de la circunstancia; serás acreedor al beneficio que no involucra la posición del piloto Sergio Pérez en la carrera.

      Proceso para recibir el monedero:
      13. Para hacer válida la promoción recibirás un correo dentro de los primeros 05 días hábiles con los pasos a seguir, revisa tu bandeja de spam o promociones, en caso de no recibirlo comunícate al 5544246837.
      14. Se enviará tu monedero en Amazon México por correo a los 60 días naturales posteriores a tu contratación, se indicará la fecha por correo electrónico. 
      15. El monedero en Amazon México  se enviará al correo electrónico que el titular de la póliza haya registrado al momento de la contratación en inter.mx
      16. La póliza tendrá que estar pagada y vigente antes del envío del monedero en Amazon México.
      17. En caso de no realizar el pago de la póliza durante el periodo de la promoción, esta no será válida.
      18. La información al momento de contratación deberá corresponder al titular de la póliza para hacer válida la promoción.
      19. Aplica únicamente para nuevas contrataciones de Coche Seguro o Moto Segura, no aplica en renovaciones.
      20. Localización y contacto con el titular y beneficiarios por los distintos canales de comunicación empleados por inter.mx ya sea correo, SMS, WhatsApp, entre otros.`
    },
    {
      head: 'PAPÁ 2024: COCHE SEGURO Y MOTO SEGURA',
      body: `Vigencia de la promoción:

      1. Aplica para pólizas de Coche Seguro y Moto Segura contratadas en inter.mx con cobertura amplia del lunes 10 de junio de 2024 hasta el domingo 16 de junio de 2024 antes de las 23:59, tiempo del centro o hasta agotar existencias.
      
      Límite de clientes:
      2. Válido para las primeras 50 personas en contratar dentro del período de la promoción su seguro de coche directamente en inter.mx
      3. Válido solo para 50 monederos en Amazon México con un valor de $500.00 MXN.
      4. Únicamente aplica para pólizas de Coche Seguro o Moto Segura contratadas con cobertura amplia dentro de inter.mx aplicando el código de la promoción.

      Para ser considerado como cliente participante:
      5. Se deberá pagar de contado o 06 y 12 meses sin intereses (con tarjetas participantes) en el periodo de la promoción. 
      6. No aplica planes de pago mensual, trimestral y semestral (diferidos en la plataforma digital de seguros inter.mx).
      7. Aplica únicamente para pólizas pagadas con el código “PAPA24” al momento de contratar en el campo “código de promoción”.
      8. Únicamente se enviará el monedero en Amazon México a aquellas pólizas que estén totalmente pagadas de contado dentro del período de la promoción, de lo contrario no aplica.

      Beneficio por cliente:
      9. Un monedero en Amazon México por cliente con un valor de $500.00 MXN.

      Proceso para recibir el monedero:
      10.	Para hacer válida la promoción recibirás un correo dentro de los primeros 05 días hábiles con los pasos a seguir, revisa tu bandeja de spam o promociones, en caso de no recibirlo comunícate al 55 44246837.
      11.	Se enviará tu monedero en Amazon México por correo a los 60 días naturales posteriores a tu contratación, se indicará la fecha por correo electrónico. 
      12.	El monedero en Amazon México  se enviará al correo electrónico que el titular de la póliza haya registrado al momento de la contratación en inter.mx
      13.	La póliza tendrá que estar pagada y vigente antes del envío del monedero en Amazon México.
      14.	En caso de no realizar el pago de la póliza durante el periodo de la promoción, esta no será válida.
      15.	La información al momento de contratación deberá corresponder al titular de la póliza para hacer válida la promoción.
      16.	Aplica únicamente para nuevas contrataciones de Coche Seguro o Moto Segura, no aplica en renovaciones.
      17.	Localización y contacto con el titular y beneficiarios por los distintos canales de comunicación empleados por inter.mx ya sea correo, SMS, WhatsApp, entre otros.`
    },
    {
      head: 'INTER DIGITAL: COCHE SEGURO y MOTO SEGURA',
      body: `Vigencia de la promoción:

      1. Aplica para pólizas de Coche Seguro contratadas 100% digital en inter.mx con cobertura amplia del sábado 01 de junio del 2024 hasta el domingo 30 de junio del 2024 antes de las 23:59, tiempo del centro o hasta agotar existencias.
      
      Límite de clientes:
      2. Válido para las primeras 150 personas en contratar dentro del período de la promoción su seguro de coche directa y exclusivamente en nuestro portal inter.mx
      3. Válido solo para 150 monederos en Amazon México con un valor de $500.00 MXN.
      4. Únicamente aplica para pólizas de Coche Seguro contratadas 100% digital con cobertura amplia y pago de contado dentro de inter.mx aplicando su código de promoción personalizado.

      Para ser considerado como cliente participante:
      5. Aplica únicamente para contrataciones 100% digitales directo en nuestro portal inter.mx
      6. Se deberá pagar de contado o 06 y 12 meses sin intereses (con tarjetas participantes) en el periodo de vigencia del código personalizado que aparecerá en la ventana emergente del portal inter.mx 
      7. No aplica planes de pago mensual, trimestral y semestral (diferidos en la plataforma digital de seguros inter.mx).
      8. Aplica únicamente para pólizas pagadas con el código personalizado que se brinde al momento de cotizar su seguro como ventana emergente y aplicándolo al contratar, en el campo “código de promoción”.
      9. No aplica para contrataciones donde intervenga un asesor. 
      10. Únicamente se enviará el monedero en Amazon México a aquellas pólizas que estén totalmente pagadas de contado dentro del período de vigencia del código de promoción personalizado, de lo contrario no aplica.

      Beneficio por cliente:
      11.	Un monedero en Amazon México por cliente con un valor de $500.00 MXN.

      Proceso para recibir el monedero:
      12.	Para hacer válida la promoción recibirás un correo dentro de los primeros 05 días hábiles con los pasos a seguir, revisa tu bandeja de spam o promociones, en caso de no recibirlo comunícate al 55 44246837.
      13.	Se enviará tu monedero en Amazon México por correo a los 30 días naturales posteriores a tu contratación, se indicará la fecha por correo electrónico. 
      14.	El monedero en Amazon México  se enviará al correo electrónico que el titular de la póliza haya registrado al momento de la contratación en inter.mx
      15.	La póliza tendrá que estar pagada y vigente antes del envío del monedero en Amazon México.
      16.	En caso de no realizar el pago de la póliza durante el periodo de la promoción, esta no será válida.
      17.	La información al momento de contratación deberá corresponder al titular de la póliza para hacer válida la promoción.
      18.	Aplica únicamente para nuevas contrataciones de Coche Seguro, no aplica en renovaciones.
      19.	Localización y contacto con el titular y beneficiarios por los distintos canales de comunicación empleados por inter.mx ya sea correo, SMS, WhatsApp, entre otros.`
    },
    {
      head: 'PROMO GP de Canadá',
      body: `Vigencia de la promoción:

      1. Aplica para pólizas de Coche Seguro y Moto Segura contratadas en inter.mx con cobertura amplia del lunes 03 de junio del 2024 hasta el domingo 09 de junio del 2024 antes de las 11:59 hrs, tiempo del centro o hasta agotar existencias.
      
      Límite de clientes:
      2. Válido para las primeras 150 personas en contratar dentro del período de la promoción su seguro de coche o moto directamente en inter.mx
      3. Válido solo para 150 monederos en Amazon México con un valor de $500.00 MXN.
      4. Únicamente aplica para pólizas de Coche Seguro y Moto Segura contratadas con cobertura amplia dentro del sitio inter.mx, aplicando el código de la promoción, según el medio por el que se conoce la misma.

      Para ser considerado como cliente participante:
      5. Se deberá pagar de contado o 06 y 12 meses sin intereses (con tarjetas participantes) únicamente por el periodo de la promoción. 
      6. No aplica planes de pago mensual, trimestral y semestral (diferidos en la plataforma digital de seguros inter.mx).
      7. Aplica para los códigos INTERWIN (canales digitales) e INTERPROMO (T.V. y medios masivos). Al momento de la contratación en el campo “código de promoción”.
      8. Únicamente se enviará el monedero en Amazon México a aquellas pólizas que estén totalmente pagadas de contado dentro del período de la promoción, de lo contrario no aplica.

      Beneficio por cliente:
      9. Un monedero en Amazon México por cliente con un valor de $500.00 MXN, por contratar tu póliza durante el periodo de la promoción.
      10. Si Checo Pérez queda en primer lugar en el Gran Premio de Canadá  2024, obtendrás el equivalente a $1,000.00 MXN en Amazon México.
      11. Cada beneficio corresponde a los diferentes resultados de la carrera respectivamente. Los beneficios son individuales, no son acumulables.
      12. Si la carrera correspondiente a esta promoción es cancelada, independientemente de la circunstancia; serás acreedor al beneficio que no involucra la posición del piloto Sergio Pérez en la carrera.

      Proceso para recibir el monedero:
      13.	Para hacer válida la promoción recibirás un correo dentro de los primeros 05 días hábiles con los pasos a seguir, revisa tu bandeja de spam o promociones, en caso de no recibirlo comunícate al 5544246837.
      14.	Se enviará tu monedero en Amazon México por correo a los 60 días naturales posteriores a tu contratación, se indicará la fecha por correo electrónico. 
      15.	El monedero en Amazon México  se enviará al correo electrónico que el titular de la póliza haya registrado al momento de la contratación en inter.mx
      16.	La póliza tendrá que estar pagada y vigente antes del envío del monedero en Amazon México.
      17.	En caso de no realizar el pago de la póliza durante el periodo de la promoción, esta no será válida.
      18.	La información al momento de contratación deberá corresponder al titular de la póliza para hacer válida la promoción.
      19.	Aplica únicamente para nuevas contrataciones de Coche Seguro o Moto Segura, no aplica en renovaciones.
      20.	Localización y contacto con el titular y beneficiarios por los distintos canales de comunicación empleados por inter.mx ya sea correo, SMS, WhatsApp, entre otros.`
    },
    {
      head: 'PROMO GP de Mónaco',
      body: `Vigencia de la promoción:

      1. Aplica para pólizas de Coche Seguro y Moto Segura contratadas en inter.mx con cobertura amplia del lunes 20 de mayo del 2024 hasta el domingo 26 de mayo del 2024 antes de las 06:59 hrs, tiempo del centro o hasta agotar existencias.
      
      Límite de clientes:
      2. Válido para las primeras 150 personas en contratar dentro del período de la promoción su seguro de coche o moto directamente en inter.mx
      3. Válido solo para 150 monederos en Amazon México con un valor de $1,000.00 MXN.
      4. Únicamente aplica para pólizas de Coche Seguro y Moto Segura contratadas con cobertura amplia dentro del sitio inter.mx, aplicando el código de la promoción, según el medio por el que se conoce la misma.

      Para ser considerado como cliente participante:
      5. Se deberá pagar de contado o 06 y 12 meses sin intereses (con tarjetas participantes) únicamente por el periodo de la promoción. 
      6. No aplica planes de pago mensual, trimestral y semestral (diferidos en la plataforma digital de seguros inter.mx).
      7. Aplica para los códigos INTERWIN (canales digitales) e INTERPROMO (T.V. y medios masivos). Al momento de la contratación en el campo “código de promoción”.
      8. Únicamente se enviará el monedero en Amazon México a aquellas pólizas que estén totalmente pagadas de contado dentro del período de la promoción, de lo contrario no aplica.

      Beneficio por cliente:
      9. Un monedero en Amazon México por cliente con un valor de $1,000.00 MXN, por contratar tu póliza durante el periodo de la promoción.
      10. Si Checo Pérez queda en primer lugar en el Gran Premio de Mónaco  2024, obtendrás el equivalente a $2,500 MXN en Amazon México.
      11. Cada beneficio corresponde a los diferentes resultados de la carrera respectivamente. Los beneficios son individuales, no son acumulables.
      12. Si la carrera correspondiente a esta promoción es cancelada, independientemente de la circunstancia; serás acreedor al beneficio que no involucra la posición del piloto Sergio Pérez en la carrera.

      Proceso para recibir el monedero:
      13.	Para hacer válida la promoción recibirás un correo dentro de los primeros 05 días hábiles con los pasos a seguir, revisa tu bandeja de spam o promociones, en caso de no recibirlo comunícate al 5544246837.
      14.	Se enviará tu monedero en Amazon México por correo a los 60 días naturales posteriores a tu contratación, se indicará la fecha por correo electrónico. 
      15.	El monedero en Amazon México  se enviará al correo electrónico que el titular de la póliza haya registrado al momento de la contratación en inter.mx
      16.	La póliza tendrá que estar pagada y vigente antes del envío del monedero en Amazon México.
      17.	En caso de no realizar el pago de la póliza durante el periodo de la promoción, esta no será válida.
      18.	La información al momento de contratación deberá corresponder al titular de la póliza para hacer válida la promoción.
      19.	Aplica únicamente para nuevas contrataciones de Coche Seguro o Moto Segura, no aplica en renovaciones.
      20.	Localización y contacto con el titular y beneficiarios por los distintos canales de comunicación empleados por inter.mx ya sea correo, SMS, WhatsApp, entre otros.`
    },
    {
      head: 'HOT SALE 2024: COCHE SEGURO Y MOTO SEGURA',
      body: `Vigencia de la promoción:

      1. Aplica para pólizas de Coche Seguro y Moto Segura contratadas en inter.mx con cobertura amplia del miércoles 15 de mayo de 2024 hasta el jueves 23 de mayo de 2024 antes de las 23:59, tiempo del centro o hasta agotar existencias.
      
      Límite de clientes:
      2. Válido para las primeras 150 personas en contratar dentro del período de la promoción su seguro de coche directamente en inter.mx
      3. Válido solo para 150 monederos en Amazon México con un valor de $1,000.00 MXN.
      4. Únicamente aplica para pólizas de Coche Seguro o Moto Segura contratadas con cobertura amplia dentro de inter.mx aplicando el código de la promoción.

      Para ser considerado como cliente participante:
      5. Se deberá pagar de contado o 06 y 12 meses sin intereses (con tarjetas participantes) en el periodo de la promoción. 
      6. No aplica planes de pago mensual, trimestral y semestral (diferidos en la plataforma digital de seguros inter.mx).
      7. Aplica únicamente para pólizas pagadas con el código “HOTSALE24” al momento de contratar en el campo “código de promoción”.
      8. Únicamente se enviará el monedero en Amazon México a aquellas pólizas que estén totalmente pagadas de contado dentro del período de la promoción, de lo contrario no aplica.

      Beneficio por cliente:
      9. Un monedero en Amazon México por cliente con un valor de $1,000.00 MXN. 

      Proceso para recibir el monedero:
      10.	Para hacer válida la promoción recibirás un correo dentro de los primeros 05 días hábiles con los pasos a seguir, revisa tu bandeja de spam o promociones, en caso de no recibirlo comunícate al 55 44246837.
      11.	Se enviará tu monedero en Amazon México por correo a los 60 días naturales posteriores a tu contratación, se indicará la fecha por correo electrónico. 
      12.	El monedero en Amazon México  se enviará al correo electrónico que el titular de la póliza haya registrado al momento de la contratación en inter.mx
      13.	La póliza tendrá que estar pagada y vigente antes del envío del monedero en Amazon México.
      14.	En caso de no realizar el pago de la póliza durante el periodo de la promoción, esta no será válida.
      15.	La información al momento de contratación deberá corresponder al titular de la póliza para hacer válida la promoción.
      16.	Aplica únicamente para nuevas contrataciones de Coche Seguro o Moto Segura, no aplica en renovaciones.
      17.	Localización y contacto con el titular y beneficiarios por los distintos canales de comunicación empleados por inter.mx ya sea correo, SMS, WhatsApp, entre otros.`
    }
  ]

  const [expanded, setExpanded] = useState('');
  const [selectedTerm, setSelectedTerm] = useState('');
  const [start, setStart] = useState(0);
  //const [openNotice, setOpenNotice] = useState(false);

  const handleChange = (termino) => (event, newExpanded) => {
    //setOpenNotice(true);
    setExpanded(newExpanded ? termino : false);
    setSelectedTerm(termino);
    let infoterminos = document.getElementsByClassName('info');
    for (let i = 0; i < infoterminos.length; i++) {
      infoterminos[i].style.display = "block";
    }
    let terminos = document.getElementsByClassName('terminos');
    for (let i = 0; i < terminos.length; i++) {
      if (terminos[i].id !== termino) {
        terminos[i].style.display = "none";
      }
    }
    document.getElementById(termino + 'd-header').style.pointerEvents = "none";
  }

  const showAllTerms = (term) => (e) => {
    setExpanded(false);
    let infoterminos = document.getElementsByClassName('info');
    for (let i = 0; i < infoterminos.length; i++) {
      infoterminos[i].style.display = "none";
    }
    let terminos = document.getElementsByClassName('terminos');
    for (let i = 0; i < terminos.length; i++) {
      terminos[i].style.display = "block";
    }
    document.getElementById(term + 'd-header').style.pointerEvents = "auto";
  };

  const getFormat = (data) => {
    let text
    let html = []
    return data.split(String.fromCharCode(10)).map((data, index) => {
      text = data.split(String.fromCharCode(9))
      html = []
      if (text.length > 1) {
        if (data.includes("<b>")) {
          let otherText = data.split("<b>")
          let endText
          otherText.map((t, i) => {
            endText = t.split("</b>")
            if (endText.length > 1) {
              html.push(<><b>{endText[0]}</b>{endText[1]}</>)
            } else {
              html.push(<>{t}</>)
            }
            return true
          })
          return <div className='p-1' >{
            html.map((v, i) => {
              return <span key={i} >{v}</span>
            })
          }</div>
        } else {
          return <div className='p-1'>{data}</div>
        }
      } else {
        text = data.split('.')
        if (text.length === 1) {
          return <div className='font-bold p-1'>{data}</div>
        } else {
          if (data.includes("<b>")) {
            let otherText = data.split("<b>")
            let endText
            otherText.map((t, i) => {
              endText = t.split("</b>")
              if (endText.length > 1) {
                html.push(<><b>{endText[0]}</b>{endText[1]}</>)
              } else {
                html.push(<>{t}</>)
              }
              return true
            })
            return <div className='p-1' >{
              html.map((v, i) => {
                return <span key={i} >{v}</span>
              })
            }</div>
          } else {
            return <div className='p-1'>{data}</div>
          }
        }
      }
    })
  }

  return (
    <Box sx={{
      paddingLeft: { xs: '16px', sm: '16px', md: '36px', lg: '247px', xl: '247px' },
      paddingRight: { xs: '16px', sm: '16px', md: '36px', lg: '247px', xl: '247px' }
    }} >
      {
        expanded &&
        <Box sx={{ width: '100%', paddingTop: '40px' }}>
          <Button sx={{ flexDirection: 'row' }}
            onClick={showAllTerms(selectedTerm)}>
            <ArrowBackIcon sx={{ color: '#039ECC' }} />
            <Typography
              sx={{
                color: '#039ECC',
                fontFeatureSettings: { clig: 'off', liga: 'off' },
                fontFamily: 'Montserrat',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 600,
                /* lineHeight: '24px' */
              }}>
              Regresar</Typography>
          </Button>
        </Box>
      }
      {
        text.splice(start, text.length).map((data, index) => {
          return (
            <Accordion
              id={`termino${index}`}
              key={index}
              expanded={expanded === `termino${index}`}
              onChange={handleChange(`termino${index}`)}
              className="terminos">
              <AccordionSummary
                aria-controls={`termino${index}d-content`} id={`termino${index}d-header`}
                expandIcon={expanded === `termino${index}` ? <></> : <ChevronRightIcon />}
              >
                <div className='header-terminoinfo'>{data.head}</div>
              </AccordionSummary>
              <AccordionDetails className='info'>
                <Typography>
                  {getFormat(data.body)}
                </Typography>
              </AccordionDetails>
            </Accordion>)
        })
      }
    </Box>
  );
}