import { useEffect } from 'react';
import DefaultNavbar from 'components/DefaultNavbar';
import DefaultFooter from 'components/DefaultFooter';
import Header from 'components/blog/Header';
import BlogSection from 'components/blog/BlogSection';

export default function Blog() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
            <DefaultNavbar />
            <main>
              <Header />
              <BlogSection />
            </main>
            <DefaultFooter />
        </>
    );
}
