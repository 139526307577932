import React from 'react';
import {
    Box,
    Container,
    Stack,
    Grid,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Mani from '../../assets/img/landing/Mani-Poses.png';
import Dot from '../../assets/img/icons/dot-dashedLine.svg';
import Dot2 from '../../assets/img/icons/dot.svg';
import Google from '../../assets/img/marketing/google-black.webp';
import Apple from '../../assets/img/marketing/apple-black.webp';

export default function ContractwithUs() {
    let letter = {
        fontSize: '16px',
        lineHeight: '19.5px',
        color: '#212121',
        fontFamily: 'Montserrat',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        /* textAlign:'justify' */
    };

    return (
        <Box
            /*  maxWidth={!isTablet ? 'xl' : !isMobile ? 'md' : 'sm'} */
            sx={{
                /* height: !isTablet ? '866px' : !isMobile ? '632px' : '1069px', */
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                margin: 0,
                paddingLeft: { xs: 1.875, sm: 1.875, md: 4.375, lg: 18.75, xl: 18.75 },
                paddingRight: { xs: 1.875, sm: 1.875, md: 4.375, lg: 18.75, xl: 18.75 },
            }}>
            <Grid container
                /* height={!isTablet ? '826px' : !isMobile ? '592px' : '988px'} */
                sx={{
                    width: '100%',
                    paddingTop: 10,
                    paddingBottom: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    /* flexWrap: { xs: 'nowrap', sm: 'nowrap', md: 'wrap', lg: 'wrap', xl: 'wrap' } */
                    /* flexWrap: !isMobile ? 'nowrap' : 'wrap', */
                }}>
                <Typography
                    /* component="h2" */
                    sx={{
                        width: '100%',
                        fontWeight: 700,
                        fontSize: 24,
                        lineHeight: 'normal',
                        color: '#212121',
                        textAlign: 'center',
                        fontFamily: 'Montserrat',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        lineHeight: 'normal',
                        paddingBottom: 5
                    }}><h2>¿Por qué contratar con nosotros?</h2>
                </Typography>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        textAlign="center"
                        height={'100%'}>
                        <img src={Mani}/>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Box
                        sx={{
                            /*  width: !isTablet ? '532px' : '333px',
                             height: isMobile || isTablet ? '464px' : '345px', */
                            alignSelf: 'center',
                            paddingTop:{xs:'40px',sm:'40px',md:'0px',lg:'0px',xl:'0px'},
                        }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}>
                            <Box sx={{ display: 'flex'}}>
                                <Box
                                    component={'img'}
                                    loading='lazy'
                                    src={Dot}
                                    sx={{ width: '20px', height: { xs: '125px', sm: '125px', md: '70px', lg: '120px', xl: '120px' } }}
                                ></Box>
                                <Typography sx={letter} paddingLeft={1}>
                                    <b>En inter.mx siempre estamos disponibles</b> para ayudarte en lo que necesites. Por eso nos puedes contactar a través del medio de contacto que tu prefieras.
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex'}}>
                                <Box
                                    component={'img'}
                                    loading='lazy'
                                    src={Dot}
                                    sx={{ width: '20px', height: { xs: '125px', sm: '125px', md: '70px', lg: '120px', xl: '120px' } }}
                                ></Box>
                                <Typography sx={letter} paddingLeft={1}>
                                    <b>Sabemos que tu tiempo vale oro,</b> por eso te brindamos un servicio ágil, acompañándote en todo momento.
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex'}}>
                                <Box
                                    component={'img'}
                                    loading='lazy'
                                    src={Dot}
                                    sx={{ width: '20px', height: { xs: '125px', sm: '125px', md: '70px', lg: '120px', xl: '120px' } }}
                                ></Box>
                                <Typography sx={letter} paddingLeft={1}>
                                    <b>¡No te quedas con dudas!</b> Nosotros te diremos todo lo que necesitas saber sobre el mundo de los seguros.
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex'}}>
                                <Box
                                    component={'img'}
                                    loading='lazy'
                                    src={Dot}
                                    sx={{ width: '20px', height: { xs: '125px', sm: '125px', md: '70px', lg: '120px', xl: '120px' } }}
                                ></Box>
                                <Typography sx={letter} paddingLeft={1}>
                                    Amamos la empatía, ya que es uno de nuestros valores más importantes. <b>¡Siempre estaremos contigo para que tu experiencia en el mundo de los seguros sea la mejor!</b>
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex'}}>
                                <Box
                                    component={'img'}
                                    loading='lazy'
                                    src={Dot2}
                                    sx={{ width: '20px', height: { xs: '20px', sm: '20px', md: '20px', lg: '20px', xl: '20px' } }}
                                ></Box>
                                <Typography sx={letter} paddingLeft={1}>
                                    No tienes que preocuparte por no tener a la mano la información de tus seguros, <b>ya que siempre la llevas contigo en nuestra app.</b>
                                </Typography>
                            </Box>
                            <Box style={{ display: 'flex', justifyContent: 'center',paddingTop:'16px'}} >
                                <Box style={{ paddingRight: 8 }} onClick={() => { 
                                    window.dataLayer.push({
                                        event: 'InterMx_descarga_app',
                                        option: 'Google Play'
                                    })
                                    window.location.href = 'https://play.google.com/store/apps/details?id=grut.inter.mx' 
                                }}>
                                    <img src={Google} className='cursor-pointer' style={{ margin: 'left' }} ></img>
                                </Box>
                                <Box style={{ paddingLeft: 8 }} onClick={() => { 
                                    window.dataLayer.push({
                                        event: 'InterMx_descarga_app',
                                        option: 'Apple Store'
                                    })
                                    window.location.href = 'https://apps.apple.com/mx/app/intermx/id1548367767?l=en' 
                                }}>
                                    <img src={Apple} className='cursor-pointer' style={{ margin: 'auto' }} ></img>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};
