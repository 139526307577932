import { ReactComponent as Check } from 'assets/img/icons/check.svg'
import { Grid, ListItem, ListItemAvatar, ListItemIcon, ListItemText } from "@mui/material";

export default function MedicalAssitance () {
  const plan = {
    one:[{
      text:`Orientación médica telefónica - 24/7`,
      bold: ``
    },{
      text: `Médico en sitio sin costo`,
      bold: 'hasta $1,000 MXN (No aplica para COVID)'
    },{
      text: `Ambulancia terrestre por emergencia`,
      bold: 'hasta $2,500 MXN'
    }
  ],
    two:[{
      text: `2 Servicios de telemedicina`,
      bold: ``
    },{
      text: `Ambulancia aérea por emergencia hasta 3,500 USD`,
      bold: ``
    },{
      text: `Hospitalización por accidente o enfermedad repentina |`,
      body: 'Hasta $50,000 MXN'
    }],
    three:[{
      text: `Costos preferenciales en Laboratorios, Farmacias, Clínicas, Enfermeras
      etc. Con descuentos desde el 5% hasta el 30% según el proveedor.`,
      bold: ``
    }]
  }
  return (
    <section className="pb-5 bg-gray-100">
    <div className="container md:w-10/12 mx-auto px-4">
        <div className='bg-white rounded-lg'>
          <div className='p-2'>
            <div className='p-5 text-center uppercase font-20 font-bold' style={{borderBottom: '1px solid #C7C7C7'}} >Asistencias medica</div>
            <div>
              <Grid container>
                <Grid item xs={12} sm={4}>
                {
                  plan.one.map((data, index) => {
                    return (
                    <ListItem key={index}>
                      <ListItemAvatar>
                        <ListItemIcon>
                          <Check />
                        </ListItemIcon>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <div style={{marginLeft: -25}}>{data.text} <span className='font-bold'>{data.bold}</span></div>
                        }
                      />
                    </ListItem>)
                  })
                }
                </Grid>
                <Grid item xs={12} sm={4}>
                {
                  plan.two.map((data, index) => {
                    return (
                    <ListItem key={index}>
                      <ListItemAvatar>
                        <ListItemIcon>
                          <Check />
                        </ListItemIcon>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <div style={{marginLeft: -25}}>{data.text} <span className='font-bold'>{data.bold}</span></div>
                        }
                      />
                    </ListItem>)
                  })
                }
                </Grid>
                <Grid item xs={12} sm={4}>
                {
                  plan.three.map((data, index) => {
                    return (
                    <ListItem key={index}>
                      <ListItemAvatar>
                        <ListItemIcon>
                          <Check />
                        </ListItemIcon>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <div style={{marginLeft: -25}}>{data.text} <span className='font-bold'>{data.bold}</span></div>
                        }
                      />
                    </ListItem>)
                  })
                }
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}