import React from "react";
import { Typography } from "@mui/material";
import Icon from "../../assets/img/landingRedisign/promotions.png";
import "./Promotions.css";

const Header = (props) => {
  const { width } = props;

  return (
    <div className="relative justify-center promotions-mobile">
      <div className="flex items-center justify-center promotions-container">
        {width <= 500 && (
          <>
            <p className="promotions-title">Promociones</p>
            <div className="promotions-mobile-img">
              <img src={Icon} className="promotions-img" />
            </div>
          </>
        )}

        {width > 500 && (
          <>
            <div className="promotions-mobile-img">
              <img src={Icon} className="promotions-img" />
            </div>
            <p className="promotions-title">Promociones</p>
          </>
        )}
      </div>
      <div className="promotions-description">
        <Typography>
          Aquí puedes revisar todas las promos y asegurarte de haber obtenido
          los beneficios al contratar tu seguro con nosotros.
        </Typography>
      </div>
    </div>
  );
};

export default Header;
