import React, { useEffect, useState } from "react";
import FinderInput from "components/common/FinderInput";
import axios from "axios";
import "./Promotions.css";
import { Typography } from "@mui/material";
import PaginationComponent from "components/common/PaginationComponent";
/** TEMPORAL */
import Promo01 from "assets/img/landingRedisign/Promociones/Seccion_promos_activaMobile_4_Septiembre.webp";
import Promo02 from "assets/img/landingRedisign/Promociones/Seccion_promos_pasada_04_Septiembre.webp";
import Promo03 from "assets/img/landingRedisign/Promociones/Seccion_promos_pasada_28_agosto.webp";
import Promo04 from "assets/img/landingRedisign/Promociones/Seccion_promos_pasada_2_agosto_ms.webp";
import Promo05 from "assets/img/landingRedisign/Promociones/Seccion_promos_pasada_2_agosto_cs.webp";
import Promo06 from "assets/img/landingRedisign/Promociones/Seccion_promos_pasada.webp";
import Promo07 from "assets/img/landingRedisign/Promociones/Seccion_promos_pasada.webp";
import Promo08 from "assets/img/landingRedisign/Promociones/CS-RaceWeek-LasVegas(330x330).webp";

import { ImaganesPromos } from "components/common/CommonImages";
import { useAuth } from "hooks/useAuth";

const Promotions = (props) => {
  const { width } = props;
  const [promotionsData, setPromotionsData] = useState({});
  const [currentData, setCurrentData] = useState([]);
  const [lastData, setLastData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [currentLastData, setCurrentLastData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const {token} = useAuth()

  useEffect(() => {
    // Función para hacer la solicitud a la URL
    const getPromotions = async () => {
      try {
        const response = await axios.get(
          "https://api.inter.mx/v1/catalogs/promo-banner",
          {headers:{Authorization: `Bearer ${token}`}}
        );
        setPromotionsData(response.data);

        let current = [],
          last = [];
        response.data.data.map((item) => {
          // Promocion vencida
          if (new Date(item.endDate) < new Date() && item.title !== "Banner") {
            last.push(item);
          }
          // Promocion en curso
          if (new Date(item.endDate) > new Date() && item.title !== "Banner") {
            current.push(item);
          }
        });
        setPromotionsData(response.data);
        setCurrentData(current);
        setLastData(last);
        setCurrentLastData(last.slice(0, 3));
      } catch (error) {
        console.error("Error al hacer la solicitud:", error);
      }
    };

    getPromotions(); // Llama a la función para obtener los datos solo una vez al montar el componente
  }, [token]); // El array de dependencias vacío asegura que useEffect se ejecute solo una vez

  useEffect(() => {
    let current = [],
      last = [];
    if (inputValue.length > 2) {
      const searchResults = promotionsData.data.filter((item) => {
        const titleMatches = item.title
          .toLowerCase()
          .includes(inputValue.toLowerCase());
        const descriptionMatches = item.description
          .toLowerCase()
          .includes(inputValue.toLowerCase());

        return titleMatches || descriptionMatches;
      });

      searchResults.map((item) => {
        // Promocion vencida
        if (new Date(item.endDate) < new Date()) {
          last.push(item);
        }
        // Promocion en curso
        if (new Date(item.endDate) > new Date()) {
          current.push(item);
        }
      });
    } else if (promotionsData.data?.length) {
      promotionsData.data.map((item) => {
        // Promocion vencida
        if (new Date(item.endDate) < new Date()) {
          last.push(item);
        }
        // Promocion en curso
        if (new Date(item.endDate) > new Date()) {
          current.push(item);
        }
      });
    }
    setCurrentData(current);
    setLastData(last);
    setCurrentLastData(last.slice(0, 3));
  }, [inputValue]);

  useEffect(() => {
    const startIndex = (currentPage - 1) * 3;
    const endIndex = startIndex + 3;
    if (lastData.length) {
      setCurrentLastData(lastData.slice(startIndex, endIndex));
    }
    return () => {};
  }, [currentPage]);

  /** Codigo temporal para imagenes */
  const imagenes = {
    1: Promo01,
    2: Promo02,
    3: Promo03,
    4: Promo04,
    5: Promo05,
    6: Promo06,
    7: Promo07,
    8: Promo08,
    ...ImaganesPromos,
  };

  return (
    <div className="pb-8">
      <FinderInput setInputValue={setInputValue} />
      <div className="flex justify-center">
        <div style={{ width: "950px" }}>
          <p className="promotions-txt p-8 text-center">Promociones vigentes</p>
          {currentData.length > 0 &&
            currentData.map((item, index) => {
              return (
                <div
                  className={`${width > 500 ? "flex items-center" : ""} py-10`}
                  style={
                    index + 1 === currentData.length
                      ? {}
                      : { borderBottom: "1px solid #C7C7C7" }
                  }
                >
                  {width > 500 ? (
                    <img
                      src={
                        item?.image.length < 10
                          ? imagenes[item.image]
                          : item.image
                      }
                    />
                  ) : (
                    <div className="flex justify-center mb-6">
                      <img
                        src={
                          item?.image.length < 10
                            ? imagenes[item.image]
                            : item.image
                        }
                      />
                    </div>
                  )}
                  <div className="pl-8">
                    <Typography style={{ fontWeight: "700" }} className="pb-8">
                      {item.title}
                    </Typography>
                    <div
                      dangerouslySetInnerHTML={{ __html: item.description }}
                      className="mb-4"
                      style={width > 500 ? {} : { marginRight: "16px" }}
                    />
                    {/* <Typography style={ width > 500 ? {} : {marginRight: '16px'}}>{item.description}</Typography> */}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="flex justify-center pb-8">
        <div style={{ opacity: "0.5", width: "950px" }}>
          <p className="promotions-txt text-center">Promociones pasadas</p>
          {currentLastData.length > 0 &&
            currentLastData.map((item, index) => {
              return (
                <div
                  className={`${width > 500 ? "flex" : ""} items-center py-10`}
                  style={
                    index + 1 === lastData.length
                      ? {}
                      : { borderBottom: "1px solid #C7C7C7" }
                  }
                  key={index}
                >
                  {
                    width > 500 ? (
                      <img
                        src={
                          item?.image.length < 10
                            ? imagenes[item.image]
                            : item.image
                        }
                      />
                    ) : (
                      <div className="flex justify-center mb-6">
                        <img
                          src={
                            item?.image.length < 10
                              ? imagenes[item.image]
                              : item.image
                          }
                        />
                      </div>
                    )
                    // <img src={imagenes[item.image]} /> :
                    // <div className='flex justify-center mb-6'>
                    //  <img src={imagenes[item.image]} />
                    // </div>
                  }
                  <div className="pl-8">
                    <Typography style={{ fontWeight: "700" }} className="pb-8">
                      {item.title}
                    </Typography>
                    <Typography
                      style={width > 500 ? {} : { marginRight: "16px" }}
                    >
                      {item.description}
                    </Typography>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <PaginationComponent
        data={lastData}
        showData={3}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export default Promotions;
