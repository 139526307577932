import { useEffect } from 'react';
import DefaultNavbar from 'components/DefaultNavbar';
import DefaultFooter from 'components/DefaultFooter';
import Header from 'components/blogInfo/Header';
import WorkingSection from 'components/blogInfo/WorkingSection';
import Legend from 'components/blogInfo/Legend';
import BlogSection from 'components/blogInfo/BlogSection';

export default function BlogInfo() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
            <DefaultNavbar />
            <main>
              <Header />
              <WorkingSection />
              <Legend />
              <BlogSection />
            </main>
            <DefaultFooter />
        </>
    );
}
