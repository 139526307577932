import { Grid} from '@mui/material';
import { ReactComponent as SOS } from 'assets/img/sos-section/sos.svg';

export default function SosSection() {
    return (
        <section className="pb-5 bg-gray-100">
            <div className="container md:w-10/12 mx-auto px-4">
              <div style={{backgroundColor: '#039ECC'}} className='rounded-lg'>
                <div className='pb-5'>
                    <Grid container columns={12} justifyContent='center' alignItems='center' direction='row'>
                      <Grid item xs={12} sm={5}>
                        <SOS style={{margin: 'auto', width:250 , height: 250, padding: 15}}></SOS>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div className='text-white text-center mx-auto px-5'>
                          <Grid container>
                            <Grid item xs={12} >
                              <div className='font-bold font-32' >
                                ¿Tienes una emergencia?
                              </div>
                            </Grid>
                            <Grid item xs={12}>
                              <div className='font-18' >
                                Nosotros te ayudamos, comunícate al:
                              </div>
                            </Grid>
                            <Grid item xs={12}>
                              <div className='font-28 font-bold'>
                                +800 224 9272
                              </div>
                            </Grid>
                            <Grid item xs={12}>
                              <div>
                                desde cualquier parte de México
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                      <Grid xs={12} sm={1} ></Grid>
                    </Grid>
                </div>
              </div>
            </div>
        </section>
    );
}
