import { useEffect, useState } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import DetalleReciboSV from 'components/cobranzasv/DetalleReciboSV';
import PagoExitoso from 'components/cobranza/PagoExitoso';
import ReciboInfo from 'components/cobranza/ReciboInfo';
import Inactividad from 'components/cobranza/Inactividad';
import PlataformaMitec from 'components/cobranza/PlataformaMitec';
import PagoExitosoDowApp from 'components/cobranzasv/PagoExitosoDowApp';
import axios from 'axios';
import styles from './DetallesLigaPagoSV.module.css';
import { PaymentScreens, ReciboInfoType, PaymentLinkType, ContactPhoneSV } from 'components/cobranza/CobranzaEnum';

const api_url = 'https://k8s-prod.inter.mx';

export default function DetallesLigaPagoSV({ idLigaPago }) {
  const [lastActivity, setLastActivity] = useState(Date.now());
  const [futurePayment, setFuturePayment] = useState(false);
  const [isLoadingContinueMitPayment, setIsLoadingContinueMitPayment] = useState(false);
  const [verifyingPayment, setVerifyingPayment] = useState(false);
  const [isLoadingActionErrorLiga, setIsLoadingActionErrorLiga] = useState(false);
  const [countRetryGetPayment, setCountRetryGetPayment] = useState(0);

  const [loadingText, setLoadingText] = useState('');
  const [paymentStep, setPaymentStep] = useState('');
  const [validacionDetallesPago, setValidacionDetallesPago] = useState({
    is_loading: false,
    error_code: null,
    error_title: '',
    error_message: '',
  });
  const [ligaMitec, setLigaMitec] = useState(null);
  const [detallesPago, setDetallesPago] = useState(null);

  function handleFuturePayment(value) {
    setFuturePayment(value);
  }

  function handleUpdateLastActivity(value) {
    setLastActivity(value);
  }

  function handleInactividad() {
    setValidacionDetallesPago(prevState => ({
      ...prevState,
      error_code: ReciboInfoType.INACTIVIDAD,
      error_title: 'Has estado inactivo durante un tiempo.',
    }));
    setPaymentStep(PaymentScreens.INFO);
  }

  async function getPaymentDetails() {
    try {
      setLoadingText('Obteniendo detalles de recibo...');
      setValidacionDetallesPago(prevState => ({ ...prevState, is_loading: true }));
      const response_datalles = await axios.get(`${api_url}/servicios_cobranza_segurovida/payment_link/details?id_liga=${idLigaPago}`);
      const res = response_datalles.data;
      if (res?.success === true) {
        setValidacionDetallesPago(prevState => ({
          ...prevState,
          error_code: null,
          error_title: '',
        }));
        setDetallesPago(res.data.payment_detail);
        handleFuturePayment(res?.data?.payment_detail?.future_payment_check === true);
        setPaymentStep(PaymentScreens.RESUMEN);
        handleUpdateView();
      } else {
        // NO_ENCONTRADA = 1, EXPIRO = 2*, DESHABILITADA = 3, PAGADA = 4*, ERROR = 5
        let info_type = res?.data?.status || ReciboInfoType.INFO;
        let info_message = '';
        if (res?.success === false) {
          info_message = res?.message || '¡Lo sentimos no pudimos procesar su solicitud, por favor inténtalo más tarde!';
        } else {
          info_message = '¡Lo sentimos no pudimos procesar su solicitud, por favor inténtalo más tarde!';
        }
        if (res?.data?.receipt_expired === true) {
          info_type = ReciboInfoType.EXPIRO_RECIBO;
        }
        setValidacionDetallesPago(prevState => ({ ...prevState, error_code: info_type, error_title: info_message }));
        setPaymentStep(PaymentScreens.INFO);
      }
    } catch (error) {
      let info_type = error?.response?.data?.data?.status || ReciboInfoType.INFO;
      let info_message = '';
      if (error?.response?.data?.success === false) {
        info_message = error?.response?.data?.message || '¡Lo sentimos no pudimos procesar su solicitud, por favor inténtalo más tarde!';
      } else {
        info_message = '¡Lo sentimos no pudimos procesar su solicitud, por favor inténtalo más tarde!';
      }
      if (error?.response?.data?.data?.receipt_expired === true) {
        info_type = ReciboInfoType.EXPIRO_RECIBO;
      }
      setValidacionDetallesPago(prevState => ({ ...prevState, error_code: info_type, error_title: info_message }));
      setPaymentStep(PaymentScreens.INFO);
    } finally {
      setValidacionDetallesPago(prevState => ({ ...prevState, is_loading: false }));
      setLoadingText('');
    }
  }

  async function handleProcederPago() {
    try {
      if (isLoadingContinueMitPayment === true) {
        return;
      }
      setLoadingText('Estableciendo conexión segura para tu pago...');
      setIsLoadingContinueMitPayment(true);
      const data = {
        future_payments: futurePayment,
        payment_link_id: idLigaPago,
      }
      const response_continue = await axios.post(`${api_url}/servicios_cobranza_segurovida/payment_mit/continue`, data);
      const res = response_continue.data;
      
      if (res?.success === true) {
        setValidacionDetallesPago(prevState => ({ ...prevState, error_code: null, error_title: '' }));
        setLigaMitec(res.data.url);
        setPaymentStep(PaymentScreens.REALIZAR_PAGO);
      } else {
        setCountRetryGetPayment(countRetryGetPayment + 1);
        // ACTIVO = 1, DESHABILITADO_MANUAL = 2, DESHABILITADO_POR_PAGO_REALIZADO = 3, EXPIRADO = 4, ERROR_REINTENTAR = 6
        let info_type = ReciboInfoType.INFO;
        let info_message = '';
        if (res?.success === false) {
          info_message = res?.message || 'Lo sentimos, no pudimos acceder al portal de pago.';
        } else {
          info_message = 'Lo sentimos, no pudimos acceder al portal de pago.';
        }
        if (res?.data?.status === 4) {
          info_type = ReciboInfoType.EXPIRO_LIGA;
        } else if (res?.data?.retry === true) {
          info_type = ReciboInfoType.ERROR_LIGA_REINTENTO;
        }
        if (countRetryGetPayment >= 2) {
          info_type = ReciboInfoType.MAS_TARDE;
          info_message = 'Lo sentimos, no pudimos acceder al portal de pago, por favor inténtalo más tarde.';
        }
        setValidacionDetallesPago(prevState => ({ ...prevState, error_code: info_type, error_title: info_message }));
        setPaymentStep(PaymentScreens.INFO);
      }
    } catch (error) {
      setCountRetryGetPayment(countRetryGetPayment + 1);
      let info_type = ReciboInfoType.INFO;
      let info_message = '';
      if (error?.response?.data?.success === false) {
        info_message = error?.response?.data?.message || 'Lo sentimos, no pudimos acceder al portal de pago.';
      } else {
        info_message = 'Lo sentimos, no pudimos acceder al portal de pago.';
      }
      if (error?.response?.data?.data?.retry === true) {
        info_type = ReciboInfoType.ERROR_LIGA_REINTENTO;
      }
      if (countRetryGetPayment >= 2) {
        info_type = ReciboInfoType.MAS_TARDE;
        info_message = 'Lo sentimos, no pudimos acceder al portal de pago, por favor inténtalo más tarde.';
      }
      setValidacionDetallesPago(prevState => ({ ...prevState, error_code: info_type, error_title: info_message }));
      setPaymentStep(PaymentScreens.INFO);
    } finally {
      setIsLoadingContinueMitPayment(false);
      setLoadingText('');
    }
  }
  
  useEffect(() => {
    let interval;
    let timeout;
    if (verifyingPayment === true && paymentStep === PaymentScreens.REALIZAR_PAGO) {
      const checkStatus = async () => {
        try {
          const response_verify = await axios.get(`${api_url}/servicios_cobranza_segurovida/payment_link/verify_payment?id=${idLigaPago}`);
          const res = response_verify.data;
          if (res.success === true) {
            setVerifyingPayment(false);
            clearInterval(interval);
            setPaymentStep(PaymentScreens.PAGO_EXITOSO);
          }
        } catch (error) {
          
        }
      };
      interval = setInterval(checkStatus, 10000);

      timeout = setTimeout(() => {
        setVerifyingPayment(false);
        clearInterval(interval);
      }, 10 * 60 * 1000); // 10 minutos
    }

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [idLigaPago, paymentStep, verifyingPayment]);

  async function handleUpdateView() {
    try {
      await axios.get(`${api_url}/servicios_cobranza_segurovida/payment_link/count_view?id=${idLigaPago}`);
    } catch (error) {
      
    }
  }

  async function handleUpdateIframe() {
    setVerifyingPayment(true);
  }

  async function handleActionButton(action) {
    if (action === 'retry_error_liga') {
      setIsLoadingActionErrorLiga(true);
      await handleProcederPago();
      setIsLoadingActionErrorLiga(false);
    }
  }

  const PagoExitosoLiga = () => {
    if (detallesPago?.type === PaymentLinkType.CROSS_SELL) {
      return <PagoExitosoDowApp />
    } else {
      return <PagoExitoso />
    }
  }

  useEffect(() => {
    getPaymentDetails();
  }, [])

  return (
    <div className={styles['container']}>
      <Backdrop className={styles['container-loading']}
        open={validacionDetallesPago.is_loading || isLoadingContinueMitPayment}
      >
        <CircularProgress sx={{ color: '#039ECC' }} thickness={4.2} size={60} />
        <p className={styles['text-loading']}>{loadingText}</p>
      </Backdrop>
      {
        paymentStep === PaymentScreens.RESUMEN ? (
          <DetalleReciboSV
            detallesPago={detallesPago}
            futurePayment={futurePayment}
            loadingContinuePayment={isLoadingContinueMitPayment}
            onClickProcederPago={handleProcederPago}
            onChangeFuturePayment={handleFuturePayment}
          />
        ) : null
      }
      {
        paymentStep === PaymentScreens.REALIZAR_PAGO ? (
          <PlataformaMitec url_mitec={ligaMitec} onUpdateIframe={handleUpdateIframe} />
        ) : null
      }
      {
        paymentStep === PaymentScreens.INFO ? (
          <ReciboInfo
            type={validacionDetallesPago.error_code}
            title={validacionDetallesPago.error_title}
            message={validacionDetallesPago.error_message}
            isActionDisabled={isLoadingActionErrorLiga}
            ContactPhone={ContactPhoneSV}
            onClickAction={handleActionButton}
          />
        ) : null
      }
      {
        paymentStep === PaymentScreens.PAGO_EXITOSO ? (
          <PagoExitosoLiga />
        ) : null
      }
      {
        paymentStep === PaymentScreens.RESUMEN || paymentStep === PaymentScreens.REALIZAR_PAGO ? (
          <Inactividad lastActivity={lastActivity} onFinishTime={() => handleInactividad()} onUpdateLastActivity={(x) => handleUpdateLastActivity(x)} />
        ) : null
      }
    </div>
  );
}
