export const hospitals  = {
  "Aguascalientes": 
    {
      "Esencial": [
        "Biomaterna Clínica Ginecobstrética",
        "Clínica Guadalupe Aguascalientes",
        "Hospital Cardiológica De Ags.",
        "Médica San Juan",
        "Prestadora De Servicios Médico Quirúrgicos De Aguascalientes"
      ],
      "Protegido": [
        "Star Médica Aguascalientes"
      ],
      "Protegido +": [
        ""
      ],
      "Blindado": [
        ""
      ]
    },
  "Baja California Norte": 
    {
      "Esencial": [
        "Centro Médico Florence",
        "Clínica Arce Quiñones",
        "Clínica Express",
        "Clínica Robsan",
        "Clínica Santa María",
        "Cruz Roja Mexicana Delegación Tijuana",
        "Hospital Arcángeles",
        "Hospital Bonanova",
        "Hospital De La Familia De Grupo Mac",
        "Hospital De La Mujer Y Niño",
        "Hospital San Angel",
        "Hospital Santa Catalina De Tecate",
        "Hospital Santa Rosa De Lima",
        "Hospital Velmar",
        "Instituto De Investigaciones E Intervenciones Cardiovasculares De Tijuana",
        "Instituto De Oftamología",
        "Sanatorio Del Carmen De Ensenada",
        "Sanatorio San Francisco (Tijuana)"
      ],
      "Protegido": [
        "Cardiomed",
        "Centro Médico Excel",
        "Centro Médico Premier",
        "Hospital Almater",
        "Hospital Ángeles Tijuana",
        "Hospital Del Prado",
        "Hospital Hispano Americano"
      ],
      "Protegido +": [
        ""
      ],
      "Blindado": [
        ""
      ]
    },
    "Baja California Sur" : {
      "Esencial": [
        "Blue Medical Net",
        "Centro De Especialidades Médicas Fidepaz"
      ],
      "Protegido": [
        "Medical Center"
      ],
      "Protegido +": [
        "Hospital AMC",
        "Hospital H Mas",
        "Saint Lukes Medical Center"
      ],
      "Blindado": [
        ""
      ]
    },
    "Campeche" : {
      "Esencial": [
        "Comercializadora Del Centro Médico Campeche",
        "Hospital Vossan"
      ],
      "Protegido": [
        "Centro Médico Cesat",
        "Clínica Campeche"
      ],
      "Protegido +": [
        ""
      ],
      "Blindado": [
        ""
      ]
    },
    Coahuila :{
      "Esencial": [
       " Centro De Estudios Oftalmológicos Láser De Saltillo",
        "Clínica Amistad",
        "Clínica De Especialidades De La Laguna",
        "Clínica de Especialidades Santa Elena",
       " Clínica Hospital Ejeza",
       " Clínica Y Farmacia México",
        "Hospital Club De Leones",
        "Hospital Inglés",
        "Hospital San José De Monclova",
        "Hospital Universidad Autónoma De Coahuila"
      ],
      "Protegido": [
        "Centro Hospitalario La Concepción",
        "Clínica De Especialistas",
        "Hospital Ángeles De Torreón",
        "Hospital Christus Muguerza Saltillo",
        "Sanatorio Español"
      ],
      "Protegido +": [
        ""
      ],
      "Blindado": [
        ""
      ]
    },
    Colima :{
      "Esencial": [
        "Centro Hospitalario Unión",
        "Centro Médico De Colima",
        "Centro Médico Quirúrgico Echauri",
        "Clínica Córdoba",
        "Grupo Médico Coliman",
        "Hospital Manzanillo"
      ],
      "Protegido": [
        "Centro Médico Puerta De Hierro Colima"
      ],
      "Protegido +": [
        ""
      ],
      "Blindado": [
        ""
      ]
    },
    Chiapas : {
      "Esencial": [
        "Centro Médico Metropolitano",
        "Hospital Rojas",
        "Sanatorio Lourdes (Tapachula)",
        "Sanatorio Pérez Fernández",
        "Sanatorio Privado Chantal"
      ],
      "Protegido": [
        ""
      ],
      "Protegido +": [
        ""
      ],
      "Blindado": [
        ""
      ]
    },
    Chihuahua : {
      "Esencial": [
        "Centro Médico Madero",
        "Christus Muguerza Del Parque",
        "Clínica Cumbres",
        "Clínica Del Centro",
        "Clínica Delicias",
        "Clínica Hospital Fortaleza",
        "Clínica Plateros",
        "Hospital Ángeles Cuauhtémoc",
        "Hospital De La Familia",
        "Hospital Juárez",
        "Hospital Medisur",
        "Poli Plaza Médica",
        "Selecta Unidad De Cirugía De Corta Estancia"
      ],
      "Protegido": [
        "Centro Médico De Especialidades (Cd. Juárez)",
        "Hospital Ángeles Chihuahua",
        "Star Médica Cd. Juárez",
        "Star Médica Chihuahua"
      ],
      "Protegido +": [
        "Hospital Ángeles De Ciudad Juárez"
      ],
      "Blindado": [
        ""
      ]
    },
    "Ciudad De México" : {
      "Esencial": [
        "Centro Médico Dalinde",
        "Centro Quirúrgico Río Consulado",
        "Clínica Médica Santa Rosa",
        "Fundación Nuestra Señora De La Luz",
        "Hospital Cami",
        "Hospital Merlos",
        "Hospital Mig",
        "Hospital San Ángel Inn Chapultepec",
        "Hospital San Ángel Inn Patriotismo",
        "Hospital San Ángel Inn Sur",
        "Hospital San Ángel Inn Universidad",
        "Hospital San José (Df)",
        "Hospital Santa Coleta",
        "Hospital Santiago Apostol",
        "Hospital Tlalpan",
        "Instituto Nacional De Enfermedades Respiratorias (Iner)",
        "Sanatorio Durango",
        "Sanatorio Oftalmológico Mérida",
        "Sanatorio San Agustín",
        "Sanatorio Santa Inés",
        "Torre Médica Tabacalera",
        "Torre Médica Valle"
      ],
      "Protegido": [
        "Bité Médica",
        "Centro Médico Tiber",
        "Hospital Ángeles Clínica Londres",
        "Hospital Ángeles De México",
        "Hospital Ángeles Roma",
        "Hospital Ángeles Santa Mónica",
        "Hospital Diomed",
        "Hospital Español",
        "Hospital Hmg Coyoacán",
        "Hospital Los Cedros",
        "Hospital Mac Periférico Sur",
        "Instituto Nacional De Cardiología Ignacio Chávez"
      ],
      "Protegido +": [
        "Hospital Ángeles Acoxpa",
        "Hospital Ángeles De Lindavista",
        "Hospital Ángeles Metropolitano",
        "Hospital Ángeles Mocel",
        "Médica Sur"
      ],
      "Blindado": [
        "Hospital Abc Observatorio",
        "Hospital Abc Santa Fe",
        "Hospital Ángeles Del Pedregal"
      ]
    },
    Durango : {
      "Esencial": [
        "Hospital De La Paz",
        "Hospital Reforma",
        "Hospital San Jorge",
        "Hospital Sante"
      ],
      "Protegido": [
        "Sanatorio San José De Gómez Palacio Durango"
      ],
      "Protegido +": [
        ""
      ],
      "Blindado": [
        ""
      ]
    },
    Guanajuato : {
      "Esencial": [
        "Centro Médico La Presa",
        "Centro Médico Quirúrgico De Celaya",
        "Hospital Aranda De La Parra",
        "Hospital Aranda De La Parra Puerto Interior",
        "Hospital Los Álamos",
        "Hospital Mac Celaya",
        "Hospital Mac Irapuato",
        "Hospital Médica Campestre",
        "Hospital San Ángel De San José De Iturbide",
        "Hospital San José De Celaya",
        "Hospital Santa Mónica",
        "Hospital Siena",
        "Hospitalización Torre Médica",
        "Maternidad Esquipulitas",
        "Sanatorio Celaya",
        "Sanatorio Guadalupano De Celaya",
        "Sanatorio Moderno Pablo Anda"
      ],
      "Protegido": [
        "Hospital Insurgentes De Irapuato",
        "Hospital Ángeles León",
        "Hospital Mac San Miguel De Allende"
      ],
      "Protegido +": [
        ""
      ],
      "Blindado": [
        ""
      ]
    },
    Guerrero: {
      "Esencial": [
        "Hospital Cristina",
        "Hospital De Especialidades Santa Lucía",
        "Hospital De Especialidades Zihuatanejo",
        "Hospital Del Prado (Guerrero)",
        "Hospital Privado Magallanes",
        "Hospital Royal Care",
        "Hospital Sur Corporativo",
        "Médica San José De Guerrero"
      ],
      "Protegido": [
        "Hospital Farallón"
      ],
      "Protegido +": [
        ""
      ],
      "Blindado": [
        ""
      ]
    },
    Hidalgo: {
      "Esencial": [
        "Clínica De Especialidades Santa Mónica",
        "Hospital Centro Médico Marfyl",
        "Intermédica Medicina Actual",
        "Médica Quirúrgica Y Especialidades Santa María",
        "Sanatorio Materno Infantil Santa Julia",
        "Sanatorio Santa María (Pachuca)"
      ],
      "Protegido": [
        "Hospital Médica Tulancingo",
        "Sociedad Española De Beneficencia"
      ],
      "Protegido +": [
        ""
      ],
      "Blindado": [
        ""
      ]
    },
    Jalisco: {
      "Esencial": [
        "Centro Médico Arandas San Martín",
        "Centro Oftalmológico De Guadalajara",
        "Clínica De Especialidades Robles",
        "Clínica San Miguel",
        "Diagnosis Clínica Hospital",
        "Hospital Arboledas",
        "Hospital Bernardette",
        "Hospital Central Quirúrgica De Guadalajara",
        "Hospital De Jesús De Yahualica",
        "Hospital De La Cruz",
        "Hospital Del Country 2000",
        "Hospital Felman",
        "Hospital Jardines",
        "Hospital Joya Marina",
        "Hospital La Ascención",
        "Hospital Mac Bernardette",
        "Hospital México Americano",
        "Hospital Real San José",
        "Hospital Real San José Valle Real",
        "Hospital San José (Cd. Guzmán)",
        "Hospital Santa Fe Memorial",
        "Hospital Santa María Chapalita",
        "Hospital Santa Sofía",
        "Hospital Siloé",
        "Hospital Terranova",
        "Hospital Versalles",
        "Hospital Vicentita",
        "Inmobiliaria Del Centro Médico Alteño",
        "Médica De La Ciudad Guadalajara",
        "Pablo Neruda Hospital",
        "Salutaris Medical Center",
        "Sanatorio Guadalajara",
        "Sanatorio San Francisco De Asís",
        "Unidad Oculoplástica Orvilac"
      ],
      "Protegido": [
        "Centro Médico Puerta De Hierro Sur",
        "Chg Hospitales",
        "Hospital Ángeles Del Carmen",
        "Hospital Centro Médico Quirúrgico De Vallarta",
        "Hospital Centro Médico Quirúrgico Premier",
        "Hospital Real San Lucas",
        "Hospital San Javier",
        "Láser Oftálmico",
        "Lomas Providencia Unidad Médica",
        "Sanatorio Médico Quirúrgico De Los Altos",
        "Vallarta Medical Center"
      ],
      "Protegido +": [
        "Centro Médico Puerta De Hierro"
      ],
      "Blindado": [
        ""
      ]
    },
    "Edo. De México": {
      "Esencial": [
        "Central Quirúrgica San Ricardo",
        "Centro Ginecológico Y De Especialidades",
        "Centro Hospitalario Jardín",
        "Centro Médico Tejupilco",
        "Centro Médico Villa Victoria",
        "Clínica Médica Londres (Capulhuac)",
        "Clínica Santa Anita",
        "Clínica Santa María (Zinacantepec)",
        "Clínica Santo Niño De Atocha",
        "Clínica Tafami",
        "Hospital De Especialidades Bosques De Aragon",
        "Hospital Efrata",
        "Hospital Futura",
        "Hospital Polanco Coacalco",
        "Hospital Polanco Ixtapaluca",
        "Hospital Privado Ecatepec",
        "Hospital Same Chalco",
        "Hospital Same Ixtapaluca",
        "Hospital San José Satélite",
        "Hospital San José Texcoco",
        "Hospital San Rafael",
        "Médica Bethesda",
        "Multimédica Norte",
        "Polimédica Lago",
        "Sanatorio Venecia",
        "Torre Maya"
      ],
      "Protegido": [
        "Corporativo Hospital Satélite",
        "Hospital Centro Quirúrgico Satélite",
        "Hospital Río De La Loza",
        "Hospital Vivo Jardín Bicentenario",
        "Médica San Isidro",
        "Médica San José (Toluca)",
        "Sanatorio Florencia",
        "Star Médica Tlalnepantla"
      ],
      "Protegido +": [
        "Centro Médico De Toluca"
      ],
      "Blindado": [
        "Hospital Ángeles De Las Lomas"
      ]
    },
    Michoacán: {
      "Esencial": [
        "Clínica Hospital San José",
        "Clínica Médica 2000",        
        "Fátima Clínica",        
        "Hospital El Ángel",        
        "Hospital Memorial (Morelia)",        
        "Morelia Medica Center",        
        "Sanatorio La Luz"        
      ],
      "Protegido": [
        "Hospital Ángeles Morelia"
      ],
      "Protegido +": [
        ""
      ],
      "Blindado": [
        ""
      ]
    },
    Morelos: {
      "Esencial": [
        "Centro Médico Del Ángel",
        "Hospital Bellavista De Cuernavaca",
        "Hospital Morelos",        
        "Maternidad Civac",        
        "Oftálmica Cuernavaca"
       ],
      "Protegido": [
        "Hospital Center Vista Hermosa",
        "Sanatorio Henri Dunant"
      ],
      "Protegido +": [
        ""
      ],
      "Blindado": [
        ""
      ]
    },
    Nayarit: {
      "Esencial": [
        "Centro Quirúrgico San Rafael",
        "Clínica San Antonio",
        "Hospital Premium Hills Medical Center"
      ],
      "Protegido": [
        "Centro Médico Puerta De Hierro Tepic"
      ],
      "Protegido +": [
        ""
      ],
      "Blindado": [
        ""
      ]
    },
    "Nuevo León": {
      "Esencial": [
        "Centro Quirúrgico Nogalar",
        "Christus Muguerza Clínica Vidriera",
        "Christus Muguerza Conchita",
        "Christus Muguerza San Nicolás",
        "Clínica Anáhuac",
        "Clínica Del Carmen",
        "Clínica Roma",
        "Ginequito",
        "Hospital La Carlota",
        "Hospital San Felipe De Jesús",
        "Hospital Sierra Madre",
        "Hospital Y Centro Médico San Jorge",
        "Hospitaria",
        "Médica Norte De Monterrey",
        "Virginia Clínica Y Maternidad"
      ],
      "Protegido": [
        "Christus Muguerza Clínica San Pedro",
        "Christus Muguerza Sur",
        "Doctors Hospital",
        "Doctors Hospital East",
        "Hospital Y Clínica Oca"
      ],
      "Protegido +": [
        "Centro Médico Zambrano Hellion",
        "Christus Muguerza Alta Especialidad",
        "Hospital Ángeles Monterrey",
        "Hospital San José De Monterrey"
      ],
      "Blindado": [
        ""
      ]
    },
    Oaxaca: {
      "Esencial": [
          "Clínica Médica Ángel Del Mar",
          "Clínica Médica Santa Fe",
          "Hospital Molina",
          "Médica 2002"
      ],
      "Protegido": [
        "Hospital Reforma Oaxaca",
        "Hospital San Lucas"
      ],
      "Protegido +": [
        ""
      ],
      "Blindado": [
        ""
      ]
    },
    Puebla: {
      "Esencial": [
        "Centro De Especialidades De Atlixco",
        "Centro Médico Medici",
        "Centro Médico Millenium",
        "Centro Médico Tecamachalco",
        "Christus Muguerza Upaep",
        "Clínica De Especialidades Médicas Monte De Yavé",
        "Hospital Ángeles De Tehuacán",
        "Hospital De Especialidades De Tezuitlán",
        "Hospital De Los Ángeles",
        "Hospital Guadalupano Upaep",
        "Sanatorio Dr. Francisco Macías Alonso"
      ],
      "Protegido": [
        "Christus Muguerza Betania",
        "Hospital De Beneficencia Española De Puebla",
        "Hospital De Puebla",
        "Hospital La Paz (Puebla)",
        "Hospital Mac Puebla",
        "Sagrado Corazón De Tehuacán"
      ],
      "Protegido +": [
        ""
      ],
      "Blindado": [
        "Hospital Ángeles Puebla"
      ]
    },
    Querétaro: {
      "Esencial": [
        "Centro Médico Coscami",
        "Hospital San José De San Juan Del Río",
        "Médica Ebor"
      ],
      "Protegido": [
        "Hospital H Mas Querétaro",
        "Hospital San José De Querétaro",
        "Hospital Santa Rosa De Viterbo"
      ],
      "Protegido +": [
        "Hospital Ángeles De Queretaro",
        "Hospital San José Moscati"
      ],
      "Blindado": [
        ""
      ]
    },
    "Quintana Roo": {
      "Esencial": [
        "Clínica De Nazareth",
        "Clínica Materno Infantil"
      ],
      "Protegido": [
        "Centro Médico De Cozumel",
        "Clínica Carranza",
        "Costa Med",
        "Hospital Americano",
        "Hospital Amerimed Cozumel Islamed",
        "Hospital De Tulum",
        "Hospital Playa Med (Cancún)",
        "Hospital Playa Med (Playa Del Carmen)",
        "Hospital Victoria Med",
        "Lls Servicios Médicos (Quirúrgica Del Sur)",
        "Médica San Miguel De Cozumel"
      ],
      "Protegido +": [
        "Hospital Amerimed Cancún",
        "Hospital Galenia"
      ],
      "Blindado": [
        "Hospiten Cancún",  
        "Hospiten Playa Del Carmen"
      ]
    },
    "San Luis Potosí": {
      "Esencial": [
        "Hospital De La Salud",
        "Hospital Star Médica San Luís Potosí",
        "Sanatorio Metropolitano (Cd. Valles)",
        "Sanatorio San José De Valles"
      ],
      "Protegido": [
        "Centro Médico Del Potosí (Ángeles Del Potosí)",
        "Hospital Lomas De San Luís",
        "Sociedad De Beneficencia Española San Luís"
      ],
      "Protegido +": [
        ""
      ],
      "Blindado": [
        ""
      ]
    },
    Sinaloa: {
      "Esencial": [
        "Centro Médico Los Mochis",
        "Hospital Clínica Del Mar",
        "Hospital Marina Mazatlán",
        "Sanatorio Cemsi Chapultepec"
      ],
      "Protegido": [
        "Hospital Agraz",
        "Hospital Fátima",
        "Hospital Sharp",
        "Médica De La Ciudad Cualiacán",
        "Sanatorio Batiz Ramos"
      ],
      "Protegido +": [
        "Hospital Ángeles De Culiacán"
      ],
      "Blindado": [
        ""
      ]
    },
    Sonora: {
      "Esencial": [
        "Centro Médico Del Noroeste",
        "Centro Médico Sonora",
        "Centro Médico Sur Sonora",
        "Clínica De La Mujer San Francisco",
        "Clínica Hospital San José De Novojoa",
        "Hospital Clínica San Benito",
        "Hospital Del Socorro De Nogales",
        "Hospital Privado San José De Cd. Obregón",
        "Hospital Santa Margarita",
        "Maternidad San Gerardo",
        "Sanatorio Lourdes (Navojoa)"
      ],
      "Protegido": [
        "Hospital San José De Hermosillo",
        "Migoo, Unidad Médico Quirúrgica"
      ],
      "Protegido +": [
        "Hospital Privado De Hermosillo Cima"
      ],
      "Blindado": [
        ""
      ]
    },
    Tabasco: {
      "Esencial": [
       " Hospital Quirúrgico Graniel",
        "Unidad Médica Guerrero"
      ],
      "Protegido": [
        "Hospital Air",
        "Hospital Ángeles De Villahermosa",
        "Hospital Ceracom",
        "Hospital Del Sureste"
      ],
      "Protegido +": [
        ""
      ],
      "Blindado": [
        ""
      ]
    },
    Tamaulipas: {
      "Esencial": [
        "Clínica De Especialidades Santa Teresa",
        "Clínica Hospital Cemain",
        "Clínica León Y Garza",
        "Clínica Providencial",
        "Grupo Médico Colombres",
        "Grupo Médico Las Fuentes",
        "Hospital Casa De Salud San José",
        "Hospital Guadalupe",
        "Hospital México Americano (Nuevo Laredo)",
        "Hospital Regional Del Río De Reynosa",
        "Hospital Santander",
        "Médica Génesis",
        "San Ángel Hospital"
      ],
      "Protegido": [
        "Beneficencia Española De Tampico",
        "Centro Médico Internacional",
        "Christus Muguerza Reynosa",
        "Hospital De Especialidades",
        "Hospital San Charbel",
        "Hospital Victoria La Salle",
        "Hospital Y C.M.Q."
      ],
      "Protegido +": [
        "Hospital Ángeles Tampico"
      ],
      "Blindado": [
        ""
      ]
    },
    Tlaxcala: {
      "Esencial": [
        "Centro Hospitalario Los Cedros",
        "Hospital Central De Apizaco",
        "Hospital San Francisco De Asís"
      ],
      "Protegido": [
        "Hospital Humanitas"
      ],
      "Protegido +": [
        ""
      ],
      "Blindado": [
        ""
      ]
    },
    Veracruz: {
      "Esencial": [
        "Centro Médico Tuxpan",
        "Clínica San Luís Rafera",
        "Hospital Concordia",
        "Hospital San José (Poza Rica)",
        "Médica Cristal",
        "Policlínica Óptima Hospital De Especialidades Médicas",
        "Sanatorio Dr. Cruz Metropolitano",
        "Sanatorio Escudero",
        "Sanatorio Huerta",
        "Sanatorio San Francisco (Xalapa)",
        "Sanatorio San Francisco De Veracruz"
      ],
      "Protegido": [
        "Clínica Hospital San Juan Bosco",
        "H. Millenium Medical Center Veracruz",
        "Hospital Ángeles Xalapa",
        "Hospital Covadonga Córdoba",
        "Hospital Covadonga Orizaba",
        "Hospital De María (Veracruz)",
        "Hospital Español Veracruz",
        "Hospital May",
        "Hospital Médico De Especialidades Médicas Mayo",
        "Sanatorio Metropolitano (Minatitlán)",
        "Sanatorio Zarza"
      ],
      "Protegido +": [
        "Star Medica Veracruz"
      ],
      "Blindado": [
        ""
      ]
    },
    Yucatán: {
      "Esencial": [
        "Centro de Especialidades Médicas Del Sureste",
        "Centro Médico De Las Américas",
        "Clínica Centro Médico Pensiones Sociedad Médica García Gineres (Lado Azul)",
        "Clínica Mérida",
        "Clínica Yucatán",
        "Instituto De Enfermedades y Cirugía Ocular"
      ],
      "Protegido": [
        "Fundación Progreso Yucatán",
        "Hospital Faro del Mayab"
      ],
      "Protegido +": [
        ""
      ],
      "Blindado": [
        ""
      ]
    },
    Zacatecas: {
      "Esencial": [
        "Torre Médica Médicos Militares Especialistas De Zacatecas",
        "Hospital San José (Zacatecas)"
      ],
      "Protegido": [
        ""
      ],
      "Protegido +": [
        ""
      ],
      "Blindado": [
        ""
      ]
    }
}