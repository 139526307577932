import React, { useState, useEffect } from "react";
import { Paper, IconButton, InputBase, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const FinderInput = ( props ) => {
  const { setInputValue } = props;
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Función para actualizar el estado cuando cambia el tamaño de la ventana
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    // Agregar un listener para el evento de cambio de tamaño de ventana
    window.addEventListener("resize", handleResize);
    // Limpiar el listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  }

  return (
    <div className="py-8 mx-8" style={{ textAlign: "-webkit-center" }}>
      <Paper
        className="border-buscador"
        component="form"
        elevation={0}
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          // width: width > 980 ? 950 : 600,
          width: "auto"
        }}
      >
        <IconButton
          type="button"
          sx={{ p: "10px", color: "#212121" }}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Buscar"
          inputProps={{ "aria-label": "buscar pregunta" }}
          onChange={handleInputChange}
        />
      </Paper>
    </div>
  );
};

export default FinderInput;
