import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Card, CardMedia, CardContent } from "@mui/material";
import BG1 from "assets/img/blog/08.jpg";
import { useParams } from "react-router-dom";
import Default from "assets/img/blog/default.jpg";
import BG2 from "assets/img/blog/07.jpg";
import BG3 from "assets/img/blog/06.jpg";
import BG4 from "assets/img/blog/05.jpg";
import BG5 from "assets/img/blog/04.jpg";
import BG6 from "assets/img/blog/03.jpg";
import BG7 from "assets/img/blog/02.jpg";
import BG8 from "assets/img/blog/01.jpg";

import BG19 from "assets/img/blog/infoBlog/19.jpg";
import BG20 from "assets/img/blog/infoBlog/20.jpg";
import BG21 from "assets/img/blog/infoBlog/21.jpg";
import BG22 from "assets/img/blog/infoBlog/22.jpg";
import BG23 from "assets/img/blog/infoBlog/23.jpg";
import BG24 from "assets/img/blog/infoBlog/24.jpg";
import BG25 from "assets/img/blog/infoBlog/25.jpg";
import BG26 from "assets/img/blog/infoBlog/26.jpg";

import BG27 from "assets/img/blog/infoBlog/27.jpg";
import BG28 from "assets/img/blog/infoBlog/28.jpg";
import BG29 from "assets/img/blog/infoBlog/29.jpg";
import BG30 from "assets/img/blog/infoBlog/30.jpg";
import BG31 from "assets/img/blog/infoBlog/31.jpg";
import BG32 from "assets/img/blog/infoBlog/32.jpg";
import BG33 from "assets/img/blog/infoBlog/33.jpg";
import BG34 from "assets/img/blog/infoBlog/34.jpg";

import BG35 from "assets/img/blog/infoBlog/35.jpg";
import BG36 from "assets/img/blog/infoBlog/36.jpg";
import BG37 from "assets/img/blog/infoBlog/37.jpg";
import BG38 from "assets/img/blog/infoBlog/38.jpg";
import BG39 from "assets/img/blog/infoBlog/39.jpg";
import BG40 from "assets/img/blog/infoBlog/40.jpg";
import BG41 from "assets/img/blog/infoBlog/41.jpg";
import BG42 from "assets/img/blog/infoBlog/42.jpg";

/** Blog February */
import BG43 from "assets/img/blog/infoBlog/43.jpg";
import BG44 from "assets/img/blog/infoBlog/44.jpg";
import BG45 from "assets/img/blog/infoBlog/45.jpg";
import BG46 from "assets/img/blog/infoBlog/46.jpg";
import BG47 from "assets/img/blog/infoBlog/47.jpg";
import BG48 from "assets/img/blog/infoBlog/48.jpg";
import BG49 from "assets/img/blog/infoBlog/49.jpg";
import BG50 from "assets/img/blog/infoBlog/50.jpg";

/** Blog March */
import BG51 from "assets/img/blog/infoBlog/51.jpg";
import BG52 from "assets/img/blog/infoBlog/52.jpg";
import BG53 from "assets/img/blog/infoBlog/53.jpg";
import BG54 from "assets/img/blog/infoBlog/54.jpg";
import BG55 from "assets/img/blog/infoBlog/55.jpg";
import BG56 from "assets/img/blog/infoBlog/56.jpg";
import BG57 from "assets/img/blog/infoBlog/57.jpg";
import BG58 from "assets/img/blog/infoBlog/58.jpg";
import BG59 from "assets/img/blog/infoBlog/59.jpg";

import BG60 from "assets/img/blog/infoBlog/60.jpg";

/** Blog April */
import BG61 from "assets/img/blog/infoBlog/61.jpg";
import BG62 from "assets/img/blog/infoBlog/62.jpg";
import BG63 from "assets/img/blog/infoBlog/63.jpg";
import BG64 from "assets/img/blog/infoBlog/64.jpg";
import BG65 from "assets/img/blog/infoBlog/65.jpg";
import BG66 from "assets/img/blog/infoBlog/66.jpg";
import BG67 from "assets/img/blog/infoBlog/67.jpg";
import BG68 from "assets/img/blog/infoBlog/68.jpg";

/** Blog May */
import BG69 from "assets/img/blog/infoBlog/69.jpg";
import BG70 from "assets/img/blog/infoBlog/70.jpg";
import BG71 from "assets/img/blog/infoBlog/71.jpg";
import BG72 from "assets/img/blog/infoBlog/72.jpg";
import BG73 from "assets/img/blog/infoBlog/73.jpg";
import BG74 from "assets/img/blog/infoBlog/74.jpg";

/** Blog June */
import BG75 from "assets/img/blog/infoBlog/75.jpg";
import BG76 from "assets/img/blog/infoBlog/76.jpg";
import BG77 from "assets/img/blog/infoBlog/77.jpg";
import BG78 from "assets/img/blog/infoBlog/78.jpg";
import BG79 from "assets/img/blog/infoBlog/79.jpg";
import BG80 from "assets/img/blog/infoBlog/80.jpg";
import BG81 from "assets/img/blog/infoBlog/81.jpg";
import BG82 from "assets/img/blog/infoBlog/82.jpg";

/** Blog July */
import BG83 from "assets/img/blog/infoBlog/83.jpg";
import BG84 from "assets/img/blog/infoBlog/84.jpg";
import BG85 from "assets/img/blog/infoBlog/85.jpg";
import BG86 from "assets/img/blog/infoBlog/86.jpg";

/** Blog August */
import BG87 from "assets/img/blog/infoBlog/87.jpg";
import BG88 from "assets/img/blog/infoBlog/88.jpg";
import BG89 from "assets/img/blog/infoBlog/89.jpg";
import BG90 from "assets/img/blog/infoBlog/90.jpg";

/** Blog September */
import BG91 from "assets/img/blog/infoBlog/91.jpg";
import BG92 from "assets/img/blog/infoBlog/92.jpg";
import BG93 from "assets/img/blog/infoBlog/93.jpg";
import BG94 from "assets/img/blog/infoBlog/94.jpg";

/** Blog October */
import BG95 from "assets/img/blog/infoBlog/95.jpg";
import BG96 from "assets/img/blog/infoBlog/96.jpg";
import BG97 from "assets/img/blog/infoBlog/97.jpg";
import BG98 from "assets/img/blog/infoBlog/98.jpg";

/** Blog November */
import BG99 from "assets/img/blog/infoBlog/99.jpg";
import BG100 from "assets/img/blog/infoBlog/100.jpg";
import BG101 from "assets/img/blog/infoBlog/101.jpg";
import BG102 from "assets/img/blog/infoBlog/102.jpg";

/** Blog December */
import BG103 from "assets/img/blog/infoBlog/103.jpg";
import BG104 from "assets/img/blog/infoBlog/104.webp";
import BG105 from "assets/img/blog/infoBlog/105.webp";
import BG106 from "assets/img/blog/infoBlog/106.jpg";

/** Blog January 2023*/
import BG107 from "assets/img/blog/infoBlog/107.webp";
import BG108 from "assets/img/blog/infoBlog/108.webp";
import BG109 from "assets/img/blog/infoBlog/109.webp";
import BG110 from "assets/img/blog/infoBlog/110.webp";

/** Blog February 2023*/
import BG111 from "assets/img/blog/infoBlog/111.webp";
import BG112 from "assets/img/blog/infoBlog/112.webp";
import BG113 from "assets/img/blog/infoBlog/113.webp";
import BG114 from "assets/img/blog/infoBlog/114.webp";

/** Blog March 2023*/
import BG115 from "assets/img/blog/infoBlog/115.webp";
import BG116 from "assets/img/blog/infoBlog/116.webp";
import BG117 from "assets/img/blog/infoBlog/117.webp";
import BG118 from "assets/img/blog/infoBlog/118.webp";

/** Blog April 2023*/
import BG119 from "assets/img/blog/infoBlog/119.webp";
import BG120 from "assets/img/blog/infoBlog/120.webp";
import BG121 from "assets/img/blog/infoBlog/121.webp";

/** Blog May 2023*/
import BG122 from "assets/img/blog/infoBlog/122.webp";
import BG123 from "assets/img/blog/infoBlog/123.webp";
import BG124 from "assets/img/blog/infoBlog/124.webp";
import BG125 from "assets/img/blog/infoBlog/125.webp";
import BG126 from "assets/img/blog/infoBlog/126.webp";

/** Blog June 2023*/
import BG127 from "assets/img/blog/infoBlog/127.webp";
import BG128 from "assets/img/blog/infoBlog/128.webp";
import BG129 from "assets/img/blog/infoBlog/129.webp";
import BG130 from "assets/img/blog/infoBlog/130.webp";

/** Blog July 2023*/
import BG131 from "assets/img/blog/infoBlog/131.webp";
import BG132 from "assets/img/blog/infoBlog/132.webp";
import BG133 from "assets/img/blog/infoBlog/133.webp";
import BG134 from "assets/img/blog/infoBlog/134.webp";


import BG135 from 'assets/img/blog/infoBlog/135.png';
import BG136 from 'assets/img/blog/infoBlog/136.png';
import BG137 from 'assets/img/blog/infoBlog/137.webp';
import BG138 from 'assets/img/blog/infoBlog/138.png';

import { useState } from "react";

export default function StatusCard({ color, icon, title, children }) {
  let history = useHistory();
  const { page } = useParams();
  const dataBlog = [
    {
      img: BG138,
      date: 'Agosto 2023',
      autor: 'adminInter',
      title: 'Cómo proteger tu moto en inter.mx',
      body: '¿Estás buscando un seguro de moto? Encuéntralo en inter.mx, seguro, seguro.',
      content: [
        {
          header: ``,
          body: `En inter.mx sabemos lo importante que es mantener tu moto protegida contra cualquier eventualidad. Aquí, te daremos los mejores consejos para que la protejas fácil y rápido.

          Cotizador de seguros - Todo lo que necesitas a un clic de distancia`,
        },{
          header: `Cotiza y compara`,
          body: `¿Sabías que en inter.mx puedes personalizar tu seguro de moto? Explora las coberturas y elige la que mejor se ajuste a lo que estás buscando. Puedes tener una cotización en línea y comparar entre las mejores aseguradoras.

          ¡Es muy fácil! Sólo entra a https://cocheseguro.inter.mx. En  el cotizador selecciona la marca de tu moto, agrega el año y el modelo. Checa las opciones que hay, personaliza las coberturas y compara los precios de las diferentes aseguradoras. 

          Coche Seguro: ¿Qué hace un ajustador de seguros en un percance?`,
        },{
          header: `Contrata al mejor precio`,
          body: `Ya hiciste el paso anterior y encontraste tu seguro ideal, ¡sólo elígelo! En inter.mx le quitamos lo complicado a los seguros para que estés seguro, seguro fácil, rápido y al mejor precio.

          Tu seguro de moto tiene grandes beneficios, como servicio de ambulancia en caso de ser necesario, asistencia si se te poncha la llanta, protección por robo total, ¡y mucho más!

          No necesitas pasar horas buscando tu seguro ideal, nuestro cotizador te ayuda para que contrates al mejor precio. Así de fácil es proteger tu moto en inter.mx.

          Coche Seguro: Híbrido, eléctrico o de gasolina.

          ¡Conoce más sobre cualquiera de nuestros seguros en el blog! ¡Contrata fácil y rápido en inter.mx! Síguenos en Instagram, Facebook, Twitter, TikTok y Youtube.`,
        }
      ]
    },
    {
      img: BG135,
      date: 'Agosto 2023',
      autor: 'adminInter',
      title: '¡Feliz Día Internacional del Gato!',
      body: 'Se acerca el Día del Gato y en inter.mx tenemos los mejores tips para cuidarlo.',
      content: [
        {
          header: ``,
          body: `Los gatos son mascotas maravillosas que nos brindan compañía, amor y alegría en nuestro hogar. Al igual que cualquier otra mascota, los gatos necesitan cuidados especiales para mantener su salud y bienestar. En este blog, te daremos algunos consejos prácticos para cuidar a tu gato de la mejor manera posible. ¡Celebra el Día Internacional del Gato y protégelo en inter.mx!!

          ¿Buscas un Seguro de Mascota? ¡Encuéntralo aquí!`,
        },{
          header: `Conoce sus necesidades nutricionales`,
          body: `Cada gato tiene requisitos dietéticos específicos según su edad, peso y estado de salud. Elige alimentos que cumplan con sus requerimientos nutricionales.

            ● Compra alimentos de calidad que estén formulados especialmente para gatos. Asegúrate de que contengan los nutrientes esenciales, como proteínas, vitaminas y minerales.

            ● Crea una rutina de alimentación estableciendo horarios fijos para las comidas de tu gato. Esto ayudará a mantener su sistema digestivo saludable y evitará problemas de sobrepeso. 

            ● Algunos alimentos son peligrosos para los gatos, como el chocolate, la cebolla, el ajo y las uvas. ¡Asegúrate de tener estos alimentos fuera de su alcance!

            Recuerda consultar siempre a tu veterinario antes de darle nuevos alimentos.

            Vacunas para mascotas: ¿Cuáles le tocan a tu peludito en cada momento de su vida?
            `,
        },{
          header: `Realiza revisiones veterinarias regulares`,
          body: `Visitar regularmente al veterinario es fundamental. Aquí te compartimos algunas razones por las que las revisiones veterinarias son importantes:

          1.  Programa revisiones veterinarias regulares para que tu gato sea examinado y se realicen chequeos de salud. El veterinario podrá detectar cualquier problema de salud de forma temprana y proporcionar el tratamiento adecuado.
          2.  Asegúrate de que tu gato esté al día con todas sus vacunas y desparasitaciones, ya que son esenciales para protegerlo de enfermedades y parásitos.
          3.  Durante las revisiones veterinarias, se pueden realizar pruebas y análisis para detectar enfermedades en etapas tempranas. Esto aumenta las posibilidades de un tratamiento exitoso.

          Cada gato es único, así que toma nota de estos consejos y no olvides que Mascota Segura te da un baño y una vacuna o desparasitación gratis al año. Este Día Internacional del Gato, cuida a tu michi en inter.mx para que esté seguro, seguro. 

          Visitas al veterinario: ¿Qué necesitas saber?

          ¡Conoce más sobre nuestros seguros en el blog! ¡Contrata fácil y rápido en inter.mx! Síguenos en Instagram, Facebook, Twitter, TikTok y Youtube.
          `,
        }
      ]
    },
    {
      img: BG136,
      date: 'Agosto 2023',
      autor: 'adminInter',
      title: 'Tips para cuidar tu salud mental',
      body: 'En inter.mx sabemos lo importante que es cuidar no sólo tu salud, sino también tu salud mental. Aquí te compartimos unos tips que seguro, seguro te van a ayudar.',
      content: [
        {
          header: ``,
          body: `En la vida es importante establecer límites y prioridades para cuidar nuestra salud mental. A veces nos encontramos con múltiples actividades y compromisos que pueden agotarnos y generar estrés. En inter.mx te damos algunos tips que te ayudarán a cuidar tu salud mental. 

          Tips infalibles para proteger tu salud`,
        },{
          header: `Cuidar tu salud mental es importante`,
          body: `Aprender a decir "no" de forma asertiva es esencial para proteger nuestra salud mental. Si te sientes abrumado/a y no puedes asumir más responsabilidades, no tengas miedo de establecer límites y decir "no" cuando sea necesario. Recuerda que tu bienestar es prioritario.

          Organizar tu tiempo y establecer prioridades te ayudará a manejar mejor tus responsabilidades y a reducir el estrés. Haz una lista de tareas y clasifícalas según su importancia. De esta manera, podrás enfocarte en lo más relevante y gestionar tu tiempo de manera más eficiente.

          El estrés puede afectar negativamente, por lo que es crucial practicar técnicas de relajación para sobrellevarlo mejor. Dedica unos minutos al día para sentarte en un lugar tranquilo y meditar.

          No olvides que el ejercicio libera endorfinas y hormonas que nos hacen sentir bien y reducen el estrés. Encuentra una actividad que disfrutes, ya sea salir a caminar, correr, practicar yoga o bailar, e incorpórala a tu rutina diaria.

          3 ventajas de tener seguro de gastos médicos.`,
        },{
          header: `Busca ayuda profesional si es necesario`,
          body: `Si sientes que tus emociones o problemas están afectando tu salud mental y bienestar, no dudes en buscar ayuda profesional. Un psicólogo o psiquiatra puede brindarte el apoyo y las herramientas adecuadas para superar tus desafíos.

          Ellos pueden guiarte en la exploración de tus pensamientos, emociones y comportamientos para mejorar tu bienestar emocional.

          Recuerda que cuidar tu salud mental es un proceso continuo y personal. No dudes en explorar diferentes técnicas y recursos que se adapten mejor a tus necesidades. 

          Implementa estos tips en tu vida diaria y toma el tiempo necesario para priorizar tu salud.

          En inter.mx le quitamos lo complicado a los seguros para que tú no te preocupes de nada. Personaliza tu seguro médico para recibir atención psicológica cuando la necesites. ¡Seguro, seguro!

          Cotizador de seguros - Todo lo que necesitas a un clic de distancia

          ¡Conoce más sobre cualquiera de nuestros seguros en el blog! ¡Contrata fácil y rápido en inter.mx! Síguenos en Instagram, Facebook, Twitter, TikTok y Youtube.`,
        }
      ]
    },
    {
      img: BG137,
      date: 'Agosto 2023',
      autor: 'adminInter',
      title: 'Montachoques: La nueva manera de extorsión',
      body: 'Desde hace unos meses llegó a la CDMX una nueva manera de extorsionar a los conductores. En inter.mx, te decimos cómo evitar caer en esta nueva práctica.',
      content: [
        {
          header: ``,
          body: `Los ahora famosos montachoques, son personas que fingen algún tipo de percance entre los coches para extorsionarte y obtener dinero. Es común que lo hagan con violencia para obligarte a entregar tus objetos personales e incluso tu coche. A continuación, te dejamos algunos tips de cómo operan y cómo puedes evitarlos.

          Coche Seguro: ¿Qué hace un ajustador de seguros en un percance?`,
        },{
          header: `¿Cómo operan los montachoques?`,
          body: `Es importante que sepas cómo funciona esta nueva manera de extorsionar para que estés prevenido.

          1.  Te identifican: Los montachoques observan detenidamente el tráfico, en busca de conductores distraídos, personas vulnerables o coches de alto valor. Una vez que encuentran a su objetivo, ponen en marcha su plan de estafa.
          2.  Se incorporan delante de tu coche: El movimiento es súper calculado para que tengas poca capacidad para reaccionar.
          3.  Hacen una maniobra de freno: El montachoques frena muy rápido, dejándote poco tiempo para poder evitar el choque.
          4.  Provocan el choque: Ya cuando esto pasa, el estafador se asegura que parezca que el choque es tu culpa para poder hacer la estafa.
          5.  El montachoques va siempre acompañado: Por lo general operan en grupo para que actúen como testigos falsos y culparte fácilmente.
          6.  El robo: Después del choque, los estafadores intentan convencerte para que pagues los daños, sin necesidad de involucrar a las autoridades.
          7.  ¡Ten cuidado! La cantidad que piden va desde los $1,500 a los $35,000.

          Esta información es básica por si te ves enfrentando esta situación. En inter.mx buscamos que siempre estés informado para que nunca dejes de estar seguro, seguro.

          ¿Coche seguro cubre mi accidente completo?`,
        },{
          header: `¿Cómo evitarlos?`,
          body: `Los montachoques representan una estafa cada vez más común en la ciudad, pero ¡no tienes que convertirte en su próxima víctima! Acá te dejamos unos consejos para que no caigas:

          ● ¡Asegura tu coche! Acuérdate que en inter.mx tenemos los mejores planes para que encuentres el seguro que más te convenga. Nuestro cotizador de seguros te permite conocer las diferentes opciones y comparar precios para encontrar el seguro ideal para ti. Recuerda que un seguro de coche es una inversión importante para tu seguridad y tranquilidad al manejar, protege tu coche fácil y rápido con nosotros.
          ● No te distraigas mientras manejas. Si ya sabes que checar tu cel mientras manejas es un NO en mayúsculas, también debes estar alerta por si cachas algún comportamiento sospechoso de algún coche y puedas reaccionar de manera adecuada.
          ● ¡No caigas en provocaciones! Mantén la calma y no cedas a la presión.
          ● Por ningún motivo salgas de tu coche. Es importante que te quedes dentro del coche a menos que sea absolutamente necesario bajarte ya que puedes exponerte a riesgos innecesarios.
          ● Si es posible, anota las placas o modelo del coche. Estos datos pueden ser útiles para las autoridades y tu aseguradora en la investigación posterior y para identificar a los estafadores.

          Recuerda que no tienes que esperar a vivir algo desagradable mientras manejas para proteger tu coche, puedes asegurarlo fácil y rápido en inter.mx. 

          Las coberturas de coche seguro que seguro, seguro te sacarán de un apuro

          ¡Conoce más sobre cualquiera de nuestros seguros en el blog! ¡Contrata fácil y rápido en inter.mx! Síguenos en Instagram, Facebook, Twitter, TikTok y Youtube.`,
        }
      ]
    },
    {
      img: BG131,
      date: 'Julio 2023',
      autor: 'adminInter',
      title: '3 actividades con tu perrhijo para celebrar el Día Internacional del Perro',
      body: 'Se acerca el Día Internacional del Perro y en inter.mx tenemos los mejores tips para celebrarlo en grande.',
      content: [
        {
          header: ``,
          body: ``,
        },{
          header: ``,
          body: `Celebra el Día Internacional del Perro de manera especial y única con actividades divertidas. Recuerda siempre tener en cuenta la seguridad y el bienestar de tu peludito. ¡Celebra y protege a tu mejor amigo en inter.mx!
          <>¿Buscas un Seguro de Mascota? ¡Encuéntralo aquí!</>`,
        },{
          header: `Actividades especiales para celebrar el Día del Perro`,
          body: `Aquí te damos algunas ideas que seguro, seguro te ayudarán a planear el día ideal para él.
          Un momento al aire libre: Disfrutar de un paseo en el parque junto a tu peludito. Busca un parque cercano donde tu perro pueda correr, explorar y socializar con otros amigos caninos. Aprovecha para jugar a lanzarle la pelota o simplemente dar un paseo. 
          Diversión bajo el sol: Si vives cerca de la playa, ¡aprovecha! A muchos perros les encanta el agua. Tu perrito pasará el día nadando, corriendo en la arena y explorando nuevos olores. 
          Picnic en el parque: Organiza un picnic en el parque para disfrutar de un momento de tranquilidad junto a tu peludito. Prepara algunos bocadillos saludables para ti y snacks especiales para tu perro, como premios o golosinas adecuadas para su alimentación. 
          ¿Quieres hornear algo especial para tu peludito, pero no sabes qué hacer? ¡Sigue leyendo, que tenemos una receta sólo para ti!
          <>Tres pasos para enseñarle a tu perrhijo buenos modales.</>`,
        },{
          header: `La receta perfecta para celebrar`,
          body: `Sólo necesitas un plátano maduro y ½ taza de mantequilla de maní natural (sin azúcar ni sal añadidos). 
          Bolitas de mantequilla de maní y plátano: En un tazón, machaca el plátano maduro con un tenedor hasta obtener un puré suave. Agrega la mantequilla de maní y mezcla bien hasta que todos los ingredientes estén completamente combinados. Toma pequeñas porciones de la mezcla y forma bolitas del tamaño adecuado para tu perro. Puedes hacerlas del tamaño de una pelota de ping-pong. Coloca las bolitas en un plato o bandeja forrada con papel encerado y refrigera durante al menos 1 hora para que se endurezcan. Una vez que las bolitas estén firmes, están listas para ser un sabroso regalo.
          En inter.mx sabemos lo importante que es cuidar de tu mejor amigo y que siempre esté seguro, seguro. Aprovecha beneficios como vacuna o desparasitación y un baño gratis al año. 
          <>Vacunas para mascotas: ¿Cuáles le tocan a tu peludito en cada momento de su vida?</>`,
        },{
          header:``,
          body:`¡Conoce más sobre cualquiera de nuestros seguros en el <>blog</>! ¡Contrata fácil y rápido en <>inter.mx</>! Síguenos en <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</>.`
        }
      ]
    },
    {
      img: BG132,
      date: 'Julio 2023',
      autor: 'adminInter',
      title: 'Este verano, ¡protege tu piel del sol!',
      body: 'Este verano protege tu piel del sol con estos consejos que tenemos',
      content: [
        {
          header: ``,
          body: `El verano es perfecto para disfrutar del sol, pero también es importante recordar que puede ser dañino para la piel. En <>inter.mx</> te damos unos consejos sencillos y efectivos para protegerte del sol durante esta temporada.` 
        },{
          header:``,
          body:`<>Cotizador de seguros - Todo lo que necesitas a un clic de distancia</>`,
        },{
          header: `Cuídate y disfruta del verano al máximo`,
          body: `Nuestros mejores consejos te ayudarán a estar prevenid@ en tu día a día. 
          ●	Tu mejor aliado contra los rayos UV: El uso de protector solar es fundamental para proteger tu piel de los rayos ultravioleta (UV), incluso en días nublados. No olvides aplicar cada dos horas.
          ●	Mantén tu piel saludable desde adentro: Recuerda que la exposición al sol puede deshidratar la piel, por lo que es aún más importante asegurarte de estar bien hidratado. Además de beber agua, puedes consumir alimentos ricos en agua, como frutas y verduras.
          ●	El sol también está en la carretera: Cuando estés al volante, utiliza lentes de sol para reducir el deslumbramiento y mejorar la visibilidad. Recuerda que la exposición al sol continua mientras conduces, puede dañar la piel de tus brazos y manos, ¡protégela!
          <>¿Buscas un Seguro de Gastos Médicos? ¡Encuéntralo aquí!</>`,
        },{
          header: `Un verano seguro, seguro`,
          body: `No importa a dónde te lleve el verano, nunca está de más estar protegid@. Tener un seguro médico es igual de importante que un protector solar que llevas contigo a todos lados. 
          En <>inter.mx</> le quitamos lo complicado a los seguros para que tú no te preocupes. Descarga nuestra app y lleva tus pólizas en un sólo lugar. Y recuerda que pase lo que pase, uno de nuestros expertos en seguros siempre estará ahí para ayudarte. ¡Seguro, seguro!`
        },{
          header:``,
          body:`<>¿Estás preparando las vacaciones de verano?</>`
        },{
          header:``,
          body:`¿Quieres saber más? ¡Conoce todo sobre cualquiera de nuestros seguros en el <>blog</>! ¡Contrata fácil y rápido en <>inter.mx</>! 

          Síguenos en <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</> para que no te pierdas de nada.`
        }
      ]
    },
    {
      img: BG133,
      date: 'Julio 2023',
      autor: 'adminInter',
      title: 'Beneficios de Coche Seguro',
      body: 'En inter.mx tenemos los mejores beneficios para que contrates tu seguro de coche.',
      content: [
        {
          header: ``,
          body: `Cuando se trata de proteger tu coche, contar con el seguro ideal es fundamental… Por eso, celebra con nosotros nuestro tercer aniversario de Coche Seguro y protege tu coche con estos beneficios que tenemos para ti.
          <>¿Qué debo saber a la hora de contratar mi seguro de coche?</>`
        },{
          header: `3 beneficios de contratar tu seguro de coche en inter.mx`,
          body: `¿Te ha pasado que al buscar tu seguro de coche el proceso te parece abrumador? ¿O te saturas de tanta información que ya no sabes ni por dónde empezar? ¡Y ni hablar de las letras chiquitas o conceptos que nunca en la vida habías escuchado!
          ¡Olvídate de todo eso! En <>inter.mx</> le quitamos lo complicado a los seguros para que encuentres tu seguro ideal fácil, rápido y al mejor precio.`,
        },{
            header:``,
            body:`1. Compara entre las mejores aseguradoras. Antes de comprometerte con cualquier aseguradora, ¡usa nuestro cotizador de seguros! Ahí encuentras a las mejores aseguradoras y comparas para encontrar tu seguro ideal. Lo hacemos muy sencillo para que contrates en un sólo lugar.
            2. Personaliza tus coberturas. También puedes elegir las coberturas que mejor se ajustan a tus necesidades y lo que estás buscando en un seguro. Sin letras chiquitas ni términos complicados.
            3. ¡Habla con un experto en seguros! Si en algún momento tienes alguna duda, ¡nos puedes echar un telefonazo o escribir un mensaje! Nuestros expertos en seguros siempre están listos para ayudarte a encontrar tu seguro ideal.
            Psst… Psst… Cotiza tu seguro de coche <>aqui.</> 
  
            <>Las coberturas de coche seguro que seguro, seguro te sacarán de un apuro</>`
        },{
          header: `¡Celebra con nosotros!`,
          body: `En <>inter.mx</> estamos de fiesta, ¡Coche Seguro cumple 3 años asegurando los coches de los mexicanos!`,
        },{
          header:``,
          body:`Durante este tiempo, hemos trabajado constantemente en quitarle lo complicado a los seguros, sin letras chiquitas y acompañándote en cada momento del proceso. Estamos comprometidos en que estés seguro, seguro en todo momento, ¡fácil, rápido y al mejor precio!
          ¡Únete a la celebración y protege tu coche en <>inter.mx</>!`
        },{
          header:``,
          body:`<>Las coberturas que seguro, seguro te pueden sacar de apuros estas vacaciones</>`
        },{
          header:``,
          body:`¡Conoce más sobre cualquiera de nuestros seguros en el <>blog</>! ¡Contrata fácil y rápido en <>inter.mx</>! Síguenos en <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</>.`
        } 
      ]
    },
    {
      img: BG134,
      date: 'Julio 2023',
      autor: 'adminInter',
      title: '¿Qué son las preexistencias?',
      body: 'Descubre por qué es importante considerar las preexistencias al contratar un seguro de gastos médicos mayores.',
      content: [
        {
          header: ``,
          body: `¿Sabías que las preexistencias se refieren a las condiciones de salud que una persona tiene antes de adquirir su seguro? ¡Así es! En inter.mx le quitamos lo complicado a los seguros para que entiendas todo lo que necesitas saber antes, durante y después de contratar tu seguro.
          <>Tips infalibles para proteger tu salud</>`
        },{
          header: `Las preexistencias comunes`,
          body: `Seguro ya conoces ejemplos de las preexistencias más comunes, como lo son: la hipertensión arterial, asma o enfermedades cardíacas. Estas condiciones pueden requerir tratamientos y seguimiento médico regular. 
          Es importante que seas completamente honest@ con tu aseguradora si tienes alguna preexistencia al momento de contratar tu seguro médico, ya que influyen en la cobertura y los términos de tu póliza.
          Recuerda que cada asegurada es diferente. Checa las opciones de coberturas, considera la opción que te dé tranquilidad y los cuidados que necesitas.
          <>¿Por qué leer las condiciones generales de tu seguro?</>`,
        },{
          header: `2 consejos para tomar en cuenta antes de contratar tu seguro médico`,
          body: `Toma en cuenta estos consejos que han ayudado a nuestros asegurados a tomar la mejor decisión.
          1.	Leer detenidamente las condiciones generales:
          Tómate el tiempo de leer y comprender cómo se manejan las preexistencias dentro de tu póliza. Esto incluye conocer los periodos de espera, exclusiones o limitaciones de cobertura que puedan aplicarse. 
          2.	Compara opciones de seguro:
          Cada aseguradora tiene sus propias políticas sobre las preexistencias. Compara diferentes opciones de seguro para ver las coberturas que ofrecen. ¡No olvides que en <>inter.mx</> tenemos el mejor comparador de seguros!`
          },{
          header:``,
          body:`Un consejo adicional sólo para ti… Al contratar tu seguro en <>inter.mx</> siempre estarás acompañado por nuestros expertos en seguros, estaremos junto a ti para quitarle lo complicado a los seguros y para que no te preocupes por nada.
          
          Protege lo que más amas, al mejor precio, con las mejores coberturas. ¡Seguro, seguro sólo en <>inter.mx</>!`,
          },{
            header:``,
            body:`<>Cotizador de seguros - Todo lo que necesitas a un clic de distancia</>`
          },{
            header:``,
            body:`¡Conoce más sobre cualquiera de nuestros seguros en el <>blog</>! ¡Contrata fácil y rápido en <>inter.mx</>! Síguenos en <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</>.`
          }
      ]
    },
    {
      img: BG127,
      date: 'Junio 2023',
      autor: 'adminInter',
      title: '3 ventajas de tener Seguro de Gastos Médicos.',
      body: 'Las 3 ventajas de tener Médico Seguro en tu vida y en la de los que amas',
      content: [
        {
          header: `¡Cuida tu salud y la de tu familia!`,
          body: `Contratar un seguro implica tomarnos el tiempo de pensar en el futuro para cuidar nuestra salud y la de la familia. Lo bueno es que en <>inter.mx</> no necesitas pasar horas revisando papeles, hablando con vendedores, ni tratando de leer letras chiquitas que quién sabe qué secretos esconden.

          En <>inter.mx</> puedes cotizar, personalizar tus coberturas, comparar entre las mejores aseguradoras y contratar al mejor precio de volada. Así, el tiempo que te ahorras protegiendo su salud, lo puedes invertir en estar con tu familia. ¡Checa algunos de los beneficios que tenemos para ti!

          Las 3 ventajas de tener Médico Seguro:
          ●	Hospitalizaciones: tu aseguradora cubre el costo de la habitación y los servicios médicos que necesites.
          ●	Medicamentos: tu seguro cubre tus medicamentos (siempre y cuando sean para cubrir tu accidente o enfermedad)
          ●	Estudios: tu seguro cubre exámenes de laboratorio, resonancias magnéticas y todos los estudios necesarios para identificar tu padecimiento.
          ¡Y mucho más!
          <>Protege a quien más amas con un Seguro Médico</>.

          Es importante que sepas también que no todas las aseguradoras cuentan con todos los beneficios, por eso es súper importante que revises bien las coberturas antes de contratar.
          Con Médico Seguro puedes personalizar tu protección. Recuerda que si tienes dudas, ¡puedes contactarnos por teléfono 55-4424-6837, WhatsApp 5543269037 o mail <>hola@inter.mx</> en el momento que sea y nosotros te respondemos toditas tus preguntas!

          ¿Te sacamos de la duda con este artículo? ¡Checa todo lo que tenemos en nuestro <>blog</> para ti! Y si aún tienes preguntas, contáctanos como ya sabes o síguenos en nuestras redes sociales: <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</>`,
        }
      ]
    },
    {
      img: BG128,
      date: 'Junio 2023',
      autor: 'adminInter',
      title: 'Visitas al veterinario: ¿Qué necesitas saber?',
      body: '¿Sabes cuál es la frecuencia para llevar a tu mejor amigo al veterinario? Conoce todo lo que debes saber y nuestro mejor tip para protegerlo.',
      content: [
        {
          header: ``,
          body: `Cuando tienes una mascota, es importante asegurarte que siempre esté segura. Las visitas regulares al veterinario son esenciales para prevenir enfermedades y detectar cualquier problema de salud a tiempo. En <>inter.mx</> te decimos todo lo que necesitas saber sobre las visitas al veterinario y lo importante que es contar con un seguro de mascota.

          <>Tres pasos para enseñarle a tu perrhijo buenos modales.</>`,
        },{
          header: `¿Con qué frecuencia debes llevar a tu mascota al veterinario?`,
          body: `Dependiendo de la edad y la salud de tu mascota, deberás llevarla al veterinario con diferentes frecuencias. Por lo general, se recomienda que los cachorros y los gatitos tengan visitas al veterinario cada tres o cuatro semanas hasta que hayan recibido todas las vacunas necesarias. Después de eso, las visitas se reducen a una o dos veces al año para chequeos de rutina y vacunas anuales. Si tu mascota tiene alguna condición de salud preexistente, es posible que necesite visitas más frecuentes.`
        },{
          header: `Consejos para prepararte para la visita al veterinario`,
          body: `Aquí te damos algunos consejos útiles para preparar a tu mascota para una visita al veterinario:
          ●	Acostumbra a tu mascota a viajar en su transportadora.
          ●	Mantén actualizado su historial médico.
          ●	Lleva una lista de preguntas para hacerle al veterinario sobre la salud de tu mascota.
          ●	Durante la visita escucha al veterinario y lleva los premios favoritos de tu mascota en caso de ser necesario. 
          <>Vacunas para mascotas: ¿Cuáles le tocan a tu peludito en cada momento de su vida?</>`,
        },{
          header:`La importancia de tener un seguro de mascotas`,
          body:`Tener un seguro de mascotas no sólo puede ser una gran ayuda en caso de emergencias veterinarias. En <>inter.mx</>, además de las coberturas básicas para tu mascota, también ofrecemos algunos beneficios extras para cuidar de tu peludito.

          Entre ellos se encuentran un baño gratis al año, una vacuna o desparasitación gratis al año y hospedaje de hasta 4 días para tu mascota en caso de que te hospitalicen. Estas son sólo algunas de las ventajas que puedes tener al asegurar a tu mascota en <>inter.mx</>, el seguro ideal para tu mejor amigo peludo. No pierdas la oportunidad de darle la protección que se merece.

          <>¿Buscas un Seguro de Mascota? ¡Encuéntralo aquí!</>

          Síguenos en <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</> para que no te pierdas de nada.`,
        }
      ]
    },
    {
      img: BG129,
      date: 'Junio 2023',
      autor: 'adminInter',
      title: 'Papá es un superhéroe, protégelo con un seguro.',
      body: 'Cuida a papá con un seguro de gastos médicos mayores.',
      content: [
        {
          header: ``,
          body: `Todos sabemos que nuestros papás son nuestros héroes y que siempre están ahí para cuidarnos y protegernos. Sin embargo, ¿qué pasa cuando son ellos los que necesitan protección? Es ahí donde entra en juego la importancia de contar con un seguro de gastos médicos mayores para papá como una forma de asegurar su bienestar y tranquilidad en caso de cualquier eventualidad médica. En <>inter.mx</> te contaremos todo lo que necesitas saber para protegerlo como el verdadero superhéroe que es.

          <>Cotizador de seguros - Todo lo que necesitas a un clic de distancia</>`,
        },{
          header: `Un seguro para papá.`,
          body: `El mejor tip para cuidar a papá es encontrar un seguro médico que tenga la cobertura ideal para él. Recuerda tomar en cuenta que cada póliza de seguro de gastos médicos mayores puede tener diferentes términos y condiciones, lo que significa que no todos los seguros son iguales. Algunos cubren una gran gama de servicios médicos, mientras que otros sólo cubren servicios específicos.
          Revisa cuidadosamente los detalles de tu póliza para entender qué está incluido y qué no. En <>inter.mx</> tenemos el mejor comparador de seguros que te ayuda a cotizar, comparar y contratar entre las mejores aseguradoras para encontrar tu seguro ideal.

          <>¿Buscas un Seguro de Gastos Médicos? ¡Encuéntralo aquí!</>`
        },{
          header: `¿Por qué es importante contar con un seguro de gastos médicos mayores para papá?`,
          body: `Te permite tener la tranquilidad de que, en caso de que ocurra alguna emergencia médica, tu papá tendrá acceso a los mejores tratamientos y atención médica sin que represente un gran gasto económico para la familia. 
          También le da atención médica de calidad en el momento en que la necesite, sin tener que preocuparse por cuestiones económicas. 
          Protege lo que más amas, al mejor precio, fácil y rápido en <>inter.mx</>. Aprovecha los mejores beneficios que tenemos, como descuentos en medicamentos, médico a domicilio, ambulancia en caso de una emergencia, ¡y mucho más! Cotiza hoy mismo en <>inter.mx</>.

          ¿Quieres saber más? ¡Conoce todo sobre cualquiera de nuestros seguros en el <>blog</>! ¡Contrata fácil y rápido en <>inter.mx</>! Síguenos en <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</> para que no te pierdas de nada.`,
        }
      ]
    },
    {
      img: BG130,
      date: 'Junio 2023',
      autor: 'adminInter',
      title: '¿Estás preparando las vacaciones de Verano?',
      body: '¿Estás preparando las vacaciones de verano? ¡Te recomendamos 5 lugares increíbles!',
      content: [
        {
          header: ``,
          body: `En <>inter.mx</> nos preocupamos por tu seguridad, por eso te recomendamos siempre contratar <>Coche Seguro</> antes que nada. 

          1. Playa del Carmen, Quintana Roo: Puedes disfrutar de actividades acuáticas como el snorkel o el buceo, o simplemente relajarte bajo el sol mientras te das un chapuzón en el mar Caribe.
          2. Ciudad de México: Podrás admirar la majestuosidad de la Catedral Metropolitana y el Palacio Nacional. ¡No te lo puedes perder!
          3. Guanajuato, Guanajuato: Explora el famoso Callejón del Beso y además, si vas en en octubre,  no te pierdas el Festival Internacional Cervantino.
          4. Puerto Vallarta, Jalisco: Puedes aventúrate en una excursión en barco para explorar las Islas Marietas o dar un paseo por el encantador centro histórico, conocido como el Malecón.
          5. Oaxaca, Oaxaca: Esta hermosa ciudad colonial está llena de historia y arte. Visita la impresionante Iglesia de Santo Domingo de Guzmán y explora el mercado de artesanías.
          Revisa cuidadosamente los detalles de tu póliza para entender qué está incluido y qué no. En <>inter.mx</> tenemos el mejor comparador de seguros que te ayuda a cotizar, comparar y contratar entre las mejores aseguradoras para encontrar tu seguro ideal.

          ¿Quieres conocer otras maneras de protegerte? ¡Conoce más sobre cualquiera de nuestros seguros en el <>blog</>! No olvides que, además de Seguro de Coche, tenemos los mejores Seguros de Mascota y Seguros Médicos. ¡Contrata fácil y rápido en <>inter.mx</>! 

          <>¿Buscas un Seguro de Gastos Médicos? ¡Encuéntralo aquí!</>
          
          Síguenos en <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</> para que no te pierdas de más tips.`,
        }
      ]
    },
    {
      img: BG122,
      date: 'Mayo 2023',
      autor: 'adminInter',
      title: 'El internet llegó a quitarle lo complicado a los seguros',
      body: '¿Sabes qué es una insurtech y cómo está cambiando el mundo de los seguros? ¡Aquí te lo contamos y te decimos cómo inter.mx le quita lo complicado a los seguros!',
      content: [
        {
          header: ``,
          body: `Las insurtech han revolucionado la forma en que interactuamos con el mundo de los seguros. Pero, ¿qué son exactamente? Si has escuchado este término pero no sabes bien a qué se refiere, ¡no te preocupes! En este blog te explicamos, de manera sencilla, qué es una insurtech y cómo <>inter.mx</> le está quitando lo complicado a los seguros para proteger a todos los mexicanos.


          <>¿Buscas un Seguro de Gastos Médicos? ¡Encuéntralo aquí!</>`,
        },{
          header: `¿Qué es una insurtech?`,
          body: `Imagina que estás buscando un seguro para tu coche. Antes, esto implicaba visitar varias aseguradoras, llenar formularios largos y esperar por cotizaciones. ¡Pero eso ha cambiado! Una insurtech es una empresa que utiliza la tecnología y la innovación para simplificar y mejorar el proceso de adquirir seguros. Por ejemplo, ahora puedes cotizar, comparar y contratar tu seguro en cuestión de minutos, ¡sin salir de casa! Las insurtech han creado plataformas digitales fáciles de usar que te permiten obtener seguros de manera rápida y personalizada, adaptándose a tus necesidades y presupuesto. 
          Otro ejemplo es el uso de aplicaciones móviles para administrar tus pólizas de seguros. Antes, tenías que guardar papeles y documentos importantes en carpetas, y a veces era difícil encontrarlos cuando los necesitabas. Pero ahora, con una aplicación, puedes tener todas tus pólizas de seguros en un sólo lugar. Puedes revisar tus coberturas, hacer cambios en tu póliza, presentar reclamaciones y recibir asesoría en tiempo real, todo desde tu celular. Las insurtechs han creado herramientas digitales que hacen que la gestión de tus seguros sea mucho más sencilla y accesible en tu vida diaria.
          <>Palabras extrañas de tu seguro médico</>`
        },{
          header: `En inter.mx le quitamos lo complicado a los seguros`,
          body: `Con nuestro cotizador de seguros, puedes encontrar el seguro ideal para proteger lo que más amas al mejor precio. Ya no tienes que preocuparte por visitar múltiples aseguradoras, llenar formularios largos o buscar las letras chiquitas. En inter.mx, vas a tener una experiencia simplificada para que puedas proteger lo que más amas.
          Una de las ventajas de inter.mx es nuestra app, que te permite llevar todas tus pólizas en un sólo lugar. Con ella puedes tener acceso fácil y rápido a tus seguros, revisar tus coberturas y contactarnos por cualquier duda. Nuestro objetivo es hacer que la gestión de tus seguros sea conveniente y accesible en tu vida diaria, para que puedas tener la tranquilidad de estar protegido en todo momento. Estamos comprometidos a brindarte una experiencia amigable y sin complicaciones.
          ¡No dejes para mañana lo que puedes proteger hoy! Descarga nuestra app <>aquí.</>.
          
          <>Cotizador de seguros - Todo lo que necesitas a un clic de distancia</>


          ¡Conoce más sobre cualquiera de nuestros seguros en el <>blog</>! ¡Contrata fácil y rápido en <>inter.mx</>! Síguenos en <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</>.`,
        }
      ]
    },
    {
      img: BG123,
      date: 'Mayo 2023',
      autor: 'adminInter',
      title: 'Las coberturas de Coche Seguro que seguro, seguro te sacarán de un apuro',
      body: 'Descubre cómo encontrar las coberturas ideales para tu coche y protegerlo de la mejor manera.',
      content: [
        {
          header: ``,
          body: `Si eres dueño de un coche, seguramente sabes lo importante que es tener un seguro que te respalde en caso de cualquier imprevisto. Aunque todos esperamos no tener que hacer uso de él, es mejor estar preparados y conocer las distintas coberturas que existen para estar seguros de que estamos tomando la mejor decisión para nosotros y nuestro vehículo. En <>inter.mx</> contamos con diferentes coberturas para tu coche y estés seguro, seguro.


          <>Cotizador de seguros - Todo lo que necesitas a un clic de distancia</>`,
        },{
          header: `Coberturas básicas de un seguro de coche`,
          body: `Cuando se trata de asegurar nuestro coche, es importante conocer las diferentes coberturas que existen para estar protegidos en caso de algún imprevisto. Los seguros de coche pueden ofrecer desde coberturas básicas hasta opciones más completas con coberturas extras que nos brindan una mayor tranquilidad.
          Las coberturas básicas de un seguro de coche son esenciales para estar cubiertos en caso de algún accidente. Estas coberturas suelen variar según la aseguradora, pero en general incluyen las siguientes:
          ●	Responsabilidad civil: cubre los daños que puedas causar a terceros en caso de un accidente. Es la cobertura más importante y en algunos países es obligatoria por ley.
          ●	Robo o pérdida total: protege tu coche en caso de robo o pérdida total. 
          ●	Daños materiales: protege tu coche en caso de daños materiales como choques, vuelcos, colisiones, etc. 
          Estas tres coberturas son básicas y esenciales en cualquier seguro de coche. Si bien no cubren todos los imprevistos que puedan surgir, son una buena base para estar seguro, seguro. 
          <>¿Qué debo saber a la hora de contratar mi seguro de coche?</>`
        },{
          header: `¿Cuáles son las coberturas ideales para ti?`,
          body: `Para encontrar las mejores coberturas, debes hacerte estas preguntas:
          ●	¿Qué es lo qué más te preocupa cuando manejas? 
          ●	¿Con qué frecuencia usas tu coche? 
          ●	¿Vives en una zona de alto riesgo? 
          Busca las coberturas ideales para ti de acuerdo a las respuestas a estas preguntas. Recuerda que siempre es importante revisar las condiciones y restricciones de cada cobertura antes de contratar un seguro de coche. 
          En <>inter.mx</> contamos con las mejores opciones de seguro de coche, desde planes más básicos hasta más completos que incluyen coberturas adicionales como asistencia vial, protección de cristales, gastos médicos y más. Nuestro cotizador de seguros te permite conocer las diferentes opciones y comparar precios para encontrar el seguro ideal para ti. Recuerda que un seguro de coche es una inversión importante en tu seguridad y tranquilidad al manejar, no dudes en proteger tu coche fácil y rápido.


          <>¿Coche Seguro cubre mi accidente completo?</>


          ¡Conoce más sobre cualquiera de nuestros seguros en el <>blog</>! ¡Contrata fácil y rápido en <>inter.mx</>! Síguenos en <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</>.`,
        }
      ]
    },
    {
      img: BG124,
      date: 'Mayo 2023',
      autor: 'adminInter',
      title: 'Si mamá cuida de ti, cuídala con un seguro',
      body: 'Festeja a mamá con estas ideas que tenemos para ti. ¡Seguro, seguro se sentirá muy consentida!',
      content: [
        {
          header: ``,
          body: `En <>inter.mx</> sabemos que el Día de las Madres es la oportunidad perfecta para mostrarle a mamá cuánto la apreciamos y lo especial que es para nosotr@s. Una manera de hacerlo es planear un festejo, hacerle su comida favorita o regalarle un seguro que la cuide. No es necesario hacer cosas complicadas para expresar lo mucho que apreciamos todo lo que hacen por nosotr@s. 


          <>¿Buscas un Seguro de Gastos Médicos? ¡Encuéntralo aquí!</>`,
        },{
          header: `Ideas para festejar a mamá`,
          body: `¿No sabes qué hacer este Día de las Madres? ¡Aquí te compartimos algunas ideas sencillas para celebrar a mamá en su día especial!
          ●	Planea un viaje o una salida a un lugar que a mamá le guste o quiere conocer. Puede ser un pueblo mágico cercano, una playa, un parque temático o incluso otro país. La idea es hacer realidad su sueño de conocer ese lugar especial y junt@s compartir momentos memorables.
          ●	Prepara una comida especial con todos sus platillos favoritos. Puedes cocinar en casa o reservar en su restaurante favorito. ¡No olvides los postres, las bebidas y la decoración de la mesa para crear un ambiente especial!
          ●	Decora la casa con guirnaldas de flores, pon cojines o mantas de sus colores favoritos, coloca fotografías familiares en lugares especiales. La idea es que cada rincón de la casa transmita su estilo y personalidad, haciéndola sentir especial en su día.
          ●	O sorprende a mamá con gestos simples pero significativos, como prepararle un desayuno en la cama, regalarle un día de spa en casa con masajes y tratamientos de belleza, o simplemente pasar tiempo de calidad con ella disfrutando de actividades que más le gusten.
          ¿Qué te parecieron nuestras ideas? Tómalas en cuenta y haz de su día, una celebración inolvidable. 
          <>Médico Seguro: ¿Cómo funciona la cobertura de maternidad?</>`
        },{
          header: `¡No olvides darle el mejor regalo!`,
          body: `Nuestras mamás siempre han estado ahí para cuidarnos. Por eso, una excelente manera de demostrarle nuestro agradecimiento es regalándole un seguro de gastos médicos mayores. Este seguro puede darle la tranquilidad de tener acceso a la mejor atención médica en caso de enfermarse o tener algún accidente.
          Además, un seguro tiene grandes ventajas, como: consultas médicas a domicilio, descuentos en medicamentos, acceso a redes de hospitales y especialistas, asesoría médica telefónica, ¡y mucho más!
          En <>inter.mx</> encuentra el seguro ideal para mamá y personalízalo con las mejores coberturas. ¡Seguro, seguro será el mejor regalo!
          
          <>Cotizador de seguros - Todo lo que necesitas a un clic de distancia</>


          ¡Conoce más sobre cualquiera de nuestros seguros en el <>blog</>! ¡Contrata fácil y rápido en <>inter.mx</>! Síguenos en <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</>.`,
        }
      ]
    },
    {
      img: BG125,
      date: 'Mayo 2023',
      autor: 'adminInter',
      title: 'Ventajas de tener una mascota',
      body: 'Descubre cómo las mascotas pueden mejorar nuestra calidad de vida y los beneficios que tienen para nuestra salud física y mental.',
      content: [
        {
          header: ``,
          body: `Tener una mascota en casa es una experiencia que puede cambiar la vida de una persona para mejor. Los animales de compañía, como los perros y los gatos, no sólo proporcionan amor y compañía, sino que también tienen muchos otros beneficios para la salud física y mental de sus dueños. En inter.mx queremos compartirte las ventajas de tener una mascota y cómo pueden mejorar tu vida. ¡No olvides tenerlas seguras con Mascota Segura!
          <>¿Qué imprevistos cubren los Seguros para Mascotas?</>`,
        },{
          header: `Una mascota activa es una mascota feliz`,
          body: `¿Alguna vez has considerado tener una mascota? ¡Tener una puede ser una de las mejores decisiones que puedas tomar! Además de brindar amor y compañía, las mascotas también ofrecen estos beneficios:
          ●	Mejoran la salud física. Los dueños de mascotas, especialmente aquellos con perros, tienden a ser más activos físicamente. Pasear a tu perro es una forma de ejercicio y puede ayudar a mejorar tu salud cardiovascular y reducir el riesgo de enfermedades crónicas como la obesidad y la diabetes. Además, acariciar y jugar con tu mascota puede ayudar a reducir la presión arterial y disminuir el estrés.
          ●	Ayudan a mantener la mente en calma. Ayudan a aliviar la ansiedad, la depresión y el estrés, y pueden mejorar el estado de ánimo de sus dueños. 
          ●	Nos vuelven responsables. Tener una mascota también puede enseñarnos responsabilidad y ayudarnos a crear rutinas diarias saludables. Debemos asegurarnos que reciban suficiente ejercicio, nutrición y atención médica adecuada, lo que puede ayudar a mejorar la disciplina y el sentido de la responsabilidad. 
          ¡Tener una mascota puede ser una experiencia increíblemente gratificante para cualquier persona!
          <>Tres pasos para enseñarle a tu perrhijo buenos modales</>`
        },{
          header: `¡Son un gran apoyo emocional!`,
          body: `Otra ventaja de tener una mascota es su capacidad para ser una mascota de apoyo emocional. Las mascotas de apoyo emocional, como los perros, pueden proporcionar una ayuda importante para las personas que luchan contra problemas emocionales o trastornos de salud mental. Estos animales pueden ofrecer consuelo y apoyo, y ayudan a aliviar la ansiedad y el estrés.
          Sabemos que el bienestar de tu mascota es muy importante, por eso en <>inter.mx</> contamos con un seguro para mascotas que puede proteger a tus compañeros peludos de imprevistos médicos y accidentes. No te preocupes por los altos costos de las visitas al veterinario o los tratamientos de emergencia, nuestro seguro te estará ahí para apoyarte. ¡Cotiza ahora y protege a tu mejor amigo!


          <>Vacunas para mascotas: ¿cuáles le tocan a tu peludito en cada momento de su vida?</>

          
          ¿Te latió esta info? Checa todos los artículos que tenemos en nuestro <>blog</> para resolver todas tus dudas sobre seguros. ¿Tienes una pregunta? Ponte en contacto con nosotros en nuestras redes: <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</>`,
        }
      ]
    },
    {
      img: BG126,
      date: 'Mayo 2023',
      autor: 'adminInter',
      title: 'Tips para encontrar tu coche ideal',
      body: 'Encuentra el coche de tus sueños con estos tips. Desde definir tus necesidades hasta considerar el costo total, te ayudamos a tomar una decisión informada. ¡Seguro, seguro!',
      content: [
        {
          header: ``,
          body: `Aquí encontrarás algunos consejos y recomendaciones útiles para encontrar tu coche ideal. En <>inter.mx</> sabemos lo importante que es encontrar el coche ideal que se adapte a tus necesidades y presupuesto, por eso queremos compartir contigo algunos tips. Ya sea que busques un auto nuevo o usado, estos tips te ayudarán a tomar la mejor decisión y a ahorrar dinero en el proceso.
          
          <>¿Qué debo saber a la hora de contratar mi seguro de coche?</>`,
        },{
          header: `3 tips que seguro, seguro te van a ayudar.`,
          body: `Comprar un coche es una inversión importante que requiere tiempo, investigación y dinero. Por eso, es importante tomar en cuenta algunos factores clave antes de tomar una decisión. Aquí te damos tres tips para encontrar el coche ideal que se ajuste a tus necesidades y presupuesto.
          1. Define tus necesidades. Piensa en cómo vas a utilizar tu coche: ¿lo necesitas para la ciudad o para hacer viajes largos? ¿Tienes familia y necesitas un coche más grande? ¿Te importa más la eficiencia en el consumo de gasolina o la potencia? Responder estas preguntas te ayudará a tener una idea más clara de qué tipo de coche necesitas.
          2. Investiga opciones. En la actualidad, tenemos a nuestro alcance una gran cantidad de información en línea que nos puede ayudar a hacer una buena elección. Puedes buscar en línea reseñas de otros conductores, comparar precios y características, y hacer una lista de los modelos que más te interesan.
          3. Haz pruebas de manejo. Conducir el coche es la mejor manera de saber si realmente es el coche ideal para ti. Asegúrate de probar el coche en diferentes condiciones de manejo, como la ciudad y la carretera. 
          Con estos tips en mente, seguro, seguro, estarás mejor preparado para encontrar tu coche ideal.
          <>¿Coche Seguro cubre mi accidente completo?</>`
        },{
          header: `Proteger tu coche también te protege`,
          body: `El costo total es uno de los factores más importantes a considerar al momento de buscar un coche ideal. Es importante considerar que el costo de un seguro de auto puede variar dependiendo del modelo y año del coche. Por ejemplo, un coche deportivo tendrá una prima de seguro más alta que uno familiar. 
          Cuando busques tu coche ideal, también considera el costo de un seguro de auto y lo incluyas en el costo total del vehículo. De esta manera, podrás tener una mejor idea del presupuesto que necesitas para adquirir y mantener tu coche. Además, al considerar un seguro de auto, podrás estar tranquilo de que estás protegiendo tu coche y a ti mismo en caso de cualquier eventualidad.
          En <>inter.mx</> te ayudamos a encontrar el seguro de coche que mejor se adapte a tus necesidades y presupuesto. Con nuestro cotizador de seguros, podrás comparar entre diferentes aseguradoras y elegir la mejor opción, al mejor precio. 
          
          ¡No esperes más y cotiza hoy mismo!
          <>Cotizador de seguros - Todo lo que necesitas a un clic de distancia</>


          ¡Conoce más sobre cualquiera de nuestros seguros en el <>blog</>! ¡Contrata fácil y rápido en <>inter.mx</>! Síguenos en <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</>.`,
        }
      ]
    },
    {
      img: BG119,
      date: "Abril 2023",
      autor: "adminInter",
      title: "Día del Niñ@: ¿Cómo hablar de seguros con tus hij@s?",
      body: "En <>inter.mx</> nos encanta quitarle lo complicado a los seguros para que hasta l@s niñ@s puedan entender.",
      content: [
        {
          header: ``,
          body: `Aprovechando este Día del Niñ@, y que nos encanta quitarle lo complicado a los seguros, en <>inter.mx</> queremos enseñarte cómo puedes hablarle de seguros a tus hij@s. ¡Sigue leyendo para que no te pierdas de nadita! Segurísimo tus hij@s te lo van a agradecer cuando sean más grandes.
          
          <>Cotizador de seguros - Todo lo que necesitas a un clic de distancia</>`,
        },
        {
          header: `¡Niñ@s informados, niñ@s seguros!`,
          body: `Hablar de seguros con tus hij@s es importante porque les ayuda a entender la importancia de estar protegidos. Puedes explicarles que los seguros son una forma de estar preparados para imprevistos, como accidentes o problemas de salud, y que ayudan a proteger lo que más amas. 
          Una forma de explicarles es a través de ejemplos cotidianos. Por ejemplo, puedes explicarles que tener un seguro es como tener una red de seguridad cuando hacemos acrobacias en el parque. Nos ayuda a estar seguros y a protegernos si algo sale mal. De la misma manera, un seguro de hogar o de coche nos ayuda a protegerlos, en caso de accidentes o robos.
          O puedes explicarles que los seguros de salud nos ayudan a pagar los gastos médicos, en caso de que nos enfermemos o tengamos un accidente. De esta manera, estamos seguros de que tendremos la atención médica que necesitamos sin preocuparnos por el costo.
          Un seguro es una inversión para protegernos. Puedes hablarles de la tranquilidad que da saber que estamos protegidos y que no tendremos de qué preocuparnos.
          ¡Así de fácil y sin complicaciones nos encanta explicarlos! Ahora te toca a ti hablarle de seguros a tus hij@s y para eso te tenemos algunas súper estrategias.
          <>Protege a quien más amas con médico seguro</>`,
        },
        {
          header: `¡Jugando con seguros!`,
          body: `Hablar con tus hij@s de seguros no tiene que ser aburrido. Por eso te compartimos algunas estrategias que seguro les encantarán:
          1. Platícales cómo nosotros te platicamos de seguros, con palabras y ejemplos súper sencillitos. Un súper tip es usar sus juguetes como herramientas didácticas para los ejemplos. ¡Usa tu imaginación y ayuda a tus hij@s a usar la suya!
          2. ¡Haz flash-cards con las palabras que puedan ser más complicadas! Además de entender de qué se tratan los seguros, puedes agregar un nivel más explicándole palabras relacionadas con los seguros, como las que tenemos aquí: <>Palabras extrañas de tu Seguro Médico</>. Haz tarjetas que tengan de un lado la palabra y del otro un dibujito con la definición para que puedan jugar con ellas y aprender estos conceptos más fácil.
          
          3. ¡Con l@s niñ@s todo debe ser un juego siempre! Hazle preguntas que l@ pongan a pensar. Dale puntos o premios cada vez que sepa la respuesta correcta y ayúdale a responder cuando se atore en algo.
          ¡Con estos tips, estamos seguros que tus hij@s se volverán unos expertos de seguros! 
          <>Recuperación Segura, el Seguro Médico más accesible.</>`,
        },
        {
          header: `¡Feliz Día del Niñ@!`,
          body: `¡Así de fácil es explicarle a nuestros hij@s qué es un seguro! Cuando explicamos en palabras sencillas y ejemplos cotidianos, nuestros hij@s pueden tener mayor tranquilidad al escuchar la palabra “seguro”.
          Celebra este Día del Niñ@ protegiendo lo que más amas. Encuentra tu seguro médico ideal con las mejores aseguradoras. ¡Entra a <>inter.mx</> y enséñale a tus hij@s cómo contratas tu seguro súper fácil y rápido!
          
          Psst… Psst… Recuerda que en <>inter.mx</> le quitamos lo complicado a los seguros para que puedas encontrar el mejor seguro de gastos médicos mayores para proteger lo que más amas al mejor precio. 
          
          ¿Te gustaron nuestros tips? ¡Checa todo lo que tenemos en nuestro <>blog</> para ti! Y si aún tienes preguntas, contáctanos como ya sabes o síguenos en nuestras redes sociales: <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</>`,
        },
      ],
    },
    {
      img: BG120,
      date: "Abril 2023",
      autor: "adminInter",
      title: "Mascota Segura: ¿cómo escoger el mejor juguete para tu peludito?",
      body: "Entre toda la variedad de juguetes para mascota que existen, ¿cuál es el mejor y más seguro para tu mascota? ¡Aquí te lo contamos!",
      content: [
        {
          header: ``,
          body: `Tener una mascota puede ser muy divertido, pero también es una graaan responsabilidad. Por eso, es importantísimo que elijamos los juguetes más adecuados para nuestros peluditos y que al mismo tiempo sean súper entretenidos y seguros.
          Aquí te tenemos unas recomendaciones para saber cómo escoger el mejor juguete para tu peludito. Psst… psst… Y de paso te platicaremos algunas cosas que debes saber de tu seguro para mascotas con <>inter.mx</>.
          
          ¡Sigue leyendo!
          <>¿Hay límite de edad en un Seguro para Mascotas?</>`,
        },
        {
          header: `Una mascota activa es una mascota feliz`,
          body: `Si tienes una mascota, es importantísimo que sepas que mantenerla activa es fundamental para su salud física y mental. Al igual que los humanos, las mascotas necesitan ejercicio para mantenerse saludables y felices. Una mascota activa también puede ser más obediente, ya que el ejercicio ayuda a reducir su ansiedad y estrés.
          Existen muchas formas de mantener a tu mascota activa. Por ejemplo, si tienes un perro, puedes sacarlo a caminar varias veces al día o jugar con él en el parque. Si tienes un gato, puedes darle juguetes que estimulen su instinto de caza, como ratones de juguete o plumas. Incluso puedes construir un pequeño circuito de obstáculos para que tu gato lo explore y se mantenga activo.
          Recuerda que cada mascota es diferente y tiene necesidades únicas. Con un poco de tiempo puedes mantenerla activa y feliz.`,
        },
        {
          header: `¡Juguetes seguros, mascotas seguras!`,
          body: `A la hora de escoger un juguete para tu peludito, debes tomar en cuenta algunos factores para asegurarte de que sea el ideal para tu mascota. 
          Considera el tamaño y la edad de tu perro. Un juguete demasiado pequeño o frágil podría romperse y ser ingerido, provocando algún problema de salud. Además, si es un cachorro, necesitará juguetes que sean suaves y fáciles de morder.
          ¿Cómo saber cuál tipo de juguete es el mejor? ¡Es muy sencillo! Si a tu peludito le gustan los juguetes para morder, compra huesos de nylon, juguetes de goma y peluches resistentes. Ahora, si tu cuatropatitas es fan de lanzar y perseguir, pueden ser pelotas, frisbees o juguetes voladores. Algunos juguetes, como los kongs, pueden estar llenos de comida y ser un gran estímulo mental para tu perro.
          Siempre, siempre asegúrate de revisar los materiales del juguete y su calidad. Busca juguetes duraderos y resistentes que no sean tóxicos y que no se deshagan fácilmente. 
          <>Tres pasos para enseñarle a tu perrhijo buenos modales</>`,
        },
        {
          header: `Un Seguro de Mascotas nunca puede faltar`,
          body: `Si tienes un perrito protégelo con un seguro de mascotas. Mascota Segura cubre accidentes, enfermedades o -toquemos madera- si estira la patita.
          En <>inter.mx</> le quitamos lo complicado a los seguros, encuentra el seguro de mascota ideal para tu peludito de manera fácil y rápida. Puedes cotizar al mejor precio y elegir las mejores coberturas.
          
          ¡Entra a inter.mx o <>haz click aquí</>  y contrata tu Seguro de Mascota hoy mismo!
          
          <>¿Qué imprevistos cubren los Seguros para Mascotas?</>
          
          ¿Te latió esta info? Checa todos los artículos que tenemos en nuestro <>blog</> para resolver todas tus dudas sobre seguros. ¿Tienes una pregunta? Ponte en contacto con nosotros en nuestras redes: <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</>`,
        },
      ],
    },
    {
      img: BG121,
      date: "Abril 2023",
      autor: "adminInter",
      title: "Mes de la salud: tips infalibles para proteger tu salud",
      body: "Protege tu salud con estos tips súper fáciles de seguir. ¡Seguro, seguro con inter.mx!",
      content: [
        {
          header: ``,
          body: `Cuidar nuestra salud es una tarea importante en nuestra vida cotidiana. A veces puede parecer difícil, pero no tienes que hacer cambios súper drásticos para empezar a mejorar tu salud. Pequeñas acciones pueden hacer una gran diferencia. En <>inter.mx</> te compartimos 5 tips para proteger tu salud que podrás aplicar en tu día a día. 
          
          ¡Sigue leyendo para que no te pierdas de nada!
          <>¿Buscas un Seguro de Gastos Médicos? ¡Encuéntralo aquí!</>`,
        },
        {
          header: `Tips para proteger tu salud`,
          body: `Este mes de la salud es una oportunidad perfecta para enfocarnos en cómo podemos proteger nuestra salud y bienestar. Aquí te compartimos cinco tips infalibles para mantener una buena salud:
          1. Come alimentos saludables: una dieta equilibrada es fundamental para mantener una buena salud. Intenta comer más frutas y verduras, proteínas y granos integrales.
          2. Haz ejercicio regularmente: el ejercicio es esencial para mantener un cuerpo sano y fuerte. Puedes intentar caminar 30 minutos al día, hacer una clase de yoga en línea o tomar clases de baile.
          3. Duerme lo suficiente: intenta dormir al menos 7 horas por la noche. Si tienes problemas para dormir, puedes intentar reducir el consumo de cafeína o crear un ambiente de sueño relajante en tu habitación.
          4. Bebe suficiente agua: es importante mantener nuestro cuerpo hidratado y funcionando correctamente. Puedes llevar una botellita de agua contigo todo el día para mantenerte hidratad@.
          5. Reduce el estrés: el estrés puede tener un impacto negativo en nuestra salud, así que es importantísimo encontrar formas de reducirlo. Puedes probar meditar, yoga, leer un libro o escuchar música para relajarte.
          Recuerda que no tienes que hacer grandes cambios en tu vida para mantener una buena salud. Sigue leyendo para que te contemos nuestro mejor tip para protegerte.
          <>Palabras extrañas de tu seguro médico</>`,
        },
        {
          header: `El mejor tip para estar seguro, seguro`,
          body: `Este mes de la salud es el momento perfecto para encontrar el seguro ideal para tu estilo de vida y que te ayude a estar tranquil@.
          En <>inter.mx</> puedes cotizar y comparar entre las mejores aseguradoras. Encuentra tu seguro de gastos médicos mayores ideal para proteger tu salud y la de tu familia al mejor precio. Compara entre las mejores aseguradoras y encuentra la mejor opción para tu seguro de gastos médicos mayores.
          
          <>Cotizador de seguros - Todo lo que necesitas a un clic de distancia</>
          
          ¡Conoce más sobre cualquiera de nuestros seguros en el blog! ¡Contrata fácil y rápido en <>inter.mx</>! 
          
          Síguenos en <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</> para que no te pierdas de naditita.`,
        },
      ],
    },
    {
      img: BG115,
      date: "Marzo 2023",
      autor: "adminInter",
      title:
        "Las coberturas que seguro, seguro te pueden sacar de apuros estas vacaciones",
      body: "¿Te vas de roadtrip en Semana Santa? Vete seguro, seguro con inter.mx.",
      content: [
        {
          header: ``,
          body: `Si eres de los que les gusta viajar en coche, debes saber que es importante contar con un Seguro de Coche para estar protegid@ en caso de cualquier eventualidad. También, siempre que viajes a donde sea es importantísimo contar con un Seguro Médico. ¡Aquí te contamos las coberturas que más necesitas!
          En inter.mx tenemos el mejor cotizador de seguros que te permite comparar entre las mejores aseguradoras, precios y personalizar tus coberturas. ¡Sigue leyendo!
          <>¿Qué debo saber a la hora de contratar mi seguro de coche?</>`,
        },
        {
          header: `¿Por qué es importante tener un seguro de coche si vas a viajar?`,
          body: `Cuando estás en la carretera, la posibilidad de tener un accidente es más alta, ya sea por condiciones climáticas, problemas mecánicos, errores humanos o incluso acciones de terceros. En caso de que te suceda –obvio tocamos madera–, el costo de los daños puede ser muy elevado, especialmente si hay heridos o si causaste daños a otros coches.
          Tener un seguro de coche te da tranquilidad y protección financiera en caso de un accidente. Además, algunos seguros de coche también incluyen cobertura para emergencias en carretera, asistencia legal y protección contra robos.
          Y no sólo eso, sino que con la cobertura de Asistencia en viajes, tu Seguro de Coche te apoya también si tienes algún percance por el que ya no puedas manejar de regreso a tu ciudad de origen. ¡A que no sabías que tu seguro te puede pagar unos cuantos días de hotel y/o tu regreso en camión, por si tienes un problema durante tu viaje! Checa bien tu póliza o pregúntale a tu aseguradora si tiene esta cobertura y qué restricciones puede haber. 
          Si vas a viajar, no sólo es importante contar con tu Seguro de Coche, sino también con un Seguro Médico. ¡Sigue leyendo y te contamos por qué!
          <>Asistencias de tu Seguro de Coche que no sabías que existían</>`,
        },
        {
          header: `inter.mx te protege hasta cuando sales de vacaciones`,
          body: `Con Médico Seguro puedes tener una cobertura de emergencia en el extranjero en caso de que algo te ocurra mientras estás fuera de México, y así como atención en todos los estados si viajas dentro de la República. Esto significa que si te enfermas o te lastimas en tus vacaciones –¡tocamos madera!–, este seguro te protegerá. (Psst… psst… La cantidad que te cubre dependerá de la suma asegurada que hayas contratado en tu póliza).
          ¿Y qué pasa con los honorarios médicos? ¡Muy sencillo! La aseguradora tiene un tabulador de acuerdo al plan que hayas elegido. No tendrás que pagar coaseguro y podrás solicitar el pago directo o el reembolso de los gastos médicos. 
          ¡Recuerda leer tooodas las condiciones generales de tu seguro para conocer súper bien qué sí te cubre y qué no! 
          Si aún no tienes un <>seguro de coche</> o un <>seguro médico</>, cotiza y contrata en <>inter.mx</>, fácil y rápido al mejor precio.


          <>Cotizador de seguros - Todo lo que necesitas a un clic de distancia</>
          
          ¡Conoce más sobre cualquiera de nuestros seguros en el <>blog</>! Síguenos en <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</> para que no te pierdas de naditita.`,
        },
      ],
    },
    {
      img: BG116,
      date: "Marzo 2023",
      autor: "adminInter",
      title: "¿Por qué leer las condiciones generales de tu seguro?",
      body: "¿Sabes por qué es importante leer las condiciones generales de tu seguro? ¡En <>inter.mx</> te lo contamos!",
      content: [
        {
          header: ``,
          body: `En <>inter.mx</> sabemos lo importante que es leer las condiciones generales de tu seguro, especialmente si se trata de un seguro de gastos médicos mayores. 
          Entendemos que puede sonar aburrido y a veces nos da flojera leer todo el contrato, pero te aseguramos que esto puede marcar la diferencia entre tener una experiencia positiva o negativa con tu seguro. 
          Sigue leyendo para que no te pierdas de nadita.
          <>¿Buscas un Seguro de Gastos Médicos? ¡Encuéntralo aquí!</>`,
        },
        {
          header: `¡Evita cualquier sorpresa!`,
          body: `Primero, hablemos de las aseguradoras. Estas empresas se dedican a ofrecer seguros para proteger a las personas en caso de cualquier eventualidad, como accidentes, enfermedades, daños a la propiedad, entre otros. Y es importante mencionar que cada aseguradora tiene sus propias condiciones y políticas, por lo que es súper importante leer los términos específicos de tu póliza.
          Ahora, ¿por qué es importante leer las condiciones generales de tu seguro de gastos médicos mayores? Pues bien, estas condiciones contienen la info relevante sobre los beneficios que ofrece tu seguro, las exclusiones (es decir, los casos en los que tu seguro no te cubre), los límites de cobertura, las primas y deducibles, entre otros detalles importantes.
          Leer las condiciones generales te permite saber exactamente qué estás contratando y qué esperar en caso de necesitar tu seguro. Además, esto te ayudará a evitar sorpresas desagradables en el futuro, como descubrir que ciertos tratamientos o padecimientos no están cubiertos o que tu cobertura tiene un límite máximo que no cubre todos tus gastos.
          Es comprensible que el lenguaje utilizado en los contratos de seguros pueda ser un poco confuso, especialmente cuando se trata de términos técnicos. Pero no te preocupes, si tienes dudas o no entiendes algo, no dudes en preguntarnos. ¡Es mejor aclarar cualquier duda!
          <>Palabras extrañas de tu seguro médico</>`,
        },
        {
          header: `Bueno, ¿y qué es una póliza?`,
          body: `La póliza de tu seguro es el documento oficial que establece lo que son los términos del contrato de tu seguro. Esta póliza es un resumen de las condiciones generales de tu seguro y debe incluir toda la información relevante sobre tu cobertura. 
          Con <>inter.mx</> no tienes que preocuparte por perder tu póliza o de no tenerla a la mano, porque te lo hacemos súper fácil. Puedes tener todas tus pólizas en un sólo lugar… ¡en nuestra app! 
          Recuerda que leer las condiciones generales de tu seguro de gastos médicos mayores (y cualquier otro seguro) es fundamental para saber exactamente qué estás contratando, evitar sorpresas desagradables y estar preparado para cualquier eventualidad. 
          Si aún no tienes un <>seguro de gastos médicos mayores</>, cotiza y contrata en <>inter.mx</>, fácil y rápido al mejor precio.
          
          <>Cotizador de seguros - Todo lo que necesitas a un clic de distancia</>

          ¡Conoce más sobre cualquiera de nuestros seguros en el <>blog</>! ¡Contrata fácil y rápido en <>inter.mx</>! 

          Síguenos en <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</> para que no te pierdas de naditita.`,
        },
      ],
    },
    {
      img: BG117,
      date: "Marzo 2023",
      autor: "adminInter",
      title: "Médico Seguro: ¿Cómo funciona la Cobertura de Maternidad?",
      body: "¿Estás planeando crecer la familia? Es el momento ideal para contratar un Seguro Médico con cobertura por Maternidad.",
      content: [
        {
          header: ``,
          body: `Decidir embarazarse no es cualquier decisión, sino que hay muchas cosas a considerar antes de la llegada de un bebé a la familia. Sin duda, lo más importante es que tanto la mamá como el bebé puedan contar con la protección que necesitan para acompañarlos en esta hermosa jornada desde el inicio. En este artículo te contamos de qué se trata la cobertura de Maternidad en tu Seguro Médico y todo lo que necesitas saber para tomar la mejor decisión.
          ¡Sigue leyendo!
          <>Protege a quien más amas con un Seguro Médico</>`,
        },
        {
          header: `Un embarazo seguro, seguro`,
          body: `La maternidad y el embarazo son momentos súper bonitos que pueden ser planeados con anticipación. Por supuesto, entre las cosas que hay que considerar desde antes son el tema económico y el de salud. 
          Por una parte, la familia debe estar preparada para todos los gastos que conlleva traer un bebé al mundo. Esto es desde todos los estudios y visitas médicas que la mamá debe tener a lo largo del embarazo, el parto o la cesárea, la atención médica adicional que puede llegar a necesitar la mamá o el bebé luego del nacimiento, hasta los gastos de pañales, ropa, juguetes, carriola, cuna, comida, guardería, escuela, etc.
          Por otra parte, para un embarazo seguro, seguro, es esencial pensar en la salud. La mamá debe estar súper lista desde antes del embarazo, con una gran alimentación y estar en la mejor condición posible. Luego, durante todo el proceso de gestación, es importantísimo estar checando tanto a la mamá como al bebé para asegurarse de que todo vaya bien. Y finalmente, luego de dar a luz, hay que estar aún más atentos de la salud del bebé y la recuperación de la mamá. 
          Por eso, es indispensable contar con un Seguro Médico con cobertura de Maternidad, que segurísimo será un gran apoyo en esta etapa increíble. ¡Sigue leyendo para conocer todo de esta cobertura!
          <>¿Qué preguntas debes responder al contratar un Seguro Médico?</>`,
        },
        {
          header: `Tu Cobertura de Maternidad ideal`,
          body: `Si ya están segurísimos de que quieren tener un bebé, ¡que no les falte su Seguro Médico con cobertura de Maternidad! Checa todo lo que esta protección puede hacer por la mamá y el bebé:
          -	Gastos del parto o cesárea
          -	Cunero, atención pediátrica y enfermería
          -	Complicaciones del embarazo
          -	Complicaciones del recién nacido
          -	Padecimientos congénitos
          -	Cirugía fetal
          ¡Ojo! Para poder hacer válida tu Cobertura por Maternidad, es súper importante que la hayas contratado con suficiente tiempo antes del embarazo –por lo general, suelen ser entre 10 y 13 meses–. ¡Los tiempos necesarios pueden variar entre aseguradoras! 
          Es importantísimo que sepas también que no todas las aseguradoras cuentan con todos los beneficios, por eso es súper importante que cheques bien las coberturas antes de contratar.
          Con Médico Seguro puedes personalizar tu protección y agregar la Cobertura de Maternidad en el momento de contratar o cuando lo desees. Recuerda que si tienes dudas, ¡puedes contactarnos por teléfono 55-4424-6837, WhatsApp 5543269037 o mail <>hola@inter.mx</> en el momento que sea y nosotros te respondemos toditas tus preguntas!
          
          ¡Entra a <>inter.mx</> hoy mismo y cotiza tu Seguro Médico con Cobertura de Maternidad! <>https://medicoseguro.inter.mx/inicio</> 
          
          <>¿Por qué es importante hacerte un check-up médico?</>
          
          ¿Te sacamos de la duda con este artículo? ¡Checa todo lo que tenemos en nuestro <>blog</> para ti! Y si aún tienes preguntas, contáctanos como ya sabes o síguenos en nuestras redes sociales: <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</>`,
        },
      ],
    },
    {
      img: BG118,
      date: "Marzo 2023",
      autor: "adminInter",
      title: "Coche Seguro: ¿Qué hace un ajustador de seguros en un percance?",
      body: "¿Alguna vez te has preguntado qué es lo que hace un ajustador de seguros cuando tienes un percance en el coche? ¡Aquí te sacamos de la duda!",
      content: [
        {
          header: ``,
          body: `Chocar o tener un percance en el coche definitivamente no es un gran momento y quisiéramos que nunca de los nuncas nos pasara –nosotros obvio tocamos madera siempre por nuestros asegurad@s–, pero si llega a suceder, es mejor estar bien acompañad@. Y no nos referimos a tu copiloto, sino al acompañamiento de tu Seguro de Coche y tu ajustador de seguros.  
          ¡Sigue leyendo para enterarte de todo lo que tu Seguro de Coche y tu ajustador pueden hacer por ti!
          <>¿Coche Seguro cubre mi accidente completo?</>`,
        },
        {
          header: `Un súper ajustador de seguros`,
          body: `Siempre que tienes un percance en el coche y le echas un telefonazo a tu aseguradora, ésta segurísimo te va a mandar a un ajustador de seguros a donde sea que estés. A nosotros nos encanta referirnos al ajustador como Superman y queremos contarte por qué.
          1. El ajustador de seguros es el representante de tu aseguradora y quien te apoyará con todo lo que se necesite durante tu reclamación del seguro.
          2. Cuando llega a donde tuviste el accidente, él va a revisar lo que ocurrió, va a platicar contigo y decirte qué hacer.
          3. El ajustador va a inspeccionar los daños, checar los informes de la policía, hablar con testigos y buscar toda la info que se necesite para poder estimar el costo del percance y lo que tu seguro te puede cubrir.
          4. También, él será el negociador con el seguro de los demás coches que puedan estar involucrados en el accidente, siempre buscando que tú tengas la mejor resolución en el caso.
          ¡Así tú no te preocupas de nada!
          ¡Ojo! Es importantísimo que tu ajustador te dé su contacto para que puedas hablarle en cualquier momento si tienes dudas o inquietudes. De igual manera él estará en comunicación contigo porque será a través de él que tu aseguradora te dirá qué toca pagar, qué procede, etc.
          ¿Ves? Con tu Seguro de Coche nunca estarás sol@ en una situación así.
          <>¿Qué debo saber a la hora de contratar mi Seguro de Coche?</>`,
        },
        {
          header: `Tu Seguro de Coche ideal`,
          body: `Si aún no tienes Seguro de coche o estás pensando en cambiarte de aseguradora, ¡en <>inter.mx</> segurísimo encuentras tu protección ideal! Con Coche Seguro, nuestro súper cotizador, contratar tu seguro perfecto es súper fácil y rápido. Además, ahí encuentras las mejores opciones, con las mejores aseguradoras y al mejor precio.
          
          ¿Ya conoces nuestras coberturas? Aquí te dejamos la lista del plan Amplio:
          -	Daños materiales
          -	Robo total
          -	Responsabilidad civil
          -	Gastos médicos
          -	Asistencia en viajes
          -	Defensa legal
          Está buenísimo, ¿no? Además, puedes contar con otros grandes beneficios adicionales como asistencia vial, gasolina, cerrajería y más.
          ¿Qué esperas? ¡Entra a <>inter.mx</> y contrata tu Seguro de Coche hoy mismo! <>https://cocheseguro.inter.mx/<> 

          <>Asistencias de tu Seguro de Coche que no sabías que existían</>


          ¿Te sacamos de la duda con este artículo? ¡Checa todo lo que tenemos en nuestro <>blog</> para ti! Y si aún tienes preguntas, contáctanos como ya sabes o síguenos en nuestras redes sociales: <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</>`,
        },
      ],
    },
    {
      img: BG111,
      date: "Febrero 2023",
      autor: "adminInter",
      title: "¿Coche Seguro cubre mi accidente completo?",
      body: "¿Quieres saber qué le toca pagar a tu seguro de coche en un accidente? ¡Aquí te lo contamos!",
      content: [
        {
          header: ``,
          body: `Imagina que vas de lo más tranquil@ manejando, sin ninguna prisa pero a buen ritmo, cuando de pronto se atraviesa otro coche de la nada y ¡pum! Obvio esperamos que nunca te pase algo así, pero si te llega a suceder, ¿sabes qué te cubre tu Seguro de Coche? No te preocupes, aquí te lo explicamos todo.
          <>Coche Seguro: híbrido, eléctrico o de gasolina</>`,
        },
        {
          header: `Primero lo primero… ¿Qué debes hacer si tienes un accidente?`,
          body: `Antes que nada, checa si hay heridos y en caso de haberlos, llama al 911. Luego toca hablarle a tu aseguradora y platicar todo lo que pasó con lujo de detalle. Tu aseguradora te va a decir qué hacer (por ejemplo, si debes intentar mover tu coche o mejor dejarlo ahí mismo) y te mandará a un ajustador –nosotros le llamamos “súperman”– para que te apoye con toditito lo que se necesite ahí mismo. Así tú no tendrás nada de qué preocuparte. 
          ¡Sigue leyendo para ver qué te toca pagar a ti y qué a tu seguro!
          <>¿Qué debo saber a la hora de contratar mi seguro de coche?</>`,
        },
        {
          header: `Ahora sí, hablemos del deducible de tu Seguro de Coche`,
          body: `Cada vez que necesitas hacer uso de tu Seguro de Coche, hay un pequeño monto inicial que debes pagar, que varía para cada cobertura. Éste es el deducible de tu Seguro de Coche. Si tu accidente supera el monto del deducible, tu seguro entra a echarte la mano con el resto.
          Entonces, para fines prácticos tú pagas el deducible y tu seguro se encargará de todo lo demás. 
          ¡Pero ojo! Recuerda que tu seguro sólo te apoyará con las coberturas y asistencias que tengas contratadas. ¡Checa tu póliza para conocerlas a detalle! Mientras tanto, aquí te recordamos la protección que puedes llegar a tener con Coche Seguro con un nivel de cobertura Amplia:`,
          list: [
            `Daños materiales`,
            `Robo total`,
            `Responsabilidad civil por daño a terceros`,
            `Gastos médicos`,
            `Defensa legal`,
            `Responsabilidad civil`,
            `Asistencia en viajes`,
          ],
        },
        {
          header: ``,
          body: `Está buenísimo, ¿no? Un seguro de veras que sí te puede sacar de aprietos en cualquier momento. Además… ¡en <>inter.mx</> seguro, seguro estamos contigo siempre!
          
          <>Asistencias de tu Seguro de Coche que no sabías que existían</>`,
        },
        {
          header: `inter.mx contigo en todo momento`,
          body: `Sin importar a dónde vayas, con Coche Seguro de <>inter.mx</> siempre estás súper bien protegid@ en todas las calles y carreteras del país. 
          
          ¡Descarga la app inter.mx para que lleves tu póliza contigo a todas partes y te puedas poner en contacto con nosotros y tu aseguradora de volada en cualquier momento! Con nuestra app, acceder a tu seguro es mucho más fácil.
          ¿Aún no tienes Coche Seguro? ¡No esperes más! Entra a <>inter.mx</> y contrata tu seguro en menos de 5 minutos. Cotiza y compara entre las mejores aseguradoras del país y encuentra el seguro que mejor vaya contigo. ¡Así de fácil!
          
          ENTRA AQUÍ PARA COTIZAR TU SEGURO DE COCHE: <>https://cocheseguro.inter.mx/</>
          
          <>¿Qué pasa con mi Seguro de Coche si vendo mi auto?</>
          
          ¿Te latió esta info? Checa todos los artículos que tenemos en nuestro <>blog</> para resolver todititas tus dudas sobre seguros. ¿Tienes una pregunta? Ponte en contacto con nosotros en nuestras redes: <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</>`,
        },
      ],
    },
    {
      img: BG112,
      date: "Febrero 2023",
      autor: "adminInter",
      title: "Si no tengo compu, ¿cómo puedo contratar mi seguro?",
      body: "¿No sabes cómo comprar un seguro? ¡Te contamos todas las maneras en las que puedes contratar un seguro con inter.mx!",
      content: [
        {
          header: ``,
          body: `En nuestra plataforma web inter.mx tenemos todos los seguros que necesitas a unos cuantos clicks de distancia. Pero… ¿hay otras maneras de contratar un seguro con <>inter.mx</>? ¡Claro! Como nos encanta quitarle lo complicado a los seguros, tenemos un montón de formas en las que puedes protegerte. ¡Sigue leyendo para que te enteres de todas!
          
          <>¿Las coberturas cambian dependiendo de la aseguradora?</>`,
        },
        {
          header: `inter.mx, nuestra plataforma digital`,
          body: `Para nosotros ha sido muy importante liderar la revolución digital de los seguros en México. Por eso hace unos años desarrollamos <>inter.mx</>, nuestra plataforma digital donde puedes encontrar los seguros que tú quieras desde cualquier compu o celular. En nuestro sitio web  puedes cotizar, comparar y contratar tus seguros en un dos por tres.

          Éstos son algunos de los productos que puedes encontrar:`,
          list: [
            `Médico Seguro – donde encontrarás el seguro de gastos médicos mayores perfecto para ti.`,
            `Coche Seguro – el mejor lugar para buscar el seguro ideal para tu auto.`,
            `Mascota Segura – un seguro perfecto para todo peludito consentido del hogar.`,
          ],
        },
        {
          header: ``,
          body: `VISITA NUESTRA PLATAFORMA DIGITAL: <>https://inter.mx/</>
          
          Y si no tengo compu o prefiero hablar con alguien para contratar mi seguro, ¿se puede? ¡Claro! 
          <>¿Qué debo saber a la hora de contratar mi seguro de coche?</>`,
        },
        {
          header: `Estamos a un mensajito o telefonazo de distancia`,
          body: `Si lo tuyo no es el internet, tienes dudas de nuestros productos, o de plano prefieres hablar con alguien para contratar tu seguro, ¡tenemos a nuestros súper asesores para apoyarte! Ya sea por WhatsApp o vía telefónica, estamos para ayudarte en todo momento.
          También, ésta es la mejor vía si lo que quieres es contratar una cobertura especial, hacer un cambio en una póliza o contratar un complemento para tu protección (por ejemplo Prevención Seguro y Recuperación Segura, que son seguros médicos más sencillos que pueden hacer un gran combo con Médico Seguro).
          ¡Toma nota de nuestros teléfonos de contacto!`,
          list: [
            `Para mensajitos de WhatsApp: 5543269037`,
            `Para una llamadita: 55-4424-6837
            Psst… psst… ¿Y si yo prefiero hacer todo por mail? ¡También se vale!`,
            `Éste es nuestro correo: hola@inter.mx`,
          ],
        },
        {
          header: ``,
          body: `Ahora sí, no hay pretextos. ¡Contrata tu seguro como tú quieras con <>inter.mx</>!

          <>La vida es complicada, tener un seguro no lo es</>.

          ¿Te sacamos de la duda con este artículo? ¡Checa todo lo que tenemos en nuestro <>blog</> para ti! Y si aún tienes preguntas, contáctanos como ya sabes o síguenos en nuestras redes sociales: <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</>`,
        },
      ],
    },
    {
      img: BG113,
      date: "Febrero 2023",
      autor: "adminInter",
      title: "¡Día internacional de amar a tu mascota!",
      body: "En inter.mx encuentras el mejor seguro para proteger a tu perrhijo o gathijo al mejor precio.",
      content: [
        {
          header: ``,
          body: `Si tienes un lomito o un michi, seguro, seguro esta info te va a quedar como anillo al dedo. Tener un peludito es una súper responsabilidad. Por eso en <>inter.mx</> queremos contarte por qué es buena idea tener un seguro para mascotas. 
          
          Sigue leyendo para que no te pierdas los beneficios que tenemos para proteger a tu mascota.
          <>Vacunas para mascotas: ¿Cuáles le tocan a tu peludito en cada momento de su vida?</>`,
        },
        {
          header: `¿Seguro para mascotas?`,
          body: `Contratar un seguro para tu perrito o gatito es igual de importante que asegurar tu salud o tu coche. Por eso, celebra el Día Internacional de Amar a tu Mascota con el seguro ideal para tu peludito. 
          En <>inter.mx</> queremos que protejas lo que más amas. Sabemos lo importante que es proteger a tu mascota contra enfermedades, accidentes o travesuras. Con un seguro de mascotas puedes estar tranquil@, y si algo llega a pasar –¡toquemos madera!–, tu seguro estará ahí para ayudarte.
          
          Lo mejor de tener una mascota son sus muestras de amor incondicional y verlos muy felices y sanos, ¿a poco no?
          <>Tres pasos para enseñarle a tu perrhijo buenos modales</>.`,
        },
        {
          header: `Conoce los beneficios de Mascota Segura`,
          body: `En <>inter.mx</> contratar tu seguro de mascota es muy fácil y rápido.
          Elige entre los diferentes paquetes que tenemos: Mascota, Garritas o Mejor amigo. Protégelo de accidentes, caídas, golpes de calor, intoxicaciones, si le hace una travesura a alguien, si estira la patita –lo peor del mundo, ojalá los peluditos fueran eternos–, ¡y mucho más! 
          ¡Además Mascota Segura tiene los mejores beneficios! Aprovéchalos una vez al año y te reembolsamos:`,
          list: [
            `Una vacunación antirrábica o desparasitación (hasta $200)`,
            `Una cortadita de pelo al año (el reembolso depende del peso de tu mascota)`,
            `Psst… psst… ¿Y si yo prefiero hacer todo por mail? ¡También se vale!`,
            `Un baño en tu estética de confianza (el reembolso depende del peso de tu mascota)`,
          ],
        },
        {
          header: ``,
          body: `¡Y más!
          Si aún no tienes un seguro para tu mejor amigo, ¿qué esperas? Regálale la mejor protección para celebrar el Día Internacional de Amar a tu Mascota.
          ENCUENTRA TU SEGURO PARA MASCOTAS AQUÍ: <>https://mascotasegura.inter.mx/seguro-mascota/landing</>
          
          ¿Quieres saber más? ¡Conoce tooodo sobre cualquiera de nuestros seguros en el <>blog</>! ¡Contrata fácil y rápido en <>inter.mx</>! 
          
          Síguenos en <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</> para que no te pierdas de naditita.`,
        },
      ],
    },
    {
      img: BG114,
      date: "Febrero 2023",
      autor: "adminInter",
      title: "Protege a quien más amas con Médico Seguro",
      body: "Si estás buscando un Seguro de Gastos Médicos Mayores, aquí te contamos algunos de los beneficios que tienes al contratar en inter.mx",
      content: [
        {
          header: ``,
          body: `Seguro, seguro cuando piensas en seguros te estresa imaginar la cantidad de papeles que debes leer y todo lo que las letras chiquitas te han de estar escondiendo, ¿no? ¡Te tenemos buenas noticias! Con <>inter.mx</> no tienes que pasar horas contratando tu seguro, porque le quitamos lo complicado y las letras chiquitas a los seguros. 
          
          ¿No nos crees? Sigue leyendo para que conozcas la experiencia de contratar tu seguro con inter.mx.
          <>¿Buscas un Seguro de Gastos Médicos? ¡Encuéntralo aquí!</>`,
        },
        {
          header: `¡Cuida tu salud y la de tu familia!`,
          body: `Contratar un seguro implica tomarnos el tiempo de pensar en el futuro para encontrar la mejor opción para cuidar nuestra salud y la de la familia. Lo bueno es que con <>inter.mx</> no necesitas pasar horas y horas revisando papeles, hablando con vendedores, ni tratando de leer letras chiquitas que quién sabe qué secretos esconden. 
          
          Con <>inter.mx</> puedes cotizar, personalizar tus coberturas, comparar entre las mejores aseguradoras y contratar al mejor precio de volada. Así, el tiempo que te ahorras protegiendo su salud, lo puedes invertir en estar con tu familia.
          ¡Checa algunos de los beneficios que tenemos para ti!`,
          list: [
            `Hospitalizaciones: tu aseguradora cubre el costo de la habitación y los servicios médicos que necesites.`,
            `Medicamentos: tu seguro cubre tus medicamentos (siempre y cuando sean para cubrir tu accidente o enfermedad)`,
            `Estudios: tu seguro cubre exámenes de laboratorio, resonancias magnéticas y todos los estudios necesarios para identificar tu padecimiento. `,
            `Honorarios médicos: el seguro se encarga de pagarle a todos los doctores que te atiendan en tu padecimiento.`,
          ],
        },
        {
          header: ``,
          body: `¡Y mucho más!
          <>¿Qué preguntas debes responder al contratar un Seguro Médico?</>`,
        },
        {
          header: `Comparador de seguros`,
          body: `¿Te ha pasado que tienes miles de pestañas abiertas en tu navegador y ya ni sabes dónde está la info que buscas? ¡Es horrible tener información por todos lados! Por eso, en <>inter.mx</> queremos hacer todo más fácil para ti. Elegimos a las mejores aseguradoras para tenerlas en un sólo lugar... ¡<>inter.mx</>!
          
          Con nuestro comparador de seguros, puedes personalizar tus coberturas y, si descargas nuestra app, puedes llevar todas tus pólizas contigo en el celular. 
          ¿Ves lo fácil y rápido que es asegurar lo que más quieres con inter.mx? ¡Contrata hoy tu seguro de gastos médicos mayores al mejor precio!
          ENTRA AQUÍ PARA ENCONTRAR TU SEGURO MÉDICO IDEAL: <>https://medicoseguro.inter.mx/inicio</>
          
          <>Cotizador de seguros - Todo lo que necesitas a un clic de distancia</>
          
          ¡Conoce más sobre cualquiera de nuestros seguros en el <>blog</>! ¡Contrata fácil y rápido en <>inter.mx</>! 
          
          Síguenos en <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</> para que no te pierdas de naditita.`,
        },
      ],
    },
    {
      img: BG107,
      date: "Enero 2022",
      autor: "adminInter",
      title: "¿Qué preguntas debes responder al contratar un Seguro Médico?",
      body: "Esto es todo lo que debes saber a la hora de contratar tu Seguro Médico. ¡Entra aquí!",
      content: [
        {
          header: ``,
          body: `Sabemos que cuando te decimos “Seguro Médico” lo primero que te imaginas es el montonal de papeleo que “seguramente” hay que llenar, ¿no? O tal vez te vienen a la mente las miles y miles de preguntas que “obviamente” debes responder para contratar tu seguro. 
          ¡Pues en inter.mx nada de eso! Queremos que te quites estas ideas de la cabeza. ¡Adiós papeleos y horas respondiendo cientos de cuestionarios! En <>inter.mx</> todo es súper fácil y rápido. ¿Quieres saber cómo? ¡Sigue leyendo! Aquí te contaremos qué te vamos a pedir a la hora de contratar tu Seguro Médico para que no te preocupes de nada.


          <>¿Un Seguro Médico cubre cirugías inmediatas?</>`,
        },
        {
          header: `¡Quiero un Seguro Médico! ¿Qué me van a preguntar?`,
          body: `¡No temas! En inter.mx no queremos que te preocupes de nada a la hora de buscar tu Seguro Médico ideal, por eso, sólo te preguntaremos lo absolutamente indispensable. ¡Toma nota!
          Antes que nada, queremos conocerte: 
          -	¿Cómo te llamas? 
          -	¿Cuándo naciste? 
          -	¿Cuál es tu sexo de nacimiento? (Sabemos que ésta puede ser una pregunta complicada, pero sólo nos interesa para poder protegerte mejor.) 
          -	¿Cuál es tu código postal?
          Luego vienen las preguntas sobre tu estilo de vida y salud:
          -	¿Eres deportista profesional?
          -	¿Cigarro, alcohol, drogas?
          -	¿Cuánto mides y pesas? (Otra pregunta que sabemos que puede ser complicada… ¡Pero de veras es sólo para protegerte de la mejor manera!)
          -	Y lo más importante: tus preexistencias, es decir, todo lo que te ha pasado relacionado a tu salud hasta el momento (psst… psst… Las gripitas y los rasponcitos no cuentan, no te preocupes)
          ¡Y listo! ¿Ves? ¡De volada! Además, casi todo es opción múltiple, para que puedas contratar aún más fácil y rápido.
          <>¿Las coberturas cambian dependiendo de la aseguradora?</>`,
        },
        {
          header: `Con Médico Seguro no hay pretextos para no estar seguro, seguro`,
          body: `Como en inter.mx nos encanta quitarle lo complicado a los seguros, ¡te presentamos Médico Seguro, nuestro súper cotizador y comparador! ¿Ya lo conoces?
          Con <>Médico Seguro</> puedes cotizar y comparar tu protección entre las mejores aseguradoras, personalizar las coberturas como tú quieras y contratar tu seguro súper fácil y rápido. Así, podemos estar segurísimos de que encontrarás tu Seguro Médico ideal, al mejor precio, hecho justo a tu medida y sin complicaciones. 
          
          ¿No nos crees? ¡Inténtalo! Entra a <>inter.mx</> hoy mismo, cotiza, compara, personaliza, contrata y enamórate de Médico Seguro tanto como nosotros.



          <>¿Cómo bajar la prima de mi Seguro Médico?</>


          ¿Te latió este artículo? Checa todo lo que tenemos para ti en el <>blog</> y responde todas tus dudas sobre todos nuestros seguros. No olvides seguirnos en <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</> para estar siempre bien cerquita de nosotros.`,
        },
      ],
    },
    {
      img: BG108,
      date: "Enero 2022",
      autor: "adminInter",
      title: "Coche Seguro: híbrido, eléctrico o de gasolina",
      body: "¿Estás pensando en comprar un coche? Aquí te contamos lo que debes saber sobre autos híbridos, eléctricos y de gasolina.",
      content: [
        {
          header: ``,
          body: `Si eres de las personas que les gusta empezar el año con un coche nuevo o estás buscando comprar tu primer auto, aquí te contamos lo que debes saber sobre eléctricos, híbridos y de gasolina para que tomes la mejor decisión. Recuerda que con Coche Seguro en <>inter.mx</> puedes asegurar tu coche en menos de cinco minutos,  súper fácil y rápido. 

          Sigue leyendo y no te pierdas de nadita.
          <>Cuatro cosas que no sabías de seguros</>`,
        },
        {
          header: `Eléctrico, híbrido o gasolina, ¿cuál es el coche más seguro para el medio ambiente?`,
          body: `Primero lo primero, ¿cuál es la diferencia entre un coche híbrido y uno eléctrico? ¡Muy fácil! El híbrido tiene dos motores (uno de combustión y uno eléctrico) y una batería, mientras que el auto eléctrico tiene baterías recargables y debe enchufarse en puntos de carga especiales. Cualquiera de las dos opciones es más amigable y seguro para el medio ambiente que un coche de gasolina (y en algunas aseguradoras como GNP y Quálitas tienen beneficios exclusivos).
          Si estás buscando algo que de veras casi no contamine, tal vez te late más un coche eléctrico. ¡Éstos crean cero emisiones! ¡Así es!, no emiten gases contaminantes porque no requieren de combustible. Sólo debes dejarlo cargar durante la noche y estará listo para usarse al día siguiente. 
          En el caso de los coches híbridos la mayor ventaja es que consumen menos, gracias a sus dos motores. El ahorro en el consumo de gasolina es un gran alivio económico, sin mencionar que no crean contaminación sonora. ¡Increíble!, ¿no? 
          Pero si por el momento prefieres un coche que use gasolina, la ventaja que tiene es su potencia superior. Si eres fan de la velocidad, tal vez uno eléctrico no llegue a tus expectativas. En especial si eres de los que viaja mucho en carretera. 
          <>Asistencias de tu Seguro de Coche que no sabías que existían</>`,
        },
        {
          header: `Asegura tu coche fácil y rápido`,
          body: `Encontrar tu coche ideal depende de lo que estás buscando y lo que mejor funcione para tu día a día. Por eso en <>inter.mx</> queremos que tengas la mejor protección, hecha a tu medida. Cotiza y compara entre las mejores aseguradoras para encontrar el mejor precio y estar seguro, seguro.

          Con Coche Seguro puedes tener cobertura de daños materiales, robo total, asistencia en viajes, responsabilidad civil y muuucho más. Y si tu auto es híbrido o eléctrico, con GNP y Quálitas puedes encontrar algunos beneficios exclusivos, ¡ponte en contacto con un asesor!
          No dejes para mañana lo que puedes hacer hoy y asegura tu coche eléctrico, híbrido o de gasolina en menos de cinco minutos en <>inter.mx</>. 

          <>¿Puedo asegurar un coche sin placas?</>

          ¿Te latió esta info? ¡Conoce más sobre todos nuestros seguros en el <>blog</>! 
          Síguenos en <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</> para que no te pierdas ninguna promo.`,
        },
      ],
    },
    {
      img: BG109,
      date: "Enero 2022",
      autor: "adminInter",
      title: "Propósito de Año Nuevo: estar seguro, seguro",
      body: "Mantente motivado y logra tus propósitos de año nuevo con inter.mx",
      content: [
        {
          header: ``,
          body: `¡Llegó el momento de decirle “hola” al 2023! ¿Te comiste las doce uvas llan@ de deseos y escribiste tu lista de propósitos de Año Nuevo? En <>inter.mx</>  queremos ayudarte a cumplirlos tooodos. 
          Sigue leyendo para que no te pierdas de los consejos que tenemos para que tu 2023 sea un gran año.
          <>Cotizador de seguros - Todo lo que necesitas a un clic de distancia</>`,
        },
        {
          header: `Consejos para lograr tus propósitos de Año Nuevo sin perder la motivación`,
          body: `Algunas de las grandes ventajas de tener propósitos de Año Nuevo son: motivarnos a hacer un cambio, volver a comenzar y buscar formas de mejorar personal o profesionalmente. 
          Lo más importante para cumplir todos tus propósitos es nunca perder la motivación. Sabemos lo rápido que se complica la vida y lo fácil que es dejar de cumplir los objetivos que te marcaste con tanto anhelo al inicio del año.  Por eso, aquí te dejamos nuestros mejores consejos para lograrlos:
          ●	Asegúrate de que tus metas sean realistas. Piensa en pequeñas victorias que puedas lograr a lo largo del año.
          ●	Sé flexible. Sabemos que tooodo puede suceder dentro de un año. Si algo cambia, si tus metas ya no son la prioridad que eran, cámbialas por otras. No necesitas esperar al siguiente año para crear nuevos propósitos.
          ●	Escribe tus metas donde las puedas ver. Si eres fan de los mood boards, ¡éste es el mejor momento para hacer uno! Pon frases motivadoras, recorta fotos de lugares, agrega todo lo que te dé inspiración.
          ●	¡Protégete! Los seguros sirven para quitarte de preocupaciones en tu día a día y ayudarte a cumplir todos tus propósitos. ¡Invertir en un seguro es pensar en tu futuro y tu bienestar, a la vez que proteges tus finanzas! 
          ¡Así de fácil! No tienes por qué estresarte para cumplir tus propósitos. Con estos consejos seguro, seguro lograrás todas tus metas.
          <>Recuperación Segura, el Seguro Médico más accesible.</>`,
        },
        {
          header: `Invertir en ti y lo que amas es el mejor propósito`,
          body: `Pensar en propósitos de Año Nuevo es pensar en el futuro. Por eso, es el momento perfecto para encontrar el seguro ideal para tu estilo de vida, que te ayude a cumplir todo lo que te propusiste sin preocupaciones.
          En <>inter.mx</> puedes cotizar y comparar entre las mejores aseguradoras. Encuentra seguros para tu salud y la de tu familia,  el coche o la moto, así como para tu mejor amigo peludito. 
          ¡Comienza el 2023 cuidando lo que más amas fácil, rápido y seguro!
          <>¿Buscas un Seguro de Gastos Médicos? ¡Encuéntralo aquí!</>
          ¿Te gustaron nuestros consejos? ¡Conoce más sobre cualquiera de nuestros seguros en el <>blog</>! ¡Contrata fácil y rápido en <>inter.mx</>! 
          Síguenos en <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</> para que no te pierdas de naditita.`,
        },
      ],
    },
    {
      img: BG110,
      date: "Enero 2022",
      autor: "adminInter",
      title:
        "Vacunas para Mascotas: ¿Cuáles le tocan a tu peludito en cada momento de su vida?",
      body: "La salud de nuestros peluditos es muy importante. ¿Sabes si el tuyo ya tiene todas sus vacunas para mascotas?",
      content: [
        {
          header: ``,
          body: `Los peluditos también son una parte muy importante de nuestra familia.. ¿Sabes qué vacunas para mascotas le tocan a tu perrhijo o gathijo en cada momento de su vida? ¿Tu peludito ya tiene su Seguro de Mascotas para siempre estar al cien? ¡No te preocupes, aquí te lo contamos todo! 
          <>Tres pasos para enseñarle a tu perrhijo buenos modales</>`,
        },
        {
          header: `El mejor Seguro para Mascotas comienza con sus vacunas completitas`,
          body: `Cuando de salud y bienestar de nuestros cuatropatitas se trata, un seguro no es lo único que necesitamos para protegerlos –¡aunque sin duda es indispensable!–. Lo primero son las vacunas, sí o sí. (Psst… psst… ¿Sabías que una de las asistencias de Mascota Segura te apoya con algunas vacunas?)
          Las vacunas para mascotas puestas a tiempo pueden hacer la diferencia frente a contagios por virus y bacterias. Por eso, te dejamos el calendario completito según cada edad de tu perrhijo y gathijo.
          Para cuatropatitas caninos:
          -	45 días: moquillo y parvovirus
          -	60-65 días: vacuna pentavalente (moquillo, hepatitis infecciosa, parvovirus, leptospira y parainfluenza)
          -	75 días: vacuna pentavalente + coronavirus canino, y luego un refuerzo a los 90 días
          -	110-120 días: rabia
          -	Tos de las perreras: a partir de la segunda semana y 72 horas antes de tener contacto con otros amigos perrunos
          Para cuatropatitas felinos:
          -	60 días: vacuna trivalente (panleucopenia, calcivirus y rinotraqueitis)
          -	75 días: leucemia felina
          -	90 días: refuerzo de la trivalente
          -	105 días: refuerzo de leucemia felina
          -	120 días: rabia 
          ¡Ojo! Varias vacunas, tanto de perritos como de gatitos, deben reforzarse cada año, ¡no olvides preguntarle a tu veterinario de confianza!
          <>¿Qué imprevistos cubren los Seguros para Mascotas?</>`,
        },
        {
          header: `Y para una súper protección… ¡Mascota Segura!`,
          body: `En inter.mx queremos que todos los peluditos estén súper protegidos. Por eso, tenemos el mejor Seguro para Mascotas. ¿Ya lo conoces?
          Con Mascota Segura protegemos a tus peluditos en caso de que se pongan malitos, tengan un accidente –¡tocamos madera!–, hagan una travesura o estiren la patita (ojalá nuestros pequeñines fueran eternos, ¿no?).
          Además, todos los planes incluyen un montón de beneficios adicionales para que tus cuatropatitas siempre estén súper al cien –incluidas vacunas y desparasitaciones–.
          Entra a <>inter.mx</> y checa todos los planes y las coberturas que <>Mascota Segura</> tiene para tus perrhijos y gathijos.

          ¡No esperes más y protégelos hoy mismo!
          <>¿Hay límite de edad en un Seguro para Mascotas?</>
          
          Psst… psst… ¿Te latió esta info? Checa todos los artículos que tenemos para ti en nuestro <>blog</> y responde todas tus dudas sobre seguros. No olvides seguirnos en <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</> para que no te pierdas de nada.`,
        },
      ],
    },
    {
      img: BG103,
      date: "Diciembre 2022",
      autor: "adminInter",
      title:
        "¿Cuándo me conviene más comprar mi Seguro Médico, a fin o inicio de año?",
      body: "Si estás dándole muchas vueltas a comprar tu Seguro Médico este año o el que viene, este artículo es para ti.",
      content: [
        {
          header: ``,
          body: `Comprar un Seguro Médico no es cualquier cosa, lo sabemos. Y con la economía como está… segurísimo te estás preguntando si vale la pena invertir desde ahorita o mejor esperar a que arranque el próximo año. 
          ¡Sigue leyendo! Estamos seguros de que este artículo te ayudará a tomar la mejor decisión.
          <>Viene la temporada de frío, ¡cuídate con un Seguro Médico y estos tips!</>`,
        },
        {
          header: `Seguro Médico: ¿gasto o inversión?`,
          body: `Primero lo primero, ¿tener un Seguro Médico de veras es taaan importante? La respuesta es ¡sí! ¡Siempre! Un seguro de este tipo te puede sacar de grandes apuros en una situación súper inesperada para la que probablemente tu cartera no estaba lista  –¡obvio tocamos madera!–. 
          Piensa tu Seguro Médico como una inversión que te puede salvar la vida sin que tu economía sufra ni tantito. Dramático, pero suena bien, ¿no?
          Tener un seguro es como tener un salvavidas personal. Por eso, ¡en <>inter.mx</> amamos los seguros!


          <>¿Cómo bajar la prima de tu Seguro Médico?</>`,
        },
        {
          header: `¿Cuándo es el mejor momento del año para comprar mi seguro?`,
          body: `La verdad es que cuando se trata de proteger nuestra salud, cualquier momento es el mejor para hacerlo. Pero si la pregunta es si te conviene más contratar tu Seguro Médico a fin de año o a inicios del siguiente, nuestra recomendación es que lo hagas lo antes posible. ¡Y tenemos tres súper razones para convencerte!
          Si compras tu seguro en diciembre…
          1. Terminarás el año de la mejor manera y seguro, seguro estarás list@ para todo lo que venga.
          2. ¡Viene el pago de aguinaldo! Y, ¿qué mejor manera de invertirlo que protegiendo tu salud y la de tu familia?
          3. Evitarás la cuesta de enero y el ajuste de precios por la inflación… Suena bien, ¿no?
          ¡No lo pienses más! Tu salud y la de tu familia definitivamente son lo más importante.
          <>¿Por qué es importante hacerte un check-up médico?</>`,
        },
        {
          header: `Un Seguro Médico a tu medida`,
          body: `No esperes más, ¡en <>inter.mx</> segurísimo tenemos el mejor seguro para ti! 
          Si lo que buscas en un seguro de gastos médicos mayores, entra a <>www.medicoseguro.inter.mx</>. Ahí puedes cotizar de volada, comparar entre las mejores opciones, personalizar tus coberturas y contratar de manera fácil, rápida y segura. 
          ¿Prefieres un Seguro Médico más sencillo o un complemento para tu protección?, escríbenos un mensajito de WhatsApp al 5543269037 y pregunta a nuestros asesores por Recuperación Segura y Prevención Segura. 
          Sin importar lo que elijas, ¡nada es mejor que estar seguro, seguro con <>inter.mx</>! 

          Psst… psst… ¿Te latió esta info? Checa todos los artículos que tenemos para ti en nuestro <>blog</> y responde todas tus dudas sobre seguro. No olvides seguirnos en  <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</> para que no te pierdas de nada.`,
        },
      ],
    },
    {
      img: BG104,
      date: "Diciembre 2022",
      autor: "adminInter",
      title: "¿Qué debo saber a la hora de contratar mi Seguro de Coche?",
      body: "Tener un Seguro de Coche es muy fácil y rápido. ¡En este artículo te contamos qué debes de saber para contratar!",
      content: [
        {
          header: ``,
          body: `Contar con un Seguro de Coche es importantísimo siempre. Pero, ¿sabías que sólo 3 de cada 10 personas tienen su coche asegurado? Si tú eres de los que no han protegido su auto todavía, ¡no te preocupes! Aquí te daremos unos tips para contratar tu Seguro de Coche de manera fácil y rápida (psst… psst… y al mejor precio).  ¡Sigue leyendo!
          <>Asistencias de tu Seguro de Coche que no sabías que existían</>`,
        },
        {
          header: `¿De veras necesito un Seguro de Coche?`,
          body: `¡Sí, de veritas! Si vives en México, es obligatorio, de hecho. Pero más allá de las reglas, tener un Seguro de Coche te puede salvar en cualquier momento. Por ejemplo, ¿qué tal si se te acaba la gasolina una noche lejos de casa? O, ¿si tu coche deja de funcionar sin razón aparente en plena vía rápida? Obvio tocamos madera, pero esas cosas suceden y qué mejor que poder echarle un telefonazo a tu seguro, ¿no?
          ¿Ves? Un seguro es indispensable. Y con inter.mx, contratarlo es súper fácil y rápido.
          <>¿Qué pasa con mi Seguro de Coche si vendo mi auto?</>`,
        },
        {
          header: `Y… ¿de veras es tan fácil y rápido contratar mi seguro?`,
          body: `¡Por supuesto! En inter.mx es taaan fácil y rápido que puedes hacerlo desde tu celular, mientras esperas en un alto. 
          ¡No más papeleos, ni palabras complicadas! ¡No más horas y horas de espera! ¡No más vendedores aburridos de seguros! En inter.mx, cotizas, comparas, personalizadas y contratas tu seguro en un mismo lugar en menos de cinco minutos.
          Y como nos encanta quitarle lo complicado a los seguros, te compartimos algunos tips que te pueden ayudar a la hora de contratar tu Seguro de Coche:
          1)	Conoce el coche y al conductor
          Con tantas opciones para proteger tu auto, ¿cómo elegir? Haz una lista de las necesidades que tu coche y tú puedan tener. Por ejemplo, si sueles salir a carretera con frecuencia, ¡busca un seguro que cuente con Asistencia en Viajes! Psst… psst… En <>inter.mx</> puedes agregar las coberturas que tú quieras.
          2)	Ten la info de tu coche súper a la mano
          Sólo necesitamos el modelo de tu coche y el número de serie (VIN) que aparece en tu tarjeta de circulación, ¡y listo! En <>inter.mx</> te pediremos sólo la info súper indispensable para hacer rapidísimo tu proceso. 
          3)	Cotiza y compara antes de contratar
          No te vayas con el primer seguro que encuentres. Si lo que buscas es el mejor seguro al mejor precio, ¡comparar es la clave! ¿Ya conoces el súper comparador de <>inter.mx</>? ¡Haz click <>aquí,</> para cotizar y encontrar tu mejor Seguro de Coche!
          
          Ahora sí, ¡no hay pretexto para no estar seguro, seguro! Entra hoy mismo a <>www.cocheseguro.inter.mx</> y comprueba lo fácil y rápido que es proteger tu auto.
          
          <>¿Puedo asegurar un coche sin placas?</>

          ¿Te latió esta info? ¡Conoce más sobre todos nuestros seguros en el blog! 
          Síguenos en <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</> para que no te pierdas ninguna promo.`,
        },
      ],
    },
    {
      img: BG105,
      date: "Diciembre 2022",
      autor: "adminInter",
      title: "La vida es complicada, tener un seguro no lo es.",
      body: "Sabemos que la vida puede ser complicada, pero aquí te decimos el secreto para que no lo sea.",
      content: [
        {
          header: ``,
          body: `Sabemos que la vida puede ser complicada, entre responsabilidades, compromisos y uno que otro inconveniente inesperado que hace que las cosas se salgan de nuestras manos. Para todos esos momentos lo mejor es tener un seguro que te proteja siempre. 
          ¡Sigue leyendo y checa cómo es que con un seguro la vida es menos complicada!
          <>¿Buscas un Seguro de Gastos Médicos? ¡Encuéntralo aquí!</>`,
        },
        {
          header: `Seguro, seguro te ha pasado…`,
          body: `Cualquiera puede tener un mal día… Una llanta ponchada en plena vía rápida, un asuntito médico que sale carísimo, o un perrito que no puede evitar hacer travesuras a los vecinos… Ni modo, así pasa. 
          Pero por eso, ¡seguro, seguro te tenemos buenas noticias! Sí existe una forma muy fácil de tener paz mental, dejar de preocuparte de todos estos imprevistos y cuidar tu economía si algo sucede… ¡un seguro con inter.mx!
          Sigue leyendo y entérate de todo.
          <>Recuperación Segura, el Seguro Médico más accesible.</>`,
        },
        {
          header: `Protegerte es fácil y rápido`,
          body: `La vida está llena de tropiezos y de momentos que llueven sobre mojado. Por eso, tenemos los mejores seguros para protegerte de casi cualquier cosa. 
          Pero si todavía tienes dudas… ¡Razones por las que NECESITAS tener un seguro con <>inter.mx</>!
          1. Si necesitas algún estudio, tu Seguro Médico cubre los exámenes de laboratorio, resonancias magnéticas y todos los estudios que sean necesarios para identificar tu padecimiento.
          2. Si te realizan algún procedimiento, tu Seguro Médico te cubre los costos de cirujanos, anestesistas, enfermeros ayudantes, entre otros.
          3. Esperemos que nunca te pase, pero si te roban el auto, tu Seguro de Coche te cubre el valor comercial o el de la factura según los añitos que tenga.
          4. Para los peluditos traviesos que de vez en cuando se portan mal, tu Seguro de Mascota te cubre si tu perrhijo o gathijo lastima a alguien o destruye algo que no es tuyo.
          Y esos sólo son algunos de los beneficios de tener un seguro que te protege en tu día a día. ¿Ves? ¡Con <>inter.mx</>, la vida sí es más feliz!

          Además de todo… ¡Le quitamos lo complicado a los seguros! Cotiza, compara y contrata desde un mismo lugar súper fácil y rápido. Aparte, con nuestra app, ¡ten tus pólizas a donde vayas! Pase lo que pase, todos tus seguros estarán en un mismo lugar a un tap tap de distancia.
          <>Cotizador de seguros - Todo lo que necesitas a un clic de distancia</>
          
          ¿Te gustó este artículo? ¡Conoce más sobre cualquiera de nuestros seguros en el <>blog</>! ¡Contrata fácil y rápido en <>inter.mx</>! 
          
          Síguenos en <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</> para que no te pierdas de naditita.`,
        },
      ],
    },
    {
      img: BG106,
      date: "Diciembre 2022",
      autor: "adminInter",
      title: "Tres pasos para enseñarle a tu perrhijo buenos modales.",
      body: "Enséñale a tu perrhijo buenos modales con estos tips que tenemos para ti.",
      content: [
        {
          header: ``,
          body: `¿Tienes un cachorro? ¿Te estás volviendo loc@ tratando de educarlo? ¡Llegaste al lugar indicado! Sabemos que tener un perrhijo es complicado, por eso queremos ayudarte con los mejores tips para enseñarle buenos modales y que no te saque canas verdes. Sigue leyendo para que no te pierdas de nadita. 
          <>Seguro para Mascotas: ¿solamente incluye perros y gatos?</>`,
        },
        {
          header: `Enséñale a tu perrhijo buenos modales con 3 simples pasos`,
          body: `Tener un cachorrito en casa significa un caos si te descuidas. Es una etapa donde tooodo les llama la atención. ¡No te preocupes y respira! Enseñarle buenos modales a tu perrhijo es sencillo, sólo requiere de paciencia.
          Para los perritos, aprender a seguir indicaciones es como resolver un rompecabezas, les ayuda a enfocarse en una meta a vencer. También ayuda a crear un lazo más fuerte entre tu perrhijo y tú. 
          Estos 3 simples pasos te ayudarán:
          1. Indicación: muéstrale lo que quieres que haga. Pon en tu mano un premio y deja que lo huela.
          2. Acción: realiza la indicación con tu mano. Asegúrate que te siga la mano con su nariz hasta entender la acción que debe hacer. 
          3. Premio: en cuanto realice la acción, dale su premio y felicítalo mucho. 
          Repite estos pasos hasta que fluya de manera natural, y ¡no olvides darle premios para que se sienta motivado!
          <>¿Hay límite de edad en un Seguro para Mascotas?</>`,
        },
        {
          header: `La ventaja de asegurar a tu mascota`,
          body: `Como te lo contamos arriba, educar a tu mascota requiere de mucha paciencia. Sabemos que mientras lo educas puede pasar de todo, como lastimarse, comer algo que no debía o herir a alguien más ⎯¡tocamos madera!⎯. 
          ¿Sabías que en estas situaciones, Mascota Segura te protege? 
          ¡Así es! Este seguro incluye Atención Médica, Responsabilidad Civil y mucho más.  Así, Mascota Segura te echa una patita si tu perrhijo…
          1. Si se pone malito o se accidenta
          a. Si se come algo raro
          b. Si se cae
          c. Si lo atropellan
          d. Si le da un golpe de calor
          e. Si se siente mal y no sabes qué onda
          2. Le hace daño a alguien
          a. Si hace lesiones corporales
          b. Si ocasiona una enfermedad
          c. Si causa la muerte
          d. Si destruye bienes
          Además, el seguro también te apoya en caso de que estire la patita o si necesita fallecimiento asistido –¡tocamos madera, otra vez!–.
          A poco no está increíble despreocuparse por estas situaciones? 
          ¡No lo pienses dos veces! Entra hoy mismo a <>inter.mx</> para proteger a tu amigo peludo.

          <>¿Qué imprevistos cubren los Seguros para Mascotas?</>

          ¿Te latieron nuestros tips? ¡Conoce más sobre cualquiera de nuestros seguros en el <>blog</>! No olvides que, además de Mascota Segura, tenemos los mejores Seguros de Coche y Seguros Médicos. ¡Contrata fácil y rápido en <>inter.mx</>! 

          Síguenos en <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</> para que no te pierdas de naditita.`,
        },
      ],
    },
    {
      img: BG99,
      date: "Noviembre 2022",
      autor: "adminInter",
      title: "Alimento para mascotas: ¿es buena idea darles comida casera?",
      body: "¿El mejor alimento para mascotas? Te contamos qué sí y qué no puedes darle de comer a tu mejor amigo peludo.",
      content: [
        {
          header: ``,
          body: `¿Le has dado comida para humanos a tu perrhijo o gathijo? El mejor alimento para mascotas siempre es un tema de debate. Seguramente te has preguntado si a tu amigo peludo le aburre su comida y has considerado compartirle de la tuya, ¿verdad? Aquí te platicamos si es o no una buena idea darle de tu plato a tu perro o gato, y lo que debes considerar para alimentarlo de la mejor manera posible.  
          ¡Sigue leyendo!
          <>¿Qué imprevistos cubren los Seguros para Mascotas?</>`,
        },
        {
          header: `¿Cuál es el mejor alimento para mascotas?`,
          body: `Si tienes un perro o un gato en tu familia, seguramente te has hecho estas preguntas: ¿Lo estoy alimentando bien? ¿Le aburrirá comer todos los días lo mismito? ¿Será mejor que le dé comida casera? No te preocupes, aquí te resolveremos todas tus dudas sobre alimento para mascotas.
          Nuestros compañeros peludos tienen necesidades nutrimentales muy diferentes a las nuestras. Incluso entre ellos, perros y gatos no pueden comer lo mismo, porque su metabolismo varía muchísimo. ¡Y no sólo eso! Alimentar bien a nuestros peluditos también depende de su raza, edad, actividad diaria y otras necesidades específicas. 
          Uff, qué rollo, ¿no? Por eso, lo mejor que podemos hacer para alimentar correctamente a nuestro perrhijo o gathijo es darle comida hecha para mascotas –sean croquetas o comida húmeda–, según su especie, raza, edad y las necesidades que tenga nuestro peludito. 
          Lo más recomendable siempre es visitar al veterinario y que él o ella te recomiende el mejor alimento para tu cuatropatitas en cada momento de su vida.
          <>¿Hay límite de edad en un seguro para mascotas?</>`,
        },
        {
          header: `¿Pero puedo compartir mi comida con mi mascota?`,
          body: `¡No! ¡Nunca! Muchos ingredientes que a nosotros nos encantan pueden ser altamente tóxicos o dañinos para nuestros peluditos, y dárselos por error podría incluso costarles la vida.
          La lista de las cosas que nuestras mascotas no deben comer es larguísima y sí varía entre perros y gatos, pero aquí te dejamos algunos de los más peligrosos para ambos:`,
          list: [
            `ajo`,
            `cebolla`,
            `chocolate`,
            `aguacate`,
            `limón`,
            `café`,
            `té`,
            `chile`,
            `lácteos`,
            `uvas y pasas`,
            `hongos`,
            `almendras`,
            `nueces`,
            `pescado crudo`,
            `huevo crudo`,
            `sal`,
            `azúcar`,
          ],
        },
        {
          header: ``,
          body: `¡Ojo! Si tu peludito llega a ingerir alguno de estos alimentos, échale un telefonazo de inmediato a tu veterinario para que te diga qué hacer. 
          Psst… psst… Si en algún momento, en un caso muuuy extremo, no te queda de otra porque se te acabó el alimento para mascotas y la tienda ya cerró, puedes darle lo siguiente a tu peludito:`,
          list: [`pollo o pavo cocido`, `arroz cocido`, `verduras cocidas`],
        },
        {
          header: ``,
          body: `¡¡Obvio sin sal, especias, ni nada extra!
          <>Seguro para mascotas: ¿solamente incluye perros y gatos?</>`,
        },
        {
          header: `¡Últimos tips para una mascota bien sana!`,
          body: ``,
          list: [
            `Para que tu cuatropatitas no se aburra ni atiborre con el alimento, no se lo dejes tooodo el día. Mejor designa una hora por la mañana y otra por la noche para alimentarlo, así su estómago puede descansar y comerá con mucho más gusto.`,
            `Siempre dale la cantidad que recomienda el fabricante de cada alimento. En casi todos los empaques puedes encontrar una tablita de cuánto debe comer tu mascota según su peso. Si no, ¡pregúntale a tu veterinario de confianza!`,
            `Si puedes, compra alimentos premium o súper premium –tu veterinario te puede recomendar el mejor para tu peludito–. Estas comidas tienen muchas ventajas, por ejemplo: mantener a nuestros cuatropatitas mucho más saludables con una nutrición completa y consistente, cuidar mejor su pelaje y hacer que sus heces sean mucho más firmes y menos olorosas.`,
            `Es importantísimo que tu mascota esté siempre súper bien hidratada. Por eso, es vital que siempre tenga su plato de agua lleno, tooodo el día.`,
            `Visita al veterinario con frecuencia para asegurarte de que tu perrhijo o gathijo se encuentra bien. Y obvio, siempre que notes algo extraño, ¡no dudes en llevártelo de volada al consultorio! Te recomendamos siempre tener bien ubicado el hospital para mascotas más cercano.`,
            `Por último… ¡Protege a tu cuatropatitas con un Seguro para Mascotas para que siempre esté al cien! Entra a <>inter.mx</> y encuentra el mejor seguro para tu peludito al mejor precio. Contratar es súuuper fácil y rápido.`,
          ],
        },
        {
          header: ``,
          body: `¿Te latieron nuestros tips? ¡Conoce más sobre cualquiera de nuestros seguros en el <>blog</>! Síguenos en <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</> para que no te pierdas de naditita.`,
        },
      ],
    },
    {
      img: BG100,
      date: "Noviembre 2022",
      autor: "adminInter",
      title:
        "Viene la temporada de frío, ¡cuídate con un Seguro Médico y estos tips!",
      body: "En inter.mx queremos que estés seguro, seguro, por eso te damos unos consejitos para que te cuides esta temporada de frío.",
      content: [
        {
          header: ``,
          body: `Es súper común enfermarnos en temporada de frío, por eso queremos que estés seguro, seguro y cuides tu salud. (Psst… psst… Si aún no tienes un seguro médico, aprovecha lo que Médico Seguro tiene para ti <>aquí</>). 
          Ahora sí, ¡no te pierdas los tips que tenemos para que tu salud esté al cien!`,
        },
        {
          header: `¿Un Seguro Médico es suficiente para cuidar nuestra salud?`,
          body: `Nos encantaría decir que sí, pero en las temporadas de frío siempre es mejor tomar precauciones extra. Ooobvio contar con un Seguro Médico es siempre lo más recomendable y lo primeritito que debes considerar cuando de cuidar tu salud se trata. Pero como ya aprendimos con el covid, a veces no basta cuidarnos como estamos acostumbrad@s y tenemos que hacer un esfuerzo adicional. 
          <>¡Quiero contratar un Seguro Médico para mí y mi familia!</>`,
        },
        {
          header: `Tips para la temporada de frío`,
          body: `Si eres de esas personas que la pasa muy mal durante la temporada de frío y a cada ratito se enferma, seguro, seguro, estos tips son para ti:
          1. Lávate las manos. En esta temporada donde las personas tosen, estornudan y andan con la nariz irritada, lo mejor es lavarnos las manos para evitar los famosos contagios.
          2. Evita cambios bruscos de temperatura. Si eres de las personas que aman ir al gym de noche, asegúrate de salir bien tapadit@ para que el aire frío no te regale una gripita.
          3. Vitaminas, vitaminas, vitaminas. No olvides que es importante que tus defensas estén bien altas, por eso tomar vitaminas extra y tener una dieta llena de frutas y verduras es buenísimo para estas épocas.
          4. ¡Vacúnate! Las vacunas nunca son agradables, pero seguro, seguro te salvan de enfermedades comunes de estas épocas, como influenza y covid. Pregúntale a tu doctor de cabecera qué vacunas te tocan y dónde puedes conseguirlas.
          5. ¡No te automediques! Sabemos que a veces es más fácil tomar lo que encontremos en casa o lo que nos recomienden los cuates, pero siempre es mejor ir al doctor. Si sientes la garganta irritada, toma un tecito de jengibre con limón y miel, y si te sigue dando lata, ¡córrele al doctor!
          6. Siempre es mejor tener un Seguro Médico. Contar con un Seguro Médico definitivamente es una gran paz y lo mejor que podemos hacer para proteger nuestra salud ante cualquier imprevisto. ¡Prevenir siempre es mejor que lamentar! (Psst… psst<>… aquí</> puedes cotizar un seguro si aún no tienes uno).
          <>Recuperación segura, el seguro médico más accesible</>
          ¿Qué te parece? Ahora ya sabes lo fácil que es cuidar tu salud en esta temporada de frío. ¡Toma en cuenta nuestros tips y checa todo lo que <>Médico Seguro</> tiene para ti! 
          Entra a <>inter.mx</> y encuentra de volada el Seguro Médico ideal para ti y tu familia al mejor precio. Con inter.mx, ¡seguro, seguro!
          ¿Te latió esta info? ¡Conoce más sobre cualquiera de nuestros seguros en el <>blog</>! Síguenos en <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</> para que no te pierdas de nuestras promos.`,
        },
      ],
    },
    {
      img: BG101,
      date: "Noviembre 2022",
      autor: "adminInter",
      title: "Asistencias de tu Seguro de Coche que no sabías que existían",
      body: "Tu Seguro de Coche incluye muchas asistencias adicionales que te pueden ayudar en tu día a día. ¿Ya las conoces? ¡Descúbrelas en este artículo!",
      content: [
        {
          header: ``,
          body: `INTRO A: ¿Le diste un lleguecito a alguien en el estacionamiento? Llama a tu seguro. ¿Tu coche desapareció de la faz de la tierra? Llama a tu seguro. ¿Le diste con la defensa al poste de un semáforo? ¡Llama a tu seguro! También si se te quedaron las llaves dentro del coche, te quedaste sin batería, se te acabó la gasolina, o se te ponchó una llanta... ¡Llama a tu seguro! 
          Conoce las asistencias adicionales de tu Seguro de Coche que tal vez no sabías que existían y que te podrían sacar de un apuro en cualquier momento.`,
        },
        {
          header: `¿Qué son las asistencias en tu Seguro de Coche?`,
          body: `¡La pregunta principal! Pues bien, las asistencias en tu Seguro de Coche son apoyos que tu seguro te ofrece para resolver problemas cotidianos que puedes llegar a tener con tu auto. 
          Aunque algunas de éstas tienen un costo extra en tu Póliza de Seguro, hay un gran número de ellas que tienes como beneficio inmediatamente a la hora de contratar con <>inter.mx</>, sin cargos adicionales.
          Puede parecer increíble, pero muchas personas no saben que pueden hacer uso de estas asistencias adicionales a su Seguro de Coche cuando sea que lo requieran.
          ¡Que no sea tu caso!
          <>Conoce, además, cuatro cosas que seguro no sabías sobre seguros</>.`,
        },
        {
          header: `Casos cotidianos en los que puedes usar las asistencias`,
          body: `Las asistencias de tu Seguro de Coche las puedes encontrar en tu póliza, fácil y rápido. (Psst… psst… ¿ya bajaste la app de inter.mx?). 
          Te dejamos la lista completita de las asistencias con las que puedes contar:`,
          list: [
            `Asistencia en viajes`,
            `Cerrajería para tu vehículo`,
            `Grúa`,
            `Gasolina`,
            `Asistencia legal`,
            `Paso de corriente`,
            `Traslado médico`,
            `Cambio o inflado de llantas`,
          ],
        },
        {
          header: ``,
          body: `¡Increíble!, ¿no crees? 
          Entonces, ¡sí! Si caes en un bache y se te poncha la llanta, dejas las llaves dentro del auto, te quedas sin batería o se te acaba la gasolina a medio camino (¡esperemos que no te pase ninguna!), tu seguro puede ir al rescate. 
          RECUERDA: tus asistencias son beneficios adicionales a tus coberturas.
          En resumen: contratar un Seguro de Coche es tu mejor opción para manejar súper bien protegid@ de aquí a Baja California y de Baja California a Quintana Roo, si así lo deseas. Contar con la protección de un seguro definitivamente es lo mejor para tu bienestar y el de tu bolsillo. 
          Si todavía no tienes tu seguro, ¡no esperes más y contrata <>aquí!</>`,
        },
        {
          header: `¡No te pierdas nada del mundo de los seguros!`,
          body: `¡Checa tooodos nuestros artículos en nuestro <>blog</> para que seas el más enterado del mundo en los seguros! Síguenos también en nuestras redes para no perderte ninguna de nuestras promociones.`,
        },
      ],
    },
    {
      img: BG102,
      date: "Noviembre 2022",
      autor: "adminInter",
      title: "¿Por qué es importante hacerte un check-up médico?",
      body: "Tu salud merece el mejor cuidado. Conoce los beneficios de un check-up médico y nuestras opciones de Seguro Médico que lo incluyen.",
      content: [
        {
          header: ``,
          body: `¡El cuidado de tu salud comienza con la prevención! Ya que un gran número de enfermedades puede prevenirse o tratarse mejor si se detectan a tiempo, hacerse check-ups médicos anuales es importantísimo para que siempre estés al cien. Conoce cómo tu Seguro Médico puede echarte la mano con estos procedimientos y cuáles son los beneficios para tu salud.`,
        },
        {
          header: `Padecimientos que puedes prevenir con un check-up médico`,
          body: `¿Te has hecho un check-up médico recientemente? ¡Es importantiiísimo! Si no nos crees, checa esta lista con algunos de los padecimientos que son súper tratables si se detectan a tiempo:
          -	Varios tipos de cáncer
          -	Diabetes
          -	Síndrome metabólico (que incluye presión arterial elevada, azúcar en la sangre elevada, exceso de grasa corporal y niveles anormales de colesterol)
          -	Gota
          -	Osteoporosis
          -	Dislipidemia
          -	Hígado graso
          -	¡Y muchos más!
          Mejorar tu calidad de vida, evitar mayores secuelas por padecimientos, disminuir costos por tratamientos futuros e, inclusive, estudiar mejor los desarrollos de tales enfermedades son grandísimos beneficios que obtienes al hacerte check-ups anuales. Está buenísimo, ¿no?
          ¿Tu seguro cubre padecimientos por nuevos virus? ¡Lee más <>acá</>!`,
        },
        {
          header: `Costos de un check-up médico`,
          body: `Los costos de un check-up dependen de varios factores. Un examen preventivo de salud no sólo requerirá de la consulta con tu médico de cabecera, sino también lo siguiente:
          -	Estudios de laboratorio (química integral, biometría hemática y estudios de orina, entre otros)
          -	Estudios de imagenología (¿de imagenoloqueeé…? Los estudios de imagenología son todos aquellos que producen imágenes internas de nuestro cuerpo, como los rayos x).
          Entonces, sumando todito, ¿en cuánto baila un check-upcito así? 
          Respuesta: alrededor de $8,000 MXN. 
          Es un buen, ¿no crees? Pero sigue leyendo, porque tenemos la solución.`,
        },
        {
          header: `Tu Seguro Médico al rescate`,
          body: `Definitivamente, $8,000 pesitos no son cualquier cosa… (suena la música de Superman), ¡para eso está tu Seguro Médico!
          En inter.mx tenemos una variedad de planes de Seguro Médico que se adaptan a tus necesidades. Conoce cuáles de ellos incluyen check-up usando nuestro cotizador fácil y rápido. <>¡Sigue este enlace!</>
            
          ¿Tu seguro no incluye check-up? ¡No te preocupes! Escríbenos un mensajito de WhatsApp a 55 4000 3289 y pregunta por PREVENCIÓN SEGURA. ¡Seguro, seguro te conviene!
          No lo olvides: estar protegid@ es la mejor inversión que puedes hacer. ¡Prevén y cuida tu salud! 
          ¡Cuídate siempre al mejor precio! Conoce también <>Recuperación Segura, el Seguro Médico más accesible</>.`,
        },
        {
          header: `¿Más dudas, comentarios, aclaraciones?`,
          body: `¡Síguenos en <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</> para no perderte nada sobre seguros! O ponte en contacto con nosotros por teléfono al 55 442 46837 o vía email a <>hola@inter.mx</>.`,
        },
      ],
    },
    {
      img: BG95,
      date: "Octubre 2022",
      autor: "adminInter",
      title: "Recuperación Segura, el Seguro Médico más accesible",
      body: "Con Recuperación Segura tienes el poder de proteger tu salud desde $137 al mes; sin letras chiquitas, sin coaseguro ni deducible. ¡Entérate aquí!",
      content: [
        {
          header: ``,
          body: `Queremos que todo México esté protegido, por eso te tenemos una nueva opción para cuidar tu salud al mejor precio y sin letras chiquitas. ¡Conoce el nuevo Seguro Médico de la familia <>inter.mx</> y aprovecha el precio de lanzamiento! Con <>inter.mx</> seguro, seguro. 
          ¡Sigue leyendo para enterarte de todo!`,
        },
        {
          header: `¿Por qué es el Seguro Médico más accesible?`,
          body: `Con Recuperación Segura, inter.mx te da una opción mucho más económica para proteger tu salud, porque creemos que tod@s debemos tener la oportunidad de protegernos con un Seguro Médico. ¡Seguro, seguro estarás de acuerdo con nosotros! 
          Y como sabemos lo importante que es cuidar tu salud y la de tu familia, queremos darte el poder de protegerla al menor precio posible. Con el nuevo producto de Médico Seguro, Recuperación Segura asegúrate desde $137 al mes. Increíble, ¿no?
          <>¿Buscas un Seguro de Gastos Médicos? ¡Encuéntralo aquí!</>`,
        },
        {
          header: `¿Qué lo hace diferente de otros seguros?`,
          body: `¡Qué gran pregunta! Recuperación Segura es un Seguro Médico que te cubre cáncer, apendicitis, infartos y un montón más de enfermedades súper comunes (checa la lista completa más adelante), ¡eso es poder! 
          Uno de los grandes beneficios es que tu eliges el hospital sin tener que pagar coaseguro ni deducible. ¿A poco no está increíble leer eso? 
          Con Recuperación Segura vas a poder elegir entre dos paquetes de diferente precio. Éstos sólo varían en la suma asegurada para cada padecimiento. Elige la opción que mejor se adapte a lo que estás buscando, ¡desde $137 al mes!
          <>¿Palabras extrañas en tu seguro médico? ¡Aquí te las explicamos!</>`,
        },
        {
          header: `¿Exactamente qué cubre?`,
          body: `Como te lo prometimos, ¡sin letras chiquitas! Aquí te decimos todas las enfermedades de las que estarás súper protegid@, siempre y cuando no sean preexistentes y cumplas con el periodo de espera necesario para cada una (esta info puedes checarla con tu asesor inter.mx).
          Apendicitis	Hernia inguinal	Neumonía
          Cáncer (tooodos, excepto de piel)	Hiperplasia prostática benigna	Pancreatitis
          Divertículos del colon	Histerectomía	Tumores benignos (excepto en piel, tejido subcutáneo y mama)
          Enfermedad vascular cerebral (excepto crisis de isquemia transitoria)	Infarto al miocardio	Úlcera duodenal
          Extirpación quirúrgica de ovarios	Litiasis renal	Úlcera gástrica
          Hernia abdominal	Litiasis vesicular	Accidentes infantiles
          ¡Te cubre hasta dos padecimientos al año!`,
        },
        {
          header: `¡¿Cómo lo contrato?!`,
          body: `¡Es muy sencillo! En inter.mx queremos proteger tu salud de forma fácil y rápida, por lo que nuestros asesores están list@s para recibir tus dudas y ayudarte a elegir el mejor paquete para ti desde $137 mensuales. ¡Échanos un mensajito a nuestro WhatsApp: 55 442 46837! 
          <>Cotizador de seguros - Todo lo que necesitas a un clic de distancia</>


          ¡En <>inter.mx</> le quitamos lo complicado a los seguros! Síguenos en <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</> para que no te pierdas de naditita.`,
        },
      ],
    },
    {
      img: BG96,
      date: "Octubre 2022",
      autor: "adminInter",
      title: "¡Activa tu Modo Checo! Un seguro gratis para todos los mexicanos",
      body: "Para inter.mx, Modo Checo es proteger a todos los mexicanos con un seguro gratis. ¡Aquí te lo contamos!",
      content: [
        {
          header: ``,
          body: `En <>inter.mx</> tenemos una misión muy clara: proteger a todos los mexicanos. Es por eso que le quitamos lo complicado a los seguros y regalamos un seguro a todas las personas que activen su Modo Checo. Sí, un SEGURO GRATIS PARA TODO MÉXICO. ¡Sigue leyendo y entérate de qué se trata!`,
        },
        {
          header: `¿Qué es Modo Checo?`,
          body: `Estar en Modo Checo es apuntar a lograr cosas grandes, tener metas que parecen casi imposibles e intentar con todas las ganas, querer alcanzar los objetivos más importantes y significativos para nosotros mismos y, como Checo Pérez, nunca rendirnos. 
          Para inter.mx, esa gran meta es proteger a todos los mexicanos. Por eso, activamos nuestro Modo Checo y queremos dar un seguro gratis a todas las personas que también lo activen hasta antes del Gran Premio de México. Sí, ¡un SEGURO GRATIS! 
          Con Modo Checo, por 23 días a partir de que arranque el GP de México, te daremos una protección médica de $10,000 MXN en caso de accidente y de $50,000 MXN en caso de fallecimiento. ¡Obvio tocamos madera, pero estamos convencidos de que siempre es mejor estar bien protegid@s!
          Te estarás preguntando cómo sumarte, ¿no? Psst… psst… Es súper fácil y rápido. ¡Sigue leyendo!
          <>¿Buscas un Seguro de Gastos Médicos? ¡Encuéntralo aquí!</>`,
        },
        {
          header: `¡Todo México seguro, seguro!`,
          body: `Activar tu Modo Checo y recibir tu seguro gratis es facilícimo (¡en <>inter.mx</> le quitamos lo complicado a los seguros!). Te lo decimos en tres pasos:
          
          1. Entra a <>inter.mx/modocheco</>.

          2. Regístrate de volada (en serio es rapidísimo).
          3. ¡Activa tu Modo Checo!
          ¡Y listo! Tienes hasta el 30 de octubre de 2022, antes del GP de México, para registrarte. Tu protección comenzará en cuanto arranque la carrera y durará 23 días. 
          Cuando termine la vigencia, tu seguro se cancelará solito, automáticamente, entonces… ¡nada de qué preocuparte! Y si deseas continuar con la protección, ¡seguro, seguro tendremos una gran oferta para ti!
          ¿Ves? Un seguro sin complicaciones y para todo México, ¡así de fácil!
          Únete a los miles de mexicanos que ya activaron su Modo Checo y están listos para el GP de México.`,
        },
        {
          header: `¡Modo Checo activado!`,
          body: `Si ya activaste tu Modo Checo, no nos dejarás mentir de lo fácil y rápido que es, ¿no? Ahora sólo falta esperar al arranque del GP de México para estar seguro, seguro. ¡No olvides descargar la app de inter.mx para que cheques tu certificado del seguro y lo tengas súper a la mano siempre!
          Recuerda que tu seguro estará activo del 30 de octubre al 21 de noviembre 2022. En caso de que necesites usarlo –¡tocamos madera!– échanos un telefonazo o escríbenos un mensajito al 55 1620 9997 y te apoyaremos de volada cuando nos necesites. 
          Con Modo Checo, ¡todo México seguro, seguro! 
          ¡En <>inter.mx</> le quitamos lo complicado a los seguros! Síguenos en <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</> para que no te pierdas de naditita.`,
        },
      ],
    },
    {
      img: BG97,
      date: "Octubre 2022",
      autor: "adminInter",
      title: "¿Cómo bajar la prima de mi Seguro Médico?",
      body: "Seguro, seguro has escuchado la palabra “prima” miles de veces. Si aún no te queda 100% claro qué es, aquí te platicamos y te damos varios tips para que sea más baja.",
      content: [
        {
          header: ``,
          body: `En <>inter.mx</> te damos los mejores tips para que, cuando contrates un seguro, sepas todito lo necesario. En este artículo nos concentraremos en la prima de Seguro Médico y te contaremos cómo bajarla de precio lo más posible. 
          ¿List@? ¡Sigue leyendo!`,
        },
        {
          header: `La prima que no es un familiar`,
          body: `Sabemos que el nombre suena chistoso, pero la prima a la que nos referimos aquí no tiene nadita que ver con las hijas de tus tíos. 
          La prima de seguro es lo que vas a pagar por tu seguro, ya sea mensual o anualmente. ¡Así de fácil! Todos los seguros tienen una prima y el precio varía por diferentes factores, como tu edad, sexo de nacimiento, el lugar donde vives, y otros asuntos que te platicaremos en breve. 
          ¿Es posible bajar el costo de tu Seguro Médico? ¡Sigue leyendo!
          <>Cotizador de seguros - Todo lo que necesitas a un clic de distancia</>`,
        },
        {
          header: `¿Cómo se calcula la prima de tu Seguro Médico?`,
          body: `El precio de la prima de tu Seguro Médico depende de un montón de cosas, como ya te anticipamos. Pero lo más importante es tu edad, el estado de tu salud al momento de contratar y las preexistencias que tengas para entonces.
          Por supuesto, entre más joven y saludable seas y menos preexistencias tengas, la prima de tu seguro va a ser menor, además de que te cubrirá muchísimos más padecimientos. En resumen… ¡menor precio y mayor cobertura entre más pronto contrates tu Seguro Médico! Suena bien, ¿no?
          Aunque estos factores son importantísimos, la prima de tu seguro también cambia de precio conforme a la aseguradora y el nivel de protección que elijas, las coberturas adicionales que decidas agregar y qué tanto quieres pagar de deducible cada que hagas uso de tu seguro –entre mayor sea tu aportación de prima, menor puede ser tu deducible–.
          Aquí es donde te podemos aconsejar para bajar el precio de tu seguro. ¡Continúa!`,
        },
        {
          header: `5 tips para bajar la prima de tu seguro`,
          body: `Ya te contamos qué es una prima y de qué depende si sube o baja su costo. Ahora te vamos a chismear nuestros tips para que pagues menos.`,
          list: [
            `¡Contrata lo antes posible! Como ya te contamos, mientras más joven y saludable seas, tu seguro será mucho más barato.`,
            `Menos es más… barato. Mientras menos coberturas adicionales agregues, tu prima será más baja, ¡obvio! Pero te recomendamos pensar muy bien en lo que de verdad necesitas, porque también puede ser que lo barato salga caro a la larga si a tu seguro le falta cobertura.`,
            `Analiza todas las opciones. Checa lo que te ofrece cada aseguradora, los diferentes niveles de protección y el de hospitales y doctores que incluye cada uno, y compara los precios para ver cuáles opciones son más económicas o te ofrecen más por menos. (Psst… psst… ¡Nuestro comparador <>Médico Seguro</> es el mejor de todo México!)`,
            `Coberturas locales. Checa si hay coberturas de sólo tu estado para que la prima sea más baja. `,
            `¡Aprovecha las promos! Puede sonar muy obvio, pero si hay un seguro que te interesa pregunta si hay promociones, todo descuento siempre es bienvenido. ¡En <>inter.mx</> nos encantan las promos!`,
          ],
        },
        {
          header: ``,
          body: `¿Qué te parece? ¡Ahora ya sabes lo fácil que es bajar la prima de tu seguro! No dejes pasar la oportunidad. Cotiza hoy mismo tu Seguro Médico en <>inter.mx</> y pon en práctica nuestros tips. Seguro, seguro encuentras la mejor opción para ti.

          
          <>Médico Seguro: cotizador para elegir el mejor Seguro Médico</>
          
          ¿Te latió esta info? ¡Conoce más sobre cualquiera de nuestros seguros en el <>blog</>! Síguenos en <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</> para que no te pierdas de naditita.`,
        },
      ],
    },
    {
      img: BG98,
      date: "Octubre 2022",
      autor: "adminInter",
      title: "¿Qué pasa con mi Seguro de Coche si vendo mi auto?",
      body: "¿Estás por vender tu coche y aún no vence tu póliza? ¡Esta información es para ti! ",
      content: [
        {
          header: ``,
          body: `¡Lo sabemos! Ésta es una de las dudas más frecuentes que se tienen a la hora de vender un coche: ¿qué pasa con mi póliza? Y con razón. Nadie nos dice qué hacer si nuestro seguro si aún no vence y ya nos vamos a despedir de nuestro auto. ¿Es tu caso? No te preocupes, nosotros te explicamos aquí.
          ¡Sigue leyendo!`,
        },
        {
          header: `La pregunta del millón: ¿qué quieres hacer tú con tu Seguro de Coche?`,
          body: `En efecto, ¡depende de ti! A la hora de vender tu auto hay de dos sopas con tu póliza vigente:`,
          list: [
            `Opción 1: Solicitar un endoso –es decir, una modificación–  para cambiar el nombre del contratante y del asegurado.`,
            `Opción 2: Cancelar tu póliza de seguro.`,
          ],
        },
        {
          header: ``,
          body: `Para elegir lo que más te convenga, considera a quién le venderás tu coche o los términos a los que pueden llegar en la venta. 
          Si, por ejemplo, un familiar o amigo quiere comprarte el auto, puede que a ambos les convenga hacer el cambio de contratante y asegurado. 
          Por otro lado, si se lo vendes a un desconocido que busca otro tipo de póliza, puedes cancelarla sin mayor problema.`,
        },
        {
          header: `¿Y si ya pagué todito mi seguro?`,
          body: `¡Súper importante! Si adelantaste todos o varios de los pagos de tu póliza y decides cancelarla (lo que se conoce como “primas no devengadas”), es necesario que le pidas a tu aseguradora la devolución de ese dinero. ¡A nadie le cae mal un dinerito de vuelta!
          Si acaso ya pagaste todo y vas a hacer el cambio de titular y asegurado, te recomendamos que platiquen ustedes qué va a pasar con ese dinero. Una opción sería agregarlo al precio del coche en venta.`,
        },
        {
          header: `¿Cómo se hacen estas solicitudes?`,
          body: `Sea lo que sea, en inter.mx es súuuper fácil y rápido. Sólo échanos un telefonazo, solicita lo que necesites, te mandaremos un mail con la info que necesitaremos que nos compartas y ¡listo!
          Acá te dejamos nuestro teléfono de atención: 55 442 46837.
          Es súper importante que, cuando quieras hacer el cambio de tu póliza o la cancelación, la tengas súper a la mano para que puedas dar tooodos los datos que te pida tu asesor de inter.mx. (Psst… psst… ¿ya tienes nuestra app? Ahí puedes llevar todas tus pólizas a donde sea que vayas.)
          ¡Ojo! Es súper necesario que seas tú, como dueño de la póliza, quien haga estas solicitudes. Si no, no se podrán hacer.`,
        },
        {
          header: `¿Complicado? ¡Nunca con inter.mx!`,
          body: `¡No te quedes con dudas! Lee nuestro <>blog</> para conocer súper bien el mundo de los seguros y síguenos en <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</> para conocer tooodas nuestras promociones y resolver más dudas sobre seguros.`,
        },
      ],
    },
    {
      img: BG91,
      date: "Septiembre 2022",
      autor: "adminInter",
      title: "Palabras extrañas de tu seguro médico",
      body: "¿Te has topado con palabras extrañas en tu Seguro Médico? Aquí te platicamos desde qué es una póliza hasta qué son las preexistencias.",
      content: [
        {
          header: ``,
          body: `Seguro, seguro te has topado con palabras extrañas cuando quieres contratar un Seguro Médico. ¡No te preocupes! Aquí en <>inter.mx</> somos expertos en quitarle lo complicado a los seguros, por eso te decimos tooodo lo que tienes que saber.`,
        },
        {
          header: `Primero lo primero, ¿qué es una póliza?`,
          body: `¡Muy sencillo! La póliza es un documento donde vas a poder encontrar toooda la información sobre el seguro que acabas de contratar y cuáles son las condiciones de tus coberturas. Te recomendamos descargar la app de <>inter.mx</> para consultar tu póliza cada que lo necesites.

          
          <>Médico Seguro: cotizador para elegir el mejor Seguro Médico</>`,
        },
        {
          header: `Deducible, Coaseguro… ¿Y eso qué es o con qué se come?`,
          body: `Nos gustaría decir que con salsa verde, pero la verdad es que tanto el deducible como el coaseguro son cosas que de veras tienes que entender de tu Seguro Médico. 
          Piensa que el deducible es como un anticipo que debes pagar en cada padecimiento para que tu seguro te eche la mano. Sólo cuando el costo de tu padecimiento supere el deducible,  entrará la protección de tu seguro.
          ¿Y el coaseguro? Cuando ya pagaste el anticipo o “enganche”, el coaseguro es un porcentaje pequeño que te tocará pagar en tu padecimiento, ¡todo lo demás lo cubrirá tu seguro!
          ¿Pero qué pasa si es muchísimo dinero? ¡Ah, tampoco te preocupes por eso! Existe un tope de coaseguro que se encarga de cuidar tus finanzas y poner un límite a lo que podrías llegar a pagar. Entonces, ¡ni te preocupes! El resto ya le tocará a tu seguro.
          <>¡Quiero contratar un Seguro Médico para mi familia!</>`,
        },
        {
          header: `¿Cuánto dinero cubre mi Seguro Médico?`,
          body: `Antes que nada, debes saber que la suma asegurada es lo máximo que tu seguro te puede cubrir en caso de un accidente o enfermedad, según el plan que hayas contratado. ¡Así de sencillo! 
          Estos son los dos planes que puedes elegir en <>inter.mx</>:


          Cobertura por padecimiento:
          Pensada para restablecer la salud, la suma asegurada que contrates será la cantidad máxima que tu seguro va a poner en cada enfermedad o accidente. 
          ¡Ojo! Es súper importante que elijas bien desde el inicio, porque si tu suma se termina en algo como un cáncer, por ejemplo, ya no habrá refill para ese mismo tema.
          Cobertura por año póliza:
          Ideal para prevenir, al contratar, la suma asegurada que escojas al contratar será la cantidad total que podrás usar en enfermedades o accidentes durante todo un año. ¡Y anualmente tu suma tendrá un refill del 100%! 
          <>Cotizador de seguros - Todo lo que necesitas a un clic de distancia</>`,
        },
        {
          header: `Metí la pata y me caí, ¿a dónde tengo que ir?`,
          body: `¡No te estreses! Lo único que tienes que hacer es revisar cuáles son los hospitales y médicos red de tu aseguradora, dependiendo de tu nivel de protección. ¡Pero aguas! Checa bien la lista, porque si vas con alguien que no es parte del seguro, tendrás que pagar la cuenta completa.`,
        },
        {
          header: `¿Cómo sé qué enfermedades cubre mi Seguro Médico?`,
          body: `Seguro has escuchado sobre las preexistencias, pero ¿sabes qué son? ¡Muy fácil!, son todas las enfermedades y todos los accidentes que hayan afectado tu salud antes de contratar tu seguro. ¡Ojo! para evitarte broncas más adelante, es mega crucial que desde el inicio le cuentes tooodo con lujo de detalle a la aseguradora.
          ¿Pero cuánto tiempo debo esperar para que el seguro cubra mi enfermedad? Hay una lista de padecimientos que tu aseguradora te puede cubrir sólo si ya llevas un ratito con tu seguro vigente. Checa muy bien tu póliza para que estés súper al tanto, porque los tiempos suelen variar entre seguros.
          <>¿Cuáles son las exclusiones de un Seguro de Gastos Médicos?</>

          ¡Listo!, ya estás preparad@ para entrar a <>inter.mx</> y cotizar tu Seguro Médico. Síguenos en <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</> para que no te pierdas de nadita.`,
        },
      ],
    },
    {
      img: BG92,
      date: "18 September 2022",
      autor: "adminInter",
      title: "Cuatro cosas que no sabías de seguros",
      body: "¿Un Seguro de Coche me protege de robo de autopartes?, ¿puedo asegurar un coche “chocolate”? ¡Resuelve éstas y otras preguntas leyendo este artículo!",
      content: [
        {
          header: ``,
          body: `¡Que no te asuste el mundo de los seguros! En inter.mx nos encanta quitarle lo complicado, por eso en este artículo te resolvemos cuatro dudas frecuentes sobre tu Seguro de Coche. 
          ¡Sigue leyendo!`,
        },
        {
          header: `1.	¿Mi Seguro de Coche me cubre el robo de autopartes?`,
          body: `Con Coche Seguro plan Amplio y Limitado cuentas con cobertura por Robo Total, pero no por Robo Parcial. ¡Pero no te preocupes! Si a ti te interesa una cobertura de este tipo, contáctanos y te hacemos una cotización especial para que tooodo tu coche con tooodas sus partes estén bien aseguradas. 
          <>¿Por qué el precio del Seguro de Coche cambia según el modelo?</>`,
        },
        {
          header: `2.	¿Con inter.mx es posible asegurar un coche “chocolate” o un coche “fronterizo”?`,
          body: `Antes de meter las manos en la masa… ¿cuál es la diferencia entre un coche “chocolate” y uno “fronterizo”? 
          Un coche “chocolate” no es el que viene dentro de un huevito de chocolate, sino un auto que fue comprado en otro país y luego fue traído a México y legalizado.
          Un coche “fronterizo” es un vehículo registrado en Estados Unidos que puede circular en zonas fronterizas de México con un permiso especial. 
          Entonces, ¿estos coches son asegurables por inter.mx? ¡La respuesta es sí y no! En inter sí podemos ayudarte a proteger tu auto “chocolate” o “fronterizo”, pero no desde nuestro sitio web. ¡Comunícate con nosotros y te apoyamos con una cotización especial!
          <>¿Cualquier Seguro de Auto me cubre por Daños a Terceros?</>`,
        },
        {
          header: `3.	Si al comprar mi coche me aseguraron, pero quiero contratar con inter.mx, ¿puedo trasladar mi Seguro?`,
          body: `Antes que nada, ¡gracias por querer cambiar tu Seguro de Coche a inter.mx! Estamos seguros de que con Coche Seguro tú y tu auto serán muy felices. 
          ¡Pero no nos adelantemos! Tristemente no podemos trasladar tu seguro así na’más. Para cambiarte a inter.mx primero debes cancelar tu póliza anterior y ahora sí… ¡A cotizar tu Coche Seguro se ha dicho! En inter.mx es suuúper fácil y rápido.
          <>¿Puedo asegurar un coche sin placas?</>`,
        },
        {
          header: `4. ¿Dónde puedo cotizar mi Seguro si soy conductor de una plataforma de viajes?`,
          body: `¡Fácil! Si eres socio conductor de una app, puedes cotizar tu seguro directo en inter.mx. Entra a <>Coche Seguro</> y encuentra la opción para cotizar tu Seguro de Coche para plataformas de viajes.
          Nuestras coberturas para ti están súper, porque no sólo te protegen en tus tiempos de trabajo, sino también en todos los momentos cuando estés desconectad@. ¡Chécalas!`,
        },
        {
          header: `Protección Amplia`,
          body: `El plan de cobertura amplia de AXA es tu Seguro de Coche ideal si eres socio conductor de la app de UBER.`,
        },
        {
          header: `Protect APP`,
          body: `Para cualquier otra aplicación de viajes, como Cabify, Easy Taxi o DiDi, Protect App es la opción ideal para ti y tus pasajeros. 
          ¿Prefieres contratar por teléfono? ¡Échanos un telefonazo al 55 442 46837! 
          Encuentra más info relacionada aquí: <>¿Puedo asegurar mi auto si soy socio conductor de una app?</>`,
        },
        {
          header: `¿Más dudas? ¡Checa nuestro blog!`,
          body: `¡Recuerda! En <>inter.mx/blog</> puedes encontrar muuuchos más temas como éstos para que le entiendas súper bien a los seguros. ¡No te quedes con dudas! Y ve siempre seguro, seguro.
          Síguenos en nuestras redes y no te pierdas de nada: <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</>`,
        },
      ],
    },
    {
      img: BG93,
      date: "18 September 2022",
      autor: "adminInter",
      title: "¿Mi seguro médico me protege contra nuevos virus?",
      body: "Seguro, seguro has escuchado sobre los nuevos virus en el mundo y te preguntas si tu seguro los va a cubrir. Aquí te contamos más sobre el tema.",
      content: [
        {
          header: ``,
          body: `Los Seguros Médicos están hechos para protegernos contra imprevistos de salud y ayudarnos a cuidar nuestra economía personal cuando suceden, ¡eso está clarísimo! Pero… ¿los Seguros Médicos pueden cubrir tooodos los virus que se vuelvan noticia, ya sean nuevas variantes de COVID, viruela del mono o cualquier otra cosa? 
          Aquí te contamos. ¡Sigue leyendo!`,
        },
        {
          header: `¡Primero un fun fact!`,
          body: `¿Sabías que <>inter.mx</> nació con la pandemia porque queríamos proteger a todos los mexicanos contra el COVID? El primer paso fue crear un producto accesible que pudiera traer paz y seguridad a tod@s, después llegaron la plataforma digital y Médico Seguro. Luego nacieron Bien Seguro, Coche Seguro, ¡y el resto ya es historia! 
          Nuestra tarea más importante es protegerte a ti y a lo que más amas, fácil, rápido, al mejor precio y con una experiencia personalizada de principio a fin.
          <>Seguro Médico: esencial, protegido o blindado ¿Qué plan escoger?</>`,
        },
        {
          header: `Ahora sí… ¿qué onda con los nuevos virus?`,
          body: `Lo primero es… ¡no te estreses! Mantener la calma, alimentarnos bien y tomar las precauciones debidas es lo mejor que podemos hacer siempre. 
          Cuando sale a la luz una nueva enfermedad, el mundo científico se súper pone las pilas para conocerla mejor y aprender a tratarla. ¡Ya nadie nos lo cuenta después del COVID! Así también las aseguradoras hacen su parte y analizan todo lo que haya por analizar para poder responder de la mejor manera y lo más rápido posible. 
          Entonces, sea lo que sea, mantente en contacto con tu aseguradora, ¡y no te angusties! Tómate un tecito de jengibre con miel y limón para estas lluvias y, si llega a aparecer alguna nueva enfermedad –¡tocamos madera!–, nosotros te respondemos tooodas tus dudas para que estés más tranquil@.
          ¡En inter.mx siempre haremos todo lo posible por que estés seguro, seguro! `,
        },
        {
          header: `Encuentra tu seguro ideal`,
          body: `Tu salud, la de tu familia y la de todos los mexicanos es muy valiosa para nosotros. Por eso, en <>inter.mx</> siempre vamos a encontrar la forma de apoyarte. ¡Entra a nuestro sitio, conoce nuestro cotizador y encuentra el mejor Seguro Médico para ti y tu familia! Es muy fácil, rápido y seguro. 
          
          <>Médico Seguro: cotizador para elegir el mejor Seguro Médico</>

          ¿Te latió esta info? ¡Conoce más sobre cualquiera de nuestros seguros en el <>blog</>! Síguenos en <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</> para que no te pierdas de naditita.`,
        },
      ],
    },
    {
      img: BG94,
      date: "18 September 2022",
      autor: "adminInter",
      title: "¿Las coberturas cambian dependiendo de la aseguradora?",
      body: "En <>inter.mx</> puedes comparar tu Seguro Médico entre las mejores aseguradoras, ¿pero cambian las coberturas? ¡Descúbrelo aquí!",
      content: [
        {
          header: ``,
          body: `Si estás pensando en contratar tu Seguro Médico en <>inter.mx</>, pero no sabes cómo elegir, ni qué aseguradora tiene más y mejores coberturas, ¡este artículo es para ti!  
          
          ¡Sigue leyendo!
          <>Médico Seguro: cotizador para elegir el mejor Seguro Médico</>`,
        },
        {
          header: `¿De qué se trata un Seguro Médico en inter.mx?`,
          body: `Si de proteger tu salud se trata, <>inter.mx</> es tu mejor opción. Con Médico Seguro tienes el poder de comparar tu seguro entre las mejores aseguradoras del país y tener todas las coberturas que tú quieras. 
          Pero vayamos al inicio, ¿qué cubre Médico Seguro? Sin importar la aseguradora que elijas, en inter.mx tu Seguro Médico siempre te va a cubrir lo siguiente:`,
          list: [
            `Hospitalizaciones`,
            `Honorarios médicos`,
            `Estudios auxiliares de diagnóstico`,
            `Medicamentos`,
            `Ambulancias`,
            `Prótesis y aparatos ortopédicos`,
          ],
        },
        {
          header: ``,
          body: `Está súper, ¿no?
          <>¿Un Seguro Médico cubre cirugías inmediatas?</>`,
        },
        {
          header: `Entonces… ¿dónde está la diferencia?`,
          body: `No tendría sentido poder elegir entre aseguradoras si todas fueran lo mismo, ¿cierto? 
          ¡Aquí es donde la cosa se pone interesante! Lo que varía entre cada aseguradora es el precio de tu seguro, la suma asegurada, el deducible, el porcentaje y el tope de coaseguro. (psst… psst… <>Palabras extrañas de tu Seguro Médico</>)
          
          Además, hay aseguradoras que te incluyen algunos beneficios adicionales sin ningún costo extra para ti, como Plan Dental, por ejemplo. 
          ¿Ves? Tal vez no es taaan difícil encontrar tu Seguro Médico perfecto. ¡En inter.mx es súper fácil y rápido!
          <>¿Mi Seguro Médico incluye Plan Dental y de qué se trata?</>`,
        },
        {
          header: `¿Y puedo agregar otras coberturas?`,
          body: `¡Pero claro! En <>inter.mx</> tú eliges toditito: desde tu nivel de protección (Esencial, Protegida o Blindada) y tu suma asegurada (entre 3 y 130 millones), hasta las coberturas adicionales que mejor vayan contigo.
          Checa algunas coberturas con las que puedes personalizar tu Seguro Médico:`,
          list: [
            `Maternidad`,
            `Accidente sin deducible`,
            `Plan Dental`,
            `Plan Visión`,
            `Atención Médica Internacional`,
            `Estudios preventivos para adultos`,
            `¡Y más!`,
          ],
        },
        {
          header: ``,
          body: `Queremos que tu Seguro Médico se adapte perfecto a tu modo de vida y tus necesidades. 
          ¡No importa lo que elijas! Lo importante es que estés seguro, seguro y que tu salud esté súper protegida siempre. 
          <>¿Mi Seguro Médico me cubre si me embarazo?</>`,
        },
        {
          header: `¡Cotiza en un tap tap!`,
          body: `Con <>inter.mx</>, encontrar tu Seguro Médico es suuúper fácil y rápido. ¡Pruébalo tú mism@! Entra a <>inter.mx</> hoy mismo y cotiza en nada de tiempo. 
          
          Síguenos en <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</> y checa nuestro <>blog</> completito para conocer tooodo sobre el mundo de los seguros. Con inter.mx, ¡seguro, seguro!`,
        },
      ],
    },
    {
      img: BG87,
      date: "Agosto 2022",
      autor: "adminInter",
      title:
        "Son tiempos de ahorrar, ¿es buen momento para contratar un seguro?",
      body: "¡Los precios de los seguros podrían subir! ¿Por qué? ¿Qué es lo más recomendable para ti? ¡Descúbrelo en este artículo!",
      content: [
        {
          header: ``,
          body: `¡La economía mundial se está poniendo color de hormiga! Seguramente has notado que todo está subiendo de precio, ¿no? Y sí, como tal vez ya habrás oído, también está afectando a los seguros. Si ya te estás preguntando si ahorita es un buen momento para contratar tu Seguro Médico o Seguro de Coche, o si sería mejor idea ahorrar y meter todo en tu cochinito, ¡este artículo es para ti!`,
        },
        {
          header: `Mini clase de economía global y seguros`,
          body: `¿Te has fijado que últimamente todo está subiendo de precio? Entre el COVID, la Guerra en Ucrania y otras crisis, la economía mundial la está pasando bastante mal, como ya te imaginarás. Y esto lo afecta TODO, incluidos los seguros. Vaya tiempos en los que vivimos, ¿no?
          Para no hacernos mil bolas con los términos económicos, te contamos en pocas palabras qué onda con los seguros. Resulta que los insumos médicos y las autopartes también están cada vez más caros, y eso puede causar que los Seguros de Coche y Salud comiencen a subir sus costos. 
          ¿Es el apocalipsis? ¡Para nada! El presidente de la Asociación Mexicana de Instituciones de Seguros (AMIS), Juan Patricio Riveroll, dijo que los aumentos en las pólizas serán mínimos y de a poquito. ¡Cruzamos los dedos para que así sea!
          <>¿Buscas un Seguro de Gastos Médicos? ¡Encuéntralo aquí!</>`,
        },
        {
          header: `¿Comprar un seguro en tiempos difíciles?`,
          body: `Entonces, ¿es buena idea comprar un Seguro Médico o de Coche ahorita? ¡Claro! Y entre antes, mejor. Siempre es un buen momento para estar seguro, seguro, y más aún cuando queremos cuidar nuestro dinero. 
          Contar con un seguro es maravilloso. Por una parte, te da muchísima tranquilidad –muy necesaria en estos tiempos–, y por la otra, definitivamente te ayuda a proteger tu dinero contra imprevistos que, sin seguro, podrían ser un golpe tremendo a tu economía personal. ¡Y a nadie le gustan esas sorpresas!
          Además, no todos los seguros son carísimos, y ya no tienes ni que salir de casa para contratar. En inter.mx puedes encontrar el seguro que tú quieras, justo a la medida de tus necesidades y desde la comodidad de tu sillón favorito.
          <>Cotizador de seguros - Todo lo que necesitas a un clic de distancia</>`,
        },
        {
          header: `¡Nuestro mejor tip!`,
          body: `¡No guardes tu dinero bajo el colchón, mejor contrata un seguro que sea tu colchón! Y así, tu patrimonio ya no estará en la cuerda floja. 
          Protege lo que más te importa: tu salud y la de tu familia, tu coche o moto, tu casa o tu mascota. Sea lo que sea, en <>inter.mx</> tenemos una gran variedad de opciones de seguros y coberturas que te van a encantar. Tú eliges cómo protegerte y hasta cuánto quieres invertir. 
          
          Además, en inter.mx tenemos un súper comparador que te ayudará a encontrar fácil y rápido tu seguro ideal. 	
          <>Cotizador de seguros: ¿de veras es rapidísimo?</>
          
          ¡En <>inter.mx</> le quitamos lo complicado a los seguros y te decimos todo como va aquí en nuestro <>blog</>! Síguenos en <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</> para que no te pierdas de naditita.`,
        },
      ],
    },
    {
      img: BG88,
      date: "18 Agosto 2022",
      autor: "adminInter",
      title: "¿Qué imprevistos cubren los Seguros para Mascotas?",
      body: "¿Quieres proteger a tu perro o gato? ¡Conoce aquí todas las coberturas de Mascota Segura!",
      content: [
        {
          header: ``,
          body: `Si el amor que tienes por tu mascota es comparable con el que ella tiene por ti, seguro, seguro querrás que siempre esté al cien y nunca le pase nadita, ¿no? ¡Entonces esto te va a encantar! En inter.mx tenemos los planes de seguro ideales para que tu cuatropatitas esté súper bien protegido, sano y sobre todo, ¡muy feliz! Conoce aquí los tipos de imprevistos que cubren nuestros Seguros para Mascotas. 
          <>¿Hay límite de edad en un seguro para mascotas?</>`,
        },
        {
          header: `Antes que nada, ¿por qué asegurar a tu mascota?`,
          body: `Imagina al perrito más adorable, curioso y travieso. Ahora imagina que, paseando, otro perro –no tan tierno– se lo quiere comer… ¡O peor! ¿Qué tal si a tu pequeño se encuentra un pastel sospechoso en el parque y se intoxica? ¡Qué nervios!, ¿no? 
          ¡Obvio tocamos madera para que nunca nada de esto le pase! Pero, sin duda, lo mejor es estar prevenido. Por suerte, un Seguro para Mascotas puede cubrir en casos como los que ya te platicamos y muchos más. 
          <>Seguro para mascotas: ¿solamente incluye perros y gatos?</>`,
        },
        {
          header: `Imprevistos que sí prevenimos en inter`,
          body: `¡Toma nota! Éstos son los imprevistos completos que Mascota Segura les cubre a tus “garritas”:`,
          list: [
            `Si le hace daño a alguien más`,
            `Si se pone malito o accidenta`,
            `Si lo atropellan`,
            `Si se cae`,
            `Si lo ataca otro animalito`,
            `Si se envenena accidentalmente`,
            `Si se come algo raro y le duele la tripita`,
            `Si le da un golpe de calor`,
            `Cualquier cosa que le haga daño y sea súbita, imprevista, externa y no intencional`,
            `Si llega a necesitar eutanasia o fallecimiento asistido`,
            `Si estira la patita, te ayudamos con gastos funerarios`,
          ],
        },
        {
          header: ``,
          body: `Súper completo, ¿no crees? Además, todas estas coberturas aplican con el veterinario o la clínica que tú elijas, siempre y cuando te dé una factura por cualquier cosa que tu perrito o gatito necesite. 
          Y lo que está todavía mejor es que si el padecimiento de tu peludito supera los $1,000 pesos, ¡no pagas deducible!
          <>¿Cómo cotizar un seguro para mascotas? ¿Qué debes saber?</>`,
        },
        {
          header: `Los tres planes de Mascota Segura en inter.mx`,
          body: `Ni mucho ni poco, ¡sino justo lo que necesitas! Nuestros tres planes de Mascota Segura están súper equipados y los tres te cubren tooodos los imprevistos de la lista de arriba. Lo único que cambia es la suma por la que te aseguran.`,
        },
        {
          header: `Plan Mascota`,
          body: `-	Suma asegurada: $10,000 MXN.
          -	Pago anual: $1300 MXN.`,
        },
        {
          header: `Plan Garritas`,
          body: `-	Suma asegurada: $20,000 MXN.
          -	Pago anual: $2600 MXN.`,
        },
        {
          header: `Plan Mejor Amigo`,
          body: `-	Suma asegurada: $30,000 MXN.
          -	Pago anual: $3900 MXN.`,
        },
        {
          header: ``,
          body: `¡Ojo! La suma asegurada es por año. Eso significa que si tu peludito se la termina en una sola travesura o en un mal día, deberás esperar hasta que se renueve tu póliza. Entonces, es súper importante que pienses en la edad de tu mascota, qué tan traviesa y movida es su vida para contratar el mejor plan. 
          ¡Entra hoy mismo a <>inter.mx</> y protege a tu amigo peludo para que siempre esté al cien!
          
          <>Seguros para mascotas: ¿un lujo o una necesidad?</>



          ¿Te latió esta info? ¡Conoce más sobre cualquiera de nuestros seguros en el <>blog</>! No olvides que, además de Mascota Segura, tenemos los mejores Seguros de Coche y Seguros Médicos. ¡Contrata fácil y rápido en <>inter.mx</>!
          
          ¡Síguenos en <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</> para que no te pierdas de naditita!`,
        },
      ],
    },
    {
      img: BG89,
      date: "18 Agosto 2022",
      autor: "adminInter",
      title:
        "El mejor Seguro de Gastos Médicos para tus hij@s este regreso a clases",
      body: ": ¿Buscas un Seguro Médico Familiar? ¡Conoce las mejores opciones para proteger la salud de tus hij@s este regreso a clases!",
      content: [
        {
          header: ``,
          body: `Ver crecer a tu hij@ san@ es algo maravilloso. Por eso, un Seguro Médico familiar es el mejor aliado. ¿Ya conoces los planes que tenemos en inter.mx? Sin duda, son la mejor opción para prevenir imprevistos o atender su salud cada que lo necesite. Descubre cómo contratar y cuál es el mejor Seguro de Gastos Médicos para tus hij@s.
          <>¡Quiero contratar un seguro médico para mi familia!</>`,
        },
        {
          header: `¿Un Seguro Médico para mis hij@s?`,
          body: `Si lo que quieres es proteger tu patrimonio y al mismo tiempo estar seguro, seguro de tener la mejor atención médica para tus hij@s, sí o sí necesitas un Plan de Salud. 
          Con un Seguro Médico Familiar puedes acortar tiempos de espera en consultorios, elegir los hospitales que tú prefieras, tener acceso a las últimas tecnologías para la salud y mucho más. Y todo esto, mientras te ahorras un buen dinero.
          ¡Vale mucho la pena!
          Y, así sean dos o cinco hij@s, mientras tengan menos de veinticinco años puedes incluirlos en tu Seguro. En pocas palabras, puedes protegerl@s desde el kínder hasta la licenciatura. Suena bien, ¿no?
          <>Seguro de gastos médicos: ¿hasta qué edad estoy cubierto?</>`,
        },
        {
          header: `¿Qué seguro es el mejor para mí?`,
          body: `En inter.mx tenemos dos opciones con las que puedes asegurar a toda tu familia. Toma en cuenta la edad de tus hij@s y las características o necesidades personales de cada quien para elegir el seguro que más les convenga a tod@s.`,
        },
        {
          header: `Cobertura por padecimiento`,
          body: `Este plan está pensado para restablecer la salud cada que se necesite. Puedes elegir una suma asegurada de entre 6 a 25 millones de pesos para cubrir de principio a fin lo que le pueda pasar a tus hij@s –tocamos madera–.
          Si, por ejemplo, en enero tu peque tiene una fractura por ser intrépido en el recreo y requiere cirugía, yeso, y varios meses de rehabilitación, tienes el monto completito de la suma asegurada para que se recupere al cien. Y si, por decir algo, luego en noviembre a tu hij@ le da lata el apéndice y otra vez necesita visitar el quirófano, nuevamente tiene completita la suma para este nuevo padecimiento. ¡De que hay salud, hay salud!`,
        },
        {
          header: `Cobertura por año póliza`,
          body: `Piensa este otro tipo de seguro como una medida preventiva. Es decir, puedes usar la suma asegurada que tú elijas al contratar para todo lo que necesites en un año hasta acabártela (¡de 3 a 35 millones de pesos!). 
          Por ejemplo, si en el mismo año tu hij@ se tuerce un tobillo, se enferma de una gripa medio fea y su pancita se pone rara, el seguro te cubre todo, pero va restando cada padecimiento a la suma total.
          Sin embargo, a diferencia de la cobertura por padecimiento, ¡ésta se restablece anualmente en un 100%! 
          <>¿Un seguro médico cubre cirugías inmediatas?</>`,
        },
        {
          header: `Lista de coberturas y beneficios`,
          body: `Checa todo lo que podrías tener al contratar cualquiera de nuestros seguros:`,
          list: [
            `Honorarios médicos`,
            `Atención médica 24h`,
            `Cobertura dental`,
            `Consultas a domicilio`,
            `Gastos hospitalarios`,
            `Terapia intensiva`,
            `Servicio de ambulancia`,
            `Atención personalizada`,
            `Gran disponibilidad de horarios de atención`,
            `¡Y más!`,
          ],
        },
        {
          header: ``,
          body: `Está buenísimo, ¿no?
          No hay mejor tiempo que el presente para proteger la salud de tus hij@s. Busca tu opción ideal en nuestra página web y recuerda, ¡seguro, seguro con <>inter.mx</>!.



          <>Médico seguro: cotizador para elegir el mejor seguro médico</>.



          ¡En <>inter.mx</> le quitamos lo complicado a los seguros! Entra a nuestro <>blog</> y checa todo lo que tenemos para ti. Síguenos en <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</> y no te pierdas de naditita.`,
        },
      ],
    },
    {
      img: BG90,
      date: "18 Agosto 2022",
      autor: "adminInter",
      title: "¿Mi Seguro Médico incluye plan dental y de qué se trata?",
      body: "Tu sonrisa también es importante cuando de salud se trata. ¡Conoce el Plan de Salud para tu Seguro Médico!",
      content: [
        {
          header: ``,
          body: `La salud bucal es importantísima para mantener nuestro cuerpo al cien. Por eso, las visitas al dentista deben estar bien apuntaditas, ¡al menos dos veces al año! Claro, la verdad es que a veces el asunto no sale tan barato, ¿será posible que un Seguro Médico te cubra también las cuentas de tu dentista? Ahora mismo te lo contamos. 
          <>Todo sobre las coberturas de un buen Seguro de Gastos Médicos</>`,
        },
        {
          header: `Las famosísimas coberturas adicionales`,
          body: `Pa’ no hacerla de emoción: ¡sí! Tu Seguro Médico puede incluir Plan Dental. ¡Pero ojo! Hay algo más que debes saber: el Plan Dental es una cobertura adicional a tu Seguro de Salud. 
          ¿Qué significa esto?   
          Facilísimo: es una de las coberturas que tú puedes agregar a tu póliza de Seguro Médico al pagar una suma adicional. 
          La verdad está súper cool, pues entre más puedas cuidar tu salud sin sacar grandes cantidades extra de tu cartera, muchísimo mejor, ¿no crees?
          <>¿Un Seguro de Gastos Médicos Mayores cubre cirugías plásticas?</>`,
        },
        {
          header: `¿Qué cubre tu Plan Dental?`,
          body: `La cobertura para tus dientes está increíble. Cubre todito lo que podrías necesitar a la hora de ir a tu dentista. Checa la lista:
          -	Limpieza
          -	Consultas
          -	Radiografías
          -	Endodoncias
          -	¡Y más!
          <>¿Cuáles son las exclusiones de un Seguro de Gastos Médicos?</>`,
        },
        {
          header: `¡Usa tu cobertura fácilmente!`,
          body: `Aplicar tu cobertura adicional es súper sencillo. Sólo tienes que solicitar factura en tu consultorio y ¡listo! 
          ¡Toma nota por si necesitas sacarte una, dos, tres o cuatro muelas del juicio!
          El procedimiento para operarte de los dientes, en realidad es igual al de cualquier intervención quirúrgica:
          1. Avisa a tu seguro de tu cirugía para que puedan preparar tu papeleo. Recuerda que debes llamarles con al menos una semana de anticipación para este proceso.
          2. Junta los siguientes documentos y envíalos a tu aseguradora:
          -	La solicitud de programación de tu cirugía
          -	El informe médico donde tu dentista señale tu diagnóstico y el procedimiento que necesitarás
          -	Una identificación oficial 
          3. Espera la carta de aprobación por parte de tu aseguradora. 
          4. Reenvíasela a tu dentista y espera a sus indicaciones. 
          ¿Así o más sencillo?
          <>¿Buscas un Seguro de Gastos Médicos? ¡Encuéntralo aquí!</>`,
        },
        {
          header: `¡Convéncete y contrata ésta y más coberturas!`,
          body: `Además de tu Plan Dental, agrega Plan Visión, Maternidad, Atención Médica en el Extranjero y otras más a tu póliza de Gastos Médicos. 
          Conoce todas nuestras opciones de seguro en <>inter.mx</> ¡Asegúrate fácil y rápido!


          <>¿Mi seguro médico me cubre si me embarazo?</>

          

          ¡En <>inter.mx</> queremos contarte todo sobre los seguros en nuestro <>blog</>! Síguenos en <>Instagram</>, <>Facebook</>, <>Twitter</>, <>TikTok</> y <>Youtube</>.`,
        },
      ],
    },
    {
      img: BG83,
      date: "Julio 2022",
      autor: "adminInter",
      title: "¿Qué necesito para contratar el Mejor Seguro de Auto?",
      body: "¿Buscas el mejor Seguro de Auto? ¡Aquí te decimos cómo y dónde encontrarlo!",
      content: [
        {
          header: ``,
          body: `Encontrar un Seguro de Auto perfecto puede parecer una tarea horriblemente tediosa. Con tantas aseguradoras allá afuera y tantos Seguros posibles, ¿cómo escoger el que mejor va contigo? y ¿cómo saber qué necesitas para contratarlo?
          Te tenemos la solución. Con inter.mx, cotizas y comparas entre las mejores aseguradoras del país en menos de 5 minutos. Y lo mejor de todo… ¡te puedes ahorrar hasta un 35% en tu Seguro de Coche! 
          ¡Sigue leyendo! Te lo contamos todo.`,
        },
        {
          header: `El Seguro de Auto más cool que podrás encontrar`,
          body: `Lo barato sale caro, es importantísimo que no te vayas por la primera opción que encuentres, ni por el más barato sin checar lo que te cubre.
          Pero entonces, ¿cómo elijo un Seguro para mi coche? ¡Fácil! El mejor Seguro de Auto más cool siempre será el que mejor se adapte a ti. Pregúntate por tus prioridades, ¿qué necesitas de un Seguro? ¿Qué te gustaría que te cubriera?
          ¡Conoce las coberturas que puedes encontrar en inter.mx!
          <>¿Qué considerar para contratar el mejor seguro de auto para ti?</>`,
        },
        {
          header: `Todo lo que tu Seguro de Coche te podría cubrir`,
          body: `No todos los seguros tienen las mismas coberturas. Por eso es súper importante que cheques todas las opciones y encuentres la que mejor se adapte a tus necesidades. 
          En inter.mx puedes encontrar tres niveles de protección con coberturas diferentes. Aquí te las presentamos:`,
          list: [`PLAN AMPLIO`],
        },
        {
          header: ``,
          body: `Éste es el plan más completo incluye todas las coberturas: Responsabilidad Civil, Gastos Médicos, Defensa Legal, Asistencia en Viajes, Robo Total y Daños Materiales.`,
          list: [`PLAN LIMITADO`],
        },
        {
          header: ``,
          body: `Éste es el segundo nivel de protección. Un plan como éste te cubrirá todo lo del Plan Amplio, excepto por Daños Materiales.`,
          list: [`PLAN BÁSICO`],
        },
        {
          header: ``,
          body: `Por último, éste es el plan con menos coberturas. Sin embargo, te protegerá todo lo del Plan Amplio, excepto por Robo Total y Daños Materiales.`,
        },
        {
          header: `
          `,
          body: `Sin importar el nivel de protección que decidas para tu coche, ¡en inter.mx segurísimo encuentras tu Seguro de Auto ideal al mejor precio! 
          <>¿Qué información necesitas para cotizar un seguro de auto?</>`,
        },
        {
          header: `Compara en un sólo lugar: inter.mx`,
          body: `¿Ya conoces <>Coche Seguro</>? Es nuestro súper comparador de seguros, que te ayudará a encontrar el mejor Seguro de Auto. 

          Con sólo unos cuantos datos –tu nombre, edad, sexo de nacimiento, código postal y el modelo de tu auto–, Coche Seguro hace magia para mostrarte las mejores opciones y podrás ahorrarte hasta 35% en tu Seguro ideal
          Todo esto en menos de cinco minutos. Suena increíble, ¿no?`,
        },
        {
          header: `¿¡Qué esperas!?`,
          body: `Entra ahora mismo a inter.mx y encuentra el Seguro de Auto perfecto para ti. 
          ¿Tienes más dudas sobre Seguros? ¡Recuerda! En nuestro blog, seguro, seguro, encontrarás artículos que te ayudarán a conocer todo sobre el mundo de los seguros. Y, si no, ¡contáctanos! 
          En inter.mx estamos para quitarle lo complicado a los seguros. 
          ¡Síguenos en <>redes sociales</> para conocer promociones y beneficios que tenemos para ti!`,
        },
      ],
    },
    {
      img: BG84,
      date: "18 Julio 2022",
      autor: "adminInter",
      title: "¿Un Seguro Médico cubre cirugías inmediatas?",
      body: "Hay cirugías de emergencia que claramente no se pueden planear con anticipación, ¿tu seguro te las cubre? ¡Aquí te lo decimos!",
      content: [
        {
          header: ``,
          body: `Tal vez hayas escuchado que para que tu Seguro Médico te cubra una cirugía la tienes que programar con tiempo. ¿Pero qué pasa con las cirugías que no pueden esperar? ¿Te puede cubrir tu seguro en casos de cirugías inmediatas? Si esta pregunta no te deja dormir por las noches, ¡llegaste al lugar correcto!
          Aquí vamos a resolver todas tus dudas.
          ¡Sigue leyendo!`,
        },
        {
          header: `¿Cirugías inmediatas?`,
          body: `¿Qué son “cirugías inmediatas”?  Son procedimientos quirúrgicos que tienen que hacerse con urgencia. Por ejemplo, si tienes un accidente, una apendicitis tremenda o piedritas en los riñones –¡tocamos madera!–, es casi seguro que tengas que someterte a una cirugía que no marcaste en tu calendario.
          Ahora viene lo interesante, si tu seguro te cubre la cirugía o no dependerá del famosísimo periodo de espera. 
          <>¿Cuáles son las exclusiones de un seguro de gastos médicos?</>`,
        },
        {
          header: `¿¡Periodo de espera!?`,
          body: `¡Paciencia! Aquí te contamos qué es el periodo de espera. Es el tiempo que tiene que pasar desde que contrataste tu seguro para que la aseguradora pueda cubrirte ciertos padecimientos. 
          Generalmente, no existe periodo de espera cuando se trata de una cirugía inmediata por un accidente. En el caso de cirugías por enfermedades, el tiempo necesario puede ser de hasta dos años. ¡Ojo! Los tiempos de espera varían entre aseguradoras, así que es súper mega importante que cheques bien tu póliza para conocer mejor tu seguro.   
          <>¿Los seguros médicos cubren enfermedades preexistentes?</>`,
        },
        {
          header: `¿Todavía no tienes Seguro Médico?`,
          body: `Tener un Seguro Médico puede ser una ayudadota si llegas a necesitar una cirugía, sea inmediata o no.
          En <>inter.mx</> puedes encontrar un Seguro Médico justo a tu medida. Cotiza y compara entre las mejores aseguradoras, elige el nivel de protección y el tipo de seguro que mejor vaya contigo, agrega todas las coberturas adicionales que necesites, y contrata. ¡Todo en el mismo lugar! Fácil, rápido y seguro. Está buenísimo, ¿no?

          
          <>¡Quiero contratar un seguro médico para mi familia!</>


          En temas de salud, lo ideal es siempre prevenir y anticiparse a cualquier inconveniente. ¡Entra ya a <>inter.mx</> y cotiza hoy mismo!



          Y, recuerda: si tienes cualquier otra duda, puedes ir de volada a nuestro <>blog</> o ponerte en contacto con nosotros súper fácil y rápido en nuestras <>redes sociales</>. ¡Síguenos!`,
        },
      ],
    },
    {
      img: BG85,
      date: "18 Julio 2022",
      autor: "adminInter",
      title: "¿Puedo asegurar un coche sin placas?",
      body: "¿Te urge asegurar tu auto, pero aún no tiene placas? ¡Entérate aquí si puedes protegerlo!",
      content: [
        {
          header: ``,
          body: `Vas saliendo de la agencia con tu auto nuevecito, impecable, aún sin placas, y no quieres que naditita lo toque… seguramente estarás pensando en cuánto te uuuurge proteger tu coche, ¿no? ¡Sigue leyendo! Te contamos si puedes asegurar un coche sin placas.`,
        },
        {
          header: `Mini lección sobre Seguros de Coche en México`,
          body: `Para llegar al asunto que realmente nos interesa, primero dejemos una cosa clara. En México es importantísimo que toda compañía de seguros esté registrada ante la Comisión Nacional de Seguros y Fianzas para evitar malas jugadas.
          Así también, para que nadie vaya a pasarse de list@, la Secretaría de Movilidad no permite que se ande por ahí sin placas. Por lo mismo, ninguna compañía de seguros puede proteger tu coche si aún no tienes tus placas registradas como se debe.
          ¡Así de fácil! Una aseguradora registrada, nunca te asegurará un coche sin placas. 
          <>Seguro para auto: ¿americano, chocolate o con título salvaje?</>`,
        },
        {
          header: `¡Tu Seguro de Coche perfecto te está esperando!`,
          body: `En inter.mx, sí o sí encontrarás el seguro ideal para tu coche al mejor precio. Cotiza, compara y elige fácil, rápido y seguro. Además, en inter.mx puedes ahorrar hasta un 35% en tu Seguro de Coche. Suena increíble, ¿no? ¡Así es inter.mx!
          Entonces, cuando ya tengas las placas nuevas bien pegadas a tu auto, ¡entra a <>inter.mx</> y contrata tu Seguro de Coche en un tap tap!

          
          <>Seguro de coche: ¿tengo que ir a una oficina para contratar?</>



          ¿Más dudas? Checa el <>blog</> y cuéntanos qué te parece en nuestras <>redes sociales</>. ¡Síguenos y no te pierdas de nada!`,
        },
      ],
    },
    {
      img: BG86,
      date: "18 Julio 2022",
      autor: "adminInter",
      title: "¿Puedo asegurar mi auto si soy socio conductor de una app?",
      body: "¿Eres socio conductor de una app de transporte? ¡Entérate aquí si puedes proteger tu coche!",
      content: [
        {
          header: ``,
          body: `Contar con un Seguro de Auto definitivamente es una gran tranquilidad. Como socio conductor de una app, cuentas con un seguro que sólo te cubre a partir de que aceptas e inicias viaje con un pasajero. Pero, ¿qué pasa cuando apagas la app? Ahí es donde entramos nosotros. 
          Si tú eres socio conductor de una app y te estás preguntando si allá afuera hay un Seguro perfecto para ti, este artículo seguro, seguro te interesa. 
          ¡Sigue leyendo y entérate de todo!`,
        },
        {
          header: `¿Un Seguro de Auto ideal para socios conductores?`,
          body: `Manejar un coche como socio conductor de una app no es cualquier cosa. Sabemos que todos los días te enfrentas al tráfico, a la responsabilidad de llevar y traer a otras personas en tu coche, a la prisa y el estrés contagioso de tus pasajeros, por horas y horas. 
          Un socio conductor definitivamente no es un conductor cualquiera. Por eso, necesitas un Seguro de Auto pensado especialmente para ti.
          En inter.mx, no hay nada es más importante para nosotros que proteger a cuantas personas sea posible. Por eso, uniendo fuerzas con AXA, hemos definido dos planes ideales para socios conductores como tú: Plan Amplio de Coche Seguro con AXA y Protect APP. 
          <>¡Tips para tener seguridad al volante!</>`,
        },
        {
          header: `Coche Seguro Plan AMPLIO y Protect APP`,
          body: `Tanto o más que cualquier otro conductor de México, como socio conductor de una app es indispensable contar con un Seguro de Coche para poder circular. Así lo recomienda el Reglamento de Tránsito. 
          Si eres socio conductor de la app de UBER, el Plan Amplio de Coche Seguro con AXA es lo que estás buscando para estar seguro, seguro siempre. 
          Si eres socio conductor de otras apps como Cabify, Easy Taxi, entre otras, tú necesitas Protect APP. Sin importar si vas con o sin pasajeros, este plan te protege en cada momento. 
          <>Situaciones en las que agradecerás tener un seguro para coches</>`,
        },
        {
          header: `¿Qué me cubren estos planes para Socios Conductores?`,
          body: `Con inter.mx + AXA, tu coche estará seguro, seguro todo el tiempo, incluso estando estacionado. Éstas son algunas de las coberturas que tenemos para ti:`,
          list: [
            `Responsabilidad civil por daños a terceros`,
            `Defensa legal`,
            `Daños materiales`,
            `Robo total`,
            `Gastos médicos`,
            `Servicios de asistencia`,
            `Accidentes automovilísticos al conductor`,
          ],
        },
        {
          header: ``,
          body: `Buenísimo, ¿no? 
          Con un Seguro de Auto como Plan Amplio de AXA o Protect APP, tu coche, tus pasajeros y tú pueden ir súper tranquilos a donde sea que vayan, sabiendo que con inter.mx siempre estarán bien protegidos.
          <>Seguro para auto | 10 cosas que debes hacer en un choque</>
          

          ¿Qué esperas? Entra a <>inter.mx</>, cotiza y contrata hoy mismo tu Seguro de Coche perfecto. ¡Es súper fácil y rápido!
          
          Si tienes alguna duda, ¡contáctanos! Nuestra pasión es quitarle lo complicado a los seguros. Y no olvides seguirnos en <>redes sociales</> para que podamos estar bien cerquita de ti.`,
        },
      ],
    },
    {
      img: BG75,
      date: "Junio 2022",
      autor: "adminInter",
      title: "¿Existe un Seguro de Coche ideal para conductores de apps?",
      body: "Existe un seguro especial si chambeas como socio conductor de una app. ¿No sabías? Tu bróker de seguros favorito te lo contará todo. ¡Entra aquí!",
      content: [
        {
          header: ``,
          body: `¿Eres socio conductor en una app de transporte privado? ¡Entonces esta info es ideal para ti! Y es que, por si aún no lo sabías, existe un seguro de coche especializado en cubrir vehículos que se usan como transporte para pasajeros. 
          ¿Te gustaría saber más del tema? ¡Aquí te lo contamos tooodo! Porque ya lo sabes: en <>inter.mx</> nos encanta quitarle lo complicado a los seguros.`,
        },
        {
          header: `¿Qué seguro de coche conviene más para socios conductores?`,
          body: `¡En <>inter.mx</> nos preocupamos por ti! Por eso, junto a AXA Seguros, diseñamos un producto completísimo para proteger a socios conductores como tú. Así como suena: un seguro de coche a tu medida.`,
        },
        {
          header: `Protect App`,
          body: `Este súper servicio te cubre mientras llevas pasajeros, obvio, y también cuando tienes la app apagada. Así vayas al súper, a recoger a tus hij@s o de vacas, este seguro te mantiene con la misma protección.
          Además, incluye una lista de coberturas indispensables para todo automovilista en este país: `,
          list: [
            `Robo total`,
            `Gastos médicos`,
            `Responsabilidad civil`,
            `Servicios de asistencia`,
          ],
        },
        {
          header: ``,
          body: `Con esta cobertura, socios conductores como Cabify, Beat, Didi y demás aplicaciones estarán perfectamente protegidos; aun si las usas al mismo tiempo. Buenísimo, ¿no?`,
        },
        {
          header: `Beneficios adicionales de Protect App`,
          body: `Además de toda la explicación que ya te dimos, este producto te permitirá contar con un par de beneficios que vas a disfrutar y mucho. Checa los plus que te ofrece exclusivamente Protect App:`,
          list: [
            `10% en daños materiales`,
            `20% por robo total`,
            `50 UMA en responsabilidad por daños a terceros`,
            `No se pagan deducibles por daños a viajeros`,
          ],
        },
        {
          header: `¡Encuentra tu seguro ideal en inter.mx!`,
          body: `¡Lo importante es que estés bieeen asegurad@! Contáctanos por si tienes más dudas o para contratar al instante el seguro de coche que mejor se adapte a tus necesidades. 
          ¡Con <>inter.mx</> asegurarte es facilísimo y rapidísimo!
          Síguenos en <>Instagram</> y <>Facebook</> para mantenerte al tanto de nuestros productos, promociones y novedades.`,
        },
      ],
    },
    {
      img: BG76,
      date: "18 Junio 2022",
      autor: "adminInter",
      title: "¿Hay límite de edad en un seguro para mascotas?",
      body: "Un seguro para mascotas no varía según la edad del animal, pero sí existe un límite a la hora de protegerlo. ¡Lee esta guía para contratar uno!",
      content: [
        {
          header: ``,
          body: `¿Mascotas? Hoy por hoy, nuestros lomitos o michis son mucho más que animalitos de compañía. Son nuestros perrhijos o gathijos, a quienes les damos tooooodo nuestro cariño y nuestro cuidado. Es por eso que en <>inter.mx</> te ofrecemos un seguro para mascotas que protege a tus pequeñines peludos y te ayuda a cuidar tus finanzas. 
          
          ¡Un lomito seguro es un lomito feliz! Lo mismo con los michis. Checa lo que debe cumplir tu amiguito de cuatro patitas para poder asegurarlo y conoce nuestra variedad de planes para ellos:`,
        },
        {
          header: `¿Cambia el costo del seguro para mascota dependiendo de los años?`,
          body: `¡Nop, en lo absoluto! Puedes contratar un Seguro de Mascota para proteger a tu peludito a partir de que cumpla los 2 meses y hasta sus 9 años de vida..  Y sin importar su edad, el costo de su seguro seguirá siendo el mismo. `,
        },
        {
          header: `¿Qué necesita tu lomito o michi para poder asegurar? `,
          body: `¡Es súper sencillo! Estos son los requisitos para poder asegurar a tu mejor amigo:`,
          list: [
            `Que tenga entre 2 meses a 9 años de edad`,
            `No debe realizar actividades de búsqueda o de rescate, ni ser un trotamundos ni un explorador`,
            `Que vivan exclusivamente en casita`,
          ],
        },
        {
          header: `Tipos de seguros de mascotas`,
          body: `En inter.mx existen tres diferentes niveles de cobertura que sólo varían en el monto de la suma asegurada al año (y en el costo, obvio). Puedes escoger un seguro para mascota anual desde $10,000 y hasta $30,000, que protegerá a tu peludo en caso de que se ponga malito, tenga un accidente, haga una travesura o estire la patita (ojalá nuestros pequeñines fueran eternos, ¿no?). 
          Además, todos los planes incluyen un montón de beneficios adicionales para que tu mejor amigo siempre esté al cien.
          Entra a <>inter.mx</> y checa todos los planes y las coberturas que <>Mascota Segura</> tiene para tu cuatropatitas.
          
          ¡No esperes más y protégelo hoy mismo! 
          ¡Síguenos en <>Instagram</> y <>Facebook</> para conocer más sobre las ventajas y beneficios que tenemos para ti!`,
        },
      ],
    },
    {
      img: BG77,
      date: "18 Junio 2022",
      autor: "adminInter",
      title: "¿Cualquier Seguro de Auto me Cubre por Daños a Terceros?",
      body: "Hay muchas maneras de cuidar tu coche, pero la mejor opción es contratando un Seguro de Auto. Con inter.mx tienes una protección total. ¡Cotiza ahora!",
      content: [
        {
          header: ``,
          body: `Respuesta rápida: ¡sí! Genial, ¿no? De hecho, ahora que estamos en el tema, hay algunos puntos sobre un seguro de auto y daños a terceros que es súper importante que conozcas. Te lo contamos rapidísimo, al estilo de <>inter.mx</>, ¡sigue leyendo!`,
        },
        {
          header: `¿Daños a terceros? ¡Tu seguro de auto te saca de aprietos!`,
          body: `¿Chocaste contra otro coche y le metiste un mega rayón, un chipote, le rompiste un cristal, un faro o lastimaste a alguien y necesita atención médica? (obvio, esperemos que nuuunca te pase) ¡Tranqui! Tu seguro de auto te echa la mano. 
          Es más, ¡Cualquier seguro! Por ley toda póliza de carros debe contar con esta cobertura: Responsabilidad Civil. Piénsalo como un bonus para asegurar tu tranquilidad, tu dinero y la protección de los demás: ultra súper esencial, ¿no te parece?`,
        },
        {
          header: `¿Por qué es taaaan importante cubrir los daños a terceros?`,
          body: `Primeramente, en un accidente nadie quiere causarle daño a otro, ¿cierto? Por eso se llama “accidente”. Peeero además de eso, está el pequeño asunto de las multitas de hasta $4,000 que te podrían tocar pagar si no cuentas con seguro y cobertura de Responsabilidad Civil. 
          ¡Ese sustote no lo quiere nadie! Y es cosa de todos los días: ¡Más vale estar prevenido con un seguro de auto! Además, no sólo son las multas, los daños más comunes son las abolladuras, cuya reparación puede costar alrededor de… ¡$15,000!
          ¡Que Dios nos agarre asegurados!`,
        },
        {
          header: `¡Contrata tu seguro de auto con inter.mx!`,
          body: `¡Bajo advertencia no hay engaño! Asegura tu coche con nosotros, encuentra tu seguro ideal al mejor precio y protégete en un ¡tap tap! 
          Nuestros seguros tienen beneficios exclusivos, como la cobertura por daños a terceros con un coche prestado o rentado, la reposición de llaves perdidas o la asistencia en caso de que pierdas tu ticket de estacionamiento. Está buenísimo, ¿no? 
          ¡No esperes más y contrata el seguro de auto que más se adecue a tus necesidades con <>inter.mx</>!

          Síguenos en nuestras redes sociales <>Facebook</>, <>Instagram</> y <>Youtube</>.`,
        },
      ],
    },
    {
      img: BG78,
      date: "18 Junio 2022",
      autor: "adminInter",
      title: "¿Mi Seguro Médico me cubre si me embarazo?",
      body: "¿Estás pensando en un embarazo y no sabes qué onda con tu seguro médico? ¡No te preocupes! En inter.mx tenemos una cobertura para ti. ¡Ingresa!",
      content: [
        {
          header: ``,
          body: `¡Queremos que tu espera sea lo más increíble del mundo y que no te estreses por nada! Y, ¿qué mejor manera de estar tranquila que asegurar tu salud y la de tu bebé con un seguro médico? En inter.mx tenemos justo lo que necesitas. ¡Sigue leyendo y conoce todas nuestras coberturas para tu embarazo!`,
        },
        {
          header: `Entonces, si me embarazo… ¿mi Seguro Médico sí me echará la mano?`,
          body: `¡Sí! Por supuesto que sí. Mientras hayas hecho tu contratación con el suficiente tiempo de anticipación que piden las aseguradoras (6 meses más o menos), tu seguro médico te apoyará en un montón de cosas. ¡Te contamos con lujo de detalle!`,
        },
        {
          header: `Visitas médicas`,
          body: `Durante tu embarazo, seguro visitarás un montón de consultorios para controles de salud, tanto para ti como para tu bebé. Y eso definitivamente no es barato… ¡Deja que tu cobertura de salud te eche una manita! Recuerda que proteger tu salud y la de tu bebé, también te ayudará a cuidar tus finanzas.   
          ¡Y eso no es todo! Un seguro médico también te puede apoyar en tooodo esto:`,
          list: [
            `Recuperación posparto`,
            `Cargos de hospitalización`,
            `Exámenes posteriores a tu embarazo`,
            `Servicio de ambulancia para emergencias (¡tocamos madera!)`,
            `¡Y más!`,
          ],
        },
        {
          header: `Después del nacimiento`,
          body: `Con tu póliza de gastos médicos puedes acceder también a una cobertura para tu recién nacid@. Ésta es en definitiva una gran ventaja frente a imprevistos. Obvio deseamos que nunca tengas que hacer uso de esta cobertura, pero nada es más importante que estar bien protegidos, ¿no crees?`,
        },
        {
          header: `¡Disfruta tu embarazo!`,
          body: `No hay mayor tranquilidad que contar con un buen seguro médico al momento de dar a luz. Ya sea antes, durante o después del nacimiento de tu bebé, tienes acceso a una red hospitalaria con la mejor atención médica. Cuando contratas tu póliza, tú eliges el nivel de protección que mejor va contigo y qué hospitales te laten más..
          Además, un seguro de gastos médicos tiene una disponibilidad de horarios súper amplia para agendar citas médicas en cualquier momento. Así de veras te sentirás protegida sabiendo que cuentas con una opción siempre a la mano.
          ¡Con inter.mx, seguro, seguro tu familia estará bien protegida! Cotiza hoy mismo tu seguro médico en <>inter.mx</> y contrata el que mejor vaya contigo y tu familia.


          ¿Más contenido top? No olvides seguirnos en <>Facebook</>, <>Youtube</> y <>Linkedin</>.`,
        },
      ],
    },
    {
      img: BG79,
      date: "18 Junio 2022",
      autor: "adminInter",
      title: "Cotizador de Seguros: ¿de veras es rapidísimo?",
      body: "¿Quieres oír algo lindo? En inter.mx tenemos el cotizador de seguros más rápido de tooodo México. ¡Sí, así como suena! ¡Tap tap y listo!",
      content: [
        {
          header: ``,
          body: `Qué sensación más cool que un precio se ajuste perfecto a tus finanzas, ¿no crees?  ¿No sería increíble que también sucediera con tu Seguro de Gastos Médicos o Seguro de Coche? ¡Pues con nuestro cotizador de seguros sí es posible! Además, es súper fácil y rápido. ¡Cotiza tu seguro en un tap tap! 
          Recuerda que si te proteges tú, también cuidas de tu bolsillo. ¡Sigue leyendo!`,
        },
        {
          header: `Entonces… ¿qué tan rápido es el cotizador de seguros?`,
          body: `¡Rapidísimo!! Nosotros hemos logrado hacer contrataciones completitas hasta en 2 o 3 minutos.. ¡En serio! Inténtalo en nuestro sitio web. Tienes todo para hacerlo. Es cuestión de entrar a <>inter.mx</> y elegir tu propio camino según el seguro que tengas en mente. ¡Nuestro cotizador de seguros es más rápido que el mismísimo Checo Pérez! `,
        },
        {
          header: `¿Pero cómo logramos tanta velocidad?`,
          body: `Odiamos tener un montón de montañas de papeles en la oficina. Y seguro te pasa lo mismo, ¿no? ¡Qué horror! Por eso, creemos que es muuucho más fácil y rápido que nos pases tu info desde nuestro sitio web –con plena privacidad y seguridad, obvio–.
          Gracias a la súper tecnología de nuestra página (sí, somos súper nerds) hemos logrado que tooodas nuestras cotizaciones sean rapidísimas, que nada se atore en el camino y que todo sea lo más cómodo posible para ti (y también para nosotros) ¿Qué crees? ¡Win-win! ¡Así también le quitamos lo complicado a los seguros!`,
        },
        {
          header: `Con inter.mx, ¡seguro, seguro!`,
          body: `¿Escuchas ese motor que ruge? No, no es el carro fórmula 1 del Checo, es nuestro cotizador de seguros entrando en acción. Por si no quedó claro, te lo repetimos. 
          Ingresa a <>inter.mx</>, elige el tipo de seguro que más te lata, llena unos cuantos datos y ¡listo! ¡Así de fácil! 

          Síguenos en <>Facebook</>, <>Instagram</> o nuestro <>blog</> para enterarte de todititas las promos, los beneficios y las noticias que tenemos para ti.`,
        },
      ],
    },
    {
      img: BG80,
      date: "18 Junio 2022",
      autor: "adminInter",
      title: "Seguro de Coche: ¿Tengo que ir a una oficina para contratar?",
      body: "Las oficinas quedaron en el pasado. ¡Qué delicia hacer tus trámites en un Tap Tap! Cotiza y contrata tu Seguro de Coche súuuper fácil. ¡Descubre cómo!",
      content: [
        {
          header: ``,
          body: `Tener que salir de casa para contratar un seguro de coche ya no va con nuestro estilo de vida. En <>inter.mx</> sabemos que amas resolver toooodo desde tu cel y sin complicaciones. Por eso, te ofrecemos cotizaciones y contrataciones 100% en línea, ¡para el seguro que tú quieras!`,
        },
        {
          header: `¿Oficina de seguros? ¡Mejor un portal web para adquirir un Seguro de Coche!`,
          body: `Desde la comodidad de tu sillón y con nuestro increíble cotizador de seguros, en <>inter.mx</> podrás encontrar, cotizar y contratar facilísimo el seguro de coche que más te lata. “Tap, tap” ¡y ya cotizaste! Así de fácil y rápido.`,
        },
        {
          header: `¡Nada mejor que viajar seguro en tu coche!`,
          body: `Imagina que estás yendo a firmar tu nueva póliza y en el camino le das un golpecito al carro. ¿Sería insólito verdad? Y no solo eso: te puede salir caro. Más si no tienes el coche asegurado. No queremos que te pase jamás, obvio, pero nunca se sabe. Por eso, mejor anticiparse y llevársela más tranqui, ¿no? 
          Además de todo un seguro de coche es una súper garantía para tu tranquilidad. Puede cubrirte en todo tipo de situaciones por las que realmente no quieres pasar sin protección (como un robo total o si por accidente ocasionas daños a terceros).  Con inter.mx te cubres rapidísimo, sin moverte de casa. ¡Un mega alivio!
          Claro, todo depende de la cobertura que tú elijas. ¡Checa nuestros planes y coberturas <>aquí!</>`,
        },
        {
          header: `Más seguros en un “tap tap”`,
          body: `¡Protegemos todo lo más importante de tu vida! Échale un ojo a todos nuestros seguros. Y ¡sí!, si tienes una moto en lugar de coche y al leer este artículo te estás preguntando si tenemos Seguros para Moto, la respuesta es ¡claro que sí! 
          Éstos son algunos de los seguros que puedes encontrar en inter.mx, además de un seguro de coche:`,
          list: [
            `Médico Seguro`,
            `Mascota Segura`,
            `Moto Segura`,
            `Viaja Seguro (Vigo)`,
            `Bien Seguro`,
          ],
        },
        {
          header: ``,
          body: `Encontrar lo que estás buscando en nuestra página. ¡Ya lo sabes! Estés donde estés: en tu coche o en casa, en el sillón, en la camita o en la regadera, puedes cotizar y contratar un seguro en <>inter.mx</>. 
          
          Si quieres conocernos más y que estemos siempre conectados, síguenos en <>Facebook</> e <>Instagram</>. Allí podrás informarte sobre tooodo lo que necesites saber de tus seguros para que sea súper fácil usarlos.`,
        },
      ],
    },
    {
      img: BG81,
      date: "18 Junio 2022",
      autor: "adminInter",
      title: "Seguro para mascotas: ¿solamente incluye Perros y Gatos?",
      body: "Te contamos todo sobre tu seguro para mascotas, para que sepas qué animalitos sí puede cubrir. ¡Entra aquí para saber bien cuáles son!",
      content: [
        {
          header: ``,
          body: `Cuando hablamos de un seguro para mascotas lo primero que nos viene a la mente son lomitos y gatitos, ¿no?. ¿Pero éstas son tooooodas las mascotas que existen? Claro que no. 
          Muchas personas (al igual que tú, suponemos) eligen otro tipo de peludos o compañeros de vida, como serpientes, tortugas, peces tropicales, hurones, etc. La tenencia de algunos animales es considerada ilegal por ley, pero no vamos a meternos en estos temas hoy. Sólo hablaremos de cuáles pueden ser asegurados y cuáles no.
          <>¡Aprende las leyes de la selva con inter.mx!</>`,
        },
        {
          header: `¿Qué animales pueden tener un Seguro para Mascotas?`,
          body: `Según nuestros amigos aseguradores de <>GMX</>, no todos los animales pueden acceder a un seguro para mascotas. Es un beneficio exclusivo para algunas especies… y ya las mencionamos más arriba… ¡Qué suspenso!`,
        },
        {
          header: `¿Perros?`,
          body: `¡Por supuesto que sí! Los lomitos son siempre aceptados por las aseguradoras, a menos que estén muy avanzados en edad, sean perritos chambeadores o exploradores. Si todavía no tienes cubierto a tu peludo, ¿qué esperas? Te lo agradecerá moviendo la cola como loquito.`,
        },
        {
          header: `¿Gatos?`,
          body: `Los michis también pueden tener un seguro para mascotas. Estos animalitos con alma de dioses que nos miran con orgullo claro que pueden estar protegidos. No esperes más, ¡con un Seguro de Mascota, seguro, seguro se sentirá aún más importante!`,
        },
        {
          header: `¿Animales exóticos?`,
          body: `¡Lamentamos decirte que no! En general, las coberturas suelen proteger mayormente a perros y gatos.`,
        },
        {
          header: `Beneficios peludos`,
          body: `Debes estar saltando de alegría porque puedes contratar un seguro para mascotas para tus perrhijos y gathijos. Te contamos toooodos los beneficios con los que contarás:`,
          list: [
            `Protección contra enfermedades`,
            `Cobertura contra accidentes`,
            `Apoyo en eutanasia y gastos funerarios cuando sea necesario`,
            `Y un montón de beneficios adicionales para que tu peludo siempre esté al cien`,
          ],
        },
        {
          header: `Protege a tu mascota con inter.mx`,
          body: `En <>inter.mx</> queremos darle a tu mejor amigo la mejor protección. Sabemos que los centennials (y otras generaciones también) aman a sus perros/gatos como si fueran sus hijos. Te ofrecemos todo lo que necesitas para proteger a tus seres queridos en todo momento. Y eso incluye a tus mascotas.

          Ellas nos hacen pasear por el parque, respirar aire puro o reír cuando deberíamos enojarnos. ¡Se merecen estar bien protegidas!
          En <>inter.mx</> seguro, seguro encuentras el mejor seguro para tu mascota.
          
          ¡No olvides seguirnos en nuestros perfiles de <>Instagram</> y <>Facebook</>!`,
        },
      ],
    },
    {
      img: BG82,
      date: "18 Junio 2022",
      autor: "adminInter",
      title: "¿Dónde puedo Cotizar un Seguro de Moto? ¡Entra a inter.mx!",
      body: "En inter.mx puedes Cotizar un Seguro para tu Moto con los mejores beneficios y a un excelente precio. ¡No esperes más y arma tu plan aquí!",
      content: [
        {
          header: ``,
          body: `Que prefieras andar en moto no tiene nada de malo: te ahorras un montón de tiempo en el tráfico, llegas más rápido a tus destinos y puedes aventarte un road trip tú solo en corto. Además, ¡te ves super cool! Pero ooobvio, para disfrutar de todo esto hay que cotizar un seguro, seguro. ¿Tú ya lo estás? 
          Si no, lee aquí todo lo que tienes que saber sobre Seguros de Moto y contrata en un tap tap en <>inter.mx</>.`,
        },
        {
          header: `¿Por qué debes sí o sí cotizar un seguro para motos?`,
          body: `¡Más que nada, por tu propia seguridad! Y las leyes de tránsito están de acuerdo. Por eso es recomendable cotizar un seguro que cubra, por lo menos, responsabilidad civil. ¡Tener seguro es una tranquilidad para tod@s!
          Pero claaaro, en <>inter.mx</> no sólo te damos esa cobertura básica. Ésos son otros beneficios que te ofrecemos al contratar tu Seguro de Moto:`,
          list: [
            `Daños materiales`,
            `Gastos médicos `,
            `Robo total`,
            `Asistencia en viajes`,
            `¡Y más!`,
          ],
        },
        {
          header: ``,
          body: `Está de pelos, ¿no? Tenlos presentes porque son esas cositas las que luego te dan tranquilidad. No olvides que todo es a tu medida: tú escoges el nivel de cobertura que más te convenga y las coberturas que más te latan. 
          Con el cotizador online de <>inter.mx</> puedes comparar nuestra variedad de seguros y elegir el que mejor se adapte a tus necesidades.`,
        },
        {
          header: `¿Por qué debo contratar mi seguro con inter.mx?`,
          body: `Con <>inter.mx</>, cotizar un seguro de Moto o cualquier otro es una experiencia personalizada, y contratar es súuuuper fácil y rápido. ¡Nos enorgullecemos de darte el mejor servicio que podrías desear! Estamos aliados con las mejores aseguradoras del país, por lo que puedes encontrar precios increíbles y coberturas más que accesibles. 

          Además, ¡tienes tu seguro siempre a la mano con nuestra app! 
          No esperes más y cotiza tu seguro en <>inter.mx</> o contactanos por <>WhatsApp</>.
          
          ¡Síguenos ahora mismo en <>Facebook</>, <>Twitter</> e <>Instagram</>!`,
        },
      ],
    },
    {
      img: BG69,
      date: "Mayo 2022",
      autor: "adminInter",
      title: "¿Qué es la Responsabilidad Civil de mi Seguro de Auto?",
      body: "La responsabilidad civil es algo que tooodoo Seguro de Auto debe tener. Entérate de qué onda para que nada te tome por sorpresa. ¡Entra aquí!",
      content: [
        {
          header: ``,
          body: `¿Sabes de qué se trata la súper cobertura de Responsabilidad Civil en tu seguro de auto? ¡Es una joyita que te dará muchísima tranquilidad! Es una cosa básica para andar por las calles sin que te preocupes de nadita.
          Como en <>nter.mx</> nos encanta quitarle lo complicado a los seguros, te vamos a platicar de qué se trata, por qué es taaaaan importante y qué es tooodooo lo que te cubre. Porque al final eso es lo que realmente importa, de qué manera tu seguro te va a facilitar la vida, ¿no crees?. ¡Sigue leyendo, porque esto está muy bueno!
          
          <>¡Ser responsable es súper fácil con inter.mx!</>`,
        },
        {
          header: `¿Qué es eso de la responsabilidad civil?`,
          body: `¡Easy-peasy! La responsabilidad civil es una cobertura que, sí o sí, tu seguro de auto debería tener. En general, se trata de una protección muy útil en caso de que manejando causes daños a alguien o sus cosas (obvio, esperamos que nunca te pase).
          Si tienes un accidente (toquemos madera) mientras conduces tu coche, esta cobertura te cubre los daños físicos o materiales que le causes a otras personas, ya sea que vayan contigo en tu auto o no.`,
        },
        {
          header: `¿Un seguro de auto debe tener responsabilidad civil?`,
          body: `¡Súper sí! Según la Ley de Caminos, Puentes y Autotransporte Federal, esta cobertura no puede faltar en tu póliza de seguro. Es una normativa que se impuso en los Caminos Federales, pero también en la Ciudad de México. Si quieres conducir con toda tranquilidad, checa que tu seguro de coche tenga esta protección. Y si no la tiene… ¡pregúntale qué onda a tu aseguradora!`,
        },
        {
          header: `Entonces… ¿Cómo puedo tener esta cobertura?`,
          body: `En los Seguros Básicos Estandarizados normalmente está incluida, cuesta alrededor de $670 anuales y te garantiza protección por daños superiores a 150 mil pesos. Suena bien, ¿no?
          Obvio en <>inter.mx</> tenemos tu Seguro de Coche ideal con Responsabilidad Civil ya incluída, porque como somos el bróker #1 de todo México. Queremos protegerte como se debe y hacerte la vida más fácil y sin preocupaciones. 
          
          ¡Entra a nuestro sitio web y checa qué ofertas tenemos para ti!`,
        },
        {
          header: `Tu vida es más relax con inter.mx, ¡seguro seguro!`,
          body: `En <>inter.mx</> claro que no sólo queremos que cuides tu coche, sino que te muevas con la confianza de que estarás seguro, seguro a donde sea que vayas. Tener un seguro de auto en definitiva es una decisión muy sabia que te hará sentir mucho más tranqui en la vida, ¡ya verás! 
          
          Entra a <>inter.mx</> y cotiza, compara y contrata tu seguro para auto en un tap tap ¡Es rapidísimo y súuuuper fácil! 
          
          ¡Síguenos en <>Instagram</> y <>Facebook</> para que no te pierdas de nadititita de lo que tenemos para ti!    
          
          *Para mayor información sobre las coberturas pregunta a tu asesor o verifica con tu aseguradora.`,
        },
      ],
    },
    {
      img: BG70,
      date: "18 Mayo 2022",
      autor: "adminInter",
      title: "Coberturas Esenciales para el Mejor Seguro de Auto",
      body: "¿Quieres tener el mejor Seguro de Auto? Entérate aquí de las coberturas esenciales que necesitas para estar seguro, seguro al volante. ¡Entra aquí!",
      content: [
        {
          header: ``,
          body: `El mejor seguro de auto no tiene por qué ser el más caro. El mejor seguro definitivamente es aquel que cuenta con las coberturas esenciales para sacarte de  aprietos y que no te va a botar en ningún lugar, sin importar a dónde vayas. Como somos el mejor bróker de seguros, en inter.mx siempre tenemos el seguro perfectísimo para ti, ¡seguro, seguro!
          ¡Pero no nos adelantemos! Lo primerito es que conozcas de qué se trata un Seguro de Coche para que sepas cuánto puedes exigir y qué seguros se adaptan mejor a lo que tú necesitas.
          Además, queremos igualmente hablarte de los complementos que puedes agregar para proteger tu coche aún mejor en inter.mx.
          <>¡Conoce mejor el mundo de los seguros con inter.mx!</>`,
        },
        {
          header: `Entonces… ¿Qué coberturas no pueden faltarle al mejor Seguro de Auto?`,
          body: `Como ya lo dijimos, el mejor seguro de auto es el que tiene todas las coberturas esenciales para que siempre vayas seguro, seguro a todas partes. Ahora, te platicaremos a qué nos referimos con “coberturas esenciales” y te las presentaremos todas para que las conozcas y sepas qué esperar.`,
        },
        {
          header: `Y con ustedeeees….¡Las coberturas esenciales!`,
          body: `Estas coberturas son las más elementales porque son las que te cuidan a ti y a quienes te acompañan, además de tu coche, claro.`,
        },
        {
          header: `Plan Básico`,
          body: `Ésta es la versión más sencilla de Coche Seguro y esto es tooodooo lo que te cubre:`,
          list: [
            `Responsabilidad civil`,
            `Defensa Legal`,
            `Gastos Médicos`,
            `Asistencia en viajes`,
          ],
        },
        {
          header: `Plan Limitado`,
          body: `Este es un plan con más protección para tu coche, esta versión tiene todas las coberturas anteriores, más una que seguro, seguro te dará un poco más de paz: robo total.`,
        },
        {
          header: `Plan Amplio`,
          body: `¡Y chécate ésta! Este plan es el más completo para proteger tu coche aún mejor con todas las coberturas que ya te contamos, más daños materiales.`,
        },
        {
          header: `Y ahora…¡mira todos estos complementos!`,
          body: `Cualquiera de éstos planes ya tienen las coberturas esenciales para manejar tranquilamente, pero si quieres hacer tu plan aún mejor…Chécate estas coberturas que puedes agregar:`,
          list: [
            `Asistencia de “regreso a casa” en caso de necesitarlo`,
            `Boleto de estacionamiento de repuesto en caso de perder el tuyo`,
            `Llaves nuevas si te distraes y “desaparecen” las tuyasGastos Médicos`,
            `Descuentos en limpieza para sacar a pasear tu auto como nuevo siempre`,
          ],
        },
        {
          header: `En inter.mx seguro, seguro tenemos tu seguro perfecto`,
          body: `Encuentra el mejor seguro de auto súuuuper fácil y rápido. ¿No nos crees? ¡Entra a inter.mx, cotiza, compara y contrata tu Seguro de Coche hoy mismo!!
          Y para que estemos más cerquita, síguenos en <>Facebook</> e <>Instagram</> y, por supuesto, échale un ojito a nuestro <>blog</>.`,
        },
      ],
    },
    {
      img: BG71,
      date: "18 Mayo 2022",
      autor: "adminInter",
      title: "¡Quiero Contratar un Seguro Médico para mi familia!",
      body: "Hay muchas maneras de mostrarle amor a tu familia, pero definitivamente cuidándola y protegiéndola con un seguro médico es la mejor. ¡Entra aquí!",
      content: [
        {
          header: ``,
          body: `Proteger a quienes queremos es un instinto basiquísimo. Además, es una mega paz y tranquilidad saber que está protegida, ¡definitivamente!
          En México existen un montón de seguros médicos para familias, todos con distintos precios según lo que cada familia necesite y tomando en cuenta cosas como estado de salud, edad, trabajo y hábitos. 
          Las coberturas de los seguros médicos son muy amplias. Cubren desde especialidades médicas e intervenciones quirúrgicas, hasta servicios odontológicos, atención psicológica, apoyo en embarazos, en fin, ¡casi todo lo que se te ocurra para cuidar tu salud!`,
        },
        {
          header: `¿No sabes cómo elegir tu seguro médico? ¡Chécate esta guía!`,
          body: `Primero lo primero: ¿quiénes pueden entrar en la póliza de un seguro médico? ¡Fácil! Tu espos@, tus hij@s (mientras tengan menos de 25 años) y tus papás (mientras tengan menos de 64 años cuando los asegures) está bueno, ¿no?  
          Segundo ¿Qué puede cubrir tu seguro?  
          Hay un buen de coberturas, como:`,
          list: [
            `Honorarios médicos`,
            `Atención médica 24h`,
            `Cobertura dental`,
            `Consultas a domicilio`,
            `Gastos hospitalarios`,
            `Terapia intensiva`,
            `Servicio de ambulancia`,
            `¡Y más!`,
          ],
        },
        {
          header: ``,
          body: `¿Ves? ¡De que estás seguro, estás seguro! Na’más recuerda que estos servicios varían según el Seguro Médico que elijas.
          Y, por último, éstas son algunas excepciones de los Seguros Médicos familiares:`,
          list: [
            `Trastornos psiquiátricos o psicológicos`,
            `Tratamientos de fertilidad`,
            `Accidentes por causa de alcohol y drogas`,
            `Internamientos en centros que no son reconocidos como hospitales`,
          ],
        },
        {
          header: ``,
          body: `El mejor tip que te podemos dar para elegir tu seguro y tu nivel de protección es el siguiente. Piensa en las cosas que tu familia de veras necesita y los hospitales que más te laterían para tu plan de salud. ¡Y listo!`,
        },
        {
          header: `¡Ahora sí, lo más importante!: Tipos de Seguro Médico familiar en inter.mx`,
          body: ``,
        },
        {
          header: `Cobertura por padecimiento`,
          body: `Este tipo de seguro médico se enfoca en restablecer la salud. Eso significa que tienes el montonal de dinero que elijas como tu suma asegurada al contratar para cada cosa que pueda pasarte a ti o a tu familia.
          Obvio, ¡tocamos madera! Esta cobertura es buenísima. Puede echarte una súper mano en casos de enfermedades o accidentes que impliquen tratamientos más o menos largos. Suena muy bien, ¿no?`,
        },
        {
          header: `Cobertura por año póliza`,
          body: `Este otro tipo de seguro es más bien para prevención. Es decir, la suma asegurada que tú elijas al contratar puedes usarla para todo lo que necesites en un año hasta acabártela. Al siguiente año, otra vez, porque se restablece al 100% anualmente. Uff, también está buenísima, ¿no crees?`,
        },
        {
          header: `Pa’ que te convenzas: conoce los beneficios de tener un seguro médico`,
          body: `A menos de que uno sea Walter Mercado, no es posible conocer los imprevistos de nuestra salud. No hay mayor ventaja que poder correr de inmediato al especialista ante cualquier emergencia.`,
          list: [
            `Contar con atención de un médico privado es como ser paciente VIP. Cuentas con cuidados personalizados y un súper ambiente de confianza elemental en toda consulta.`,
            `¡Bye bye a las filas! Un Seguro Médico tiene una disponibilidad de horarios súper amplia para agendar citas médicas en cualquier momento. Además, sus espacios de espera son brevísimos, tanto en quirófanos como en urgencias.`,
            `Tienes acceso a tratamientos médicos mega innovadores con equipo de última tecnología, ¿qué te parece? `,
          ],
        },
        {
          header: ``,
          body: `¡Tu familia estará bien segura, seguro seguro! No hay de otra. Cotiza tu Seguro Médico en <>inter.mx</> y contrata el que mejor vaya contigo y tu familia. 
          ¿Más contenido top? No olvides seguirnos en <>Facebook</>, <>YouTube</> y <>LinkedIn</>.`,
        },
      ],
    },
    {
      img: BG72,
      date: "18 Mayo 2022",
      autor: "adminInter",
      title: "¡Contrata el Seguro de Gastos Médicos Ideal para Mamá!",
      body: "¡Mamá merece lo mejor! Contrata ahora un seguro de gastos médicos para ella. ¡Cotiza, compara y elige el mejor seguro en inter.mx!",
      content: [
        {
          header: ``,
          body: `¡Mamá siempre nos cuidó, es hora de cuidarla a ella! Con un seguro de gastos médicos podrás estar tranquilo de que recibirá la mejor atención, con tecnología de punta y especialidades en diferentes áreas.
          Disfruta al máximo los momentos con mamá teniendo la certeza de que estará protegida ante cualquier eventualidad. En <>inter.mx</> te ayudamos a contratar el seguro ideal para ella, que se ajuste a sus necesidades y al bolsillo familiar.`,
        },
        {
          header: `¿Por qué tener un seguro de gastos médicos?`,
          body: `Los gastos médicos son bastante costosos, así se trate de procedimientos simples, la infraestructura y el talento humano de la salud tienen un alto costo. Por eso, tener un seguro de gastos médicos es una excelente opción para prevenir un desajuste económico en tu familia en caso de una eventualidad médica.
          Los seguros de gastos médicos cubren diferentes servicios que quizá no habías contemplado. De esta manera, no te llevarás sorpresas que puedan afectar tus finanzas.`,
        },
        {
          header: `Complementa otros tipos de seguro con un seguro de gastos médicos`,
          body: `La ventaja de un seguro de gastos médicos es que lo puedes combinar con otros seguros, por ejemplo, un seguro de vida. Así podrás disfrutar de una cobertura más completa que te ayudará a prevenir una crisis en caso de un evento no planeado. 
          En inter.mx te aconsejamos adquirir tu paquete de seguros en un solo lugar. Te ofrecemos seguros para proteger tu coche, bienes, salud, mascotas y viajes. Así, te cuidamos en todo momento y en cualquier circunstancia sin que te compliques.`,
        },
        {
          header: `Ventajas de contratar tu seguro de gastos médicos con inter.mx`,
          body: `En <>inter.mx</> te ofrecemos planes individuales o familiares para obtener un seguro médico. Además, contamos con tres tipos de cobertura que te brindarán diferentes beneficios dependiendo de lo que necesites para estar seguro.
          Tenemos disponibles los planes:`,
          list: [`Esencial`, `Protegido`, `Blindado`],
        },
        {
          header: ``,
          body: `Cada uno ofrece coberturas diferentes según las necesidades y posibilidades de cada persona. Así que puedes elegir el plan más conveniente para proteger a mamá. 
          Para saber cuál plan es el mejor para tí, realiza ahora una cotización a través de nuestra nueva herramienta “Médico Seguro”. ¡Con tan solo unos cuantos datos te diremos cómo puedes protegerte a tí y a tu familia!`,
        },
        {
          header: `Atención personalizada`,
          body: `Otra ventaja que tienes contratando tu seguro con inter.mx es que siempre vas a tener atención personalizada. Nuestro equipo estará siempre dispuesto a escucharte a través de todos nuestros canales de comunicación.
          ¡Te acompañaremos durante todo tu proceso!`,
        },
        {
          header: `Lleva tus pólizas siempre contigo`,
          body: `Descarga nuestra app y encuentra todo lo que necesitas desde tu teléfono móvil. Con la app de <>inter.mx</> tendrás siempre a la mano todas tus pólizas. Así nunca te faltará nada. 
          ¡Le quitamos lo complicado a los seguros!
          Como siempre, estamos contigo y queremos hacer tu vida más fácil. Por ello te recomendamos seguirnos en Facebook e Instagram para conocer las promociones especiales del día de las madres.
          Participa en nuestro giveaway. ¡Mamá merece más que un abrazo, se merece una tele nueva! 
          Protegiendo a mamá con un seguro médico o de coche llévate de regalo una membresía familiar con 20 beneficios. Al contratar tu seguro usa el código #INTER10MAYO
          ¡Protege lo que más amas, fácil, rápido, al mejor precio y con una experiencia personalizada de principio a fin!`,
        },
      ],
    },
    {
      img: BG73,
      date: "18 Mayo 2022",
      autor: "adminInter",
      title: "¿Buscas el Mejor Seguro de Auto? ¡Ya llegó HOT SALE 2022!",
      body: "Aprovecha el HOT SALE con inter.mx y contrata ya tu seguro de auto perfecto en un tap tap, súuuuper fácil y rápido. ¡Te contamos qué onda!",
      content: [
        {
          header: ``,
          body: `Si estás pensando en contratar un Seguro de Auto y na’más no te decides, éste es tu momento para hacerlo. Aprovecha el Hot Sale con inter.mx y contrata tu seguro perfecto al mejor precio. Nada mejor que poder sentirte súper tranqui al volante, ¿no crees?
          ¡Sigue leyendo para que te enteres de toditito sobre cómo hacerte del Seguro de Coche que tanto has imaginado en este Hot Sale!
          <>¡Cotiza ahora mismo con nuestro cotizador de seguros!</>`,
        },
        {
          header: `¿Calorón? ¡El  del HOT SALE 2022!`,
          body: `¡Obvio no puedes perderte esta gran oportunidad para contratar tu Seguro de Auto! En inter.mx te ayudamos a escoger el mejor para ti.
          Por si aún no lo sabes, Hot Sale es la campaña más grande de México para ventas online, y este año, celebra su novena edición. Por eso, esta vez tendrás 9 días de increíbles descuentos, del 23 de mayo a las 00:00 hrs hasta el 31 de mayo a las 23:50 hrs. Está genial, ¿no?`,
        },
        {
          header: `Tooodooo lo que necesitas saber para contratar el mejor Seguro de Auto`,
          body: `A la hora de contratar un seguro, debes tener en cuenta (obvio) qué auto tienes y el tipo de conductor que eres. Para poder darte el precio de la póliza, las aseguradoras toman en cuenta esta info:`,
          list: [`Marca de tu vehículo`, `Año de fabricación`],
        },
        {
          header: ``,
          body: `Y algunos datos de quien conduce:`,
          list: [
            `Edad`,
            `Sexo de nacimiento (Ya sabes, las aseguradoras a veces hacen preguntas complicadas, ¡pero siempre es para poder protegerte mejor!)`,
            `Lugar de residencia`,
            `A qué te dedicas`,
          ],
        },
        {
          header: ``,
          body: `Vas a ver, es rapidísimo y súper fácil cotizar y contratar tu Seguro de Coche en inter.mx. Con unos cuantos datos, nuestro cotizador hace pura magia para encontrar tu seguro ideal al mejor precio. Una experiencia de seguros increíble y completamente personalizada, ¡seguro, seguro!
          También te puede interesar: <>¿Qué información necesitas para cotizar el seguro ideal para tu auto?</>`,
        },
        {
          header: `¡Asegúrate contra todo de una vez!`,
          body: `El mejor Seguro de Auto es el que cubre justito lo que tú necesitas y se adapta perfecto a ti. Claro que siempre lo más aconsejable es elegir el plan más completo, porque a veces tener precauciones extra no está de más, ¿no crees? Aunque la póliza puede ser un poco más alta, a largo plazo seguro seguro será un gran beneficio para tu bolsillo. 
          Piénsalo… ¿sabes cuánto cuesta arreglar un coche cada vez que algo sucede, por más mínimo que sea? Las reparaciones suelen ser súper costosas y pueden ser un golpazo inesperado a tu bolsillo. ¿Y para qué es un Seguro, si no para protegerte en situaciones que para nada te esperabas, como puede ser un robo total o daños de terceros? Uff, qué paz sería estar protegid@, ¿no?`,
        },
        {
          header: `Estás a un tap tap de distancia de tu Seguro perfecto`,
          body: `En <>inter.mx</> tenemos tres tipos de Seguro de Auto con diferentes coberturas para que puedas escoger lo que tú prefieras. ¡Pero esto no es todo! si en vez de coche tienes una moto, también tenemos las coberturas que necesitas. 
          
          Así es, no importa si vas en cuatro o en dos ruedas, con inter.mx siempre estarás protegid@ de cualquier incidente que se te presente. Y hoy lo puedes disfrutar al mejor precio en el Hot Sale. 
          ¡No puedes dejar pasar esta oportunidad! Busca a inter.mx en el Hot Sale y contrata tu seguro en un tap tap. Adquirir tu seguro con nosotros es súuuuper fácil. 
          Si quieres conocernos un poquito más puedes seguirnos en <>Facebook</> e <>Instagram</>.`,
        },
      ],
    },
    {
      img: BG74,
      date: "18 Mayo 2022",
      autor: "adminInter",
      title: "¿Buscas Cotizador de Seguros? ¡Checa el Hot Sale de inter.mx!",
      body: "Con nuestro Cotizador de Seguros online compara y contrata tu seguro ideal rápido y fácil. ¡No pierdas tiempo, que el HOT SALE pasa volando!",
      content: [
        {
          header: ``,
          body: `En este HOT SALE, ¿¡Quién no quiere encontrar los mejores descuentos y aprovechar las mejores oportunidades!? ¡Esto te va a encantar! Con nuestro cotizador de seguros sí o sí encuentras el mejor precio para tu seguro ideal, súuuuper fácil y rápido. Eso suena bien, ¿no crees?
          En <>inter.mx</> queremos que tengas el seguro que tú quieras y que se adapte a ti a la perfección. Con nuestro cotizador puedes tenerlo, seguro seguro. ¡Aprovecha este HOT SALE! Sigue leyendo y entérate de toditito.
          
          <>¡Ahorra tiempo y dinero con la ayuda de inter.mx!</>`,
        },
        {
          header: `Aprovecha el HOT SALE al 100%`,
          body: `En época de promociones, nuestro cotizador de seguros es lo mejor que te puede pasar. ¿Sientes ese calorón? Es porque ya llegó el HOT SALE a inter.mx ¡Aquí te contamos exactamente de qué va la promo!
          Contratando hoy tu seguro de coche, seguro de moto o seguro médico te regresamos $1,000 pesos. Solo tienes que ingresar el código HOTSALE22. Y así de fácil. ¡Ya está! Esta promo es válida desde el 23 al 31 de mayo. Para conocer exactos los términos y condiciones, puedes encontrarlos en nuestro <>sitio</>. ¡Corre!`,
        },
        {
          header: `Entonces… ¿Cómo funciona un cotizador de seguros?`,
          body: `Para usar la magia del cotizador de seguros de inter.mx sólo debes escoger el producto que buscas, por ejemplo:`,
          list: [`Coche Seguro`, `Moto Segura`, `Médico Seguro`],
        },
        {
          header: ``,
          body: `Luego toca que nos compartas unos cuantos datos para que el cotizador pueda hacer bien su chamba. ¡No olvides ningún detalle! Todo cuenta para encontrar el seguro perfecto para ti, con la mejor cobertura y al mejor precio.
          Además, en inter.mx puedes elegir cómo quieres pagar. Y una vez que tengas tu seguro, podrás manejarlo todo desde tu celular con la app de inter.mx. ¡Súper práctico!`,
        },
        {
          header: `Con inter.mx, ¡seguro, seguro!`,
          body: `Para usar nuestro famosísimo cotizador de seguros, entra ahora mismo a <>inter.mx</>. ¡Descubre lo fácil y rápido que es estar seguro, seguro!. 

          ¡Y no te pierdas de naditita! Síguenos en <>Facebook</> e <>Instagram</> para enterarte de toodaaas las promos, los beneficios y las noticias que tenemos para ti.
          
          Y checa nuestro <>blog</>, ¡tenemos todas las respuestas que necesitas!
          
          ¡inter.mx le quita lo complicado a los seguros, seguro seguro!!`,
        },
      ],
    },
    {
      img: BG61,
      date: "18 Abril 2022",
      autor: "adminInter",
      title: "¿Cuál es el mejor Seguro de Auto según el Modelo de mi Coche?",
      body: "¿Quieres cuál seguro se adapta a tu coche? Llegaste al lugar indicado. En este artículo seguro, seguro te quedará claro.",
      content: [
        {
          header: ``,
          body: `El mejor seguro sabe que tu coche es como una persona, requiere cuidados especiales, necesita cariño y cumple años. Este último detalle es importante a la hora de asegurar tu coche. ¿Por qué? Muy simple: no todos los modelos pueden acceder a las mismas coberturas. 
          Actualmente, en el mercado podemos encontrar coches de todo tipo. Existen descapotables, deportivos, familiares, eléctricos, de gasolina, etcétera. Y en un rincón muy especial están los que ya tienen más de 20 años. La pregunta es: ¿cuáles pueden acceder a las opciones de cobertura de <>Coche Seguro</> que ofrece <>inter.mx</>?`,
        },
        {
          header: `¿El mejor seguro de auto le queda bien a mi coche?`,
          body: `Aunque tiene los mejores seguros de auto, Coche Seguro no puede encargarse de un clásico de 20 años. Pero si tu coche dio sus primeros 15 años, está justito en el límite de antigüedad que Coche Seguro te puede cubrir. Si tu auto tiene menos tiempo, entonces podrás protegerlo con el mejor seguro de auto cuando lo decidas, ¡pero, no te duermas, que el tiempo corre! Además, tu bolsillo también te lo agradecerá.`,
        },
        {
          header: `Beneficios de Coche Seguro`,
          body: `Con la versión más amplia de Coche Seguro puedes acceder a una gran cantidad de beneficios. Entre ellos, podrás contar con protección cuando necesites una grúa o gasolina. Incluso, tendrás cobertura si manejas un auto que no es tuyo. También protegerás a las personas que se vayan de viaje contigo a la Riviera Maya o cualquier otro lugar. Pero esto no es todo.`,
        },
        {
          header: `Daños Materiales`,
          body: `Si tienes un accidente y tu coche se lastima, el mejor seguro para auto lo tratará como uno más de tu familia y te ayudará con los gastos. Recuerda: el periodo de espera para todas las coberturas es de 15 días.`,
        },
        {
          header: `Robo Total`,
          body: `Aunque esto no te va a ocurrir, es bueno saber que si alguien toma “prestado” tu auto y no lo devuelve, también tendrás cobertura. 
          Un plus de este plan es que ofrece una indemnización por Covid-19 en caso que este virus le robe tiempo a tu vida ocupada. Si estuviste enfermo y no pudiste trabajar hasta recuperarte, tu seguro se hace cargo de todo. ¡Nunca está demás estar bien protegido!`,
        },
        {
          header: `Defensa Legal`,
          body: `Si las cosas se ponen color de hormiga, vas a necesitar un especialista en leyes que responda por ti y por tu coche, ¿no crees? Con este espíritu de servicio, Coche Seguro también te ofrece orientación telefónica y asistencia psicológica para que estés seguro seguro en todo momento.
          En <>inter.mx</> lo que más disfrutamos es quitarle lo complicado a los seguros. Por esta razón y muchas más –modestia aparte– somos tu mejor bróker de seguros. ¡Entra hoy mismo a nuestro portal y cotiza el mejor seguro de auto!


          Queremos seguir platicando contigo de igual a igual. Para estar más cerquita, dinos qué piensas en nuestras redes.  
          Te invitamos a seguirnos en <>Facebook</> e <>Instagram</> y, por supuesto, a leer nuestro <>blog</>.
          
          ¡Protege lo que más amas, fácil, rápido y al mejor precio en inter.mx!`,
        },
      ],
    },
    {
      img: BG62,
      date: "18 Abril 2022",
      autor: "adminInter",
      title: "Seguro para Auto: ¿Americano, Chocolate o con Título Salvage?",
      body: "¡Descubre si tu coche puede tener un seguro para auto! Resuelve este gran misterio leyendo esta nota hasta el final.",
      content: [
        {
          header: ``,
          body: `¿Tu auto es chocolate, americano o con título Salvage y no sabes si puedes contratar un seguro para auto? ¡No te quedes con la duda! Sigue leyendo y entérate de todo lo que necesitas para asegurar tu auto cuanto antes. 
          Dale a tu coche la protección que merece. Piensa en todo lo bueno que te da y lo mucho que tienes que cuidarlo. 
          En <>inter.mx</> sabemos lo que tu vehículo vale para ti y tu felicidad. ¡Sigue leyendo!`,
        },
        {
          header: `Dime qué auto tienes y te diré qué seguro para auto necesitas`,
          body: `Antes de hablar del seguro para auto ideal, debes saber que en México hay algunos autos que son “especiales”, y no por el cariño que les tienen, sino por su procedencia. Hablamos de los famosos autos chocolate, americanos o, en algunos casos, con título salvage.
          Estos autos, aunque son un poco raros y casi exclusivos, tienen un gran número de seguidores a lo largo y ancho del país. Por ello, a continuación, haremos un breve repaso para hablar de cada tipo y luego te diremos cuáles pueden obtener un seguro para auto.`,
        },
        {
          header: `Chocolate`,
          body: `Chocolate es la manera informal de llamar a los autos que ya no se comercializan. Incluso, dentro de esta categoría entran los coches que nunca se han vendido en el país. Sin embargo, no es tan difícil conseguirles un seguro para auto.`,
        },
        {
          header: `Americano`,
          body: `Un auto americano es simplemente un coche fabricado en Estados Unidos. En general, suelen ser clásicos de grandes motores y que hacen mucho ruido al arrancar. Sus motores V8 tienen mucha potencia ¡son inmensos! Y lo bueno es que tampoco es complicado conseguirles un seguro.`,
        },
        {
          header: `Título Salvage`,
          body: `Cuando un seguro para auto se encuentra con un auto de título salvage no hay mucha esperanza de que lo cubra. Se trata de los coches que tienen daños materiales de hasta un 90%. Podríamos decir que es una forma elegante de decir “destrucción total”. En este caso, conseguirles una cobertura es realmente dificultoso.`,
        },
        {
          header: `¿Cómo puedo proteger mi auto?`,
          body: `En el caso de los autos chocolate o americanos obtener un seguro para auto no es tan complicado. Sólo debes cumplir con algunos requisitos como:`,
          list: [
            `Licencia de conducir al día`,
            `Título de tu auto`,
            `Comprobante de domicilio`,
            `Credencial de elector`,
            `Antigüedad del auto menor a 15 años`,
          ],
        },
        {
          header: ``,
          body: `Si cumples con los requisitos en <>inter.mx</> contamos con las mejores aseguradoras. Algunas de las empresas que pueden brindarte la protección que tu auto necesita son:`,
          list: [`Mapfre`, `Quálitas`, `HDI Seguros`, `GNP`],
        },
        {
          header: ``,
          body: `Un párrafo aparte es para la Shipper Export Declaration, un documento que sirve para dar de baja a tu coche en Estados Unidos y legalizarlo en México. Con todo esto, podrás acceder a un seguro para auto sin problemas ya que estarás dentro del REPUVE.
          En cuanto a un Título Salvage, lo más probable es que si intentas asegurarlo te hagan esta recomendación: “Cambialo a Rebuilt”. Esta palabra quiere decir “reconstruido” y te vendrá mejor para proteger tu auto. Eso sí, antes deberás reconstruirlo de veras, sin las comillas.
          En <>inter.mx</> no sólo queremos que cuides tu coche, sino que sigas haciendo todo lo que haces con él.
          
          Como ves, asegurar tu auto es una sabia decisión. Si ya lo hiciste, tu vida es más tranquila, ¿no crees? Pero si no lo has hecho, ¿¡qué esperas!?
          Entra a <>inter.mx</> y ¡cotiza, compara, y contrata tu seguro para auto! ¡Es rapidísimo!
          
          ¡Síguenos en <>Instagram</> y <>Facebook</> para conocer más sobre las ventajas y beneficios que tenemos para ti!
          
          *Para mayor información sobre las coberturas pregunta a tu asesor o verifica con tu aseguradora.`,
        },
      ],
    },
    {
      img: BG63,
      date: "18 Abril 2022",
      autor: "adminInter",
      title: "Seguro de Gastos Médicos: ¿Hasta qué edad estoy cubierto?",
      body: "Siempre es un buen momento para contar con un Seguro de Gastos Médicos. ¡Entra aquí para conocer hasta qué edad puedes contratarlo!",
      content: [
        {
          header: ``,
          body: `Los gastos médicos son uno de los motivos más habituales por los que las personas contratan un seguro. Sin embargo, no todos saben cuál es la edad máxima para contratar una cobertura de este tipo. En este artículo te contamos qué es un seguro de gastos médicos y hasta qué edad puedes asegurarte.`,
        },
        {
          header: `¿Qué es un seguro de Gastos Médicos?`,
          body: `Cuando contratas un seguro de gastos médicos, por lo general, estás pagando una tarifa mensual para ayudar a cubrir el costo de un gasto futuro. Existen muchos tipos diferentes de seguros, pero el objetivo final de todos es proteger tus finanzas contra cualquier eventualidad que pudiera poner en jaque tu bolsillo.
          Los seguros de gastos médicos son de los más contratados, en definitiva. Con un seguro de este tipo, estás cubierto por una compañía de seguros, que se encargará de respaldarte en casi cualquier contingencia médica. Suena atractivo, ¿no? Claro que tu nivel de protección depende del seguro que contrates.`,
        },
        {
          header: `¿Por qué contratar tu seguro en inter.mx?`,
          body: `Si tienes hasta 65 años, ¡puedes contratar un Seguro Médico en inter.mx!  Y entre antes, ¡mejor, porque el tiempo no perdona!
          En realidad, siempre es un buen momento para proteger tu salud. Y nunca es demasiado temprano para contratar un seguro de gastos médicos. Lo único que importa es que tomes la decisión a tiempo y encuentres un seguro justo a la medida de tus necesidades.
          El primer paso para obtener un <>seguro de salud</> es averiguar qué cobertura requieres. Hay muchos tipos diferentes de cobertura, por lo que es importante saber cuáles son tus necesidades antes de comenzar a buscar.
          Si todavía eres joven y saludable, entonces un seguro con deducible alto podría ser lo mejor para ti, porque tienden a ser más baratos que otros planes. Si eres mayor y tienes más necesidades médicas, no tienes por qué preocuparte, pues en <>inter.mx</> hay un montón de opciones entre las que podrás elegir.`,
        },
        {
          header: `Recomendaciones para las distintas edades`,
          body: `Las recomendaciones para contratar un seguro de gastos médicos son diferentes para los distintos grupos de edad.
          1) Jóvenes: usualmente se recomienda contratar un seguro médico que cubra todos los riesgos de accidentes y lesiones.
          2) Adultos: se aconseja contratar un seguro que cubra todos los riesgos de accidentes y lesiones así como de enfermedades y padecimientos.
          3) Tercera edad: en este caso, lo mejor siempre será contratar un plan de seguro de gastos médicos que cubra todos los riesgos de enfermedades y padecimientos.
          Queremos asesorarte para que puedas obtener el mejor tipo de seguro, el que mejor se adapte a tus necesidades. Entra a inter.mx y encuentra un seguro perfecto para ti.
          ¡Elige hoy mismo la mejor cobertura para tu salud!
          Y recuerda que puedes seguirnos en nuestros perfiles de <>Instagram</> y <>Facebook</> para estar al tanto de todas las novedades que tenemos para ti.`,
        },
      ],
    },
    {
      img: BG64,
      date: "18 Abril 2022",
      autor: "adminInter",
      title: "¿Por qué utilizar un Cotizador de Seguros? ¿Realmente sirve?",
      body: "Usando un cotizador de seguros ahorras tiempo y obtienes un producto personalizado que se ajusta a tu presupuesto y estilo de vida. ¡Conoce más!",
      content: [
        {
          header: ``,
          body: `Buscar un seguro puede ser una tarea tediosa y, entre tantas opciones, puede ser difícil encontrar una solución que se acomode a las necesidades y presupuestos de cada persona. <>inter.mx</> es un cotizador de seguros que te ayuda a encontrar el mejor seguro para ti. 
          
          ¡No te compliques, contrata el seguro ideal con el cotizador de seguros en inter.mx!`,
        },
        {
          header: `Ahorra tiempo y evita el estrés`,
          body: `Cotizar un seguro requiere de formularios, filas, documentos y trámites que en inter.mx queremos evitarte. Con un cotizador de seguros que analiza la información de forma automatizada, encontrar tu seguro ideal es tan fácil y rápido como hacer un “tap tap”. Sólo ingresas unos cuantos datos y el cotizador se encarga del resto, así puedes escoger el producto que se lleve mejor contigo.`,
        },
        {
          header: `Adapta el seguro a tu bolsillo con el cotizador de seguros`,
          body: `¡No gastes más! Sabemos que tu tranquilidad y seguridad es muy importante, por eso queremos que puedas acceder a un seguro que se ajuste a tu presupuesto. Un cotizador de seguros analiza tus datos financieros y los contrasta con tus necesidades, de esta manera, te ayuda a elegir un tipo de seguro que cubra tus prioridades sin preocuparte por tu bolsillo.`,
        },
        {
          header: `No te enredes con las características de cada seguro`,
          body: `Para contratar un seguro es necesario conocer el valor de la póliza, las primas, el deducible y la suma asegurada, que cambian dependiendo del tipo de seguro y de los intereses de cada persona. ¿Suena complicado? ¡No te preocupes! En inter.mx nos encargamos de quitarle lo complicado a los seguros. Con nuestro cotizador y un acompañamiento personal en todo momento, no te quedará ninguna duda.`,
        },
        {
          header: `Personaliza tu seguro`,
          body: `Con sólo unos cuantos datos personales, como tu edad, el lugar donde vives, tu historial médico y tus necesidades básicas y especiales, nuestro cotizador de seguros te muestra los seguros que mejor se pueden ajustar a ti. Pero no sólo eso, también puedes agregar las coberturas que quieras para así tener un seguro hecho a tu medida. 
          Si quieres cotizar tu seguro médico, entra ahora mismo a <>inter.mx</> y descubre lo fácil y rápido que es encontrar tu seguro ideal.
          
          Y como sabemos que las redes sociales nos facilitan la vida y nos mantienen más cerquita, te invitamos a seguirnos en <>Facebook</> e <>Instagram</>.
          
          Además, puedes seguir resolviendo todas tus dudas sobre los seguros en nuestro <>blog</>.
          
          ¡Con inter.mx nunca fue tan fácil contratar un seguro!`,
        },
      ],
    },
    {
      img: BG65,
      date: "18 Abril 2022",
      autor: "adminInter",
      title: "¿Cómo cotizar un Seguro para Mascotas? ¿Qué debes saber?",
      body: "Para cotizar tu seguro para mascotas necesitas aprender algunos trucos. ¡Entra a este artículo y te lo contamos todo!",
      content: [
        {
          header: ``,
          body: `¿No sabes cómo cotizar tu seguro para mascotas? En unos minutos descubrirás que es súper fácil y rápido. Al terminar de leer esta nota te sentirás mejor por haber protegido a quien te hace compañía todos los días. Claro que esperamos que nunca tengas que usar esta cobertura, pero sabemos que es mejor prevenir, ¿no crees? 
          En <>inter.mx</> le quitamos lo complicado a los seguros. Por eso te ayudamos a cotizar el seguro de tu mascota y te respondemos cualquier duda que puedas tener. ¡Recuerda que una mascota protegida es una mascota feliz!
          
          <>¡Protege lo que más amas, fácil, rápido y al mejor precio!</>`,
        },
        {
          header: `¿Quieres cotizar un seguro para mascotas? ¡Es rápido y muy sencillo!`,
          body: `El mejor seguro para mascotas te está esperando en <>inter.mx</>. Se trata de una protección para perros y gatos que te permite elegir las coberturas que necesites para cuidar mejor a tu peludo.
          Si le da miedo ir al veterinario, que al menos no sea por los gastos.
          Para encontrar el seguro ideal para tu mascota sólo debes debes seguir unos cuantos pasos.`,
        },
        {
          header: `Cuida a tu perro o gato como él cuida de ti`,
          body: `1. Selecciona qué mascota tienes (perro o gato) 
          2. Escribe su nombre
          3. Anota tu correo para que nos comuniquemos contigo
          4.  Haz clic en “cotizar”
          Listo, con estos simples pasos ya habrás iniciado el camino hacia tu nuevo seguro para mascota. Pero, ¡espera! No te apresures que todavía hay mucho que debes conocer.`,
        },
        {
          header: `Beneficios y coberturas`,
          body: `En cuanto a los beneficios, podrás recibir servicios muy cool como la orientación telefónica de una clínica veterinaria. Además, si tu amigo tiene una enfermedad (¡respira!, es sólo un ejemplo…), los gastos estarán cubiertos. 
          Por otro lado, tu seguro para mascotas también cubre otro tipo de gastos que tu perro o gato necesita sí o sí:`,
          list: [`Desparasitación`, `Vacuna antirrábica`, `Pipeta antipulgas`],
        },
        {
          header: ``,
          body: `Puede que alguno de estos beneficios le resulte antipático a tu peludo, pero sabemos que tú sabrás convencerlo.
          En cuanto a las coberturas, que son muy completas, vamos a destacar las siguientes:`,
          list: [
            `Respaldo por daños a terceros: para ese incómodo momento en el que te enteras que tu animalito fue a hacer travesuras al jardín del vecino.`,
            `Gastos por hospitalización y análisis cubiertos: para ese día que no quieres que llegue, pero que debes prevenir porque uno nunca sabe.`,
            `Robo: para cuando Cruella se obsesiona con los dálmatas… puede pasar y será mejor tener la compañía de un seguro.`,
          ],
        },
        {
          header: `Requisitos y restricciones para obtener un seguro para mascotas`,
          body: `Para contratar un seguro para mascotas debes cumplir estos requisitos, además de amar muchísimo a tu perro o gato:`,
          list: [
            `Ser mayor de 18 años`,
            `Tener perro o gato entre 3 meses y 9 años de edad`,
            `Tu mascota debe ser doméstica (nada de mascotas intrépidas que se la viven explorando fuera de casa)`,
          ],
        },
        {
          header: ``,
          body: `En <>inter.mx</> queremos darle a tu peludo la mejor protección. Sabemos que los centennials aman a sus mascotas tanto como a sus hijos, y por eso te ofrecemos lo que necesitas para proteger a tu peludo en todo momento.
          
          Es lógico, nuestras mascotas nos hacen pasear por el parque, respirar aire puro o reír cuando deberíamos enojarnos. ¡Se merecen estar protegidas!
          En <>inter.mx</> seguro, seguro encuentras el plan que tu mascota necesita.
          
          ¡No olvides seguirnos en nuestros perfiles de <>Instagram</> y <>Facebook</>!`,
        },
      ],
    },
    {
      img: BG66,
      date: "18 Abril 2022",
      autor: "adminInter",
      title: "¿Los Seguros Médicos cubren Enfermedades Preexistentes?",
      body: "¿Qué pasa con las enfermedades preexistentes? ¿Es cierto que los Seguros Médicos no las cubren? ¡Despeja tus dudas sobre seguros con inter.mx!",
      content: [
        {
          header: ``,
          body: `Hay una creencia popular de que los seguros médicos que brindan las aseguradoras no cubren las enfermedades preexistentes. Y esto es un malentendido. Si bien, en general, hay restricciones sobre este tipo de enfermedades, existen coberturas muy específicas para personas con ciertos padecimientos. 
          Lo que pasa es que las aseguradoras necesitan conocer toda información sobre estas patologías, muchas de ellas crónicas, si es que las padeces. Por eso, te piden llenar un formulario especial indicando si tienes enfermedades o lesiones en el momento de contratar un seguro.`,
        },
        {
          header: `¿Qué son las enfermedades preexistentes?`,
          body: `“Enfermedades preexistentes” es uno de esos términos importantes que debes conocer a la hora de buscar seguros médicos. Tienen este nombre las enfermedades crónicas, como el asma o la diabetes, que te hayan diagnosticado desde antes de contratar un seguro.
          En este grupo también entran las lesiones, como las deportivas que no terminaron de curar del todo, o bien, que pueden aquejarte por mucho tiempo. También las operaciones entran dentro de esta clasificación.`,
        },
        {
          header: `Los seguros médicos sí cubren operaciones preexistentes, pero en otros términos`,
          body: `Justamente las aseguradoras piden esta información sobre enfermedades preexistentes para poder medir el nivel de riesgo y los costos de brindar una cobertura a alguien con este perfil.
          Por eso, las aseguradoras suelen incluir en las pólizas de gastos médicos cláusulas en donde se indica que “no se harán cargo de los gastos derivados de patologías que no se hayan informado”. Algunas incluso pueden llegar a denegar la posibilidad de una renovación de la póliza o directamente anularla si les mientes y te cachan.`,
        },
        {
          header: `Más información para coberturas más específicas`,
          body: `Conocer a detalle el historial médico de personas con enfermedades preexistentes permite a las aseguradoras entender mejor las necesidades de sus clientes. Y esto es fundamental para ofrecerles seguros médicos más específicos.
          Incluso una aseguradora puede evaluar la situación en cada caso particular según la situación y el momento de la vida del cliente. No olvidemos que el objetivo de estas compañías es proteger a las personas y mantener su bienestar en temas de salud.
          Obviamente, no todas las aseguradoras brindan este tipo de coberturas. Pero eso no es necesariamente malo. De hecho, provoca que las que sí lo hacen estén más preparadas para brindar un servicio que deje al cliente satisfecho. También significa que el trabajo de los brókers de seguros, como <>inter.mx</>, es todavía más valioso.
          
          Lo importante es poder juntar a las personas que tienen necesidades particulares (como una enfermedad preexistente) con las aseguradoras que se especializan en atender esas necesidades. Y no sólo eso, sino hacerlo de la forma más fácil y rápida posible.
          Visita <>inter.mx</> para cotizar un seguro de gastos médicos de forma confiable, precisa y sencilla.
          
          Le quitamos lo complicado a los seguros para que sepas exactamente qué es lo que estás contratando. ¡Cotiza ahora mismo!
          ¡Síguenos en nuestras redes de <>YouTube</> - <>Facebook</> - <>Instagram</> - <>Twitter</>!`,
        },
      ],
    },
    {
      img: BG67,
      date: "18 Abril 2022",
      autor: "adminInter",
      title: "Médico Seguro: Cotizador para Elegir el Mejor Seguro Médico",
      body: "Lanzamos Médico Seguro, el nuevo cotizador online que te ayuda a elegir tu seguro médico. ¡Ingresa ahora mismo y encuentra tu seguro ideal!",
      content: [
        {
          header: ``,
          body: `Hay cosas que alejan a las personas a contratar un seguro médico. Por ejemplo, no saber cómo funciona o dónde buscarlo. Incluso, a veces el mismo proceso para contratar un seguro les resulta demasiado complicado y eso puede desanimar a cualquiera. Éstas son las cosas que en inter.mx nos quitan el sueño y nos impulsan a hacer todo muuuuucho más fácil y rápido. 
          Si te identificas con uno de estos casos, ¡sigue leyendo! En este artículo te traemos una herramienta que te va a hacer la vida más fácil.`,
        },
        {
          header: `Una herramienta simple para elegir el mejor seguro médico`,
          body: `Médico Seguro es el nombre del nuevo cotizador de gastos médicos en línea de <>inter.mx</>. Esta poderosa herramienta está diseñada especialmente para ayudarte a elegir la mejor opción de seguro médico según tus necesidades.
          
          Con este cotizador puedes tener una experiencia totalmente personalizada, con asesoría y acompañamiento durante todo el proceso. La gran experiencia de servicio va desde la búsqueda de opciones hasta la contratación de tu seguro médico ideal.
          Además, si usas Médico Seguro, puedes contar con que estarás cotizando y comparando con las mejores aseguradoras del país. Todo esto de una forma fácil, rápida y súper segura. ¡Sin trucos ni letras chiquitas!`,
        },
        {
          header: `Médico Seguro: ¿Cómo funciona nuestro nuevo cotizador?`,
          body: `Médico Seguro cuenta con una amplia variedad de seguros según las necesidades específicas de quien lo utilice. Lo mejor es que podemos organizar las opciones en diferentes categorías y según nuestros intereses, es decir, podemos cotizar por:`,
          list: [
            `Tipo de seguro: gastos médicos preventivos, gastos médicos mayores o gastos médicos menores`,
            `Costos: suma asegurada, con o sin deducible o tope de coaseguro`,
            `Coberturas: padecimiento o año`,
            `Nivel hospitalario: esencial, protegido o blindado`,
          ],
        },
        {
          header: ``,
          body: `Y en este punto tal vez te preguntes: ¿y si quiero agregar alguna cobertura extra a mi plan? Bien, pues para esto Médico Seguro también te ofrece la opción de agregar beneficios adicionales como Plan Dental, Check Up Básico, Emergencia en el Extranjero, Accidentes sin deducible, entre otras coberturas.
          La facilidad con la que puedes cotizar, personalizar y contratar tu seguro médico desde esta nueva herramienta es increíble. <>¡Cotiza ahora!</>`,
        },
        {
          header: `Tecnología e innovación para quitarle lo complicado a los seguros`,
          body: `En <>inter.mx</> invertimos en tecnología e innovación para transformar la manera en la que los usuarios interactúan con los seguros. Nuestro objetivo es que sea cada vez más fácil contratar un seguro, y que las personas tengan cada vez más confianza en los seguros.
          
          ¡Le quitamos lo complicado a los seguros, seguro seguro!
          Entérate de otras novedades sobre los servicios que ofrecemos. ¡Síguenos en nuestras redes de <>YouTube</> - <>Facebook</> - <>Instagram</> - <>Twitter</>!`,
        },
      ],
    },
    {
      img: BG68,
      date: "18 Abril 2022",
      autor: "adminInter",
      title:
        "Médico Seguro: el nuevo cotizador de inter.mx que te ayuda a elegir tu mejor seguro",
      body: "Médico Seguro, un nuevo cotizador de gastos médicos en línea para buscar alternativas de seguro de forma fácil y rápida. ¡Elige tu seguro médico ideal!",
      content: [
        {
          header: ``,
          body: `INTERprotección, el bróker de seguros con más de 40 años de experiencia en el mercado, lanza el nuevo cotizador <>Médico Seguro</> en inter.mx. Ésta es una nueva herramienta para facilitar la experiencia de sus usuarios al contratar un seguro médico. 
          
          En los últimos años cada vez más personas han considerado contratar un seguro médico, ya que prevenir cualquier imprevisto de salud podría representar un apoyo en las finanzas y economía personal y familiar. Sin embargo, algunas de las limitantes para no contratar un seguro son que no saben cómo funciona o dónde solicitarlo, pues muchas veces el proceso y los términos son muy complicados o el costo no se adecua al presupuesto.
          <>Médico Seguro</> es el nuevo cotizador en línea de Gastos Médicos de <>inter.mx</>, que ayuda a los usuarios a elegir la mejor opción acorde a sus necesidades. Esta herramienta ofrece una experiencia personalizada, ya que brinda asesoría y acompañamiento en todo el proceso, desde la búsqueda de opciones hasta la contratación del servicio. Médico Seguro permite al usuario cotizar y comparar con las mejores aseguradoras del país, lo que permite evaluar una diversidad de opciones adaptadas para cada persona, todo esto al alcance de un clic.
          
          “A dos años del lanzamiento de la plataforma inter.mx, estrenamos el nuevo cotizador de seguro médico, Médico Seguro, una herramienta que facilita al máximo la experiencia de usuario. Y no sólo eso, además seguimos cambiando la forma de hablar sobre los seguros, porque tenemos el compromiso de hacer las cosas más sencillas, no sólo en nuestros productos, sino también en nuestra comunicación. Queremos hacer conciencia entre los mexicanos y por eso invertimos en publicidad diferente para que de una forma fresca y divertida entiendan la importancia de estar protegidos y cada vez sea más fácil asegurarse.” Mencionó Gabriela Gutierrez, Chief Marketing Officer de <>inter.mx</>.
          
          Dentro de su oferta, <>Médico Seguro</> cuenta con diferentes opciones que despliegan diferentes tipos de seguros para las distintas necesidades, encontrando el seguro ideal para ti, por ejemplo:
          
          Por tipo de seguro: gastos médicos preventivos, gastos médicos mayores, gastos médicos menores.
          Por costos: suma asegurada, con o sin deducible, tope de coaseguro.
          Por coberturas: por padecimiento o por año.
          Por nivel hospitalario: esencial, protegido o blindado.
          Además ofrece la opción de agregar beneficios adicionales como Plan dental, Check-Up básico, Emergencia en el extranjero, Accidentes sin deducible, entre otras.
          Para Alonso Pallarés, Chief Digital Officer de la empresa, es muy importante quitarle lo complicado a los seguros:
          “inter.mx ha invertido en tecnología e innovación para mejorar la experiencia de usuario, transformando la manera en la que nuestros clientes interactúan con los seguros, cada vez, de manera más fácil, dándole más confianza en ellos. Estos esfuerzos se ven materializados en Médico Seguro, en donde es posible comparar distintas opciones de productos para elegir la opción más conveniente y el mejor seguro para ti y estar protegido de una manera innovadora y disruptiva”.
          A través de este nuevo servicio, <>inter.mx</> refuerza cada vez más su compromiso de quitarle lo complicado a los seguros de manera fácil y rápida.
          
          YouTube: <>INTERprotección Oficial</>
          
          Facebook: <>www.facebook.com/interproteccionmx</>
          
          Instagram: <>@INTERproteccion</>
          
          Twitter: <>@INTERproteccion.</>
          
          Acerca de <>inter.mx</>:
          
          inter.mx, la insurtech mexicana líder en el mercado con la mejor innovación tecnológica en todos sus procesos, se enfoca directamente al cliente, llevándolo a la vanguardia, ofreciendo el mejor servicio a través de productos innovadores y disruptivos, como lo son Coche Seguro y Médico Seguro. Su principal diferenciador son los precios y la facilidad para contratar, ya que inter.mx le quita lo complicado a los seguros, y en menos de cinco minutos se puede adquirir cualquiera de sus servicios, acercándose mejor al consumidor final.`,
        },
      ],
    },
    {
      img: BG60,
      date: "Mayo 2022",
      autor: "adminInter",
      title: "¡Contrata el Seguro de Gastos Médicos Ideal para Mamá!",
      body: "¡Mamá merece lo mejor! Contrata ahora un seguro de gastos médicos para ella. ¡Cotiza, compara y elige el mejor seguro en inter.mx!",
      content: [
        {
          header: ``,
          body: `¡Mamá siempre nos cuidó, es hora de cuidarla a ella! Con un seguro de gastos médicos podrás estar tranquilo de que recibirá la mejor atención, con tecnología de punta y especialidades en diferentes áreas.
          Disfruta al máximo los momentos con mamá teniendo la certeza de que estará protegida ante cualquier eventualidad. En <>inter.mx</> te ayudamos a contratar el seguro ideal para ella, que se ajuste a sus necesidades y al bolsillo familiar.`,
        },
        {
          header: `¿Por qué tener un seguro de gastos médicos?`,
          body: `Los gastos médicos son bastante costosos, así se trate de procedimientos simples, la infraestructura y el talento humano de la salud tienen un alto costo. Por eso, tener un seguro de gastos médicos es una excelente opción para prevenir un desajuste económico en tu familia en caso de una eventualidad médica.
          Los seguros de gastos médicos cubren diferentes servicios que quizá no habías contemplado. De esta manera, no te llevarás sorpresas que puedan afectar tus finanzas.`,
        },
        {
          header: `Complementa otros tipos de seguro con un seguro de gastos médicos`,
          body: `La ventaja de un seguro de gastos médicos es que lo puedes combinar con otros seguros, por ejemplo, un seguro de vida. Así podrás disfrutar de una cobertura más completa que te ayudará a prevenir una crisis en caso de un evento no planeado.
          En inter.mx te aconsejamos adquirir tu paquete de seguros en un solo lugar. Te ofrecemos seguros para proteger tu coche, bienes, salud, mascotas y viajes. Así, te cuidamos en todo momento y en cualquier circunstancia sin que te compliques.`,
        },
        {
          header: `Ventajas de contratar tu seguro de gastos médicos con inter.mx`,
          body: `En inter.mx te ofrecemos planes individuales o familiares para obtener un seguro médico. Además, contamos con tres tipos de cobertura que te brindarán diferentes beneficios dependiendo de lo que necesites para estar seguro.
          Tenemos disponibles los planes:`,
          list: [`Esencial`, `Protegido`, `Blindado`],
        },
        {
          header: ``,
          body: `Cada uno ofrece coberturas diferentes según las necesidades y posibilidades de cada persona. Así que puedes elegir el plan más conveniente para proteger a mamá.
          Para saber cuál plan es el mejor para tí, realiza ahora una cotización a través de nuestra nueva herramienta “<>Médico Seguro</>”. ¡Con tan solo unos cuantos datos te diremos cómo puedes protegerte a tí y a tu familia!`,
        },
        {
          header: `Atención personalizada`,
          body: `Otra ventaja que tienes contratando tu seguro con inter.mx es que siempre vas a tener atención personalizada. Nuestro equipo estará siempre dispuesto a escucharte a través de todos nuestros canales de comunicación.
          ¡Te acompañaremos durante todo tu proceso!`,
        },
        {
          header: `Lleva tus pólizas siempre contigo`,
          body: `Descarga nuestra app y encuentra todo lo que necesitas desde tu teléfono móvil. Con la app de inter.mx tendrás siempre a la mano todas tus pólizas. Así nunca te faltará nada.
          ¡Le quitamos lo complicado a los seguros!
          Como siempre, estamos contigo y queremos hacer tu vida más fácil. Por ello te recomendamos seguirnos en <>Facebook</> e <>Instagram</> para conocer las promociones especiales del día de las madres.
          Participa en nuestro giveaway. ¡Mamá merece más que un abrazo, se merece una tele nueva!
          Protegiendo a mamá con un seguro médico o de coche llévate de regalo una membresía familiar con 20 beneficios. Al contratar tu seguro usa el código #INTER10MAYO
          ¡Protege lo que más amas, fácil, rápido, al mejor precio y con una experiencia personalizada de principio a fin!`,
        },
      ],
    },
    {
      img: BG51,
      date: "18 Marzo 2022",
      autor: "adminInter",
      title: "Seguro Médico: esencial, protegido o blindado ¿Qué plan escoger?",
      body: "En inter.mx tenemos tres planes para contratar un Seguro Médico: Esencial, Protegido y Blindado. ¡Contrata el que mejor se adapte a tus necesidades!",
      content: [
        {
          header: ``,
          body: ` 
          En <>inter.mx</> tenemos como meta, ofrecer a cada persona que llega hasta nosotros una cobertura a su medida. Si estás buscando un seguro médico, nosotros te acompañamos para que encuentres la opción perfecta para ti y tus necesidades, al mejor precio.
          
          ¡No más, no menos! Con nosotros pagas sólo por lo que realmente quieres.
          Por eso dividimos los productos en tres niveles, cada uno con distintas opciones, ventajas y sumas aseguradas. Éstos son: Esencial, Protegido y Blindado.`,
          list: [],
          footer: ``,
        },
        {
          header: `¡Encuentra tu plan ideal de seguro médico!`,
          body: ``,
        },
        {
          header: `Esencial`,
          body: `Este plan es para quienes desean contratar un seguro médico básico, con todos los cuidados mínimos indispensables. Con el plan esencial tendrás acceso a una red elemental de hospitales que te brindará una respuesta segura, rápida y de excelente calidad para atender casi cualquier problema de salud que se te presente.
          La suma asegurada de este plan va hasta los $6,000,000.00 pesos y siempre estará disponible en caso de que la necesites. Esto aplica para cualquier seguro que contrates a través de tu plan esencial.`,
        },
        {
          header: `Protegido`,
          body: `Es la versión mejorada de un plan “Esencial” y se la recomendamos a las personas que necesitan cuidados especiales. Este plan agrega a la red hospitalaria centros con hasta tres especialidades y tecnología media, localizados en varios estados de la República.
          Además al obtener un seguro médico a través de nuestro plan “Protegido”, tendrás una suma disponible de hasta $25,000,000.00`,
        },
        {
          header: `Blindado`,
          body: `Éste es el mejor plan de cobertura médica que podemos ofrecer. Se lo recomendamos a quienes buscan los mejores cuidados médicos en todo el país.
          Como un plan de excelencia, tiene la red de hospitales más amplia, con centros médicos de hasta cuatro especialidades y tecnología de punta. Además, la suma asegurada puede ser de hasta $100,000,000.00`,
        },
        {
          header: `¡Con inter.mx encuentras lo que necesitas!`,
          body: `Cada plan que te ofrecemos tiene diferentes beneficios y costos. En los planes más avanzados encontrarás mayores deducibles y topes de coaseguro. Ponte en <>contacto</> con uno de nuestros especialistas para conocer a fondo los detalles.

          Con <>inter.mx</>, contratas el seguro médico que tú quieres y necesitas. Además, puedes elegir el plan que mejor te convenga según lo que estás dispuesto a pagar. ¡Cuentas claras, amistades largas!

          Si tienes cualquier duda, te confundimos o no sabes qué onda, ¡nosotros te ayudamos! Escríbenos o échanos un telefonazo. 
          WhatsApp: 55 4326 9037
          Teléfono: 55 442 46837 
          Email: <>hola@inter.mx</>.`,
        },
      ],
    },
    {
      img: BG52,
      date: "18 Marzo 2022",
      autor: "adminInter",
      title: "¿Buscas un Seguro de Gastos Médicos? ¡Encuéntralo aquí!",
      body: "Acceder a un seguro de gastos médicos es importante para cuidar tu bienestar y el de quienes te rodean. ¡Conoce más ingresando aquí!",
      content: [
        {
          header: ``,
          body: ` 
          Las palabras “seguro de gastos médicos” se han convertido en parte de nuestro vocabulario cotidiano. A cada rato escuchamos hablar sobre lo importante que es contar con una cobertura médica y sus múltiples beneficios. Como en <>inter.mx</> nuestra misión es que cada vez más personas estén protegidas, hoy te contamos cómo es que funciona. Y lo hacemos como debe ser: desde el principio. `,
        },
        {
          header: `¿Qué es un seguro de gastos médicos?`,
          body: `Un seguro de gastos médicos es un plan de salud que, como podrás deducir, cubre los gastos asociados a diferentes tipos de atención médica. Dependiendo de tus intereses y necesidades, puedes adquirir pólizas con más o menos coberturas.
          ¡Tú eliges lo que más te conviene!`,
        },
        {
          header: `Beneficios básicos de un seguro médico`,
          body: `Contar con una <>cobertura médica</> para ti y quienes más amas tiene un montón de beneficios para asegurar su bienestar, debes conocerlos todos. 
          
          ¡Toma nota!`,
        },
        {
          header: `Ayuda a tu bolsillo`,
          body: `Tener una cobertura médica evitará que caigas en gastos inesperados y elevados. Recuerda que las intervenciones médicas, por más pequeñas que sean, tienen costos que sí afectan nuestro bolsillo. En este sentido, un seguro de gastos médicos es un colchón que no te deja caer al piso y protege tu dinero.
          ¡Cuida tu salud y tus finanzas!`,
        },
        {
          header: `Atención fuera de tu zona`,
          body: `Contratar un seguro médico será un respaldo maravilloso cuando viajes o visites ese pueblito mágico que tanto has soñado conocer. Aunque las restricciones geográficas cambian según las coberturas de cada póliza, los <>mejores planes de salud</> te cubren sin importar dónde te encuentres.
          
          ¡Bienestar en todos lados!`,
        },
        {
          header: `Toda la atención que te mereces`,
          body: `Un seguro de gastos médicos te garantizará la atención que te mereces siempre que la necesites. El acceso a clínicas y centros de atención de calidad será un gran respaldo para ti y para quienes te rodean, sin importar lo pequeño que sea el procedimiento o examen que necesites.`,
        },
        {
          header: `Y lo más obvio: salud para ti y los tuyos`,
          body: `El beneficio más obvio, pero también el más importante de contratar un seguro médico, es que podrás cuidar de tu salud siempre. No sólo si llegas a tener algún incidente, sino que tu seguro te acompaña en controles de salud al año, chequeos generales y hasta en la compra de alguna medicina recurrente.
          ¡Lo que sea que necesites, tu seguro médico estará ahí para ti!`,
        },
        {
          header: `¿Cómo escoger el mejor seguro médico?`,
          body: `Esta es la pregunta del millón al momento de buscar un seguro de gastos médicos. Y la verdad es que hoy el mercado de seguros está repleto de alternativas… 
          ¡Pero que no cunda el pánico!
          En <>inter.mx</> encuentras el mejor seguro médico, justo a la medida de tus necesidades y al mejor precio. Además, contratar tu seguro es súper fácil y rápido.


          Entra a <>nuestro sitio</>, conoce, cotiza y compara todas las opciones que tenemos para ti con las mejores aseguradoras. Te ayudamos a elegir el mejor seguro médico para ti en tan sólo unos minutitos.


          Visítanos hoy mismo en <>inter.mx</> y no olvides seguirnos en nuestros perfiles de <>Instagram</> y <>Facebook</> para estar al tanto de las diferentes opciones que tenemos para ti y tu familia.`,
        },
      ],
    },
    {
      img: BG53,
      date: "18 Marzo 2022",
      autor: "adminInter",
      title:
        "Cotizador de Seguros - Todo lo que necesitas a un clic de distancia",
      body: "La tecnología ha hecho más fácil lo complicado, y por eso creó el cotizador de seguros de inter.mx ¡Ahorra mucho tiempo con un sólo clic!",
      content: [
        {
          header: ``,
          body: ` 
          El cotizador de seguros, inter.mx es súper práctico. En esta nota te explicaremos cómo es que le quita lo complicado a los seguros y hace mucho más fácil y rápida la experiencia de buscar tu seguro ideal. En inter.mx encuentras el seguro perfecto para ti sin necesidad de esperar a nadie ni pagar nada.
          Queremos que tu experiencia con los seguros no sea como un examen de matemáticas. Es más, no queremos que nadie te diga lo que es bueno o malo para ti. Con esta herramienta, podrás ser libre para decidir qué necesitas y cuánto quieres pagar por ello.`,
        },
        {
          header: `¿De qué se trata el cotizador de seguros de inter.mx?`,
          body: `Tu flamante cotizador de seguros te demostrará que inter.mx es tu mejor compañero. El proceso para cotizar y elegir tu seguro ideal es súper fácil y rápido en inter.mx. Con sólo algunos datos básicos, te mostraremos todas las opciones que tenemos para ti, y ¡listo! Escoge el seguro que más te convenga y contrata ahí mismo. Es así de fácil y rápido.
          Aquí tienes un ejemplo de seguro de gastos médicos.`,
        },
        {
          header: `Médico Seguro`,
          body: `Según lo que necesites, este seguro puede ser familiar o individual. Ofrece un servicio de gastos médicos mayores, cobertura telefónica y asistencia en viaje para protegerte durante tus aventuras. 
          De acuerdo a tus necesidades y posibilidades, podrás elegir qué tipo de red hospitalaria se adapta mejor. Puede ser un plan de cobertura Esencial, Protegido o Blindado. ¡Ojo! No tardes mucho en decidirte, ya que la edad máxima de aceptación es de 65 años.`,
        },
        {
          header: `Entonces… ¿Cómo usar el cotizador de seguros de inter.mx para contratar tu seguro médico perfecto, por ejemplo?`,
          body: ``,
        },
        {
          header: `Primero, lo primero`,
          body: `Antes que nada, te pedimos tu nombre y cómo prefieres recibir tu asesoramiento, si por celular o por mail.`,
        },
        {
          header: `¡Estoy list@!`,
          body: `Una vez que tengamos esta info, te pedirá algunos datos personales como tu fecha de nacimiento y tu código postal, y te preguntará a quién quieres asegurar. El seguro puede ser para ti, tu familia, o alguien más.`,
        },
        {
          header: `¡Listo!`,
          body: `Aparecerán todas las opciones que tenemos para ti. Elige tu seguro perfecto y ¡listo! Nunca has hecho un trámite tan rápido. Cuando ya te empezabas a encariñar, el cotizador de seguros terminó su trabajo. Así de veloz y así de sencillo. En inter.mx lo que más disfrutamos es quitarle lo complicado a los seguros.
          Por todas estas razones y más –modestia aparte– somos tu mejor bróker, y tenemos el cotizador de seguros más cool. ¡Entra hoy mismo a inter.mx y cotiza el seguro que quieras!
          En <>inter.mx</> queremos seguir comunicándonos contigo de igual a igual. Para estar más cerquita, dinos qué piensas en nuestras redes. 
          
          Te invitamos a seguirnos en <>Facebook</> e <>Instagram</> y, por supuesto, a leer nuestro <>blog</>.
          
          ¡Protege lo que más amas, fácil, rápido y al mejor precio en inter.mx!`,
        },
      ],
    },
    {
      img: BG54,
      date: "18 Marzo 2022",
      autor: "adminInter",
      title: "¿Qué Información Necesitas para Cotizar un Seguro de Auto?",
      body: "En inter.mx sabemos que quieres cotizar tu seguro de auto lo más rápido posible. Aquí tienes los datos que necesitas ¡Son muy poquitos! ¡Toma nota!",
      content: [
        {
          header: ``,
          body: ` 
          Cuando te acercas a cotizar tu seguro de auto, las aseguradoras te piden algunos datos como ubicación, modelo del vehículo, marca, entre otros. Y esto puede parecer tedioso, pero la verdad es que esta información es indispensable para poder darte el precio justo de la póliza que estás buscando. ¡No más, no menos!
          Hoy te contamos qué datos son importantes y cuáles no tanto para cotizar el <>seguro ideal para tu auto</>.`,
        },
        {
          header: `¿Qué datos son relevantes para cotizar un seguro de auto?`,
          body: `Sabemos que cotizar seguros de auto hasta encontrar la mejor opción del mercado puede ser una tarea difícil. Estar dando nuestros datos por todas partes en el proceso de búsqueda no es lo más divertido del mundo.
          Pero la verdad, es que detrás de toda esta información hay una buena razón. Cada dato que proporciones servirá para ofrecerte las coberturas y los costos de tu <>póliza,</> que mejor se adapten a tus necesidades y posibilidades.
          
          Los datos realmente importantes al momento de cotizar tu seguro de auto, son:`,
          list: [
            `Modelo del vehículo`,
            `Marca del vehículo`,
            `Ubicación o residencia del propietario`,
            `Sexo de nacimiento del propietario del vehículo`,
          ],
        },
        {
          header: ``,
          body: `Adicionalmente a esto, algunas aseguradoras pueden solicitar datos personales del propietario como edad u oficio, así como la actividad para la que será usado el vehículo. Y esto se debe a que, en algunos casos, estos factores sí llegan a afectar el precio final de la póliza. 
          ¡Recuerda que cada aseguradora maneja sus propias reglas!
          Por lo tanto, cotizar tu seguro de auto en varias aseguradoras es importante. Cuando comiences a cotizar tu seguro verás que las coberturas y montos asegurados cambian de lugar en lugar. 
          Por ejemplo, no es lo mismo asegurar un auto que utilizas para viajar muy seguido por carretera debido a tu trabajo, que un auto que usas sólo para moverte dentro de una zona tranquila y con poco tránsito vehicular.
          Pero como en inter.mx estamos empeñados en quitarle lo complicado a los seguros, tenemos las herramientas para ayudarte en esta aburrida tarea y volverla una gran experiencia.`,
        },
        {
          header: `Encuentra tu seguro ideal con nuestro Bróker de Seguros`,
          body: `El comparador de <>inter.mx</> te ayudará a encontrar el seguro de auto que has estado buscando de manera fácil y rápida. Sólo debes ingresar los datos –que ya sabes que son básicos– y ¡listo!. 
          
          ¡En un dos por tres tendrás la póliza de tus sueños y un seguro que se adapte a tus intereses y necesidades –y las de tu coche, claro–!
          Recuerda que con <>inter.mx</> puedes personalizar tu seguro y adquirir todas las demás coberturas que necesites para ti, tu hogar, tu familia y hasta tu mascota. 
          
          ¡Todos los seguros que necesites en un mismo lugar!
          ¡Anímate a probar nuestro servicio! Aquí seguro, seguro encuentras el plan que necesitas para que tu coche esté cubierto frente a cualquier imprevisto, por más pequeño que sea.
          ¡Y no olvides seguirnos en nuestros perfiles de <>Instagram</> y <>Facebook</>!`,
        },
      ],
    },
    {
      img: BG55,
      date: "18 Marzo 2022",
      autor: "adminInter",
      title: "¿Por qué el precio del Seguro de Coche cambia según el modelo?",
      body: "Hay factores que hacen más caros los siniestros y entre ellos está el modelo del auto. Aquí te contamos por qué un seguro de coche varía su precio.",
      content: [
        {
          header: ``,
          body: ` 
          Al consultar un seguro de coche, muchos de nuestros clientes nos preguntan: ¿por qué el modelo del auto influye en el precio del seguro? Esto tiene una explicación muy simple y tiene que ver con los riesgos que implica asegurar un modelo u otro.
          Al brindar una cobertura, una aseguradora se compromete a cubrirte en determinadas situaciones, y al mismo tiempo asume un riesgo. Cuanto más probabilidades haya de que suceda un siniestro, mayor riesgo asume la aseguradora.
          Por eso hay factores que modifican el tipo de cobertura que se va a brindar, por ejemplo el perfil del conductor del vehículo o la ciudad en la que reside.`,
        },
        {
          header: `Seguro de coche: un factor que encarece los siniestros`,
          body: `Hay factores que hacen que los siniestros, en caso de tener lugar, sean más caros. Como la aseguradora es la responsable de cubrir los gastos, va a seguir de cerca estos detalles. De modo que ofrecerá un seguro de coche más caro si alguno de estos factores está presente.
          Uno de ellos es justamente el modelo del auto. Cuanto más nuevo o lujoso sea el modelo, más caras son las autopartes. Si te las roban o se dañan en un accidente, significa que hay mayores costos para la aseguradora.
          Ten en cuenta que incluso algunas autopartes ni siquiera se fabrican en México. Por lo que dejar esas partes de tu auto como nuevas requerirá más tiempo, dedicación y, por supuesto… ¡Adivinaste! dinero, dinero y más dinero. Al fin y al cabo esto es lo que hace que el cliente quede satisfecho con el servicio.`,
        },
        {
          header: `¡En inter.mx le quitamos lo complicado a los seguros!`,
          body: `Ya viste que no era tan complicado entender por qué algunos seguros cuestan más que otros según el modelo de tu auto. En inter.mx te lo queremos explicar cada detalle sin letras chiquitas para que tengas todo clarísimo a la hora de contratar un seguro. Eso es quitarle lo complicado a los seguros. 
          Si tienes alguna otra duda sobre tu seguro de coche, no pierdas más tiempo y ponte en contacto con uno de nuestros asesores. 
          Échanos un telefonazo al 55 442 46837 o mándanos un mensajito a <>hola@inter.mx</>. Incluso puedes descargar nuestra App en <>Google Play</> o <>Apple App Store</>.
          
          ¡También estamos en <>Facebook</> - <>Instagram</> - <>LinkedIn</> - <>TikTok</> - <>YouTube</>!`,
        },
      ],
    },
    {
      img: BG56,
      date: "18 Marzo 2022",
      autor: "adminInter",
      title: "Tu CP influye en el precio del Seguro para Auto: ¿Sabes por qué?",
      body: "El Código Postal es muy importante a la hora de determinar el precio de un Seguro para Auto. Es una variable que debes considerar ¡Conoce más!",
      content: [
        {
          header: ``,
          body: `En otro artículo de este blog explicamos por qué el modelo de un coche influye en el precio al momento de asegurarlo. Hay factores que aumentan o disminuyen el riesgo que toma una aseguradora al comprometerse mediante una póliza de seguros. Por eso, un seguro para auto puede variar su precio en función de diferentes aspectos.
          Éste es el caso del CP, o código postal, que identifica la residencia del titular del seguro. Por ende, es el lugar en donde más va a circular con su carro.`,
        },
        {
          header: `¿Qué factores influyen en el precio de un seguro para auto?`,
          body: `El modelo de tu carro, entre más nuevo, exclusivo o caro, tendrá autopartes más costosas o difíciles de reponer. Por eso, un siniestro puede ser más caro para la aseguradora según el modelo. En cambio, el CP influye de otra manera en el costo del seguro para auto.`,
        },
        {
          header: `Todo se basa en riesgos…`,
          body: `Cada región tiene sus riesgos. Hay ciudades más transitadas que otras, e incluso lugares en donde la gente maneja mejor o peor. Esto afecta al riesgo de verse involucrado en un accidente de tránsito.  
          Lo mismo pasa con la posibilidad de sufrir el robo de tu auto o de alguna de sus partes, como las llantas. Cada región, ciudad o estado tiene su propio índice de robos.
          Lógicamente, si vives en un lugar en donde hay muchas más personas, habrá más chances de estar implicado en un accidente. Esto aplica tanto para peatones como para conductores. No es lo mismo vivir en una ciudad que en un pueblito, o en una zona industrial que en una residencial.
          El código postal también es un indicador del clima y las condiciones geográficas de un lugar. También esto impacta en el precio del seguro para auto. ¿Te suenan el granizo, las inundaciones, los temblores o las temperaturas altísimas? Pues si a ti no te suenan, seguro que a tu auto sí.`,
        },
        {
          header: `El código postal dice más de lo que crees`,
          body: `Lo que queremos decir es que todos estos riesgos son perfectamente medibles. Aunque no lo creas, todos los siniestros se pueden contabilizar y, a partir de esos números, es posible sacar estadísticas para saber dónde o cuándo hay más riesgos. 
          Una aseguradora debe tener presentes todos estos detalles para conocer el costo del servicio que está ofreciendo.
          Pero los que acabas de leer no son los únicos factores que modifican el precio de la cobertura; hay más. La clave es que entiendas la lógica detrás de los distintos precios y tipos de seguros. Así sabrás perfectamente qué es lo que estás pagando.`,
        },
        {
          header: `Resolvemos todas tus dudas sobre seguros, ¡Sin vueltas!`,
          body: `Si tienes alguna otra duda con respecto a tus coberturas, ya sabes que en <>inter.mx</> estamos siempre listos para resolverlas. Por algo somos el mejor bróker de seguros de todo México, y el más directo. Para andar con vueltas, ahí tenemos al Checo en la Fórmula 1. 
          
          ¡Para cualquier duda, mándanos un mensajito por <>WhatsApp</> o por mail a hola@inter.mx, o échanos un telefonazo al 55 4424 - 6837!`,
        },
      ],
    },
    {
      img: BG57,
      date: "18 Marzo 2022",
      autor: "adminInter",
      title: "¿Qué considerar para contratar el Mejor Seguro de Auto para ti?",
      body: "Aprende a identificar rápidamente cuál es el mejor seguro de auto para ti. Todo lo que tienes que saber lo encuentras aquí en inter.mx. ¡Ingresa ahora!",
      content: [
        {
          header: ``,
          body: `Para nadie es un secreto que el mercado de seguros no para de crecer. Cada día nuevas alternativas salen al mercado con todas las ganas de conquistarlo. Y por esto es que en la actualidad encontrar el mejor seguro de auto podría ser una ardua tarea.
          Pero como dicen por ahí: “para todo problema, hay una solución”. Y para tu fortuna, <>inter.mx</> ha llegado para hacer esta tarea mucho, mucho, mucho más fácil.
          
          ¿Quieres saber cómo?
          Keep reading…`,
        },
        {
          header: `No enloquezcas buscando el mejor seguro de auto`,
          body: `Con <>inter.mx</> podrás cotizar, comparar y contratar el mejor seguro de auto en menos de lo que canta un gallo. Pero antes de esto, es importante que conozcas lo que NO debes hacer al momento de buscar el seguro de tu coche.
          
          ¡Aquí te lo contamos rápidamente!`,
        },
        {
          header: `Elegir tu seguro sólo por el precio`,
          body: `Aunque sabemos que el factor económico es fundamental, es importante que tengas en cuenta cuáles son las coberturas y los montos asegurados. Como dicen popularmente: “a veces lo barato sale caro”. 
          Recuerda que en realidad el precio no es lo que determina si un seguro es bueno o no. Lo que realmente importa es que la póliza que contrates cuente con las coberturas que tú como titular del seguro necesitas. Así, además de cuidar de tu coche, también cuidas tu bolsillo, pues evitas gastos altos e inesperados.
          ¡Si se trata de bienestar y seguridad, el precio no es lo más importante!`,
        },
        {
          header: `No revisar seriamente las coberturas`,
          body: `Antes de contratar tu seguro de auto, es importante que revises las coberturas una a una. Así podrás analizar si éstas cubren las cosas a las que realmente está expuesto tu auto según la gama, la marca, el uso que le das, entre otros. Puedes contratar el seguro más costoso, pero si no responde directamente a las necesidades de tu coche o las tuyas como conductor o conductora, seguro que no será la mejor opción.`,
        },
        {
          header: `Contratar una póliza demasiado básica`,
          body: `Las pólizas básicas son siempre las más económicas. Pero como su nombre indica, son coberturas muy limitadas. Generalmente los montos asegurados son bajos y las coberturas bastante reducidas. Por eso, muchas veces es mejor invertir un poco más de dinero para tener más garantías.
          También puedes considerar añadir coberturas adicionales –e indispensables– a tu seguro de coche para que se adapte mejor a tus necesidades, como: `,
          list: [
            `Atención en carretera`,
            `Daños menores como rayones o cambios de neumáticos`,
            `Asesoramiento jurídico`,
            `Centros de atención (entre más sean, mejor)`,
          ],
        },
        {
          header: ``,
          body: `¡De esto se trata encontrar el mejor seguro de auto!
          Además, es importante que siempre que estés interesado en un seguro –sea el que sea, revises especificaciones sobre tiempos de espera, deducibles y demás condiciones que pueden variar de póliza en póliza. Así encontrarás el seguro que más se ajuste a tus necesidades e intereses.`,
        },
        {
          header: `Y en este mar de alternativas, ¿Cómo encontrar la mejor opción?`,
          body: `Con el comparador de <>inter.mx</> podrás encontrar el mejor seguro de auto, sí o sí. Sólo debes ingresar algunos datos y verás lo fácil y rápido que es. 
          
          ¡En un dos por tres tendrás la póliza de tus sueños! 
          Recuerda que con <>inter.mx</> puedes personalizar tu seguro y adquirir todas las coberturas que necesites para ti, tu coche, tu hogar, tu familia y hasta tu mascota.
          
          ¡Anímate a probar nuestro servicio! Aquí seguro, seguro encuentras el plan que necesitas para que tu coche esté cubierto frente a cualquier imprevisto, por más pequeño que sea.
          ¡Y no olvides seguirnos en nuestros perfiles de <>Instagram</> y <>Facebook</>!`,
        },
      ],
    },
    {
      img: BG58,
      date: "18 Marzo 2022",
      autor: "adminInter",
      title: "Seguros para Mascotas: ¿Un lujo o una necesidad?",
      body: "Cuida a tu mejor amigo contratando un seguro para mascotas. Conoce todos los beneficios que esta cobertura tiene para el Firulais y tu bolsillo.",
      content: [
        {
          header: ``,
          body: `Algunas personas pensarán que los seguros para mascotas no son una adquisición 100% necesaria. No obstante, todos sabemos que la vida es más costosa cada día, y eso incluye el precio por el veterinario, el baño, la comida y hasta los juguetes de nuestros amigos peludos.
          Por eso, contar con el respaldo de una <>póliza</> que cubra algunos de estos gastos básicos puede ser de gran ayuda para nuestras mascotas y nuestro bolsillo. 
          
          Te contamos paso a paso cómo funcionan los seguros para mascotas y por qué ya es hora de que contrates el tuyo.`,
        },
        {
          header: `¡Asegura a tu mascota, Karen!`,
          body: `Los seguros para mascotas nacen como respuesta a una necesidad de los dueños y dueñas de perros y gatos en todo el mundo. Y es que aunque le demos a nuestros peludos todo el amor y los cuidados necesarios, hay ocasiones en que una revisión extra no está de más.
          Por eso, en <>inter.mx</> descubrirás los beneficios y coberturas de algunas pólizas para el cuidado y respaldo de tu amigo peludo.`,
        },
        {
          header: `Coberturas indispensables de los seguros para mascotas`,
          body: `¡Amiga, date cuenta! El seguro de tu peludo no es un lujo, sino una necesidad. Verás, con el paso del tiempo, tener un plan de atención especializado para las necesidades cambiantes de tu gato o perro será de mucha ayuda, pues te permitirá garantizar el bienestar de tu mascota y cuidar tus finanzas. 
          Si eres novato en este mundo de los seguros para mascotas, no te preocupes, hemos reunido las coberturas más básicas que todo buen plan de cuidado para mascotas debe tener:`,
          list: [
            `Gastos Médicos`,
            `Responsabilidad civil`,
            `Gastos Funerarios`,
            `Eutanasia`,
          ],
        },
        {
          header: ``,
          body: `Adicional a esto, otros seguros para mascotas cubren uno o dos baños cada semestre, vacunación anual, gastos por daños a otros animales, entre otros. Tú sólo pregunta a uno de <>nuestros asesores</> y encontrarás la póliza que has estado buscando.`,
        },
        {
          header: `¿Así de fácil?`,
          body: `¡Si! Tener tu mascota segura con <>inter.mx</> es fácil, facilísimo.
          
          Sólo debes asegurarte de que tu mascota cumpla con algunos requisitos:`,
          list: [
            `Entre 2 meses y 9 años de edad`,
            `Ser parte de los gatos o perros catalogados como domésticos`,
            `NO realizar actividades de búsqueda o rescate`,
          ],
        },
        {
          header: ``,
          body: `Como verás, la mayoría de felinos y caninos son aptos para el sistema de coberturas que hemos expuesto. Así que anímate a asegurar a tu peludo antes de que cumpla la edad límite. Recuerda que además del amor que le das cada día, también se trata de garantizar su calidad de vida.  
          En <>inter.mx</> estamos comprometidos con brindar a tu peludo la mejor atención, porque ya sabemos que las nuevas generaciones aman a sus mascotas tanto como a sus hijos. 
          
          ¿Y cómo no hacerlo? Ellos siempre alegran nuestros días y los paseos por el parque. 
          ¡Anímate a probar nuestro servicio! En inter.mx seguro, seguro encuentras el plan que necesitas para tu amigo. 
          ¡Y no olvides seguirnos en nuestros perfiles de <>Instagram</> y <>Facebook</>!`,
        },
      ],
    },
    {
      img: BG59,
      date: "18 Marzo 2022",
      autor: "adminInter",
      title:
        "Elige el Mejor Seguro de Moto - ¡Fácil, rápido y al mejor precio!",
      body: "¿Te urge salir a rodar con la mejor protección? En inter.mx te decimos lo que debes considerar para encontrar el mejor seguro de moto ¡Cotiza ahora!",
      content: [
        {
          header: ``,
          body: `Las motos son rápidas, prácticas y te ahorran tanto dinero en gasolina, como tiempo entre tus destinos. Sin embargo, como cualquier vehículo de motor, requieren cuidados, mantenimiento y un seguro de moto que brinde la protección necesaria para viajar tranquilamente. 
          En <>inter.mx</> le quitamos lo complicado a los seguros. Y por eso, aquí te vamos a dar algunos consejos súper prácticos para que contrates el mejor seguro de moto. 
          ¡Ponte el casco y toma nota!`,
        },
        {
          header: `¿Qué debo considerar en un seguro de moto?`,
          body: `Si eres nuevo en el mundo del motociclismo o simplemente quieres cambiar tu seguro de moto porque el que tienes no te convence mucho, seguro que esto te interesa.`,
        },
        {
          header: `Revisa las coberturas disponibles`,
          body: `Lo primero en lo que debes fijarte para encontrar el seguro ideal son las coberturas que incluye. Éstas deben responder a tus necesidades, pero también a tus posibilidades. Es decir, tienes que poner todo en una balanza. 
          ¡Ni muy muy, ni tan tan!
          Todo depende del uso que le das a tu moto, el modelo, valor, los kilómetros que recorres en ella, y hasta la frecuencia con la que la dejas estacionada en lugares seguros y no tan seguros.
          Haz memoria y repasa todos esos riesgos y factores a los que tu moto se ve expuesta de acuerdo con las actividades que realizas con ella. Todo cuenta al momento de elegir el mejor seguro para tu moto.
          Después, analiza las coberturas que realmente pueden ser útiles para tu día a día. Por ejemplo, algunas de las más básicas y que nunca deben faltar en tu plan son:`,
          list: [
            `Daños materiales`,
            `Robo total`,
            `Responsabilidad civil por daños a terceros (bienes y personas)`,
          ],
        },
        {
          header: ``,
          body: `Éstas te van a ayudar a resolver prácticamente cualquier situación que te encuentres en el camino. No obstante, sólo tú sabes qué coberturas se ajustan mejor a tus necesidades. Para ello, existen algunas adicionales que te van a ofrecer una protección más completa.
          En este sentido, algunas de las coberturas que pueden ser el complemento ideal para tu plan son:`,
          list: [
            `Muerte accidental`,
            `Accesorios (daños por colisión o volcadura)`,
            `Gastos médicos (conductor y acompañantes)`,
            `Asistencia jurídica`,
            `Asistencia vial (grúa, gasolina, cambio de llantas, reposición de llaves, entre otros)`,
          ],
        },
        {
          header: `Compara los seguros y planes`,
          body: `Después de revisar las coberturas que se adaptan mejor a tu estilo de vida sobre dos ruedas, lo que sigue es comparar los planes. Cada seguro de moto es diferente, pero está diseñado para cubrir las necesidades de cada persona.
          Pero, ¿cómo puedes comparar todos esos seguros, planes y coberturas? ¡Tranqui! Es súper fácil. Con <>inter.mx</> puedes cotizar, comparar y contratar tu seguro de moto desde un sólo lugar.

          
          Esto te va a ahorrar la tarea de ir de una aseguradora a otra y anotar los precios de cada plan para lueeeego poder compararlos. Sólo tienes que entrar a nuestro comparador, proporcionar unos datos muy básicos y en segundos estarás cotizando con las aseguradoras líderes.`,
        },
        {
          header: `¡Encuentra tu seguro ideal, fácil, rápido y al mejor precio!`,
          body: `Con <>inter.mx</>, además de encontrar tu seguro ideal, también vas a poder personalizar la forma de pago ideal que se ajuste mejor a tus necesidades.  
          
          Y como un plus, contarás con una App desde la cual podrás controlar, gestionar y consultar tu seguro para moto siempre que quieras. 
          Protege lo que más amas, fácil, rápido, al mejor precio y con una experiencia personalizada de principio a fin en <>inter.mx</>. 
          
          Síguenos en <>Facebook</> – <>LinkedIn</> – <>TikTok</> – <>YouTube</>.`,
        },
      ],
    },
    {
      img: BG43,
      date: "18 Febrero 2022",
      autor: "adminInter",
      title: "¿A qué Hospitales tengo acceso con mi Seguro Médico?",
      body: "¿Qué es y cómo funciona la red hospitalaria a la que accedes contratando un Seguro Médico? ¡Entra y conoce todos los hospitales según tu plan!",
      content: [
        {
          header: ``,
          body: ` 
          Si tienes un seguro médico, te has preguntado en qué hospitales podrás atenderte cuando lo necesites. Un seguro de salud te permite recibir atención médica en un conjunto de hospitales según el lugar en donde te encuentres y el plan que hayas contratado.
          En <>inter.mx</> te vamos a contar cuáles son esos hospitales según el plan de salud que hayas elegido.`,
          list: [],
          footer: ``,
        },
        {
          header: `Ventajas de una red hospitalaria`,
          body: `¿Sabes cuáles son las ventajas de contar con una red hospitalaria en tu seguro médico? Lo primero que debes saber es que cuando eliges tu seguro, escoges también un conjunto de hospitales de acuerdo a tu nivel de protección. Así sabrás qué servicios médicos podrás recibir si un día lo necesitas.
          En inter.mx puedes acceder a la <>red hospitalaria</> completa de tu seguro, estado por estado. Busca donde dice “¿Qué hospitales incluye mi plan de seguro de gastos médicos mayores?” Selecciona el estado en donde quieres buscar y verás todos los centros médicos disponibles para cada plan.
          Como verás, la lista es larguísima. Al contratar tu seguro médico, en realidad te cubres por una red hospitalaria a lo largo y ancho de todo México. Es bueno saberlo por si te encuentras en otro estado, ¿no crees?`,
        },
        {
          header: `Planes para contratar un seguro médico con inter.mx`,
          body: `Nosotros dividimos los planes de acuerdo a las necesidades de cada quién y el costo. Por eso contamos con tres categorías distintas para la contratación de un seguro médico. Cada uno de estos planes te da acceso a una red hospitalaria diferente.`,
        },
        {
          header: `Esencial`,
          body: `Tiene una suma asegurada de $6 millones, un deducible de $19,000, un tope de coaseguro en $42,000 y un nivel hospitalario esencial.`,
        },
        {
          header: `Protegido`,
          body: `Contempla una suma asegurada de $25 millones, deducible de $19,000 tope de coaseguro $62,500 y un nivel hospitalario óptimo.`,
        },
        {
          header: `Blindado`,
          body: `La suma asegurada es de $100 millones, deducible de $30,000, tope de coaseguro en $60,000 y un nivel hospitalario amplio.
          ¡Busca la cobertura que más te convenga en inter.mx! Cotiza, compara y elige.
          Si tienes cualquier duda, te confundimos o no sabes qué onda, ¡nosotros te ayudamos! Escríbenos o échanos un telefonazo. 
          WhatsApp: 55 4326 9037
          Teléfono: 55 442 46837 
          Email: <>hola@inter.mx</>.`,
        },
      ],
    },
    {
      img: BG44,
      date: "18 Febrero 2022",
      autor: "adminInter",
      title: "¿Mi Seguro para Auto Cubre Daños por Baches?",
      body: "Un seguro para auto puede ser tu mejor aliado si un bache se atraviesa en tu camino. ¡Entra y conoce todo sobre esta cobertura y sus especificaciones!",
      content: [
        {
          header: ``,
          body: `¿De dónde salen los baches? Nadie lo sabe, y tal vez sea un misterio que nunca podremos resolver. Pero lo que sí sabemos es que pueden provocar daños a tu coche. Por eso, hoy te contamos qué para su te encuentras con alguna falla en el camino y cómo un seguro para auto te echa una manita súper fácil y rápido. 
          ¡Ya sabes que con <>inter.mx</> todo es más fácil!`,
        },
        {
          header: `Principales daños causados por baches`,
          body: `El daño que tu coche puede tener en realidad depende de algunos factores básicos como la velocidad, la profundidad del bache y otros factores que te contaremos a continuación. Conoce cómo tu seguro para autos te respalda -o no- en cada caso. 
          Si caes en un bache, no tienes mucho de qué preocuparte, en el peor de los casos tu coche sólo terminará un poquito desalineado. Pero aguas con los baches que son tan grandes que parecen cenotes sagrados…
          Te contamos cuáles son los daños más comunes y lo fácil que es solucionarlos.`,
        },
        {
          header: `“Chichones” en los neumáticos`,
          body: `Los “chichones” salen porque el caucho interno de tus neumáticos se separa de la pared exterior debido al impacto. Éstos pueden aparecer apenas caes en el bache o unos días después. Aunque no es algo por lo que debas salir corriendo, es importante que sepas que por el bien de tu coche debes buscar un repuesto lo antes posible. Así previenes más daños a tus llantas con el paso del tiempo. 
          Tú eliges si llevas tu coche a un mecánico, <>cambias la llanta tú solito</>, o mejor llamas a tu seguro (obvio, la opción más cómoda).`,
        },
        {
          header: `Ruedas descarriadas`,
          body: `Un bache muy profundo o muy grande puede causar alguna fisura de tu rueda, así como torcerla hacia algún lado. Por eso, siempre que pises un bache de esos que duelen hasta el alma, revisa casi con lupa que ninguna grieta haya quedado por ahí en tu neumático. 
          Si encuentras una fisura, ¡fácil! Contacta con tu agente de seguros de <>inter.mx</> y pregunta por las extensiones de tu seguro.`,
        },
        {
          header: `Coche desalineado`,
          body: `La dirección es una de las partes que más se desajusta cuando pasas por un bache.  Esto puede suceder cuando intentas esquivar un bache creyéndote Checo Perez. Recuerda que tu dirección se puede desajustar casi con cualquier cosa, y lo más seguro es que tu coche necesite un balanceo o una revisión preventiva. 
          ¡Pero tranqui, que no cunda el pánico! Con las extensiones de tu seguro para autos en <>inter.mx</> tienes ésta y muchas coberturas más. 
          `,
        },
        {
          header: `Dile adiós a los micro-daños por los baches con tu seguro para auto`,
          body: `Si eres parte de esta Chilanga Banda, sabrás que uno de los grandes inconvenientes de las calles de Ciudad de México son los baches. Tanto así, que el gobierno decidió habilitar una línea para reportar la aparición de baches en cada colonia (línea *0311).`,
        },
        {
          header: `Entonces, ¿Mi seguro de auto sí cubre daños por baches?`,
          body: `La respuesta es sí, con algunas excepciones que podrás consultar directamente en tu póliza. Recuerda que cada aseguradora tiene diferentes condiciones. 
          Pero bueno, si llegas a caer en uno de esos hoyos negros sabes que puedes llamar a tu seguro para autos y solicitar la asistencia que necesites, súper fácil y rápido. 
          Comunícate con la línea de atención de tu aseguradora. Después de que analicen tu caso te dirán qué pasos debes seguir y ¡listo!
          Para mayor facilidad, puedes consultar y gestionar tu póliza directamente en nuestra app. ¡Descargala en <>Google Play</> o en la <>App Store!</> 
          ¡Todo lo que necesitas a un clic de distancia!
          Con <>inter.mx</> puedes buscar, cotizar y contratar el seguro para autos que más te convenga. Recuerda que cada aseguradora maneja y aplica diferente sus coberturas. 

          ¡Anímate a probar nuestro servicio! Aquí seguro, seguro encuentras el plan que necesitas para tu auto. 
          ¡Y no olvides seguirnos en nuestros perfiles de <>Instagram</> y <>Facebook!</>`,
        },
      ],
    },
    {
      img: BG45,
      date: "18 Febrero 2022",
      autor: "adminInter",
      title: "Cuida tu Coche Nuevo con el ¡Mejor Seguro para Auto!",
      body: "Tu seguro de auto puede ser tu superhéroe. Descubre cómo te puede ayudar a cuidar tu coche nuevo, mientras tocamos madera para que nunca tengas que usarlo.",
      content: [
        {
          header: ``,
          body: ` 
          Ya sé, a veces no sabes para qué tienes el mejor seguro para auto si nunca te ocurre nada. Pero aunque tengas muy buena suerte y seas tan hábil al volante como Checo Pérez, es importante que protejas tu coche.
          Cuando proteges tu carro, lo que aseguras es tu tranquilidad y el gozo que te da tu coche. Y mantener esa paz siempre es súper importante al volante, ¿no crees? Esto te lo dice <>inter.mx</> y también lo diría cualquier persona que te quiera.
          Estamos seguros que no quieres que nada le pase, sobre todo cuando comprar tu auto nuevo, te ha costado tanto y luce tan cool con su pintura nueva brillando hasta en la noche. Siempre es mejor prevenir, seguro, seguro. Aquí te damos algunos consejos para que tu coche siempre esté como nuevo.`,
        },
        {
          header: `Te platicamos tres razones por las que es bueno tener el mejor Seguro para Auto`,
          body: `Una empresa que te ofrece el mejor seguro para auto, también debe hacerte más fácil lo difícil. Esto simplemente vale oro. El mundo de los seguros no tiene por qué ser un terrible espanto… ¿Qué mejor que ahorrarse esas pilas y pilas de papeles? Pero si la indecisión te está ganando, te explicaremos por qué sí está cool tener una buena cobertura.`,
        },
        {
          header: `Protege tu inversión`,
          body: `Somos conscientes del esfuerzo que implica comprar un auto, claramente no es como ir a comprar una hamburguesa. Por eso insistimos: que siempre esté seguro, seguro.   
          Con una buena póliza, todo lo que gastaste en tu coche estará protegido por profesionales. Es como si tuvieras tu propia liga de superhéroes.`,
        },
        {
          header: `Puedes manejar chill`,
          body: `La confianza de tener el mejor seguro para auto te permitirá manejar con toda tranquilidad. No importa si vas a desayunar, a ver Spider Man o a la casa de tu abuelita. Un seguro siempre te dará la confianza que necesitas para vivir la vida como tú quieres. 
          Además, nosotros sabemos que amas la tecnología de tu coche. Así que no hay nada mejor que estar seguro, seguro para gozar de todas sus comodidades. 
          Claro que no queremos que intentes imitar a Checo Pérez, pero sí que disfrutes de tu coche nuevo sin miedo.`,
        },
        {
          header: `¡Al rescate!`,
          body: `Imagínate que está por empezar la final del fútbol donde va a jugar tu equipo y justo se te descompone el coche en pleno camino. ¿Quién crees que irá a rescatarte? Por supuesto, una grúa o equipo de mecánicos. Pero, ¿quién los enviará súper rápido para que alcances a ver el partido? ¡El mejor seguro para auto, obvio! 
          En inter.mx no sólo queremos que cuides tu coche, sino que sigas haciendo todo lo que haces con él.
          Como ves, asegurar tu auto es una sabia decisión. Si ya lo hiciste, tu vida es más tranquila, ¿no crees?. Pero si no lo has hecho, ¿¡qué esperas!?           
          Entra a <>inter.mx</> y ¡cotiza, compara, y contrata tu seguro para auto! ¡Es rapidísimo!


          ¡Síguenos en <>Instagram</> y <>Facebook</> para conocer más sobre las ventajas y beneficios que tenemos para ti!


          *Para mayor información sobre las coberturas pregunta a tu asesor o verifica con tu aseguradora.`,
        },
      ],
    },
    {
      img: BG46,
      date: "18 Febrero 2022",
      autor: "adminInter",
      title: "¡Obtén tu cobertura médica con el mejor Bróker de Seguros!",
      body: "¿Para qué sirve un bróker de seguros? Para encontrar la mejor atención médica y cuidar esa vida tan cool que llevas. ¡Aquí te platicamos todo!",
      content: [
        {
          header: ``,
          body: `Si sientes que tu bróker de seguros no entiende tu idioma, será mejor que lo cambies por <>inter.mx</>. Nosotros le quitamos lo complicado a los seguros y te hablamos sin rodeos ni letras chiquitas. 

          En <>inter.mx</> tendrás todo lo que necesites antes de que Checo Pérez termine de dar una vuelta a la manzana. Con nosotros contratas súper rápido, fácil y con la mejor experiencia personalizada. Nada de papeleos engorrosos ni agentes aburridos. ¡Por eso somos el bróker #1 de México!`,
        },
        {
          header: `¿Por qué inter.mx es el mejor bróker de seguros?`,
          body: `Somos el mejor bróker de seguros porque inter.mx está lleno de gente súper cool, profesional, apasionada y comprometida para protegerte de la mejor manera, escuchando lo que tú necesitas y acompañándote en todo momento. Pero sobre todo, somos el mejor bróker porque hablamos el mismo lenguaje que tú, sin términos súper complicados ni letras chiquitas.`,
        },
        {
          header: `Encuentra la mejor cobertura sin salir de casa`,
          body: `Un buen bróker de seguros hará tu vida más sencilla. Olvídate de pasar largas horas haciendo trámites y perdiendo el tiempo que usarías en hacer lo que te gusta. 
          Para contratar tu seguro sólo tienes que seguir estos pasos:`,
          list: [
            `Ingresa a <>inter.mx</>`,
            `Selecciona el tipo de seguro que deseas cotizar.`,
            `Da clic en "cotizar".`,
            `Ingresa tus datos.`,
          ],
        },
        {
          header: ``,
          body: `Buscaremos los productos que mejor se adapten a ti. Compara, personaliza, contrata, ¡y listo! En muy poco tiempo tendrás tu póliza para proteger lo que más quieres.
          ¿Y luego? ¡Nada, ya has terminado! Rápido y súper fácil, para que veas que no prometemos en vano. 
          Mientras piensas qué necesitas para mejorar tu vida, aquí te damos la lista de los diferentes seguros que puedes encontrar con nosotros:`,
          list: [
            `Bien Seguro`,
            `Viaja Seguro`,
            `Coche Seguro`,
            `Mascota Segura`,
            `Vida Segura`,
            `Médico Seguro`,
          ],
        },
        {
          header: ``,
          body: `Nuestra premisa más importante es quitarle lo complicado a los seguros. Por todas estas razones –modestia aparte– somos tu mejor bróker de seguros.
          En <>inter.mx</> queremos seguir comunicándonos contigo de igual a igual. Para estar más cerquita, dinos qué piensas en nuestras redes. 
          Te invitamos a seguirnos en <>Facebook</> e <>Instagram</> y, por supuesto, a leer nuestro <>blog</>.

          ¡Protege lo que más amas, fácil, rápido y al mejor precio!`,
        },
      ],
    },
    {
      img: BG47,
      date: "18 Febrero 2022",
      autor: "adminInter",
      title: "Seguro de Coche: ¿Puedo asegurar mis llantas y rines?",
      body: "Al contratar un seguro de coche puede ser que necesites saber lo siguiente: ¿Cubre mis llantas? ¿Y los rines? ¡Descúbrelo en esta nota!",
      content: [
        {
          header: ``,
          body: `Lo más importante de un seguro de coche es que cumpla con su cometido, ¡obvio! Cuando estacionas tu auto “a la vuelta” o en un estacionamiento público para ir a hacer las compras, tus llantas y rines no deberían ser una preocupación. La función de tu cobertura es que puedas confiar en ella 
          Tus llantas y rines son muy valiosos, por eso no está de más preguntar si quedarán cubiertos con el pago de tu póliza. En días como éstos, no podemos confiar en las letras chiquitas. Mejor que todo esté claro como el agua, ¿no crees? 
          Tú cuidarás tu bolsillo y nosotros de ti. Queremos que encuentres un seguro perfecto para ti que te permita disfrutar la vida al máximo.`,
        },
        {
          header: `¿Mi seguro de coche cubre mis llantas y rines?`,
          body: `Tu seguro de coche tiene que ser como el escudo del Capitán América: debe estar hecho perfecto para ti. En inter.mx nos encargamos de ajustar este servicio a tu estilo de vida. Tus necesidades no se nos pasan por alto, y tampoco tus preocupaciones. Por eso, si tu inquietud es la cobertura de tus llantas y rines, te daremos las respuestas que buscas.
          En realidad, tanto para roturas por baches o robos de llantas o rines, es necesario solicitar una extensión de tu póliza. Se trata de un beneficio que debe pedirse aparte una vez que ya contrataste tu seguro. 
          De todas formas, no lo olvides: ¡en inter.mx estamos a un telefonazo o un mensajito de distancia para resolver todas tus dudas!`,
        },
        {
          header: `¿Cómo cuidar tus llantas y rines?`,
          body: `Incluso si tienes el mejor seguro de coche, no puedes esperar una protección omnipresente. Hay algunas cosas que debes hacer tú para cuidar la salud y la belleza de tu auto.
          Para cuidar tus llantas es fundamental que controles su presión (debe estar entre 32 y 35 psi), revises al menos una vez al mes sus bandas de rodadura y procures la rotación de las ruedas cada medio año. Esto último es para que el desgaste de tus llantas sea más lento, aunque definitivamente no es lo más económico.
          Por otro lado, no está de más que cambies tus neumáticos cuando veas que empiezan a desgastarse.
          ¿Que cómo notas si tus llantas están averiadas? Simple: tan sólo debes observarlas de cerquita. Si ves grietas o su desgaste es desproporcionado, debes cambiarlas. Si no lo haces, hasta podrían afectar el kilometraje de auto aunque tengas el más poderoso seguro de coche.
          En el caso de tus rines, lavándolos con agua y jabón estarán perfectos. Tus movimientos tienen que ser suaves para no dañar la superficie. También puedes encerarlos para mostrarles tu cariño y protegerlos de los rayos ultravioletas.`,
        },
        {
          header: `¿Por qué recurrir a inter.mx?`,
          body: `En primer lugar, porque no importa qué tipo de seguro de coche estés buscando, con nosotros encuentras tu seguro ideal, rápido, fácil, al mejor precio y con una experiencia personalizada. 
          En segundo lugar, porque siempre vamos a estar a tu lado con el respaldo que necesitas. Con nuestra app puedes llevar tus pólizas contigo en un sólo lugar, lo que es practiquísimo.
          <>inter.mx</> le quita lo complicado a tu seguro de coche y te escucha. Además, te ofrece una gran variedad de coberturas con increíbles beneficios según el plan que elijas:`,
          list: [
            `Daños materiales`,
            `Robo total`,
            `Responsabilidad civil`,
            `Gastos médicos`,
            `Defensa legal`,
            `Asistencia en viajes`,
          ],
        },
        {
          header: ``,
          body: `Para obtener un seguro, seguro, ¡sólo tienes que <>contactarnos</>!



          Síguenos en <>Instagram</> y <>Facebook</> para mantenerte al tanto de nuestros productos, promociones y novedades.



          ¡Asegura eso que amas, fácil rápido y al mejor precio!`,
        },
      ],
    },
    {
      img: BG48,
      date: "18 Febrero 2022",
      autor: "adminInter",
      title: "¿Cuáles son las exclusiones de un Seguro de Gastos Médicos?",
      body: "Seguro que ya has escuchado sobre las exclusiones de tu seguro de gastos médicos. ¡Pero no es tan grave como parece! Te contamos cómo funcionan.",
      content: [
        {
          header: ``,
          body: `Seguro que ya has escuchado por ahí sobre las exclusiones que tienen algunos seguros. Y aunque sabemos que esto puede parecer una jugada poco confiable, realmente no es tan grave como parece. Hoy te contamos rápidamente cuáles son las principales exclusiones de un seguro de gastos médicos y porqué. 
          Antes de comenzar, debes saber que en el mundo de los seguros, cada compañía aseguradora tiene sus propias reglas con las exclusiones. Y aunque hoy te vamos a mencionar las más comunes, ten presente que éstas pueden cambiar según el seguro que decidas contratar.`,
        },
        {
          header: `Principales exclusiones de un seguro de gastos médicos`,
          body: `Las exclusiones de un seguro de gastos médicos, como podrás deducir, se tratan de algunas situaciones particulares que tu póliza no cubre. Pero esto no es tan terrible, ¡lo prometemos! En realidad, las exclusiones tienen que ver con los tiempos del contrato y demás cosas básicas que cualquiera podría asumir a la hora de contratar un seguro. 
          Pero para que no haya ni una duda, vamos a hacer un breve resumen de las principales exclusiones:`,
        },
        {
          header: `1. Condiciones preexistentes`,
          body: `Las enfermedades preexistentes y demás síntomas que puedas tener antes de contratar tu seguro no serán cubiertos. Hablamos de padecimientos más complicados como diabetes, hipertensión, lesiones, entre otros, que son parte de las exclusiones de un seguro de gastos médicos. 
          Aquí también podemos incluir aquellos síntomas que puedas padecer en el <>periodo de espera,</> que es un colchón de tiempo entre la contratación del seguro y la entrada en vigencia.`,
        },
        {
          header: `2. Servicios dentales y tratamientos en los ojos`,
          body: `Algunos seguros (aunque no podemos decir que todos), no cubren servicios dentales, ni tratamientos en los ojos, bajo la idea de que “son tratamientos estéticos”. 
          Si deseas tener esta cobertura, es mejor que compares las coberturas de varios seguros de gastos médicos de la mano de un <>bróker de seguros especializado, como inter.mx</>.

          Lo mismo pasa con tratamientos de medicina alternativa o intervenciones estéticas como cirugías plásticas.`,
        },
        {
          header: `3. Maternidad`,
          body: `En el mercado te encontrarás con que es poco común que los gastos de maternidad sean una de las exclusiones de un seguro de gastos médicos. Este servicio generalmente corresponde a otro tipo de cobertura o a una extensión de ésta. 

          ¡Pero hoy estás de suerte! Con <>inter.mx</> esta cobertura sí aplica, siempre que haya pasado el periodo de espera acordado en el contrato.`,
        },
        {
          header: `Otras exclusiones recurrentes`,
          body: `Adicionalmente a las exclusiones que ya te contamos, algunos seguros también eliminan coberturas en:`,
          list: [
            `Tratamientos de salud mental.`,
            `Accidentes en espacios deportivos.`,
            `Tratamientos ambulatorios.`,
            `Coberturas de gastos adicionales como transportes, alimentación especial y traslados.`,
            `Intervenciones quirúrgicas de alto nivel.`,
          ],
        },
        {
          head: ``,
          body: `Para conocer a fondo cada cobertura, es importante que revises puntualmente las coberturas que tiene explícitas cada una de las aseguradoras. 
          Con <>inter.mx</> puedes comparar, cotizar y contratar el seguro que más te convenga. Recuerda que cada aseguradora maneja y aplica diferente sus coberturas, y por ende, las exclusiones de tu seguro de gastos médicos dependen de cada acuerdo. 



          En <>inter.mx</> encuentras tu seguro seguro. 
          

          Y no olvides seguirnos en nuestros perfiles de <>Instagram</> y <>Facebook</> para enterarte de todo.`,
        },
      ],
    },
    {
      img: BG49,
      date: "18 Febrero 2022",
      autor: "adminInter",
      title: "Compara tu Seguro de Gastos Médicos Mayores ¡El mejor precio!",
      body: "Cotiza tu Seguro de Gastos Médicos Mayores con el 1er Comparador de Seguros de México. En inter.mx ¡Cotiza, compara y contrata en minutos!",
      content: [
        {
          header: ``,
          body: `<>inter.mx</> es el primer comparador de seguro de gastos médicos mayores que te ofrece la posibilidad de comparar en tiempo real las mejores opciones del mercado. 
          ¡Ya no necesitas ir de una página a otra! Con nosotros encuentras justo lo que necesitas en un sólo lugar de forma fácil, rápida y al mejor precio.`,
        },
        {
          header: `¡Cotiza, compara y contrata!`,
          body: `No importa para quién quieras el seguro de gastos médicos mayores. Puede ser para tus hijos, para ti, tu pareja o tus papás, aquí encuentras porque encuentras lo que estás buscando.
          Además, con <>inter.mx</> obtienes beneficios inmediatos que te harán la vida muchísimo más fácil y sencilla. 
          ¡Sí! Leíste bien: fácil y sencilla 
          Con nosotros puedes ahorrarte un buen porcentaje de tu presupuesto. Pero ¿por qué te decimos esto? Bien, porque con el comparador de <>inter.mx</> vas a poder seleccionar las mejores opciones dentro del mercado y contratar la que mejor se adapte a tu estilo de vida. ¡No pagues más y mejor ahórrate una lana!
          Vas a pagar sólo por lo que necesitas ¡Olvídate de las letras chiquitas! Tendrás el seguro de gastos médicos mayores ideal para estar protegido tal y cómo tú quieres y sin pagar de más. 
          ¡Pero eso no es todo! También te vas a ahorrar muuuuuucho tiempo. Hasta te vas a quedar con las ganas de seguir explorando todas las opciones de seguros que tenemos para ti. Recuerda que con nosotros también encuentras seguros de vida, de auto, de mascota, de casa  ¡Y mucho más! 
          Te puede interesar: <>Guía definitiva para comprar tu seguro médico</>.`,
        },
        {
          header: `Contrata tu seguro de gastos médicos mayores ideal`,
          body: `El proceso para comparar tu seguro ideal es sencillo y personalizado. Es decir, sólo necesitamos saber tu nombre y cómo prefieres que te asesoremos: vía telefónica o email. 
          ¡Tú decides!
          Lo que viene a continuación es simple. Después de proporcionarnos algunos datos básicos te llevaremos de la mano a través de los diferentes planes de salud que las mejores aseguradoras del país tienen para ti. 
          Conforme a tus necesidades y estilo de vida podrás ir comparando y analizando las ofertas según la protección que buscas. Así, en cuestión de minutos, tendrás a tu alcance el seguro con el plan que mejor te convenga. 
          Y listo, en cuanto tengas tu opción seleccionada podrás pagar en línea desde la comodidad de tu casa u oficina. Es más, si estás de vacaciones también puedes realizar todo el proceso desde la playita con tu piña colada en mano.
          ¿Lo ves?
          Todo es más fácil con <>inter.mx</>. ¡Le quitamos lo complicado a los seguros!
          Te puede interesar: Si tu coche fue amor a primera vista ¡Protégelo! Seguro de Coche.`,
        },
        {
          header: `Descarga nuestra App`,
          body: `Por último, lo mejor de contratar tu seguro de gastos médicos mayores con <>inter.mx</> es que podrás llevar tu póliza y consultarla en todo momento. Sólo tienes que bajar nuestra App en <>Google Play</> o en la <>App Store</> y listo.
          Desde tu celular podrás:`,
          list: [
            `Registrar todas tus pólizas (sin importar la aseguradora)`,
            `Contactar con tu aseguradora`,
            `Revisar la oferta de <>inter.mx</>`,
            `Acceder a la Red de Hospitales de todo México`,
            `Aprender con “El ABC de los Seguros`,
          ],
        },
        {
          head: ``,
          body: `No olvides que somos el primer comparador de seguros en México. ¡No te quedes atrás y encuentra tu seguro ideal! 
          Protege lo que más amas, fácil, rápido, al mejor precio y con una experiencia personalizada de principio a fin. 
          Síguenos en <>Facebook</> – <>LinkedIn</> – <>TikTok</> – <>YouTube</>.
          `,
        },
      ],
    },
    {
      img: BG50,
      date: "18 Febrero 2022",
      autor: "adminInter",
      title: "3 Nuevas Coberturas de tu Seguro de Gastos Médicos en inter.mx",
      body: "Tu seguro de gastos médicos tiene un par de sorpresas para ti. ¿Te interesa? Entra en la nota y entérate cuáles son las nuevas coberturas.",
      content: [
        {
          header: ``,
          body: `Si tener un seguro de gastos médicos ya te parecía una gran idea, ahora pegarás brincos de felicidad con esta gran noticia. Antes que nada, ¡toquemos madera! Ojalá nunca tengas que usarlo, pero es mejor tenerlo, sobre todo ahora que se vuelve más completo cada día. Si no te has enterado, aquí podrás actualizarte sobre tus nuevas coberturas.
          En inter.mx trabajamos para que tu vida sea cada vez menos complicada. Por eso, elegimos para ti los mejores seguros y los ajustamos a tus necesidades, incluso mejor que tus zapatos. 
          Se trata de tres novedades súper útiles para la vida cotidiana que te ayudarán a  estar mejor y con más tranquilidad.`,
        },
        {
          header: `Las tres novedades que te regala tu seguro de gastos médicos`,
          body: ``,
        },
        {
          header: `1.	Auxilio de diagnóstico`,
          body: `Este servicio se incluye en tu seguro de gastos médicos. Se trata de la cobertura de algunos estudios que tu doctor de cabecera puede solicitar para revisar tu estado de salud. Por ejemplo:`,
          list: [
            `Análisis de laboratorio`,
            `Estudios de rayos X`,
            `Estudios Audiológicos`,
            `¡Entre otros!`,
          ],
        },
        {
          header: `2.	Rehabilitación`,
          body: `Tu seguro de gastos médicos también cubrirá tu rehabilitación. Pero es un asunto que merece toda tu atención. ¡Toma nota! 
          Para que la cobertura aplique, la rehabilitación debe ser:`,
          list: [
            `Consecuencia de un padecimiento o enfermedad que te haya ocurrido (¡tocamos madera!) durante la vigencia de tu póliza.`,
            `Autorizada por el médico certificado que te haya atendido.`,
            `Brindada por profesionales de la rehabilitación. ¡No vale si se lo pides a tu masajista personal!`,
          ],
        },
        {
          header: `3. Medicamentos recetados`,
          body: `Esta cobertura te permitirá obtener un reembolso cuando vayas a la farmacia por tus medicamentos. Saber esto quizá te bajará la fiebre antes que la medicina. ¡Pero cuidado! Para conseguir tu reembolso debes tener una receta indicada y justificada por el médico tratante. Esto quiere decir que debes cuidar ese papelito como un tesoro. 
          También anota esto: la cobertura funcionará si tienes registro sanitario en México. Si viajas al extranjero por negocios o vacaciones, necesitas una autorización previa para no tener problemas.`,
        },
        {
          header: `¿Ya estás más feliz con tu seguro de gastos médicos?`,
          body: `En <>inter.mx</> le quitamos lo complicado a los seguros. Entra a nuestro portal ¡Cotiza, compara, elige y contrata el mejor seguro de gastos médicos para ti!
          
          Te invitamos a seguirnos en <>Instagram</> y <>Facebook</> para enterarte de nuestras promociones.`,
        },
      ],
    },
    {
      img: BG35,
      date: "18 Enero 2022",
      autor: "adminInter",
      title: "Seguro de Gastos Médicos: Afiliaciones y Preexistencias",
      body: "Tu seguro de gastos médicos te acompañará cuando lo necesites. Para no llevarte sorpresas, presta atención a sus reglas. ¡Ingresa ahora! ",
      content: [
        {
          header: ``,
          body: `Tu seguro de gastos médicos es una parte fundamental de tu vida. Generalmente es invisible, pero podría volverse protagonista en cualquier momento. Y para sacar el mayor provecho de sus servicios, es mejor que lo conozcas bien. 
          Como contratante de una aseguradora, debes leer todo con mucha atención. Sabemos que nadie debe decirte qué hacer, pero siempre es bueno recordarlo, porque hasta las letras chiquitas son importantes.
          Para un gran 2022, elige el mejor plan de salud para ti y conoce bien todas sus normas y beneficios. ¡No te lleves ninguna sorpresa!. 
          Aquí te contaremos a detalle todo lo que debes saber acerca de las afiliaciones y preexistencias. ¡Sigue leyendo!
          `,
        },
        {
          header: `¿Qué pasa con mi afiliación en caso de preexistencias?`,
          body: `Cualquier seguro de gastos médicos tiene reglas, como ya te imaginarás. La preexistencia es una de las más importantes y se refiere a las enfermedades crónicas o los males que una persona tiene desde antes de contratar su plan de salud.
          Por ejemplo, si tienes <>diabetes</> –¡tocamos madera!–, tu seguro nuevo es probable que no se pueda hacer cargo de tus gastos por esta enfermedad. Sólo podrás acceder a la cobertura <>si tu póliza así lo determina</>. 
          ¿Y si me da una enfermedad crónica después de contratar mi plan de salud, qué sucede? Aquí hay otra norma importante: el periodo de espera. Te contamos de qué se trata a continuación.
          `,
          list: [],
          footer: ``,
        },
        {
          header: `El famoso periodo de espera`,
          body: `Cuando contratas un plan de salud, debe pasar cierto tiempo hasta que puedas recibir cobertura en caso de que te diagnostiquen con una enfermedad crónica. A ese tiempo entre la contratación de un seguro y tu diagnóstico se le llama <>periodo de espera</>.
          Hay enfermedades para las que se requiere un periodo que va desde 6 hasta 48 meses. Es decir, si por ejemplo te diera diabetes antes de cumplir el tiempo que tu plan de salud establece, es posible que tu seguro de gastos médicos no pueda cubrirte. De lo contrario, si ya ha pasado el tiempo que tu plan ha fijado, no tendrás problemas.
          Pero, ¡no te preocupes! Con inter.mx no estás solo.
          `,
          list: [],
          footer: ``,
        },
        {
          header: `¿Cómo me puede ayudar inter.mx?`,
          body: `Fácil. En <>inter.mx</> nos especializamos en ayudarte a tomar la mejor decisión para ti en cada momento.
          Nosotros te asesoramos para que adaptes tu seguro con las coberturas ideales para tu ritmo y estilo de vida. En inter.mx, tu seguro de gastos médicos es sencillo, fácil y rápido. Debe ser una solución. 
          Accede a una cobertura de honorarios médicos, ambulancia, asistencia telefónica,  protección contra el Covid-19 y más. Seguro, seguro encuentras tu plan de salud ideal en <>inter.mx</>.
          Síguenos en <>Instagram</> y <>Facebook</> para estar al tanto de todas las noticias, los productos y los servicios que tenemos para ti.

          ¡Visítanos!
          `,
          list: [],
          footer: ``,
        },
      ],
    },
    {
      img: BG36,
      date: "18 Enero 2022",
      autor: "adminInter",
      title: "¿Un seguro de Gastos Médicos Mayores cubre cirugías plásticas?",
      body: "Al hablar de un seguro de gastos médicos mayores, una de las preguntas más recurrentes es: ¿Me cubre cirugías plásticas? ¡Aquí tenemos la respuesta!",
      content: [
        {
          header: ``,
          body: `Para contratar un seguro de gastos médicos mayores lo más importante es conocer tus propias necesidades e inquietudes. No te preocupes, sabemos que en este proceso surgen muchas dudas. Por ejemplo, ¿te has preguntado si este servicio puede cubrir cirugías plásticas?
          Ésta ha sido una pregunta muy recurrente en los últimos años, en especial entre el público millennial… pero nosotros sabemos que no hay edad específica para preocuparte por estos temas.
          Aquí te respondemos esta y otras inquietudes comunes a la hora de contratar un seguro de gastos médicos mayores.
          `,
          list: [],
          footer: ``,
        },
        {
          header: `¿Mi seguro de gastos médicos mayores cubre cirugías plásticas?`,
          body: `En general, los seguros de gastos médicos mayores no cubren operaciones para mejorar tu <>estética</>. Sí podrían echarte una mano con tus cirugías plásticas luego de haber sufrido algún accidente. Pero si pretendes tener la nariz de Juanpa Zurita, lo más probable es que te lleves un no como respuesta.
          ¡Sigue leyendo! Esto también te puede interesar.
          `,
        },
        {
          header: `¿Qué ocurre con los males preexistentes?`,
          body: `Una preexistencia es cualquier enfermedad crónica o mal que una persona tenga desde antes de contratar un plan de salud. En estos casos, la aseguradora no podrá cubrir los gastos.`,
        },
        {
          header: `¿Sabes qué es el periodo de espera?`,
          body: `Conocer este punto es muy importante para aprovechar tu seguro de gastos médicos mayores. Se trata del tiempo que tu aseguradora necesita para comenzar a cubrir gastos de enfermedades crónicas. 
          Esto puede variar según tu cobertura, pero los tiempos suelen ir desde los 30 días hasta los 6 meses. Es decir, para que tu seguro pueda protegerte en estos casos, tu diagnóstico debe coincidir con los tiempos determinados por tu aseguradora.
          `,
        },
        {
          header: `¿Incluso si contraes cáncer o diabetes?`,
          body: `Sí. En caso de tener que enfrentarte a estas enfermedades, tu seguro te acompañará siempre y cuando se cumpla el periodo de espera. Te recomendamos consultar las normas según tu situación particular.`,
        },
        {
          header: `¿Cómo sería mi cobertura médica si elijo inter.mx?`,
          body: `Recuerda que en <>inter.mx</> tenemos diferentes tipos de coberturas que puedes personalizar para que se ajusten lo mejor posible a tus necesidades. Elige cómo cuidar tu salud con nuestros cuatro niveles de protección:`,
          list: [`Esencial`, `Protegido`, `Blindado`],
          footer: `A la hora de escoger, ten en cuenta que el nivel de tu seguro de gastos médicos mayores determinará en qué hospitales podrás atenderte, el monto de tu suma asegurada y el nivel de atención médica al que tendrás acceso (desde “esencial” hasta “amplio”).
          En inter.mx le quitamos lo complicado a los seguros. Entra a inter.mx y elige el mejor plan de salud para ti. 
          ¡Te invitamos a seguirnos en <>Instagram</> y <>Facebook</> para enterarte de nuestras promociones!
          `,
        },
      ],
    },
    {
      img: BG37,
      date: "18 Enero 2022",
      autor: "adminInter",
      title: "Dinos lo que buscas y te acercamos el mejor Seguro para Autos",
      body: "Tu seguro para autos te está esperando. Para obtener el mejor servicio sólo necesitas decirnos exactamente qué necesitas. ¡Entra a inter.mx y contrátalo!   ",
      content: [
        {
          header: ``,
          body: `Sabemos que elegir un seguro para autos no siempre es fácil. Se trata de una decisión tan personal, que debería estar hecha justo a tu medida, ¿no crees? En inter.mx lo hacemos así de fácil.
          Este servicio debe darte seguridad, confianza y tranquilidad en todos tus trayectos. Por eso, te acompañamos de inicio a fin para que elijas el seguro que mejor se ajuste a ti y tu forma de vida. 
          ¿Quieres incluso escoger la manera de pagar y hacer tu contratación en el menor tiempo posible? Tú sólo dinos lo que necesitas y tendrás el mejor seguro de coche.
          `,
          list: [],
          footer: ``,
        },
        {
          header: `Cuando tu seguro para autos se vuelve una experiencia agradable`,
          body: `Cuando piensas en un seguro para autos, seguramente lo primero que imaginas es todo el tiempo que perderás y la horrible y fría atención que recibirás en el proceso, ¿cierto? Pero no tiene por qué ser así. Ahora es posible tener una experiencia completamente personalizada y rapidísima a la hora de escoger tu seguro. En inter.mx, esto es garantía. `,
        },
        {
          header: `Toda la ayuda que necesitas`,
          body: `En momentos de estrés, siempre queremos a nuestro lado a alguien que sea más capaz y confiable para ayudarnos. ¡Obviamente! Así debe ser tu seguro para autos, siempre contigo para cuando sea que lo necesites. Pero no te preocupes, si eliges tu seguro de coche con <>inter.mx</>, seguro seguro estará contigo en todo momento.`,
        },
        {
          header: `Un servicio moderno y personalizado`,
          body: `El <>mundo digital</> ha hecho todo más rápido y sencillo. Si tienes días muy agitados o eres la persona más olvidadiza del mundo, no te preocupes. Ahora, tu seguro para autos se ajusta perfectamente a ti. 
          No importa donde estés o a qué hora salgas del trabajo. En inter.mx puedes escoger la forma de pago que más se acomode a tus necesidades.
          `,
        },
        {
          header: `Una app para estar siempre seguro, seguro`,
          body: `¿Sueles olvidar tu póliza en casa, en el trabajo o en cualquier parte? No hay problema. Ahora puedes descargar la aplicación de inter.mx para que te mantenga siempre al corriente con tu seguro. Aunque te olvides de su existencia, tu seguro para autos siempre estará contigo desde tu celular.
          ¿Necesitas más razones para contratar tu seguro para auto con inter.mx? ¡Sigue leyendo!
          `,
        },
        {
          header: `¿Por qué recurrir a inter.mx?`,
          body: `En primer lugar, por todas las ventajas que ya te dijimos (puedes encontrarlas <>aquí</>). 
          inter.mx le quita lo complicado a los seguros y te escucha. Además, te ofrece una gran variedad de coberturas con increíbles beneficios según el plan que elijas:
          `,
          list: [
            `Daños materiales`,
            `Robo total`,
            `Responsabilidad civil`,
            `Gastos médicos`,
            `Defensa legal`,
            `Asistencia en viajes`,
          ],
          footer: `Para obtener un seguro, seguro, ¡sólo tienes que <>contactarnos</>!
          Síguenos en <>Instagram</> y <>Facebook</> para mantenerte al tanto de nuestros productos, promociones y novedades.
          ¡Únete!        
          `,
        },
      ],
    },
    {
      img: BG38,
      date: "18 Enero 2022",
      autor: "adminInter",
      title: "Seguro para Coche: ¡Tips para tener seguridad al volante!",
      body: "Entérate de cuál es la mejor forma de tener seguridad al volante, además de contar con un seguro para coche a tu medida. ¡Ingresa y conoce más! ",
      content: [
        {
          header: ``,
          body: `Ir al volante significa una inmensa responsabilidad. Y aún cuando cuentas con la cobertura de un seguro para coche, es importante tener ciertas precauciones para evitar al máximo cualquier tipo de incidente. 
          Sabemos que no importa la velocidad ni el destino, sino que siempre hay que conducir con inteligencia. 
          Por eso, hoy te contamos algunas lecciones que nos han dejado las carreras de grandes e importantes figuras al volante como <>Sergio “Checo” Pérez</>. 
          `,
          list: [],
          footer: ``,
        },
        {
          header: `El primer lugar es un gran triunfo, pero no es la única meta `,
          body: `Una excelente idea para garantizar tu bienestar y el de tus acompañantes es contratar un seguro para coche que cuente con las coberturas necesarias. Pero como dirían por ahí: "siempre es mejor prevenir que lamentar". Por lo tanto debemos siempre conducir con inteligencia para que así los incidentes no sean ni siquiera una posibilidad. 
          No importa si bajar un poco la velocidad implica tener que llegar a tu destino unos cuantos minutos más tarde, conducir con cautela será siempre mejor opción que ganar la carrera al arrancar en la luz verde del semáforo.
          `,
        },
        {
          header: `¡Ojos siempre al frente! `,
          body: `Un gran consejo de los pilotos más reconocidos del mundo es mantener la concentración y siempre ir con los ojos atentos. Estar pendientes de cualquier cosa que pueda pasar en el camino, seguro nos ayudará a prevenir muchos incidentes. 
          Más aún cuando manejas en grandes ciudades o espacios con tráfico pesado, es importante evitar al máximo las distracciones, pues un segundo puede ser la clave. Suelta un ratito  TikTok y no quites los ojos del camino. 
          `,
        },
        {
          header: `Contrata el mejor seguro de auto `,
          body: `Tener el respaldo de un seguro adaptado a tus necesidades y presupuesto puede ser de gran ayuda en caso de tener algún <>choque</>, incluso si es muy leve. Según las coberturas de la póliza que elijas estarás cubierto ante distintas situaciones.`,
        },
        {
          header: `¿Quieres conocer cuál es la mejor opción en el mercado? `,
          body: `Con <>inter.mx</> puedes buscar, cotizar y contratar el seguro para coche que más te convenga. 
          ¡Anímate a probar nuestro servicio! 
          Y no olvides seguirnos en nuestros perfiles de <>Instagram</> y <>Facebook</>.
          `,
        },
      ],
    },
    {
      img: BG39,
      date: "18 Enero 2022",
      autor: "adminInter",
      title: "inter.mx: un bróker de seguros con vocabulario centennial",
      body: "Somos un bróker de seguros para tu generación. Hablamos tu idioma, en inter.mx te entendemos como nadie. ¡Ingresa ahora! ",
      content: [
        {
          header: ``,
          body: `Como buen bróker de seguros, queremos ayudarte a decidir qué servicio te conviene más. Para hacerlo, elegimos acercarnos a la generación que está tomando el control del mundo. 
          Los nativos digitales están transformando las acciones que realizamos día a día. En este contexto, nos preocupamos por entender las necesidades del futuro.
          A medida que pasan los años, los tipos de clientes van cambiando. Esto es algo que no se nos puede pasar por alto. Por ello, elegimos actualizarnos para estar en sintonía. 
          `,
          list: [],
          footer: ``,
        },
        {
          header: `¿Quiénes son los centennials?`,
          body: `Un bróker de seguros que no esté al tanto de esta realidad, no conoce a la juventud de hoy. Por lo tanto, veamos primero quiénes son los centennials.
          Se le llama centennials a las personas que nacieron a partir del año 2000. La generación anterior son los millennials y la siguiente es de los Alfa. Se trata de jóvenes muy involucrados con la tecnología y el consumo. <>Suelen decir lo que piensan en voz alta</> y manifestarse en contra de las injusticias. Además, están muy ligados al uso de redes sociales.
          En el <>mercado laboral</>, los centennials están avanzando a la velocidad de la luz. Se trata de una generación que aprovecha al máximo su gran manejo de la tecnología. La monetización en línea, las criptomonedas y cualquier proyecto digital están a su alcance. 
          `,
        },
        {
          header: `inter.mx: la empresa que habla como tú`,
          body: `En <>inter.mx</> tratamos de no tomar distancia. Todo lo contrario: para comprender de qué va la vida en el presente, hay que acercarse a la realidad. Parece obvio, pero no todos empiezan por el lenguaje. 
          Consideramos que para tener un buen diálogo, no podemos sonar como si te diéramos una lección. Tú sabes perfectamente lo que quieres y por eso nos eliges.
          `,
        },
        {
          header: `Las campañas de Inter`,
          body: `El corredor de F1 Checo Pérez y el youtuber Juanpa Zurita, forman parte de este cambio de actitud. Queremos acercarnos a quienes tomarán las decisiones más importantes en los próximos años. El primer paso como bróker de seguros era cambiar nosotros, y ya lo hemos hecho.
          Así lo dijo Juanpa Zurita en <>CNN</>: “El mensaje que tienen es muy divertido y honesto. Están cambiando una dinámica de esta industria que siempre es muy compleja”. Por ello, estamos convencidos de que estamos en el camino correcto. Escuchar tu voz nos saca una sonrisa.
          <>inter.mx</> quiere seguir comunicándose con tu generación. Dinos qué piensas en nuestras redes. 
          Te invitamos a seguirnos en <>Facebook</> e <>Instagram</> y, por supuesto, a leer nuestro <>blog</>.
          ¡Contrata un seguro para cuidar tu brillante futuro!
          `,
        },
      ],
    },
    {
      img: BG40,
      date: "18 Enero 2022",
      autor: "adminInter",
      title: "Situaciones en las que agradecerás tener un Seguro para Coches",
      body: "Conoce todos los momentos en los que un seguro para coches te puede ayudar. Disfrutar la vida no está peleado con prevenir. ¡Ingresa! ",
      content: [
        {
          header: ``,
          body: `Un seguro para coches puede no parecerte muy atractivo, pero cuando menos te lo esperas, puede convertirse en tu gran héroe. A continuación, te daremos una lista completa de razones por las cuales es necesario. 
          En <>inter.mx</> te ayudamos a tomar la mejor decisión para ti, porque queremos que siempre te sientas seguro, seguro a donde sea que vayas. 
          Nadie puede controlar el futuro, pero sí tenemos el poder de prevenir y prepararnos para lo que venga.
          `,
          list: [],
          footer: ``,
        },
        {
          header: `¿Cuándo tu seguro para coches se convertirá en el amor de tu vida?`,
          body: ``,
        },
        {
          header: `Pequeños y molestos accidentes`,
          body: `Imagina que te pasas una tarde divertida en casa de un amigo. Te despides feliz y cuando vas de salida, ¡oh, sorpresa! Tu auto tiene un rayón. Pero no te preocupes, porque sabes que puedes usar tu seguro para coches.  
          Sólo llamas a tu aseguradora, explicas el problema y listo, tienes una solución. 
          Te puede interesar: <>Glosario de conceptos que no entiendes sobre los seguros</>.
          `,
        },
        {
          header: `¡Es que no encuentro mis llaves!`,
          body: `¿A quién no le ha pasado? No es nada del otro mundo, pero si te sucede necesitas una solución urgente. ¿O piensas abandonar tu auto?
          ¡No te preocupes!, este es otro de los momentos en que agradecerás tener un seguro para coches. Sólo solicita tu reposición de llave por robo o pérdida y pronto estarás de nuevo detrás del volante.  
          `,
        },
        {
          header: `Estás en la ruta y se te ponchan las llantas`,
          body: `En primer lugar, una recomendación universal: no entres en pánico. Como has tomado precauciones, sabes que no estás solo (aunque vayas sin acompañante). Siempre tendrás el respaldo de tu aseguradora. 
          Ten paciencia y en menos de lo que piensas volverás a tu viaje con normalidad. Sólo toma tu celular, contacta a tu aseguradora, solicita asistencia vial y listo. 
          `,
        },
        {
          header: `Para evitar multas: repara tus luces y espejos rotos`,
          body: `Que los <>controles de tránsito</> no te tomen por sorpresa. Si tienes luces y espejos rotos ahora existe la posibilidad de repararlos con ayuda de tu aseguradora. Recuerda que son fundamentales para tu seguridad y la de los demás.`,
        },
        {
          header: `“¿¡Dónde está mi coche!?”`,
          body: `Que te manden al corralón también justifica tener un seguro de coche. Tú no quieres enfrentarte a trámites difíciles, tiempos infinitos y cantidades terribles de dinero. En caso de vivir esta mala experiencia, contar con una asesoría legal por parte de tu aseguradora sin dudas es una ventaja.
          Tu aseguradora también puede ayudarte en la gestión de trámites vehiculares. Usa este beneficio y ahórrate horas de espera.
          `,
        },
        {
          header: `No queda de otra, hay que ir al mecánico`,
          body: `El mantenimiento de tu vehículo puede ser costoso, y ni hablar cuando algo se le descompone. Todo se vuelve terrible y parece un callejón sin salida. 
          Por suerte, cuentas con un aliado que está ahí para ayudarte. Llama a tu seguro y muy probablemente tendrás que pagar menos en la reparación de tu auto. Así ese ahorro lo podrás usar en algo que realmente disfrutes.
          Como ves, son muchas las situaciones en las que puedes desear tener un seguro para coches. Si ya lo tienes, qué tranquilidad, ¿no crees? Pero si no, ¿qué esperas? 
          Entra a <>inter.mx</> ¡Cotiza, elige y contrata!
          ¡Síguenos en <>Instagram</> y <>Facebook</> para conocer más sobre las ventajas y beneficios que tenemos para ti!    
          *Para mayor información sobre las coberturas pregunta a tu asesor o verifica con tu aseguradora.
          `,
        },
      ],
    },
    {
      img: BG41,
      date: "18 Enero 2022",
      autor: "adminInter",
      title: "¿Por qué SÍ comprar tu Seguro de Coche de forma digital?",
      body: "Contratar tu seguro de coche y otras pólizas es muy fácil con el servicio online que inter.mx tiene para ti. ¡Ingresa y conoce todos los detalles aquí! ",
      content: [
        {
          header: ``,
          body: `Actualmente, por medio de internet puedes hacer tus compras, pagar facturas o contratar un seguro de coche. Lo único que tienes que hacer es dar un par de clics y listo. 
          Sabemos que los cambios en ocasiones pueden darnos un poco de miedo. Pero sin duda alguna, hay muchos que son positivos y nos hacen la vida más fácil. En este sentido, las compras en línea son uno de estos cambios que seguro has agradecido en diferentes momentos.
          Continúa leyendo y conoce las ventajas de contratar tu seguro y otros productos de manera online. 
          `,
          list: [],
          footer: ``,
        },
        {
          header: `Seguro de coche al alcance de un clic`,
          body: `La tecnología ha avanzado tanto que ahora con tan solo escribir unas cuantas palabras y dar un par de clics es suficiente para hacer cualquier compra desde casa. 
          En <>inter.mx</> esto nos ha inspirado a construir uno de los portales más completos y eficientes del mercado donde podrás buscar, cotizar y contratar el seguro de coche que más se ajuste a tus necesidades y presupuesto. 
          Y lo mejor: ¡todo en un mismo lugar!
          `,
        },
        {
          header: `Ventajas de contratar seguros online `,
          body: `El beneficio general de contratar todas tus pólizas por medio de internet es que ahorras tiempo y recibes todo lo que necesitas casi de manera instantánea. Además de esto, existen múltiples beneficios de comprar productos y servicios vía online, los cuales te vamos a contar a continuación. `,
        },
        {
          header: `Ahórrate las filas y el papeleo `,
          body: `Contratando un seguro de coche, de vida, de mascota o de gastos médicos a través de internet podrás ahorrarte el tiempo que gastarías al trasladarte a una oficina. También, con la digitalización de los trámites, tendrás en cuestión minutos todos los documentos que necesitas para firmar o revisar. 
          De esta manera, la contratación de tu póliza la podrás hacer desde la comodidad de tu casa u oficina y sin necesidad de perder todo un día. 
          ¡Ingresa, descarga, firma y contrata en unos cuantos minutos!
          `,
        },
        {
          header: `Aprovecha excelentes promociones`,
          body: `En inter.mx es muy común que tengamos promociones y beneficios exclusivos por contratar en línea. Entonces, además de ahorrar tiempo, puedes ahorrar dinero significativamente.  
          ¡Contrata tu seguro rápido y a un excelente precio!
          `,
        },
        {
          header: `Consulta todas las posibilidades`,
          body: `En <>inter.mx</> podrás cotizar las diferentes opciones que el mercado ofrece y encontrar la que más se ajuste a lo que estás buscando. Podrás contratar rápido y fácil un seguro de coche con las coberturas y precios que más te convengan.  
          ¿Quieres conocer cuál es tu mejor opción? 
          En <>inter.mx</> puedes buscar, cotizar y contratar el seguro de auto con las mejores aseguradoras del país. 
          ¡Anímate a probar nuestro servicio! 
          Encuentra promociones, recomendaciones, pólizas y asesoría en el <>portal especializado</> que tenemos para ti. 
          Y no olvides seguirnos en nuestros perfiles de <>Instagram</> y <>Facebook</>.
          `,
        },
      ],
    },
    {
      img: BG42,
      date: "18 Enero 2022",
      autor: "adminInter",
      title: "Seguro de Coche: ¿Cómo saber en qué choques quedo cubierto?",
      body: "¿Sabes cuándo es tu responsabilidad en un choque de autos? Con un Seguro de Coche todo está cubierto, pero no en todos los casos. ¡Entérate!",
      content: [
        {
          header: ``,
          body: `Cuando ocurre un accidente automovilístico, la persona culpable del choque es quien se tiene que hacer cargo de los costos ocasionados. Si esa persona tiene contratado un seguro de coche, la responsabilidad se transmite a la compañía aseguradora.
          Pero, ¿cómo se determina quién es el culpable en un choque? Y más importante: ¿cómo hacen las aseguradoras para determinar la responsabilidad de los gastos por un incidente?
          `,
          list: [],
          footer: ``,
        },
        {
          header: `Pasos a seguir para saber quién es el culpable en un choque`,
          body: `Hay determinados pasos que utilizan las aseguradoras en México para establecer quién tiene la culpa en un choque. Supongamos que hay un accidente que involucra a dos conductores que tienen contratado un seguro de coche.
          En primer lugar, se debe definir la circunstancia en que se encontraba el primer automóvil cuando tuvo lugar el incidente. Para ello, hay una <>tabla de circunstancias</> que sirve como guía para las aseguradoras. 
          Estas pueden ser: “circulaba por vía primaria o glorieta”, “circulaba por vía secundaria”, “circulaba a la izquierda en crucero de igual amplitud”, entre otras opciones. En total hay diecinueve circunstancias posibles.
          El ajustador  debe definir la situación del segundo auto con esta misma tabla. Después de esto las compañías que proveen el seguro de coche determinan cuál de los conductores tiene la responsabilidad en el choque consultando una Matriz de Responsabilidad. Éste es un criterio del que disponen todas las aseguradoras.
          `,
        },
        {
          header: `¿Cómo sé si mi seguro de coche me cubre?`,
          body: `Los posibles resultados luego de aplicar esta guía son cuatro: la culpa es del conductor del primer vehículo, es del segundo, son responsables ambos o el caso no aplica.
          La Matriz de Responsabilidad relaciona las circunstancias del primer auto con la del segundo auto en filas y columnas para establecer qué conductor es el responsable del choque. 
          Por esta razón, la pregunta “quién es el culpable y qué aseguradora tiene que hacerse cargo” suele ser fácilmente resuelta. Por lo general, los accidentes se repiten de manera similar, aunque hay excepciones.
          Esperamos haber aclarado un poco tus dudas sobre cómo se determinan los casos en que quedarás cubierto por tu seguro de coche. 
          Tocamos madera para que nunca te encuentres en una situación así, pero de ser el caso, recuerda que con inter.mx siempre estarás seguro, seguro.. 
          Maneja con responsabilidad. Así ganamos todos. 
          En <>inter.mx</> puedes buscar, cotizar y contratar la póliza que más se acomode a tus necesidades y presupuesto. 
          No olvides seguirnos en nuestros perfiles de <>Instagram</> y <>Facebook</>.
          `,
        },
      ],
    },
    {
      img: BG27,
      date: "18 Diciembre, 2021",
      autor: "admininter",
      title: "Recorre el país sin preocupaciones con tu Seguro de Coche",
      body: "Nada como conocer todo México con la libertad de ir al volante y la seguridad que solo te ofrece el mejor Seguro de Coche del mercado. ¡Contrata ya!",
      content: [
        {
          header: ``,
          body: `México es un país lleno de historias por escuchar, paisajes por disfrutar y personas por conocer. Viajar por las carreteras mexicanas es la mejor forma de conectarnos con nuestra nación y disfrutar de la naturaleza que nos ofrece nuestro territorio. ¿Listo para recorrerlo? Alista tu seguro de coche y prepárate para vivir la mejor aventura al volante.`,
        },
        {
          header: `Las 3 claves para un viaje tranquilo`,
          body: `Si necesitas viajar largos trayectos por carretera, bien sea por trabajo o por turismo, hay 3 elementos que serán claves para que tu viaje sea todo un éxito y no tengas que preocuparte por nada: pericia, planeación y un buen seguro de coche.`,
          list: [],
          footer: ``,
        },
        {
          header: `Pericia`,
          body: `Lo más importante para tener un viaje tranquilo es la pericia al conducir. No solo se trata de ser un buen conductor y tener reflejos rápidos -que estamos seguros de que en eso nadie te supera- sino de estar en perfectas condiciones para que nada te sorprenda al volante.
          Para ayudarte a tener mayor pericia al conducir puedes seguir estos excelentes consejos. Antes de emprender tu viaje:
          `,
          list: [
            `Asegúrate de haber dormido lo suficiente.`,
            `Evita consumir alimentos pesados que te puedan causar inconvenientes en el camino.`,
            `No ingieras medicamentos que produzcan somnolencia.`,
            `No tomes ninguna bebida alcohólica.`,
          ],
          footer: `Te puede interesar: <>¿Cómo hacer que el sueño no te gane en un viaje largo?</>`,
        },
        {
          header: `Planeación`,
          body: `Sabiendo que el conductor está preparado como el mismísimo Checo Pérez, ahora necesitamos saber cuál será la ruta a seguir. 
          Infórmate sobre el estado actual de las vías con instituciones oficiales como la <>Red Nacional de Caminos</>. O bien, utiliza los dispositivos digitales que tenemos hoy en día para ir siempre por la ruta correcta y evitar retrasos. 
          Recuerda que puedes conocer un aproximado de la hora de llegada y las distintas rutas disponibles para llegar a tu destino con aplicaciones como Waze o Google Maps. ¡Pero nada de usar el celular mientras manejas!
          `,
          list: [],
          footer: ``,
        },
        {
          header: `Prevención (seguro de coche)`,
          body: `Ya todo lo que depende de ti está bajo control. Ahora solo falta tener un respaldo económico para viajar con tranquilidad y confianza hasta tu destino.
          Al contratar tu seguro de coche en <>inter.mx</> estarás protegido en todo el territorio nacional ante cualquier inconveniente.  
          Además, tendrás disponible 24/7 el servicio de asistencia con grúa o gasolina, en caso de que sea imposible regresar a casa en tu coche, y el servicio de defensa legal si requieres de un abogado.
          Ahora sí, ¡A disfrutar el viaje!
          Contrata ya mismo tu seguro de coche en <>inter.mx</> y ahorra hasta un 30% ¡Te ofrecemos el mejor precio del mercado!
          No olvides seguirnos en nuestras redes de <>Instagram</> y <>Facebook</>.
          `,
          list: [],
          footer: ``,
        },
      ],
    },
    {
      img: BG28,
      date: "18 Diciembre, 2021",
      autor: "admininter",
      title: "¿Cómo contratar un Seguro de Gastos Médicos siendo extranjero?",
      body: "Conoce la importancia y las posibilidades para contratar un seguro de gastos médicos siendo un ciudadano extranjero en México. ",
      content: [
        {
          header: ``,
          body: `Según el <>portal de cifras Statista</>, para el año 2020, en México vivían un total de 1,2 millones de extranjeros, de los cuales el 65,8% eran de nacionalidad estadounidense, y los demás se dividían en ciudadanos de diferentes partes del mundo y latinoamérica. Y el aumento de esta cifra ha hecho que cada vez sea más importante para un extranjero contratar un seguro de gastos médicos, bien sea turista o residente. 
          Continúa leyendo y conoce las especificaciones de este servicio. 
          `,
        },
        {
          header: `¿Salud Pública o Seguro de Gastos Médicos?`,
          body: `Comúnmente, los extranjeros residentes en México optan por hacerse chequeos médicos periódicamente en sus países de origen. No obstante, siempre está la opción de contratar un seguro de gastos médicos o inscribirse al sistema de salud público. 
          Y aunque inscribirse en el INSABI es relativamente fácil, en la mayoría de casos es mejor acceder a un SGM o asegurarse de que el servicio médico contratado en el país de origen tiene cobertura en México. 
          Esto es importante, no solo porque un SGM asegura una atención especializada y eficiente, sino porque tiene una cobertura mucho más amplia. 
          `,
          list: [],
          footer: ``,
        },
        {
          header: `¿Cómo contratar un seguro siendo extranjero?`,
          body: `La primera opción, y por la que cientos de extranjeros optan, es contratar un SGM internacional, que no solo cubra México, sino otros lugares. Así podrás garantizar un servicio de salud óptimo en diferentes partes del mundo. 
          Es decir que, si tienes planes de viaje o jubilación, podrás asegurar el cuidado de tu salud y tu bienestar casi en cualquier lugar dónde te encuentres. 
          Otra alternativa que puedes tomar es contratar un seguro de gastos médicos o gastos médicos mayores que tenga las coberturas necesarias según tus necesidades y presupuesto. 
          Para ello, solo debes presentar todos tus documentos de identificación actualizados e iniciar el trámite de contratación. Aunque debes tener presente que los requisitos dependen directamente de la entidad con la que contrates tu seguro. 
          Las coberturas que no pueden faltar en tu póliza, bien sea internacional o local, son: 
          `,
          list: [
            `Medicamentos y ambulancia.`,
            `Prótesis y aparatos ortopédicos.`,
            `Rehabilitaciones`,
            `Gastos hospitalarios.`,
            `Honorarios médicos.`,
            `Asistencia telefónica.`,
            `Asistencia en viaje.`,
            `Auxiliares de diagnóstico.`,
          ],
          footer: `Finalmente, es importante que cuentes con una asesoría especializada y profesional antes de contratar tu seguro de gastos médicos. Con ello te aseguras de tomar la mejor opción del mercado. 
          ¿Quieres saber cómo lograrlo? Ingresa a <>inter.mx</> y conoce cómo cotizar, contratar y gestionar el seguro que mejor se ajusta a tus necesidades. 
          No olvides seguirnos en nuestros perfiles de <>Instagram</> y <>Facebook</>.
          `,
        },
      ],
    },
    {
      img: BG29,
      date: "18 Diciembre, 2021",
      autor: "admininter",
      title: "¡Aprovecha tu aguinaldo y contrata un Seguro de Gastos Médicos!",
      body: "No te gastes el aguinaldo en algo de lo que luego te arrepientas, mejor inviértelo en protección para ti y tu familia con un Seguro de Gastos Médicos.",
      content: [
        {
          header: ``,
          body: `Contar con un dinero extra nunca viene mal, ¿cierto? Esa es la magia del aguinaldo, llega como un dinero con el que no contábamos y que nos permite superar sin problemas una de las épocas del año en las que más se gasta. Pero… ¿Qué tal si te dijéramos que un seguro de gastos médicos es incluso mejor que el aguinaldo?`,
        },
        {
          header: `Protección siempre que la necesites`,
          body: `Con el seguro de gastos médicos de <>inter.mx</> tendrás un respaldo económico siempre que lo necesites. No importa cuándo ni dónde, en todo momento y en todo lugar estarás protegido y asegurado. 
          Nuestras pólizas cubren todo lo relacionado con:
          `,
          list: [
            `Gastos hospitalarios.`,
            `Honorarios médicos.`,
            `Auxiliares de diagnóstico.`,
            `Medicamentos y ambulancia.`,
            `Asistencia telefónica.`,
            `Asistencia en viaje.`,
            `Prótesis y aparatos ortopédicos.`,
            `Rehabilitaciones.`,
          ],
          footer: `Con nuestros planes obtienes una suma asegurada de hasta $100 millones que puedes ampliar según tu preferencia. Además, tienes  acceso a una red hospitalaria con más de 250 instituciones médicas en todo el territorio nacional.`,
        },
        {
          header: `Seguro de gastos médicos a tu alcance`,
          body: `Lo mejor de contratar un seguro de gastos médicos con <>inter.mx</> es que todo lo puedes hacer desde la comodidad de tu casa. 
          Solo tienes que ingresar a nuestro sitio web y ahí te mostraremos las mejores opciones del mercado tomando en cuenta cuánto quieres invertir y qué cobertura buscas.
          Teniendo un amplio panorama con todas las alternativas disponibles para ti, podrás personalizar tu póliza para que se ajuste a tus necesidades y las de tu familia.
          Una vez seleccionado el seguro que quieras podrás realizar el pago de forma segura a través de nuestra plataforma. ¡Y todo listo! En cuestión de minutos recibirás tu póliza en tu correo electrónico.
          Asimismo, a través de nuestra app podrás gestionar, renovar y ver a detalle todos tus seguros al instante. Recuerda que con <>inter.mx</> todo es fácil, rápido y seguro.
          Aprovecha ese dinero extra que llega para este Fin de Año e invierte en tranquilidad para que puedas seguir disfrutando de la vida. Conoce todos los detalles de nuestro seguro de gastos médicos en <>inter.mx</>.
          Si quieres saber más sobre nuestros productos te invitamos a visitar nuestro <>blog</> y seguirnos en nuestras redes de <>Instagram</> y <>Facebook</>.
          `,
          list: [],
          footer: ``,
        },
      ],
    },
    {
      img: BG30,
      date: "18 Diciembre, 2021",
      autor: "admininter",
      title: "Seguro para coche | Conoce tu auto y aprende a cuidar de él",
      body: "Así como tu seguro para coche te protege, tu auto también lo hace. Cuídalo a él también con estos consejos que en inter.mx hemos preparado para ti.",
      content: [
        {
          header: ``,
          body: `Si bien para sacar la licencia de conducir es necesario tener conocimientos previos sobre el funcionamiento de tu coche, con el tiempo y por falta de ponerlos en práctica, estos empiezan a quedar en el olvido. Sin embargo, tener fresca esta información te ayudará a estar tranquilo al volante tal y como lo hace tu seguro para coche.
          Por esta razón, hoy en <>inter.mx</> te traemos lo más importante que necesitas saber sobre tu auto para cuidar de él y que él cuide de ti.
          `,
        },
        {
          header: `¿Más seguro que un Seguro para Coche?`,
          body: `La única forma de estar más seguro que con un seguro para coche es asegurándote (Ba Dum tss) de que tu vehículo esté siempre en las mejores condiciones. Esto es verificar constantemente que todas las piezas estén en buen estado y cuenten con los insumos necesarios para su funcionamiento. Veamos algunos ejemplos:
          `,
          list: [],
          footer: ``,
        },
        {
          header: `Nivel de aceite`,
          body: `El aceite es indispensable para que el motor funcione correctamente. Se encarga de mantenerlo lubricado cuando está trabajando, pero conforme se va utilizando pierde densidad y cambiarlo se vuelve algo necesario .
          Para medirlo debes asegurarte de que el motor esté frío y el auto no tenga inclinación. Dentro del motor encontrarás el compartimiento del aceite y una varilla con dos líneas para medir el nivel. 
          Limpia la varilla e introdúcela dentro del depósito de aceite. Si al sacar la varilla la marca del aceite está por encima de la línea inferior, todo está bien, pero si está por debajo es momento de ir al mecánico.          
          `,
          list: [],
          footer: ``,
        },
        {
          header: `Nivel del agua`,
          body: `Con el agua pasa algo similar, ya que ayuda a regular la temperatura del motor pero con el tiempo se va evaporando. 
          Revisar los niveles de agua es muy sencillo. Solo debes esperar a que el coche esté frío, abrir la tapa del motor y localizar el radiador (normalmente tiene una tapa azul). Una vez destapado ese compartimiento y es necesario revisar cuánta agua tiene. Si está hasta el tope no hay más por hacer, es el nivel ideal, de lo contrario, puedes agregar más agua tú mismo.
          `,
          list: [],
          footer: ``,
        },
        {
          header: `Las señales del tablero`,
          body: `El tablero nos da información inmediata sobre el estado de nuestro coche, pero muchas veces no sabemos qué es lo que nos quiere decir. 
          La nomenclatura cambia según cada marca y modelo, pero en el manual de tu vehículo podrás encontrar una lista detallada con lo que quiere decir cada símbolo. Dale una leída al manual y asegúrate de entender los símbolos. Así, cuando tu auto te mande una alerta en el tablero, sabrás inmediatamente por dónde comenzar.
          Pero no te confíes, siempre hay más cosas que puedes aprender sobre tu auto. ¿Sabes cómo cambiar una llanta? ¿Sabes qué hacer si alguien te choca? o ¿Te estás estrenando en la conducción? 
          ¡No te preocupes! Para todo lo que necesitas cuentas con el seguro para coche de <>inter.mx</>, seremos tu respaldo vayas a donde vayas.
          Conoce más sobre nuestros productos, ¡Visita <>inter.mx</> y síguenos en nuestras redes de <>Instagram</> y <>Facebook</>.
          `,
          list: [],
          footer: ``,
        },
      ],
    },
    {
      img: BG31,
      date: "18 Diciembre, 2021",
      autor: "admininter",
      title: "Aprende lo básico y fundamental de un Seguro de Gastos Médicos",
      body: "¿Sabes cuáles son las coberturas principales de un Seguro de Gastos Médicos? Descubre todos los beneficios de tu seguro en inter.mx ¡Ingresa ahora!",
      content: [
        {
          header: ``,
          body: `Mucho se habla sobre el seguro de gastos médicos, pero no siempre es sobre lo realmente esencial. Para empezar, hay que tener en cuenta que se trata de un servicio que contiene varias coberturas básicas. Por ello, en esta nota encontrarás información sobre ellas y otras coberturas adicionales.
          Siempre que hablamos de un seguro estamos hablando de un servicio económico pensado para brindar protección financiera y asegurar el bienestar de los asegurados y de sus seres queridos. En el caso de un plan de gastos médicos,a través de un contrato se transfieren los riesgos por siniestros de tipo médico de una persona a una compañía aseguradora.
          `,
        },
        {
          header: `Un seguro de gastos médicos individual, familiar o empresarial`,
          body: `No todos los seguros médicos son iguales. En principio, se distinguen tres tipos de pólizas según quienes sean los beneficiarios de un seguro de gastos médicos: pueden ser individuales, familiares o empresariales.
          Igualmente, en todos los casos se gestionan a través de una persona que actúa como el titular, ya sea el jefe de familia o de la empresa. Con esto se extiende la cobertura al grupo familiar o laboral.
          A partir de esta categorización, existen coberturas básicas que vienen incluídas en cada plan, pero también coberturas adicionales a las que se puede acceder pagando una cuota extra.          
          `,
          list: [],
          footer: ``,
        },
        {
          header: `Coberturas básicas`,
          body: `Un seguro de gastos médicos básico cubre los siguientes gastos circunstanciales:`,
          list: [
            `Honorarios médicos por consultas, visitas médicas e intervenciones quirúrgicas (cirujano, anestesiólogo). Esto también incluye los honorarios por enfermería.`,
            `Servicios de diagnóstico adicionales, en caso de que se requiera pruebas más exhaustivas para un diagnóstico certero.`,
            `Medicamentos, tanto en hospitalización como en tratamiento ambulatorio.`,
            `Servicios de ambulancia.`,
            `Hospitalización, incluyendo reserva de habitación, sala de operaciones o de terapia intensiva e intermedia, insumos hospitalarios, curaciones y recuperaciones, aplicación de sueros, sangre y plasma, consumo de oxígeno y pruebas de laboratorio.`,
            `Tratamientos de corta o larga duración como rehabilitación física y el alquiler de equipos médicos (muletas, bastones y andaderas).`,
          ],
          footer: `Cabe señalar que un seguro de gastos médicos también brindará cobertura por servicios adicionales. Entre estos se encuentran servicios de maternidad (con un monto estipulado), atención dental básica, y atención médica, nutricional o psicológica. En servicios nutricionales y psicológicos la atención puede ser por teléfono o videollamada.`,
        },
        {
          header: `Coberturas adicionales`,
          body: `Entre los servicios a los que se puede acceder a través del pago extra de una cuota  se encuentran:`,
          list: [
            `Todos los gastos totales generados por maternidad, hospitalización y cuidados del recién nacido.`,
            `Cobertura para actividades deportivas extremas.`,
            `Atención médica y cobertura amplia por urgencias, accidentes y emergencias en el extranjero. `,
            `Indemnizaciones diarias por hospitalización prolongada.`,
          ],
          footer: `Obviamente estos servicios adicionales y sus costos varían según la aseguradora. Cada seguro de gastos médicos tiene sus especificaciones y hay varias opciones para suplir las necesidades de todos los clientes. 
          Por eso, es fundamental contar con una compañía que conozca cada una de las coberturas de seguros a fondo. En este caso, tu mejor aliado es <>inter.mx</>, el bróker de seguros número 1 de México. ¡Te asesoramos para que encuentres tu póliza ideal!
          Si quieres saber más sobre nuestros productos visita nuestro <>blog</>, o síguenos en nuestras redes de <>Instagram</> y <>Facebook</>.
          `,
        },
      ],
    },
    {
      img: BG32,
      date: "18 Diciembre, 2021",
      autor: "admininter",
      title: "Todo sobre las coberturas de un buen Seguro de Gastos Médicos",
      body: "Conoce todas las coberturas básicas que debe tener un buen seguro de gastos médicos. Descubre cómo inter.mx te ayuda a contratar la mejor opción.",
      content: [
        {
          header: ``,
          body: `Antes de contratar un seguro de gastos médicos es realmente importante que conozcas a profundidad las coberturas con las que este cuenta. No solo por asegurar un respaldo económico ante cualquier eventualidad, sino por contratar un servicio con el que realmente te sientes cómodo. 
          Para estar seguro de que estás contratando la mejor opción para ti, entre las diferentes opciones que el mercado ofrece, te vamos a contar brevemente cuáles son las coberturas básicas que sí o sí debe incluir tu seguro. 
          `,
        },
        {
          header: `Coberturas infalibles de tu seguro de gastos médicos `,
          body: `Si bien más coberturas y servicios extra en tu seguro de gastos médicos significa un servicio más completo, existen algunas coberturas infaltables en cualquier seguro de calidad. 
          Aquí te contamos las 5 más importantes.`,
          list: [],
          footer: ``,
        },
        {
          header: `Servicios básicos de salud `,
          body: `Es importante asegurarte de que el seguro de gastos médicos que contrates cuente con atención y servicios de medicina primaria. Esto garantizará que tengas atención médica necesaria en caso de una consulta o accidente menor.
          ¡Todo <>buen seguro médico</> cuenta con esta cobertura!`,
          list: [],
          footer: ``,
        },
        {
          header: `Atención 24/7`,
          body: `En realidad, esta puede ser una de las más importantes -e infalibles- coberturas de tu seguro médico, ya que nunca se sabe cuando puede llegar un resfriado o dolor de cabeza que requiera una atención básica. 
          Por esto, es importante que tu seguro te brinde atención médica a cualquier hora y día de la semana. Nunca sabes cuándo vas a necesitar un médico de emergencia. 
          `,
          list: [],
          footer: ``,
        },
        {
          header: `Cobertura por hospitalizaciones `,
          body: `La mayoría de los seguros tienen topes en el monto de cobertura de hospitalización. En este sentido, es importante que contrates aquel con la capacidad y monto suficiente para cubrir al menos una hospitalización básica. 
          Considera que una intervención delicada puede resultar en cuentas de hospital muy costosas, aún con el monto que asume la empresa aseguradora. 
          `,
          list: [],
          footer: ``,
        },
        {
          header: `Coberturas en caso de embarazo `,
          body: `Especialmente para las mujeres, es importante contar con un seguro de gastos médicos que cubra el embarazo. Desde la atención prenatal, hasta los cuidados posparto de la madre y del bebé la atención médica adecuada siempre es primordial. 
          Esto permitirá a la madre tener una atención profesional, especializada y, sobre todo constante, durante todo el proceso. 
          `,
          list: [],
          footer: ``,
        },
        {
          header: `Pruebas diagnósticas y cirugías en diferentes niveles `,
          body: `Aunque es posible que el seguro contratado lo utilices de manera esporádica y por cuestiones simples, es importante que las coberturas también incluyan pequeños procedimientos, tales como estudios de laboratorio e intervenciones de mediano y alto nivel. 
          Con ello tendrás una atención médica integral que te garantice diagnósticos, tratamientos y cirugías de calidad.
          Adicional a estas cinco coberturas básicas sería importante que tu seguro cuente con otras coberturas extra como: 
          `,
          list: [
            `Atención por COVID-19.`,
            `Catálogo de medicamentos (gratis o con descuento).`,
            `Atención domiciliaria.`,
            `Compensación por invalidez.`,
            `Atención dentro y fuera de tu ciudad o país de residencia. `,
          ],
          footer: `Antes de escoger un seguro de gastos médicos, no olvides revisar las especificaciones en cuanto a deducibles, preexistencias y otros factores que podrían extender el cumplimiento de tus coberturas. 
          ¡Consulta, analiza y contrata!
          ¿Quieres contratar el seguro de gastos médicos que más se adapte a tus necesidades? 
          Ingresa a <>inter.mx</> y conoce todas las opciones disponibles para que elijas en tan sólo unos minutos. 
          No olvides seguirnos en nuestros perfiles de <>Instagram</> y <>Facebook</> para estar al tanto de las diferentes opciones que <>inter.mx</> tiene para ti y tu familia.
          `,
        },
      ],
    },
    {
      img: BG33,
      date: "18 Diciembre, 2021",
      autor: "admininter",
      title: "Seguro para Auto | 10 Cosas que debes hacer en un choque",
      body: "Saber todo sobre tu seguro para auto será indispensable para llevar de mejor manera cualquier accidente de tránsito. ¡Maneja libre y sin preocupaciones!",
      content: [
        {
          header: ``,
          body: `Hoy en inter.mx te traemos 10 tips que te ayudarán a salir con confianza, seguridad y, sobre todo, tranquilidad de cualquier tipo de accidente de tránsito que pueda ocurrir. Con tu seguro para auto tienes un aliado que te respalda siempre que lo necesites. 
          Nadie está exento de sufrir un choque al volante, los imprevistos están en todas partes y hay muchas situaciones que van más allá de tu control. Sin embargo, hay una serie de pasos que puedes seguir para salir bien librado de la situación y aprovechar tu seguro para auto al máximo.
          `,
        },
        {
          header: `1)	Mantén la calma`,
          body: `Lo primero que debes hacer es mantener la calma. Es necesario pensar con la cabeza fría para solucionar la situación lo más pronto posible y lograr que todos los involucrados lleguen a un común acuerdo.`,
          list: [],
          footer: ``,
        },
        {
          header: `2)	Sal de la vía`,
          body: `Si es posible, aparta tu vehículo fuera de la vía para evitar embotellamientos. Si alguno de los coches no puede moverse, los pasajeros deben alejarse caminando y colocar la señalización correspondiente para informar a otros conductores sobre la situación. `,
          list: [],
          footer: ``,
        },
        {
          header: `3)	Primeros auxilios`,
          body: `Revisa que todos los pasajeros de tú auto y de los demás vehículos involucrados estén bien. De ser necesario, bríndale los primeros auxilios correspondientes a quien lo necesite.`,
          list: [],
          footer: ``,
        },
        {
          header: `4)	Llama al 911`,
          body: `Llama al 911 para reportar el accidente y que las autoridades correspondientes lleguen lo más rápido posible.`,
          list: [],
          footer: ``,
        },
        {
          header: `5)	Llama a la compañía que emitió tu seguro para auto`,
          body: `Comunícate con tu compañía aseguradora para reportar el siniestro y que un agente se dirija a tu ubicación para evaluar la situación. Esto será indispensable para que posteriormente puedas utilizar tu póliza de seguro para auto.`,
          list: [],
          footer: ``,
        },
        {
          header: `6)	Evalúa los daños`,
          body: `Evalúa los daños de los autos y los pasajeros. Verifica que todos hayan recibido los primeros auxilios y toma nota de los daños a tu auto para que puedas saber con seguridad cuáles serán los arreglos necesarios.`,
          list: [],
          footer: ``,
        },
        {
          header: `7)	Información relevante`,
          body: `Recopila toda la información que creas necesaria sobre los vehículos y sus pasajeros: nombres, licencias, identificaciones, etc.`,
          list: [],
          footer: ``,
        },
        {
          header: `8)	Toma fotos`,
          body: `Reúne imágenes de los daños que presentan los vehículos, así como de la zona en la que ocurrió el accidente. Esto será especialmente útil si es necesario hacer cualquier tipo de reclamación.`,
          list: [],
          footer: ``,
        },
        {
          header: `9)	Revisa todos los detalles`,
          body: `Asegúrate de que todos los documentos tramitados ante las autoridades oficiales y el agente de la aseguradora estén en orden. En caso de que encuentres alguna inconsistencia debes reportarla de inmediato.`,
          list: [],
          footer: ``,
        },
        {
          header: `10)	Seguimiento`,
          body: `En el caso de que los vehículos tengan que ser movilizados a otro lugar, haz un seguimiento de la compañía remolcadora y el lugar en el que permanecerá.
          Manténte tranquilo en la carretera y conduce con la seguridad que te ofrece <>inter.mx</>. En caso de emergencia llámanos al 55 442 - 46837 para darte toda la asesoría relacionada con tu seguro para auto.
          Visita <>inter.mx</> y conoce todo nuestro catálogo de productos especialmente diseñados para brindarte seguridad y confianza al volante.
          No olvides seguirnos en nuestras redes de <>Instagram</> y <>Facebook</>.
          `,
          list: [],
          footer: ``,
        },
      ],
    },
    {
      img: BG34,
      date: "18 Diciembre, 2021",
      autor: "admininter",
      title:
        "Seguro para Auto | ¿Cuánto debo invertir para sentirme tranquilo?",
      body: "Entérate de qué depende el monto que pagas por tu seguro para auto y cómo medir la inversión para éste ¡inter.mx es tu mejor opción! ¡Ingresa ahora!",
      content: [
        {
          header: ``,
          body: `Debido a la amplia oferta de seguros en el mundo, contratar un seguro para auto en México puede convertirse en una verdadera travesía. Y sabemos que alrededor de esta decisión surgen cientos de preguntas:`,
          list: [
            `¿Cuál es la mejor opción? `,
            `¿Cómo contratarlo? `,
            `¿Cuál es el precio justo?`,
            `¿Qué tipo de coberturas debe incluir? `,
            `¿Cómo tomar la mejor decisión?`,
          ],
          footer: `Por suerte para ti, <>inter.mx</> tiene la respuesta para cada una de estas preguntas. Continúa leyendo y conoce cómo contratar la opción que más se ajusta a tu presupuesto y necesidades. `,
        },
        {
          header: `Contrata un seguro para auto perfecto para ti `,
          body: `Aunque hayas escuchado por ahí que el precio de tu seguro para auto depende directamente de las <>coberturas</> que ofrece, es momento de borrar esa premisa de tu cabeza. Pues si bien esto influye en la consolidación del precio final, hay otros factores adicionales que ayudarán a determinar el costo final de tu seguro. 
          En el sector de seguros de coches, casi tanto como en la bolsa de valores, todo se determina gracias a la probabilidad. Esta fórmula matemática milenaria sirve a las aseguradoras para determinar cuál es la probabilidad de que un carro asegurado se involucre en un siniestro y el posible coste de dicho siniestro. 
          Todo esto se calcula <>dependiendo del año de fabricación</> del auto, marca, modelo y más. 
          El valor final del seguro para auto que contrates, dependerá de factores como: 
          `,
          list: [
            `Tipo de auto.`,
            `Marca. `,
            `Antigüedad.`,
            `Referencia o submarca.`,
            `Uso. `,
            `Siniestralidad.`,
            `Perfil del contratante. `,
          ],
          footer: `Como verás, son varios los factores que determinan el precio de un seguro. Por esto, es importante conocer las diferentes opciones que el mercado ofrece. Con esta información sabrás cuál de todas se ajusta más a tus necesidades y las de tu vehículo. Y por necesidades, nos referimos tanto a las coberturas como al presupuesto que tengas destinado para gastar en tu póliza. 
          En este orden, podemos asegurar que el precio adecuado para invertir en tu seguro no depende en realidad de cuántos ceros tiene al final, sino de la calidad del servicio que estás dispuesto a contratar, y por supuesto, de la cobertura que tu coche necesita. 
          De esta manera, la única forma de conocer exactamente cuál es el valor adecuado para invertir es cotizar las diferentes opciones que el mercado tiene para la cobertura de tu auto.
          Pero ¿Cómo hacerlo? 
          Es sencillo, ingresa a <>inter.mx</>, cotiza y contrata el seguro para auto que más se acomode a tus necesidades, tu coche y tu presupuesto. 
          Y no olvides seguirnos en nuestros perfiles de <>Instagram</> y <>Facebook</>.
          `,
        },
      ],
    },
    {
      img: BG19,
      date: "18 Noviembre, 2021",
      autor: "admininter",
      title: "Seguro de Coche para Socios Uber - DiDi - Cabify - Easy Taxi",
      body: "Contrata el seguro de coche especialmente diseñado para conductores de las las aplicaciones de transporte privado de pasajeros. ¡Conoce más!",
      content: [
        {
          header: ``,
          body: `Sabemos que encontrar un seguro de coche con cobertura amplia que ampare a los conductores de las aplicaciones de transporte privado de pasajeros no es nada fácil. Afortunadamente, AXA ha desarrollado 2 diferentes productos especialmente para ti, conductor, qué necesitas un respaldo en todo momento para desempeñar tu trabajo y proteger tu coche. `,
        },
        {
          header: `Protect U: seguro de coche para socios UBER`,
          body: `<>AXA</>, en asociación con Uber, ha creado un seguro de coche para Socios Conductores con la app de Uber que buscan complementar el seguro gratuito Blanket.
          Protect U nace como el complemento perfecto para aumentar la cobertura de los socios conductores sin importar si la aplicación está encendida o no, o si los usuarios permanecen dentro de la unidad o han concluido su viaje. 
          Su funcionamiento es simple, cuando la aplicación de Uber está apagada, Protect U entra en acción. Asimismo, cuando la aplicación está encendida, pero aún no es designado algún pasajero, las coberturas de daños materiales y robo total se activan.
          Mientras Blanket te protege “en viaje”, “en ruta por el usuario” y con la “app encendida”, Protect U te respalda cuando esperas a un usuario y te protege en todo momento con la “app apagada”. Es decir, cuando utilizas tu unidad como particular.
          Lo mejor de este seguro de coche es que tienen los deducibles más bajos del mercado:
          `,
          list: [
            `10% de deducible en daños materiales y robo total`,
            `20% de deducible en rotura de cristales`,
            `No pagas deducible en responsabilidad civil, daños a terceros, gastos médicos de ocupantes y accidentes automovilísticos al conductor.`,
          ],
          footer: ``,
        },
        {
          header: `Protect app: seguro para todas las plataformas`,
          body: `Por otro lado, AXA también cuenta con un seguro de coche que brinda protección a todos los socios conductores que trabajan con varias plataformas al mismo tiempo, por ejemplo en UBER, DiDi, BEAT o Cabify. 
          Este producto te protege cuando la app está prendida o apagada y si te encuentras en viaje o en espera de un usuario. Y lo mejor es que también cuenta con los deducibles más bajos.
          `,
          list: [
            `10% deducible en daños materiales.`,
            `20% de deducible por robo total.`,
            `50 <>UMA</> en responsabilidad civil por daños a terceros.`,
            `No pagas deducible en responsabilidad civil por personas en exceso, por daños del viajero ni por gastos médicos de ocupantes.`,
          ],
          footer: `En cuanto a las formas de pago, éstas son bastante accesibles y se adaptan a tus posibilidades. Puedes pagar tu prima mensual, trimestral, semestral o anualmente.
          Contrata tu seguro de coche ideal en <>inter.mx</>, el bróker de seguros # 1 de México.
          Ingresa a nuestro portal, cotiza, elige y contrata en tan solo unos minutos. ¡Nunca antes había sido tan fácil!
          No olvides seguirnos en nuestras redes sociales para estar al tanto de nuestras promociones, lanzamientos y noticias: <>Facebook</> – <>Instagram</> – <>LinkedIn</> – <>YouTube</>.`,
        },
      ],
    },
    {
      img: BG20,
      date: "18 Noviembre, 2021",
      autor: "admininter",
      title: "Glosario de Conceptos que no entiendes sobre los Seguros",
      body: "En temas de seguros, muchas veces no entendemos bien los conceptos que se utilizan. Por fortuna, en inter.mx te los explicamos mejor ¡Ingresa ahora!",
      content: [
        {
          header: ``,
          body: `En materia de seguros, existen diferentes términos o conceptos técnicos que te ayudarán a comprender mejor cómo funciona tu seguro, en qué momento utilizarlo y cómo sacarle el mejor provecho.`,
        },
        {
          header: `Glosario de conceptos sobre Seguros`,
          body: `Aquí encontrarás un breve, pero completísimo glosario de conceptos, que te ayudarán a comprender y a saber utilizar adecuadamente tu seguro de vida, de coche, te casa o de mascota.
          Recuerda que en <>inter.mx</> encontrarás el seguro que más se ajusta a tus necesidades, rápido, fácil y con los mejores precios del mercado. 
          ¡Entra a nuestro portal y asegura lo que más quieres!
          `,
          list: [],
          footer: ``,
        },
        {
          header: `Póliza`,
          body: `Una póliza es un contrato que se celebra entre la compañía aseguradora y el contratante o asegurado. Este documento viene con todos los seguros y contiene los riesgos que cubre el seguro, tanto como exclusiones, beneficios, obligaciones y derechos.`,
          list: [],
          footer: ``,
        },
        {
          header: `Cobertura`,
          body: `La cobertura es el compromiso por parte de la aseguradora para brindar protección ante los diferentes riesgos que pertenecen a rubros tales como salud, vida, auto, entre otros.`,
          list: [],
          footer: ``,
        },
        {
          header: `Riesgo`,
          body: `El riesgo es la posibilidad de que un suceso incierto, el cual no depende de la voluntad del asegurado ni de la aseguradora, genere un accidente.`,
          list: [],
          footer: ``,
        },
        {
          header: `Condiciones generales`,
          body: `Las condiciones generales son las regulaciones y estipulaciones que rigen la póliza contratada. Asimismo, contienen el desglose de las coberturas de acuerdo a los riesgos que cubre cada seguro.`,
          list: [],
          footer: ``,
        },
        {
          header: `Exclusiones`,
          body: `Las exclusiones son las declaraciones que vienen integradas en la póliza de tu seguro, generalmente en letras grandes y negritas. Éstas indican los riesgos en donde no hay cobertura. En caso de endoso las exclusiones se pueden alterar.`,
          list: [],
          footer: ``,
        },
        {
          header: `Vigencia`,
          body: `La vigencia en el ámbito de los seguros es el tiempo de duración de una póliza, se específica al contratar tu seguro.
          Te puede interesar: <>5 claves para elegir el Seguro para Coche perfecto</>.
          `,
          list: [],
          footer: ``,
        },
        {
          header: `Prima`,
          body: `La prima es el costo del seguro, depende de las coberturas contratadas y la duración de la póliza. El pago de la prima puede realizarse en una sola exhibición o en períodos mensuales, bimestrales, semestrales o anuales.`,
          list: [],
          footer: ``,
        },
        {
          header: `Siniestro`,
          body: `El siniestro es un evento causado o provocado por un agente externo que genera un daño o una pérdida económica y que está amparado en la póliza contratada.`,
          list: [],
          footer: ``,
        },
        {
          header: `Deducible`,
          body: `El <>deducible</> aplica para todos los seguros y se trata de la suma de dinero que te corresponde pagar frente a un siniestro. Recuerda que la responsabilidad se divide entre la aseguradora y el asegurado, por lo que el deducible es la parte que tu compañía de seguros no indemniza.`,
          list: [],
          footer: ``,
        },
        {
          header: `Deducible`,
          body: `El deducible aplica para todos los seguros y se trata de la suma de dinero que te corresponde pagar frente a un siniestro. Recuerda que la responsabilidad se divide entre la aseguradora y el asegurado, por lo que el deducible es la parte que tu compañía de seguros no indemniza.`,
          list: [],
          footer: ``,
        },
        {
          header: `Reclamación`,
          body: `La reclamación es el pago que se exige a la aseguradora por los daños cubiertos en la póliza a causa de un siniestro. Para realizar este proceso es necesario presentar algunos documentos que se especifican dentro de las condiciones generales de la póliza.`,
          list: [],
          footer: ``,
        },
        {
          header: `Coaseguro`,
          body: `El coaseguro es la segunda cantidad a pagar por parte del asegurado después del deducible y sirve para pagar por cada enfermedad o accidente. A diferencia del deducible que sólo se paga una vez, el coaseguro se hace en cada reclamación.`,
          list: [],
          footer: ``,
        },
        {
          header: `Pérdida total`,
          body: `En el caso de un seguro de coche, se considera pérdida total cuando el costo de la reparación supera el 100% del valor asegurado, es decir del automóvil completo.`,
          list: [],
          footer: ``,
        },
        {
          header: `Pérdida parcial`,
          body: `La pérdida o daño parcial se refiere a los daños que el vehículo o bien asegurado recibe y que tienen un costo de reparación menor al 50% del valor comercial. 
          ¿Sabías que en <>inter.mx</> puedes ahorrar hasta un 30% en tu seguro? Somos el bróker de seguros # 1 en México.
          ¡Contrata tu seguro con el mejor precio del mercado!
          Síguenos en <>Facebook</> – <>Instagram</> – <>LinkedIn</> – <>YouTube</>. 
          `,
          list: [],
          footer: ``,
        },
      ],
    },
    {
      img: BG21,
      date: "18 Noviembre, 2021",
      autor: "admininter",
      title: "Seguro de Coche | ¿Qué hacer en caso de un choque? ",
      body: "Si cuentas con un Seguro de Coche, estos son los pasos a seguir ante cualquier siniestro. Sigue nuestras recomendaciones y viaja tranquilo con inter.mx.",
      content: [
        {
          header: ``,
          body: `Si cuentas con un seguro de coche y acabas de tener un accidente automovilístico, o bien, tienes planes de recorrer la ciudad o salir a carretera y estás previendo todo lo que vas a necesitar, este post es para ti.`,
        },
        {
          header: `¿Qué hacer ante un choque?`,
          body: `Tener un seguro de coche siempre es garantía de que tendrás un respaldo económico confiable y sólido para hacer frente a cualquier accidente automovilístico. 
          A continuación, te vamos a dar una serie de pasos que debes seguir inmediatamente después de sufrir un choque.
          `,
          list: [
            `Mantén la calma. Siempre es mejor actuar con la cabeza fría.`,
            `Asegúrate de que tú y tus acompañantes se encuentran bien, así como las demás personas involucradas en el accidente. En caso de requerir atención médica comunícate al 911.`,
            `Identifica el lugar donde te encuentras. Esta información será de utilidad para que el ajustador y los servicios de emergencia lleguen de forma rápida y precisa al lugar del siniestro.`,
            `Localiza tu póliza de coche.`,
            `Contacta a tu aseguradora y notifica el siniestro. Es fundamental que informes detalladamente los hechos del siniestro para agilizar el proceso.`,
            `Permanece en el lugar del siniestro hasta que el ajustador llegue, siempre y cuando tu integridad y la de las demás personas involucradas no estén en riesgo.`,
            `No pierdas comunicación con tu aseguradora para informar de cualquier cambio en la situación.`,
          ],
          footer: ``,
        },
        {
          header: `Recomendaciones `,
          body: `Un seguro de coche protege a las personas que van dentro del vehículo y a las que resulten afectadas en caso de un siniestro. Asimismo, brinda protección a tu vehículo por daño, robo y te respalda ante daños a terceros.
          Sin embargo, para que puedas disfrutar de tu viaje con toda tranquilidad es indispensable tomar en cuenta las siguientes recomendaciones.
          Antes de salir de casa: 
          `,
          list: [
            `Verifica que tú póliza de seguro esté vigente. Esto lo puedes revisar desde la <>app de inter.mx</>.`,
            `Asegúrate de portar tu documentación oficial (INE, tarjeta de circulación y licencia de conducir) y tu póliza.`,
            `Revisa los números telefónicos de personas cercanas a las que puedas contactar en caso de emergencia.`,
          ],
          footer: `Si sigues estas indicaciones podrás atender de la mejor manera cualquier siniestro con la ayuda de tu aseguradora, ajustador y personas involucradas en el accidente.
          Por último, recuerda que para resolver cualquier duda sobre tu seguro de coche puedes contactarnos al teléfono 55 4424 – 6837, o vía redes sociales en las cuentas oficiales de inter.mx: <>Facebook</> – <>Instagram</> – <>Linkedin</>.
          Estaremos a tu disposición en todo momento. ¡Contáctanos!
          `,
        },
      ],
    },
    {
      img: BG22,
      date: "18 Noviembre, 2021",
      autor: "admininter",
      title: "El Buen Fin 2021 es para ti ¡Contrata el mejor Seguro de Coche!",
      body: "¿Buscas contratar un Seguro de Coche al mejor precio? El Buen Fin es la oportunidad que estabas esperando. ¡Contrata en un solo click!",
      content: [
        {
          header: ``,
          body: `La edición del Buen Fin 2021 es uno de los eventos más importantes de este año para conseguir productos y servicios a los mejores precios. Y para que aproveches estos días, <>inter.mx</> tiene para ti las mejores promociones y ofertas. ¡Encuentra el seguro de coche que estabas buscando!`,
        },
        {
          header: `Buen Fin 2021`,
          body: `Si eres de las personas que buscan encontrar descuentos, ofertas y promociones para aprovechar todo tipo de productos y servicios, el Buen Fin es para ti. Y más si se trata de contratar un seguro de coche que te brinde el respaldo, la protección y la tranquilidad qué buscas en un producto financiero.
          Entonces, si quieres aprovechar de verdad el Buen Fin ¡Toma nota!
          Del 10 hasta el 16 de noviembre del 2021 podrás hacer tus compras en línea o en las tiendas en físico con los descuentos y promociones que te ofrece este gran evento. 
          Son 7 días durante los cuales tendrás acceso a los mejores precios del mercado en los productos y servicios de las marcas participantes. ¡No te lo puedes perder!
          El Buen Fin es la mejor época del año para encontrar lo que necesitas y lo que mejor se adapta a tu estilo de vida.
          Te puede interesar: <>¿Qué necesitas que cubra tu seguro de gastos médicos mayores?</>
          `,
          list: [],
          footer: ``,
        },
        {
          header: `¡Contrata tu seguro de coche ideal!`,
          body: `En <>inter.mx</> sabemos que buscas un producto financiero integral para manejar tu automóvil por toda la ciudad y en cualquier carretera con una protección sólida ante cualquier imprevisto. 
          Y si, estamos hablando de un seguro de coche completo que te garantice un respaldo económico para cubrir todas las necesidades que se te presenten en el camino.
          Lo único que tienes que hacer es ingresar a <>inter.mx</> durante las fechas programadas para el Buen Fin. Ahí encontrarás productos innovadores que podrás cotizar al instante y elegir el que mejor se adapte a lo que estás buscando. 
          Te daremos precios, coberturas y beneficios de cada uno de los productos. De esta manera, tendrás la seguridad de que estás eligiendo un producto que beneficia no sólo a tu bolsillo, sino también a tu bienestar y el de tus seres queridos.
          Además, nuestro objetivo es acompañarte en cualquier momento y brindarte la mejor experiencia con tu seguro de coche, así que no dudes en solicitar una asesoría con nosotros.
          Contrata el mejor seguro de coche en <>inter.mx</>, el mejor bróker de seguros de México.
          No olvides seguirnos en nuestras redes sociales para estar al tanto de promociones, noticias y nuevos lanzamientos: <>Instagram</> – <>YouTube</> – <>LinkedIn</> – <>TikTok</>.
          `,
          list: [],
          footer: ``,
        },
      ],
    },
    {
      img: BG23,
      date: "18 Noviembre, 2021",
      autor: "admininter",
      title: "Seguro de Gastos Médicos | ¿Cuándo debo hacerme un chequeo?",
      body: "Conoce cuál es la frecuencia recomendada para realizarse chequeos médicos y por qué contratar un seguro de gastos médicos es tu mejor opción.",
      content: [
        {
          header: ``,
          body: `Los chequeos médicos de rutina son importantes no solo para cuidar nuestra salud, sino también para mejorar hábitos y evitar posibles malestares. Esto es muy sencillo cuando cuentas con un seguro de gastos médicos adecuado a tus necesidades y las de tus seres queridos. 
          Continúa leyendo y conoce cuáles son las recomendaciones de los expertos para cuidar tu salud.
          `,
        },
        {
          header: `Sobre la periodicidad de los chequeos`,
          body: `Según los <>expertos</>, es importante hacer chequeos constantes, pero la frecuencia directa depende de las condiciones de salud de cada persona. Aún así, recomiendan hacer mínimo un chequeo cada cuatro años antes de cumplir 40 años de edad, cada dos hasta los 60 y una vez al año después de esta edad. 
          Sin embargo, la frecuencia también depende de otros factores. Por ejemplo, una mujer embarazada o un niño menor de 3 años deben realizarse chequeos mucho más frecuentes. Esto claro, según las recomendaciones médicas. 
          Por otra parte, debemos considerar que hay momentos específicos de la vida en los que es importante hacer ciertos exámenes. Por ejemplo, cuando se trata de visitar al ginecólogo o al urólogo, un diagnóstico o tratamiento certeros siempre serán indispensables.
          Recuerda que los exámenes de rutina son útiles principalmente para: 
          `,
          list: [
            `Identificar y prevenir enfermedades.`,
            `Prevenir algunos riesgos médicos a futuro.`,
            `Conocer mejores estilos de vida y hábitos más saludables.`,
            `Evitar complicaciones por condiciones de salud particulares.`,
          ],
          footer: `Estos chequeos puedes hacerlos por medio de entidades particulares como un seguro de gastos médicos, o bien, a través del <>servicio público de salud</>. Tu elección de servicio dependerá directamente de los intereses personales. `,
        },
        {
          header: `¿Por qué contratar un Seguro de Gastos Médicos?`,
          body: `Verás, contar con un seguro de gastos médicos adecuado a tus necesidades y presupuesto, será de gran ayuda para realizar todos los chequeos y exámenes de rutina recomendados por los expertos. 
          Adicionalmente, y según las coberturas del seguro de gastos médicos que elijas, tendrás apoyo económico y profesional en caso de algún padecimiento que pueda llegar a limitar tus capacidades. 
          Es decir, aunque el seguro funciona más como una estrategia para evitar el desarrollo de cualquier enfermedad, también puede ser tu gran aliado cuando requieras un tratamiento especializado. 
          ¿Quieres conocer dónde adquirir el seguro de gastos médicos que más se adapte a tus necesidades? 
          Ingresa a <>inter.mx</> y conoce todas las opciones disponibles para que elijas en tan sólo unos minutos. 
          No olvides seguirnos en nuestros perfiles de <>Instagram</> y <>Facebook</> para estar al tanto de las diferentes opciones que <>inter.mx</> tiene para ti y tu familia.
          `,
          list: [],
          footer: ``,
        },
      ],
    },
    {
      img: BG24,
      date: "18 Noviembre, 2021",
      autor: "admininter",
      title: "Seguro de Coche | ¿Cada cuánto se deben cambiar las llantas?",
      body: `Además de contar con un Seguro de Coche, es muy importante tener las llantas de tu auto en buen estado. Apuesta por una mayor seguridad al volante.`,
      content: [
        {
          header: ``,
          body: `Como el bróker de seguros #1 de México, nos esforzamos por acercarte las mejores opciones de seguro de coche del mercado según tus necesidades. Pero, además de brindarte un respaldo financiero ante cualquier siniestro, en <>inter.mx</> nos preocupamos por tu seguridad al volante.
          Por eso queremos que siempre te sientas protegido cuando estés en el carro, para que no sufras contratiempos. 
          El tema que queremos atender el día de hoy es uno que preocupa a muchos conductores. ¿Sabes cada cuánto tiempo debes cambiar las llantas de tu auto?
          `,
        },
        {
          header: `¿Ya están gastadas?`,
          body: `En primer lugar te vamos a enseñar a conocer el desgaste de tus llantas, así sabrás si ya es momento de cambiarlas o no. Esta información es tan útil como contar con un buen seguro de coche. De eso puedes estar seguro.
          Hay muchos factores que pueden provocar el desgaste prematuro de las ruedas. Las malas condiciones de las rutas, altas temperaturas, un mal inflado, tus propios hábitos de conducción o errores en la inclinación y balanceo del coche, son las principales causas por las cuales las llantas se desgastan con facilidad.
          Lo fundamental para medir el estado de desgaste es que te fijes en el dibujo del rodado. Todas las ruedas traen indicadores de profundidad, cuya función es señalar cuando se ha alcanzado el máximo desgaste aceptable. Por lo general, se encuentran a la altura de algún dibujo marcado que encontrarás en el costado de la llanta.
          Pero también hay otros aspectos que te dirán si tus llantas están al límite o no. Por ejemplo: si tiene cortes, golpes, algún objeto incrustado o alguna protuberancia.
          `,
          list: [],
          footer: ``,
        },
        {
          header: `¿Cuál es el tiempo estimado para el cambio de llantas?`,
          body: `Los expertos recomiendan cambiar las llantas antes de los 10 años. Aunque los buenos hábitos de manejo y el cuidado constante pueden hacer una gran diferencia, es posible que las tengas que cambiar antes de ese tiempo si no cumples con estos requisitos.
          Lo recomendable es que a partir de los 5 años de instalar llantas nuevas las empieces a llevar al mecánico para una breve inspección. Haz esto por lo menos una vez al año. De esta manera te asegurarás de mantener tus llantas en perfecto estado y listas para llevarte a donde quieras.
          `,
          list: [],
          footer: ``,
        },
        {
          header: `Un seguro de coche para garantizar tu seguridad financiera`,
          body: `En <>inter.mx</> sabemos de la responsabilidad que conlleva manejar un automóvil. Por ello, te recomendamos siempre hacerlo con respeto, educación y tomando todas las precauciones para evitar desperfectos y accidentes. De todo lo demás nos encargamos nosotros.
          Encuentra en nuestro portal el mejor seguro de coche para que manejes con tranquilidad y llegues a tu destino sabiendo que tienes un respaldo financiero sólido y confiable. Somos tu aliado ideal.
          Visita nuestro <>inter.mx</> para conocer todas tus alternativas. <>Elige y cotiza la cobertura adecuada para ti</>. 
          Síguenos en <>Facebook</> – <>Instagram</> – <>LinkedIn</> – <>YouTube</>.
          `,
          list: [],
          footer: ``,
        },
      ],
    },
    {
      img: BG25,
      date: "18 Noviembre, 2021",
      autor: "admininter",
      title: "¿El mercado de seguros cambió tras la pandemia?",
      body: `La pandemia afectó la economía global y el mercado de Seguros de Gastos Médicos, de Vida y de Coches. ¿Hay indicios de recuperación? ¡Entérate!`,
      content: [
        {
          header: ``,
          body: `El desarrollo del mercado de seguros siempre ha estado ligado directamente al contexto en el que vivimos. Cuanto mayores son las preocupaciones de las personas, la necesidad de tener un respaldo económico aumenta. Pero, ahora que la incertidumbre es más grande a causa de la pandemia por COVID-19, ¿cómo se afectó el mercado de seguros de gastos médicos, de vida y de coches?`,
        },
        {
          header: `Una crisis que afectó a todos`,
          body: `Antes de abordar la cuestión sobre los seguros de gastos médicos, de vida y de coches, veamos el panorama general de la economía. Según información del <>Banco Mundial</>, los efectos que tuvo el COVID-19 en la economía del planeta son incluso peores que los que dejó la devastadora Segunda Guerra Mundial. Se estima que hubo una recesión mundial del 4.3%.
          El caso de México no es distinto, el INEGI informó a principios del 2021 que el PIB nacional había caído un 8.5% durante el 2020. Solamente una cifra de diferencia con la Gran Depresión en los años 30.
          Aunque muchos creerían que, durante una situación como esta, el mercado de seguros de gastos médicos, de vida y de coches sacó grandes réditos, lo cierto es que tuvo una contracción del 2.7%, tal y como lo explicó la revista <>Forbes</>.
          `,
          list: [],
          footer: ``,
        },
        {
          header: `¿Por qué se vieron perjudicados los seguros?`,
          body: `Si bien la contratación de seguros pudo haber aumentado, también se incrementó la cantidad de pólizas ejecutadas y, bajo esta misma lógica, la cantidad de sumas aseguradas entregadas a los clientes.
          En otras palabras, el mercado de seguros tuvo que gastar más capital del que ingresó. En México las aseguradoras reportaron más de 1,800 millones de dólares en indemnizaciones.
          Esto se debió a que muchas personas tuvieron que hacer uso de sus seguros de gastos médicos o de vida durante la pandemia. Sin embargo, esto no se tiene que ver como algo negativo, ya que fue una oportunidad para que las compañías del sector demostraran su compromiso de velar por el bienestar de sus clientes.
          Por ejemplo, quienes cuentan con un seguro de gastos médicos mayores contratado en <>inter.mx</>, están cubiertos por gastos hospitalarios, honorarios médicos, medicamentos o ambulancias tras contraer COVID-19 y cualquier otra enfermedad.
          Por suerte el panorama parece mejorar, los casos de contagios cada vez bajan más, la vacunación avanza a una escala mundial y la economía empieza a recuperarse. 
          En <>inter.mx</> tenemos todo lo que necesitas para sentirte protegido en esta nueva época que apenas comienza, ¡visita nuestro sitio web y conoce más sobre nuestros productos!
          No olvides seguirnos en nuestras redes de <>Instagram</> y <>Facebook</>.
          `,
          list: [],
          footer: ``,
        },
      ],
    },
    {
      img: BG26,
      date: "18 Noviembre, 2021",
      autor: "admininter",
      title: "Seguro de Auto | Ventajas de comprar un coche de segunda mano",
      body: `Conoce las ventajas de comprar un coche de segunda mano y cuál es la opción de seguro de auto que más se ajusta a tus necesidades. ¡Ingresa ahora!`,
      content: [
        {
          header: ``,
          body: `La decisión de cuándo, cómo y dónde comprar tu coche puede ser una de las más importantes de tu vida pues en tu cabeza rondan muchas preguntas. Según tu presupuesto puedes enfrentarte a muchas opciones, desde coches nuevos y completamente equipados, hasta autos usados y más sencillos. Pero para cualquier caso, hay gastos extras que debes contemplar como el seguro de auto, mantenimiento, impuestos y demás. 
          Continúa leyendo y conoce cuál es la mejor opción, según tus necesidades y presupuesto.
          `,
        },
        {
          header: `Ventajas de comprar un Coche Usado `,
          body: `Actualmente, con la cantidad de coches en el mercado, existen muchas ventajas asociadas a la compra de autos usados. Además que la contratación de un seguro de auto te facilita el control y manejo de los gastos relacionados a tu vehículo.`,
          list: [],
          footer: ``,
        },
        {
          header: `Precios insuperables`,
          body: `Sin duda alguna, una de las principales ventajas de comprar un coche usado es que se pueden conseguir precios increíbles en el mercado. Y más si tomamos en cuenta que hay autos en muy buen estado y con poco kilometraje en su historial. 
          Asimismo, los vehículos de segunda mano no se <>desvalorizan</> de manera tan acelerada como pasa con los carros recién salidos del concesionario.
          ¡Recuerda! En promedio un auto nuevo pierde el 20% de su valor inicial solo en el primer año.  
          Lo único que debes hacer al comprar un auto de segunda mano es asegurarte que el coche realmente se encuentra en buen estado y que cuenta con todas las especificaciones que el vendedor está ofreciendo. 
          `,
          list: [],
          footer: ``,
        },
        {
          header: `Menos costos en mantenimiento y seguro de auto  `,
          body: `Si bien un coche usado puede requerir más costos de mantenimiento, según el uso y cuidado del vehículo, en general los arreglos y ajustes necesarios no te obligan a hacerlos directamente con los concesionarios. 
          Puedes optar por llevarlo a tu mecánico de confianza sin miedo a perder la garantía -pues ya no existe tal cosa-. No obstante, con la ayuda de un seguro de auto es posible mitigar estos gastos y obtener siempre un mejor servicio. 
          Conoce <>aquí </>todas las opciones que <>inter.mx</> tiene para ti y tu coche de segunda mano.
          `,
          list: [],
          footer: ``,
        },
        {
          header: `Compra Segura `,
          body: `Gracias a los avances tecnológicos, en la actualidad es más seguro comprar un coche usado debido a los requisitos y garantías de las diferentes <>plataformas</> de compra-venta de autos en México y el mundo. 
          Aunque nadie puede garantizar una compra 100% segura, la llegada de estas nuevas formas de comercio, han abierto un mundo de posibilidades para aquellos interesados en comprar coches de segunda mano. 
          Y lo cierto es que no importa qué tan antiguo sea el auto que escojas, siempre podrás adquirir un seguro de auto para garantizar el mantenimiento y buen estado de tu coche. 
          ¿Quieres conocer cómo hacerlo? Ingresa a <>inter.mx</> y contrata el seguro de auto que más se acomode a tus necesidades y presupuesto. 
          Y no olvides seguirnos en nuestros perfiles de <>Instagram</> y <>Facebook</>.
          `,
          list: [],
          footer: ``,
        },
      ],
    },
    {
      img: BG1,
      header: "¿Mi Seguro para Coche aplica si se roban el auto?",
      date: "21 octubre, 2021",
      autor: "admininter",
      body: "Contratar un seguro para coche es una decisión que se debe tomar con toda la información disponible. Esto ayudará a que la compra sea mucho más razonable y la sensación",
      title: `PROTECCIONES Y CUIDADOS ADICIONALES A UN SEGURO DE AUTO`,
      content: [
        {
          header: ``,
          body: `Un seguro de auto es tal vez la mejor forma de cuidar de tu coche. Pero también existen otros cuidados adicionales que puedes poner en práctica para prolongar la vida útil de tu motor y mantenerlo siempre impecable.
          Y lo mejor de todo es que son cuidados simples, que no requieren mucho tiempo, ni dinero. A continuación, te contamos cuáles son ¡Toma nota!`,
        },
        {
          header: `La prevención es tu mejor aliado`,
          body: `No importa si es de primera o segunda mano, lo primero que debes hacer cuando compras un coche (además de contratar un seguro de auto), es llevar un registro de los kilómetros recorridos, para que regularmente hagas revisiones y mantenimientos preventivos.
          ¡No esperes hasta el último momento para dar una visita al mecánico!
          Ten siempre presente que los mantenimientos que debes hacer con más frecuencia son:`,
          list: [
            `Revisión general del motor y sistema eléctrico (si tu coche tiene uno).`,
            `Mantenimiento de frenos y todos sus componentes.`,
            `Cambio de llantas cuando sea pertinente (por ejemplo, cuando ya están muy lisas).`,
            `Revisión de amortiguadores.`,
            `Chequeo constante a los niveles de líquidos del motor (como el líquido de frenos o el anticongelante).`,
          ],
          footer: `Adicionalmente, no olvides pedirle a tu mecánico que revise otros elementos externos como las luces o el claxon para que queden siempre bien conectados y funcionales.`,
        },
      ],
    },
    {
      img: BG2,
      date: "21 octubre, 2021",
      autor: "admininter",
      body: "Contratar un seguro para coche es una decisión que se debe tomar con toda la información disponible. Esto ayudará a que la compra sea mucho más razonable y la sensación",
      title: `¿MI SEGURO PARA COCHE APLICA SI SE ROBAN EL AUTO?`,
      content: [
        {
          header: ``,
          body: `Contratar un seguro para coche es una decisión que se debe tomar con toda la información disponible. Esto ayudará a que la compra sea mucho más razonable y la sensación de tranquilidad tras contratar la póliza será mayor.
          En inter.mx somos un bróker de seguros que tiene como objetivo brindar siempre las mejores ofertas a nuestros clientes. Además de trabajar con las mejores compañías aseguradoras del mercado, enfocamos nuestros esfuerzos en dar certeza a las personas de que su compra cubre al 100% sus necesidades.`,
        },
        {
          header: `¿Cómo encontrar el mejor seguro para coche?`,
          body: `Para encontrar el mejor seguro para coche debes considerar 3 puntos clave: precio, cobertura y beneficios adicionales.
          En cuanto al precio, debes saber que este puede variar dependiendo del tipo de vehículo que tengas. También influyen aspectos demográficos como tu edad o nivel socioeconómico y el alcance de las coberturas que tú escojas.
          Eso sí, al contratar tu póliza con nosotros te ahorras por lo menos el 30%, ya que manejamos los mejores precios del mercado.
          Con respecto a las coberturas debes hacer un análisis muy detallado teniendo en cuenta que algunos planes pueden no cubrir todo lo que necesitas. Por ejemplo, en el caso en que te roben el auto, se cubrirá el valor comercial del vehículo y se aplicará un deducible del 10% si cuentas con las coberturas amplia y limitada de seguro para coche que ofrece inter.mx
          Las principales coberturas que debe tener un seguro son:`,
          list: [
            `Daños materiales al coche`,
            `Robo`,
            `Responsabilidad civil por daño a terceros`,
            `Gastos médicos para los acompañantes`,
            `Servicios de asistencia`,
            `Defensa legal`,
            `Responsabilidad civil si manejas otro auto`,
            `Daños a cristales`,
          ],
          footer: `Al contratar tu póliza de seguro para coche con inter.mx recibes todo esto ¡y mucho más! Quedas cubierto en caso de dañar a otros con adaptaciones de tu coche (racks de bicicletas o tumbaburros), al conducir un coche rentado, y por todos los gastos que podrían surgir en caso de un accidente.
          Además, por ser nuestro cliente te brindaremos asistencia para regresar a casa, ayuda en caso de que pierdas tu boleto de estacionamiento, reposición de llave, paramédico en moto y descuentos en servicios de limpieza vehicular.
          Conociendo todo esto queda más que claro cuál es el mejor seguro para coche. Contrátalo ya mismo a través de inter.mx y empieza a sentir la tranquilidad que solo nosotros te podemos ofrecer.
          No olvides seguirnos en nuestras redes de <>Instagram</> y <>Facebook</>.`,
        },
      ],
    },
    {
      img: BG3,
      header: "SEGURO PARA COCHE | PASO A PASO PARA CAMBIAR UNA LLANTA",
      date: "21 octubre, 2021",
      autor: "admininter",
      body: "Hay dos elementos claves a la hora de estar seguro al volante: contar con un buen seguro para coche y saber reaccionar de manera correcta ante cualquier situación inesperada.En inter.mx",
      title: `SEGURO PARA COCHE | PASO A PASO PARA CAMBIAR UNA LLANTA`,
      content: [
        {
          header: ``,
          body: `Hay dos elementos claves a la hora de estar seguro al volante: contar con un buen seguro para coche y saber reaccionar de manera correcta ante cualquier situación inesperada.
          En inter.mx sabemos muy bien que una de las peores situaciones para los conductores es que una llanta del auto se ponche. Esto puede suceder, por ejemplo, luego de pasar por un bache a alta velocidad o al encontrar un clavo malintencionado.
          Sigue leyendo, porque te traemos una guía rapidísima que te sacará de ese apuro.`,
        },
        {
          header: `Siéntete tranquilo con tu seguro para coche`,
          body: `Conducir por la calle o carretera con la tranquilidad que te ofrece un seguro para coche es una experiencia agradable, y más si vamos con la familia. Y todo bien, menos cuando una llanta se nos poncha.
          Es bastante común escuchar a la gente decir que cambiar una llanta es de lo más sencillo, pero la realidad es que cuando llega el momento incluso el más experto se pone nervioso.
          A continuación, te vamos a contar en 5 sencillos pasos para cambiar tu llanta de repuesto y seguir adelante con tu camino sin ninguna complicación.`,
        },
        {
          header: `Estaciona tu coche con cuidado`,
          body: `Este es el primer y más importante paso a seguir. Asegúrate de estacionarte en un lugar donde tengas suficiente espacio para hacer el cambio de la llanta.
          Se recomienda que el coche quede en una superficie plana, ya que el terreno inclinado podría dificultar todo el proceso.
          ¡Ojo! No olvides poner el freno de mano.`,
        },
        {
          header: `Prepara el equipo`,
          body: `Con el auto estacionado en un lugar seguro, puedes prepararte para cambiar la llanta. Busca la llave de cruz, el gato hidráulico y revisa que la llanta de repuesto esté en buen estado.
          Si las llantas de tu coche tienen algún tipo de birlo de seguridad asegúrate de tener la pieza que necesitas para sacarlos.`,
        },
        {
          header: `Afloja los birlos y levanta el auto.`,
          body: `En esta parte debes tener mucho cuidado. No se trata de sacar los birlos por completo, solo debes aflojarlos para que cuando el carro esté en el aire no tengas que hacer más fuerza de la necesaria.
          Para levantar el auto debes colocar el gato hidráulico cerca de la llanta ponchada. Pero ten en cuenta, que el gato haga fuerza sobre el chasis y no la carrocería.
          Tranquilo, te lo explicamos. La carrocería es la cobertura del carro, el chasis es la estructura sobre la cual está ensamblado todo el vehículo.`,
        },
        {
          header: `Fuera birlos, hola repuesto`,
          body: `Con el carro elevado ya puedes terminar de retirar los birlos, sacar la llanta ponchada y colocar la de repuesto.
          En este paso funciona la misma lógica que en el paso anterior. No aprietes por completo los birlos para que no apliques ninguna fuerza innecesaria sobre el auto desestabilizado.`,
        },
        {
          header: `Vámonos`,
          body: `Baja el auto con el gato hidráulico y ahora sí ajusta con fuerza todos los birlos. Asegúrate de guardar todo en su lugar y poner la llanta ponchada en el maletero.
          ¡Ya está todo listo! Solo recuerda que la llanta de repuesto está diseñada para ser utilizada durante periodos cortos y a baja velocidad, así que debes ir tan pronto como puedas a una vulcanizadora o a comprar una llanta nueva.
          Recuerda que, si por alguna razón no pudiste hacer el cambio, al contratar tu seguro para coche con inter.mx tienes disponible 24/7 servicio de asistencia en caso de que requieras una ayuda.
          Lo único que debes hacer es contactarte con uno de nuestros asesores al (teléfono) o en nuestro sitio inter.mx.
          Además, está guía siempre estará disponible en nuestro <>blog</> para que la puedas revisar cuando la necesites.
          En nuestra sitio web encontrarás gran variedad de consejos para que estés seguro al volante y le saques el máximo provecho a tu seguro para coche. ¡Visítanos!
          No olvides seguirnos en nuestras redes de <>Instagram</> y <>Facebook</>.`,
        },
      ],
    },
    {
      img: BG4,
      date: "21 octubre, 2021",
      autor: "admininter",
      title: "¿QUÉ ES EL DEDUCIBLE EN MI SEGURO DE GASTOS MÉDICOS MAYORES?",
      body: "Al contratar un seguro de gastos médicos mayores esperamos tener la certeza de contar con un respaldo económico que nos brinde tranquilidad y seguridad tanto a nosotros como a nuestros",
      content: [
        {
          header: ``,
          body: `Al contratar un seguro de gastos médicos mayores esperamos tener la certeza de contar con un respaldo económico que nos brinde tranquilidad y seguridad tanto a nosotros como a nuestros seres queridos.
          Todas las pólizas funcionan así. Esto no quiere decir que podamos andar por ahí viviendo la vida sin ningún tipo de precaución. Para que no sea así, se incluye dentro del contrato un término llamado “deducible” que garantiza que la póliza será un negocio rentable tanto para el cliente como para la aseguradora.`,
        },
        {
          header: `¿Cuál es el deducible de un Seguro de Gastos Médicos Mayores?`,
          body: `El deducible en el seguro de gastos médicos mayores hace referencia a una suma de dinero que correrá por cuenta del asegurado en caso de que ocurra un siniestro.
          Este valor por lo general es muy bajo en comparación con el total de la suma asegurada que ofrece el seguro. Sin embargo, se incluye en la póliza, ya que es una forma sumamente efectiva de que el asegurado actúe de manera responsable y evite al máximo poner su integridad y su vida en peligro.
          Con el deducible todos ganan. El asegurado reduce la posibilidad de sufrir algún percance y el contrato se vuelve rentable para la aseguradora.
          Si contratas tu seguro de gastos médicos mayores con inter.mx puedes elegir entre una gran variedad de planes con sumas aseguradas que van desde los $6,000,000 hasta los $100,000,000, mientras que para el deducible solo aplicarán los valores de $19,000 o $30,000 dependiendo de la cobertura contratada.
          
          Te puede interesar: Todo lo que debes saber del deducible de tu seguro de auto.
          Recuerda que con cualquiera de las pólizas que ofrecemos tendrás cobertura en:`,
          list: [
            `Gastos hospitalarios`,
            `Honorarios médicos`,
            `Auxiliares de diagnóstico`,
            `Medicamentos`,
            `Ambulancia`,
            `Asistencia telefónica`,
            `Asistencia en viaje`,
            `Prótesis`,
            `Rehabilitaciones`,
          ],
          footer: `No esperes más y contrata un seguro de gastos médicos mayores para garantizar tu tranquilidad y la de tus seres queridos. En inter.mx encontrarás la cobertura que necesitas al mejor precio del mercado, y lo mejor, es que la podrás contratar desde la comodidad de tu casa.
          Si quieres más información sobre cómo contratar tu póliza visita inter.mx o llámanos al teléfono 55 4424 6837 si tienes alguna duda sobre los detalles de la póliza.
          No olvides seguirnos en nuestras redes de <>Instagram</> y <>Facebook</>.`,
        },
      ],
    },
    {
      img: BG5,
      date: "21 octubre, 2021",
      autor: "admininter",
      title: "GUÍA RÁPIDA PARA ELEGIR UN SEGURO DE GASTOS MÉDICOS MAYORES",
      body: "Algo que entendemos de quienes buscan un seguro de gastos médicos mayores en México es que muchas veces se encuentran desinformados sobre el alcance de las coberturas a las que",
      content: [
        {
          header: ``,
          body: `Algo que entendemos de quienes buscan un seguro de gastos médicos mayores en México es que muchas veces se encuentran desinformados sobre el alcance de las coberturas a las que pueden acceder.
          Uno de los compromisos de inter.mx es fomentar la claridad en la contratación de un seguro. El objetivo es que nuestros clientes siempre queden satisfechos con las pólizas que contratan mediante nuestra asesoría y servicios.
          Siguiendo esta línea, este artículo resaltará algunas de las cuestiones más importantes que hay que tener en cuenta al contratar un seguro médico. Y también otras cuestiones personales, no menos importantes, que hacen al cuidado de las finanzas personales de nuestros clientes.`,
        },
        {
          header: `¿Incluyes en tus finanzas un presupuesto para tu seguro de gastos médicos mayores?`,
          body: `Como medida inicial, es necesario prestar atención a la disponibilidad de gasto con la que se cuenta para destinar a un seguro de gastos médicos mayores. ¿Sabes cuánto puedes destinar al mes para el pago de tu seguro?
          Para calcular el presupuesto, también debes saber que algunas aseguradoras permiten pagar sus coberturas mensualmente, bimestralmente, semestralmente o anualmente.
          Coteja esta información con otros gastos personales para saber qué montos puedes destinar a la contratación de un seguro y elegir tus opciones con base en esa información.`,
        },
        {
          header: `Tiempos de espera`,
          body: `Todas las pólizas tienen un tiempo de espera a partir del cual la indemnización de un siniestro se puede hacer válida. Aquí es importante tener en cuenta una cuestión y es que las aseguradoras ofrecen distintos tiempos de espera según los padecimientos a cubrir.
          Debes saber que todas las empresas que ofrecen un seguro de gastos médicos mayores en México tienen tiempos de espera similares. Lo mejor es buscar los períodos de espera en enfermedades puntuales y evaluar las distintas alternativas. Por ejemplo, el período de espera para un padecimiento por cáncer es generalmente de 1 año.`,
        },
        {
          header: `Hospitales cubiertos y tabulador médico`,
          body: `¿De qué sirve tener un seguro para tu salud si los centros médicos contemplados en la póliza están muy lejos de tu casa? Es fundamental revisar la cercanía de los hospitales disponibles y verificar si cumplen con tus niveles de exigencia.
          Respecto a la calidad del personal médico -en este caso la medimos según sus honorarios- debes tener en cuenta que cada aseguradora tiene su propio tabulador médico.
          Es decir, una lista de costos derivados a procedimientos médicos, que tiene distintos niveles según lo que necesite y esté dispuesto a pagar el contratante.`,
        },
        {
          header: `Deducible y coaseguro`,
          body: `El deducible y el coaseguro son puntos básicos que debes tener en cuenta al contratar un seguro de gastos médicos mayores. Lo mejor es que el deducible sea alto, pero no muy elevado para que no represente un riesgo financiero.
          Procura que el coaseguro no sea ilimitado, y que tenga valores coherentes con los costos de las enfermedades que cubre. También es importante verificar que tanto deducible como coaseguro sean por toda la extensión del padecimiento y no anuales. Esto te salvará en caso de padecer una enfermedad crónica o que se extienda por mucho tiempo.`,
        },
        {
          header: `Calificaciones de las aseguradoras`,
          body: `Al momento de evaluar los productos de distintas aseguradoras, puedes ver la calificación de cada una de ellas que hace la CONDUSEF. Ahí encontrarás cuadros comparativos con más de 70 entidades de México, sus productos, eficiencia en el servicio y valoración.
          Como ves, en inter.mx conocemos a fondo todos los detalles de un seguro de gastos médicos mayores.
          Si te interesa informarte más o avanzar con la contratación de un seguro, llámanos al 55 4424 6837 o escríbenos a contacto@inter.mx.
          Somos el bróker de seguros número 1 en México.
          ¡Ahora también puedes encontrar la app de inter.mx en Apple Apple App Store y Google Play!`,
        },
      ],
    },
    {
      img: BG6,
      title: "ESTE DÍA DE MUERTOS, CUENTA CON UN SEGURO DE GASTOS MÉDICOS",
      date: "21 octubre, 2021",
      autor: "admininter",
      body: "Estamos en octubre y ya empezó la temporada más festiva para todos los mexicanos. Cada día que pasa nos acercamos más al Día de Muertos, una fecha en que muchas",
      content: [
        {
          header: ``,
          body: `Estamos en octubre y ya empezó la temporada más festiva para todos los mexicanos. Cada día que pasa nos acercamos más al Día de Muertos, una fecha en que muchas personas salen a la calle a celebrar. Y es en esta época del año que es oportuno contar con un seguro de gastos médicos.
          Tanto en esta como en otras celebraciones, la gente busca relajarse y salir a divertirse. Esto tiene como contracara que muchas personas andan más distraídas de lo habitual. Lo mejor en este sentido y para evitar sorpresas es mejor estar protegido.`,
        },
        {
          header: `¿Por qué contar con un seguro de gastos médicos para este Día de Muertos?`,
          body: `Un estudio llevado a cabo por el INEGI estimó que en 2018, de todos los accidentes que tuvieron lugar en el Día de Muertos, el 46,4% fueron de tránsito. En total se registraron 16.035 muertes por estas causas, significando un 2,2% del total de todos los accidentes de tránsito a lo largo del año.
          Además, de todas esas defunciones en accidentes de tránsito, el 23,4% correspondió a peatones, quienes son más vulnerables en este tipo de situaciones.
          Esto significa que estamos en una época en la que se concentran más situaciones de peligro para las personas que se encuentran en la calle celebrando. ¿De repente contar con un seguro de gastos médicos para estas fechas suena sensato, verdad?
          Lo que promovemos en inter.mx es que las personas puedan contar con un salvavidas financiero en caso de sufrir algún tipo de accidente en estas temporadas festivas.`,
        },
        {
          header: `En estas celebraciones, cuida tu seguridad financiera y el bienestar de tu familia`,
          body: `Sabemos que hay muchas ganas de salir a celebrar el Día de Muertos. Hagámoslo con cuidado y evitando riesgos innecesarios. Que estas celebraciones te encuentren disfrutando de tu familia y amigos, sin preocupaciones financieras.
          Protégete contra cualquier eventualidad médica con un seguro de gastos médicos.
          Además, en la actualidad existen diferentes coberturas para complementar la seguridad financiera de tu familia, como nuestro plan Vida Segura.
          Cotiza un seguro de vida desde $53 MXN mensuales mensuales con inter.mx.
          Somos tu aliado para que encuentres la cobertura indicada. Ponte en contacto a través de nuestro sitio, por WhatsApp o escribiendo a contacto@inter.mx.
          Ahora también puedes descargar nuestra app en App Store y Google Play.`,
        },
      ],
    },
    {
      img: BG7,
      title: "Seguro de Coche: ¿Qué debo revisar antes de Salir de Viaje?",
      date: "21 octubre, 2021",
      autor: "admininter",
      body: "Antes de comenzar tu viaje en carretera, ya sea con tu familia o amigos, es importante que revises algunas cosas sobre tu auto, desde que está funcionando al 100 hasta",
      content: [
        {
          header: ``,
          body: `Antes de comenzar tu viaje en carretera, ya sea con tu familia o amigos, es importante que revises algunas cosas sobre tu auto, desde que está funcionando al 100 hasta que tu seguro de coche está vigente y en orden.
        Según el Instituto Nacional de Salud Pública, México ocupa el séptimo lugar de países con más siniestros viales, que se dan por diferentes causas, tanto factores de infraestructura vial, como fallas técnicas de los coches que transitan por las carreteras y ciudades del país.
        Por esta razón, revisar tu coche y asegurarte de que cumples con todas las condiciones antes de salir de viaje, puede ser una excelente práctica para cuidarte y cuidar a aquellos que te acompañarán.
        Pero, ¿cómo hacerlo? ¡Nosotros te contamos!`,
        },
        {
          header: `Aspectos mecánicos`,
          body: `Esta revisión recoge gran parte de lo que debes verificar antes de salir, ya que incluye desde el estado del motor de tu vehículo hasta el estado de las llantas y luces.
        Debes comprobar que todos los sistemas integrados en tu coche estén funcionando adecuadamente y que tu coche no tenga ningún testigo o alarma encendida en el tablero.
        Algunos puntos que siempre debes revisar son:`,
          list: [
            `Estado de la batería`,
            `Frenos`,
            `Nivel de líquidos`,
            `Estado de las llantas`,
            `Amortiguadores y suspensión`,
            `Luces`,
            `Limpiaparabrisas`,
          ],
        },
        {
          header: `Kit de emergencia`,
          body: `Es importante que te asegures de cargar siempre un kit de emergencia. Recuerda que tu coche puede presentar fallas en cualquier momento, aún cuando hayas revisado que todo esté en perfecto estado.
        Un kit de emergencia te ayudará a manejar cualquier situación que se presente en carretera. Al menos mientras contactas a la asistencia de tu seguro de coche, o mientras llegas a un lugar donde puedan auxiliarte.
        Los elementos básicos que todo buen kit de emergencia debe tener, son:`,
          list: [
            `Caja básica de herramientas`,
            `Elementos para cambio de refacciones`,
            `Agua`,
            `Refrigerante o anticongelante`,
            `Linterna`,
            `Triángulo de advertencia o conos inflables`,
          ],
          footer: `Utilices o no estos elementos, lo mejor siempre será estar bien equipados para atender cualquier inconveniente que se pueda presentar durante el viaje.`,
        },
        {
          header: `Seguro de Coche vigente`,
          body: `Nunca olvides revisar que tu licencia de conducir, tarjeta de circulación y seguro de tu coche estén vigentes y al día. Esto no solo es necesario para que puedas circular tranquilamente, sino que te será de gran ayuda cuando necesites un respaldo legal y seguro ante cualquier siniestro.
        Si aún no estás asegurado, recuerda que siempre podrás cotizar y contratar tu seguro de coche rápido, fácil y a la medida de tus necesidades con inter.mx.
        Ingresa y conoce todas las opciones y coberturas que tenemos para que estés siempre seguro.
        Ahora que sabes qué debes revisar y cómo debes preparar tu auto, ¿Estás listo para hacer maletas? Anímate a conocer nuevos lugares siempre con el respaldo de inter.mx.
        Y no olvides seguirnos en nuestras redes de <>Instagram</> y <>Facebook</> para mantenerte al tanto de todos nuestros servicios y promociones.`,
        },
      ],
    },
    {
      img: BG8,
      title: "5 COSAS QUE CUBRE TU SEGURO PARA COCHE Y NO SABÍAS",
      date: "21 octubre, 2021",
      autor: "admininter",
      body: "Sabemos que con tu seguro para coche tienes la certeza de estar preparado para mantener tu seguridad y la de tus seres queridos cuando vas en tu auto. Pues… ¡Todavía",
      content: [
        {
          header: ``,
          body: `Sabemos que con tu seguro para coche tienes la certeza de estar preparado para mantener tu seguridad y la de tus seres queridos cuando vas en tu auto. Pues… ¡Todavía te tenemos mejores noticias!
          Sigue leyendo porque te contaremos 5 beneficios a los que puedes acceder sin ningún costo adicional solo por contratar tu seguro con inter.mx.`,
        },
        {
          header: `No es solo un seguro para coche`,
          body: `Quienes contratan una póliza de este tipo suelen pensar que solo trae beneficios para cuando pasa algún accidente. Por suerte, la realidad no podría estar más alejada de esto.`,
        },
        {
          header: `Responsabilidad civil en exceso`,
          body: `En primer lugar, contarás con cobertura de responsabilidad civil en exceso. Esto quiere decir, que estarás amparado por cualquier daño que puedas llegar a causar con adaptaciones de tu coche (rack de bicicletas, tumbamuros, etc.), si conduces un vehículo distinto al asegurado o cualquier gasto que pueda surgir tras un accidente.
          Te puede interesar: ¿Qué hacer si choco con un Conductor Sin Seguro?
          ¡Pero eso no es todo!`,
        },
        {
          header: `Asistencia para regresar a casa`,
          body: `Si no estás en condiciones de manejar tu auto, contamos con servicio de asistencia para llevarte a casa de manera segura. Recuerda que, si estás bajo los efectos del alcohol no debes conducir. Para eso, nosotros nos encargamos de llevarte sano y salvo hasta tu casa.`,
        },
        {
          header: `Pérdida de boleto de estacionamiento`,
          body: `Asimismo, te ayudaremos en caso de que pierdas tu boleto de estacionamiento o la llave de tu coche. Y puede que pienses que eso nunca va a pasarte, pero cuando sea que lo necesites, lo único que tienes que hacer es llamarnos al 55 4424 6837.`,
        },
        {
          header: `Asesoría para trámites`,
          body: `El cuarto beneficio que probablemente no conocías es que te asesoraremos siempre que lo necesites. Cuando requieras hacer un trámite legal de tu vehículo cuentas con nuestro apoyo.
          Si vas a vender tu auto o hacer un traspaso de dueño, nosotros te daremos el apoyo necesario.`,
        },
        {
          header: `Paramédico en moto`,
          body: `Finalmente, al contratar tu seguro para coche con nosotros tendrás disponible el servicio de paramédico en moto. Así siempre que lo necesites tendrás asistencia médica en minutos.
          Es cierto que dijimos que solo eran 5, pero como estamos tan acostumbrados a dar de más te vamos a contar sobre un último beneficio.
          Gracias a tu seguro gozarás de grandes descuentos en la limpieza de tu vehículo para que, además de manejarlo con tranquilidad, sea todo un placer estar frente al volante.
          Entra ahora mismo a inter.mx y conoce cómo puedes contratar tu seguro para coche y todos sus beneficios desde la comodidad de tu casa.
          No olvides seguirnos en nuestras redes de <>Instagram</> y <>Facebook</>.`,
        },
      ],
    },
    {
      img: "",
      title: "Seguro de Gastos Médicos para adulto mayor ¿Cómo adquirirlo?",
      date: "15 septiembre, 2021",
      autor: "admininter",
      body: "En los últimos tiempos, las aseguradoras del sector salud han intentado brindar mejores soluciones a los adultos mayores a través de la oferta del seguro de gastos médicos. La emergencia",
      content: [
        {
          header: ``,
          body: `En los últimos tiempos, las aseguradoras del sector salud han intentado brindar mejores soluciones a los adultos mayores a través de la oferta del seguro de gastos médicos. La emergencia sanitaria ha hecho que cada vez más personas alrededor de sus 70 años, y mayores a 80, consulten sobre una póliza de seguro para su salud.
        En 2021 hay cerca de 15 millones de personas de más de 60 años viviendo en México, según datos difundidos por la Secretaría de la Gobernación. Y se estima que en 2024 serán más de 16 millones.`,
        },
        {
          header: `¿Un Seguro de Gastos Médicos me cubre hasta los 70 años, o más?`,
          body: `La mayoría de las coberturas que se ofrecen para adultos mayores apuntan a las personas de entre 60 y 70 años. Pero también hay pólizas para personas de más de 70 años que incluso cubren otras necesidades más acordes a su edad.
        Un seguro de gastos médicos para adultos mayores puede significar un ahorro para cubrir los siguientes servicios:`,
          list: [
            `Honorarios médicos y de enfermeros`,
            `Gastos de hospitalización`,
            `Cuidados en casa`,
            `Medicamentos`,
            `Tratamientos de rehabilitación`,
            `Prótesis`,
            `Tratamientos de radioterapia y quimioterapia`,
            `Cirugía reconstructiva`,
            `Consultas médicas a domicilio`,
            `Asistencia en casa`,
            `Apoyo psicológico`,
            `Pruebas de laboratorio a domicilio`,
            `¡Y más!`,
          ],
        },
        {
          header: `Conoce los planes de Inter para adquirir un Seguro de Gastos Médicos`,
          body: `Como bróker de seguros con más de 40 años de experiencia en administración y consultoría integral de riesgos, en inter.mx tomamos nota de las necesidades que se presentan. Por eso les ofrecemos a los adultos mayores un seguimiento especializado y la posibilidad de conocer todas las coberturas médicas a las que pueden acceder.
        En inter.mx podemos asesorarte para que encuentres el seguro de gastos médicos que más se adecúe a tus necesidades y a tus posibilidades. Tenemos distintos planes para adultos mayores según la cobertura de riesgos que contemplan.
        Nuestros paquetes van desde un nivel básico, con $6,000,000 de suma asegurada, $19,000 de deducible, coaseguro del 10 % topado a $42,000 y red hospitalaria esencial, a una cobertura de $100,000,000 de suma asegurada, $30,000 de deducible, coaseguro del 10 % topado a $60,000 y red hospitalaria amplia.
        Contáctanos en inter.mx para más información con respecto a los planes de salud a los que puedes acceder. ¡Síguenos en redes! <>Instagram</> – <>Facebook</>.`,
        },
      ],
    },
    {
      img: "",
      date: "15 septiembre, 2021",
      autor: "admininter",
      title: "VIAJA TRANQUILO CON UN SEGURO DE GASTOS MÉDICOS MAYORES",
      body: "Conforme avanza la tecnología las fronteras se flexibilizan y el turismo se posiciona cada vez más como una actividad accesible para todos. Por esta razón, hoy en día es indispensable contar con un seguro de gastos médicos mayores que te cuide donde sea que estés.",
      content: [
        {
          header: `Nos vamos de viaje ¡Por fin!`,
          body: `Ahora que la vacunación está avanzando a pasos agigantados en todas las latitudes del mundo, el turismo está recuperando fuerza y poco a poco nos vamos olvidando de los momentos más oscuros de la pandemia generada por la COVID-19.
          Sin embargo, la amenaza sigue presente y si te vas a ir de viaje debes estar protegerte a ti y a toda tu familia. ¿Cómo? ¡Con inter.mx es muy fácil!
          Al contratar tu seguro de gastos médicos mayores en inter.mx tendrás cobertura en cualquier lugar del mundo en caso de que te ocurra una emergencia sin necesidad de aplicar deducible ni coaseguro.
          En el exterior te respaldamos con:`,
          list: [
            `Asistencia médica por enfermedad o accidente`,
            `Asistencia dental de emergencia`,
            `Desplazamiento por interrupción del viaje`,
          ],
          footer: `Además, te brindaremos apoyo en caso de que pierdas tu equipaje, documentos importantes o necesites enviar un mensaje de urgencia a alguien en México.
          Te puede interesar: ¿Qué es una Póliza de Seguros?`,
        },
        {
          header: `Seguro de Gastos Médicos Mayores, el aliado ideal`,
          body: `Con tu seguro de gastos médicos mayores no solo tienes asistencia en caso de encontrarte en el exterior, sino también tienes grandes beneficios permanentes como cobertura de los gastos de hospitalización, atención médica, intervenciones quirúrgicas, medicamentos y análisis clínicos que surjan a partir de alguna enfermedad o accidente.
          Asimismo, cuentas con asistencia telefónica 24/7 en caso de que ocurra un siniestro sin importar la hora que sea.
          Si estás dentro del país, podrás recibir atención en los 32 estados de la República Mexicana a través de nuestra red hospitalaria que cuenta con más de 250 instituciones médicas.
          ¡No esperes más! Visita INTER.MX y encuentra el seguro de gastos médicos mayores que te ofrece mayor cobertura al mejor precio.
          Recuerda que desde nuestro sitio web puedes realizar todo el proceso en cuestión de minutos tendrás tu póliza.
          ¡Empieza a protegerte a ti y tu familia en menos de 5 minutos!
          No olvides seguirnos en nuestras redes de <>Instagram</> y <>Facebook</>.`,
        },
      ],
    },
    {
      img: "",
      date: "15 septiembre, 2021",
      autor: "admininter",
      title: "¿REGRESAS A LA OFICINA? ESCOGE UN BUEN SEGURO PARA COCHE",
      body: "Estar cubierto es la mejor manera de volver a la oficina. A continuación te dejamos algunos consejos para ayudarte a elegir el seguro de coche que mejor se adapte a",
      content: [
        {
          header: ``,
          body: `Estar cubierto es la mejor manera de volver a la oficina. A continuación te dejamos algunos consejos para ayudarte a elegir el seguro de coche que mejor se adapte a tus necesidades.`,
        },
        {
          header: `Llegó el momento de volver a la oficina`,
          body: `Sabemos que volver al trabajo luego de los acontecimientos del último año puede ser muy estresante y hasta causarte algo de ansiedad. Los empleados cuentan con sus compañías para garantizar que el regreso al trabajo sea de forma segura y nosotros queremos ayudarte a que el viaje también sea seguro, asesorándote para elegir el mejor seguro de coche.
          Tu lista de quehaceres antes de volver a la oficina debe ser larga y tediosa, pero no desesperes, elegir el seguro para tu coche no tiene por qué serlo. Estos son algunos tips para hacerlo fácil, rápido y seguro.`,
        },
        {
          header: `¿Es necesario que asegure mi coche?`,
          body: `¡Sí! No importa a quién se lo preguntes ni cuantas veces, la respuesta siempre va a ser: SÍ. Pongamos un ejemplo, si en tu viaje de regreso al trabajo tuvieras una distracción ocasionando un choque, las consecuencias serían muy serias y generarían un fuerte impacto a tu billetera ya que deberías cubrir todos los gastos. Contratar un seguro para coche es más asequible y puede ahorrarte muchos dolores de cabeza.
          Ahora que sabes de la importancia de proteger tanto tu integridad como tu patrimonio, te dejamos una lista de las cosas que debes evaluar antes de contratar un seguro de coche determinado.
          
          Estos son los tipos de cobertura que se encuentran comúnmente en el mercado:`,
          list: [
            `Asistencia legal: ofrece asesoría para sus clientes en caso de estar involucrados en un accidente.`,
            `Daños materiales: para la aseguradora, los daños materiales cubiertos son los ocasionados por colisiones y vuelcos, como rotura de cualquier cristal.`,
            `Gastos médicos: cubre la atención inmediata para el conductor y los pasajeros del coche asegurado que se vea involucrado en un accidente.`,
            `Robo total del automóvil: cuando el coche es robado, el dueño debe llamar a la aseguradora y proporcionar todos sus datos. Algunas coberturas incluyen un sistema de localización para que la compañía de seguros pueda buscar la unidad robada.`,
            `Responsabilidad civil (también conocido como daños a terceros): cubre los daños que se ocasionan a las personas o bienes involucrados en un accidente vial.`,
          ],
          footer: `Teniendo estos ítems en cuenta puedes pasar a cotejar los precios de varias aseguradoras y decidir cuál se adapta más a tu presupuesto. Recuerda que a la hora de elegir el mejor seguro para coche tener en cuenta la opinión de otros clientes y conocer la aseguradora puede ser clave para tomar una decisión.
          En inter.mx somos expertos en cuidarte a la hora de salir a la calle y darte respaldo cuando lo necesites. Tenemos una variedad de planes para ofrecerte con características particulares, pensadas para cada tipo de rutina y forma de vivir.
          ¡Contáctanos para un asesoramiento personalizado!
          También puedes seguirnos en <>Instagram</> y <>Facebook</> para informarte de todas las novedades.`,
        },
      ],
    },
    {
      img: "",
      date: "15 septiembre, 2021",
      autor: "admininter",
      title: "SEGURO DE GASTOS MÉDICOS | ¿QUÉ PASA CUANDO CUMPLO 25 AÑOS?",
      body: "En ocasiones, la vida adulta puede llegar a ser tan complicada como te cuentan. Y es que a medida que vas creciendo y van pasando cumpleaños y cumpleaños, te das",
      content: [
        {
          header: ``,
          body: `En ocasiones, la vida adulta puede llegar a ser tan complicada como te cuentan. Y es que a medida que vas creciendo y van pasando cumpleaños y cumpleaños, te das cuenta que debes comenzar a asumir ciertas responsabilidades fundamentales. Una de ellas la afrontas cuando debes asumir el coste de tu seguro de gastos médicos, ya que al cumplir tus 25 años automáticamente dejas de ser beneficiario de tus padres.
          ¿No lo sabías? Te contamos brevemente cómo funciona este modelo.
          Según las disposiciones del Gobierno Mexicano, todos los ciudadanos mayores de 18 años dejan de ser beneficiarios de sus padres en lo que respecta al IMSS y el ISSSTE.
          No obstante, todas las familias pueden solicitar una prórroga de esta disposición, si el ciudadano (que ya es mayor de edad) se encuentra en dos situaciones particularmente: es estudiante o tiene alguna condición física o discapacidad que dificulta su total independencia.
          Para hacer esta solicitud, los padres deben presentar:`,
          list: [
            `Identificación oficial vigente (Credencial para votar INE, Pasaporte o Cédula Profesional)`,
            `Constancia de estudios expedida por un plantel oficial`,
            `Certificado médico expedido por área médica del ISSSTE`,
          ],
          footer: `Sin embargo, y tal vez el detalle que no puedes olvidar, es que esta prórroga tendrá vigencia hasta que el beneficiario cumpla los 25 años de edad. Ya que en este momento se le dará de baja automáticamente cuando el sistema actualice los datos de su afiliación.
          En este sentido, es importante que poco tiempo antes de cumplir los 25 años busques la opción de adquirir un seguro de gastos médicos que cubra todos los servicios de salud que necesitas en ese momento o, si por casualidad ya tienes uno, averigua y cotiza cómo puedes mejorar las condiciones del mismo.`,
        },
        {
          header: `¿Dónde encontrar el Seguro de Gastos Médicos ideal?`,
          body: `El mercado de seguros hoy en día ofrece cientos de opciones con distintos beneficios y coberturas. Pero como en inter.mx sabemos que cada quien tiene sus propias necesidades, te ofrecemos la oportunidad de encontrar el seguro ideal para ti y tu familia.
          Usando nuestro servicio de cotización de seguro de gastos médicos, encontrarás un seguro que no solo garantiza la protección y la atención adecuada para tu familia, sino que también se ajusta al presupuesto que tienes contemplado. Solo debes seleccionar la opción que más se ajusta a lo que estás buscando, y así, con un par de clics, tendrás en tu pantalla el seguro de gastos médicos perfecto para ti.
          Pero antes, hay algunos detalles que no puedes olvidar:`,
          list: [
            `Revisa siempre el tope de los gastos hospitalarios.`,
            `Dale una mirada con lupa a los hospitales y centros de atención.`,
            `Lee detalladamente las ⦁ coberturas extras: medicamentos, asistencias telefónicas o domiciliarias, traslados, asistencia de viaje, entre otras.`,
            `Confirma a quiénes puedes incluir en tu póliza.`,
            `Ten presente el periodo de espera y las cláusulas de tu contrato.`,
          ],
          footer: `¿No sabes cómo abordar toda esta información? ¡Nosotros te asesoramos! Recuerda que inter.mx es el bróker de seguros más completo del mercado donde encontrarás las mejores empresas aseguradoras. Algunas de ellas, son:`,
        },
        {
          header: ``,
          body: ``,
          list: [
            `Quálitas`,
            `Seguros Afirme`,
            `MAPFRE Seguros`,
            `Primero Seguros`,
            `CHUBB`,
            `HDI Seguros`,
            `GNP Seguros`,
          ],
          footer: `¿Quieres conocer cuál es la mejor opción para ti y tu familia? Habla con nuestros agentes! Con ellos podrás cotizar y contratar el seguro que más se ajusta a tus necesidades.
          No olvides seguirnos en nuestras redes de <>Instagram</> y <>Facebook</>.`,
        },
      ],
    },
    {
      img: "",
      date: "15 septiembre, 2021",
      autor: "admininter",
      title: "ESTE MES CELEBRA TU INDEPENDENCIA CON UN SEGURO PARA COCHES",
      body: "Sabemos que la decisión de independizarte de tus padres llegó junto con un montón de responsabilidades y cosas por resolver. Y es que los procesos de independencia siempre son así,",
      content: [
        {
          header: ``,
          body: `Sabemos que la decisión de independizarte de tus padres llegó junto con un montón de responsabilidades y cosas por resolver. Y es que los procesos de independencia siempre son así, ¿O acaso no recuerdas la historia de la Independencia de México hace más de 200 años?. También sabemos que cuando eres un joven independiente parece que el universo conspira en tu contra: el dinero no alcanza, la alacena siempre está vacía y llegar a fin de mes es el verdadero “Grito de Dolores”, y de repente también tienes que sacar un extra destinado al seguro para coches.
          Y es que cuando te independizas, hay algunos gastos que no pueden esperar, como por ejemplo: la renta, el pago de servicios públicos, tu seguro médico, los gastos de tu mascota, comida, actividades recreativas, entre otros. Y a medida que vas adquiriendo tus cosas, van resultando nuevos gastos que no puedes dejar pasar.
          ¿Por qué? La respuesta es simple: porque estos gastos (o más bien inversiones) son fundamentales para que continúes tu camino hacia el futuro, porque si tu no lo haces ¿Quién más? Por eso, hoy, en conmemoración del 211 Aniversario del inicio de la Independencia de México, queremos contarte cuáles inversiones son importantes, para que logres independizarte y no morir en el intento.`,
        },
        {
          header: `Una vez dado el grito ¡Está prohibido retroceder!`,
          body: `Lo primero que debes saber es que, justo como pasó en 1810, todo, absolutamente todo, es un proceso. Por ende, es importante que te tomes tu tiempo y vayas poco a poco organizando y adquiriendo las cosas que la vida -y tu bolsillo- te van permitiendo. No siempre ser independiente es sinónimo de comprar y gastar sin medida. Al contrario, es el momento cuando debes planificar y estructurar tus gastos y planes a futuro.
          Luego, debes tener claro que es imposible dar un paso tan grande sin tener algunas cosas aseguradas (y si no nos crees, pregúntaselo a Don Miguel Hidalgo y Costilla). Por ejemplo, si te sumas a la aventura de comprar una casa o un coche no dudes, ni por un segundo, en adquirir un seguro para coches o para tu hogar. Así proteges tu bienestar y aseguras tus inversiones.
          Recuerda que las batallas ya no se libran a espadazos y sobre un caballo, y que los tratos de palabra y los bienes comunitarios ya son cosa del pasado. En una era donde cada quien lucha por lo suyo, es mejor estar asegurado para así continuar el camino hacia la victoria y evitar cualquier tropiezo.`,
        },
        {
          header: `Mejor firma tu Acta de Independencia`,
          body: `Sabemos que la gran pesadilla de todo joven independiente es tener que pedir la ayuda de sus padres. ¡Es imposible aceptar esa derrota!
          Por eso, si ya comenzaste a comprar tus propias cosas, es importante que mantengas todos tus papeles en regla y tus bienes siempre asegurados. No olvides que cualquier gasto imprevisto puede ser una gran piedra en el camino y por eso es mejor tener el respaldo de una compañía profesional que se encargue de cualquier inconveniente, por si metes la pata.
          Recuerda siempre la enseñanza que dejó José María Morelos y Pavón: que la vida y las batallas se ganan con alianzas, que la independencia se logra, pero si es en compañía y que un consejo experto nunca está de más.`,
        },
        {
          header: `¿Dónde encontrar el aliado perfecto?`,
          body: `Es simple: con inter.mx tendrás todo el respaldo que necesitas, no solo con tu seguro para coches, sino con tu seguro médico, el seguro de tu hogar (no importa si aún estás en renta) o el seguro que garantiza el bienestar de tu amigo peludo.
          Ingresa a nuestro sitio y encuentra, cotiza y contrata el seguro que más se ajusta a tus necesidades y presupuesto (no olvides que siempre llega fin de mes).
          Y no olvides seguirnos en nuestras redes de <>Instagram</> y <>Facebook</> para mantenerte al tanto de todos nuestros servicios y promociones.`,
        },
      ],
    },
    {
      img: "",
      date: "15 septiembre, 2021",
      autor: "admininter",
      title: "SEGURO PARA COCHE | ¿CÓMO ASEGURAR UN AUTO CLÁSICO?",
      body: "Un auto clásico no está exento de necesitar un seguro para coche. Aunque lo tengas en el garage y no lo uses, o lo uses poco, este increíble elemento de",
      content: [
        {
          header: ``,
          body: `Un auto clásico no está exento de necesitar un seguro para coche. Aunque lo tengas en el garage y no lo uses, o lo uses poco, este increíble elemento de colección merece tener su protección asegurada, ¡más que cualquier otro tipo de coche!
          A continuación, te contamos todo lo que debes saber a la hora de asegurar tu vehículo mimado.`,
        },
        {
          header: `¿Qué es un auto clásico?`,
          body: `Primero que nada, debemos dejar claro que no todos los autos más o menos viejos son clásicos. Se considera que un coche es clásico cuando pasaron más de 30 años de su fabricación.
          Si su vehículo no supera los 15 o 20 años, será catalogado como antiguo. En el caso de los autos clásicos, el monto del vehículo podrá ser estimado a un precio mayor de su monto de compra, mientras que en los autos antiguos su valor disminuye año tras año.`,
        },
        {
          header: `¿Tienes un auto clásico y quieres manejarlo tranquilo?`,
          body: `Estos son los requisitos que debe cumplir tu vehículo para acceder a un seguro para coche:`,
          list: [
            `Contar con al menos 85% de las partes originales y en buen estado`,
            `Tener más de 30 años de antigüedad`,
            `No ser de uso diario`,
            `Ser de circulación moderada para exposiciones o eventos`,
            `Contar con la documentación original`,
            `Tener la acreditación validada por un experto`,
          ],
        },
        {
          header: ``,
          body: `Otros aspectos que pueden ser tomados en cuenta por tu aseguradora, a la hora de contratar un seguro para coche, son:`,
          list: [
            `Marca del vehículo`,
            `Edición`,
            `Fabricante`,
            `Cantidad de arreglos hechos al vehículo durante su tiempo de uso`,
            `Kilometraje`,
          ],
        },
        {
          header: `¿Qué cubre el seguro para coche si es clásico?`,
          body: `La póliza para auto clásico funciona igual que cualquier otro seguro para coche, tienen la misma cobertura tradicional:`,
          list: [
            `Robo de vehículo`,
            `Responsabilidad Civil`,
            `Defensa Jurídica`,
            `Asesoría Legal`,
            `Daños a terceros`,
            `Gastos Médicos`,
          ],
          footer: `Puedes acceder a cualquier tipo de cobertura, desde la más básica hasta la más extensa. Recuerda que el valor del seguro para coche varía dependiendo del vehículo que poseas.`,
        },
        {
          header: `¡A disfrutar tu coche clásico!`,
          body: `Ahora que estás informado sobre todo lo que deberías buscar para contratar un seguro para coche, lo único que te queda por hacer, es cotejar los distintos precios que pueden ofrecerte distintas aseguradoras.
          Pero, ¡a no desesperar! En INTER.MX ofrecemos una serie de planes especiales para cada tipo de coche, teniendo en cuenta sus particularidades y características, para que asegures tu coche de manera fácil, rápida y segura.
          ¡Mantente al día de nuestros servicios visitando nuestro <>Facebook</> e <>Instagram</>!`,
        },
      ],
    },
    {
      img: "",
      date: "15 septiembre, 2021",
      autor: "admininter",
      title: "SEGURO PARA COCHE Y TORMENTAS: ¿QUÉ DAÑOS CUBRE?",
      body: "Si quisiéramos hacer una lista con todas las amenazas que tiene un conductor al salir a las calles jamás acabaríamos de enumerarlas: inseguridad, sueño, conductores imprudentes, mal clima, baches y",
      content: [
        {
          header: ``,
          body: `Si quisiéramos hacer una lista con todas las amenazas que tiene un conductor al salir a las calles jamás acabaríamos de enumerarlas: inseguridad, sueño, conductores imprudentes, mal clima, baches y ni hablar de la posibilidad de una avería… Por suerte, al contratar tu seguro para coche con inter.mx estarás cubierto contra cualquier obstáculo que se te presente en el camino.`,
        },
        {
          header: `El enemigo #1 de los conductores: las tormentas`,
          body: `A pesar de todas las amenazas que existen, las tormentas y lluvias aumentan significativamente el riesgo de accidente para los conductores. Por esta razón, tan importante como contar con un buen seguro para coche, es necesario que conozcas cómo reducir al máximo los inconvenientes.
          Es clave que antes de emprender un viaje revises el estado de:`,
          list: [
            `Luces`,
            `Frenos`,
            `Batería`,
            `Aceite`,
            `Neumáticos`,
            `Limpiaparabrisas`,
            `Kit de carreteras`,
          ],
          footer: `Cabe recordar que es fundamental no ir a alta velocidad cuando la vía está húmeda ya que con una frenada súbita se puede perder por completo el control del vehículo.
          Te puede interesar: Los 3 accidentes más comunes al volante.`,
        },
        {
          header: `Nunca te faltará respaldo con un Seguro para Coche`,
          body: `Si sigues las recomendaciones que te hemos dado podrás reducir al máximo el riesgo de accidentes generados por una tormenta. Sin embargo, esto no quiere decir que estés exento de que pase algo.
          Con el seguro de coche de inter.mx no tendrás que preocuparte porque tu estabilidad financiera se vea perjudicada tras algún tipo de accidente. Nosotros cubriremos los daños materiales de tu coche y las afectaciones que puedan presentare en caso de que ocurra algún inconveniente por culpa de la lluvia.
          Además, cuentas con servicio de asistencia 24/7 para grúa, regreso a casa, paramédico o defensa legal si el accidente llegara a afectar a terceros.
          ¡No lo pienses más! Prevenir está bien, pero es necesario tener la seguridad de que tendrás los recursos necesarios en caso de que un imprevisto se cruce en tu camino.
          En INTER.MX puedes adquirir tu seguro de coche con el mejor precio del mercado y la máxima cobertura.
          ¡Contrata en línea rápido, fácil y seguro!
          No olvides seguirnos en nuestras redes de <>Instagram</> y <>Facebook</>.`,
        },
      ],
    },
    {
      img: "",
      date: "15 septiembre, 2021",
      autor: "admininter",
      title: "SEGURO DE GASTOS MÉDICOS MAYORES: PROTÉGETE DE LA COVID-19",
      body: "Si bien a estas alturas de la pandemia se han tomado medidas sanitarias estrictas para reducir las curvas de contagio por la COVID-19. La necesidad de salir a desempeñar nuestras",
      content: [
        {
          header: ``,
          body: `Si bien a estas alturas de la pandemia se han tomado medidas sanitarias estrictas para reducir las curvas de contagio por la COVID-19. La necesidad de salir a desempeñar nuestras actividades cotidianas, ha hecho que muchas personas opten por un seguro de gastos médicos mayores que les ayude a hacer frente a esta enfermedad.
        De acuerdo con la Dirección General de Epidemiología, México sumó 3.27 millones de contagios acumulados desde el inicio de la pandemia hasta mediados del mes de agosto del 2021.
        En este sentido, es importante considerar que la economía se ha visto afectada por el cese de gran parte de las actividades comerciales, las cuales se han ido retomando poco a poco.
        Por lo tanto, la mejor alternativa que tenemos es seguir las medidas de prevención contra la COVID-19 y contar con un respaldo económico que nos permita cubrir todos los gastos médicos en caso de contraer el virus.`,
        },
        {
          header: `Seguro de gastos médicos mayores por COVID-19`,
          body: `Covid Protect Complete es una protección adicional que INTER ha desarrollado para brindarte un respaldo financiero ante la COVID-19. Estamos hablando de un seguro de gastos médicos mayores que te protege si te hospitalizan por coronavirus.
        Por una cantidad de $1,100 por persona, este seguro te ofrece una suma asegurada de 50 mil pesos para recuperarte con tranquilidad en caso de ser hospitalizado 48 horas por COVID-19.
        Te puede interesar: Conoce cuáles son los beneficios de los Seguros para Autos.
        Además, también brinda una serie de beneficios adicionales en caso de que no puedas salir de casa y necesites recomendaciones o asistencia en situaciones relacionadas con la COVID-19:`,
          list: [
            `Orientación médica y psicológica`,
            `Médico y ambulancia a domicilio 24/7`,
            `Asistencia nutricional`,
            `Asistencia escolar en materias de educación primaria`,
            `Asistencia veterinaria para perros o gatos`,
          ],
          footer: `No esperes hasta el momento de contagiarte, contrata desde ahora tu seguro de gastos médicos mayores por COVID-19 y protege tu salud financiera. Aún nos falta mucho para poder vencer esta terrible enfermedad.
        Contrata tu seguro desde nuestro portal INTER.MX ¡Fácil, rápido y seguro!
        Síguenos en <>Facebook</> – <>Instagram</> – YouTube – TikTok – LinkedIn.`,
        },
      ],
    },
  ];
  const [dataRender, setDataRender] = useState({});
  useEffect(() => {
    window.scrollTo(0, 0);
    let found = false;
    dataBlog.map((data, index) => {
      if (
        data.title
          .replaceAll(" - ", "-")
          .replaceAll(" | ", "-")
          .replaceAll(" ", "-")
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replaceAll(/[.,/#¡!$%¿?^&*;:{}=_`~()]/g, "")
          .toLowerCase() === page
      ) {
        setDataRender(data);
        found = true;
      }
      return true;
    });
    if (!found) {
      history.push("/blog");
    }
  }, [page]);

  const urls = {
    "¿Por qué leer las condiciones generales de tu seguro?":"https://inter.mx/info/por-que-leer-las-condiciones-generales-de-tu-seguro",
    "Tips infalibles para proteger tu salud":"https://inter.mx/info/mes-de-la-salud-tips-infalibles-para-proteger-tu-salud",
    "Las coberturas que seguro, seguro te pueden sacar de apuros estas vacaciones":"https://inter.mx/info/las-coberturas-que-seguro-seguro-te-pueden-sacar-de-apuros-estas-vacaciones",
    "Las coberturas de coche seguro que seguro, seguro te sacarán de un apuro":"https://inter.mx/info/las-coberturas-de-coche-seguro-que-seguro-seguro-te-sacaran-de-un-apuro",
    "aqui.": "https://cocheseguro.inter.mx/",
    "¿Estás preparando las vacaciones de verano?":"https://inter.mx/info/estas-preparando-las-vacaciones-de-verano",
    "¿Buscas un Seguro de Mascota? ¡Encuéntralo aquí!":"https://mascotasegura.inter.mx/seguro-mascota/landing",
    "Tres pasos para enseñarle a tu perrhijo buenos modales.":"https://inter.mx/info/tres-pasos-para-ensenarle-a-tu-perrhijo-buenos-modales",
    "Vacunas para mascotas: ¿cuáles le tocan a tu peludito en cada momento de su vida?":"https://inter.mx/info/vacunas-para-mascotas-cuales-le-tocan-a-tu-peludito-en-cada-momento-de-su-vida",
    "Médico Seguro: ¿Cómo funciona la cobertura de maternidad?":"https://inter.mx/info/medico-seguro-como-funciona-la-cobertura-de-maternidad",
    "aquí.":"https://play.google.com/store/apps/details?id=grut.inter.mx&pli=1",
    "haz click aquí": "https://mascotasegura.inter.mx/seguro-mascota/landing",
    "Protege a quien más amas con médico seguro":
      "https://inter.mx/info/protege-a-quien-mas-amas-con-medico-seguro",
    "¿Qué debo saber a la hora de contratar mi Seguro de Coche?":
      "https://inter.mx/info/que-debo-saber-a-la-hora-de-contratar-mi-seguro-de-coche",
    "¿Coche Seguro cubre mi accidente completo?":
      "https://inter.mx/info/coche-seguro-cubre-mi-accidente-completo",
    "Protege a quien más amas con un Seguro Médico":
      "https://inter.mx/info/protege-a-quien-mas-amas-con-medico-seguro",
    "seguro de gastos médicos mayores": "https://medicoseguro.inter.mx/inicio",
    "Palabras extrañas de tu seguro médico":
      "https://inter.mx/info/palabras-extranas-de-tu-seguro-medico",
    "seguro médico": "https://medicoseguro.inter.mx/inicio",
    "seguro de coche": "https://cocheseguro.inter.mx/",
    "https://medicoseguro.inter.mx/inicio":
      "https://medicoseguro.inter.mx/inicio",
    "¿Qué preguntas debes responder al contratar un Seguro Médico?":
      "https://inter.mx/info/que-preguntas-debes-responder-al-contratar-un-seguro-medico",
    "https://mascotasegura.inter.mx/seguro-mascota/landing":
      "https://mascotasegura.inter.mx/seguro-mascota/landing",
    "Vacunas para mascotas: ¿Cuáles le tocan a tu peludito en cada momento de su vida?":
      "https://inter.mx/info/vacunas-para-mascotas-cuales-le-tocan-a-tu-peludito-en-cada-momento-de-su-vida",
    "La vida es complicada, tener un seguro no lo es":
      "https://inter.mx/info/la-vida-es-complicada-tener-un-seguro-no-lo-es",
    "https://inter.mx/": "https://inter.mx/",
    "https://cocheseguro.inter.mx/": "https://cocheseguro.inter.mx/",
    "Asistencias de tu Seguro de Coche que no sabías que existían":
      "https://inter.mx/info/asistencias-de-tu-seguro-de-coche-que-no-sabias-que-existian",
    "¿Qué debo saber a la hora de contratar mi seguro de coche?":
      "https://inter.mx/info/que-debo-saber-a-la-hora-de-contratar-mi-seguro-de-coche",
    "Coche Seguro: híbrido, eléctrico o de gasolina":
      "https://inter.mx/info/coche-seguro-hibrido-electrico-o-de-gasolina",
    "Tres pasos para enseñarle a tu perrhijo buenos modales":
      "https://inter.mx/info/info/tres-pasos-para-ensenarle-a-tu-perrhijo-buenos-modales",
    "Cuatro cosas que no sabías de seguros":
      "https://inter.mx/info/cuatro-cosas-que-no-sabias-de-seguros",
    "¿Cómo bajar la prima de mi Seguro Médico?":
      "https://inter.mx/info/como-bajar-la-prima-de-mi-seguro-medico",
    "¿Las coberturas cambian dependiendo de la aseguradora?":
      "https://inter.mx/info/las-coberturas-cambian-dependiendo-de-la-aseguradora",
    "¿Qué imprevistos cubren los Seguros para Mascotas?":
      "https://inter.mx/info/que-imprevistos-cubren-los-seguros-para-mascotas",
    "¿Hay límite de edad en un Seguro para Mascotas?":
      "https://inter.mx/info/hay-limite-de-edad-en-un-seguro-para-mascotas",
    "Seguro para Mascotas: ¿solamente incluye perros y gatos?":
      "https://inter.mx/info/seguro-para-mascotas-solamente-incluye-perros-y-gatos",
    "Cotizador de seguros - Todo lo que necesitas a un clic de distancia":
      "https://inter.mx/info/cotizador-de-seguros-todo-lo-que-necesitas-a-un-clic-de-distancia",
    "Recuperación Segura, el Seguro Médico más accesible.":
      "https://inter.mx/info/recuperacion-segura-el-seguro-medico-mas-accesible",
    "¿Buscas un Seguro de Gastos Médicos? ¡Encuéntralo aquí!":
      "https://inter.mx/info/buscas-un-seguro-de-gastos-medicos-encuentralo-aqui",
    "¿Puedo asegurar un coche sin placas?":
      "https://inter.mx/info/puedo-asegurar-un-coche-sin-placas",
    "www.cocheseguro.inter.mx": "http://www.cocheseguro.inter.mx/",
    "aquí,": "http://www.cocheseguro.inter.mx/",
    "¿Qué pasa con mi Seguro de Coche si vendo mi auto?":
      "https://inter.mx/info/que-pasa-con-mi-seguro-de-coche-si-vendo-mi-auto",
    // eslint-disable-next-line no-dupe-keys
    "Asistencias de tu Seguro de Coche que no sabías que existían":
      "https://inter.mx/info/asistencias-de-tu-seguro-de-coche-que-no-sabias-que-existian",
    "www.medicoseguro.inter.mx": "http://www.medicoseguro.inter.mx/",
    "¿Por qué es importante hacerte un check-up médico?":
      "https://inter.mx/info/por-que-es-importante-hacerte-un-check-up-medico",
    "¿Cómo bajar la prima de tu Seguro Médico?":
      "https://inter.mx/info/como-bajar-la-prima-de-mi-seguro-medico",
    "Viene la temporada de frío, ¡cuídate con un Seguro Médico y estos tips!":
      "https://inter.mx/info/viene-la-temporada-de-frio-cuidate-con-un-seguro-medico-y-estos-tips",
    "¡Sigue este enlace!": "https://medicoseguro.inter.mx/inicio",
    acá: "https://inter.mx/info/mi-seguro-medico-me-protege-contra-nuevos-virus",
    "aquí!": "https://cocheseguro.inter.mx/inicio",
    "Conoce, además, cuatro cosas que seguro no sabías sobre seguros":
      "https://inter.mx/info/cuatro-cosas-que-no-sabias-de-seguros",
    "Recuperación segura, el seguro médico más accesible":
      "https://inter.mx/info/recuperacion-segura-el-seguro-medico-mas-accesible",
    "… aquí":
      "https://inter.mx/info/medico-seguro-cotizador-para-elegir-el-mejor-seguro-medico",
    "¡Quiero contratar un Seguro Médico para mí y mi familia!":
      "https://inter.mx/info/quiero-contratar-un-seguro-medico-para-mi-familia",
    aquí: "https://medicoseguro.inter.mx/inicio",
    // eslint-disable-next-line no-dupe-keys
    "¿Qué imprevistos cubren los Seguros para Mascotas?":
      "https://inter.mx/info/que-imprevistos-cubren-los-seguros-para-mascotas",
    "inter.mx/modocheco": "https://modocheco.inter.mx/",
    "¿Palabras extrañas en tu seguro médico? ¡Aquí te las explicamos!":
      "https://inter.mx/info/palabras-extranas-de-tu-seguro-medico",
    "¿Mi Seguro Médico me cubre si me embarazo?":
      "https://inter.mx/info/mi-seguro-medico-me-cubre-si-me-embarazo",
    "¿Mi Seguro Médico incluye Plan Dental y de qué se trata?":
      "https://inter.mx/info/mi-seguro-medico-incluye-plan-dental-y-de-que-se-trata",
    "Palabras extrañas de tu Seguro Médico":
      "https://inter.mx/info/palabras-extranas-de-tu-seguro-medico",
    "¿Un Seguro Médico cubre cirugías inmediatas?":
      "https://inter.mx/info/un-seguro-medico-cubre-cirugias-inmediatas",
    "Médico Seguro: cotizador para elegir el mejor Seguro Médico":
      "https://inter.mx/info/medico-seguro-cotizador-para-elegir-el-mejor-seguro-medico",
    "Seguro Médico: esencial, protegido o blindado ¿Qué plan escoger?":
      "https://inter.mx/info/seguro-medico-esencial-protegido-o-blindado-que-plan-escoger",
    "inter.mx/blog": "https://inter.mx/blog",
    "¿Puedo asegurar mi auto si soy socio conductor de una app?":
      "https://inter.mx/info/puedo-asegurar-mi-auto-si-soy-socio-conductor-de-una-app",
    "Coche Seguro": "https://cocheseguro.inter.mx/",
    // eslint-disable-next-line no-dupe-keys
    "¿Puedo asegurar un coche sin placas?":
      "https://inter.mx/info/puedo-asegurar-un-coche-sin-placas",
    "¿Cualquier Seguro de Auto me cubre por Daños a Terceros?":
      "https://inter.mx/info/cualquier-seguro-de-auto-me-cubre-por-danos-a-terceros",
    "¿Por qué el precio del Seguro de Coche cambia según el modelo?":
      "https://inter.mx/info/por-que-el-precio-del-seguro-de-coche-cambia-segun-el-modelo",
    "¿Cuáles son las exclusiones de un Seguro de Gastos Médicos?":
      "https://inter.mx/info/cuales-son-las-exclusiones-de-un-seguro-de-gastos-medicos",
    // eslint-disable-next-line no-dupe-keys
    "Cotizador de seguros - Todo lo que necesitas a un clic de distancia":
      "https://inter.mx/info/cotizador-de-seguros-todo-lo-que-necesitas-a-un-clic-de-distancia",
    "¡Quiero contratar un Seguro Médico para mi familia!":
      "https://inter.mx/info/quiero-contratar-un-seguro-medico-para-mi-familia",
    "¿Mi seguro médico me cubre si me embarazo?":
      "https://inter.mx/info/mi-seguro-medico-me-cubre-si-me-embarazo",
    // eslint-disable-next-line no-dupe-keys
    "¿Buscas un Seguro de Gastos Médicos? ¡Encuéntralo aquí!":
      "https://inter.mx/info/buscas-un-seguro-de-gastos-medicos-encuentralo-aqui",
    "¿Un Seguro de Gastos Médicos Mayores cubre cirugías plásticas?":
      "https://inter.mx/info/un-seguro-de-gastos-medicos-mayores-cubre-cirugias-plasticas",
    "Todo sobre las coberturas de un buen Seguro de Gastos Médicos":
      "https://inter.mx/info/todo-sobre-las-coberturas-de-un-buen-seguro-de-gastos-medicos",
    "Médico seguro: cotizador para elegir el mejor seguro médico":
      "https://inter.mx/info/medico-seguro-cotizador-para-elegir-el-mejor-seguro-medico",
    "¿Un seguro médico cubre cirugías inmediatas?":
      "https://inter.mx/info/un-seguro-medico-cubre-cirugias-inmediatas",
    "Seguro de gastos médicos: ¿hasta qué edad estoy cubierto?":
      "https://inter.mx/info/seguro-de-gastos-medicos-hasta-que-edad-estoy-cubierto",
    "¡Quiero contratar un seguro médico para mi familia!":
      "https://inter.mx/info/quiero-contratar-un-seguro-medico-para-mi-familia",
    "Seguros para mascotas: ¿un lujo o una necesidad?":
      "https://inter.mx/info/seguros-para-mascotas-un-lujo-o-una-necesidad",
    "¿Cómo cotizar un seguro para mascotas? ¿Qué debes saber?":
      "https://inter.mx/info/como-cotizar-un-seguro-para-mascotas-que-debes-saber",
    "Seguro para mascotas: ¿solamente incluye perros y gatos?":
      "https://inter.mx/info/seguro-para-mascotas-solamente-incluye-perros-y-gatos",
    "¿Hay límite de edad en un seguro para mascotas?":
      "https://inter.mx/info/hay-limite-de-edad-en-un-seguro-para-mascotas",
    "Cotizador de seguros: ¿de veras es rapidísimo?":
      "https://inter.mx/info/cotizador-de-seguros-de-veras-es-rapidisimo",
    "Seguro para auto | 10 cosas que debes hacer en un choque":
      "https://inter.mx/info/seguro-para-auto-10-cosas-que-debes-hacer-en-un-choque",
    "Situaciones en las que agradecerás tener un seguro para coches":
      "https://inter.mx/info/situaciones-en-las-que-agradeceras-tener-un-seguro-para-coches",
    "¡Tips para tener seguridad al volante!":
      "https://inter.mx/info/seguro-para-coche-tips-para-tener-seguridad-al-volante",
    "Seguro de coche: ¿tengo que ir a una oficina para contratar?":
      "https://inter.mx/info/seguro-de-coche-tengo-que-ir-a-una-oficina-para-contratar",
    "Seguro para auto: ¿americano, chocolate o con título salvage?":
      "https://inter.mx/info/seguro-para-auto-americano-chocolate-o-con-titulo-salvage",
    "¿Los seguros médicos cubren enfermedades preexistentes?":
      "https://inter.mx/info/los-seguros-medicos-cubren-enfermedades-preexistentes",
    "¿Cuáles son las exclusiones de un seguro de gastos médicos?":
      "https://inter.mx/info/cuales-son-las-exclusiones-de-un-seguro-de-gastos-medicos",
    "redes sociales": "https://www.instagram.com/intermx/",
    "¿Qué información necesitas para cotizar un seguro de auto?":
      "https://inter.mx/info/que-informacion-necesitas-para-cotizar-un-seguro-de-auto",
    "¿Qué considerar para contratar el mejor seguro de auto para ti?":
      "https://inter.mx/info/que-considerar-para-contratar-el-mejor-seguro-de-auto-para-ti",
    GMX: "https://www.gmx.com.mx/assets/files/Medipet/Condiciones-generales-MEDIPET.pdf",
    "¡Aprende las leyes de la selva con inter.mx!": "https://www.inter.mx/",
    "aqui!": "https://cocheseguro.inter.mx/inicio",
    "Mascota Segura": "https://mascotasegura.inter.mx/seguro-mascota/landing",
    sitio: "https://inter.mx/terminos-condiciones",
    "¡Ahorra tiempo y dinero con la ayuda de inter.mx!": "https://inter.mx/",
    "¿Qué información necesitas para cotizar el seguro ideal para tu auto?":
      "https://inter.mx/info/que-informacion-necesitas-para-cotizar-un-seguro-de-auto",
    "¡Cotiza ahora mismo con nuestro cotizador de seguros!":
      "https://cocheseguro.inter.mx/inicio",
    "¡Conoce mejor el mundo de los seguros con inter.mx!":
      "https://www.inter.mx/",
    "¡Ser responsable es súper fácil con inter.mx!": "https://www.inter.mx/",
    "@INTERproteccion.": "https://twitter.com/intermx",
    "@INTERproteccion": "https://www.instagram.com/interproteccion/",
    "www.facebook.com/interproteccionmx":
      "http://www.facebook.com/interproteccionmx",
    "INTERprotección Oficial":
      "https://www.youtube.com/channel/UCZ8wH2JLzDYvvrN-JiG3roA",
    "¡Cotiza ahora!": "https://medicoseguro.inter.mx/inicio",
    "¡Protege lo que más amas, fácil, rápido y al mejor precio!":
      "https://www.inter.mx/",
    "seguro de salud": "https://productos.inter.mx/gastos-medicos-mayores/",
    "Médico Seguro": "https://medicoseguro.inter.mx/inicio",
    "nuestros asesores":
      "https://api.whatsapp.com/send/?phone=5215543269037&text&app_absent=0",
    póliza: "https://mascotasegura.inter.mx/seguro-mascota/landing",
    WhatsApp:
      "https://api.whatsapp.com/send/?phone=5215543269037&text&app_absent=0",
    "Apple App Store":
      "https://apps.apple.com/mx/app/intermx/id1548367767?l=en",
    "póliza,":
      "https://inter.mx/info/el-buen-fin-2021-es-para-ti-contrata-el-mejor-seguro-de-coche",
    "seguro ideal para tu auto":
      "https://inter.mx/info/dinos-lo-que-buscas-y-te-acercamos-el-mejor-seguro-para-autos",
    "nuestro sitio": "https://inter.mx/",
    "mejores planes de salud":
      "https://seguros.inter.mx/que-necesitas-que-cubra-tu-seguro-de-gastos-medicos-mayores/",
    "cobertura médica":
      "https://inter.mx/info/aprende-lo-basico-y-fundamental-de-un-seguro-de-gastos-medicos",
    contacto: "https://medicoseguro.inter.mx/inicio",
    "Si tu coche fue amor a primera vista ¡Protégelo! Seguro de Coche":
      "https://seguros.inter.mx/si-tu-coche-fue-amor-a-primera-vista-protegelo-seguro-de-coche/",
    "Guía definitiva para comprar tu seguro médico":
      "https://seguros.inter.mx/guia-definitiva-para-comprar-un-seguro-de-gastos-medicos/",
    "bróker de seguros especializado, como inter.mx":
      "https://seguros.inter.mx/no-sabes-que-tipo-de-seguro-contratar-broker-de-seguros/",
    "periodo de espera,":
      "https://seguros.inter.mx/que-es-el-periodo-de-espera-de-un-seguro-de-gastos-medicos/",
    "App Store": "https://apps.apple.com/mx/app/intermx/id1548367767?l=en",
    "Google Play":
      "https://play.google.com/store/apps/details?id=grut.inter.mx",
    "cambias la llanta tú solito":
      "https://seguros.inter.mx/seguro-para-coche-paso-a-paso-para-cambiar-una-llanta/",
    "hola@inter.mx": "mailto:hola@inter.mx",
    "red hospitalaria": "https://productos.inter.mx/gastos-medicos-mayores/",
    "inter.mx": "https://inter.mx/",
    diabetes:
      "https://www.mayoclinic.org/es-es/diseases-conditions/diabetes/diagnosis-treatment/drc-20371451",
    "si tu póliza así lo determina":
      "https://www.fundacionmapfre.org/publicaciones/diccionario-mapfre-seguros/preexistencia/",
    "periodo de espera":
      "https://www.abcdelseguro.com.pe/diccionario/periodo-espera-7467491#:~:text=El%20tiempo%20de%20espera%20es,cuanto%20se%20cumpla%20este%20periodo.",
    estética:
      "https://www.mayoclinic.org/es-es/healthy-lifestyle/adult-health/in-depth/cosmetic-surgery/art-20048303",
    "mundo digital":
      "https://payretailers.com/es/latam/las-10-mejores-apps-de-pagos-en-linea-de-latinoamerica/",
    // eslint-disable-next-line no-dupe-keys
    aquí: "https://cocheseguro.inter.mx/inicio?utm_source=affiliate&utm_medium=cpc&utm_campaign=getblue_seguro_auto&utm_content=300x250mejorprecio",
    contactarnos:
      "https://cocheseguro.inter.mx/inicio?utm_source=affiliate&utm_medium=cpc&utm_campaign=getblue_seguro_auto&utm_content=300x250mejorprecio",
    "Sergio “Checo” Pérez":
      "https://carmania.mx/2021/09/30/inter-mx-y-checo-perez-lanzan-el-interchecocha11enge/",
    choque:
      "https://inter.mx/info/seguro-de-coche-que-hacer-en-caso-de-un-choque-",
    "Suelen decir lo que piensan en voz alta":
      "https://www.gaceta.unam.mx/centennials-y-millenials-claves-para-su-definicion/",
    "mercado laboral":
      "https://www.elplural.com/leequid/que-significa-ser-centennial_203008102",
    CNN: "https://sponsorcontent.cnn.com/es/interproteccion/las-generaciones-digitales-el-nuevo-reto-de-la-industria-aseguradora/?fbclid=IwAR34WFj6Hnpl51OIN0cjxsKpnZSg6jpG4y0tSf0QqqzlZK4FnNALwWuj3V8",
    "Glosario de conceptos que no entiendes sobre los seguros":
      "https://inter.mx/info/glosario-de-conceptos-que-no-entiendes-sobre-los-seguros",
    "controles de tránsito":
      "https://www.dodge.com.mx/blog/dodge-life/reglamento-de-transito-cdmx-2021cuales-son-los-cambios?gclid=CjwKCAiAzrWOBhBjEiwAq85QZ23r4LFD9tUAc6feIfDRt18GGGry3vflPILQ3PDHZFSCACxkTKSYnhoCjkAQAvD_BwE",
    "portal especializado": "https://inter.mx/",
    "tabla de circunstancias":
      "https://www.ep-seguros.com/Guia_de_deslinde_Autos_2015.pdf",
    "¿Cómo hacer que el sueño no te gane en un viaje largo?":
      "https://seguros.inter.mx/seguro-de-coche-como-hacer-que-el-sueno-no-te-gane-en-un-viaje-largo/",
    "Red Nacional de Caminos":
      "https://www.gob.mx/imt/acciones-y-programas/red-nacional-de-caminos",
    Waze: "https://www.waze.com/es/",
    "portal de cifras Statista":
      "https://es.statista.com/estadisticas/1190260/poblacion-extranjera-de-mexico-por-nacionalidad/",
    "gastos médicos mayores":
      "https://seguros.inter.mx/sabes-que-cubre-un-seguro-de-gastos-medicos-mayores/",
    redhospitalaria:
      "https://inter.mx/info/seguro-de-gastos-medicos-para-adulto-mayor-como-adquirirlo",
    "cambiar una llanta":
      "https://inter.mx/info/seguro-para-coche-paso-a-paso-para-cambiar-una-llanta",
    siniestros:
      "https://www.edufinet.com/inicio/seguros/siniestro/que-se-entiende-por-siniestro",
    "medicina primaria":
      "https://www.who.int/es/news-room/fact-sheets/detail/primary-health-care",
    "buen seguro médico":
      "https://inter.mx/info/guia-rapida-para-elegir-un-seguro-de-gastos-medicos-mayores",
    coberturas:
      "https://inter.mx/info/5-cosas-que-cubre-tu-seguro-para-coche-y-no-sabias",
    "dependiendo del año de fabricación":
      "https://inter.mx/info/seguro-para-coche-como-asegurar-un-auto-clasico",
    AXA: "https://axa.mx/personas/inicio",
    UMA: "https://www.inegi.org.mx/temas/uma/",
    "5 claves para elegir el Seguro para Coche perfecto":
      "https://seguros.inter.mx/5-claves-para-elegir-el-seguro-para-coche-perfecto/",
    deducible:
      "https://seguros.inter.mx/todo-lo-que-debes-saber-del-deducible-de-tu-seguro-de-auto/",
    "app de inter.mx":
      "https://play.google.com/store/apps/details?id=grut.inter.mx&hl=es_MX&gl=US",
    blog: "https://inter.mx/blog",
    "¿Qué necesitas que cubra tu seguro de gastos médicos mayores?":
      "https://seguros.inter.mx/que-necesitas-que-cubra-tu-seguro-de-gastos-medicos-mayores/",
    TikTok: "https://vm.tiktok.com/ZMeSRnwDw/",
    expertos: "https://medlineplus.gov/spanish/ency/article/002125.htm",
    "servicio público de salud": "https://www.insp.mx/",
    "expertos recomiendan":
      "https://www.michelin.com.mx/auto/tips-and-advice/advice-auto/when-should-i-change-my-car-tyres",
    "Elige y cotiza la cobertura adecuada para ti":
      "https://cocheseguro.inter.mx/inicio",
    "Banco Mundial": "https://openknowledge.worldbank.org/handle/10986/36331",
    Forbes:
      "https://www.forbes.com.mx/nuestra-revista-los-seguros-en-mexico-despues-de-la-pandemia/",
    desvalorizan: "https://dle.rae.es/desvalorizar",
    "aquí ": "https://inter.mx/blog",
    plataformas:
      "https://www.autofact.com.mx/blog/comprar-carro/mercado/paginas-venta-autos",
    Twitter:
      "https://twitter.com/intermx?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
    Instagram: "https://www.instagram.com/intermx/",
    Facebook: "https://www.facebook.com/intermx",
    LinkedIn: "https://www.linkedin.com/company/interproteccion/",
    YouTube: "https://www.youtube.com/channel/UCZ8wH2JLzDYvvrN-JiG3roA",
  };

  const replaceHtml = (text) => {
    let explode;
    let html = [];
    let htmlRaw = text.trim().split(String.fromCharCode(10));
    htmlRaw.map((line, j) => {
      if (line.includes("<>")) {
        let lineBreak;
        explode = line.split("<>");
        lineBreak = explode[0].split(String.fromCharCode(10));
        if (lineBreak.length < 1) {
          lineBreak.map((textResult, b) => {
            html.push(
              <>
                {textResult}
                <br />
              </>
            );
            return true;
          });
        } else {
          html.push(<>{explode[0]}</>);
        }
        explode.map((v, i) => {
          if (v.includes("</>")) {
            let e = v.split("</>");
            html.push(
              <>
                <a href={urls[e[0]]} target="_blank" rel="noreferrer">
                  {e[0]}
                </a>
                {e[1]}
              </>
            );
          }
          return true;
        });
      } else {
        if (line) {
          if (htmlRaw.length - 1 === j) {
            html.push(
              <>
                {line}
                <br />
              </>
            );
          } else {
            html.push(
              <>
                {line}
                <br />
                <br />
              </>
            );
          }
        }
      }
      return false;
    });

    return (
      <>
        {html.map((v, i) => {
          return <span key={i}>{v}</span>;
        })}
      </>
    );
  };
  return (
    <div className="w-full margin-auto text-center">
      <div className="text-left rounded-2xl px-4">
        <Card aria-describedby="scroll-dialog-description">
          <CardMedia
            component="img"
            height="240"
            image={dataRender.img || Default}
            loading="lazy"
            alt={`imagen${page}`}
          />
          <CardContent>
            <div className="md:w-9/12 mx-auto">
              <div className="font-bold font-28 uppercase">
                {dataRender.title}
              </div>
              <div className="font-18 pb-5">{dataRender.date} - adminInter</div>
              {dataRender.content &&
                dataRender.content.map((data, index) => {
                  return (
                    <div key={index}>
                      {data.header && (
                        <div className="title-blog py-3">{data.header}</div>
                      )}
                      {data.body && (
                        <div className="body-blog">
                          {replaceHtml(data.body)}
                        </div>
                      )}
                      <div
                        className={`${
                          data.list && data.list.length ? "body-list" : "hidden"
                        } pl-10 py-5`}
                      >
                        <ul style={{ listStyleType: "disc" }}>
                          {data.list &&
                            data.list.map((map, index) => {
                              return <li>{replaceHtml(map)}</li>;
                            })}
                        </ul>
                      </div>
                      <div
                        className={`${data.footer ? "blog-footer" : " hidden"}`}
                      >
                        {data.footer ? replaceHtml(data.footer) : ""}
                      </div>
                    </div>
                  );
                })}
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
