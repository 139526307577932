import { Grid } from '@mui/material';
import Form from 'components/vigo/Form';

export default function Budget () {
  return (
  <section className="pb-5 bg-gray-100">
    <div className="container md:w-10/12 mx-auto px-4">
      <div className='px-4 bg-white rounded-lg' >
        <div className='lg:p-5'>
          <Grid container justifyContent='center' alignItems='center'>
            <Grid item xs={12} sm={12} md={7}>
              <div className='p-5 text-black' >
                <div className='font-bold font-32'>Viaja<br/>Seguro, seguro</div>
                <div>Contrata desde $87 + IVA al día</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={5}>
              <div className='' >
                <div className=''>
                  <Form />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  </section>)
} 