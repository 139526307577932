import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation, useRouteMatch,useParams } from 'react-router-dom'
import FinderInput from "components/common/FinderInput";
import TabsNav from "components/common/TabsNav";
import { Grid, Typography/* , Pagination */ } from "@mui/material";
import { ArrowOutward } from "@mui/icons-material";
import BlogDetails from "components/blog/BlogDetails";
import axios from "axios";
import PaginationComponent from "components/common/PaginationComponent";
import "./Blog.css";
/** Temporal */
import Coche from "assets/img/landingRedisign/Blog/Coche/137.webp";
import Coche2 from "assets/img/landingRedisign/Blog/Coche/133.webp";
import Coche3 from "assets/img/landingRedisign/Blog/Coche/138.png";
import Mascota from "assets/img/landingRedisign/Blog/Mascota/135.png";
import Mascota2 from "assets/img/landingRedisign/Blog/Mascota/125.webp";
import Mascota3 from "assets/img/blog/128.webp";
import Salud from "assets/img/blog/66.jpg";
import Salud2 from "assets/img/blog/132.webp";
import Salud3 from "assets/img/landingRedisign/Blog/Salud/136.png";
import Salud4 from "assets/img/blog/134.webp";
import Salud5 from "assets/img/blog/127.webp";
import {updateStaticMetadata} from "components/utils/utils";
import { ImagenesBlog } from "components/common/CommonImages";
import { useAuth } from "hooks/useAuth";

const BlogSection = () => {
  const { name } = useParams();
  const [inputValue, setInputValue] = useState("");
  const [allBlogData, setAllBlogData] = useState([]);
  const [blogData, setBlogData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [filterActive, setFilterActive] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [currentBlogPost, setCurrentBlogPost] = useState({});
  const [relatedPosts, setRelatedPosts] = useState([]);
  const [currentTab, setCurrentTab] = useState("Todos");
  const [currentBlogData, setCurrentBlogData] = useState([]);
  const [currentFilterData, setCurrentFilterData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isClick, setIsClick] = useState(false);
  const [isUrl, setIsUrl] = useState(false);
  const history = useHistory();
  const { url } = useRouteMatch();
  const{token} = useAuth();


  // Función para hacer la solicitud a la URL
  const getPromotions = useCallback((currentTag) => {
    axios
      .get("https://api.inter.mx/v1/catalogs/promo-abc", {
        params: { tag: currentTag },
        headers:{Authorization: `Bearer ${token}`}
      })
      .then((response) => {
        setBlogData(response.data);
        setAllBlogData(response.data);
        if (name){
          setCurrentBlogData(response.data.data);
        }else {
          setCurrentBlogData(response.data.data.slice(0, 6));
        }
      })
      .catch((err) => {
        console.error("Error al hacer la solicitud:", err);
      });
  },[name, token])

  useEffect(() => {
    getPromotions("Todos"); // Llama a la función para obtener los datos solo una vez al montar el componente
    let title= 'Tipos de seguros a la medida | inter.mx';
    let url='/blog';
    let description = 'Consulta en nuestro blog ABC de seguros, noticias relacionadas con el mundo de los seguros de todos los productos que ofrecemos';
    //updateStaticMetadata(title,description,url);
  }, [getPromotions]); // El array de dependencias vacío asegura que useEffect se ejecute solo una vez

  useEffect(() => {
    if (inputValue.length > 2) {
      setFilterActive(true);
      const searchResults = blogData.data.filter((item) => {
        const titleMatches = item.title
          .toLowerCase()
          .includes(inputValue.toLowerCase());
        const tagsMatches = item.tags.some((tag) =>
          tag.toLowerCase().includes(inputValue.toLowerCase())
        );

        return titleMatches || tagsMatches;
      });
      setFilterData(searchResults);
      setCurrentFilterData(searchResults.slice(0, 6));
    } else {
      setFilterActive(false);
    }
  }, [inputValue]);

  useEffect(() => {
    if (allBlogData?.data?.length) {
      const filtrado = allBlogData.data.filter((item) =>
        item.tags.includes(currentTab)
      );
      setBlogData({ data: filtrado });
      setCurrentBlogData(filtrado.slice(0, 6));
    }



    return () => {};
  }, [currentTab]);

  useEffect(() => {
    const startIndex = (currentPage - 1) * 6;
    const endIndex = startIndex + 6;
    if (filterActive) {
      currentFilterData(filterData.slice(startIndex, endIndex));
    } else if (blogData?.data?.length) {
      setCurrentBlogData(blogData.data.slice(startIndex, endIndex));
    }
    return () => {};
  }, [currentPage]);


  const handleTabChange = (tab) => {
    setCurrentTab(tab);
    // getPromotions(tab);
  };

  const showBlogDetails = () => {
    if(showDetails){
      history.replace(`/blog`);
      let title= 'Tipos de seguros a la medida | inter.mx';
      let url='/blog';
      let description = 'Consulta en nuestro blog ABC de seguros, noticias relacionadas con el mundo de los seguros de todos los productos que ofrecemos';
      updateStaticMetadata(title,description,url);
    }
    setShowDetails(!showDetails);
    setIsClick(!isClick)
  };

  const handleDetails = (details) => {
    setIsClick(true)
    setIsUrl(false)
    if (details){
      let noteTitle = details.title;
      //  let fixTitle = noteTitle.replaceAll(' - ', '-').replaceAll(' | ', '-').replaceAll(' ', '-').normalize("NFD").replace(/[\u0300-\u036f]/g, '').replaceAll(/[.,/#¡!$%¿?^&*;:{}=_`~()]/g, '').toLowerCase()
      history.replace(`${url}/${details.url}`);
      updateStaticMetadata(details.title,details.metadata,`${url}/${details.url}`);

      setCurrentBlogPost(details);
      let data = [],
        fullData = filterActive ? currentFilterData : currentBlogData;

      fullData.map((item) => {
        if (item.doc_name !== details.doc_name && data.length < 3) {
          data.push(item);
        }
      });
      setRelatedPosts(data.slice(0, 2));
      showBlogDetails();
    }
  };

  const handleRelatedArticle = (details) => {
    let noteTitle = details.title;
    // let fixTitle = noteTitle.replaceAll(' - ', '-').replaceAll(' | ', '-').replaceAll(' ', '-').normalize("NFD").replace(/[\u0300-\u036f]/g, '').replaceAll(/[.,/#¡!$%¿?^&*;:{}=_`~()]/g, '').toLowerCase()
    history.replace(`/blog/${details.url}`);
    const noteImg = document.getElementById("noteImg");
    noteImg.scrollIntoView();

    setCurrentBlogPost(details);
    let data = [],
      fullData = filterActive ? currentFilterData : currentBlogData;

    fullData.map((item) => {
      if (item.doc_name !== details.doc_name && data.length < 3) {
        data.push(item);
      }
    });
    setRelatedPosts(data.slice(0, 2));
  };

  let fullData = filterActive ? currentFilterData : currentBlogData;
  const cocheImg = { coche: Coche, coche2: Coche2, coche3: Coche3 };
  const mascotasImg = {
    mascota: Mascota,
    mascota2: Mascota2,
    mascota3: Mascota3,
  };
  const saludImg = {
    salud: Salud,
    salud2: Salud2,
    salud3: Salud3,
    salud4: Salud4,
    salud5: Salud5,
  };

  let imagenes = { ...saludImg, ...mascotasImg, ...cocheImg, ...ImagenesBlog };

  const getUrl= async (urlName)=>{
    let item = null
    if (fullData && fullData.length >0){
      item =  await fullData.find(noti => noti.url.toLowerCase() === urlName.toLowerCase());
    }
    if (item){
      setCurrentBlogPost(item);
      let data = [],
        fullData = filterActive ? currentFilterData : currentBlogData;

      fullData.map((ite) => {
        if (ite.doc_name !== item.doc_name && data.length < 3) {
          data.push(ite);
        }
      });
      setRelatedPosts(data.slice(0, 2));
      showBlogDetails();
    }
  }

  useEffect(() => {
    if (!isClick){
      if (name){
        const gets = async ()=>{
          if (fullData && fullData.length >0){
            setIsUrl(true)
            await getUrl(name)
          }
        }
        gets()
      }
    }
  }, [name,fullData])
  return (
    <>
      <FinderInput setInputValue={setInputValue} />
      <TabsNav handleTabChange={handleTabChange} />
      {!showDetails && (
        <>
          <div className="flex justify-center pb-8">
            <Grid container sx={{ maxWidth: "1100px" }}>
              {fullData &&
                fullData.length >0 &&
                fullData.map((data, index) => {
                  return (
                    <Grid item xs={12} md={6} className="px-4 pt-8" key={index}>
                      <div className="blog-tags-container">
                        <div className="blog-first-tag">
                          <Typography>{data.tags[0]}</Typography>
                        </div>
                        {/* <div className="blog-second-tag">
                            <Typography>{data.tags[1]}</Typography>
                        </div> */}
                      </div>
                      <div
                        className="flex items-center justify-center"
                        style={{ height: "80px" }}
                      >
                        <Typography className="text-center txt-bold py-4">
                          {/* {(data.title).toUpperCase()} */}
                          <h2>{data.title}</h2>
                        </Typography>
                      </div>
                      <div
                        className="blog-img-promo"
                        onClick={() => {
                          if (data) {
                            handleDetails(data)
                          }
                        }}
                      >
                        <img
                          src={
                            data?.image.length < 10
                              ? imagenes[data.image]
                              : data.image
                          }
                        />
                        <span>
                          <ArrowOutward />
                        </span>
                      </div>
                      <div className="blog-grid-border pt-8" />
                    </Grid>
                  );
                })}
            </Grid>
          </div>
          <PaginationComponent
            data={filterActive ? filterData : blogData?.data}
            showData={6}
            setCurrentPage={setCurrentPage}
          />
        </>
      )}

      {showDetails && (
        <BlogDetails
          toggleDetails={showBlogDetails}
          currentPost={currentBlogPost}
          relatedPosts={relatedPosts}
          handleRelatedArticle={handleRelatedArticle}
          images={imagenes}
        />
      )}
    </>
  );
};

export default BlogSection;
