import { Box, Toolbar } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import React, { useEffect, useState } from 'react'
import './bannerRenovacion.css'
import { useLocation } from 'react-router-dom'
import maniCintillo from 'assets/img/videos/maniCintillo.gif'

const BannerRenovacion = ({ setShowCmp,showCmp=false}) => {
  let location = useLocation()

  useEffect(() => {
    if (location.pathname === '/') {
      setShowCmp(true)
    }
  }, [location.pathname])

  const closeAction = () => {
    setShowCmp(false)
  }

  return (<Box >
    {
      showCmp &&
      <Box sx={{
        display: 'flex',
        width: '100%',
        padding: { xs: '10px 20px 0 0px', md: '10px 20px 0 20px' },
        background: 'linear-gradient(270deg, #0056CF 0%, #2781FF 100%)',
        position: 'relative', height: '120px',
        justifyContent: 'space-between'

      }}>
        <Box sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          justifyContent: 'flex-end',
          flex: { xs:.5,sm:.3}
        }}>
          <Box component={'img'} sx={{
            objectFit: { xs:'cover',sm:'contain' },
            width: {xs:'100%',sm:'auto' },
            objectPosition: 'center', height: 'auto'
          }} src={maniCintillo}/>
        </Box>
        <Box sx={{
          position: 'relative', width: '90%', display: 'flex',
          alignItems:'center',
          flex:1,
          justifyContent: 'flex-start'
        }}>
          <Box
            id="slider" className="slide-in">
            <>
              <Box component={'span'} sx={{
                color: '#FFD60E',
                fontSize: { xs: '16px', md: '24px' },
                fontFamily: 'Montserrat',
                fontWeight: 900,
                letterSpacing: 0.50,
                wordWrap: 'break-word'
              }}>{'¡Nos estamos renovando! '}
                <Box component={'span'} sx={{
                  color: '#FFF',
                  fontSize: { xs: '16px', md: '24px' },
                  fontFamily: 'Montserrat',
                  fontWeight: 500,
                  letterSpacing: 0.50,
                  wordWrap: 'break-word'
                }}>{'Muy pronto vivirás un nueva experiencia en inter.mx'}</Box>
              </Box>
            </>
          </Box>
        </Box>
        <Box sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
          flex: 0
        }}>
          <CloseIcon onClick={() => {
            closeAction()
          }} fontSize="inherit" sx={{ paddingTop: '0px', width: 24, height: 24 }}/>
        </Box>

      </Box>
    }
  </Box>)
}
export default BannerRenovacion
