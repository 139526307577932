import React from 'react';
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import { Grid } from '@mui/material';
import { ReactComponent as Inter } from 'assets/img/card/inter-blue.svg';

export default function StatusCard({ color, icon, title, children }) {
    return (
        <div className="w-full margin-auto px-4 flex justify-center text-center">
            <Card>
                <CardBody >
                    <Grid container columns={12} alignItems='center'>
                        <Grid item xs={12} sm={12} md={6}>
                          <Inter className='mx-auto svg-autofill'></Inter>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <div className='text-left'>
                            <div className='pb-5' >
                              <span className='font-bold' >Bienvenido a inter.mx, el broker digital </span>
                              que viene a quitarle lo complicado al mundo de los seguros a través de la innovación digital y el respaldo de INTERprotección con más de 40 años de experiencia en México y Latinoamérica.
                            </div>
                            <div className='pt-5'>
                              En inter.mx te ayudaremos a elegir el
                              <span className='font-bold' > seguro ideal para que protejas lo que más amas, </span>
                              garantizandote la mejor experiencia con un
                              <span className='font-bold' > acompañamiento personalizado</span>
                              , para hacerte sentir tranquilo, protegido y escuchado de principio a fin.
                            </div>
                          </div>
                        </Grid>
                    </Grid>
                </CardBody>
            </Card>
        </div>
    );
}
