import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import styles from './DetalleReciboSV.module.css';
import metlife from '../../assets/img/assistants/metlife-logo.png';

const logos = {
  metlife
}

export default function DetalleReciboSV({
  detallesPago,
  futurePayment,
  loadingContinuePayment,
  onClickProcederPago,
  onChangeFuturePayment,
}) {
  const PaymentStatus = () => {
    if (detallesPago.status === 1) {//pendiente
      return <span className={`${styles['status-recibo']} ${styles['status-cancelado']}`}>{detallesPago.status_description}</span>;
    } else if (detallesPago.status === 2) {//pagado
      return <span className={`${styles['status-recibo']} ${styles['status-pagado']}`}>{detallesPago.status_description}</span>;
    } else if (detallesPago.status === 4) {//cancelado
      return <span className={`${styles['status-recibo']} ${styles['status-cancelado']}`}>{detallesPago.status_description}</span>;
    } else {//desconocido
      return <span className={`${styles['status-recibo']} ${styles['status-desconocido']}`}>{detallesPago.status_description}</span>;
    }
  };

  const PolicyNumberStatus = () => {
    if (!detallesPago?.policy_id) {//pendiente
      return (
        <div className={styles['row-detalle']}>
          <p className={styles['titulo-detalle']}>Número de póliza</p>
          <p className={styles['valor-detalle']}><span className={`${styles['status-no-poliza']} ${styles['status-pendiente']}`}>En proceso de emisión</span></p>
        </div>
      );
    } else {
      return <RowDatos titulo='Número de póliza' valor={detallesPago.policy_id} />;
    }
  };

  const RowDatos = ({ titulo, valor }) => {
    if (!valor) {
      return <></>
    }
    return (
      <div className={styles['row-detalle']}>
        <p className={styles['titulo-detalle']}>{titulo}</p>
        <p className={styles['valor-detalle']}>{valor}</p>
      </div>
    )
  }

  const handleFuturePayment = (event) => {
    onChangeFuturePayment(event.target.checked);
  };

  if (
    !detallesPago?.insurance ||
    !detallesPago?.contractor_name ||
    !detallesPago?.payment_limit_date ||
    !detallesPago?.status ||
    !detallesPago?.status_description ||
    !detallesPago?.total ||
    !detallesPago?.total_formatted
  ) {
    return <></>
  }

  return (
    <div className={styles['container-datos']}>
      <p className={styles['titulo-plataforma-mit']}>Información del recibo</p>
      <div className={styles['container-recibos']}>
        <div className={styles['container-montos']}>
          <img className={styles['logo-aseguradora']} src={logos[detallesPago?.insurance]} alt={detallesPago?.insurance} />
          <p className={styles['numero-cuota']}>Recibo {detallesPago.receipt_number} de {detallesPago.total_receipts}</p>
          <p className={styles['monto-total']}>{detallesPago.total_formatted}</p>
          <p className={styles['monto-titulo']}>Monto a pagar</p>
          <PaymentStatus />
        </div>
        <div className={styles['container-descripcion']}>
          <PolicyNumberStatus />
          <RowDatos titulo='Plan' valor={`Vida + ahorro`} />
          <RowDatos titulo='Titular' valor={detallesPago.contractor_name} />
          {
            detallesPago?.policy_id && (<RowDatos titulo='Período' valor={`${detallesPago.receipt_start_date} a ${detallesPago.receipt_end_date}`} />)
          }
          <RowDatos titulo='Fecha de vencimiento de la liga' valor={detallesPago.payment_limit_date} />
        </div>
        <div className={styles['container-acciones']}>
          {
          detallesPago.future_payment_check === true ? (
            <p className={styles['aviso-pago-ref']}>Al hacer clic en <i>Proceder al pago</i>, la tarjeta que ingreses se domiciliará para el cobro de los recibos subsecuentes.</p>
          ) : (
            <FormControlLabel
              disabled={loadingContinuePayment || detallesPago.future_payment_check}
              control={<Checkbox checked={futurePayment} onChange={handleFuturePayment} className={styles['check-future-payment']} />}
              label='Deseo usar esta tarjeta para pagar los recibos subsecuentes.'
              className={styles['form-check-future-payment']}
            />
          )}
          <button disabled={loadingContinuePayment} className={styles['boton-proceder']} onClick={onClickProcederPago}>
            Proceder al pago
          </button>
        </div>
      </div>
    </div>
  );
}
