import React from 'react';
import H2 from '@material-tailwind/react/Heading2';
import { Grid } from '@mui/material';
import Form from 'components/medico/Form';
import { ReactComponent as Medico } from 'assets/img/icons/product/medico-header.svg';

export default function Header() {
    return (
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center h-screen">
            <div className="bg-gmm bg-cover bg-center absolute top-0 w-full h-full" />
            <div className="container md:w-10/12 relative mx-auto">
                <div className="items-left flex flex-wrap">
                    <div className="w-full px-12 pt-24 mr-auto">
                        <Grid container justifyContent='center' alignItems='center' spacing={3} >
                            <Grid item xs={12} sm={12} md={7}>
                                <div className='flex align-items'>
                                    <div className='pr-5'>
                                        <Medico className='margin-auto' />
                                    </div>
                                    <div className='grid align-items font-20 font-bold text-white'>
                                        <div>
                                            <div>Médico</div>
                                            <div>Seguro</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='' >
                                    <H2 color="white">
                                        Seguro, seguro
                                    </H2>
                                    <div className='text-white font-32 font-32-mobile' >
                                        Te ayudamos a contratar el plan de salud ideal para ti, garantizándote la mejor experiencia de principio a fin.
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5}>
                                <div className='form-md'>
                                    <Form />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </div>
    );
}
