import React from "react";
import { useEffect, useState } from "react";
import { Link, Stack, Toolbar, Grid } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Blue from "assets/img/navbar/intermx-blue.svg";
import imageIcon from 'assets/img/image-icon/racing-car.gif';
import {
  AppBar,
  CssBaseline,
  Typography,
  Box,
  IconButton,
  Menu,
  List,
  ListItemButton,
  ListItemText,
  Button,
  MenuList,
  MenuItem,
} from "@mui/material";
//import MenuIcon from "@mui/icons-material/Menu";
import MenuIcon from "../assets/img/icons/hamburguer.svg";
//import ExpandMore from "@mui/icons-material/ExpandMore";
//import ExpandLess from "@mui/icons-material/ExpandLess";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import BannerRenovacion from 'components/banners/BannerRenovacion'
//import TagManager from "react-gtm-module";

export default function DefaultNavbar() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [openL, setOpenL] = useState(false);
  const open = Boolean(anchorEl);
  const [openAlert, setOpenAlert] = useState(false);
  //const [closeBanner, setCloseBanner] = useState(false);
  const history = useHistory();

  const [showCmp, setShowCmp] = useState(false)


  const closePublish = async () => {
    try {
      sessionStorage.setItem("closePublish", JSON.stringify({ closePublish: 1 }))
    } catch (e) {
      console.log("Error set LocalStorage::", e)
    }
  }

  const getSatus = async () => {
    try {
      let responseLocal = await sessionStorage.getItem("closePublish");
      responseLocal = JSON.parse(responseLocal)
      if (responseLocal) {
        setOpenAlert(false);
      }
    } catch (e) {
      console.log("Error get LocalStorage::", e)
    }
    /* try {
      let responseBanner = await sessionStorage.getItem("closeBanner");
      responseBanner = JSON.parse(responseBanner)
    } catch (e) {
      console.log("Error get LocalStorage::", e)
    } */
  }

  useEffect(() => {
    getSatus()
  }, [])

/*   const getStatusBanner = () => {
    try {
      let bannerStorage = sessionStorage.getItem('closeBanner');
      let response = JSON.parse(bannerStorage);
      if (response) {
        setCloseBanner(true);
      }
    } catch {
      console.log('error');
    }
  } */

  /* useEffect(() => {
    getStatusBanner();
  }, [sessionStorage.getItem('closeBanner')]) */


  const handleMovil = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  /* const handleClick = () => {
    setOpenL(!openL);
  }; */

  const handleOpenSeguros = (e) => {
    setAnchorEl2(e.currentTarget);
    window.dataLayer.push({
      event: 'ev_menu', //Static data
      option: 'Seguros', //Dynamic data
    });
  }; 

   const handleCloseSeguros = () => {
    setAnchorEl2(null);
  };

const handleTRUS = (e) => {
    window.dataLayer.push({
      event: 'ev_menu', //Static data
      option: 'Te Regalamos un Seguro', //Dynamic data
    });
  };
  const handleCocheSeguro = (e) => {
    window.dataLayer.push({
      event: 'ev_menu', //Static data
      option: 'Coche Seguro', //Dynamic data
    });
  };

  const handleMedicoSeguro = (e) => {
    window.dataLayer.push({
      event: 'ev_menu', //Static data
      option: 'Medico Seguro', //Dynamic data
    });
  };

  const handleMascotaSegura = (e) => {
    window.dataLayer.push({
      event: 'ev_menu', //Static data
      option: 'Mascota Segura', //Dynamic data
    });
  };

  const handleABCdelosSeguros = (e) => {
    history.push('/blog');
    window.dataLayer.push({
      event: 'ev_menu', //Static data
      option: 'ABC de los seguros', //Dynamic data
    });
  };

  const handlePreguntas = (e) => {
    history.push('/preguntas-frecuentes');
    window.dataLayer.push({
      event: 'ev_menu', //Static data
      option: 'Preguntas frecuentes', //Dynamic data
    });
  };

  const handlePromociones = (e) => {
    history.push('/promotions');
    window.dataLayer.push({
      event: 'ev_menu', //Static data
      option: 'Promociones', //Dynamic data
    });
  };

  const ssClosePublish = JSON.parse(sessionStorage.getItem('closePublish'));

  // Si se cambia el cintillo se debe cambiar parametros de eventCategory, eventAction y eventLabel del view, select y close

  useEffect(() => {
    if (!ssClosePublish /* && closeBanner */ && openAlert) {
      window.dataLayer.push({
        event: 'InterMx_cintillo_view',
        eventCategory: 'Raceweek_contrata_seguro',
        eventAction: 'Raceweek2023',
        eventLabel: '30_%_off',
        page_url: window.location.href
      })
    }
  }, [!ssClosePublish])

  const handleTerminos = (e) => {
    e.preventDefault();
    window.dataLayer.push({
      event: 'InterMx_cintillo_select',
      eventCategory: 'Raceweek_contrata_seguro',
      eventAction: 'Raceweek2023',
      eventLabel: '30_%_off',
      page_url: window.location.href
    })
    history.push('/terminos-condiciones');
  };


  return (
    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
      <CssBaseline />
      <AppBar position="fixed" style={{ backgroundColor: '#FFFF' }}>
        <BannerRenovacion setShowCmp={setShowCmp} showCmp={showCmp} />
        {openAlert &&
          /* (closeBanner && openAlert) && */
          <Box sx={{
            width: '100%', marginBottom: 0, position: 'static',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#F4F4F4'
          }}>
            <div style={{
              display: 'flex',
              width: '100%',
              height: 100,
              /* alignItems:'center', */
              justifyContent: 'center',
              background: '#002C81'
            }}>
              <Grid container
                sx={{
                  paddingLeft: { xs: 1.875, sm: 1.875, md: 4.375, lg: 18.75, xl: 18.75 },
                  paddingRight: { xs: 1.875, sm: 1.875, md: 4.375, lg: 18.75, xl: 18.75 }
                }}>
                <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                  <Typography sx={{
                    width: '100%',
                    paddingTop: { xs: "36px", sm: "36px", md: "41px", lg: "41px", xl: "41px" },
                    fontFamily: 'Montserrat',
                    textAlign: 'center',
                    color: '#FFFF',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: { xs: "14px", sm: "14px", md: "18px", lg: "18px", xl: "24px" },
                    lineHeight: 'normal',
                    letterSpacing: '0.5px',
                    fontFeatureSettings: { clig: 'off', liga: 'off' },
                  }}>Esta Raceweek contrata tu seguro de coche con hasta un <span style={{ fontWeight: '900', color: '#FFD60E' }}>30% off</span>
                  </Typography>
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    /* spacing={2} */
                    sx={{ paddingTop: { xs: "6px", sm: "6px", md: "6px", lg: "6px", xl: "9px" } }}
                  >
                    <img src={imageIcon} style={{ height: 64, width: 64 }} alt="" />
                  </Stack>
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1} xl={1}
                  sx={{ textAlign: 'end' }}>
                  <IconButton
                    sx={{
                      /* top: {xs: "8px",sm: "8px", md:"10px",lg:"10px",xl:"20px"}, */
                      /* right:{xs: "10px",sm: "10px", md:"10px",lg:"10px",xl:"150px"}, */
                      flexShrink: 0,
                      color: '#FFFF',
                      left: 0,
                      padding: 0,
                    }}
                    aria-label="close"
                    onClick={() => {
                      setOpenAlert(false);
                      window.dataLayer.push({
                        'event': 'InterMx_cintillo_close',
                        'eventCategory': 'Raceweek_contrata_seguro',
                        'eventAction': 'Raceweek2023',
                        'eventLabel': '30_%_off',
                        'page_url': window.location.href
                      })
                      closePublish();
                    }}>
                    <CloseIcon fontSize="inherit" style={{ width: 16, height: 16 }} />
                  </IconButton>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'center' }}>
                  <Typography
                    sx={{
                      width: '100%',
                      fontFamily: 'Montserrat',
                      textAlign: 'center',
                      color: '#FFFF',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: { xs: "8px", sm: "8px", md: "10px", lg: "10px", xl: "10px" },
                      paddingBottom: { xs: 0.75, sm: 0.75, md: 1, lg: 0.75, xl: 0.75 },
                      lineHeight: 'normal',
                      letterSpacing: '0.5px',
                      fontFeatureSettings: { clig: 'off', liga: 'off' },
                    }}><a href="" onClick={handleTerminos}>Consulta términos y condiciones</a>
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Box>
        }
        <Toolbar style={{ minHeight: '56px' }}
          sx={{
            paddingLeft: { xs: 1.875, sm: 1.875, md: 4.375, lg: 12.5, xl: 12.5 },
            paddingRight: { xs: 1.875, sm: 1.875, md: 4.375, lg: 12.5, xl: 12.5 }
          }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', textAlign: 'center' }}>
            <Box style={{minWidth:'114px',height:'56px',alignItems:'center',display:'flex'}}>
              <Link
                underline='none'
                href='https://inter.mx'
              >
                <img src={Blue} alt='' />
              </Link>
            </Box>
            
            <Grid container spacing={3} sx={{ alignItems: 'center', margin: 0, padding: 0 }}>
            <Grid item lg={2.5} xl={2.5} style={{ padding: 0 }}>
                <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' } }}>
                  <Link
                    underline='none'
                    href='https://teregalamosunseguro.inter.mx/'
                    /* onClick={() => window.dataLayer.push({
                      event: 'main_menu_cs',
                      option: 'Blog'
                    })} */
                    onClick={handleTRUS}>
                    <span className='text-header'>Te Regalamos un Seguro</span>
                  </Link>
                </Box>
              </Grid>
              <Grid item lg={1.5} xl={1.5} style={{ padding: 0 }}>
                <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' } }}>
                  <Link
                    underline='none'
                    href='https://cocheseguro.inter.mx/inicio'
                    /* onClick={() => window.dataLayer.push({
                      event: 'main_menu_cs',
                      option: 'Blog'
                    })} */
                    onClick={handleCocheSeguro}>
                    <span className='text-header'>Coche Seguro</span>
                  </Link>
                </Box>
              </Grid>
              <Grid item lg={1.5} xl={1.5} style={{ padding: 0 }}>
                <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' } }}>
                  <Link
                    underline='none'
                    href='https://motoseguro.inter.mx/'
                    /* onClick={() => window.dataLayer.push({
                      event: 'main_menu_cs',
                      option: 'Blog'
                    })} */
                    onClick={handleCocheSeguro}>
                    <span className='text-header'>Moto Seguro</span>
                  </Link>
                </Box>
              </Grid>
              <Grid item lg={2.0} xl={2.0} style={{ padding: 0 }}>
                <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' } }}>
                  <Link
                    underline='none'
                    href='https://medicoseguro.inter.mx/inicio'
                    onClick={handleMedicoSeguro}>
                    <span className='text-header'>Médico Seguro</span>
                  </Link>
                </Box>
              </Grid>
              <Grid item lg={2.0} xl={2.0} style={{ padding: 0 }}>
                <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' } }}>
                  <Link
                    underline='none'
                    href='https://mascotasegura.inter.mx/'
                    onClick={handleMascotaSegura}>
                    <span className='text-header'>Mascota Segura</span>
                  </Link>
                </Box>
              </Grid>
              <Grid item lg={1.5} xl={1.5} style={{ padding: 0 }}>
              { <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' } }}>
                    <Button
                      aria-controls='menu'
                      aria-haspopup='true'
                      onClick={handleOpenSeguros}
                    >
                      <span className='text-header' style={{ textTransform: 'capitalize' }}>Conocer más</span>
                    </Button>
                    <Menu
                      style={{ marginTop: '5' }}
                      id='menu'
                      onClose={handleCloseSeguros}
                      anchorEl={anchorEl2}
                      open={Boolean(anchorEl2)}
                    >
                      <MenuList>
                        <MenuItem>
                          <Link
                            underline='none'
                            href=''
                            onClick={handlePromociones}>
                            <span className='text-header'>Promociones</span>
                          </Link>
                        </MenuItem>
                        <MenuItem>
                          <Link
                            underline='none'
                            href=''
                            onClick={handleABCdelosSeguros}>
                            <span className='text-header'>ABC de los seguros</span>
                          </Link>
                        </MenuItem>
                        <MenuItem>
                          <Link
                            underline='none'
                            onClick={handlePreguntas}
                            href=''>
                            <span className='text-header'>Preguntas frecuentes</span>
                          </Link>
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Box>}
                </Grid>
              {/*<Grid item lg={1.6} xl={1.6} style={{ padding: 0 }}>
                <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' } }}>
                  <Link
                    underline='none'
                    href=''
                    onClick={handlePromociones}>
                    <span className='text-header'>Promociones</span>
                  </Link>
                </Box>
              </Grid>
              <Grid item lg={2.3} xl={2.3} style={{ padding: 0 }}>
                <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' } }}>
                  <Link
                    underline='none'
                    href=''
                    onClick={handleABCdelosSeguros}>
                    <span className='text-header'>ABC de los seguros</span>
                  </Link>
                </Box>
              </Grid>
              <Grid item lg={2.3} xl={2.3} style={{ padding: 0 }}>
                <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' } }}>
                  <Link
                    underline='none'
                    onClick={handlePreguntas}
                    href=''>
                    <span className='text-header'>Preguntas frecuentes</span>
                  </Link>
                </Box>
              </Grid>*/}
            </Grid>
            <Box sx={{ display: { xs: 'block', sm: 'block', md: 'block', lg: 'none', xl: 'none' } }}>
              <IconButton
                style={{padding:'0px',minWidth:'25.5px'}}
                id="basic-button"
                aria-controls="basic-menu"
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleMovil}>
                <img src={MenuIcon} alt="" style={{width:'35px',height:'35px'}} />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <List
                  sx={{ width: '100%', maxWidth: 360, paddingTop: 2, paddingBottom: 2, gap: 5, justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column' }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                >
                  {/* <ListItemButton onClick={handleClick}>
                          <ListItemText primary="Seguros" />
                          {openL ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openL} timeout="auto" unmountOnExit className='collapse-list'>
                          <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 4 }}>
                              <ListItemText>
                                <Link
                                  color='Black'
                                  underline='none'
                                  href='https://cocheseguro.inter.mx/inicio'>
                                  <span className="textMovil">Coche Seguro</span>
                                </Link>
                              </ListItemText>
                              <ListItemText></ListItemText>
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4 }}>
                              <ListItemText>
                                <Link
                                  color='Black'
                                  underline='none'
                                  href='https://medicoseguro.inter.mx/inicio'>
                                  <span className="textMovil">M&eacute;dico Seguro</span>
                                </Link>
                              </ListItemText>
                              <ListItemText></ListItemText>
                            </ListItemButton> */}
                  {/* <ListItemButton sx={{ pl: 4 }}>
                              <ListItemText>
                                <Link
                                  color='Black'
                                  underline='none'
                                  href='https://productos.inter.mx/vida-segura'>
                                  <span className="textMovil">Vida Segura</span>
                                </Link>
                              </ListItemText>
                              <ListItemText></ListItemText>
                            </ListItemButton> */}
                  {/* <ListItemButton sx={{ pl: 4 }}>
                              <ListItemText>
                                <Link
                                  color='Black'
                                  underline='none'
                                  href='https://productos.inter.mx/bien-seguro '>
                                  <span className="textMovil">Bien Seguro</span>
                                </Link>
                              </ListItemText>
                              <ListItemText></ListItemText>
                            </ListItemButton> */}
                  {/* <ListItemButton sx={{ pl: 4 }}>
                              <ListItemText>
                                <Link
                                  color='Black'
                                  underline='none'
                                  href='https://inter.mx/viaje-seguro'>
                                  <span className="textMovil">Viaja Seguro</span>
                                </Link>
                              </ListItemText>
                              <ListItemText></ListItemText>
                            </ListItemButton> */}
                  {/* </List>
                        </Collapse> */}
                <ListItemButton>
                    <ListItemText>
                      <Link
                        color='Black'
                        underline='none'
                        href='https://teregalamosunseguro.inter.mx/'>
                        <span className="textMovil-Menu">Te Regalamos un Seguro</span>
                      </Link>
                    </ListItemText>
                  </ListItemButton>
                  <ListItemButton>
                    <ListItemText>
                      <Link
                        color='Black'
                        underline='none'
                        href='https://motoseguro.inter.mx/'>
                        <span className="textMovil-Menu">Moto Seguro</span>
                      </Link>
                    </ListItemText>
                  </ListItemButton>
                  <ListItemButton>
                    <ListItemText>
                      <Link
                        color='Black'
                        underline='none'
                        href='https://cocheseguro.inter.mx/inicio'>
                        <span className="textMovil-Menu">Coche Seguro</span>
                      </Link>
                    </ListItemText>
                  </ListItemButton>
                  <ListItemButton>
                    <ListItemText>
                      <Link
                        color='Black'
                        underline='none'
                        href='https://medicoseguro.inter.mx/inicio'>
                        <span className="textMovil-Menu">M&eacute;dico Seguro</span>
                      </Link>
                    </ListItemText>
                  </ListItemButton>
                  <ListItemButton>
                    <ListItemText>
                      <Link
                        color='Black'
                        underline='none'
                        href='https://mascotasegura.inter.mx/'>
                        <span className="textMovil-Menu">Mascota Segura</span>
                      </Link>
                    </ListItemText>
                  </ListItemButton>
                  <ListItemButton>
                    <ListItemText>
                      <Link
                        color='Black'
                        underline='none'
                        href=''
                        onClick={handlePromociones}
                      >
                        <span className="textMovil-Menu">Promociones</span>
                      </Link>
                    </ListItemText>
                  </ListItemButton>
                  <ListItemButton>
                    <ListItemText>
                      <Link
                        color='Black'
                        underline='none'
                        href=''
                        onClick={handleABCdelosSeguros}
                      >
                        <span className="textMovil-Menu">ABC de los seguros</span>
                      </Link>
                    </ListItemText>
                  </ListItemButton>
                  <ListItemButton>
                    <ListItemText>
                      <Link
                        color='Black'
                        underline='none'
                        href=''
                        onClick={handlePreguntas}
                      >
                        <span className="textMovil-Menu">Preguntas Frecuentes</span>
                      </Link>
                    </ListItemText>
                  </ListItemButton>
                  {/* <ListItemButton>
                    <ListItemText>
                      <Link
                        color='Black'
                        underline='none'
                        href='https://www.interproteccion.com.mx/'>
                        <span className="textMovil-Menu">Soy Empresa</span>
                      </Link>
                    </ListItemText>
                  </ListItemButton> */}
                </List>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      {/* <Toolbar style={{ minHeight: openAlert && closeBanner ? '156px' : '56px', width: '100%', padding: 0 }} /> */}
      <Toolbar style={{ minHeight: '56px', width: '100%', padding: 0 }} />
    </Box>
  );
}
