import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const TabsNav = ( props ) => {
    const { handleTabChange } = props;
  const [value, setValue] = useState('Todos');

  const handleChange = (event, newValue) => {
    setValue(newValue);
    handleTabChange(newValue);
  };

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <Tabs value={value} onChange={handleChange} centered>
        <Tab label="Todos" value="Todos" />
        <Tab label="Coche" value="Coche" />
        <Tab label="Mascotas" value="Mascota" />
        <Tab label="Salud" value="Salud" />
        <Tab label="Coberturas" value="Cobertura" />
      </Tabs>
    </Box>
  );
}

export default TabsNav;