import { Collapse, FormControl, Grid, MenuItem, Select} from '@mui/material';
import { useState } from 'react';
import { hospitals } from './hospitals';
import { useEffect } from 'react';

export default function Requirements() {
  const plans = ['Esencial', 'Protegido', 'Protegido +', 'Blindado']
  const state = [
    'Aguascalientes',
    'Baja California Norte',
    'Baja California Sur',
    'Campeche',
    'Coahuila',
    'Colima',
    'Chiapas',
    'Chihuahua',
    'Ciudad De México',
    'Durango',
    'Guanajuato',
    'Guerrero',
    'Hidalgo',
    'Jalisco',
    'Edo. De México',
    'Michoacán',
    'Morelos',
    'Nayarit',
    'Nuevo León',
    'Oaxaca',
    'Puebla',
    'Querétaro',
    'Quintana Roo',
    'San Luis Potosí',
    'Sinaloa',
    'Sonora',
    'Tabasco',
    'Tamaulipas',
    'Tlaxcala',
    'Veracruz',
    'Yucatán',
    'Zacatecas'
  ]
  const [selected, setSelected] = useState({
    plan: 'Esencial',
    state: 'Aguascalientes'
  })
  const [hospitalsData, setHospitalsData] = useState([])
  const [checked, setChecked] = useState(false)
  const handleChange = (type) => (event) => {
    setChecked(false)
    setSelected({...selected, [type]: event.target.value});
  };

  useEffect(()=>{
    setHospitalsData(hospitals[selected.state][selected.plan])
    setChecked(true)
  }, [selected])
    return (
        <section className="pb-5 bg-gray-100">
            <div className="container md:w-10/12 mx-auto px-4">
              <div className=''>
                <div className='pb-5 text-white rounded-lg bg-blue-inter'>
                    <Grid container columns={12} justifyContent='center' alignItems='center' direction='row'>
                      <Grid item xs={12} sm={6}>
                        <div className='p-6 text-center' >
                          <div className='font-bold font-32'>¿Qué hospitales incluye mi plan de Seguro de Gastos Médicos?</div>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div>
                          <div className='mx-auto py-6 px-5 flex flex-wrap justify-center items-center' >
                            <div style={{minWidth: '100%'}} >
                              <div className='py-5' >
                                  <div className='bg-white rounded-lg' >
                                  <FormControl fullWidth >
                                    <Select labelId='plan' id='select-plan' value={selected.plan} label='Plan' onChange={handleChange('plan')}>
                                      {plans.map((data, index) => {
                                        return (
                                          <MenuItem key={index} value={data} >{data}</MenuItem>
                                        )
                                      })}
                                    </Select>
                                  </FormControl>
                                  </div>
                                </div>
                                  <div className='bg-white rounded-lg' >
                                    <FormControl fullWidth >
                                      <Select labelId='state' id='select-state' value={selected.state} label='Estado' onChange={handleChange('state')}>
                                        {state.map((data, index) => {
                                          return (
                                            <MenuItem key={index} value={data} >{data}</MenuItem>
                                          )
                                        })}
                                      </Select>
                                    </FormControl>
                                  </div>
                                  <div className='font-20 font-bold py-5' >
                                    Hospitales en {selected.state}
                                  </div>
                                  <Collapse in={checked}>
                                  {hospitalsData.map((data, index) => {
                                    return (
                                      <div key={index} >{data}</div>
                                    )
                                  })}
                                  </Collapse>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                </div>
              </div>
            </div>
        </section>
    );
}
