import { useEffect, useState } from 'react';
import DefaultNavbar from 'components/DefaultNavbar';
import DefaultFooter from 'components/DefaultFooter';
import Header from 'components/PreguntasFrecuentes/Header';
import Buscador from 'components/PreguntasFrecuentes/Buscador';

export default function PreguntasFrecuentes() {
  const [currentTab, setCurrentTab] = useState('Todos');
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.scrollTo(0, 0);
    // Función para actualizar el estado cuando cambia el tamaño de la ventana
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    // Agregar un listener para el evento de cambio de tamaño de ventana
    window.addEventListener("resize", handleResize);
    // Limpiar el listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const onSelectTabOption = (option) => {
      setCurrentTab(option);
    };

    return (
        <>
            <DefaultNavbar />
            <main>
              <Header width={width} />
              <Buscador onSelectTabOption={onSelectTabOption} currentTab={currentTab} width={width} />
            </main>
            <DefaultFooter />
        </>
    );
}
