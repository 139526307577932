import { Grid } from '@mui/material';
import { ReactComponent as Expansion } from 'assets/img/magazine/expansion.svg';
import { ReactComponent as Forbes } from 'assets/img/magazine/forbes.svg';
import { ReactComponent as Economista } from 'assets/img/magazine/economist.svg';
import Marquee from "react-fast-marquee";

export default function Legend () {
  return (
    <section className="pb-5 pt-1 bg-gray-100" >
      <div className="container mx-auto max-w-full">
        <div className='bg-white uppercase text-center' style={{fontSize: 22, color: '#212121'}}>
          <div className="flex flex-wrap mt-5 md:w-10/12 mx-auto" >
           <Marquee speed={40} pauseOnHover >
              <Grid container columns={6} padding={2} alignItems='center' spacing={0}>
                  <Grid xs={2} sm={2} md={2} padding={1}>
                    <a href='https://expansion.mx/empresas/2021/09/07/contratar-un-seguro-de-auto-en-linea' target='_blank' rel="noreferrer"  >
                      <Expansion className='margin-auto svg-autofill' />
                    </a>
                  </Grid>
                  <Grid xs={2} sm={2} md={2} padding={1} >
                    <a href='https://www.forbes.com.mx/brand-voice/ad-interproteccion-marca-mexicana-apuesta-en-checo-perez/' target='_blank' rel="noreferrer" >
                      <Forbes className='margin-auto svg-autofill' />
                    </a>
                  </Grid>
                  <Grid xs={2} sm={2} md={2} padding={1} >
                    <a href='https://www.eleconomista.com.mx/sectorfinanciero/Cuanto-cuesta-NO-estar-asegurado-ante-el-COVID-19-20210125-0044.html' target='_blank' rel="noreferrer" >
                      <Economista className='margin-auto svg-autofill' />
                    </a>
                  </Grid>
              </Grid>
              </Marquee>
          </div>
        </div>
      </div>
    </section>
  )
}