import React, { useState } from "react";
import { Pagination } from "@mui/material";

const PaginationComponent = (props) => {
  const { data, showData, setCurrentPage } = props;
  const [page, setPage] = useState(1);

  // Manejador del cambio de página
  const handlePageChange = (event, value) => {
    setPage(value);
    setCurrentPage(value);
  };
  const totalPages = data?.length ? Math.ceil(data.length / showData) : 1;

  return (
    <div className="flex justify-center pb-8">
      <Pagination
        count={totalPages}
        color="primary"
        page={page}
        onChange={handlePageChange}
      />
    </div>
  );
};

export default PaginationComponent;
