import React, { useEffect } from "react";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./Modal.css";
import TagManager from "react-gtm-module";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Modal = ({ images, onClose }) => {
  const settings = {
    infinite: true,
    slidesToShow: 1, // Cambia este número según la cantidad de imágenes que desees mostrar a la vez
    slidesToScroll: 1,
    autoplay: true,
    speed: 2500, // Velocidad de transición en milisegundos
    autoplaySpeed: 3500, // Tiempo entre cada transición en milisegundos
    arrows: true, // Mostrar flechas de navegación
  };

  const handleImageClick = (type, data) => {
    // Cambia la ubicación de la página al hacer clic en la imagen
    window.dataLayer.push({
      event: 'InterMx_banner_select',
      eventCategory: data.category,
      eventAction: data.name,
      eventLabel: data.promotion,
      page_url: window.location.href
    });
    switch (type) {
      case 'coche':
        window.open('https://cocheseguro.inter.mx/inicio', '_blank');
        break;
      case 'gmm':
        window.open('https://medicoseguro.inter.mx/inicio', '_blank');
        break;
      case 'tr1s':
          window.open('https://teregalamosunseguro.inter.mx/', '_blank');
          break;
      default:
        break;
    }
  };

  const handleCloseClick = () => {
    onClose();
    TagManager.dataLayer({
      dataLayer: {
        event: 'InterMx_banner_close',
        items: 
          images.map((img) => (
            {
              eventCategory: img.category,
              eventAction: img.name,
              eventLabel: img.promotion,
            }
          )),
        page_url: window.location.href
      },
    });
  };

  const ssCloseBanner = JSON.parse(sessionStorage.getItem('closeBanner'));

  useEffect(() => {
    if (!ssCloseBanner) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'InterMx_banner_view',
          items: 
          images.map((img) => (
            {
              eventCategory: img.category,
              eventAction: img.name,
              eventLabel: img.promotion,
            }
          )),
          page_url: window.location.href
        },
      });
    }
  }, [!ssCloseBanner])

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-button-close mb-2">
          <IconButton
            type="button"
            sx={{ p: "12px", color: "#212121" }}
            aria-label="closeModal"
            onClick={handleCloseClick}
          >
            <CloseIcon sx={{ fontSize: "32px" }} />
          </IconButton>
        </div>
        <div className="carousel-container">
          <Slider {...settings}>
            {images.map((data, index) => {
              return (
                  <img
                    src={data.image}
                    alt={`Anuncio ${index}`}
                    className="carousel-image"
                    onClick={() => handleImageClick(data.link, data)}
                    key={index}
                  />
              );
            })}
          </Slider>
        </div>
        {/* <img src={imageUrl} alt="Anuncio" className="modal-image" onClick={handleImageClick}/> */}
      </div>
    </div>
  );
};

export default Modal;
