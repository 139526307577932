import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';
import { ReactComponent as LogoGMM } from 'assets/img/icons/product/gmm.svg';
import { ReactComponent as LogoCoche } from 'assets/img/icons/product/coche.svg';
import { ReactComponent as LogoVida } from 'assets/img/icons/product/vida.svg';
import { ReactComponent as LogoBien } from 'assets/img/icons/product/bien.svg';
import { ReactComponent as LogoMascota } from 'assets/img/icons/product/mascota.svg';
import { ReactComponent as LogoVigo } from 'assets/img/icons/product/vigo.svg';
import Marquee from 'react-fast-marquee';

export default function FooterProducts() {
    const location = useLocation();
    return (
    <section className="pb-5 bg-gray-100">
        <div className="container md:w-10/12 mx-auto px-4">
            <div className='px-4 bg-white rounded-lg text-center' >
                <div className='p-5 font-bold font-28' >¿Quieres proteger algo más?</div>
                <div className='pb-5'>Tenemos estos seguros pensados para ti</div>
                <div className='mproducts-lg pb-5'>
                    <Grid container columns={5}>
                        { !location.pathname.includes('mascota-segura') && <Grid item xs={1} >
                        <a href='https://mascotasegura.inter.mx/seguro-mascota/landing' target='_blank' rel="noreferrer"  >
                            <div className='p-2' >
                                <div className='flex flex-wrap align-items justify-center rounded-2xl p-5 icon-product' >
                                    <div className='pt-5' ><LogoMascota className='mx-auto'/></div>
                                    <div className='p-5 font-bold' >Mascota Segura</div>
                                </div>
                            </div>
                        </a>
                        </Grid>}
                        { !location.pathname.includes('medico-seguro') && <Grid item xs={1} > 
                        <a href='https://medicoseguro.inter.mx/' target='_blank' rel="noreferrer"  >
                            <div className='p-2' >
                                <div className='flex flex-wrap align-items justify-center rounded-2xl p-5 icon-product' >
                                    <div className='pt-5' ><LogoGMM className='mx-auto'/></div>
                                    <div className='font-bold' >Médico Seguro</div>
                                </div>
                            </div>
                        </a>
                        </Grid>}
                        { !location.pathname.includes('coche-seguro') &&
                        <Grid item xs={1} >
                        <a href='https://cocheseguro.inter.mx/inicio' target='_blank' rel="noreferrer"  >
                            <div className='p-2'>
                                <div className='flex flex-wrap align-items justify-center rounded-2xl p-5 icon-product' >
                                    <div className='pt-5' ><LogoCoche className='mx-auto'/></div>
                                    <div className='font-bold' >Coche Seguro</div>
                                </div>
                            </div>
                        </a>
                        </Grid>}
                        {!location.pathname.includes('vida-segura') &&
                        <Grid item xs={1}>
                        <a href='https://productos.inter.mx/vida-segura' target='_blank' rel="noreferrer"  >
                            <div className='p-2' >
                                <div className='flex flex-wrap align-items justify-center rounded-2xl p-5 icon-product' >
                                    <div className='pt-5' ><LogoVida className='mx-auto'/></div>
                                    <div className='p-5 font-bold' >Vida Segura</div>
                                </div>
                            </div>
                        </a>
                        </Grid>}
                        {/* { !location.pathname.includes('bien-seguro') &&
                        <Grid item xs={1} >
                        <a href='https://productos.inter.mx/bien-seguro' target='_blank' rel="noreferrer"  >
                            <div className='p-2' >
                                <div className='flex flex-wrap align-items justify-center rounded-2xl p-5 icon-product' >
                                    <div className='pt-5' ><LogoBien className='mx-auto'/></div>
                                    <div className='p-5 font-bold' >Bien Seguro</div>
                                </div>
                            </div>
                        </a>
                        </Grid>}
                        { !location.pathname.includes('viaje-seguro') &&
                        <Grid item xs={1} >
                            <a href='https://vigo.inter.mx/landing' target='_blank' rel="noreferrer"  >
                                <div className='p-2' >
                                    <div className='flex flex-wrap align-items justify-center rounded-2xl p-5 icon-product' >
                                        <div className='pt-5' ><LogoVigo className='mx-auto'/></div>
                                        <div className='p-5 font-bold' >Viaje seguro</div>
                                    </div>
                                </div>
                            </a>
                        </Grid>} */}
                    </Grid>
                </div>
                <div className='mproducts-sm' >
                    <Marquee speed={40} pauseOnHover gradientWidth={50}>
                        <Grid container columns={5}>
                            {! location.pathname.includes('mascota-segura') && <Grid item xs={1}>
                            <a href='https://mascotasegura.inter.mx/seguro-mascota/landing' target='_blank' rel="noreferrer"  >
                                <div className='p-2' >
                                    <div className='flex flex-wrap align-items justify-center rounded-2xl p-5 icon-product' >
                                        <div className='pt-5' ><LogoMascota className='mx-auto'/></div>
                                        <div className='font-bold' >Mascota Segura</div>
                                    </div>
                                </div>
                                </a>
                            </Grid>}
                            { !location.pathname.includes('medico-seguro') &&
                            <Grid item xs={1}>
                                <a href='https://medicoseguro.inter.mx/' target='_blank' rel="noreferrer"  >
                                <div className='p-2' >
                                    <div className='flex flex-wrap align-items justify-center rounded-2xl p-5 icon-product' >
                                        <div className='pt-5' ><LogoGMM className='mx-auto'/></div>
                                        <div className='font-bold' >Médico Seguro</div>
                                    </div>
                                </div>
                                </a>
                            </Grid>}
                            { !location.pathname.includes('coche-seguro') && <Grid item xs={1} >
                            <a href='https://cocheseguro.inter.mx/inicio' target='_blank' rel="noreferrer"  >
                                <div className='p-2' >
                                    <div className='flex flex-wrap align-items justify-center rounded-2xl p-5 icon-product' >
                                        <div className='pt-5' ><LogoCoche className='mx-auto'/></div>
                                        <div className='font-bold' >Coche Seguro</div>
                                    </div>
                                </div>
                            </a>
                            </Grid>}
                            { !location.pathname.includes('vida-segura') && <Grid item xs={1}>
                            <a href='https://productos.inter.mx/vida-segura' target='_blank' rel="noreferrer"  >
                                <div className='p-2' >
                                    <div className='flex flex-wrap align-items justify-center rounded-2xl p-5 icon-product' >
                                        <div className='pt-5' ><LogoVida className='mx-auto'/></div>
                                        <div className='p-5 font-bold' >Vida segura</div>
                                    </div>
                                </div>
                            </a>
                            </Grid>}
                            { !location.pathname.includes('bien-seguro') && <Grid item xs={1} >
                            <a href='https://productos.inter.mx/bien-seguro' target='_blank' rel="noreferrer"  >
                                <div className='p-2' >
                                    <div className='flex flex-wrap align-items justify-center rounded-2xl p-5 icon-product' >
                                        <div className='pt-5' ><LogoBien className='mx-auto'/></div>
                                        <div className='p-5 font-bold' >Bien seguro</div>
                                    </div>
                                </div>
                            </a>
                            </Grid>}
                            {!location.pathname.includes('viaje-seguro') && <Grid item xs={1} >
                                <Link to="/viaje-seguro" rel="noreferrer" target='_blank' >
                                    <div className='p-2' >
                                        <div className='flex flex-wrap align-items justify-center rounded-2xl p-5 icon-product' >
                                            <div className='pt-5' ><LogoVigo className='mx-auto'/></div>
                                            <div className='p-5 font-bold' >Viaje seguro</div>
                                        </div>
                                    </div>
                                </Link>
                            </Grid>}
                        </Grid>
                    </Marquee>
                </div>
            </div>
        </div>
    </section>);
}
