import { ReactComponent as Car } from "assets/img/card/car.svg";
import { Button } from "@mui/material";
import TagManager from 'react-gtm-module';

export default function Coche() {
  const tag = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'USA_Landing',
        eventCategory: 'USA_Landing',
        eventAction: 'select_seguro',
        eventLabel: 'cocheSeguro'
      },
    });
  };
  return (
    <div className="insuranceCard">
      <div className="insuranceObjects">
        <div className="">
          <div className="flex justify-center items-center">
            <Car />
          </div>
          <div className="flex flex-col justify-center items-center pt-4">
            <div className="paraphsCards">Coche</div>
            <div className="paraphsCards">Seguro</div>
          </div>
        </div>
        <div className="referenceInsurance py-8">
          El mejor seguro de coche, por el precio más justo.
        </div>
        <Button
          style={{
            backgroundColor: "#039ecc",
            width: "212px",
            height: "48px",
            borderRadius: "8px",
            fontWeight: 600,
            fontSize: "16px",
            textAlign: "center",
            color: "#ffffff",
          }}
          onClick={tag}
          href="https://cocheseguro.inter.mx/inicio"
          target="_blank"
        >
          LO QUIERO
        </Button>

        <p className="lastParaph">¡Conoce nuestras promos!</p>
      </div>
    </div>
  );
}
