import {scroller} from 'react-scroll';
import { Button, Grid, Box, Stack, Toolbar } from '@mui/material';
import Typography from '@mui/material/Typography';
import VideoJuanPa from "../../assets/img/videos/videoJuanPa.gif";

export default function Header() {

    const handledScroll = () => {
        let screen = parseInt(window.screen.width);
        let scrollOffset = '';
        switch(true){
            case screen <= 320 : //x-small-320px
                scrollOffset=240;
            break;
            case screen > 320 && screen < 576 : //x-small
                scrollOffset=0;
                break;
            case screen > 576 && screen < 768 : //small
                scrollOffset=-40;
                break;
            case screen >= 768 && screen < 992 : //medium
                scrollOffset=-150;
                break;
            case screen >= 992: //large, extra-large.
                scrollOffset=-180;
                break; 
        }
        scroller.scrollTo("pickInsurance", {
         duration: 800,
         delay: 50,
         smooth: true,
         offset: scrollOffset,
       }
     )}

    return (
        <Box sx={{
            paddingLeft: { xs: 1.875, sm: 1.875, md: 4.375, lg: 18.75, xl: 18.75 },
            paddingRight: { xs: 1.875, sm: 1.875, md: 4.375, lg: 18.75, xl: 18.75 },
            paddingTop: { xs: 0, sm: 0, md: 0, lg: 10, xl: 10 },
            paddingBottom: { xs: '0px', sm: '0px', md: '0px', lg: '50px', xl: '50px' },
        }}>
            <Grid container  sx={{ alignItems: 'center', textAlign: 'center', justifyContent:'center'}}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Typography sx={{
                        color: '#039ECC',
                        textAlign: 'center',
                        paddingTop: { xs: '40px', sm: '40px', md: '40px', lg: '0px', xl: '0px' },
                        fontFeatureSettings: { clig: 'off', liga: 'off' },
                        fontFamily: 'Montserrat',
                        fontSize: { xs: '36px', sm: '36px', md: '36px', lg: '56px', xl: '56px' },
                        fontStyle: 'normal',
                        fontWeight: 900,
                        lineHeight: 'normal',
                        letterSpacing: '0.5px',
                    }}><strong>En inter.mx le quitamos lo complicado a los seguros</strong>
                    </Typography>
                    <Typography sx={{
                        color: '#212121',
                        textAlign: 'center',
                        paddingTop: '40px',
                        fontFeatureSettings: { clig: 'off', liga: 'off' },
                        fontFamily: 'Montserrat',
                        fontSize: '28px',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        lineHeight: 'normal',
                        letterSpacing: '0.5px',
                    }}>
                        Protege lo que más quieres fácil y rápido
                    </Typography>
                    <Box
                        sx={{
                            paddingTop: '40px',

                        }}>
                        <Button variant="contained"
                            sx={{
                                backgroundColor: '#039ECC',
                                textAlign: 'center',
                                fontFeatureSettings: { clig: 'off', liga: 'off' },
                                fontFamily: 'Montserrat',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                lineHeight: 'normal',
                                gap:'10px',
                                borderRadius:'8px',
                                padding:'14px 16px',
                                textTransform: "uppercase !important",
                                ":hover": { backgroundColor: '#026E8E', color: '#FFFF' },
                            }}
                            onClick={handledScroll}
                        >Checa nuestros seguros
                        </Button>
                        
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Box sx={{ textAlign: "center",justifyContent:'center',
                        paddingTop: {xs:'40px',sm:'40px',md:'40px',lg:'0px',xl:'0px'}    
                        }}>
                        <img src={VideoJuanPa} />
                    </Box>
                </Grid>
            </Grid >
        </Box>
    )
};