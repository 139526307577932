import { useEffect } from 'react';

export default function ModoCheco() {
    useEffect(() => {
        window.location.href = "https://modocheco.inter.mx";
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
        <div id="root"></div>
        </>
    );
}
