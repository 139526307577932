import { Typography, Box, Stack} from "@mui/material";
import intermxIcon from 'assets/img/icons/intermx.svg';
import chatIcon from 'assets/img/icons/chatIcon.svg';

export default function aboutUS() {
    return (
        <Box sx={{
            display: 'flex', width: '100%', flexDirection: 'column', background: '#039ECC', justifyContent: 'center',
            paddingLeft: { xs: 1.875, sm: 15.625, md: 15.625, lg: 43.125, xl: 43.125 },
            paddingRight: { xs: 1.875, sm: 15.625, md: 15.625, lg: 43.125, xl: 43.125 }
            }}>
            <Stack sx={{paddingTop:'24px',alignItems:'center'}}>
                <img src={intermxIcon} style={{width:'195px',height:'76.2px'}}></img>
            </Stack>
            <Typography sx={{
                paddingTop:{sm:'24px',xs:'24px',md:'24px',lg:'40px',xl:'40px'},
                color: '#FFFF',
                textAlign: 'center',
                fontFamily: 'Montserrat',
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: 'normal',
            }}><h2>¿Quiénes somos?</h2>
            </Typography>
            <Typography sx={{
                paddingTop: '40px',
                /* textAlign:'justify', */
                color: '#FFFF',
                fontFamily: 'Montserrat',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
            }}>¡Hola! Somos el bróker digital que quiere acercar los seguros a todos los mexicanos. Te ofrecemos una experiencia rápida, sencilla e innovadora. ¡Bienvenido a inter.mx!
            </Typography>
            <Stack sx={{paddingTop:'40px',alignItems:'center'}}>
                <img src={chatIcon} style={{width:'76px',height:'85px'}}></img>
            </Stack>
            <Typography sx={{
                paddingTop: '40px',
                color: '#FFFF',
                textAlign: 'center',
                fontFamily: 'Montserrat',
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: 'normal',
            }}><h2>¿Qué es un broker de seguros digital?</h2>
            </Typography>
            <Typography sx={{
                paddingTop: '40px',
                paddingBottom: '24px',
                /* textAlign:'justify', */
                color: '#FFFF',
                fontFamily: 'Montserrat',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
            }}>Somos un equipo de expertos en seguros que quiere facilitarte la vida. Nuestra misión es hacer que los seguros sean accesibles y fáciles para ti. Estamos aquí para ser tu aliado, brindarte tranquilidad, seguridad y cuidar lo que más valoras.
            </Typography>
        </Box >
    );
}