import React from 'react';
import MedicoSeguro from '../../assets/img/landing/GIFMedico.gif';
import Medic from '../../assets/img/insurances/MedicoSeguro.svg';
import { Box, Typography, Grid, Stack, Button, } from '@mui/material';

export default function MedicalInsurance() {
    return (
        <Box
            sx={{
                width: '100%',
                height:{xs:'690px',sm:'450px',md:'450px',lg:'590px',xl:'590px'},
                display: 'flex',
                alignItems: 'center',
                margin: 0,
                paddingLeft: { xs: 1.875, sm: 1.875, md: 4.375, lg: 18.75, xl: 18.75 },
                paddingRight: { xs: 1.875, sm: 1.875, md: 4.375, lg: 18.75, xl: 18.75 },
            }}>
            <Grid container
                style={{padding:0}}
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems:'center',
                }}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            width:{xs:'250px',sm:'250px',md:'361.616px',lg:'470px',xl:'470px'},
                            height:{xs:'276.549px',sm:'276.549px',md:'400px',lg:'520px',xl:'520px'},
                            display:'block',
                            margin:'auto',
                            alignContent:{xs:'start',sm:'start',md:'center',lg:'center',xl:'center'}
                        }}>
                        <img src={MedicoSeguro} />
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Box
                        sx={{
                            alignSelf: 'center',
                            paddingTop:{xs:'40px',sm:'0px',md:'0px',lg:'0px',xl:'0px'},
                        }}>
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            textAlign="center"
                            height={'100%'}>
                            <img src={Medic} />
                        </Stack>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                paddingTop:'40px',
                            }}>
                            <Box sx={{ display: 'flex',paddingLeft:{md:'16px',lg:'24px',xl:'24px'} }}>
                                <Typography
                                    sx={{
                                        color: '#212121',
                                        fontFamily: 'Montserrat',
                                        fontSize: '22px',
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        lineHeight: 'normal',
                                    }}>
                                    Con Médico Seguro contrata el plan de salud ideal y disfruta de un <b>seguro de gastos médicos</b> con las mejores coberturas para ti y tus seres queridos.
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex',paddingBottom:'40px',paddingLeft:{md:'16px',lg:'24px',xl:'24px'} }}>
                                <Typography 
                                sx={{
                                    color: '#039ECC',
                                    fontFamily: 'Montserrat',
                                    fontSize: '20px',
                                    fontStyle: 'normal',
                                    fontWeight: 700,
                                    lineHeight: 'normal',
                                    paddingTop:'16px'
                                }}>
                                    Además, ¡Obtendrás más beneficios a un mejor costo!
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex',justifyContent:'center' }}>
                                <Button variant="contained" sx={{
                                    background: '#039ECC',
                                    color: '#FFFF',
                                    textAlign: 'center',
                                    fontFeatureSettings: { clig: 'off', liga: 'off' },
                                    fontFamily: 'Montserrat',
                                    fontSize: '16px',
                                    fontStyle: 'normal',
                                    fontWeight: 600,
                                    padding: '14px 16px',
                                    gap: '10px',
                                    borderRadius:'8px',
                                    lineHeight: 'normal',
                                    textTransform: "uppercase !important",
                                    ":hover": { backgroundColor: '#026E8E', color: '#FFFF' },
                                    }}
                                    onClick={() => { 
                                        window.dataLayer.push({
                                            event: 'InterMx_seguro_lo_quiero',
                                            seguro: 'Medico Seguro',
                                            boton_contacto: 'Lo quiero',
                                            page_url: window.location.href
                                        })
                                        window.location.href = 'https://medicoseguro.inter.mx/'
                                    }}>
                                    ¡Lo quiero!
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};
