import {SvgIcon} from "@mui/material";


const IconEmail = () => {
    return (<SvgIcon sx={{width:'14px'}}>
            <svg width="auto" height="auto" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.2797 0.582102L16.2221 0.519526C15.9956 0.301208 15.7019 0.183149 15.3991 0.188661H1.82707C1.52428 0.183149 1.2306 0.301208 1.00403 0.519526L0.946417 0.582102C0.7399 0.826532 0.622804 1.14459 0.6172 1.47634V10.2936C0.61591 10.6025 0.718325 10.9014 0.905264 11.1341L0.971107 11.2415C1.01371 11.2957 1.06722 11.3385 1.12749 11.3667C1.3341 11.5273 1.58127 11.6147 1.83531 11.617H15.3991C15.6866 11.6141 15.9638 11.5 16.181 11.2951C16.3164 11.1727 16.4256 11.0197 16.5009 10.8467C16.5763 10.6737 16.616 10.4849 16.6172 10.2936V1.47634C16.6141 1.14234 16.4934 0.822307 16.2797 0.582102ZM1.54724 1.85193L5.753 5.96544L1.54724 10.079V1.85193ZM10.5431 5.5541L10.469 5.63458L8.63365 7.42306L6.79003 5.63458C6.76666 5.60323 6.73888 5.57607 6.70773 5.5541L2.26329 1.20808H14.9958L10.5431 5.5541ZM6.42789 6.63612L8.31267 8.47826C8.39637 8.55958 8.50491 8.60419 8.61719 8.60344C8.72894 8.60102 8.83649 8.55682 8.92172 8.47826L10.8065 6.63612L14.9217 10.6781H2.30444L6.42789 6.63612ZM11.4814 5.96544L15.6954 1.85193V10.079L11.4814 5.96544Z" fill="#039ECC"/>
            </svg>
        </SvgIcon>
    )
}
export default IconEmail
