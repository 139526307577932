import React, { useEffect } from "react";
import {
    Box,
    Container,
    Button,
    Grid,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';

import Many from "assets/img/renovaciones/many_not_found.png";
import "./Success.css";
import DefaultNavbar from "components/DefaultNavbar";
import DefaultFooter from "components/DefaultFooter";

export default function NotFound() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const { breakpoints } = useTheme();
    const isTablet = useMediaQuery(breakpoints.down("md"));
    const isMobile = useMediaQuery(breakpoints.down("sm"));
    const isMedium = useMediaQuery(breakpoints.only("lg"));

    return (
        <>
            <DefaultNavbar/>
            <main>
                <Container className="container-succRen">
                    <Grid
                        sx={{
                            display:"flex",
                            flexDirection:"column",
                            alignItems:"center",
                            padding: !isTablet ? "64px 32px 64px" : !isMobile ? "48px 16px 48px" : "32px 8px 32px",
                        }}
                    >
                        <Box
                            component={"img"}
                            loading="lazy"
                            src={Many}
                            sx={{
                                width: !isTablet ? "320px" : !isMobile ? "300px" : "280px",
                                height: !isTablet ? "380px" : !isMobile ? "360px" : "340px",
                                mt: -7
                            }}
                        ></Box>
                        <Box
                            sx={{
                                alignSelf: "center",
                                padding: !isTablet ? "64px 0px 72px" : !isMobile ? "32px 0px 48px" : "16px 0px 24px",
                            }}
                        >
                            <Typography
                                component="h5"
                                className="tipography-h5"
                                sx={{
                                    fontSize: !isTablet ? "36px" : !isMobile ? "32px" : "28px",
                                }}
                            >
                                ¡Oops! Esta liga de pago no es válida. 
                            </Typography>

                            <Typography
                                component="h5"
                                className="tipography-regular"
                                sx={{
                                    fontSize: !isTablet ? "36px" : !isMobile ? "32px" : "28px",
                                }}
                            >
                                No corresponde a tu recibo actual.
                                <br />
                                <br />
                                <br />
                                ¿Tienes dudas? <br />
                                Si necesitas asistencia contáctanos y te ayudaremos.
                            </Typography>

                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: 4,
                                    mt: 1,
                                }}
                            >
                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                    <WhatsAppIcon sx={{ color: "#25D366", fontSize: "2.5rem" }} />
                                    <Typography
                                        component="h5"
                                        className="tipography-regular"
                                        sx={{
                                            fontSize: !isTablet ? "36px" : !isMobile ? "32px" : "28px",
                                            letterSpacing: "0.15rem",
                                        }}
                                    >
                                        55 474 22768
                                    </Typography>
                                </Box>

                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                    <PhoneIcon sx={{ color: "#469dc8", fontSize: "2.5rem" }} />
                                    <Typography
                                        component="h5"
                                        className="tipography-regular"
                                        sx={{
                                            fontSize: !isTablet ? "36px" : !isMobile ? "32px" : "28px",
                                            letterSpacing: "0.15rem",
                                        }}
                                    >
                                        55 442 46837
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection:"column",
                                alignItems: "center",
                                gap: "24px",
                                alignSelf:"center",
                            }}
                        >
                            <Button
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.location.href='/';
                                }}
                                className="container-button"
                                variant="contained"
                                sx={{
                                    display:"flex",
                                    padding:"16px 32px",
                                    justifyContent:"center",
                                    alignItems:"center",
                                }}
                            >
                                <Typography
                                    className="tipography-button"
                                    sx={{
                                        textAlign:"center",
                                        /* Button */
                                        fontSize: !isTablet ? "20px" : !isMobile ? "18px" : "16px",
                                        fontFeatureSettings: { clig: 'off', liga: 'off' },
                                    }}
                                >
                                    Entendido
                                </Typography>
                            </Button>
                        </Box>
                    </Grid>
                </Container>
            </main>
            <DefaultFooter/>
        </>
    );
}