import { useRef, useEffect } from 'react';
import info_icon from '../../assets/img/cobranza/info-icon.svg';
import styles from './PlataformaMitec.module.css';

export default function PlataformaMitec({
  url_mitec,
  onUpdateIframe,
}) {
  const iframe_mitec = useRef(null);

  useEffect(() => {
    const iframe = iframe_mitec.current;
  
    const updateUrl = () => {
      if (iframe) {
        onUpdateIframe();
      }
    };
    iframe.addEventListener('load', updateUrl);
    // Limpia el evento cuando el componente se desmonta
    return () => {
      if (iframe) {
        iframe.removeEventListener('load', updateUrl);
      }
    };
  }, []);

  return (
    <div className={styles['container-plataforma-mit']}>
      <p className={styles['titulo-plataforma-mit']}>Método de pago</p>
      <div className={styles['info-plataforma-mit']}>
        <img src={info_icon} className={styles['icon-info']} alt="" />
        <p className={styles['text-plataforma-mit']}>En inter.mx tienes una experiencia de pago segura y certificada. Tus datos están a salvo en todo momento.</p>
      </div>
      
      <div className={styles['container-mitec']}>
        <iframe
          ref={iframe_mitec}
          src={url_mitec}
          height="850px"
          title="Mitec payment"
          className={styles['container-iframe-mitec']}
        ></iframe>
      </div>
    </div>
  );
}
