import { ReactComponent as Check } from 'assets/img/icons/check.svg'
import { Grid, ListItem, ListItemAvatar, ListItemIcon, ListItemText } from "@mui/material";

export default function TravelsAssitance () {
  const plan = {
    one:[{
      text:`Envío y recuperación de equipaje`,
      bold: `hasta por $2,500.00 (dos mil quinientos) pesos.`
    },{
      text: `Interrupción de viaje por emergencia: cubrimos los gastos de avión de
      regreso a su lugar de origen en caso de fallecimento de un familiar directo`,
      bold: ''
    },{
      text: `Asistencia legal telefónica y Asistencia legal por pérdida de documentos`,
      bold: ''
    }
  ],
    two:[{
      text: `Hotel por convalecencia médica o imposibilidad para viajar hasta 10 (diez)
      días |`,
      bold: `Hasta 1,000 USD por noche`
    },{
      text: `Pago de hotel y traslado para un familiar por convalecencia |`,
      bold: `$ 1,500 (mil quinientos) peso.s hasta por 3 (tres) días`
    },{
      text: `Concierge telefónico: reservaciones para boletos, hoteles, taxi seguro, etc.`,
      body: ''
    }],
    three:[{
      text: `Repatriación funeraria para extranjeros`,
      bold: ``
    },{
      text: `Pago de noche de hotel por cierre de fronteras | hasta 3 (Tres) días |`,
      bold: `Hasta 1,000 USD por noche`
    },{
      text: `Muerte accidental | `,
      bold: `Hasta $150,000.00 (ciento cincuenta mil) pesos.`
    }]
  }
  return (
    <section className="pb-5 bg-gray-100">
    <div className="container md:w-10/12 mx-auto px-4">
        <div className='bg-white rounded-lg'>
          <div className='p-2'>
            <div className='p-5 text-center uppercase font-20 font-bold' style={{borderBottom: '1px solid #C7C7C7'}} >Asistencias de viaje</div>
            <div>
              <Grid container>
                <Grid item xs={12} sm={4}>
                {
                  plan.one.map((data, index) => {
                    return (
                    <ListItem key={index}>
                      <ListItemAvatar>
                        <ListItemIcon>
                          <Check />
                        </ListItemIcon>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <div style={{marginLeft: -25}}>{data.text} <span className='font-bold'>{data.bold}</span></div>
                        }
                      />
                    </ListItem>)
                  })
                }
                </Grid>
                <Grid item xs={12} sm={4}>
                {
                  plan.two.map((data, index) => {
                    return (
                    <ListItem key={index}>
                      <ListItemAvatar>
                        <ListItemIcon>
                          <Check />
                        </ListItemIcon>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <div style={{marginLeft: -25}}>{data.text} <span className='font-bold'>{data.bold}</span></div>
                        }
                      />
                    </ListItem>)
                  })
                }
                </Grid>
                <Grid item xs={12} sm={4}>
                {
                  plan.three.map((data, index) => {
                    return (
                    <ListItem key={index}>
                      <ListItemAvatar>
                        <ListItemIcon>
                          <Check />
                        </ListItemIcon>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <div style={{marginLeft: -25}}>{data.text} <span className='font-bold'>{data.bold}</span></div>
                        }
                      />
                    </ListItem>)
                  })
                }
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}