export default function Legend () {
  return (
    <section className="pb-5 bg-gray-100" >
      <div className="container mx-auto max-w-full">
        <div className='bg-white uppercase text-center' style={{fontSize: 22, color: '#212121', padding: 20}}>
          COBERTURAS QUE te CUIDAN a la hora de viajar
        </div>
      </div>
    </section>
  )
}