import { Grid, Box, Link } from '@mui/material';
import siempre from 'assets/img/sections/handshake.webp';
import encuentra from 'assets/img/sections/compare.webp';
import personaliza from 'assets/img/sections/custom.webp';
import unSoloLugar from 'assets/img/sections/device.svg';
import googlePlay from 'assets/img/marketing/google-black.webp';
import appleStore from 'assets/img/marketing/apple-black.webp';

export default function TeamSection() {
    return (
        <section className="pb-5 bg-gray-100" >
            <div className="container md:w-10/12 mx-auto px-4 text-center">
                <div className="font-bold font-28 pb-4" >
                    ¿Por qué INTER?
                </div>
                <Grid container direction="row" justifyContent="center" alignItems="center" spacing="24px">
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                        <Box className="box-pq-inter">
                            <Grid container direction="row" alignItems="center" justifyContent="center" className="box">
                                <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                                    <img src={siempre} alt='' className='w100px' />
                                </Grid>
                                <Grid item xs={6} sm={6} md={7} lg={7} xl={7}>
                                    <div className="titulo-box-text">
                                    Siempre estaremos contigo
                                    </div>
                                    <div className="desc-box-text">
                                    <strong>Disfruta de una atenci&oacute;n personalizada</strong>, qu&eacute;date tranquilo de sentirte protegido y escuchado todo el tiempo.
                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box className="box-pq-inter">
                            <Grid container direction="row" alignItems="center" justifyContent="center" className="box">
                                <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                    &nbsp;
                                </Grid>
                                <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                                    <img src={encuentra} alt='' className='w100px' />
                                </Grid>
                                <Grid item xs={6} sm={6} md={7} lg={7} xl={7}>
                                    <div className="titulo-box-text">
                                        Aqu&iacute; encuentras el mejor seguro
                                    </div>
                                    <div className="desc-box-text">
                                        Obt&eacute;n <strong>f&aacute;cil, r&aacute;pido</strong> y <strong>seguro</strong> el mejor seguro m&eacute;dico, al mejor precio con las mejores aseguradoras.
                                    </div>
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                    &nbsp;
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                        <Box className="box-pq-inter">
                            <Grid container direction="row" alignItems="center" justifyContent="center" className="box">
                                <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                    &nbsp;
                                </Grid>
                                <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                                    <img src={personaliza} alt='' className='w100px' />
                                </Grid>
                                <Grid item xs={6} sm={6} md={7} lg={7} xl={7}>
                                    <div className="titulo-box-text">
                                        Personaliza tu seguro
                                    </div>
                                    <div className="desc-box-text">
                                        Escoge la <strong>mayor cobertura</strong> y la <strong>forma de pago</strong> que mejor se ajuste a tus necesidades.
                                    </div>
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                    &nbsp;
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box className="box-pq-inter">
                            <Grid container direction="row" alignItems="center" justifyContent="center" className="box">
                                <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                    &nbsp;
                                </Grid>
                                <Grid item xs={4} sm={4} md={3} lg={3} xl={3} className="text-align-center">
                                    <img src={unSoloLugar} alt='' />
                                </Grid>
                                <Grid item xs={6} sm={6} md={7} lg={7} xl={7}>
                                    <div className="titulo-box-text">
                                        Tus seguros en un s&oacute;lo lugar
                                    </div>
                                    <div className="desc-box-text">
                                        <strong>Lleva tu p&oacute;liza siempre contigo</strong> en nuestra app despu&eacute;s de contratar en l&iacute;nea.
                                    </div>
                                    <div className="tiendas mt-15 hide-movil">
                                        <Link
                                            href='https://play.google.com/store/apps/details?id=grut.inter.mx'
                                            target={'_blank'}
                                        >
                                            <img src={googlePlay} alt='' />
                                        </Link>
                                        <Link
                                            href=' https://apps.apple.com/mx/app/intermx/id1548367767?l=en'
                                            target={'_blank'}
                                        >
                                            <img src={appleStore} alt='' />
                                        </Link>
                                    </div>

                                </Grid>
                                <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                    &nbsp;
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="hide-desktop">
                                    <div className="tiendas-movil ">
                                    <Link
                                        href='https://play.google.com/store/apps/details?id=grut.inter.mx'
                                        target={'_blank'} className="pr-16px"
                                    >
                                        <img src={googlePlay} alt='' />
                                    </Link>
                                    <Link
                                        href=' https://apps.apple.com/mx/app/intermx/id1548367767?l=en'
                                        target={'_blank'}
                                    >
                                        <img src={appleStore} alt='' />
                                    </Link>
                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </div>
        </section>
    );
}
