import React, { useEffect, useState,forwardRef, useRef } from 'react';
import Carousel from 'react-material-ui-carousel';
import CarInsurance from './CarInsurance';
import MedicalInsurance from './MedicalInsurance';
import PetInsurance from './PetInsurance';
import RightVector from '../../assets/img/icons/rightVector.svg';
import LeftVector from '../../assets/img/icons/leftVector.svg';
import { Box } from '@mui/material';

export default function PickInsurance() { 

  const insurances = [<CarInsurance />, <MedicalInsurance />, <PetInsurance />];

  return (
    <Box sx={{paddingBottom:'80px'}}>
      <Carousel
        autoPlay={false}
        animation={'slide'}
        indicators={true}
        navButtonsAlwaysVisible={true}
      
        NextIcon={
          <Box
            component={'img'}
            src={RightVector}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '33.182px',
              flexShrink: 0,
              marginRight:{lg: 8.75, xl: 8.75}
            }}
          />
        }
        PrevIcon={
          <Box
            component={'img'}
            src={LeftVector}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '33.182px',
              flexShrink: 0,
              marginLeft:{lg: 8.75, xl: 8.75}
            }}
          />
        }
        navButtonsProps={{
          style: {
            background: 'none',
            margin:0,
            flexShrink: 0,
          },
          sx:{
            visibility:{xs:'hidden',sm:'hidden',md:'hidden',lg:'visible',xl:'visible'}
          }
        }}

        cycleNavigation={false}

        indicatorIconButtonProps={{
          style:{
            color:'#D9D9D9',
            width:30,
            height:10,
            margin:0,
            borderRadius:10,
          }
        }}

        activeIndicatorIconButtonProps={{
          style: {
              color: '#039ECC',
              width:30,
              height:10,
              borderRadius:10,
          }
        }}

      >
        {insurances.map((insurance, index) => (
          <Box key={index}>
            <Box>{insurance}</Box>
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};