import React from 'react';
import H2 from '@material-tailwind/react/Heading2';
import { Grid } from '@mui/material';
import Form from 'components/vigo/Form';
import { ReactComponent as Vigo } from 'assets/img/icons/product/vigo-header.svg';

export default function Header() {
    return (
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center h-screen">
            <div className="bg-vigo bg-cover bg-center absolute top-0 w-full h-full" />
            <div className="container md:w-10/12 relative mx-auto">
                <div className="items-left flex flex-wrap">
                    <div className="w-full px-12 mr-auto">
                        <Grid container >
                            <Grid item xs={12} sm={7}>
                                <div className='flex align-items'>
                                    <div className='pr-5'>
                                        <Vigo className='margin-auto' />
                                    </div>
                                    <div className='grid align-items font-20 font-bold text-white'>
                                        <div>
                                            <div>Viaja</div>
                                            <div>Seguro</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='pr-10' >
                                    <H2 color="white">
                                        Viaja <br/>seguro, seguro
                                    </H2>
                                    <div className='text-white font-40' >
                                        Contrata desde $87 + IVA al día
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <div className='banner-lg'>
                                    <Form />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </div>
    );
}
