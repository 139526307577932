import TermsM from '../../assets/img/landing/Mani-Terminos.png';
import { Typography, Box, Grid, Stack } from '@mui/material';

import './styles.css';

export default function Header() {
    return (
        <Grid>
            <Grid container
                className='pt-16'
                direction={{ xs: 'column-reverse', sm: 'row', md: 'row', lg: 'row', xl: 'row' }} /* column-reverse */
                justifyContent="flex-start"
                alignItems="center"
            >
            <Grid item xs={12} sm={4} md={4} lg={2} xl={2}>
                <Stack
                    direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' }}
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                    sx={{
                        width: { xs: '294px', sm: '230px', md: '260px', lg: '200px', xl: '200px' },
                        height: { xs: '294px', sm: '230px', md: '260px', lg: '200px', xl: '200px' }
                    }}
                >
                    <img src={TermsM} />
                </Stack>
            </Grid>
            <Grid item xs={12} sm={8} md={8} lg={10} xl={10}>
                <Stack
                    direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' }}
                    justifyContent="flex-start"
                    position={'relative'}
                    alignItems="center"
                    spacing={1}
                    sx={{width:'100%'}}
                >
                    <Typography className='termino-header' sx={{ fontSize: { xs: '44px', sm: '44px', md: '54px', lg: '70px', xl: '70px' } }}>Términos y</Typography><Typography className='condition-header' sx={{ fontSize: { xs: '44px', sm: '44px', md: '54px', lg: '70px', xl: '70px' } }}>Condiciones</Typography>
                </Stack>
            </Grid>
            </Grid>

            <Box className='descripcion-termino'
                sx={{
                    height: { xs: '168px', sm: '168px', md: '120px', lg: '120px', xl: '120px' },
                    paddingLeft: { xs: '16px', sm: '16px', md: '36px', lg: '247px', xl: '247px' },
                    paddingRight: { xs: '16px', sm: '16px', md: '36px', lg: '247px', xl: '247px' }
                }}>
                <Typography
                    sx={{fontSize:'20px',fontWeight:700,lineHeight:'normal'}}>
                    Consulta nuestros términos y condiciones
                </Typography>
            </Box>

        </Grid>
    );
}
