import GreetingM from '../../assets/img/landing/Greeting-Mani.png';
import { Typography, Box, Grid, Stack } from '@mui/material';
import { useState, useEffect } from "react";

import './styles.css';

export default function Header() {
  const [pTop, setPaddingTop] = useState('');

  const getSatus = async () => {
    try {
      let responseLocal = await sessionStorage.getItem("closePublish");
      responseLocal = JSON.parse(responseLocal)
      if (responseLocal) {
        setPaddingTop('0px');
      } else {
        setPaddingTop('120px');
      }
    } catch (e) {
      console.log("Error get LocalStorage::", e)
    }
  }

  useEffect(() => {
    getSatus()
  }, [

  ])

  return (
    <Grid sx={{ paddingTop: pTop }}>
      <Grid container
        className='pt-16'
        direction={{xs:'column-reverse',sm:'row',md:'row',lg:'row',xl:'row'}} /* column-reverse */
        justifyContent="flex-start"
        alignItems="center"
      >
        <Stack
          direction={{xs:'column',sm:'row',md:'row',lg:'row',xl:'row'}}
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          sx={{width:{xs:'326px',sm:'326px',md:'249px',lg:'399px',xl:'399px'},
              height:{xs:'292px',sm:'292px',md:'227px',lg:'357px',xl:'357px'}}}
          >
          <img src={GreetingM} alt='' />
        </Stack>
        <Stack
          direction={{xs:'column',sm:'colum',md:'row',lg:'row',xl:'row'}}
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Typography className='aviso' sx={{ fontSize: { xs: '44px', sm: '44px', md: '54px', lg: '70px', xl: '70px' }}}>Aviso de</Typography><Typography className='privacidad' sx={{ fontSize: { xs: '44px', sm: '44px', md: '54px', lg: '70px', xl: '70px' } }}>Privacidad</Typography>
        </Stack>
      </Grid>

      <Box className='descripcion-aviso'
        sx={{
          height: { xs: '168px', sm: '168px', md: '120px', lg: '120px', xl: '120px' },
          paddingLeft: { xs: '16px', sm: '16px', md: '36px', lg: '247px', xl: '247px' },
          paddingRight: { xs: '16px', sm: '16px', md: '36px', lg: '247px', xl: '247px' }
        }}>
        <Typography
          sx={{fontSize:'20px',fontWeight:700}}>
          Consulta nuestros avisos de privacidad
        </Typography>
      </Box>

    </Grid>
  );
}
