import { useEffect } from 'react';
import DefaultNavbar from 'components/DefaultNavbar';
import DefaultFooter from 'components/DefaultFooter';
import Header from 'components/Aviso/Header';
import TermnsSection from 'components/Aviso/TermsSection';

export default function Termns() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
            <div className="absolute w-full z-20">
                <DefaultNavbar />
            </div>
            <main>
                <Header />
                <TermnsSection />
            </main>
            <DefaultFooter />
        </>
    );
}
