import { Box, Typography } from '@mui/material';
import { useState, useEffect } from "react";
import './styles.css';
import Slider from "react-slick";
/* import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"; */

import imgOpinion1 from '../../assets/img/opiniones/opinion1.jpg';
import imgOpinion2 from '../../assets/img/opiniones/opinion2.jpg';
import imgOpinion3 from '../../assets/img/opiniones/opinion3.1.jpg';
import imgOpinion4 from '../../assets/img/opiniones/opinion4.jpg';
import imgOpinion5 from '../../assets/img/opiniones/opinion5.jpg';
import imgOpinion6 from '../../assets/img/opiniones/opinion6.jpg';

import facebook from '../../assets/img/opiniones/src_facebook.svg';

const Opiniones = () => {

  const [sliderHeight, setSliderHeight] = useState('auto');
  let maxHSlider = 0;

  const getStatus = async () => {
    try {
      let responseCounter = await sessionStorage.getItem("countCloseBanner");
      if (responseCounter) {
        try {
          let responseMaxHOpinions = await sessionStorage.getItem("maxHeightOpinions");
          if (!responseMaxHOpinions) {
            //set the maximum height to the opinion cards height:
            const opinionsCards = document.querySelectorAll('.item-slide');
            opinionsCards.forEach(opinionCard => {
              opinionCard.style.height = `${maxHSlider}px`;
            });
            //se crea en sessionStorage el registro de la altura máxima.
            sessionStorage.setItem("maxHeightOpinions", JSON.stringify({ value:  maxHSlider}));
          }else{
            let maxHOpinions = JSON.parse(sessionStorage.getItem("maxHeightOpinions"));
            const opinionsCards = document.querySelectorAll('.item-slide');
            opinionsCards.forEach(opinionCard => {
              opinionCard.style.height = `${maxHOpinions.value}px`;
            });
          }
        } catch (e) {
          console.log("Error get LocalStorage::", e);
        }
      }
    } catch (e) {
      console.log("Error get LocalStorage::", e);
    }
  };

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: true,
          arrows: true,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
          arrows: true,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
          arrows: false,
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        }
      }
    ]
  };

  useEffect(() => {
    //get the opinion cards height:
    const opinionsCards = document.querySelectorAll('.item-slide');
    opinionsCards.forEach((opinionCard, index) => {
      if (opinionCard.clientHeight > maxHSlider) {
        maxHSlider = opinionCard.clientHeight;
      }
    });
    setSliderHeight(maxHSlider + 'px');
    getStatus();
  });

  return (
    <Box sx={{
      width: '100%',
      height: 'auto',
      paddingLeft: { xs: '0px', sm: '0px', md: '36px', lg: '247px', xl: '247px' },
      paddingRight: { xs: '0px', sm: '0px', md: '36px', lg: '247px', xl: '247px' },
      paddingBottom: '130px',
      backgroundColor: '#F2FAFC',
    }}>
      <Typography
        sx={{
          fontWeight: 700,
          color: '#212121',
          fontStyle: 'normal',
          fontSize: '24px',
          lineHeight: 'normal',
          textAlign: 'center',
          paddingTop: '80px',
          paddingBottom: '40px'
        }}><h2>¿Qué opinan nuestros clientes?</h2>
      </Typography>
      <Box className="container-slider" height='auto'>
        <Slider {...settings} className='slider' height='auto'>
          <Box className="item-slide"
            style={{ height: sliderHeight }}
            /* height={sliderHeight} */
            sx={{
              paddingLeft: '16px', paddingRight: '16px',
              borderRightColor: '#77CAE3', borderRightWidth: '1px',
            }}>
            <Box>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img src={imgOpinion1} style={{ height: 120, width: 120, borderRadius: '50%' }} />
              </div>
            </Box>
            <Typography
              sx={{
                fontWeight: 700,
                color: '#595959',
                fontSize: '20.393px',
                lineHeight: '24.86px',
                textAlign: 'center',
                paddingTop: '27.19px',
                paddingBottom: '27.19px',
              }}>Raúl Torres</Typography>
            <Box>
              <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '40px' }}>
                <img src={facebook} style={{ width: 104.128, height: 34.277 }} />
              </div>
            </Box>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '18px',
                fontStyle: 'normal',
                lineHeight: 'normal',
              }}
            >“Excelente servicio y atención muy atentos a todo el trámite”</Typography>
          </Box>
          <Box className="item-slide"
            style={{ height: sliderHeight }}
            /* height={sliderHeight} */
            sx={{
              paddingLeft: '16px', paddingRight: '16px',
              borderRightColor: '#77CAE3', borderRightWidth: '1px'
            }}>
            <Box >
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img src={imgOpinion2} style={{ height: 120, width: 120, borderRadius: '50%' }} />
              </div>
            </Box>
            <Typography
              sx={{
                fontWeight: 700,
                color: '#595959',
                fontSize: '20.393px',
                lineHeight: '24.86px',
                textAlign: 'center',
                paddingTop: '27.19px',
                paddingBottom: '27.19px',
              }}>Eduardo García Torres</Typography>
            <Box>
              <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '40px' }}>
                <img src={facebook} style={{ width: 104.128, height: 34.277 }} />
              </div>
            </Box>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '18px',
                fontStyle: 'normal',
                lineHeight: 'normal',
              }}
            >“Excelente atención y servicio por parte de Raúl Lemus, muy amable y atento en todo el trámite, recomendable 100%”</Typography>
          </Box>
          <Box className="item-slide"
            style={{ height: sliderHeight }}
            /* height={sliderHeight} */
            sx={{
              paddingLeft: '16px', paddingRight: '16px',
              borderRightColor: '#77CAE3', borderRightWidth: '1px'
            }}>
            <Box >
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img src={imgOpinion3} style={{ height: 120, width: 120, borderRadius: '50%' }} />
              </div>
            </Box>
            <Typography
              sx={{
                fontWeight: 700,
                color: '#595959',
                fontSize: '20.393px',
                lineHeight: '24.86px',
                textAlign: 'center',
                paddingTop: '27.19px',
                paddingBottom: '27.19px',
              }}>Rafael Reyes</Typography>
            <Box>
              <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '40px' }}>
                <img src={facebook} style={{ width: 104.128, height: 34.277 }} />
              </div>
            </Box>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '18px',
                fontStyle: 'normal',
                lineHeight: 'normal',
              }}
            >“Muy buena atención, oportuna y efectiva por parte del asesor Raúl Lemus,
              para la renovación y pago de mi póliza de seguro”
            </Typography>
          </Box>
          <Box className="item-slide"
            style={{ height: sliderHeight }}
            /* height={sliderHeight} */
            sx={{
              paddingLeft: '16px', paddingRight: '16px',
              borderRightColor: '#77CAE3', borderRightWidth: '1px'
            }}>
            <Box>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img src={imgOpinion4} style={{ height: 120, width: 120, borderRadius: '50%' }} />
              </div>
            </Box>
            <Typography
              sx={{
                fontWeight: 700,
                color: '#595959',
                fontSize: '20.393px',
                lineHeight: '24.86px',
                textAlign: 'center',
                paddingTop: '27.19px',
                paddingBottom: '27.19px',
              }}>Jula Miguel</Typography>
            <Box>
              <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '40px' }}>
                <img src={facebook} style={{ width: 104.128, height: 34.277 }} />
              </div>
            </Box>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '18px',
                fontStyle: 'normal',
                lineHeight: 'normal',
                /* textAlign: 'justify', */
              }}
            >"Marque para saber acerca de mi póliza de gastos, me atendió muy amable  Modesto, me ayudó con las dudas sobre este tema, muchas gracias."</Typography>
          </Box>
          <Box className="item-slide"
            style={{ height: sliderHeight }}
            /* height={sliderHeight} */
            sx={{
              paddingLeft: '16px', paddingRight: '16px',
              borderRightColor: '#77CAE3', borderRightWidth: '1px'
            }}>
            <Box>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img src={imgOpinion5} style={{ height: 120, width: 120, borderRadius: '50%' }} />
              </div>
            </Box>
            <Typography
              sx={{
                fontWeight: 700,
                color: '#595959',
                fontSize: '20.393px',
                lineHeight: '24.86px',
                textAlign: 'center',
                paddingTop: '27.19px',
                paddingBottom: '27.19px',
              }}>Maria De Jesús León</Typography>
            <Box>
              <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '40px' }}>
                <img src={facebook} style={{ width: 104.128, height: 34.277 }} />
              </div>
            </Box>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '18px',
                fontStyle: 'normal',
                lineHeight: 'normal',
              }}
            >“La atención personalizada, hasta culminar todo el trámite. (recomiendo ampliamente Christian Castillo, excelente asesor)”</Typography>
          </Box>
          <Box className="item-slide"
            style={{ height: sliderHeight }}
            /* height={sliderHeight} */
            sx={{
              paddingLeft: '16px', paddingRight: '16px',
              borderRightColor: '#77CAE3', borderRightWidth: '1px'
            }}>
            <Box>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img src={imgOpinion6} style={{ height: 120, width: 120, borderRadius: '50%' }} />
              </div>
            </Box>
            <Typography
              sx={{
                fontWeight: 700,
                color: '#595959',
                fontSize: '20.393px',
                lineHeight: '24.86px',
                textAlign: 'center',
                justifyContent: 'justify',
                paddingTop: '27.19px',
                paddingBottom: '27.19px',
              }}>Lalo Ramos</Typography>
            <Box>
              <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '40px' }}>
                <img src={facebook} style={{ width: 104.128, height: 34.277 }} />
              </div>
            </Box>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '18px',
                fontStyle: 'normal',
                lineHeight: 'normal',
              }}
            >“La renovación fue muy sencilla y en todo momento mi asesor Ángel, estuvo al pendiente en casa paso, me guío y me ayudó a obtener la renovación y mi experiencia fue muy gratificante y ampliamente lo recomiendo 10/10. ¡Excelente servicio!”</Typography>
          </Box>
        </Slider>
      </Box>
    </Box>
  )
}

export default Opiniones;