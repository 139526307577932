import React from 'react';
import { useEffect } from 'react';
import DefaultNavbar from 'components/DefaultNavbar';
import DefaultFooter from 'components/DefaultFooter';
import Success from 'components/renovaciones/Success';


export default function Renovaciones() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <DefaultNavbar />
            <main>
                <Success />
            </main>
            <DefaultFooter />
        </>
    );
}
