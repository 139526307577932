import Coche from "./cards/Coche";
import Mascota from "./cards/Mascota";
import Medico from "./cards/Medico";

export default function Insurances() {
  return (
    <div className="insuranceContainer">
      <p className="insuranceTitle">Descubre nuestros seguros</p>
      <div className="insuranceContainerCards">
        <Coche />
        <Medico />
        <Mascota />
      </div>
    </div>
  );
}
