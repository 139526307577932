import { useEffect, useRef } from 'react';

import './HeaderRenovacion.css';

export default function HeaderRenovacion() {
    return (
        <>
            <div className='container-PagoReno'>
                <div className='yellow-line' />
                <p className='title'>Pago de renovación</p>
            </div>
        </>
    );
}

