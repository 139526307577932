import React from 'react';
import { Row, Col } from 'react-bootstrap';

export default function SimpleAccordion() {
  return (
    <section className="pb-20 block bg-gray-100 semovi">
      <div className="container mx-auto md:w-10/12 px-4 pb-12">
        <div className="flex flex-wrap relative z-50 -mt-32 mx-auto">
          <div className="w-full margin-auto flex justify-center text-center bg-white rounded-lg">
            <div className='text-left'>
                <div className="title-promociones">
                  <span>Inter.mx y Semovi, te llevamos seguro, seguro a tu destino</span>
                </div><br/><br/>
                <div className="bg-content-semovi" w="100" h="100">

                  <div>
                    <p className="txt-empty">
                      SEMOVI, inter.mx y Waze colaboran para proteger la
                      seguridad vial de las y los conductores
                    </p>
                  </div>
                  <div class="alin">
                    <Row>
                      <Col>
                        <ul>
                          <li> <i>A través de Waze, SEMOVI e inter.mx emitirán diversos mensajes de seguridad vial,
                            de acuerdo con las características de las vialidades por las que circulen las personas
                            conductoras, con el propósito de salvar vidas</i></li>
                          <li><i>De acuerdo con la Secretaría de Movilidad (SEMOVI) de la Ciudad de México, es
                            fundamental concientizar a las personas sobre los distintos usuarios de la vía y
                            respetar los límites de velocidad para mejorar la convivencia vial.</i>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                    <Col>
                      <p>
                        <b>Ciudad de México, 3 de noviembre de 2021.</b> A partir de hoy, a través de Waze, la
                        Secretaría de Movilidad (SEMOVI) e inter.mx, emitirán diversos mensajes para mejorar la
                        seguridad vial al circular por zonas y calles de la ciudad. La plataforma usará como base los
                        registros de incidentes viales recabados por la Secretaría de Seguridad Ciudadana, en los
                        que ha identificado rutas con mayor incidencia de hechos de tránsito.
                      </p>
                    </Col>
                    <Col>
                      <p>
                        Esta campaña de seguridad vial funcionará a través de una campana geolocalizada que
                        mostrará alertas en Waze, con forma de banners, en lugares que han sido registrados como
                        una zona de alta incidentalidad.
                      </p>
                    </Col>
                    <Col>
                      <p>
                        De acuerdo con SEMOVI, en la Ciudad de México, los incidentes de tránsito han disminuido
                        de 2019 a 2021 (-15%). Sin embargo, es fundamental seguir concientizando a las personas
                        sobre los distintos usuarios de la vía y continuar implementando estrategias de gestión de
                        velocidad para mejorar la convivencia vial.
                      </p>
                    </Col>
                    <Col>
                      <p>
                        La campaña <i>“Salva vidas al volante”</i> que estará disponible durante noviembre, mostrará tres
                        tipos de alertas dentro de Waze para las y los conductores, señalando el <b>exceso de
                          velocidad, la protección a peatones y ciclistas y precaución en giros y cambios de
                          carril</b>. La plataforma desplegará avisos como: “Estás en una zona de incidentes por alta
                        velocidad, no seas una estadística más; “Salva vidas al volante”; “Salvar vidas está en tus
                        manos”; “Recuerda que las ciclovías son exclusivas de las bicis, no para estacionarse ni
                        circular en ellas”; o “Avisa cuando gires o cambies de carril: usa las direccionales”.
                      </p>
                    </Col>
                    <Col>
                      <p>
                        Por su parte SEMOVI, ha establecido una estrategia que se alinea con los objetivos de la
                        Segunda Década de Acción para la Seguridad Vial. Al enviar mensajes específicos en
                        lugares identificados como prioritarios, se inhiben factores de riesgo como la velocidad y se
                        protege a todos los usuarios de la vía, especialmente ciclistas y peatones.
                      </p>
                    </Col>
                    <Col>
                      <p>
                        <b><a href="https://inter.mx/">inter.mx</a></b> es una plataforma digital de seguros creativa, flexible y en continua evolución que
                        busca acercar al cliente a los mejores productos y óptima experiencia para que se sienta
                        seguro-seguro. Para ello ha desarrollado diferentes productos que se pueden adquirir de
                        manera digital, fácil y rápida en menos de 5 minutos, por ejemplo, “Coche Seguro" (seguro
                        de coche).
                      </p>
                    </Col>
                    <Col>
                      <p>
                        <i>“Siempre hemos impulsado una cultura de la prevención y de seguridad entre las personas,
                          ya que ante un imprevisto siempre debemos estar preparados. Esta alianza con Waze y
                          SEMOVI permitirá que más personas conozcan la importancia del tema”</i>, <b>declaró Alonso
                            Pallarés, Director Digital de <a href="https://inter.mx/">inter.mx.</a></b>
                      </p>
                    </Col>
                    <Col>
                      <p>
                        <i>“En Waze, tenemos un firme compromiso con la seguridad vial de nuestros usuarios, hemos
                          realizado esta colaboración para fomentar una correcta convivencia y corresponsabilidad al
                          momento de conducir. Como plataforma de movilidad tenemos una cercanía privilegiada
                          con la comunidad de conductores para indicar zonas con alto riesgo vial y recordarles
                          algunas normas de seguridad que pueden evitar accidentes",</i> <b>afirmó Ingrid Avilés,
                            Country Manager de Waze México.</b>
                      </p>
                    </Col>
                    <Col>
                      <p><b>Acerca de Waze</b></p>
                      <p>Waze es el punto de encuentro entre las personas y la tecnología para resolver los retos del transporte. Es una
                        plataforma que permite a las comunidades aportar datos sobre las carreteras, editar los mapas de Waze y
                        compartir el coche para mejorar la forma en que nos movemos por el mundo. Gracias a los Wazers de todo el
                        mundo, Waze es capaz de asociarse con los municipios y las autoridades de tránsito para reducir el tráfico y la
                        congestión, aprovechando la infraestructura actual, al mismo tiempo que influye en la planificación de la ciudad.
                      </p>
                      <p>Un mundo con mejor transporte no tiene por qué ser un futuro lejano. Aprovechando el poder de la comunidad
                        para invertir las tendencias negativas del transporte, Waze puede crear un mundo en el que el tráfico sea
                        historia.
                      </p>
                    </Col>
                    <Col>
                      <p>
                        <b>Acerca de Inter.mx</b>
                      </p>
                      <p>
                        <a href="https://inter.mx/">inter.mx</a>, la insurtech mexicana líder en el mercado con la mejor innovación tecnológica en todos sus procesos,
                        se enfoca directamente al cliente, llevándolo a la vanguardia, ofreciendo el mejor servicio a través de productos
                        innovadores y disruptivos, como lo son Coche Seguro y Médico Seguro. Su principal diferenciador son los
                        precios y la facilidad para contratar, ya que <a href="https://inter.mx/">inter.mx</a> le quita lo complicado a los seguros, y en menos de cinco
                        minutos se puede adquirir cualquiera de sus servicios, acercándose mejor al consumidor final.
                      </p>
                    </Col>
                  </div>
                  <div>

                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}