import React from "react";
import {
    Box,
    Container,
    Button,
    Grid,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import Many from "assets/img/renovaciones/many_success.svg";
import "./Success.css";

export default function Success() {

    const { breakpoints } = useTheme();
    const isTablet = useMediaQuery(breakpoints.down("md"));
    const isMobile = useMediaQuery(breakpoints.down("sm"));
    const isMedium = useMediaQuery(breakpoints.only("lg"));

    return (
        <Container className="container-succRen">
            <Grid
                sx={{
                    display:"flex",
                    flexDirection:"column",
                    alignItems:"center",
                    padding: !isTablet ? "64px 32px 64px" : !isMobile ? "48px 16px 48px" : "32px 8px 32px",
                }}
            >
                <Box
                    component={"img"}
                    loading="lazy"
                    src={Many}
                    sx={{
                        width: !isTablet ? "384px" : !isMobile ? "364px" : "344px",
                        height: !isTablet ? "457px" : !isMobile ? "437px" : "417px",
                    }}
                ></Box>
                <Box
                    sx={{
                        alignSelf: "center",
                        padding: !isTablet ? "64px 0px 72px" : !isMobile ? "32px 0px 48px" : "16px 0px 24px",
                    }}
                >
                    <Typography
                        component="h5"
                        className="tipography-h5"
                        sx={{
                            fontSize: !isTablet ? "36px" : !isMobile ? "32px" : "28px",
                        }}
                    >
                        ¡Tu pago se ha realizado con éxito!
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection:"column",
                        alignItems: "center",
                        gap: "24px",
                        alignSelf:"center",
                    }}
                >
                    <Button
                        onClick={(e) => {
                            e.preventDefault();
                            window.location.href='/';
                        }}
                        className="container-button"
                        variant="contained"
                        sx={{
                            display:"flex",
                            padding:"16px 32px",
                            justifyContent:"center",
                            alignItems:"center",
                        }}
                    >
                        <Typography
                            className="tipography-button"
                            sx={{
                                textAlign:"center",
                                /* Button */
                                fontSize: !isTablet ? "20px" : !isMobile ? "18px" : "16px",
                                fontFeatureSettings: { clig: 'off', liga: 'off' },
                            }}
                        >
                            Entendido
                        </Typography>
                    </Button>
                </Box>
            </Grid>
        </Container>
    );
}
