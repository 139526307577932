import React from "react";
import { Typography } from "@mui/material";
import Icon from "../../assets/img/landingRedisign/abc-seguros.png";
import "./Blog.css";
import { Helmet } from "react-helmet";

const Header = () => {
    return (
        <div className="relative justify-center">
            <Helmet>
                <title>Tipos de seguros a la medida | inter.mx</title>
                <link rel="canonical" href="https://inter.mx/blog" />
                <meta name="description" content="Consulta en nuestro blog ABC de seguros, noticias relacionadas con el mundo de los seguros de todos los productos que ofrecemos"></meta>
            </Helmet>
            <div className="flex items-center justify-center blog-container">
                <div className="">
                    <p className="blog-first-title">ABC de los</p>
                </div>
                <div className="absolute z-9">
                    <img src={Icon} className="blog-img" />
                </div>
                <div className="blog-second-title">
                    <p className="">seguros</p>

                </div>
            </div>
            <div className="blog-description">
                <Typography>
                    No queremos que te quedes con ninguna duda, aquí puedes encontrar guías y tips para que le saques provecho a tu seguro.
                </Typography>
            </div>
        </div>
    );
}

export default Header;