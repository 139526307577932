import DefaultNavbar from 'components/DefaultNavbar';
import DefaultFooter from 'components/DefaultFooter';
import Header from 'components/medico/Header';
import TeamSection from 'components/landing/TeamSection';
import Legend from 'components/medico/Legend';
// import Banner from 'components/medico/Banner';
import Plans from 'components/medico/Plans';
import FooterProducts from 'components/FooterProduct';
import Budget from 'components/medico/Budget';
import Requirements from 'components/medico/Requirements';
import { useEffect } from 'react';
import WorkingSection from 'components/medico/WorkingSection';

export default function MedicoSeguro() {
    const loadScript = (src) => {
        var tag = document.createElement('script');
        tag.async = true;
        tag.src = src;
        var body = document.getElementsByTagName('body')[0];
        body.appendChild(tag);
      }
    useEffect(() => {
        window.scrollTo(0, 0)      
        loadScript('https://widget.sirena.app/get?token=752cd2f3e228464aabe08ef5692bc0f2');
    }, [])

    return (
        <>
            <div className="absolute w-full z-20">
                <DefaultNavbar />
            </div>
            <main>
              <Header />
              <WorkingSection />
              <Legend />
              <TeamSection />
              {/*<Banner />*/}
              <Plans />
              <Requirements />
              <Budget />
              <FooterProducts />
            </main>
            <DefaultFooter />
        </>
    );
}
