import { Grid } from '@mui/material';
import Carousel from 'react-material-ui-carousel'

export default function ComentsSection() {
    return (
        <section className="pb-5 bg-gray-100" >
            <div className="container md:w-10/12 mx-auto px-5 text-center" style={{overflow: 'auto' }}>
                <div className='font-bold pb-15 px-5 font-28'>
                  ¿Qué dicen de nosotros?
                </div>
                <div className='mx-auto text-white bg-blue-inter rounded-lg container-lg'>
                <Carousel
                  fullHeightHover={false}
                >
                  <div className='mx-auto font-22 p-5'>
                    <Grid container columns={12} >
                      <Grid item xs={4}>
                        <div className='' style={{borderRight: '1px solid #FFF'}}>
                            <div className='mx-auto flex flex-wrap justify-center content-center bg-bubble-light bg-cover' style={{height: 435, width: 329}}>
                              <div className='p-5'>
                              “Excelente atención siempre disponibles ,mi agente le dió seguimiento a mi solicitud a pesar que contraté en la madrugada, muy buena aplicación. Además gane la devolución del 100% de mi póliza.”
                              </div>
                            </div>
                          <div>
                            Lalo Trejo
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div className='px-5' style={{borderRight: '1px solid #FFF'}}>
                          <div className='mx-auto bg-bubble-light bg-cover flex flex-wrap justify-center content-center ' style={{height: 435, width: 329}}>
                            <div className='p-5'>
                            ‘‘Me cotizaron mi seguro muy rápido y el más barato del mercado. Lo contraté súper rápido y pagué en línea desde la comodidad de mi casa. Muy buen servicio.’’
                            </div>
                          </div>
                          <div>
                            Luis Edrey
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div className='pl-5'>
                          <div className='mx-auto bg-bubble-light bg-cover flex flex-wrap justify-center content-center' style={{height: 435, width: 329}}>
                            <div className='p-5'>
                            ‘‘Gracias Interprotección, por cumplir con sus promociones, ¡Sigan así!’’
                            </div>
                          </div>
                          <div>
                            Leonel Espinosa Nava
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Carousel>
                </div>
                <div className='text-white bg-blue-inter rounded-lg container-sm'>
                <Carousel
                  fullHeightHover={false}
                >
                  <div className='px-5'>
                    <div className='flex mx-auto flex-wrap justify-center content-center bg-bubble-light bg-cover h-coments-mobile'>
                      <div className='p-5'>
                      “Excelente atención siempre disponibles ,mi agente le dió seguimiento a mi solicitud a pesar que contraté en la madrugada, muy buena aplicación. Además gane la devolución del 100% de mi póliza.”
                      </div>
                    </div>
                    <div>
                      Lalo Trejo
                    </div>
                  </div>
                  <div className='px-5'>
                    <div className='bg-bubble-light mx-auto bg-cover flex flex-wrap justify-center content-center h-coments-mobile'>
                      <div className='p-5'>
                      ‘‘Me cotizaron mi seguro muy rápido y el más barato del mercado. Lo contraté súper rápido y pagué en línea desde la comodidad de mi casa. Muy buen servicio.’’
                      </div>
                    </div>
                    <div>
                      Luis Edrey
                    </div>
                  </div>
                  <div className='px-5'>
                    <div className='bg-bubble-light mx-auto bg-cover flex flex-wrap justify-center content-center h-coments-mobile'>
                      <div className='p-5'>
                      ‘‘Gracias Interprotección, por cumplir con sus promociones, ¡Sigan así!’’
                      </div>
                    </div>
                    <div>
                      Leonel Espinosa Nava
                    </div>
                  </div>
                </Carousel>
                </div>
            </div>
        </section>
    );
}
