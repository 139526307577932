import axios from "axios";
import { client_secret } from "config";
import { client_id } from "config";
import { createContext, useCallback, useEffect, useState } from "react";

export const AutenthContext = createContext({});
export const AuthProvider = ({children}) => {
    const [token, setToken] = useState();
    const [isLoading, setIsLoading] = useState(true)

    const getToken = useCallback( async() => {
      try {
        const { data } = await axios.post(
          "https://api.inter.mx/v1/authorization/token",
          {
            client_id,
            client_secret
          }
        );
        setToken(data.data.access_token);
      } catch (error) {
        
      } finally {
        setIsLoading(false)
      }
   
    },[])
    
    useEffect(() => {
      setTimeout(() => {
        getToken()
      }, 180000);
    },[getToken])

    useEffect(() => {
        getToken()
    },[getToken])

    return <AutenthContext.Provider value={{token}}>{isLoading ? '' : children}</AutenthContext.Provider>
}