import {useState} from 'react';
import {useHistory} from "react-router-dom";

import mapfre from 'assets/img/Aseguradora.svg';
import qualitas from 'assets/img/insurances/Qualitas-color.svg';
import aig from 'assets/img/insurances/AIG-color.svg';
import axa from 'assets/img/insurances/AXA-color.svg';
import aba from 'assets/img/insurances/chubb-color.svg';
import hdi from 'assets/img/insurances/HDI-color.svg';
import gnp from 'assets/img/insurances/GNP-color.svg';
import zurich from 'assets/img/insurances/zurich-color.svg';
import primeroseguros from 'assets/img/insurances/primero.png';

import './DatosPasarelaRenovacion.css';

const logos = {
    mapfre, qualitas, aig, axa, aba, hdi, gnp, zurich, primeroseguros
  }
  

export default function DatosPasarelaRenovacion({numPoliza, nombre, modalidadPago, total, url, primerPago = "0", pagoSubsecuente = "0", aseguradora, currentPayment = 1, currentTotal = 0 }) {
    const history = useHistory();
    const [isSecondLoad, setIsSecondLoad] = useState(false)

    function handleIframe() {
        if(isSecondLoad){
            history.push('/renovacion/success')
        } else {
            setIsSecondLoad(true)
        }
    }

    const formatMoney = (value) => {
        return parseFloat(
            parseFloat(+value).toFixed(2)
        ).toLocaleString('es-MX', { minimumFractionDigits: 2 })
    }

    return (
        <div className='container-datos'>
            <div className='datos'>
                <p className='title'>Datos de tu seguro</p>
                <div className='flex-row'>
                    <p className='subText'>Número de póliza</p>
                    <p className='title'>{numPoliza}</p>
                </div>
                <div className='flex-row'>
                    <p className='subText'>Titular</p>
                    <p className='title'>{nombre}</p>
                </div>
                <div className='flex-row'>
                    <p className='subText'>Modalidad de pago</p>
                    <p className='title'>{modalidadPago}</p>
                </div>

                {
                    modalidadPago !== "Anual" && (
                        <>
                            <div className='flex-row'>
                                <p className='subText'>
                                    {currentPayment === 1 ? 'Primer pago de' : 'Pagos subsecuentes de'}
                                </p>
                                <p className='title'>
                                    ${formatMoney(currentPayment === 1 ? primerPago : pagoSubsecuente)}
                                </p>
                            </div>

                            <div className='flex-row'>
                                <p className='subText'>Número de pago de</p>
                                <p className='title'>
                                    {modalidadPago === "Semestral" 
                                        ? `${currentPayment} de 2` 
                                        : modalidadPago === "Trimestral" 
                                        ? `${currentPayment} de 4` 
                                        : modalidadPago === "Mensual" 
                                        ? `${currentPayment} de 12` 
                                        : ''}
                                </p>
                            </div>
                        </>
                    )
                }

                <div className='payment-info-container'>
                    <img src={logos[aseguradora]} alt='aseguradora'/>
                    <div className='payment-info'>
                        <p className='total'>Prima total</p>
                        <p className='amount'>${formatMoney(total)}</p>
                        <p className='costo-anual'>Costo Anual</p>
                    </div>
                    {
                        modalidadPago !== "Anual" && (
                            <div className='payment-importe-info'>
                                <p className='total'>Importe actual a pagar</p>
                                <p className='amount'>${formatMoney(currentTotal)}</p>
                            </div>
                        )
                    }
                </div>
            </div>
            <div className='iframe'>
                <iframe
                    style={{
                        width: '100%',
                        height: '100%',
                        border: '0px',
                        position: 'relative',
                    }}
                    onLoad={handleIframe}
                    src={url}
                    title='payment'
                    id='payment'
                />
            </div>
        </div>
    );
}
