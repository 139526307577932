// TabSeguros.js
import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import Preguntas from './Preguntas';

const TabSeguros = (props) => {
  const { currentTab, onSelectTabOption, inputValue } = props;
  const [selectedTab, setSelectedTab] = useState('Todos');

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    onSelectTabOption(newValue); // Llama a la función para almacenar la opción seleccionada
  };

  const tabPanelStyle = {
    padding: 0
  };

  return (
    <>
      <TabContext value={selectedTab}>
        <AppBar position="static" sx={{ boxShadow: 'none', background: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='mb-4'>
          <Tabs value={selectedTab} onChange={handleTabChange}>
            <Tab label="Todos" value="Todos" />
            <Tab label="Coche" value="Coche" />
            <Tab label="Mascotas" value="Mascota" />
            <Tab label="Salud" value="Salud" />
            <Tab label="Coberturas" value="Cobertura" />
          </Tabs>
        </AppBar>
        <TabPanel value="Todos" index="0" sx={tabPanelStyle}>
          <Preguntas currentTab={currentTab} inputValue={inputValue} />
        </TabPanel>
        <TabPanel value="Coche" index="1" sx={tabPanelStyle}>
          <Preguntas currentTab={currentTab} inputValue={inputValue} />
        </TabPanel>
        <TabPanel value="Mascota" index="2" sx={tabPanelStyle}>
          <Preguntas currentTab={currentTab} inputValue={inputValue} />
        </TabPanel>
        <TabPanel value="Salud" index="3" sx={tabPanelStyle}>
          <Preguntas currentTab={currentTab} inputValue={inputValue} />
        </TabPanel>
        <TabPanel value="Cobertura" index="4" sx={tabPanelStyle}>
          <Preguntas currentTab={currentTab} inputValue={inputValue} />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default TabSeguros;
