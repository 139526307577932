import H2 from '@material-tailwind/react/Heading2';

export default function Header() {
    return (
        <div className="relative pt-16 flex content-center items-center justify-center h-screen">
            <div className="bg-desk-background bg-cover bg-center absolute top-0 w-full h-full" />
            <div className="container md:w-10/12 relative mx-auto">
                <div className="items-left flex flex-wrap">
                    <div className="w-full px-12 mr-auto">
                        <H2 color="white">
                          Queremos cambiar el mundo de los seguros
                        </H2>
                        <div className='text-white font-40'>
                          Conoce más sobre nosotros
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
