import DefaultNavbar from "components/DefaultNavbar";
import HeaderRenovacion from "../components/HeaderRenovacion";
import { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Vortex } from "react-loader-spinner";
import Renovaciones from "../components/renovaciones/Renovaciones";
import DatosPasarelaRenovacion from "../components/DatosPasarelaRenovacion";
import NotFound from "../components/renovaciones/NotFound";

export default function Pasarela() {
  const [token, setToken] = useState("");
  const [numPoliza, setNumPoliza] = useState("");
  const [nombre, setNombre] = useState("");
  const [modalidadPago, setModalidadPago] = useState(""); // Anual, Semestral, Trimestral, Mensual
  const [total, setTotal] = useState("");
  const [url, setUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [primerPago, setPrimerPago] = useState("");
  const [pagoSubsecuente, setPagoSubsecuente] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [insurance, setInsurance] = useState("");
  const [currentPayment, setCurrentPayment] = useState(1);
  const [currentTotal, setCurrentTotal] = useState(0);
  const [isReceiptUrlValid, setIsReceiptUrlValid] = useState(true);

  const history = useHistory();

  const getToken = async () => {
    setIsLoading(true);
    const { data } = await axios.post(
      "https://api.inter.mx/renovaciones/authorization/token",
      {
        client_id: "B5LbQYZi51vDT9PYR66WyAkwM5mHp3GX",
        client_secret:
          "kiYBSmzo_YsrC4hJEtwaLpg7hs-PapDa4V2XQq_v8n5n3j8FE-Ed3B25tLfkDj1S",
      }
    );
    setToken(data.data.access_token);
  };

  const getPolizaNumber = () => {
    const fullUrl = window.location.href;
    const params = new URLSearchParams(fullUrl.split("?")[1]);
    history.replace("/renovacion/pasarela");
    const poliza = params.get("poliza");
    const aseguradora = params.get("aseguradora") || "mapfre";
    setInsurance(aseguradora);
    const negocio = params.get("negocio") || "medicoseguro";
    const recibo = params.get("recibo") || "0";

    if (poliza) {
      return {
        poliza,
        aseguradora,
        negocio,
        recibo,
      };
    }

    history.push("/");
  };

  const getPoliza = async () => {
    const { poliza, aseguradora, negocio, recibo } = getPolizaNumber();
    const { data } = await axios.get(
      `https://api.inter.mx/renovaciones/policies/findOneWithEndoso?NUM_POLIZA_NUEVO=${poliza}&negocio=${negocio}&aseguradora=${aseguradora}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    const datos = data.data[0].data;
    const inProgressReceipt = datos?.IN_PROGRESS_RECEIPT || 0;
    const receipts = datos?.receipts || [];
    const currentReceipt = receipts.find((receipt) => {
      return receipt.receipt === inProgressReceipt;
    });

    if (negocio === 'medicoseguro') {
      if (['Paid', 'InProgress'].includes(currentReceipt?.status)) {
        history.replace("/renovacion/success");
      };
    } else {
      if (datos.PAYMENT_STATUS === 'True' || datos.paymentStatus === 'True') {
        history.replace("/renovacion/success");
      };
    };

    setNumPoliza(datos.NUM_POLIZA_NUEVO || datos.newPolicy);
    setNombre(
      `${datos.NOMBRE || datos.firstName} ${
        datos.AP_PATERNO || datos.secondName || ""
      } ${datos.AP_MATERNO || datos.lastName || ""}`
    );
    setModalidadPago(
      +datos.FP === 1 || +receipts[0]?.totalReceipts === 1
        ? "Anual"
        : +datos.FP === 2 || +receipts[0]?.totalReceipts === 2
        ? "Semestral"
        : +datos.FP === 4 || +receipts[0]?.totalReceipts === 4
        ? "Trimestral"
        : "Mensual"
    );
    setTotal(datos.PRIMA_TOTAL || datos.total);
    setUrl(currentReceipt?.serviceUrl || datos.PASARELA_URL || datos.serviceUrl);
    setPrimerPago(receipts[0]?.totalPremium || datos.PRIMA_TOTAL_1);
    setPagoSubsecuente(
      receipts[1]?.totalPremium || datos?.PRIMA_TOTAL_SUB
    );
    setIsLoading(false);
    setPaymentStatus(currentReceipt?.status || datos.PAYMENT_STATUS || datos.paymentStatus);
    setCurrentPayment(inProgressReceipt);
    setCurrentTotal(
      inProgressReceipt === 1
        ? receipts[0]?.totalPremium || datos.PRIMA_TOTAL_1
        : receipts[1]?.totalPremium || datos?.PRIMA_TOTAL_SUB
    );
    setIsReceiptUrlValid(
      !(+recibo === 0 || +recibo !== inProgressReceipt || inProgressReceipt === 0)
    );
  };

  useEffect(() => {
    if (token !== "") {
      getPoliza();
    }
  }, [token]);

  useEffect(() => {
    getToken();
  }, []);

  return (
    <>
      {!isReceiptUrlValid ? (
        <NotFound />
      ) : (
        <>
          <DefaultNavbar />
          {paymentStatus === "True" ||
          paymentStatus === "Paid" ||
          paymentStatus === "InProgress" ? (
            <Renovaciones />
          ) : (
            <>
              <HeaderRenovacion />
              {isLoading ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Vortex
                    visible={true}
                    height="180"
                    width="180"
                    ariaLabel="vortex-loading"
                    wrapperStyle={{}}
                    wrapperClass="vortex-wrapper"
                    colors={[
                      "#469dc8",
                      "#469dc8",
                      "#469dc8",
                      "#469dc8",
                      "#469dc8",
                      "#469dc8",
                    ]}
                  />
                </div>
              ) : (
                <DatosPasarelaRenovacion
                  numPoliza={numPoliza}
                  nombre={nombre}
                  modalidadPago={modalidadPago}
                  total={total}
                  url={url}
                  primerPago={primerPago}
                  pagoSubsecuente={pagoSubsecuente}
                  aseguradora={insurance}
                  currentPayment={currentPayment}
                  currentTotal={currentTotal}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
