import { useEffect } from 'react';
import DefaultNavbar from 'components/DefaultNavbar';
import DefaultFooter from 'components/DefaultFooter';
import Header from 'components/terminos/Header';
import TermnsSection from 'components/terminos/TermsSection';

export default function Termns() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
            <DefaultNavbar />
            <main>
                <Header />
                <TermnsSection />
            </main>
            <DefaultFooter />
        </>
    );
}
