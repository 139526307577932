import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function Header() {
    return (
        <div className="relative flex content-center justify-center h-screen">
            <div className="bg-blog-background bg-cover absolute top-0 w-full h-full" />
            <div className="container md:w-10/12 relative mx-auto">
                <div className="items-left flex flex-wrap mt-48">
                    <div className="w-full md:w-10/12 px-4 mr-auto">
                        <div className='text-white pb-10'>
                            <Link to='/blog' >
                                <ArrowBackIcon />Regresar
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
