import BG1 from "assets/img/blog/08.jpg";
import BG2 from "assets/img/blog/07.jpg";
import BG3 from "assets/img/blog/06.jpg";
import BG4 from "assets/img/blog/05.jpg";
import BG5 from "assets/img/blog/04.jpg";
import BG6 from "assets/img/blog/03.jpg";
import BG7 from "assets/img/blog/02.jpg";
import BG8 from "assets/img/blog/01.jpg";

import BG19 from "assets/img/blog/19.jpg";
import BG20 from "assets/img/blog/20.jpg";
import BG21 from "assets/img/blog/21.jpg";
import BG22 from "assets/img/blog/22.jpg";
import BG23 from "assets/img/blog/23.jpg";
import BG24 from "assets/img/blog/24.jpg";
import BG25 from "assets/img/blog/25.jpg";
import BG26 from "assets/img/blog/26.jpg";

import BG27 from "assets/img/blog/27.jpg";
import BG28 from "assets/img/blog/28.jpg";
import BG29 from "assets/img/blog/29.jpg";
import BG30 from "assets/img/blog/30.jpg";
import BG31 from "assets/img/blog/31.jpg";
import BG32 from "assets/img/blog/32.jpg";
import BG33 from "assets/img/blog/33.jpg";
import BG34 from "assets/img/blog/34.jpg";

import BG35 from "assets/img/blog/35.jpg";
import BG36 from "assets/img/blog/36.jpg";
import BG37 from "assets/img/blog/37.jpg";
import BG38 from "assets/img/blog/38.jpg";
import BG39 from "assets/img/blog/39.jpg";
import BG40 from "assets/img/blog/40.jpg";
import BG41 from "assets/img/blog/41.jpg";
import BG42 from "assets/img/blog/42.jpg";

import BG43 from "assets/img/blog/43.jpg";
import BG44 from "assets/img/blog/44.jpg";
import BG45 from "assets/img/blog/45.jpg";
import BG46 from "assets/img/blog/46.jpg";
import BG47 from "assets/img/blog/47.jpg";
import BG48 from "assets/img/blog/48.jpg";
import BG49 from "assets/img/blog/49.jpg";
import BG50 from "assets/img/blog/50.jpg";

import BG51 from "assets/img/blog/51.jpg";
import BG52 from "assets/img/blog/52.jpg";
import BG53 from "assets/img/blog/53.jpg";
import BG54 from "assets/img/blog/54.jpg";
import BG55 from "assets/img/blog/55.jpg";
import BG56 from "assets/img/blog/56.jpg";
import BG57 from "assets/img/blog/57.jpg";
import BG58 from "assets/img/blog/58.jpg";
import BG59 from "assets/img/blog/59.jpg";

import BG60 from "assets/img/blog/60.jpg";

import BG61 from "assets/img/blog/61.jpg";
import BG62 from "assets/img/blog/62.jpg";
import BG63 from "assets/img/blog/63.jpg";
import BG64 from "assets/img/blog/64.jpg";
import BG65 from "assets/img/blog/65.jpg";
import BG66 from "assets/img/blog/66.jpg";
import BG67 from "assets/img/blog/67.jpg";
import BG68 from "assets/img/blog/68.jpg";

import BG69 from "assets/img/blog/69.jpg";
import BG70 from "assets/img/blog/70.jpg";
import BG71 from "assets/img/blog/71.jpg";
import BG72 from "assets/img/blog/72.jpg";
import BG73 from "assets/img/blog/73.jpg";
import BG74 from "assets/img/blog/74.jpg";

import BG75 from "assets/img/blog/75.jpg";
import BG76 from "assets/img/blog/76.jpg";
import BG77 from "assets/img/blog/77.jpg";
import BG78 from "assets/img/blog/78.jpg";
import BG79 from "assets/img/blog/79.jpg";
import BG80 from "assets/img/blog/80.jpg";
import BG81 from "assets/img/blog/81.jpg";
import BG82 from "assets/img/blog/82.jpg";

import BG83 from "assets/img/blog/83.jpg";
import BG84 from "assets/img/blog/84.jpg";
import BG85 from "assets/img/blog/85.jpg";
import BG86 from "assets/img/blog/86.jpg";

import BG87 from "assets/img/blog/87.jpg";
import BG88 from "assets/img/blog/88.jpg";
import BG89 from "assets/img/blog/89.jpg";
import BG90 from "assets/img/blog/90.jpg";

import BG91 from "assets/img/blog/91.jpg";
import BG92 from "assets/img/blog/92.jpg";
import BG93 from "assets/img/blog/93.jpg";
import BG94 from "assets/img/blog/94.jpg";

import BG95 from "assets/img/blog/95.jpg";
import BG96 from "assets/img/blog/96.jpg";
import BG97 from "assets/img/blog/97.jpg";
import BG98 from "assets/img/blog/98.jpg";

import BG99 from "assets/img/blog/99.jpg";
import BG100 from "assets/img/blog/100.jpg";
import BG101 from "assets/img/blog/101.jpg";
import BG102 from "assets/img/blog/102.jpg";

import BG103 from "assets/img/blog/103.jpg";
import BG104 from "assets/img/blog/104.webp";
import BG105 from "assets/img/blog/105.webp";
import BG106 from "assets/img/blog/106.jpg";

import BG107 from "assets/img/blog/107.webp";
import BG108 from "assets/img/blog/108.webp";
import BG109 from "assets/img/blog/109.webp";
import BG110 from "assets/img/blog/110.webp";

import BG111 from "assets/img/blog/111.webp";
import BG112 from "assets/img/blog/112.webp";
import BG113 from "assets/img/blog/113.webp";
import BG114 from "assets/img/blog/114.webp";

import BG115 from "assets/img/blog/115.webp";
import BG116 from "assets/img/blog/116.webp";
import BG117 from "assets/img/blog/117.webp";
import BG118 from "assets/img/blog/118.webp";

import BG119 from "assets/img/blog/119.webp";
import BG120 from "assets/img/blog/120.webp";
import BG121 from "assets/img/blog/121.webp";

import BG122 from "assets/img/blog/122.webp";
import BG123 from "assets/img/blog/123.webp";
import BG124 from "assets/img/blog/124.webp";
import BG125 from "assets/img/blog/125.webp";
import BG126 from "assets/img/blog/126.webp";

import BG127 from "assets/img/blog/127.webp";
import BG128 from "assets/img/blog/128.webp";
import BG129 from "assets/img/blog/129.webp";
import BG130 from "assets/img/blog/130.webp";

import BG131 from "assets/img/blog/131.webp";
import BG132 from "assets/img/blog/132.webp";
import BG133 from "assets/img/blog/133.webp";
import BG134 from "assets/img/blog/134.webp";

import BG135 from "assets/img/blog/135.png";
import BG136 from "assets/img/blog/136.png";
import BG137 from "assets/img/blog/137.webp";
import BG138 from "assets/img/blog/138.png";

import Promo from 'assets/img/promos/mobile/Seccion_promos_activaMobile_18_Septiembre.webp';
import Promo1 from "assets/img/promos/past/contigocheco_arabia.jpg";
import Promo2 from "assets/img/promos/past/bn-blackfriday-cs.jpg";
import Promo3 from "assets/img/promos/past/bn-blackfriday-gmm.jpg";
import Promo4 from "assets/img/promos/past/masterspromos_buenfin_checo_330x330.jpg";
import Promo5 from "assets/img/promos/past/promo-gp-mx.png";
import Promo6 from "assets/img/promos/past/bn_buenfin_coche.jpg";
import Promo7 from "assets/img/promos/past/contigocheco_abu_dhabi.jpg";
import Promo8 from "assets/img/promos/past/banner-gmm-past.jpg";
import Promo9 from "assets/img/promos/past/interenero.png";
import Promo10 from "assets/img/promos/past/bn-key.png";
import Promo11 from "assets/img/promos/past/bn-febrero2022.jpg";
import Promo12 from "assets/img/promos/past/bn-cocheseguro-feb2022.png";
import Promo13 from "assets/img/promos/past/PLANSALUD2022_pasada_desktop_330x330-20220303-001202.png";
import Promo14 from "assets/img/promos/past/bannersweb_checoleyenda_rojo_330x330.jpg";
import Promo15 from "assets/img/promos/past/bannersweb_marzo_gmm_corazon_330x330.png";
import Promo16 from "assets/img/promos/past/bannersweb_promo_amazon_azul_330x330.png";
import Promo17 from "assets/img/promos/past/bannersweb_checoleyenda_rojo_.jpg";
import Promo18 from "assets/img/promos/past/Web_semanasanta_moto_330x330.png";
import Promo19 from "assets/img/promos/past/web_semanasanta_coche_330x330_.png";
import Promo20 from "assets/img/promos/past/bannersweb_checoleyenda_rojos_330x330.jpg";
import Promo21 from "assets/img/promos/past/BannersWeb_promo_abril_gmm_330x330.png";
import Promo22 from "assets/img/promos/past/MOTO_AMAZON_500_330x330.png";
import Promo23 from "assets/img/promos/past/BanersW_PROMO_COCHE_500_330X330.png";
import Promo24 from "assets/img/promos/past/bannersweb_checoleyenda_amazon_330x330.jpg";
import Promo25 from "assets/img/promos/past/MOTO_SEGURA_330x330.png";
import Promo26 from "assets/img/promos/past/bannersweb_promo_diadelasmadres_coche_330x330.jpg";
import Promo27 from "assets/img/promos/past/BannersW_PROMO_DIADELASMADRES_GMM_330x330.png";
import Promo28 from "assets/img/promos/past/banners_moto_madres_1_330x330.png";
import Promo29 from "assets/img/promos/past/MOTO_SEGURA_330x330.png";
import Promo30 from "assets/img/promos/past/bannersweb_checoleyenda_amazon_330x330.jpg";
import Promo31 from "assets/img/promos/past/Promo03_Hotsale_Coche_330x330.png";
import Promo32 from "assets/img/promos/past/bannersweb_hotsale_1_moto_330x330.png";
import Promo33 from "assets/img/promos/past/bannersweb_hotsale_2_gmm_330x330.jpg";
import Promo34 from "assets/img/promos/past/bannersweb_checoleyenda_rojo_330x330.jpg";
import Promo35 from "assets/img/promos/past/MOTO_SEGURA_330x330.png";
import Promo36 from "assets/img/promos/past/banners_posthotsale_moto_330x330.jpg";
import Promo37 from "assets/img/promos/past/BannersWeb_PostHotsale_GMM_330x330.jpg";
import Promo38 from "assets/img/promos/past/banners_posthotsale_coche_330x330.jpg";
import Promo39 from "assets/img/promos/past/bannersweb_checoleyenda_amazon_330x330(2).jpg";
import Promo40 from "assets/img/promos/past/MOTO_SEGURA_330x330(2).png";
import Promo41 from "assets/img/promos/past/Banners_Diadelpadre_amazon_330x330.jpg";
import Promo42 from "assets/img/promos/past/Banners_raceweekCanada_330x330.jpg";
import Promo43 from "assets/img/promos/past/BannersMoto_raceweekCanada_330x330.jpg";
import Promo44 from "assets/img/promos/past/Banners_amazon500_gmm_330x330.png";
import Promo45 from "assets/img/promos/past/bannersweb_checoleyenda_rojo_330x330.jpg";
import Promo46 from "assets/img/promos/past/bannersweb_checoleyenda_amazon_330x330(3).jpg";
import Promo47 from "assets/img/promos/past/MOTO_SEGURA_330x330(3).png";
import Promo48 from "assets/img/promos/past/WEB_MOTOYCOCHE_$500_330x330.png";
import Promo49 from "assets/img/promos/past/bannersweb_RW_coche_moto_1000_330x330.jpg";
import Promo50 from "assets/img/promos/past/bannersweb_RW_coche_moto_500_330x330.jpg";
import Promo51 from "assets/img/promos/past/NewBanners_amazon500_gmm_330x330.png";
import Promo52 from "assets/img/promos/past/bannersweb_RW_coche_moto_1000_330x330(2).jpg";
import Promo53 from "assets/img/promos/past/Banner_amazon_verano_gmm_330x330.webp";
import Promo54 from "assets/img/promos/past/bannersweb_RW_coche_moto_500_330x330.webp";
import Promo55 from "assets/img/promos/past/rs_raceweek_italia_cochemoto_330x330.webp";
import Promo56 from "assets/img/promos/past/Banners_back2school_gmm_330x330.webp";
import Promo57 from "assets/img/promos/past/bannersweb_mespatrio_coche_330x330.webp";
import Promo58 from "assets/img/promos/past/rs_raceweek_italia_cochemoto_330x330(2).webp";
import Promo59 from "assets/img/promos/past/Banners_mespatrio_gmm_330x330.webp";
import Promo60 from "assets/img/promos/past/nners_raceweek_750_cochemoto_330x330.webp";
import Promo61 from "assets/img/promos/past/rs_raceweek_Austin_cochemoto_330x330.webp";
import Promo62 from "assets/img/promos/past/nners_raceweek_1000_cochemoto_330x330.webp";
import Promo63 from "assets/img/promos/past/web_gmm_750amazon_cancermama_330x330.webp";
import Promo64 from "assets/img/promos/past/Banners_diademuertos_cochemoto_330x330.jpg";
import Promo65 from "assets/img/promos/past/banners_raceweek_brasil_cochemoto_330x330.jpg";
import Promo66 from "assets/img/promos/past/banners_diademuertos_medico_330x330(3).jpg";
import Promo67 from "assets/img/promos/past/banners_raceweek_abu-dhabi_cochemoto_330x330.jpg";
import Promo68 from "assets/img/promos/past/banners_buenfin_coche_2_330x330.jpg";
import Promo69 from "assets/img/promos/past/Bannersweb_buenfin_medico_2_330x330.png";
import Promo70 from "assets/img/promos/past/Blackfriday_Coche_Banners_330x330.jpg";
import Promo71 from "assets/img/promos/past/Blackfriday_Medico_Banners_330x330.jpg";
import Promo72 from "assets/img/promos/past/banners_cybermonday_coche_330x330.jpg";
import Promo73 from "assets/img/promos/past/Banners_cybermonday_gmm_330x330.jpg";
import Promo74 from "assets/img/promos/past/banners_amazon_diciembre_coche_330x330.jpg";
import Promo75 from "assets/img/promos/past/Banners_promodiciembre_gmm_330x330.jpg";
import Promo76 from "assets/img/promos/past/banners_gmm_enero800_330x330.jpg";
import Promo77 from "assets/img/promos/past/Banners_coche_enero800_330x330.jpg";
import Promo78 from "assets/img/promos/past/banners-amistad-coche-330x330.webp";
import Promo79 from "assets/img/promos/past/ersaweb-amistad-me204129dico-330x330.webp";
import Promo80 from "assets/img/promos/past/banners_raceweek_500_330x330.webp";
import Promo81 from "assets/img/promos/past/baners-raceweek-750-330x330.webp";
import Promo82 from "assets/img/promos/past/banners-raceweek-500-330x330.webp";
import Promo83 from "assets/img/promos/past/BANNERS_330x330_COCHE_SEMANASANTA.webp";
import Promo84 from "assets/img/promos/past/Banners_promos_pasada_Arzebaiyan.webp";
import Promo85 from "assets/img/promos/past/CS-RaceWeek-Miami(330x330).webp";
import Promo86 from "assets/img/promos/past/promo_pasada_CSyMS_10Mayo.webp";
import Promo87 from "assets/img/promos/past/promoImolaNew_pasada.webp";
import Promo88 from "assets/img/promos/past/promo_pasada-Monaco.webp";
import Promo89 from "assets/img/promos/past/bannerpromo_CS-HS_intermx_330x330.webp";
import Promo90 from "assets/img/promos/past/bannerpromo-HS-MS_intermx_330x330.webp";
import Promo91 from "assets/img/promos/past/bannermaspromoCS_30porcientoOff_330x330.webp";
import Promo92 from "assets/img/promos/past/bannermaspromoMS_30porcientoOff_330x330.webp";
import Promo93 from "assets/img/promos/past/CS-Raceweek-Canada(330x330).webp";
import Promo94 from "assets/img/promos/past/banners-diapadre-coche-checo-330x330.webp";
import Promo95 from "assets/img/promos/past/bannerRaceweekAustria_330x330.webp";
import Promo96 from "assets/img/promos/past/promos_pasada-Raceweek-Silverstone.webp";
import Promo97 from "assets/img/promos/past/Seccion_promos_pasada.webp";
import Promo98 from "assets/img/promos/past/Seccion_promos_pasada_2_agosto_cs.webp";
import Promo99 from "assets/img/promos/past/Seccion_promos_pasada_2_agosto_ms.webp";
import Promo100 from "assets/img/promos/past/Seccion_promos_pasada_28_agosto.webp";
import Promo101 from "assets/img/promos/past/Seccion_promos_pasada_04_Septiembre.webp";
import Promo102 from 'assets/img/promos/past/Seccion_promos_pasada_11_Septiembre.webp';
import Promo103 from 'assets/img/promos/past/Seccion_promos_pasada_18_Septiembre.webp';
import Promo104 from 'assets/img/promos/past/CS-RaceWeek-Brasil(330x330).webp';
import Promo08 from "assets/img/landingRedisign/Promociones/CS-RaceWeek-LasVegas(330x330).webp";

export const ImagenesBlog = {
  BG1,
  BG2,
  BG3,
  BG4,
  BG5,
  BG6,
  BG7,
  BG8,
  BG19,
  BG20,
  BG21,
  BG22,
  BG23,
  BG24,
  BG25,
  BG26,
  BG27,
  BG28,
  BG29,
  BG30,
  BG31,
  BG32,
  BG33,
  BG34,
  BG35,
  BG36,
  BG37,
  BG38,
  BG39,
  BG40,
  BG41,
  BG42,
  BG43,
  BG44,
  BG45,
  BG46,
  BG47,
  BG48,
  BG49,
  BG50,
  BG51,
  BG52,
  BG53,
  BG54,
  BG55,
  BG56,
  BG57,
  BG58,
  BG59,
  BG60,
  BG61,
  BG62,
  BG63,
  BG64,
  BG65,
  BG66,
  BG67,
  BG68,
  BG69,
  BG70,
  BG71,
  BG72,
  BG73,
  BG74,
  BG75,
  BG76,
  BG77,
  BG78,
  BG79,
  BG80,
  BG81,
  BG82,
  BG83,
  BG84,
  BG85,
  BG86,
  BG87,
  BG88,
  BG89,
  BG90,
  BG91,
  BG92,
  BG93,
  BG94,
  BG95,
  BG96,
  BG97,
  BG98,
  BG99,
  BG100,
  BG101,
  BG102,
  BG103,
  BG104,
  BG105,
  BG106,
  BG107,
  BG108,
  BG109,
  BG110,
  BG111,
  BG112,
  BG113,
  BG114,
  BG115,
  BG116,
  BG117,
  BG118,
  BG119,
  BG120,
  BG121,
  BG122,
  BG123,
  BG124,
  BG125,
  BG126,
  BG127,
  BG128,
  BG129,
  BG130,
  BG131,
  BG132,
  BG133,
  BG134,
  BG135,
  BG136,
  BG137,
  BG138,

};

export const ImaganesPromos = {
  Promo,
  Promo1,
  Promo2,
  Promo3,
  Promo4,
  Promo5,
  Promo6,
  Promo7,
  Promo8,
  Promo9,
  Promo10,
  Promo11,
  Promo12,
  Promo13,
  Promo14,
  Promo15,
  Promo16,
  Promo17,
  Promo18,
  Promo19,
  Promo20,
  Promo21,
  Promo22,
  Promo23,
  Promo24,
  Promo25,
  Promo26,
  Promo27,
  Promo28,
  Promo29,
  Promo30,
  Promo31,
  Promo32,
  Promo33,
  Promo34,
  Promo35,
  Promo36,
  Promo37,
  Promo38,
  Promo39,
  Promo40,
  Promo41,
  Promo42,
  Promo43,
  Promo44,
  Promo45,
  Promo46,
  Promo47,
  Promo48,
  Promo49,
  Promo50,
  Promo51,
  Promo52,
  Promo53,
  Promo54,
  Promo55,
  Promo56,
  Promo57,
  Promo58,
  Promo59,
  Promo60,
  Promo61,
  Promo62,
  Promo63,
  Promo64,
  Promo65,
  Promo66,
  Promo67,
  Promo68,
  Promo69,
  Promo70,
  Promo71,
  Promo72,
  Promo73,
  Promo74,
  Promo75,
  Promo76,
  Promo77,
  Promo78,
  Promo79,
  Promo80,
  Promo81,
  Promo82,
  Promo83,
  Promo84,
  Promo85,
  Promo86,
  Promo87,
  Promo88,
  Promo89,
  Promo90,
  Promo91,
  Promo92,
  Promo93,
  Promo94,
  Promo95,
  Promo96,
  Promo97,
  Promo98,
  Promo99,
  Promo100,
  Promo101,
  Promo102,
  Promo103,
  Promo104,
  Promo08
};
