import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from 'App';
import reportWebVitals from 'reportWebVitals';
import { createTheme, ThemeProvider } from '@mui/material';
const theme = createTheme({
    typography: {
      fontFamily: ['Montserrat'].join(',')
    }
})

ReactDOM.render(
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </BrowserRouter>
    ,
    document.getElementById('root-index')
);
reportWebVitals();
