import { useEffect,useRef } from "react";
import { Container, Grid, Box, Stack, Toolbar, Typography } from '@mui/material'
import {Element} from 'react-scroll';
import DefaultNavbar from "components/DefaultNavbar";
import DefaultFooter from "components/DefaultFooter";
import Header from "components/landing/Header";
import PickInsurance from "components/insurances/PickInsurance"
import AboutUs from "components/landing/AboutUs";
import ContractwithUs from "components/landing/ContractwithUs";
import BrandSection from "components/landing/BrandSection";
import Opiniones from "components/opiniones/Opiniones";
import AntiFraudHome from "../components/antiFraud/AntiFraudHome"

export default function Landing() {

  const scrollToElementRef = useRef(null);

  useEffect(() => {
    if (scrollToElementRef.current) {
      scrollToElementRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    fetch("https://api.ipify.org?format=json")
      .then((resp) => resp.json())
      .then((data) => {
        if (data.ip) {
          fetch("https://ipwhois.app/json/" + data.ip + "?lang=es")
            .then((response) => response.json())
            .then((info) => {
              if (info.country_code === "US") {
                window.location.href = "/us";
              }
            });
        }
      });
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <DefaultNavbar/>
      <main>
        <Header/>
        <Element name="pickInsurance"><PickInsurance /></Element>
        <AboutUs/>
        <ContractwithUs/>
        <BrandSection />
        <Opiniones />
          <AntiFraudHome/>
      </main>
      <DefaultFooter />
    </>
  );
}
