import Gear from "../../assets/img/landingUs/Icon-Gear.png";
import Select from "../../assets/img/landingUs/Icon-Select.png";
import Save from "../../assets/img/landingUs/Icon-Savings.png";
import Heart from "../../assets/img/landingUs/Icon-Heart.png";

export default function InterMean() {
  return (
    <div className="interContainer">
      <h1 className="interTitleMean">¿Qué es inter.mx?</h1>
      <div className="interDiv">
        <div className="interObjects">
          <img src={Gear} alt="firstImage" />
          <h2 className="interSubtitle pt-6">No somos una aseguradora</h2>
          <p className="interDescription pt-4">
            Somos tu aliado digital de seguros para que encuentres los mejores
            seguros para tus parientes y amigos en México.
          </p>
        </div>
        <div className="interObjects">
          <img src={Select} alt="secondImage" />
          <h2 className="interSubtitle">Personaliza tu seguro</h2>
          <p className="interDescription pt-4">
            Cotiza y compara en un solo lugar con las mejores aseguradoras para
            que elijas la mejor opción.
          </p>
        </div>
        <div className="interObjects">
          <img src={Save} alt="thirdImage" />
          <h2 className="interSubtitle ">La mejor cobertura al mejor precio</h2>
          <p className="interDescription pt-4">
            Elije la cobertura, quítale, pónle lo que necesites y elije la forma
            de pago que más te convenga.
            <br />
            <br />
            Aceptamos tarjetas de todo el mundo.
          </p>
        </div>
        <div className="interObjects">
          <img src={Heart} alt="fourthImage" />
          <h2 className="interSubtitle">Sin complicaciones</h2>
          <p className="interDescription pt-8">
            Te ayudamos a que toda tu experiencia sea más fácil y rápida. Tienes
            tu seguro en minutos y un app que te acompaña en caso de
            necesitarlo.
          </p>
        </div>
      </div>
    </div>
  );
}
