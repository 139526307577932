import { Grid} from '@mui/material';
/* import { ReactComponent as HandShake } from 'assets/img/sections/handshake.svg'; */
import HandShake from 'assets/img/sections/handshake.webp'
/* import { ReactComponent as Compare } from 'assets/img/sections/compare.svg'; */
import Compare from 'assets/img/sections/compare.webp'
/* import { ReactComponent as Custom } from 'assets/img/sections/custom.svg'; */
import Custom from 'assets/img/sections/custom.webp'
import { ReactComponent as Device } from 'assets/img/sections/device.svg';
/* import Device from 'assets/img/sections/device.webp' */
/* import { ReactComponent as Google } from 'assets/img/marketing/google-black.svg'; */
import Google from 'assets/img/marketing/google-black.webp'
//import { ReactComponent as Apple } from 'assets/img/marketing/apple-black.svg';
import Apple from 'assets/img/marketing/apple-black.webp'

export default function TeamSection() {
    return (
        <section className="pb-5 bg-gray-100" >
            <div className="container md:w-10/12 mx-auto px-4 text-center">
                <div style={{fontWeight: 700, fontSize: 28, paddingBottom: 15}} >
                    ¿Por qué INTER?
                </div>
                <Grid container columns={12}>
                    <Grid xs={12} md={6} xl={6} >
                        <div style={{padding: 10}}>
                            <div className='flex wrap-flex team-section-container'>
                                <div style={{padding: 15, display: 'grid'}}>
                                    {/* <HandShake className='margin-auto' /> */}
                                    <img src={HandShake} alt='' style={{margin: 'auto'}}></img>
                                </div>
                                <div className='text-left team-section-text'>
                                    <div>
                                        <div className='blue team-section-header'>Siempre a tu lado</div>
                                        <div className='team-section-footer' >
                                            Vive una experiencia personalizada y disfruta la tranquilidad de sentirte protegido, escuchado y <span className='font-bold' >respaldado de principio a fin.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid xs={12} md={6} xl={6}>
                        <div style={{padding: 10}} >
                            <div className='flex wrap-flex team-section-container'>
                                <div style={{padding: 15, display: 'grid'}}>
                                    {/* <Compare className='margin-auto' /> */}
                                    <img src={Compare} alt='' style={{margin: 'auto'}}></img>
                                </div>
                                <div className='text-left team-section-text'>
                                    <div>
                                        <div className='blue team-section-header'>Encuentra el mejor seguro</div>
                                        <div className='team-section-footer' >
                                            Cotiza con las aseguradoras líderes y obtén <span className='font-bold' >la mejor cobertura, al mejor precio,</span> de forma fácil, rápida y segura.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid xs={12} md={6} xl={6} spacing={5}>
                        <div style={{padding: 10}}>
                            <div className='flex wrap-flex team-section-container'>
                                <div style={{padding: 15, display: 'grid'}}>
                                    {/* <Custom className='margin-auto' /> */}
                                    <img src={Custom} alt='' style={{margin: 'auto'}}></img>
                                </div>
                                <div className='text-left team-section-text'>
                                    <div>
                                        <div className='blue team-section-header'>Personaliza tu seguro</div>
                                        <div className='team-section-footer' >
                                            Elige la mejor <span className='font-bold' >cobertura</span> para ti y la forma de <span className='font-bold' >pago que más se ajuste a tus necesidades.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid xs={12} md={6}  xl={6}>
                        <div style={{padding: 10}} className='icons-team-lg'>
                            <div className='flex wrap-flex team-section-container'>
                                <div style={{padding: 15, display: 'grid'}}>
                                    <Device className='margin-auto' style={{width: 110}}/>
                                </div>
                                <div className='text-left team-section-text'>
                                    <div>
                                        <div className='blue team-section-header'>Tus seguros en un sólo lugar</div>
                                        <div className='team-section-footer' >
                                            Contrata en línea tu seguro y <span className='font-bold' >obtén tu póliza</span> en minutos <span className='font-bold' >llevándolas siempre contigo en nuestra app.</span>
                                        </div>
                                        <div style={{display: 'flex', justifyContent: 'center'}} >
                                            <div style={{paddingRight: 5}}>
                                                <a href='https://play.google.com/store/apps/details?id=grut.inter.mx' target='_blank' rel="noreferrer" >
                                                    {/* <Google style={{margin: 'left'}} ></Google> */}
                                                    <img src={Google} alt='' style={{margin: 'left'}} ></img>
                                                </a>
                                            </div>
                                            <div style={{paddingLeft: 5}}>
                                                <a href='https://apps.apple.com/mx/app/intermx/id1548367767?l=en' target='_blank' rel="noreferrer" >
                                                    {/* <Apple style={{margin: 'auto'}} ></Apple> */}
                                                    <img src={Apple} alt='' style={{margin: 'auto'}}></img>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='icons-team-md' style={{padding: 10}} >
                            <div className='team-section-container' style={{display: 'grid'}}>
                                <div className='flex wrap-flex'>
                                    <div style={{padding: 15, display: 'grid'}}>
                                        {/* <Device className='margin-auto' style={{width:110}} /> */}
                                        <img src={Device} className='margin-auto' alt='' style={{width:110}}></img>
                                    </div>
                                    <div className='text-left team-section-text'>
                                        <div>
                                            <div className='blue team-section-header'>Tus seguros en un sólo lugar</div>
                                            <div className='team-section-footer' >
                                                Contrata en línea tu seguro y <span className='font-bold' >obtén tu póliza</span> en minutos <span className='font-bold' >llevándolas siempre contigo en nuestra app.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center'}} >
                                    <div style={{paddingRight: 5}}>
                                        <a href='https://play.google.com/store/apps/details?id=grut.inter.mx' target='_blank' rel="noreferrer" >
                                            {/* <Google style={{margin: 'left'}} ></Google> */}
                                            <img src={Google} alt='' style={{margin: 'left'}} ></img>
                                        </a>
                                    </div>
                                    <div style={{paddingLeft: 5}}>
                                        <a href='https://apps.apple.com/mx/app/intermx/id1548367767?l=en' target='_blank' rel="noreferrer" >
                                            {/* <Apple style={{margin: 'auto'}} ></Apple> */}
                                            <img src={Apple} alt='' style={{margin: 'auto'}} ></img>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </section>
    );
}
