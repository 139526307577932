import StatusCard from 'components/blogInfo/StatusCard';

export default function WorkingSection() {
    return (
        <section className="pb-5 bg-gray-100">
            <div className="container mx-auto px-4 md:w-10/12">
                <div className="flex flex-wrap relative z-50 -mt-544 mx-auto ">
                    <StatusCard />
                </div>
            </div>
        </section>
    );
}
