import Form from './Form';

export default function WorkingSection() {
    return (
        <section className="pt-16 bg-working-background bg-cover form-lg">
            <div className="container mx-auto md:w-10/12 px-4 pb-5">
                <div className="flex flex-wrap relative z-50 -mt-32 mx-auto ">
                    <Form />
                </div>
            </div>
        </section>
    );
}
