import React from 'react';
import { Link } from 'react-router-dom';
import { Grid,Box } from '@mui/material';
import { ReactComponent as LogoInter } from 'assets/img/footer/LogoInter-w.svg';
import { ReactComponent as LogoApple } from 'assets/img/footer/App_Store.svg';
import { ReactComponent as LogoGoogle } from 'assets/img/footer/Google_play.svg';
import { ReactComponent as LFace } from 'assets/img/footer/FaceBook.svg';
import { ReactComponent as LInst } from 'assets/img/footer/Instagram.svg';
import { ReactComponent as LIn } from 'assets/img/footer/LinkedIn.svg';
import { ReactComponent as LTik } from 'assets/img/footer/Tik_Tok.svg';
import { ReactComponent as LYou } from 'assets/img/footer/YouTube.svg';
import { ReactComponent as LTwi } from 'assets/img/footer/X.svg';


export default function DefaultFooter() {
    //social networks buttons:
    const handleFacebook = (e) => {
        window.dataLayer.push({
            event : 'ev_social_networks', //Static data
            option:'Facebook', //Dynamic data
        });
    };

    const handleInstagram = (e) => {
        window.dataLayer.push({
            event : 'ev_social_networks', //Static data
            option:'Instagram', //Dynamic data
        });
    };

    const handleLinkedin = (e) => {
        window.dataLayer.push({
            event : 'ev_social_networks', //Static data
            option:'Linkedin', //Dynamic data
        });
    };

    const handleTiktok = (e) => {
        window.dataLayer.push({
            event : 'ev_social_networks', //Static data
            option:'Tiktok', //Dynamic data
        });
    };

    const handleYoutube = (e) => {
        window.dataLayer.push({
            event : 'ev_social_networks', //Static data
            option:'Youtube', //Dynamic data
        });
    };

    const handleTwitter = (e) => {
        window.dataLayer.push({
            event : 'ev_social_networks', //Static data
            option:'Twitter', //Dynamic data
        });
    };

    //footer button:
    const handleAppStore = (e) => {
        window.dataLayer.push({
            event : 'ev_btn_footer', //Static data
            option:'App Store', //Dynamic data
        });
    };

    const handleGooglePlay = (e) => {
        window.dataLayer.push({
            event : 'ev_btn_footer', //Static data
            option:'Google Play', //Dynamic data
        });
    };

    const handleContactoTelefono = (e) => {
        window.dataLayer.push({
            event : 'ev_btn_footer', //Static data
            option:'Tel', //Dynamic data
        });
    };

    const handleContactoCorreo = (e) => {
        window.dataLayer.push({
            event : 'ev_btn_footer', //Static data
            option:'Email', //Dynamic data
        });
    };

    const handleCocheSeguro = (e) => {
        window.dataLayer.push({
            event : 'ev_btn_footer', //Static data
            option:'Coche Seguro', //Dynamic data
        });
    };

    const handleMedicoSeguro = (e) => {
        window.dataLayer.push({
            event : 'ev_btn_footer', //Static data
            option:'Medico Seguro', //Dynamic data
        });
    };

    const handleMascotaSegura = (e) => {
        window.dataLayer.push({
            event : 'ev_btn_footer', //Static data
            option:'Mascota Segura', //Dynamic data
        });
    };


    return (
        <>
            <footer className="relative bg-footer">
                <Box className="container max-w-7xl mx-auto px-4"
                    sx={{pt:"24px",pb:"24px",
                        pl:{xs:'24px',sm:'16px',md:'24px',lg:'24px'},
                        pr:{xs:'24px',sm:'16px',md:'24px',lg:'24px'},
                    }}>
                    <Grid container>
                        <Grid item xs={12} sm={4} md={3}
                            sx={{
                                pr:{xs:'0px',sm:'24px',md:'40px',lg:'40px'}
                            }}>
                            <div>
                                <Link to='/'>
                                    <LogoInter></LogoInter>
                                </Link>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                            <Box className='header-footer'
                                sx={{pl:0,pb:'24px',
                                    pt:{xs:'24px',sm:'0px',md:'0px',lg:'0px'},
                                    pr:{xs:'0px',sm:'24px',md:'40px',lg:'40px'}
                                }}>Productos
                            </Box>
                            <Box
                                sx={{
                                    pr:{xs:'0px',sm:'24px',md:'40px',lg:'40px'}
                                }}>
                                <a href='https://cocheseguro.inter.mx/inicio' target='_blank' rel="noreferrer" onClick={handleCocheSeguro}>
                                    <div className='body-footer' >Coche Seguro</div>
                                </a>
                                <a href='https://motoseguro.inter.mx/' target='_blank' rel="noreferrer" onClick={handleCocheSeguro}>
                                    <div className='body-footer' >Moto Segura</div>
                                </a>
                                <a href='https://medicoseguro.inter.mx/' target='_blank' rel="noreferrer" onClick={handleMedicoSeguro}>
                                    <div className='body-footer' >Médico Seguro</div>
                                </a>
                                {/*<a href='https://productos.inter.mx/vida-segura' target='_blank' rel="noreferrer"  >
                                    <div className='body-footer' >Vida Seguro</div>
                                </a>*/}
                                <a href='https://mascotasegura.inter.mx/seguro-mascota/landing' target='_blank' rel="noreferrer" onClick={handleMascotaSegura}>
                                    <div className='body-footer' >Mascota Segura</div>
                                </a>
                                {/* <a href='https://productos.inter.mx/bien-seguro' target='_blank' rel="noreferrer"  >
                                    <div className='body-footer' >Bien Seguro</div>
                                </a>
                                <a href='https://vigo.inter.mx/landing' target='_blank' rel="noreferrer"  >
                                    <div className='body-footer' >Viaje Seguro</div>
                                </a> */}
                            </Box>
                            <Box sx={{
                                    pt:'24px',
                                    pr:{xs:'0px',sm:'24px',md:'40px',lg:'40px'}
                                }}>
                                <Box className='header-footer' 
                                    sx={{pb:'24px'}}>Clientes
                                </Box>
                                <div className='body-footer' >
                                    <Link to='/aviso-de-privacidad'>
                                    Aviso de privacidad
                                    </Link>
                                </div>
                                <div className='body-footer' >
                                    <Link to='/terminos-condiciones'>
                                    Términos y condiciones
                                    </Link>
                                </div>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} >
                            <Box sx={{ 
                                pt:0,pb:0,pl:0,
                                pr:{xs:'0px',sm:'0px',md:'40px',lg:'40px'}
                            }} >
                                <Box className='header-footer' 
                                    sx={{pb:'24px',pt:{xs:'24px',sm:'0px',md:'0px',lg:'0px'}}}>Contáctanos
                                </Box>
                                <div className='body-footer' >
                                    <a href='tel:+525544246837' target='_blank' rel="noreferrer" onClick={handleContactoTelefono}>
                                        55 442 46837
                                    </a>
                                </div>
                                <div className='body-footer' >
                                    <a href='mailto:hola@inter.mx' target='_blank' rel="noreferrer" onClick={handleContactoCorreo}>
                                        hola@inter.mx
                                    </a>
                                </div>
                                <Box className='body-footer'
                                    sx={{ pt: "24px" }}>
                                    <p href='' target='_blank' rel="noreferrer">
                                        Miguel de Cervantes Saavedra No. 301, Torre Sur Piso 17, Colonia Granada, Delegación Miguel Hidalgo, C.P. 11520, Ciudad de México, México
                                    </p>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} sx={{display:'flex',flexDirection:{xs:'column',sm:'row',md:'column'},justifyContent:{xs:'flex-start',sm:'flex-end',md:'flex-start'}}}>
                            <Grid item xs={12} sm={4} md={12}>
                                <Box>
                                    <Box className='header-footer' 
                                        sx={{pb:{xs:'24px',sm:'16px',md:'24px',lg:'24px'},
                                            pt:{xs:'24px',sm:'40px',md:'0px',lg:'0px'},pr:{xs:'0px',sm:'24px',md:'0px',lg:'0px'}}}>Descarga nuestra app
                                    </Box>
                                    <Box className='logos' 
                                        sx={{display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            pr:{xs:'0px',sm:'24px',md:'0px',lg:'0px'},
                                        }}>
                                        <div style={{padding:0}}>
                                            <a href='https://apps.apple.com/mx/app/intermx/id1548367767?l=en' target='_blank' rel="noreferrer" onClick={handleAppStore}>
                                                <LogoApple ></LogoApple>
                                            </a>        
                                        </div>
                                        <div style={{padding:0}}>
                                            <a href='https://play.google.com/store/apps/details?id=grut.inter.mx' target='_blank' rel="noreferrer" onClick={handleGooglePlay}>
                                                <LogoGoogle></LogoGoogle>
                                            </a>
                                        </div>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4} md={12}>
                                <Box>
                                    <Box className='header-footer' 
                                        sx={{pb:'24px',pt:{xs:'24px',sm:'40px',md:'40px',lg:'24px'}
                                        }}>Síguenos en redes sociales
                                    </Box>
                                    <Box className='logos-footer'
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "flex-start",
                                            alignSelf: "stretch",
                                            height:'20px'
                                        }}>
                                        <a href='https://www.facebook.com/intermx' target='_blank' rel="noreferrer" onClick={handleFacebook}>
                                            <LFace></LFace>
                                        </a>
                                        <a href='https://www.instagram.com/intermx/' target='_blank' rel="noreferrer" onClick={handleInstagram}>
                                            <LInst></LInst>
                                        </a>
                                        <a href='https://www.linkedin.com/company/interproteccion/' target='_blank' rel="noreferrer" onClick={handleLinkedin}>
                                            <LIn></LIn>
                                        </a>
                                        <a href='https://www.tiktok.com/@inter.mx' target='_blank' rel="noreferrer" onClick={handleTiktok}>
                                            <LTik></LTik>
                                        </a>
                                        <a href='https://www.youtube.com/channel/UCZ8wH2JLzDYvvrN-JiG3roA' target='_blank' rel="noreferrer" onClick={handleYoutube}>
                                            <LYou></LYou>
                                        </a>
                                        <a href='https://twitter.com/intermx' target='_blank' rel="noreferrer" onClick={handleTwitter}>
                                            <LTwi></LTwi>
                                        </a>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container columns={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} 
                            sx={{textAlign:{xs:'start',sm:'center',md:'center'}}}>
                            <Box className='footer-footer' 
                                sx={{pt:{xs:'24px',sm:'40px',md:'40px',lg:'40px'},
                                    fontSize: {xs:'12px',sm:'10px',md:'10px',lg:'10px'},
                                    fontStyle: 'normal',
                                    fontWeight: {xs:400,sm:300,md:300,lg:300},
                                    lineHeight: {xs:'normal',sm:'200.4%',md:'200.4%',lg:'200.4%'}}}>
                                Copyright {/* {new Date().getFullYear()} */}2023 ® Interprotección</Box>
                        </Grid>
                    </Grid>
                </Box>
            </footer>
        </>
    );
}
