import styles from './HeaderLigaPago.module.css';
import logo_inter from '../../assets/img/cobranza/logo_inter.svg';

export default function HeaderLigaPago() {
  return (
    <div className={styles['container-header-recibos']}>
      <img src={logo_inter} className={styles['logo-inter-recibos']} alt="Inter MX" />
    </div>
  );
}
