import { Paper, IconButton, InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TabsSeguros from "./TabsSeguros";

import './PreguntasFrecuentes.css';
import { useState } from "react";

export default function Buscador( props ) {
  const { onSelectTabOption, currentTab, width } = props;
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  }

  return (
    <div className="preguntas-container pb-8">
      <div className="pt-8" style={{ textAlign: "-webkit-center" }}>
        <Paper
          className="border-buscador"
          component="form"
          elevation={0}
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: width > 500 ? 950 : 380,
          }}
        >
          <IconButton
            type="button"
            sx={{ p: "10px", color: "#212121" }}
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Buscar"
            inputProps={{ "aria-label": "buscar pregunta" }}
            onChange={handleInputChange}
          />
        </Paper>
      </div>
      <div className="pt-8">
        <TabsSeguros onSelectTabOption={onSelectTabOption} currentTab={currentTab} inputValue={inputValue} />
      </div>
    </div>
  );
}
