import { ReactComponent as Check } from 'assets/img/icons/check.svg'
import { Grid, ListItem, ListItemAvatar, ListItemIcon, ListItemText } from "@mui/material";

export default function OtherAssitance () {
  const plan = {
    one:[{
      text:`Gastos médicos por hospitalización por COVID |`,
      bold: `Hasta $45,000.00 (cuarenta y cinco mil) pesos.`
    }
  ],
    two:[{
      text: `Pago y envío de medicamentos (posterior a la hospitalización) |`,
      bold: `Hasta $5,000.00 (cinco mil) pesos.`
    }],
    three:[{
      text: `Pago de honorarios médicos en hospital |`,
      bold: `Hasta $10,000.00 MXN`
    }]
  }
  return (
    <section className="pb-5 bg-gray-100">
    <div className="container md:w-10/12 mx-auto px-4">
        <div className='bg-white rounded-lg'>
          <div className='p-2'>
            <div className='p-5 text-center uppercase font-20 font-bold' style={{borderBottom: '1px solid #C7C7C7'}} >Asistencias Covid-19**</div>
            <div>
              <Grid container>
                <Grid item xs={12} sm={4}>
                {
                  plan.one.map((data, index) => {
                    return (
                    <ListItem key={index}>
                      <ListItemAvatar>
                        <ListItemIcon>
                          <Check />
                        </ListItemIcon>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <div style={{marginLeft: -25}}>{data.text} <span className='font-bold'>{data.bold}</span></div>
                        }
                      />
                    </ListItem>)
                  })
                }
                </Grid>
                <Grid item xs={12} sm={4}>
                {
                  plan.two.map((data, index) => {
                    return (
                    <ListItem key={index}>
                      <ListItemAvatar>
                        <ListItemIcon>
                          <Check />
                        </ListItemIcon>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <div style={{marginLeft: -25}}>{data.text} <span className='font-bold'>{data.bold}</span></div>
                        }
                      />
                    </ListItem>)
                  })
                }
                </Grid>
                <Grid item xs={12} sm={4}>
                {
                  plan.three.map((data, index) => {
                    return (
                    <ListItem key={index}>
                      <ListItemAvatar>
                        <ListItemIcon>
                          <Check />
                        </ListItemIcon>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <div style={{marginLeft: -25}}>{data.text} <span className='font-bold'>{data.bold}</span></div>
                        }
                      />
                    </ListItem>)
                  })
                }
                </Grid>
                <Grid xs={12}>
                  <div className='font-12 text-center p-2'>
                  ** Estas coberturas operan Vía Reembolso y aplican presentando una prueba COVID PCR positiva y tras 48 horas de hospitalización
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}