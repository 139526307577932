import StatusCard from 'components/desk/StatusCard';

export default function WorkingSection() {
    return (
        <section className="pt-16 bg-working-background bg-cover">
            <div className="container mx-auto md:w-10/12 px-4">
                <div className="flex flex-wrap relative z-50 -mt-32 mx-auto ">
                    <StatusCard />
                </div>
            </div>
        </section>
    );
}
