import React, {useEffect} from "react";
import { Typography, Grid } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ArrowOutward } from "@mui/icons-material";
import { updateStaticMetadata} from "components/utils/utils";

const BlogDetails = (props) => {
  const { toggleDetails, currentPost, relatedPosts, handleRelatedArticle, images } = props;

 useEffect(() => {
    const noteImg = document.getElementById("noteImg");
    noteImg.scrollIntoView();
    updateStaticMetadata(currentPost.title,currentPost.metadata,currentPost.url);
  }, []);

  /* useEffect(()=> {
    
    return()=>{
      //updateStaticMetadata(title,metadata,url);
      updateStaticMetadata();
    }

  }, []); */

  return (
    <div className="flex justify-center">
      <div style={{ maxWidth: "1100px" }}>
        <div className="flex mb-8 cursor-pointer" onClick={toggleDetails}>
          <ArrowBackIcon
            sx={{ color: "#039ECC", fontWeight: "700" }}
            className="mr-2"
          />{" "}
          <Typography sx={{ color: "#039ECC", fontWeight: "700" }}>
            Regresar
          </Typography>
        </div>
        <div className="blog-tags-container my-4">
          <div className="blog-first-tag">
            <Typography>{currentPost.tags[0]}</Typography>
          </div>
          {/* <div className="blog-second-tag">
            <Typography>{currentPost.tags[1]}</Typography>
          </div> */}
        </div>
        <div className="blog-grid-border">
          <img id="noteImg" src={currentPost?.image.length < 10 ? images[currentPost.image] : currentPost.image} />
          <Typography
            className="txt-bold text-center py-4"
            style={{ fontSize: "18px" }}
          >
            {" "}
            <h1>{currentPost.title}</h1>{" "}
          </Typography>
          {/* <Typography className="pb-8"> {currentPost.description} </Typography> */}
          <div dangerouslySetInnerHTML={{__html: currentPost.description}} className="mb-4"/>

        </div>
        <div>
          <Typography
            className="txt-bold text-center py-8"
            style={{ fontSize: "22px" }}
          >
            {" "}
            Artículos relacionados{" "}
          </Typography>
          <Grid container sx={{ maxWidth: "1150px" }}>
            {relatedPosts &&
              relatedPosts.map((data, index) => {
                return (
                  <Grid item xs={12} md={6} className="px-4 py-8" key={index}>
                    <div className="blog-tags-container">
                      <div className="blog-first-tag">
                        <Typography>{data.tags[0]}</Typography>
                      </div>
                      <div className="blog-second-tag">
                        <Typography>{data.tags[1]}</Typography>
                      </div>
                    </div>
                    <div className="flex items-center justify-center" style={{height: "80px"}}>
                      <Typography className="text-center txt-bold py-4">
                      {/* {(data.title).toUpperCase()} */}
                      <h2>{data.title}</h2>
                      </Typography>
                    </div>
                    <div
                      className="blog-img-promo"
                      onClick={() => handleRelatedArticle(data)}
                    >
                      <img src={data?.image.length < 10 ? images[data.image] : data.image} />
                      <span>
                        <ArrowOutward />
                      </span>
                    </div>
                  </Grid>
                );
              })}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
