export default function Legend () {
  return (
    <section className="bg-gray-100" >
      <div className="container w-full mx-auto md:w-10/12 px-8">
        <div className='font-22 uppercase' style={{color: '#212121'}}>
          Otras entradas
        </div>
      </div>
    </section>
  )
}