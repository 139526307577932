import React, {useState} from 'react';
import { Button as BRender, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import moment, {} from "moment";
import "moment/locale/es-mx";

const Button = styled(BRender)(({ theme }) => ({
  color: 'white',
  backgroundColor: '#039ECC',
  width: '100%',
  borderRadius: 10,
  minHeight: 48,
  textTransform: 'uppercase !important',
  '& .Mui-disabled': {
    backgroundColor: 'rgba(0, 0, 0, 0.12)'
  }
}));

export default function Form () {
  const [genero, setGenero] = useState('true');
  const [cp, setCP] = useState(null);
  const [cel, setCel] = useState(null);
  const [birth, setBirth] = useState(null)
  const [name, setName] = useState(null)
  const [email, setEmail] = useState(null)
  const [error, setError] = useState({
    name: false,
    cel: false,
    email: false,
    cp: false,
    birth: false
  })
  const handleChange = (event) => {
    setGenero(event.target.value);
  };

  const handleSelect = (value) => {
    setBirth(value);
    setError({...error, birth: false})
  };
  const getForm = () => {
    return {
      name: name,
      email: email,
      cel: cel,
      birth: birth && moment(birth).toISOString().substr(0,10),
      cp,
      male: genero, 
    }
  }
  const validate = () => {
    const form = getForm()
    let result = error
    let response = false
    Object.keys(result).map((key, i) => {
			return result[key] = true
		})
    if(form.name) {
      result.name = false
    }
    if(form.email && error.email) {
      result.email = false
    }
    if(form.cel && form.cel.length === 10) {
      result.cel = false
    }
    if(form.cp && form.cp.length === 5) {
      result.cp = false
    }
     if(form.birth){
      result.birth = false
     }
    setError({
      name: result.name,
      cel: result.cel,
      cp: result.cp,
      email: result.email,
      birth: result.birth
    })
    response = (Object.keys(result).map((data) => result[data])).every((result) => result === false)
    return response
  }
  const sendToGmm = (e) => {
    const form = getForm()
    if (validate()){
      let url
      url =`nombre=${form.name}&email=${form.email}&telefono=${form.cel}&genero=${form.male}&codigopostal=${form.cp}&nacimiento=${form.birth}`
      window.location.href='https://medicoseguro.inter.mx/medico-seguro/datos-personales?' + url
    }
    e.preventDefault()
  }

  const handleEmail = (e) => {
    let review = true
    review = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(e.target.value)
    setEmail(e.target.value)
    setError({...error, email: !review})
  }

  const handleNum = (type) => (e) => {
    let review = true
    review = /^[0-9\b]+$/.test(e.target.value)
    switch (type) {
      case 'cel':
        if (e.target.value === '' || review) {
          setCel(e.target.value)
        }
        break
      case 'cp':
        if (e.target.value === '' || review) {
          setCP(e.target.value)
        }
        break
      default:
        break
    }
    setError({...error, [type]: !review})
    
  }

  const handleText = (e) => {
    setName(e.target.value)
    if(e.target.value === '') {
      setError({...error, name: true})
    }
  }

  return (
    <div className='bg-white rounded-lg' >
      <div className=''>
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item xs={12}>
            <div className='p-5 text-black text-center' >
              <div className='font-bold font-20'>Buscamos un seguro para ti <br/> seguro, seguro</div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className='px-5' >
              <div className='py-3 txt-color-gray uppercase' >
                Datos del cliente
              </div>
              <div className='py-3' >
                <FormControl fullWidth>
                  <TextField 
                    error={error.name}
                    id="name" 
                    label="Nombre" 
                    variant="outlined"
                    value={name}
                    helperText={error.name && "Ingrese un nombre válido"}
                    onChange={handleText}
                  />
                </FormControl>
              </div>
              <div className='py-3'>
                <FormControl fullWidth>
                  <TextField 
                    id="email" 
                    label="Correo electrónico" 
                    variant="outlined"
                    value={email}
                    error={error.email}
                    helperText={error.email && "Ingrese un correo válido"}
                    onChange={handleEmail}
                  /> 
                </FormControl>
              </div>
              <div className='py-3'>
                <Grid container spacing={3} >
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <TextField 
                        id="cel" 
                        label="Celular" 
                        variant="outlined"
                        value={cel}
                        error={error.cel}
                        helperText={error.cel && "Ingrese un numero válido"}
                        inputProps={{maxLength: 10}}
                        onChange={handleNum('cel')}
                      /> 
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} >
                    <FormControl fullWidth>
                      <LocalizationProvider dateAdapter={AdapterMoment} locale={'es'}>
                        <DatePicker
                          label="Fecha de nacimiento"
                          value={birth}
                          onChange={handleSelect}
                          renderInput={(params) => <TextField {...params} error={error.birth} helperText={error.birth && 'Ingrese una fecha válida'} />}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <TextField 
                        id="cp" 
                        label="Código postal" 
                        variant="outlined"
                        onChange={handleNum('cp')}
                        value={cp}
                        error={error.cp}
                        helperText={error.cp && "Ingrese un código postal válido"}
                        inputProps={{maxLength: 5}}
                      /> 
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} >
                    <FormControl fullWidth>
                      <RadioGroup 
                        row 
                        aria-label="genero" 
                        name="genero"
                        id="male"
                        value={genero}
                        onChange={handleChange}
                        >
                        <FormControlLabel value='true' control={<Radio />} label='Hombre' />
                        <FormControlLabel value='false' control={<Radio />} label='Mujer' />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
              <div className='pt-3 pb-6'>
                <Button variant='contained' onClick={sendToGmm} target='_blank'>
                  Buscar un seguro &nbsp;<ArrowForwardIcon />
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>)
}