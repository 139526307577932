import { Grid, ListItem, ListItemAvatar, ListItemIcon, ListItemText } from "@mui/material";
import { ReactComponent as Check } from 'assets/img/icons/check.svg'

export default function Plans () {
  const plan = {
    one: [
      {
        text: `Suma asegurada:`,
        bold: `$100 millones`
      }, {
        text: `Nivel hospitalario:`,
        bold: `AMPLIO`
      }, {
        text: `Deducible:`,
        bold: `$30,000`
      }, {
        text: `Coaseguro:`,
        bold: `10%`
      }, {
        text: `Tope coaseguro:`,
        bold: `$60,000`
      }
  ],
    two: [
      {
        text: `Suma asegurada:`,
        bold: `$100 millones`
      }, {
        text: `Nivel hospitalario:`,
        bold: `AMPLIO`
      }, {
        text: `Deducible:`,
        bold: `$30,000`
      }, {
        text: `Coaseguro:`,
        bold: `10%`
      }, {
        text: `Tope coaseguro:`,
        bold: `$60,000`
      }
    ],
    three:[
      {
        text: `Suma asegurada:`,
        bold: `$100 millones`
      }, {
        text: `Nivel hospitalario:`,
        bold: `AMPLIO`
      }, {
        text: `Deducible:`,
        bold: `$30,000`
      }, {
        text: `Coaseguro:`,
        bold: `10%`
      }, {
        text: `Tope coaseguro:`,
        bold: `$60,000`
      }
    ],
    four:[
      {
        text: `Suma asegurada:`,
        bold: `$100 millones`
      }, {
        text: `Nivel hospitalario:`,
        bold: `AMPLIO`
      }, {
        text: `Deducible:`,
        bold: `$30,000`
      }, {
        text: `Coaseguro:`,
        bold: `10%`
      }, {
        text: `Tope coaseguro:`,
        bold: `$60,000`
      }
    ]
  }
  return (
  <section className="pb-5 bg-gray-100">
    <div className="container md:w-10/12 mx-auto px-4">
          <div className=''>
            <div>
              <Grid container>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <div className='p-3' >
                    <div className='bg-white rounded-lg' >
                      <div className='px-4' >
                        <div className='font-bold font-20 py-4' style={{borderBottom: '1px solid #C7C7C7'}} >Esencial</div>
                      </div>
                      <div>
                    {
                      plan.one.map((data, index) => {
                        return (
                        <ListItem key={index}>
                          <ListItemAvatar>
                            <ListItemIcon>
                              <Check />
                            </ListItemIcon>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <div style={{marginLeft: -25}}>{data.text} <span className='font-bold'>{data.bold}</span></div>
                            }
                          />
                        </ListItem>)
                      })
                    }
                    </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <div className='p-3' >
                    <div className='bg-white rounded-lg' >
                      <div className='px-4' >
                        <div className='font-bold font-20 py-4' style={{borderBottom: '1px solid #C7C7C7'}} >Protegido</div>
                      </div>
                      <div>
                    {
                      plan.two.map((data, index) => {
                        return (
                        <ListItem key={index}>
                          <ListItemAvatar>
                            <ListItemIcon>
                              <Check />
                            </ListItemIcon>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <div style={{marginLeft: -25}}>{data.text} <span className='font-bold'>{data.bold}</span></div>
                            }
                          />
                        </ListItem>)
                      })
                    }
                    </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3} >
                  <div className='p-3' >
                    <div className='bg-white rounded-lg' >
                      <div className='px-4' >
                        <div className='font-bold font-20 py-4' style={{borderBottom: '1px solid #C7C7C7'}} >Protegido +</div>
                      </div>
                      <div>
                    {
                      plan.three.map((data, index) => {
                        return (
                        <ListItem key={index}>
                          <ListItemAvatar>
                            <ListItemIcon>
                              <Check />
                            </ListItemIcon>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <div style={{marginLeft: -25}}>{data.text} <span className='font-bold'>{data.bold}</span></div>
                            }
                          />
                        </ListItem>)
                      })
                    }
                    </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <div className='p-3' >
                    <div className='bg-white rounded-lg' >
                      <div className='px-4' >
                        <div className='font-bold font-20 py-4' style={{borderBottom: '1px solid #C7C7C7'}} >Blindado</div>
                      </div>
                      <div>
                    {
                      plan.four.map((data, index) => {
                        return (
                        <ListItem key={index}>
                          <ListItemAvatar>
                            <ListItemIcon>
                              <Check />
                            </ListItemIcon>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <div style={{marginLeft: -25}}>{data.text} <span className='font-bold'>{data.bold}</span></div>
                            }
                          />
                        </ListItem>)
                      })
                    }
                    </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
    </div>
  </section>)
} 