import { useEffect, useState, useRef } from 'react';
import { Backdrop, Button } from '@mui/material';
import styles from './Inactividad.module.css';

const inactivityTime = 20; // minutos de inactividad
const checkInterval = 1; // revisar inactividad cada x minutos
const preAvisoTime = 4; // minutos antes de finalizar la inactividad para mostrar el pre aviso

export default function Inactividad({ onFinishTime, onUpdateLastActivity, lastActivity }) {
  const [showPreAviso, setShowPreAviso] = useState(false);
  const showPreAvisoRef = useRef(showPreAviso);

  const handleContinueActivity = () => {
    setShowPreAviso(false);
    onUpdateLastActivity(Date.now());
  };

  const handleActivity = () => {
    if (!showPreAvisoRef.current) {
      onUpdateLastActivity(Date.now());
      //console.log("se actualiza el tiempo", showPreAvisoRef.current);
    } else {
      //console.log("No se actualiza el tiempo", showPreAvisoRef.current);
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      const now = Date.now();
      const elapsedTime = now - lastActivity;
      const remainingTime = (inactivityTime * 60 * 1000) - elapsedTime;
      if (elapsedTime >= inactivityTime * 60 * 1000) {
        setShowPreAviso(false);
        onFinishTime();
        clearInterval(timer);
      } else if (remainingTime <= preAvisoTime * 60 * 1000) { // 2 minutos en milisegundos
        setShowPreAviso(true);
      }
    }, checkInterval * 60 * 1000);

    return () => clearInterval(timer);
  }, [lastActivity]);

  useEffect(() => {
    showPreAvisoRef.current = showPreAviso;
  }, [showPreAviso]);

  useEffect(() => {
    // Reiniciar el temporizador cuando el usuario hace click o presiona una tecla
    window.addEventListener('click', handleActivity);
    window.addEventListener('keypress', handleActivity);

    return () => {
      window.removeEventListener('click', handleActivity);
      window.removeEventListener('keypress', handleActivity);
    };
  }, []);

  return (
    <>
      <Backdrop sx={{ backgroundColor: "rgb(0 0 0 / 85%)", zIndex: 1300 }}
        open={showPreAviso}
      >
        <div className={styles['container-inactividad']}>
          <p className={styles['inactividad-info']}>Parece que has estado inactivo. ¿Estás ahí?</p>
          <Button variant="contained" style={{ borderRadius: 20, width: 125 }} onClick={handleContinueActivity}>Sigo aquí</Button>
        </div>
      </Backdrop>
    </>
  );
}
