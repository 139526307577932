import React, {useState} from 'react';
import { Button as BRender, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

const Button = styled(BRender)(({ theme }) => ({
  color: 'white',
  backgroundColor: '#039ECC',
  width: '100%',
  borderRadius: 10,
  minHeight: 48,
  textTransform: 'uppercase !important',
  '& .Mui-disabled': {
    backgroundColor: 'rgba(0, 0, 0, 0.12)'
  }
}));

export default function Form () {
  const [dates, setDates] = useState({
    start: new Date(),
    end: new Date()
  });
  const [travel, setTravel] = useState('Grupal');
  const [days, setDays] = useState('');
  const daysTravel = [1,4,8,15]
  const handleChange = (event) => {
    setTravel(event.target.value);
  };

  const handleSelect = (event) => {
    setDays(event.target.value);
  };

  const setDate = (type) => (value) =>{
    setDates({...dates, [type]: value})
  }
  return (
    <div className='bg-white rounded-lg' >
      <div className='p-3'>
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item xs={12}>
            <div className='p-5 text-black' >
              <div className='font-bold font-20'>Viaja de forma segura</div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className='px-5' >
              <div className=''>
                <FormControl fullWidth>
                  <RadioGroup 
                    row 
                    aria-label="travel" 
                    name="travel"
                    value={travel}
                    onChange={handleChange}
                    >
                    <FormControlLabel value='Grupal' control={<Radio />} label='Grupal' />
                    <FormControlLabel value='Individual' control={<Radio />} label='Individual' />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className='py-3'>
                <FormControl fullWidth >
                  <InputLabel id='days'>Dias del viaje</InputLabel>
                  <Select labelId='days' id='select-day' value={days} label='Dias del viaje' onChange={handleSelect}>
                    {daysTravel.map((data, index) => {
                      return (
                        <MenuItem key={index} value={data} >{data}</MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </div>
              <div className='py-3' >
                <Grid container spacing={3} >
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          label="Desde"
                          value={dates.start}
                          onChange={setDate('start')}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} >
                    <FormControl fullWidth>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          label="Hasta"
                          value={dates.end}
                          onChange={setDate('end')}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
              <div className='py-3'>
                <Button variant='contained' href='https://vigo.inter.mx/formulario-asistencia' target='_blank'>
                  Cotizar
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>)
}