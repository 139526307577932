// TabSeguros.js
import React, { useState, useEffect, Fragment } from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@mui/material";
import axios from "axios";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./PreguntasFrecuentes.css";
//import TagManager from "react-gtm-module";
import PaginationComponent from "components/common/PaginationComponent";
import { useAuth } from "hooks/useAuth";


const Preguntas = (props) => {
  const { currentTab, inputValue } = props;
  const{token} = useAuth();
  const [questionsData, setQuestionsData] = useState({});
  const [filterData, setFilterData] = useState({});
  const [filterActive, setFilterActive] = useState(false);
  const [expanded, setExpanded] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentQuestions, setCurrentQuestions] = useState([]);
  const [currentFilterData, setCurrentFilterData] = useState([]);


  useEffect(() => {
    // Función para hacer la solicitud a la URL
    const getQuestions = async () => {
      try {
        const response = await axios.get(
          "https://api.inter.mx/v1/catalogs/frequent-questions",
          { params: { tag: currentTab }, headers:{Authorization:`Bearer ${token}`} }
        );
        setQuestionsData(response.data);
        setCurrentQuestions(response.data.data.slice(0, 10));
      } catch (error) {
        console.error("Error al hacer la solicitud:", error);
      }
    };

    getQuestions(); // Llama a la función para obtener los datos solo una vez al montar el componente
  }, [currentTab, token]); // El array de dependencias vacío asegura que useEffect se ejecute solo una vez

  useEffect(() => {
    if (inputValue.length > 2) {
      setFilterActive(true);
      const searchResults = questionsData.data.filter((item) => {
        const titleMatches = item.title
          .toLowerCase()
          .includes(inputValue.toLowerCase());
        const tagsMatches = item.tags.some((tag) =>
          tag.toLowerCase().includes(inputValue.toLowerCase())
        );

        return titleMatches || tagsMatches;
      });
      setFilterData(searchResults);
      setCurrentFilterData(searchResults.slice(0, 10));
    } else {
      setFilterActive(false);
    }
  }, [inputValue]);

  useEffect(() => {
    const startIndex = (currentPage - 1) * 10;
    const endIndex = startIndex + 10;
    if (filterActive) {
      setCurrentFilterData(filterData.slice(startIndex, endIndex));
    } else if (questionsData?.data?.length) {
      setCurrentQuestions(questionsData.data.slice(startIndex, endIndex));
    }
    return () => {
    }
  }, [currentPage]);

  const handleChange = (panel, data) => (event, newExpanded) => {
    if (newExpanded) {
      if (inputValue.length > 2) {
        window.dataLayer.push({
          event: "InterMx_faqs_search",
          faq: inputValue,
          tag_faq: data.tags[0],
          tag_keyword: data.tags[1],
          page_url: window.location.href,
        });
      }
      window.dataLayer.push({
        event: "InterMx_faqs_open",
        faq: data.title,
        tag_faq: data.tags[0],
        tag_keyword: data.tags[1],
        page_url: window.location.href,
      });
    } else {
      window.dataLayer.push({
        event: "InterMx_faqs_close",
        faq: data.title,
        tag_faq: data.tags[0],
        tag_keyword: data.tags[1],
        page_url: window.location.href,
      });
    }
    setExpanded(newExpanded ? panel : false);
  };

  let fullData = filterActive ? currentFilterData : currentQuestions;

  return (
    <>
      {fullData &&
        fullData.map((data, index) => {
          return (
            <div className="question-container" key={index}>
              <Accordion
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`, data)}
                style={{ boxShadow: "none" }}
              >
                <AccordionSummary
                  expandIcon={
                    expanded === `panel${index}` ? "" : <ArrowForwardIosIcon />
                  }
                  aria-controls={`panel${index}d-content`}
                  id={`panel${index}d-header`}
                >
                  <div>
                    {expanded === `panel${index}` && (
                      <div className="flex mb-6">
                        <ArrowBackIcon
                          sx={{ color: "#039ECC", fontWeight: "700" }}
                          className="mr-2"
                        />{" "}
                        <Typography
                          sx={{ color: "#039ECC", fontWeight: "700" }}
                        >
                          Regresar
                        </Typography>
                      </div>
                    )}
                    <div className="tags-container">
                      <div className="first-tag">
                        <Typography>{data.tags[0]}</Typography>
                      </div>
                      <div className="second-tag">
                        <Typography>
                          {data.tags[1] && data.tags[1] !== ""
                            ? data.tags[1]
                            : "TAG KEYWORD"}
                        </Typography>
                      </div>
                    </div>
                    <div className="mt-4">
                      <Typography sx={{ fontWeight: "700" }}>
                        <h2>{data.title}</h2>
                      </Typography>
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div dangerouslySetInnerHTML={{__html: data.description}} className="text-left mb-4"/>
                </AccordionDetails>
              </Accordion>
            </div>
          );
        })}

      <PaginationComponent data={filterActive ? filterData : questionsData?.data} showData={10} setCurrentPage={setCurrentPage} />
    </>
  );
};

export default Preguntas;
