import DefaultNavbar from 'components/DefaultNavbar';
import DefaultFooter from 'components/DefaultFooter';
import Header from 'components/vigo/Header';
import TeamSection from 'components/landing/TeamSection';
import Legend from 'components/vigo/Legend';
import LegendAfter from 'components/vigo/LegendAfter';
import FooterProducts from 'components/FooterProduct';
import TravelsAssitance from 'components/vigo/TravelsAssistance';
import MedicalAssitance from 'components/vigo/MedicalAssistance';
import OtherAssitance from 'components/vigo/OtherAssistance';
import SosSection from 'components/vigo/SosSection';
import { useEffect } from 'react';
import Budget from 'components/vigo/Budget';

export default function VigoSeguro() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <div className="absolute w-full z-20">
                <DefaultNavbar />
            </div>
            <main>
              <Header />
              <Legend />
              <TeamSection />
              <LegendAfter />
              <TravelsAssitance />
              <MedicalAssitance />
              <OtherAssitance />
              <SosSection />
              <Budget />
              <FooterProducts />
            </main>
            <DefaultFooter />
        </>
    );
}
