export default function LegendAfter () {
  return (
    <section className="pb-5 bg-gray-100" >
      <div className="container mx-auto max-w-full">
        <div className='uppercase text-center font-22 p-5' style={{color: '#212121'}}>
          Contamos con las mejores asistencias para que viajes seguro
        </div>
      </div>
    </section>
  )
}