import Container from '@mui/material/Container';
import { useParams } from 'react-router-dom';
import DetallesLigaPago from 'components/cobranza/DetallesLigaPago';
import HeaderLigaPago from 'components/cobranza/HeaderLigaPago';
import FooterLigaPago from 'components/cobranza/FooterLigaPago';

export default function Cobranza() {
  const { id_liga } = useParams();

  return (
    <>
      <Container maxWidth="100%" style={{
        height: "100vh",
        paddingTop: 64,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}>
        <HeaderLigaPago />
        <DetallesLigaPago idLigaPago={id_liga} />
        <FooterLigaPago />
      </Container>
    </>
  );
}
