import styles from './PagoExitosoDowApp.module.css';
import successful_payment from '../../assets/img/cobranza/successful_payment.png';
import download_app_bg from '../../assets/img/assistants/download_app_bg_desk.svg';
import play_store from '../../assets/img/assistants/play_store.svg';
import app_store from '../../assets/img/assistants/app_store.svg';
import { ContactPhoneSV, LinkAppStore, LinkPlayStore } from "../cobranza/CobranzaEnum";

export default function PagoExitosoDowApp() {
  const contact_phone = ContactPhoneSV;

  const PhoneLink = () => {
    return (
      <a href={`tel:${contact_phone.replace(/\s+/g, '')}`} className={styles['pago-recibido-info-telefono']}>
        {contact_phone}
      </a>
    )
  }

  return (
    <div className={styles['container-pago-exitoso']}>
      <p className={styles['pago-exitoso-titulo']}>¡Tu pago se ha realizado con éxito!</p>
      <img src={successful_payment} className={styles['img-pago-ok']} alt='Pago exitoso' />
      <div className={styles['background-download-app']} style={{ backgroundImage: `url(${download_app_bg})` }}>
        <p className={styles['bg-app-title']}>Descarga nuestra app y lleva tu seguro siempre a la mano</p>
        <p className={styles['bg-app-subtitle']}>Ya no tienes que preocuparte por encontrar la información de tus seguros.<br/>Con nuestra app los tienes siempre a la mano.</p>
        <div className={styles['container-link-stores']}>
          <a className={styles['link-app-appstore']} href={LinkAppStore} target="_blank" rel="noreferrer">
            <img src={app_store} className={styles['bg-app-appstore']} alt='App store' />
          </a>
          <a className={styles['link-app-playstore']} href={LinkPlayStore} target="_blank" rel="noreferrer">
            <img src={play_store} className={styles['bg-app-playstore']} alt='Google play' />
          </a>
        </div>
      </div>
      <p className={styles['pago-exitoso-info']}>Gracias por tu pago, si necesitas ayuda o tienes dudas, comunícate con nosotros al
      <span className={styles['pago-exitoso-info-telefono']}> {<PhoneLink />} </span>y con gusto te atenderemos.</p>
    </div>
  );
}
