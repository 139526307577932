import { useEffect } from "react";
import DefaultNavbar from "components/DefaultNavbar";
import DefaultFooter from "components/DefaultFooter";
import HeaderUs from "components/landingUs/HeaderUs";
import Insurances from "components/landingUs/Insurances";
import InterMean from "components/landingUs/InterMean";
import TagManager from 'react-gtm-module';

export default function LandingUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
    TagManager.dataLayer({ dataLayer: {
        event: 'USA_Landing',
        eventCategory:'USA_Landing',
        eventAction: 'HomeView',
        eventLabel: undefined
      }});
  }, []);
  return (
    <>
      <DefaultNavbar />
      <main>
        <HeaderUs />
        <InterMean />
        <Insurances />
      </main>
      <DefaultFooter />
    </>
  );
}
