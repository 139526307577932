import Icon from "../../assets/img/landingRedisign/Mani-Poses-15.png";
import { Typography } from "@mui/material";
import { Helmet } from "react-helmet";

import "./PreguntasFrecuentes.css";

export default function Header(props) {
  const { width } = props;

  return (
    
    <div className="relative justify-center">
      <Helmet>
        <title>Preguntas Frecuentes | Seguros a la medida | inter.mx</title>
        <meta name="description" content="Encuentra todas las preguntas y respuestas acerca de la importancia de un Seguro de Coche, Gastos médicos y Mascota. Conoce todo el proceso de compra."></meta>
        <link rel="canonical" href="https://inter.mx/preguntas-frecuentes" />
      </Helmet>
      <div className="flex items-center justify-center preguntas-frecuentes-container">
        {width <= 500 && (
          <div className="pf-container">
            <div className="pf-titles">
              <p className="preguntas">preguntas</p>
              <p className="frecuentes">frecuentes</p>
            </div>
            <img src={Icon} className="absolute z-9 peguntas-frecuentes-img" alt="" />
          </div>
        )}

        {width > 500 && (
          <>
            <img src={Icon} className="peguntas-frecuentes-img" alt="" />
            <div className="preguntas-frecuentes">
              <p>
                <span className="preguntas">preguntas</span>
                <span className="frecuentes">frecuentes</span>
              </p>
            </div>
          </>
        )}
      </div>


      {/* <div className="container-preguntas items-center display-height">
        
      </div> */}
      <div className="descripcion-preguntas">
        <Typography>
          Nuestros clientes nos han hecho miles de preguntas sobre el mundo de
          los seguros y a todas ellas les hemos podido dar respuesta.
        </Typography>
      </div>
    </div>
  );
}
