import { useEffect, useState } from "react";
import DefaultNavbar from "components/DefaultNavbar";
import DefaultFooter from "components/DefaultFooter";
import Header from "components/promotion/Header";
import Promotions from "components/promotion/Promotions";

export default function PromotionsPage() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.scrollTo(0, 0);
    // Función para actualizar el estado cuando cambia el tamaño de la ventana
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    // Agregar un listener para el evento de cambio de tamaño de ventana
    window.addEventListener("resize", handleResize);
    // Limpiar el listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <DefaultNavbar />
      <main>
        <Header width={width} />
        <Promotions width={width} />
      </main>
      <DefaultFooter />
    </>
  );
}
